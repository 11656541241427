export class Field {
    fieldId;
    name;
    displayOrder;
    formTypeId;
    type;
    dataType;
    isRunningTotal;
    runningTotalDecimals;
    format;
    significantDigits;
    currencyCode;
    isCalculated;
    expression;
//
    deactivated;
    systemName;
    textFormat;
    fieldFormula;
    useFieldDefault;
    defaultValue;
    showRunningTotal;
    confidential;
    showInEditor;
    isFieldIndexed;
    dateFormat;
 }

 export class FieldList {
     formTypeId;
     fields;
 }

 export const FieldDataType = Object.freeze({
    String: Symbol("0"),
    Number: Symbol("1"),
    Date: Symbol("2"),
  });

//   export class FieldModel {
//       public field: Field;
//       public isDirty: boolean;
//       public toDelete: boolean;
//   }
