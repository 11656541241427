import * as React from 'react';
import AutoOcrSidebar from './AutoOcrSidebar';
import { createBrowserHistory } from 'history';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import {
    GetFieldDataForDocType,
    UpdateAutoOcrData,
    DeleteDocumentType
  } from '../../../../actions/AutoOcrActions';
import AutoOcrStore from '../../../../stores/AutoOcrStore';
import actionTypes from '../../../../actions/ActionTypes';
import { Link } from 'react-router-dom';

const customHistory = createBrowserHistory();

class AutoOcrDocumentTypeView extends React.Component {
  constructor(props) {
    super(props);
    // state
    this.state = {
      currentDocTypeId: 0,
      currentDocTypeName: '',
      editName: '',
      showEditName: false,
      currentField: undefined,
      isSecOneDirty: false,
      isSecOneSaving: false,
      errorOne: '',
      deleteStatus: 0,
      fields: [],
      currentView: 'Fields',
      currentFieldIdentifier: undefined,
      newFieldIdentifierValue: '',
      newPatternValue: '',
      currentFieldData: undefined,
      fieldDataSet: [],
      newIdentifierId: 0,
      newPatternId: 0,
      newFieldId: 0,
      newFieldName: '',
    };
    this.updateDocumentTypes = this.updateDocumentTypes.bind(this);
    this.onStoreUpdate = this.onStoreUpdate.bind(this);
    this.startDelete = this.startDelete.bind(this);
    this.deleteField = this.deleteField.bind(this);
    this.deleteFieldIdentifier = this.deleteFieldIdentifier.bind(this);
    this.deleteFieldPattern = this.deleteFieldPattern.bind(this);
    this.editField = this.editField.bind(this);
    this.cancelDelete = this.cancelDelete.bind(this);
    this.backToFields = this.backToFields.bind(this);
    this.backToFieldIdentifiers = this.backToFieldIdentifiers.bind(this);
    this.editFieldPatternText = this.editFieldPatternText.bind(this);
    this.addNewFieldIdentifier = this.addNewFieldIdentifier.bind(this);
    this.editNewFieldIdentifier = this.editNewFieldIdentifier.bind(this);
    this.addNewPattern = this.addNewPattern.bind(this);
    this.editNewPattern = this.editNewPattern.bind(this);
    this.editNewFieldName = this.editNewFieldName.bind(this);
    this.editFieldName = this.editFieldName.bind(this);
    this.save = this.save.bind(this);
    this.deleteDocumentType = this.deleteDocumentType.bind(this);
    this.isNameChangeClick = this.isNameChangeClick.bind(this);
    this.handleNameChange = this.handleNameChange.bind(this);
    this.handleNameCancelClick = this.handleNameCancelClick.bind(this);
    this.handleNameSaveClick = this.handleNameSaveClick.bind(this);
  }

  componentDidMount() {
    AutoOcrStore.addChangeListener(this.onStoreUpdate);
    this.updateDocumentTypes();
    //GetBPList();
  }

  componentWillUnmount() {
    AutoOcrStore.removeChangeListener(this.onStoreUpdate);
  }

  componentDidUpdate() {
    this.updateDocumentTypes();
  }

  updateDocumentTypes() {
    let loc = window.location.pathname;
    let pathParts = loc.split('/');
    let docTypeId = parseInt(pathParts[pathParts.length - 1], 10);
    if (
        docTypeId !== 0 &&
      (this.state.currentDocTypeId === undefined ||
        this.state.currentDocTypeId !== docTypeId)
    ) {
      this.setState({ currentDocTypeId: docTypeId, currentView: 'Fields' });
      GetFieldDataForDocType(docTypeId);
    } else if (
      docTypeId === 0 &&
      this.state.currentDocTypeId !== undefined &&
      this.state.currentDocTypeId !== -1
    ) {
      this.setState({
        showEditName: true, 
        currentDocTypeId: -1, 
        currentView: 'Fields', 
        fieldDataSet: [], 
        editName: '',
        currentDocTypeName: ''
      });
    }
  }

  onStoreUpdate() {
    let lastAction = AutoOcrStore.lastChange();
    if (lastAction === actionTypes.AutoOcrFieldDataList) {
        let fieldData = AutoOcrStore.getFieldData();
        this.setState({
          fieldDataSet: fieldData.data, currentDocTypeName: fieldData.documentTypeName, editName: fieldData.documentTypeName, documentTypeId: fieldData.documentTypeId
        });
      }

      if (lastAction === actionTypes.AutoOcrDocTypeDelete) {
          let link = document.getElementById('autoOcrHome');
          link.click();
      }

      if (lastAction === actionTypes.AutoOcrDataUpdate) {
        let loc = window.location.pathname;
        let pathParts = loc.split('/');
        let formTypeId = parseInt(pathParts[pathParts.length - 1], 10);
        if (formTypeId === 0){
          var docTypeId = AutoOcrStore.getCurrentDocumentTypeId();
          customHistory.push(`/Admin/AutoOCRDocumentType/` + docTypeId);
          window.location.reload(false);
        }
      }
  }

  save() {
    UpdateAutoOcrData(this.state.fieldDataSet, this.state.currentDocTypeName, this.state.currentDocTypeId);
  }

  startDelete() {
    this.setState({ deleteStatus: 1 });
  }

  deleteDocumentType() {
    DeleteDocumentType(this.state.currentDocTypeId);
  }

  editField(field) {
    this.setState({ currentView: 'FieldIdentifiers', 
                    currentField: field});
  }

  editFieldIdentifier(fieldIdentifier) {
    this.setState({ currentView: 'FieldPatterns', currentFieldIdentifier: fieldIdentifier });
  }

  handleNameSaveClick() {
    this.setState({ showEditName: false, currentDocTypeName: this.state.editName });
  }

  isNameChangeClick() {
    this.setState({ showEditName: true, editName: this.state.currentDocTypeName });
  }

  handleNameCancelClick() {
    this.setState({ showEditName: false });
  }

  handleNameChange(event) {
    this.setState({ editName: event.target.value });
  }

  backToFields() {
    this.setState({ currentView: 'Fields' });
  }

  backToFieldIdentifiers() {
    this.setState({ currentView: 'FieldIdentifiers' });
  }

  editFieldName(value, field) {
    field.name = value;      
    this.setState({fieldDataSet: this.state.fieldDataSet});
  }
  
  editFieldIdentifierName(value, fieldIdentifier) {
    fieldIdentifier.identifierText = value;

    var index = this.state.fieldDataSet.filter(a => a.field.id === this.state.currentField.id)[0].identifiers.findIndex(function(c) { 
        return c.id === fieldIdentifier.id; 
    });

    this.state.fieldDataSet.filter(a => a.field.id === this.state.currentField.id)[0].identifiers[index].identifierText = value;

    this.setState({fieldDataSet: this.state.fieldDataSet});
  }


  editNewFieldIdentifier(e) {
    this.setState({ newFieldIdentifierValue: e.target.value});
  }

  addNewFieldIdentifier() {
    var fieldIdentifier = {
        id: this.state.newIdentifierId,
        ocrFieldId: this.state.currentField.id,
        identifierText: this.state.newFieldIdentifierValue
    };

    var nextIdentifierId = this.state.newIdentifierId - 1;

    this.state.fieldDataSet.filter(a => a.field.id === this.state.currentField.id)[0].identifiers.push(fieldIdentifier);

    this.setState({fieldDataSet: this.state.fieldDataSet, newFieldIdentifierValue: '', newIdentifierId: nextIdentifierId});
  }
 
  editNewFieldName(e) {
    this.setState({ newFieldName: e.target.value});
  }

  addNewField() {
    var field = {
        id: this.state.newFieldId,
        documentTypeId: this.state.currentDocTypeId,
        name: this.state.newFieldName
    };
    var patterns = [];
    var identifiers = [];

    var fieldData = {
        field: field,
        patterns: patterns,
        identifiers: identifiers
    };

    var nextFieldId = this.state.newFieldId - 1;
    
    this.state.fieldDataSet.push(fieldData);

    this.setState({fieldDataSet: this.state.fieldDataSet, newFieldName: '', newFieldId: nextFieldId});
  }

  editNewPattern(e) {
    this.setState({ newPatternValue: e.target.value});
  }

  addNewPattern() {
    var pattern = {
        id: this.state.newPatternId,
        fieldId: this.state.currentField.id,
        pattern: this.state.newPatternValue
    };

    var nextPatternId = this.state.newPatternId - 1;
    
    this.state.fieldDataSet.filter(a => a.field.id === this.state.currentField.id)[0].patterns.push(pattern);

    this.setState({fieldDataSet: this.state.fieldDataSet, newPatternValue: '', newPatternId: nextPatternId});
  }


  editFieldPatternText(value, fieldPattern) {
    fieldPattern.pattern = value;

    var index = this.state.fieldDataSet.filter(a => a.field.id === this.state.currentField.id)[0].patterns.findIndex(function(c) { 
        return c.id === fieldPattern.id; 
    });

    this.state.fieldDataSet.filter(a => a.field.id === this.state.currentField.id)[0].identifiers[index].pattern = value;

    this.setState({fieldDataSet: this.state.fieldDataSet});
  }


  deleteField(field) {
    var fieldIndex = 0;
    var index = 0;
    this.state.fieldDataSet.forEach(async (dataSet) => {
        if(dataSet.field === field){
            fieldIndex = index;
        }
        index++;
      });

    if (fieldIndex > -1) { // only splice array when item is found
        this.state.fieldDataSet.splice(fieldIndex, 1); // 2nd parameter means remove one item only
      }
      
    this.setState({fieldDataSet: this.state.fieldDataSet});
  }

  cancelDelete() {
    this.setState({ deleteStatus: 0 });
  }

  deleteFieldIdentifier(fieldIdentifier) {
    var index = this.state.fieldDataSet.filter(a => a.field.id === this.state.currentField.id)[0].identifiers.findIndex(function(c) { 
        return c.id === fieldIdentifier.id; 
    });

    if (index > -1) { // only splice array when item is found
        this.state.fieldDataSet.filter(a => a.field.id === this.state.currentField.id)[0].identifiers.splice(index, 1); // 2nd parameter means remove one item only
      }
      
    this.setState({fieldDataSet: this.state.fieldDataSet});
  }

  deleteFieldPattern(pattern) {
    var index = this.state.fieldDataSet.filter(a => a.field.id === this.state.currentField.id)[0].patterns.findIndex(function(c) { 
        return c.id === pattern.id; 
    });

    if (index > -1) { // only splice array when item is found
        this.state.fieldDataSet.filter(a => a.field.id === this.state.currentField.id)[0].patterns.splice(index, 1); // 2nd parameter means remove one item only
      }
      
    this.setState({fieldDataSet: this.state.fieldDataSet});
  }



  render() {
    return (
      <div className='container-fluid' style={{ height: '100%' }}>
        <div className='row flex-nowrap' style={{ height: '100%' }}>
          <AutoOcrSidebar />
          <Link
              id='autoOcrHome'
              className='nav-link'
              to={`/Admin/AutoOCR`}
              style={{ display: 'none' }}
            >
            </Link>
            <div
              className='flex-fill'
              style={{ backgroundColor: '#efefef', overflow: 'scroll' }}
            >
                {this.state.currentView === 'Fields' && (
                    <div
                    className='card'
                    style={{
                      marginTop: '20px',
                      marginLeft: '30px',
                      marginRight: '30px',
                      maxHeight: '620px',
                    }}
                  >
    
                    <div className='card-header' key={'header'}>
                      <div className='mt-3 fw-bold fs-5'>
        
                        {'  '}
                        {this.state.showEditName !== true && (

                            <span style={{ fontSize: '14pt' }}>
                            <FontAwesomeIcon icon={solid('wand-magic')} />
                            {' '}
                            {this.state.currentDocTypeName !== undefined
                                ? this.state.currentDocTypeName
                                : ''}
                            {'    '}
                            <FontAwesomeIcon
                                icon={solid('pen')}
                                className='fas fa-pen ms-1'
                                onClick={this.isNameChangeClick}
                                style={{
                                color: '#2D359B',
                                cursor: 'pointer',
                                }}
                            />
                                  <div className='mb-3' style={{ float: 'right' }}>
                            <div>
                                <button className='btn btn-primary'
                                onClick={this.save}>
                                Save
                                </button>
                                <button
                                className='btn btn-danger'
                                style={{ marginLeft: '6px' }}
                                onClick={this.startDelete}
                                >
                                Delete
                                </button>
                            </div>
                            {this.state.isSecOneSaving && (
                            <i className='fas fa-spinner fa-spin fa-2x' />
                            )}
                            {this.state.deleteStatus === 1 && (
                            <div
                                className='alert alert-danger'
                                style={{ marginTop: '6px' }}
                                role='alert'
                            >
                                <p>Are you sure you want to delete this document type?</p>
        
                                <button
                                className='btn btn-danger'
                                onClick={this.deleteDocumentType}
                                >
                                Yes - Delete
                                </button>
                                <button
                                className='btn btn-secondary'
                                style={{ marginLeft: '6px' }}
                                onClick={this.cancelDelete}
                                >
                                Cancel
                                </button>
                            </div>
                            )}
        
                            {this.state.errorOne !== '' && (
                            <div
                                className='alert alert-danger'
                                style={{ marginTop: '6px' }}
                                role='alert'
                            >
                                {this.state.errorOne}
                            </div>
                            )}
                        </div>
                            </span>
                        )}
                        {this.state.showEditName && (
                            <div className='row g-3 align-items-center'>
                                
                                <div className='col-auto'>
                                <FontAwesomeIcon icon={solid('wand-magic')} />
                                {' '}
                                    <label htmlFor='isNameTxt' className='col-form-label'>
                                        Document Type Name:
                                    </label>
                                </div>
                                <div className='col-auto'>
                                    <input
                                    type='text'
                                    id='isNameTxt'
                                    className='form-control'
                                    value={this.state.editName}
                                    onChange={this.handleNameChange}
                                    />
                                </div>
                                <div className='col-auto'>
                                    <button
                                    className='btn btn-primary'
                                    onClick={this.handleNameSaveClick}
                                    >
                                    OK
                                    </button>
                                </div>
                                <div className='col-auto'>
                                    <button
                                    className='btn btn-secondary'
                                    onClick={this.handleNameCancelClick}
                                    >
                                    Cancel
                                    </button>
                                </div>
                                <div className='col'>
                                  <div style={{ float: 'right' }}>
                                      <button className='btn btn-primary'
                                      onClick={this.save}>
                                      Save
                                      </button>
                                      <button
                                      className='btn btn-danger'
                                      style={{ marginLeft: '6px' }}
                                      onClick={this.startDelete}
                                      >
                                      Delete
                                      </button>
                                  </div>
                                  {this.state.isSecOneSaving && (
                                  <i className='fas fa-spinner fa-spin fa-2x' />
                                  )}
                                  {this.state.deleteStatus === 1 && (
                                  <div
                                      className='alert alert-danger'
                                      style={{ marginTop: '6px' }}
                                      role='alert'
                                  >
                                      <p>Are you sure you want to delete this document type?</p>
              
                                      <button
                                      className='btn btn-danger'
                                      onClick={this.deleteDocumentType}
                                      >
                                      Yes - Delete
                                      </button>
                                      <button
                                      className='btn btn-secondary'
                                      style={{ marginLeft: '6px' }}
                                      onClick={this.cancelDelete}
                                      >
                                      Cancel
                                      </button>
                                  </div>
                                  )}
              
                                  {this.state.errorOne !== '' && (
                                  <div
                                      className='alert alert-danger'
                                      style={{ marginTop: '6px' }}
                                      role='alert'
                                  >
                                      {this.state.errorOne}
                                  </div>
                                  )}
                                </div>
                            </div>
                        )}
    
                  
                      </div>
    
    
                    </div>
                
                    {this.state.currentView === 'Fields' && (
                        <>
                            <div className='card-body'>
                                <div className=''>
                                    {this.state.fieldDataSet.map((data) => (
                                            <div className='row' key={`field-${data.field.id}`}>
                                                <div className='col-3 pt-2'>
                                                    <input value={data.field.name}
                                                        key={data.field.id}
                                                        onChange={event => this.editFieldName(event.target.value, data.field)}
                                                        className='form-control'
                                                        >
                                                    </input>
                                                </div>
                                                <div className='col-2 mt-2'>
                                                  <span>
                                                      <button className='btn btn-primary'
                                                      onClick={ () => {this.editField(data.field) }}
                                                      >
                                                          Edit
                                                      </button>

                                                      <button
                                                          className='btn btn-danger ms-2'
                                                          onClick={ () => {this.deleteField(data.field) }}
                                                          >
                                                          Delete
                                                      </button>
                                                  </span>
                                                </div>
                                            </div>
                                        ))}

                                    <div className='row'>
                                        <div className='col-3 pt-2'>
                                            <input value={this.state.newFieldName}
                                                        onChange={this.editNewFieldName}
                                                        className='form-control'
                                                        ></input>
                                        </div>
                                        <div className='col-2 pt-2'>
                                            <button
                                                className={
                                                    this.state.newFieldName === ''
                                                      ? 'btn btn-success disabled'
                                                      : 'btn btn-success'
                                                  }
                                                onClick={ () => {this.addNewField() }}
                                                >
                                                Add
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    )}
    
                </div>
                )}

                {this.state.currentView === 'FieldIdentifiers' && (
                    <div
                    className='card'
                    style={{
                      marginTop: '20px',
                      marginLeft: '30px',
                      marginRight: '30px',
                      overflow: 'scroll'
                    }}
                  >
    
    
    
                    <div className='card-header' key={'header'}>
                        <div className='mt-3 fw-bold fs-5'>
                            <FontAwesomeIcon icon={solid('wand-magic')} />
                            {'  '}
                            {this.state.currentView === 'FieldIdentifiers' && (
                                <>Auto OCR Field configuration
                                    {this.state.currentField !== null && this.state.currentField !== undefined && 
                                        <>
                                            {' '}for field "{this.state.currentField.name}"{' '}
                                            <button
                                                className='btn btn-primary btn-sm mb-1'
                                                onClick={this.backToFields}
                                            >
                                                Back to Fields
                                            </button>
                                        </>
                                    }
                                </>
                            )}
        
                            <div className='mb-3' style={{ float: 'right' }}>
                                <div>
                                    <button className='btn btn-primary' onClick={this.save}>
                                    Save
                                    </button>
                                </div>
                                {this.state.isSecOneSaving && (
                                <i className='fas fa-spinner fa-spin fa-2x' />
                                )}
            
                                {this.state.errorOne !== '' && (
                                <div
                                    className='alert alert-danger'
                                    style={{ marginTop: '6px' }}
                                    role='alert'
                                >
                                    {this.state.errorOne}
                                </div>
                                )}
                            </div>
                        </div>
                    </div>

                    <div className='row'>
                    <div className='col-6'>
                    <div
                        id='userRoles'
                        className='card'
                        style={{
                        gridColumnStart: '1',
                        gridColumnEnd: '3',
                        gridRow: '3 / 3',
                        margin: '16px',
                        }}
                    >
                        <div className='card-header'>Identifiers</div>
                        <div className='card-body pt-0'>
                        <div className='row'>
                        {this.state.currentView === 'FieldIdentifiers' && (
                        <>
                            <div className='card-body'>
                                <div className=''>
                            
                                    {this.state.fieldDataSet.filter(a => a.field.id === this.state.currentField.id)[0].identifiers.map((fieldIdentifier) => (
                                    // {this.state.fieldIdentifiers.map((fieldIdentifier) => (
                                        <div className='row' key={`fieldIdentifier-${fieldIdentifier.id}`}>
                                            <div className='col-4 pt-2'>
                                                <input value={fieldIdentifier.identifierText}
                                                        key={fieldIdentifier.id}
                                                        onChange={event => this.editFieldIdentifierName(event.target.value, fieldIdentifier)}
                                                        className='form-control'
                                                        >
                                                </input>
                                            </div>
                                            <div className='col-4 pt-2'>
                                                <span>
                                                    <button
                                                        className='btn btn-danger'
                                                        onClick={ () => {this.deleteFieldIdentifier(fieldIdentifier) }}
                                                        >
                                                        Delete
                                                    </button>
                                                </span>
                                            </div>
                                        </div>
                                    ))}
                                    <div className='row'>
                                        <div className='col-4 pt-2'>
                                            <input value={this.state.newFieldIdentifierValue}
                                                        onChange={this.editNewFieldIdentifier}
                                                        className='form-control'
                                                        ></input>
                                        </div>
                                        <div className='col-4 pt-2'>
                                            <button
                                                className={
                                                    this.state.newFieldIdentifierValue === ''
                                                      ? 'btn btn-success disabled'
                                                      : 'btn btn-success'
                                                  }
                                                onClick={ () => {this.addNewFieldIdentifier() }}
                                                >
                                                Add
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    )}
                         
                        </div>
                        </div>
                    </div>
                    </div>

                    <div className='col-6'>
                    <div
                        id='userRoles'
                        className='card'
                        style={{
                        gridColumnStart: '1',
                        gridColumnEnd: '3',
                        gridRow: '3 / 3',
                        margin: '16px',
                        }}
                    >
                        <div className='card-header'>Patterns</div>
                        <div className='card-body pt-0'>
                        <div className='row'>
                            
                        <>
                            <div className='card-body'>
                                <div className=''>
                                    {/* {this.state.patterns.map((pattern) => ( */}
                                    {this.state.fieldDataSet.filter(a => a.field.id === this.state.currentField.id)[0].patterns.map((pattern) => (
                                        <div className='row' key={`fieldPattern-${pattern.id}`}>
                                            <div className='col-4 pt-2'>
                                                <input value={pattern.pattern}
                                                        key={pattern.id}
                                                        onChange={event => this.editFieldPatternText(event.target.value, pattern)}
                                                        className='form-control'
                                                        ></input>
                                            </div>
                                            <div className='col-4 pt-2'>
                                                <span>
                                                    <button
                                                        className='btn btn-danger'
                                                        onClick={ () => {this.deleteFieldPattern(pattern) }}
                                                        >
                                                        Delete
                                                    </button>
                                                </span>
                                            </div>
                                        </div>
                                    ))}
                                    <div className='row'>
                                        <div className='col-4 pt-2'>
                                            <input value={this.state.newPatternValue}
                                                        onChange={this.editNewPattern}
                                                        className='form-control'
                                                        ></input>
                                        </div>
                                        <div className='col-4 pt-2'>
                                            <button
                                                className={
                                                    this.state.newPatternValue === ''
                                                      ? 'btn btn-success disabled'
                                                      : 'btn btn-success'
                                                  }
                                                onClick={ () => {this.addNewPattern() }}
                                                >
                                                Add
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    
                         
                        </div>
                        </div>
                    </div>
                    </div>
                    </div>

 
 
    
                </div>
                )}

              
        </div>


        </div>
      </div>
    );
  }
}

export default AutoOcrDocumentTypeView;
