import dispatcher from '../Dispatcher';
import actionTypes from './ActionTypes';
import axios from 'axios';
import * as Globals from '../Globals';
import { PostAlert } from './AlertActions';

export function GetQueueTree() {
  axios
    .get(`${Globals.baseUrl}/Inbox/GetQueueTree`, {
      headers: {
        'content-type': 'application/json; charset=utf-8',
        Authorization: `Bearer ${Globals.getJwtToken()}`,
      },
    })
    .then((res) => {
      if (res.status === 200) {
        dispatcher.dispatch({
          actionType: actionTypes.InboxGetQueueTree,
          Result: res.data,
        });
      } else if (res.status === 400 || res.status === 401) {
        console.log('error: 401');
      }
    })
    .catch((res) => {});
}

export function GetErrorTree() {
    axios
      .get(`${Globals.baseUrl}/Inbox/GetErrorTree`, {
        headers: {
          'content-type': 'application/json; charset=utf-8',
          Authorization: `Bearer ${Globals.getJwtToken()}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          dispatcher.dispatch({
            actionType: actionTypes.InboxGetErrorTree,
            Result: res.data,
          });
        } else if (res.status === 400 || res.status === 401) {
          console.log('error: 401');
        }
      })
      .catch((res) => {});
  }


  export function GetDeletedTree() {
    axios
      .get(`${Globals.baseUrl}/Inbox/GetDeletedTree`, {
        headers: {
          'content-type': 'application/json; charset=utf-8',
          Authorization: `Bearer ${Globals.getJwtToken()}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          dispatcher.dispatch({
            actionType: actionTypes.InboxGetDeletedTree,
            Result: res.data,
          });
        } else if (res.status === 400 || res.status === 401) {
          console.log('error: 401');
        }
      })
      .catch((res) => {});
  }


  export function GetImageReviewTree() {
    axios
      .get(`${Globals.baseUrl}/Inbox/GetImageReviewTree`, {
        headers: {
          'content-type': 'application/json; charset=utf-8',
          Authorization: `Bearer ${Globals.getJwtToken()}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          dispatcher.dispatch({
            actionType: actionTypes.InboxGetImageReviewTree,
            Result: res.data,
          });
        } else if (res.status === 400 || res.status === 401) {
          console.log('error: 401');
        }
      })
      .catch((res) => {});
  }

  export function GetFilterTree() {
    axios
      .get(`${Globals.baseUrl}/Inbox/GetFilterTree`, {
        headers: {
          'content-type': 'application/json; charset=utf-8',
          Authorization: `Bearer ${Globals.getJwtToken()}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          dispatcher.dispatch({
            actionType: actionTypes.InboxGetFilterTree,
            Result: res.data,
          });
        } else if (res.status === 400 || res.status === 401) {
          console.log('error: 401');
        }
      })
      .catch((res) => {});
  }


export function GetInboxStep(id, bpId, type, column, order, offset) {
  axios
    .get(`${Globals.baseUrl}/Inbox/GetInboxStep/${id}/${bpId}/${type}/${column}/${order}/${offset}`, {
      headers: {
        'content-type': 'application/json; charset=utf-8',
        Authorization: `Bearer ${Globals.getJwtToken()}`,
      },
    })
    .then((res) => {
      if (res.status === 200) {
        dispatcher.dispatch({
          actionType: actionTypes.InboxGetStep,
          Result: res.data,
        });
      } else if (res.status === 400 || res.status === 401) {
        console.log('error: 401');
      }
    })
    .catch((res) => {});
}

export function GetInboxBp(bpId, type, column, order, offset) {
  axios
    .get(`${Globals.baseUrl}/Inbox/GetInboxBp/${bpId}/${type}/${column}/${order}/${offset}`, {
      headers: {
        'content-type': 'application/json; charset=utf-8',
        Authorization: `Bearer ${Globals.getJwtToken()}`,
      },
    })
    .then((res) => {
      if (res.status === 200) {
        dispatcher.dispatch({
          actionType: actionTypes.InboxGetBp,
          Result: res.data,
        });
      } else if (res.status === 400 || res.status === 401) {
        console.log('error: 401');
      }
    })
    .catch((res) => {});
}

export function GetWorkflowStepsForBPs(bpList) {
    axios
      .post(
        `${Globals.baseUrl}/Inbox/GetWorkflowStepsForBPs`,
        {
          bpList: bpList,
        },
        {
          headers: {
            'content-type': 'application/json; charset=utf-8',
            Authorization: `Bearer ${Globals.getJwtToken()}`,
          },
        }
      )
      .then((res) => {
        if (res.status === 200) {
            dispatcher.dispatch({
                actionType: actionTypes.GetWorkflowStepsForBPs,
                Result: res.data,
              });
          //let result = res.data;
          //PostAlert('success', 'User settings updated');
        }
      })
      .catch((res) => {
        console.log(res);
      });
  }

  export function GetFieldsByFormType(formTypeId) {
    axios
      .get(`${Globals.baseUrl}/Inbox/GetFieldsByFormType/${formTypeId}`, {
        headers: {
          'content-type': 'application/json; charset=utf-8',
          Authorization: `Bearer ${Globals.getJwtToken()}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          dispatcher.dispatch({
            actionType: actionTypes.GetFieldsByFormType,
            Result: res.data,
          });
        } else if (res.status === 400 || res.status === 401) {
          //Logout();
        }
      })
      .catch((res) => {
        console.log('error');
        console.log(res);
        // Logout();
      });
  }

  export function GetHeaderFieldsByFormType(formTypeId) {
    axios
      .get(`${Globals.baseUrl}/Inbox/GetHeaderFieldsByFormType/${formTypeId}`, {
        headers: {
          'content-type': 'application/json; charset=utf-8',
          Authorization: `Bearer ${Globals.getJwtToken()}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          dispatcher.dispatch({
            actionType: actionTypes.GetHeaderFieldsByFormType,
            Result: res.data,
          });
        } else if (res.status === 400 || res.status === 401) {
          //Logout();
        }
      })
      .catch((res) => {
        console.log('error');
        console.log(res);
        // Logout();
      });
  }


  export function UpdateFilter(id, bps, formType, workflowSteps, field, name, onlyMyQueue, reverseSort) {    
    axios
      .post(
        `${Globals.baseUrl}/Inbox/Update`,
        {
            id: id,
            bps: bps,
            formType: formType,
            workflowSteps: workflowSteps,
            field: field,
            name: name,
            onlyMyQueue: onlyMyQueue,
            reverseSort: reverseSort,
        },
        {
          headers: {
            'content-type': 'application/json; charset=utf-8',
            Authorization: `Bearer ${Globals.getJwtToken()}`,
          },
        }
      )
      .then((res) => {
        if (res.status === 200) {
            dispatcher.dispatch({
                actionType: actionTypes.InboxUpdateFilter,
                Result: res.data,
              });
          //let result = res.data;
          PostAlert('success', 'Filter updated');
        }
      })
      .catch((res) => {
        console.log(res);
      });
  }


  export function DeleteFilter(id) {
    axios
      .post(
        `${Globals.baseUrl}/Inbox/DeleteFilter`,
        { filterId: id },
        {
          headers: {
            'content-type': 'application/json; charset=utf-8',
            Authorization: `Bearer ${Globals.getJwtToken()}`,
          },
        }
      )
      .then((res) => {
        if (res.status === 200) {
          dispatcher.dispatch({
            actionType: actionTypes.InboxDeleteFilter,
            Result: res.data,
          });
        } else if (res.status === 400 || res.status === 401) {
          console.log('error: 401');
        }
      })
      .catch((res) => {
        console.log('Delete filter error');
      });
  }


  export function GetFilters() {
    axios
      .get(`${Globals.baseUrl}/Inbox/GetFilters`, {
        headers: {
          'content-type': 'application/json; charset=utf-8',
          Authorization: `Bearer ${Globals.getJwtToken()}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          dispatcher.dispatch({
            actionType: actionTypes.InboxGetFilters,
            Result: res.data,
          });
        } else if (res.status === 400 || res.status === 401) {
          console.log('error: 401');
        }
      })
      .catch((res) => {});
  }

  export function GetFilterData(filterId) {
    axios
      .get(`${Globals.baseUrl}/Inbox/GetFilterData/${filterId}`, {
        headers: {
          'content-type': 'application/json; charset=utf-8',
          Authorization: `Bearer ${Globals.getJwtToken()}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          dispatcher.dispatch({
            actionType: actionTypes.GetFilterData,
            Result: res.data,
          });
        } else if (res.status === 400 || res.status === 401) {
          //Logout();
        }
      })
      .catch((res) => {
        console.log('error');
        console.log(res);
        // Logout();
      });
  }