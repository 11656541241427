import * as React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';

import actionTypes from '../../../actions/ActionTypes';
import InboxStore from '../../../stores/InboxStore';
import { GetQueueTree, GetErrorTree, GetDeletedTree, GetImageReviewTree, GetFilters } from '../../../actions/InboxActions';
import { createBrowserHistory } from 'history';

const customHistory = createBrowserHistory();

class FiltersSidebar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      queueNodeTree: [],
      filterNodeTree: [],
      errorNodeTree: [],
      deletedNodeTree: [],
      imageReviewNodeTree: [],
      h: window.innerHeight - 142,
      displayMyQueues: false,
      displayMyFilters: false,
      displayErrors: false,
      displayDeleted: false,
      displayImageReview: false,
      filters: [],
    };

     this.onReceiveInboxChange = this.onReceiveInboxChange.bind(this);
     this.toggleNode = this.toggleNode.bind(this);
     this.resizeH = this.resizeH.bind(this);
     this.displayMyQueues = this.displayMyQueues.bind(this);
     this.displayMyFilters = this.displayMyFilters.bind(this);
     this.displayErrors = this.displayErrors.bind(this);
     this.displayDeleted = this.displayDeleted.bind(this);
     this.displayImageReview = this.displayImageReview.bind(this);
     this.selectFilter = this.selectFilter.bind(this);
  }

  componentDidMount() {
     InboxStore.addChangeListener(this.onReceiveInboxChange);
     GetQueueTree();
     GetErrorTree();
     GetDeletedTree();
     GetImageReviewTree();
     GetFilters();
     this.resizeH();
     window.addEventListener('resize',this.resizeH);
   }

  componentWillUnmount() {
    InboxStore.removeChangeListener(this.onReceiveInboxChange);
    window.removeEventListener('resize',this.resizeH);
  }

  onReceiveInboxChange() {
    let lastChange = InboxStore.lastChange();

    if (lastChange === actionTypes.InboxGetQueueTree) {
       let adTree = InboxStore.getQueueTree();
       var settings = this.getADSettings();
       if(settings !== null){

        settings.ouOpen.forEach((id) => {
            adTree.forEach((ou) => {
                if(ou.type === 'ou'){
                    ou.isOpen = true;
                }
            });
        });



       this.setState({
        queueNodeTree: adTree
       });
     }
    }

    if (lastChange === actionTypes.InboxGetErrorTree) {
        let adTree = InboxStore.getErrorTree();
        settings = this.getADSettings();
        if(settings !== null){
 
        //  settings.fav.forEach((id) => {
        //      var node1 = adTree.find(n1 => n1.type === 'bp' && n1.id === id);
        //      if(node1)
        //      {
        //          if(settings.favOpen.indexOf(node1.id) >-1) {
        //              node1.isOpen = true;
        //          }
 
        //      }
        //      else {
        //          // search children
        //          adTree.forEach((ou) => {
        //              if(ou.type === 'ou'){
        //                  var node2 = ou.children.find(n2 => n2.type === 'bp' && n2.id === id);
        //                  if(node2)
        //                  {
        //                      if(settings.favOpen.indexOf(node2.id) >-1) {
        //                          node2.isOpen = true;
        //                      }
        //                  }
        //              }
        //          });
        //      }
        //  });
 
         settings.ouOpen.forEach((id) => {
             adTree.forEach((ou) => {
                 if(ou.type === 'ou'){
                     ou.isOpen = true;
                 }
             });
         });
 
 
 
        this.setState({
         errorNodeTree: adTree
        });
      }
     }

     if (lastChange === actionTypes.InboxGetDeletedTree) {
        let adTree = InboxStore.getDeletedTree();
        settings = this.getADSettings();
        if(settings !== null){
 
        //  settings.fav.forEach((id) => {
        //      var node1 = adTree.find(n1 => n1.type === 'bp' && n1.id === id);
        //      if(node1)
        //      {
        //          if(settings.favOpen.indexOf(node1.id) >-1) {
        //              node1.isOpen = true;
        //          } 
        //      }
        //      else {
        //          // search children
        //          adTree.forEach((ou) => {
        //              if(ou.type === 'ou'){
        //                  var node2 = ou.children.find(n2 => n2.type === 'bp' && n2.id === id);
        //                  if(node2)
        //                  {
        //                      if(settings.favOpen.indexOf(node2.id) >-1) {
        //                          node2.isOpen = true;
        //                      }
        //                  }
        //              }
        //          });
        //      }
        //  });
 
         settings.ouOpen.forEach((id) => {
             adTree.forEach((ou) => {
                 if(ou.type === 'ou'){
                     ou.isOpen = true;
                 }
             });
         });
 
 
 
        this.setState({
         deletedNodeTree: adTree
        });
      }
     }

     if (lastChange === actionTypes.InboxGetImageReviewTree) {
        let adTree = InboxStore.getImageReviewTree();
        settings = this.getADSettings();
        if(settings !== null){
 
        //  settings.fav.forEach((id) => {
        //      var node1 = adTree.find(n1 => n1.type === 'bp' && n1.id === id);
        //      if(node1)
        //      {
 
        //          if(settings.favOpen.indexOf(node1.id) >-1) {
        //              node1.isOpen = true;
        //          }
 
        //          favTree.push(node1);
 
        //      }
        //      else {
        //          // search children
        //          adTree.forEach((ou) => {
        //              if(ou.type === 'ou'){
        //                  var node2 = ou.children.find(n2 => n2.type === 'bp' && n2.id === id);
        //                  if(node2)
        //                  {
        //                      node2.isFav = true;
        //                      if(settings.favOpen.indexOf(node2.id) >-1) {
        //                          node2.isOpen = true;
        //                      }
        //                      favTree.push(node2);
        //                  }
        //              }
        //          });
        //      }
        //  });
 
         settings.ouOpen.forEach((id) => {
             adTree.forEach((ou) => {
                 if(ou.type === 'ou'){
                     ou.isOpen = true;
                 }
             });
         });
 
 
 
        this.setState({
         imageReviewNodeTree: adTree
        });
      }
     }

     if (lastChange === actionTypes.InboxGetFilters) {
        var filters = InboxStore.getFilters();
        this.setState({filters: filters});
    }

    if (lastChange === actionTypes.InboxDeleteFilter) {
        let link = document.getElementById('filterHome');
        link.click();
    }

    if (lastChange === actionTypes.InboxUpdateFilter) {
        var filterId = InboxStore.getFilterId();
        customHistory.push(`/Filters/` + filterId);
        GetFilters();
    }
  }

  refreshNode(node) {

  }


  selectFilter(id) {
    let link = document.getElementById(`/Filters/` + id);
    link.click();
}

  resizeH() {
    this.setState({h: window.innerHeight - 142});

    }

    copyNode(node) {
        var newNode = {
            id: node.id,
            type: node.type,
            name: node.name,
            isOpen: false,
            isFav: node.isFav,
            count: node.count,
            children: [],
        }
        node.children.forEach((n) => {
            newNode.children.push(this.copyNode(n));
        });
        return newNode;
    }

    getADSettings(){
        var settings= JSON.parse(localStorage.getItem('ADSettings'));

        if(settings === null){
            settings = {
                fav:[],
                favOpen:[],
                ouOpen:[],
                bpOpen:[],
            }
        }
        return settings;
    }

    toggleNode(node, type) {

        if (node.type === 'bp') {
            this.props.LoadBPClick(node.id);
        }
        var settings = this.getADSettings();
        if(node.type === 'bp') {
            var idx = settings.bpOpen.indexOf(node.id);
            if(idx === -1 && !node.isOpen)
            {
                settings.bpOpen.push(node.id);
            } else if(idx > -1 && node.isOpen) {
                settings.bpOpen.splice(idx, 1);
            }
        }
        if(node.type === 'ou') {
            var idx = settings.ouOpen.indexOf(node.id);
            if(idx === -1 && !node.isOpen)
            {
                settings.ouOpen.push(node.id);
            } else if(idx > -1 && node.isOpen) {
                settings.ouOpen.splice(idx, 1);
            }
        }
        localStorage.setItem('ADSettings', JSON.stringify(settings));

    var tree = undefined;
    switch(type){
        case 'queues':
            tree = this.state.queueNodeTree;
            break;
        case 'filters':
            tree = this.state.filterNodeTree;
            break;
        case 'errors':
            tree = this.state.errorNodeTree;
            break;
        case 'deleted':
            tree = this.state.deletedNodeTree;
            break;
        case 'imageReview':
            tree = this.state.imageReviewNodeTree;
            break;
        default:
            break;
    }

    var node1 = tree.find(n1 => n1.type === node.type && n1.id === node.id);
    if(node1)
    {

        node1.isOpen = !node1.isOpen;
    }
    else {
        // search children
        tree.forEach((ou) => {
            if(ou.type === 'ou'){
                var node2 = ou.children.find(n2 => n2.type === node.type && n2.id === node.id);
                if(node2)
                {
                    node2.isOpen = !node2.isOpen;
                }
            }
        });
    }

    switch(type){
        case 'queues':
            this.setState({queueNodeTree: tree});
            break;
        case 'filters':
            this.setState({filterNodeTree: tree});
            break;
        case 'errors':
            this.setState({errorNodeTree: tree});
            break;
        case 'deleted':
            this.setState({deletedNodeTree: tree});
            break;
        case 'imageReview':
            this.setState({imageReviewNodeTree: tree});
            break;
        default:
            break;
    }
    this.setState({nodeTree: tree});
  }

  nodeMouseUp(e) {

  }

  displayMyQueues(e){
    if (this.state.displayMyQueues){
        this.setState({displayMyQueues: false});
    }
    else{
        this.setState({displayMyQueues: true});
    }
  }

  displayMyFilters(e){
    if (this.state.displayMyFilters){
        this.setState({displayMyFilters: false});
    }
    else{
        this.setState({displayMyFilters: true});
    }
  }

  displayErrors(e){
    if (this.state.displayErrors){
        this.setState({displayErrors: false});
    }
    else{
        this.setState({displayErrors: true});
    }
  }

  displayDeleted(e){
    if (this.state.displayDeleted){
        this.setState({displayDeleted: false});
    }
    else{
        this.setState({displayDeleted: true});
    }
  }

  displayImageReview(e){
    if (this.state.displayImageReview){
        this.setState({displayImageReview: false});
    }
    else{
        this.setState({displayImageReview: true});
    }
  }


  render() {

    return (
      <div
        className='d-flex flex-column pt-3 ps-3 text-white bg-dark'
        style={{ maxHeight: '100%', width: '320px'  }}
      >
        <Link
          to='/ActiveDocuments'
          className='d-flex align-items-center mb-3 mb-md-0 me-md-auto text-white text-decoration-none'
        >
            <Link
              id='filterHome'
              className='nav-link'
              to={`/Filters`}
              style={{ display: 'none' }}
            >
            </Link>
          <span className='fs-4'>
            <FontAwesomeIcon style={{ color: '#E2D45B'}} icon={solid('filter')} />
            {'   '}
            Filters
          </span>
        </Link>
        <hr />
        <div className='pe-3 coolScroll' style={{maxHeight: `${this.state.h}px`, overflow: 'auto'}}>
            <ul className='list-group active-documents'>
                <Link to='/Inbox' className='d-flex align-items-center mb-3 mb-md-0 me-md-auto text-white text-decoration-none'>
                    <li className='list-group-item d-flex justify-content-between aligh-items-start'>
                        <div className='me-auto'>
                            <FontAwesomeIcon icon={solid('arrow-left')}/>{' '}<strong>Back to Inbox</strong>
                        </div>
                    </li>
                </Link>
            <hr />
                    <li className='list-group-item d-flex justify-content-between aligh-items-start'>
                        <Link id='/Filters/0' to='/Filters/0' className='d-flex align-items-center mb-3 mb-md-0 me-md-auto text-white text-decoration-none'
                            onClick={() => this.selectFilter(0)}
                            >
                            <div className='me-auto'>
                                <FontAwesomeIcon style={{color: 'green'}} icon={solid('plus')}/>{' '}<strong>Create New Filter</strong>
                            </div>
                        </Link>
                    </li>
                
            <hr/>
          
                    {this.state.filters.map((filter) => (
                        <div>
                            <Link //link
                                className='d-flex align-items-center mb-3 mb-md-0 me-md-auto text-white text-decoration-none'
                                to={'/Filters/' + filter.id}
                                id={'/Filters/' + filter.id}
                                key={filter.id}
                                onClick={() => this.selectFilter(filter.id)}
                                style={{ cursor: 'Pointer', wordWrap: 'break-word' }}
                            >
                                {filter.name}
                            </Link>{' '}
                            {/* link */}
                        </div>
                    ))}
    
            </ul>
        </div>

      </div>
    );
  }
}

export default FiltersSidebar;
