import * as React from 'react';
import Dropzone from 'react-dropzone';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import TemplateImageViewer from './TemplateImageViewer';
import {
  CreateSample,
  CreateTemplate,
  GetTemplate,
  GetSamples,
  GetSample,
  CheckForData,
  UpdateSampleName,
  UpdateTemplate,
  GetTemplateHeaderFields,
  GetTemplateTables,
  DeleteSample,
  GetSampleFromDocument,
  GetSampleInfo,
  DownloadSamplePDF,
  GetDocSample,
  GetDocSampleInfo,
  ConvertToSample,
} from '../../../actions/TemplateActions';
import TemplateStore from '../../../stores/TemplateStore';
import NavStore from '../../../stores/NavStore';
import { AcceptNav } from '../../../actions/NavActions';
import actionTypes from '../../../actions/ActionTypes';

class TemplateEditor extends React.Component {
  constructor() {
    // state
    super();
    this.interval = undefined;
    this.state = {
      imageviewerHeight: window.innerHeight - 137,
      imageviewerWidth: window.innerWidth - 480,
      currentTemplate: {
        templateZones: [],
        isActive: false,
      },
      headers: [],
      tables: [],
      currentTableFields: [],
      captureZoneMenu: 'open',
      identifierZoneMenu: 'closed',
      splitZoneMenu: 'closed',
      currentZone: {
        screenId: 0,
      },
      currentColumn: {
        screenId: 0,
      },
      showSamples: false,
      showSampleDialogue: false,
      editingName: false,
      currentDrop: {
        imageCount: 1,
        ocrType: 2,
        ocrQuality: 1,
        readBC: false,
        drop: undefined,
        dropName: '',
        includeLastPage: false,
      },
      samples: [],
      currentImage: { pageId: 0 },
      currentSample: { pages: [] },
      currentSamplePage: {
        image: {
          fullBytes: '',
          height: 100,
          width: 200,
          pageId: 0,
        },
        pageId: 0,
      },
      currentSampleInfo: {},
      showSaveAs: false,
      showSaveAsDialog: false,
      isCreatingSample: false,
      isDirty: false,
      showCapture: false,
      showCaptureText: false,
      showCaptureOCR: false,
      captureLayer: '',
      showZoneSelect: false,
      waitingOnOCR: false,
      newZone: undefined,
      showAdvanceSettings: true,
      showSampleInfo: false,
      sampleNameEdit: '',
      templateIdentifies: undefined,
      showOCRDescriptions: false,
      currentOcrDescription:
        'Smart OCR - TrinDocs uses text data encoded into digitally born documents to increase reading speed. This is recommended for general use with a wide variety of documents.',
      newSampleHeight: '400px',
      showSaveAsDialog: false,
      newTemplateName: '',
      newFT: {},
      formTypes: [],
      saveAsStatus: {
        status: 'none',
        newTemplateId: 0,
        errorMessage: '',
      },
      showTables: false,
      tableCapture: [],
      showNav: false,
      navDestination: '',
      inspectDocId: 0,
      docSample: undefined,
      docSampleId: 0,
    };

    this.onWindowResize = this.onWindowResize.bind(this);
    this.onTemplateChange = this.onTemplateChange.bind(this);
    this.toggleShowSample = this.toggleShowSample.bind(this);
    this.editDropImageCount = this.editDropImageCount.bind(this);
    this.onDrop = this.onDrop.bind(this);
    this.removeDrop = this.removeDrop.bind(this);
    this.editOCRType = this.editOCRType.bind(this);
    this.editBarcodeSample = this.editBarcodeSample.bind(this);
    this.onSampleClose = this.onSampleClose.bind(this);
    this.onCreateSample = this.onCreateSample.bind(this);
    this.onSampleSubmit = this.onSampleSubmit.bind(this);
    this.checkForUpdate = this.checkForUpdate.bind(this);
    this.onSwitchSample = this.onSwitchSample.bind(this);
    this.toggleEditName = this.toggleEditName.bind(this);
    this.editTemplateName = this.editTemplateName.bind(this);
    this.toggleSaveAs = this.toggleSaveAs.bind(this);
    this.toggleActive = this.toggleActive.bind(this);
    this.updateZoneGeometry = this.updateZoneGeometry.bind(this);
    this.toggleShowCapture = this.toggleShowCapture.bind(this);
    this.toggleShowText = this.toggleShowText.bind(this);
    this.toggleShowOCR = this.toggleShowOCR.bind(this);
    this.initializeNewZone = this.initializeNewZone.bind(this);
    this.onSelectZoneClose = this.onSelectZoneClose.bind(this);
    this.onTimerClick = this.onTimerClick.bind(this);
    this.createZone = this.createZone.bind(this);
    this.setActiveZone = this.setActiveZone.bind(this);
    this.calculateZoneValue = this.calculateZoneValue.bind(this);
    this.editZoneValue = this.editZoneValue.bind(this);
    this.toggleAdvance = this.toggleAdvance.bind(this);
    this.setActiveImage = this.setActiveImage.bind(this);
    this.updateAllZones = this.updateAllZones.bind(this);
    this.editSampleName = this.editSampleName.bind(this);
    this.showSampleInfo = this.showSampleInfo.bind(this);
    this.closeSampleInfo = this.closeSampleInfo.bind(this);
    this.deleteSample = this.deleteSample.bind(this);
    this.updateTemplate = this.updateTemplate.bind(this);
    this.deleteZone = this.deleteZone.bind(this);
    this.lookupFieldValues = this.lookupFieldValues.bind(this);
    this.splitColumn = this.splitColumn.bind(this);
    this.deleteColumn = this.deleteColumn.bind(this);
    this.setActiveColumn = this.setActiveColumn.bind(this);
    this.editColumnValue = this.editColumnValue.bind(this);
    this.toggleOCRDescriptions = this.toggleOCRDescriptions.bind(this);
    this.editOCRQuality = this.editOCRQuality.bind(this);
    this.editIncludeLastPage = this.editIncludeLastPage.bind(this);
    this.showSaveTemplateAs = this.showSaveTemplateAs.bind(this);
    this.editNewTemplateName = this.editNewTemplateName.bind(this);
    this.editNewFT = this.editNewFT.bind(this);
    this.createNewTemplate = this.createNewTemplate.bind(this);
    this.onShowTables = this.onShowTables.bind(this);
    this.onHideTables = this.onHideTables.bind(this);
    this.onNavRequest = this.onNavRequest.bind(this);
    this.onSaveAndNav = this.onSaveAndNav.bind(this);
    this.onStay = this.onStay.bind(this);
    this.onDiscardAndNav = this.onDiscardAndNav.bind(this);
    this.updateTableVals = this.updateTableVals.bind(this);
    this.downloadSample = this.downloadSample.bind(this);
    this.onSwitchDocSample = this.onSwitchDocSample.bind(this);
    this.getZoneWords = this.getZoneWords.bind(this);
    this.calculateAnchor2 = this.calculateAnchor2.bind(this);
    this.showDocSampleInfo = this.showDocSampleInfo.bind(this);
    this.convertToSample = this.convertToSample.bind(this);

  }

  componentDidMount() {
    window.onresize = this.onWindowResize;
    TemplateStore.addChangeListener(this.onTemplateChange);
    NavStore.addChangeListener(this.onNavRequest);
    this.interval = setInterval(this.onTimerClick, 1500);
    this.checkForUpdate();
    document.title = 'TrinDocs - Template Editor';
  }
  componentWillUnmount() {
    console.log('TemplateUnmount');
    TemplateStore.removeChangeListener(this.onTemplateChange);
    NavStore.removeChangeListener(this.onNavRequest);
    clearInterval(this.interval);
  }
  componentDidUpdate() {
    this.checkForUpdate();
  }

  checkForUpdate() {
    let loc = window.location.pathname;
    let pathParts = loc.split('/');
    let tidx = pathParts.indexOf('TemplateEditor');
    let templateId = parseInt(pathParts[tidx + 1], 10);
    let docID = 0;
    if(pathParts.length == tidx + 3) {
      docID = parseInt(pathParts[tidx + 2], 10);
    }

    if (
      templateId !== 0 &&
      (this.state.currentTemplate === undefined ||
        this.state.currentTemplate.templateId !== templateId)
    ) {
      console.log(templateId);
      console.log(this.state.currentTemplate.templateId);
      GetTemplate(templateId);
      if(docID !== 0)
      {
        console.log(docID);
        //this.setState({sampleDocId: docID});
        GetDocSample(docID);
      }
    }


  }

  onNavRequest() {
    if (this.state.isDirty) {
      let dest = NavStore.getNavDestination();
      this.setState({ showNav: true, navDestination: dest });
    } else {
      AcceptNav();
    }
  }

  onWindowResize(e) {
    //setViewWidth
    this.setState({
      imageviewerHeight: window.innerHeight - 137,
      imageviewerWidth: window.innerWidth - 480,
    });
  }

  onTimerClick() {
    if (this.state.waitingOnOCR) {
      CheckForData(this.state.currentSample.sampleId);
    }
  }

  toggleShowSample() {
    this.setState({ showSamples: !this.state.showSamples });
  }

  onTemplateChange() {
    var lastChange = TemplateStore.getLastChange();
    if (lastChange === actionTypes.TemplateCreateSample) {
      var latestSamples = TemplateStore.getSamples();
      var currentSamp = TemplateStore.getCurrentSample();
      if (currentSamp !== undefined) {
        this.setState({ samples: latestSamples });
        this.setState({
          currentSample: currentSamp,
          currentSamplePage: currentSamp.pages[0],
        });
        this.setState({
          isCreatingSample: false,
          showSampleDialogue: false,
          waitingOnOCR: true,
        });
      } else {
        this.setState({ isCreatingSample: false, showSampleDialogue: false });
      }
    }
    if (lastChange === actionTypes.TemplateUpdate) {
      let loc = window.location.pathname;
      let pathParts = loc.split('/');
      let templateId = parseInt(pathParts[pathParts.length - 1], 10);
      GetTemplate(templateId);

      this.setState({ isDirty: false, showSaveAsDialog: false });
    }
    if (lastChange === actionTypes.TempalteGetOne) {
      console.log('TemplateGetOne');
      let template = TemplateStore.getCurrentTemplate();

      template.templateZones.forEach((zone) => {
        console.log(zone);
        if(zone.regExBehavior === null) {
          zone.regExBehavior = 'matchingWords';
        }
        if (zone.zoneType === 3) {
          // fix column layouts
          let runningLeft = 0;

          zone.rows = [];
          zone.columns.forEach((col) => {
            col.height = zone.height;
            col.x = runningLeft;
            col.y = 0;
            col.screenId = col.columnId;
            runningLeft += col.width;
          });
        }
      });
      let updateSamples = false;
      if(this.state.currentTemplate.templateId !== template.templateId)
        updateSamples = true;
      GetTemplateHeaderFields(template.templateId);
      GetTemplateTables(template.templateId);
      this.setState({
        currentTemplate: template,
        isDirty: false,
        showSaveAsDialog: false,
      });

      if(updateSamples)
      {

        GetSamples(template.templateId);
      } else {
        this.updateAllZones();
        this.lookupFieldValues();
      }
      console.log(this.state.headers);

      if (window.location.search !== '') {
        let idx = window.location.search.indexOf('inspect=');
        if (idx > -1) {

          let docIdStr = window.location.search.substring(idx + 8);
          this.setState({ inspectDocId: parseInt(docIdStr, 10) });
          GetSampleFromDocument(docIdStr);
        }
      }
    }
    if (lastChange === actionTypes.TemplateDeleteSample) {
      let template = TemplateStore.getCurrentTemplate();
      GetSamples(template.templateId);
      this.setState({ showSampleInfo: false });
    }
    if (lastChange === actionTypes.TemplateGetSamples) {
      let samples = TemplateStore.getSamples();

      this.setState({ samples: samples });
      let loc = window.location.pathname;
      let pathParts = loc.split('/');
      let tidx = pathParts.indexOf('TemplateEditor');
      let templateId = parseInt(pathParts[tidx + 1], 10);
      let docID = 0;
      if(pathParts.length == tidx + 3) {
        docID = parseInt(pathParts[tidx + 2], 10);
      }
      if (samples.length > 0 && this.state.inspectDocId === 0 && docID === 0) {
        GetSample(samples[0].sampleId);
        //this.setState({currentSample: samples[0]});
        //this.setState({currentSamplePage: samples[0].pages[0]});
      } else if (docID !== 0) {
        // GetDocSample();
      }
    }
    if (lastChange === actionTypes.TemplateGetSample) {
      currentSamp = TemplateStore.getCurrentSample();
      currentSamp.pages.forEach((p) => {
        if (p.pageData !== null) {
          if (p.pageData.ocrData === null) {

              let od = {
                pageNum: p.pageData.textData.pageNum,
                requiresRotation: p.pageData.requiresRotation,
                rotationAngle: p.pageData.rotationAngle,
                words: [],
              };
              p.pageData.ocrData = od;

          }
        }
      });

      currentSamp.isOCRing = false;
      let ocrId = 1;
      currentSamp.pages.forEach((p) => {
        if (p.pageData !== null) {
          p.pageData.ocrData.words.forEach((w) => {
            w.ocrId = ocrId;
            w.active = false;
            ocrId++;
          });
          p.pageData.textData.words.forEach((w) => {
            w.ocrId = ocrId;
            w.active = false;
            ocrId++;
          });
        }
      });
      this.setState({
        currentSample: currentSamp,
        currentSamplePage: currentSamp.pages[0],
        showSampleInfo: false,
      });

      setTimeout(() => {
        this.updateAllZones();
        this.lookupFieldValues();
      }, 100);
      this.setState({ waitingOnOCR: false });

      let samples = this.state.samples;
      let samp = samples.find((s) => s.sampleId === currentSamp.sampleId);
      if (samp) {
        if (samp.sampleName !== currentSamp.sampleName) {
          samp.sampleName = currentSamp.sampleName;
          this.setState({ samples: samples });
        }
      }
    }
    if (lastChange === actionTypes.TemplateDocSample) {
      currentSamp = TemplateStore.getDocSample();
      currentSamp[0].pages.forEach((p) => {
        if (p.pageData !== null) {
          if (p.pageData.ocrData === null) {

              let od = {
                pageNum: p.pageData.textData.pageNum,
                requiresRotation: p.pageData.requiresRotation,
                rotationAngle: p.pageData.rotationAngle,
                words: [],
              };
              p.pageData.ocrData = od;

          }
        }
      });
      currentSamp[0].isOCRing = false;
      let ocrId = 1;
      currentSamp[0].pages.forEach((p) => {
        if (p.pageData !== null) {
          p.pageData.ocrData.words.forEach((w) => {
            w.ocrId = ocrId;
            w.active = false;
            ocrId++;
          });
        }
        if (p.pageData !== null) {
          p.pageData.textData.words.forEach((w) => {
            w.ocrId = ocrId;
            w.active = false;
            ocrId++;
          });
        }
      });
      this.setState({
        docSample: currentSamp[0],
        currentSample: currentSamp[0],
        currentSamplePage: currentSamp[0].pages[0],
        showSampleInfo: false,
      });

      setTimeout(() => {
        this.updateAllZones();
        this.lookupFieldValues();
      }, 100);
      this.setState({ waitingOnOCR: false });

    }
    if (lastChange === actionTypes.TemplateHeaders) {
      let head = TemplateStore.getHeaders();
      let existingHeader = this.state.headers;
      head.forEach(h => {
        let ext = existingHeader.find(e => e.fieldId === h.fieldId);

        if(ext !== undefined)
        {
          h.value = ext.value;
        }
      });
      this.setState({ headers: head });
    }

    if (lastChange === actionTypes.TemplateTables) {
      let tables = TemplateStore.getTables();
      this.setState({ tables: tables });
    }

    if (lastChange === actionTypes.TemplateCreateTemplate) {
      let newTemplate = TemplateStore.getCurrentTemplate();
      if (newTemplate.templateId !== this.state.currentTemplate.templateId) {
        // success
        let status = {
          status: 'success',
          newTemplateId: newTemplate.templateId,
          errorMessage: '',
        };
        this.setState({
          saveAsStatus: status,
        });
      } else {
        // fail
        let status = {
          status: 'success',
          newTemplateId: 0,
          errorMessage: 'Template failed to create.  Please see system log',
        };
        this.setState({
          saveAsStatus: status,
        });
      }
    }

    if (lastChange === actionTypes.TemplateGetSampleForDoc) {
      currentSamp = TemplateStore.getCurrentSample();
      currentSamp.isOCRing = false;
      let ocrId = 1;
      currentSamp.pages.forEach((p) => {
        p.pageData.ocrData.words.forEach((w) => {
          w.ocrId = ocrId;
          w.active = false;
          ocrId++;
        });
        p.pageData.textData.words.forEach((w) => {
          w.ocrId = ocrId;
          w.active = false;
          ocrId++;
        });
      });
      this.setState({
        currentSample: currentSamp,
        currentSamplePage: currentSamp.pages[0],
        showSampleInfo: false,
      });

      let samples = this.state.samples;
      let samp = samples.find((s) => s.sampleId === currentSamp.sampleId);
      if (samp) {
        if (samp.sampleName !== currentSamp.sampleName) {
          samp.sampleName = currentSamp.sampleName;
          this.setState({ samples: samples });
        }
      } else {
        samples.push(currentSamp);
        this.setState({ samples: samples });
      }
    }

    if(lastChange === actionTypes.TemplateGetSampleInfo) {
      console.log('getSampleInfo');
      let info = TemplateStore.getSampleInfo();
      console.log(info);
      let sample = this.state.currentSampleInfo;
      sample.type = info.type;
      info.info.forEach((p) => {
        if(p.propName === 'OCRType') sample.OCRType = p.value;
        if(p.propName === 'OCRQuality') sample.OCRQuality = p.value;
        if(p.propName === 'Barcodes') sample.Barcodes = p.value;
        if(p.propName === 'PageCount') sample.PageCount = p.value;

      });
      console.log(sample);
      if(sample.type === 'doc'){
        console.log(this.state.docSample);
        sample.sampleName = this.state.docSample.sampleName;
      }
      this.setState({ currentSampleInfo: sample, showSampleInfo: true, });
    }

    if(lastChange === actionTypes.TemplateConvertSample) {
      let newSampleId = TemplateStore.getNewSampleId();
      GetSample(newSampleId);
      this.setState({showSampleInfo: false, docSample: undefined});
    }
  }

  updateTemplate() {
    UpdateTemplate(
      this.state.currentTemplate.templateId,
      this.state.currentTemplate
    );
  }

  onDrop(files) {
    let drop = this.state.currentDrop;
    drop.drop = files[0];
    drop.dropName = files[0].path;

    this.setState({ currentDrop: drop });
  }

  editDropImageCount(e) {
    let drop = this.state.currentDrop;
    let ic = parseInt(e.target.value);
    if (!isNaN(ic)) {
      drop.imageCount = ic;
      this.setState({ currentDrop: drop });
    }
  }

  removeDrop() {
    let drop = this.state.currentDrop;
    drop.drop = undefined;
    drop.dropName = '';
    this.setState({ currentDrop: drop });
  }

  editOCRQuality(ocrQuality) {
    let drop = this.state.currentDrop;
    drop.ocrQuality = ocrQuality;

    this.setState({
      currentDrop: drop,
    });
  }

  editIncludeLastPage(e) {
    let drop = this.state.currentDrop;
    drop.includeLastPage = e.target.checked;
    this.setState({ currentDrop: drop });
  }

  editOCRType(ocrType) {
    let drop = this.state.currentDrop;
    drop.ocrType = ocrType;

    let ocrDescription = '';
    let newHeight = '400px';

    if (ocrType === 1) {
      if (this.state.showOCRDescriptions) {
        newHeight = '500px';
      }
      ocrDescription =
        '1.0 Text Only OCR - A version of the legacy TrinDocs OCR library which reads only the text present in the document’s metadata and does not attempt to read any images embedded in the document.';
    }
    if (ocrType === 2) {
      if (this.state.showOCRDescriptions) {
        newHeight = '500px';
      }
      ocrDescription =
        '1.0 Smart OCR - A version of the legacy TrinDocs OCR library designed to use metadata from documents when it is available to speed up the reading process.';
    }
    if (ocrType === 3) {
      if (this.state.showOCRDescriptions) {
        newHeight = '500px';
      }
      ocrDescription =
        '1.0 Full OCR - The version of the legacy TrinDocs OCR library used by default in previous versions. Reads each document from scratch rather than trusting any metadata contained in the document.';
    }
    if (ocrType === 4) {
      if (this.state.showOCRDescriptions) {
        newHeight = '500px';
      }
      ocrDescription =
        'OCR 2.0 – Updated TrinDocs OCR library designed to provide high performance OCR without the compromises needed in previous versions for fast document reading.';
    }

    this.setState({
      currentDrop: drop,
      currentOcrDescription: ocrDescription,
      newSampleHeight: newHeight,
    });
  }

  onSwitchSample(sampleId) {
    console.log(sampleId);
    if (sampleId === 0) {
    } else {
      GetSample(sampleId);
    }
  }

  onSwitchDocSample() {
    let currentSamp = TemplateStore.getDocSample();
      currentSamp[0].pages.forEach((p) => {
        console.log(p);
        if (p.pageData !== null) {
          if (p.pageData.ocrData === null) {
            p.pageData.textData.forEach((td) => {
              let od = {
                pageNum: td.pageNum,
                requiresRotation: td.requiresRotation,
                rotationAngle: td.rotationAngle,
                words: [],
              };
              p.pageData.ocrData = od;
            });
          }
        }
      });
      currentSamp[0].isOCRing = false;
      let ocrId = 1;
      currentSamp[0].pages.forEach((p) => {
        if (p.pageData !== null) {
          p.pageData.ocrData.words.forEach((w) => {
            w.ocrId = ocrId;
            w.active = false;
            ocrId++;
          });
        }
        if (p.pageData !== null) {
          p.pageData.textData.words.forEach((w) => {
            w.ocrId = ocrId;
            w.active = false;
            ocrId++;
          });
        }
      });
      console.log(currentSamp);
      this.setState({
        docSample: currentSamp[0],
        currentSample: currentSamp[0],
        currentSamplePage: currentSamp[0].pages[0],
        showSampleInfo: false,
      });

      setTimeout(() => {
        this.updateAllZones();
        this.lookupFieldValues();
      }, 100);
      this.setState({ waitingOnOCR: false });
  }

  editBarcodeSample(e) {
    let drop = this.state.currentDrop;
    drop.readBC = !drop.readBC;
    this.setState({ currentDrop: drop });
  }

  onSampleSubmit() {
    CreateSample(this.state.currentTemplate.templateId, this.state.currentDrop);
    this.setState({ isCreatingSample: true });
  }

  onSampleClose() {
    this.setState({ showSampleDialogue: false });
  }

  onCreateSample() {
    let drop = this.state.currentDrop;
    drop.includeLastPage = true;
    drop.drop = undefined;
    this.setState({ showSampleDialogue: true, currentDrop: drop });
  }

  toggleEditName() {
    var editingName = this.state.editingName;
    this.setState({ editingName: !editingName });
  }

  editTemplateName(e) {
    let t = this.state.currentTemplate;
    t.name = e.target.value;
    this.setState({ currentTemplate: t, isDirty: true });
  }

  toggleSaveAs() {
    let sa = this.state.showSaveAs;
    sa = !sa;
    this.setState({ showSaveAs: sa });
  }

  toggleAdvance() {
    let adv = this.state.showAdvanceSettings;
    this.setState({ showAdvanceSettings: !adv });
  }

  toggleOCRDescriptions() {
    let adv = this.state.showOCRDescriptions;

    let height = '400px';
    if (adv === false) {
      if (this.state.currentDrop.ocrType === 1) {
        height = '570px';
      }
      if (this.state.currentDrop.ocrType === 2) {
        height = '520px';
      }
      if (this.state.currentDrop.ocrType === 3) {
        height = '520px';
      }
    }

    this.setState({ showOCRDescriptions: !adv, newSampleHeight: height });
  }

  calulateAnchors(zone, pagesToRead) {
      let pageBoxes = [];
      if(zone.barcodeFormula !== '')
      {
        let matchParts = zone.barcodeFormula.split(' ');

        let regex = null;


        for (let p = 0; p < pagesToRead.length; p++)
        {

          let wordBox = [];
          pagesToRead[p].pageData.textData.words.forEach((word) => {
            if (
              zone.x <= word.loc.x &&
              zone.y <= word.loc.y &&
              zone.x + zone.width >= word.loc.x + word.loc.w &&
              zone.y + zone.height >= word.loc.y + word.loc.h
            ) {
              wordBox.push({...word});
            }

          });
          pageBoxes.push({pageId: p, words: wordBox, anchor: null});
        }

        try {
          regex = new RegExp(matchParts[0]);
        } catch {
          regex = null;
        }

        if(pageBoxes.length > 0)
        {
          pageBoxes.forEach((pb) => {
            pb.words.forEach((pbw) => {
              let v = regex.test(pbw.value);
              if(v) {

                if(matchParts.length === 1)
                {
                  pb.anchor = { x: pbw.loc.x, y: pbw.loc.y, width: pbw.loc.w, height: pbw.loc.h };
                } else if(matchParts.length > 1){
                  let stillGood = true;
                  let fullBox = {
                    x: pbw.loc.x,
                    y: pbw.loc.y,
                    h: pbw.loc.h,
                    w: pbw.loc.w,
                  }
                  for(let i = 1; i < matchParts.length; i++)
                  {

                    if(stillGood)
                    {
                      let nextWordToMatch = matchParts[i];
                      let nextMatch = false;
                      let regex2 = new RegExp(nextWordToMatch);
                      pb.words.forEach((pbw2) => {
                        let v2 = regex2.test(pbw2.value);
                        if(v2) {
                          // is it to the right...?

                          if(pbw.loc.y < pbw2.loc.y + pbw2.loc.h &&
                            pbw2.loc.y < pbw.loc.y + pbw.loc.h &&
                            pbw2.loc.x > pbw.loc.x + pbw.loc.w &&
                            pbw2.loc.x - pbw.loc.x >= 10)
                            {
                              nextMatch = true;
                              fullBox.w = pbw2.loc.x + pbw2.loc.w - pbw.loc.x;
                            }
                        }
                      });
                      if(!nextMatch) stillGood = false;
                    }
                  }
                  if(stillGood)
                  {
                    pb.anchor = {
                      x:fullBox.x,
                      y: fullBox.y,
                      width: fullBox.w,
                      height: fullBox.h,
                    };
                  }
                }
              }
            });
          });
        }
      }
      return pageBoxes;
  }

  calculateAnchor2(zone, pageId) {
    let result = {
      anchorFound: false,
      x: zone.x,
      y: zone.y,
    }
    if(zone.barcodeFormula !== '')
    {
      let matchParts = zone.barcodeFormula.split(' ');

      let regex = null;

      let wordBox = [];
      let pageToRead = this.state.currentSample.pages[pageId -1];
      if(pageToRead.pageData.textData !== null && pageToRead.pageData.textData !== undefined)
      {
          pageToRead.pageData.textData.words.forEach((word) => {
          if (
            zone.x <= word.loc.x &&
            zone.y <= word.loc.y &&
            zone.x + zone.width >= word.loc.x + word.loc.w &&
            zone.y + zone.height >= word.loc.y + word.loc.h
          ) {
            wordBox.push({...word});
          }
        });
      }
      if(wordBox.length === 0 && pageToRead.pageData.ocrData !== null && pageToRead.pageData.ocrData !== undefined)
      {
          pageToRead.pageData.ocrData.words.forEach((word) => {
          if (
            zone.x <= word.loc.x &&
            zone.y <= word.loc.y &&
            zone.x + zone.width >= word.loc.x + word.loc.w &&
            zone.y + zone.height >= word.loc.y + word.loc.h
          ) {
            wordBox.push({...word});
          }
        });
      }

      try {
        regex = new RegExp(matchParts[0]);
      } catch {
        regex = null;
      }


      wordBox.forEach((pbw) => {
        let v = regex.test(pbw.value);
        if(v) {
          if(matchParts.length === 1)
          {
            result.x = pbw.loc.x;
            result.y = pbw.loc.y;
            result.width = pbw.loc.w;
            result.height = pbw.loc.h;
            result.anchorFound = true;
          }
          else if(matchParts.length > 1){
            let stillGood = true;
            let fullBox = {
              x: pbw.loc.x,
              y: pbw.loc.y,
              h: pbw.loc.h,
              w: pbw.loc.w,
            }
            for(let i = 1; i < matchParts.length; i++)
            {

              if(stillGood)
              {
                let nextWordToMatch = matchParts[i];
                let nextMatch = false;
                let regex2 = new RegExp(nextWordToMatch);
                wordBox.forEach((pbw2) => {
                  let v2 = regex2.test(pbw2.value);
                  if(v2) {
                    // is it to the right...?

                    if(pbw.loc.y < pbw2.loc.y + pbw2.loc.h &&
                      pbw2.loc.y < pbw.loc.y + pbw.loc.h &&
                      pbw2.loc.x > pbw.loc.x + pbw.loc.w &&
                      pbw2.loc.x - pbw.loc.x >= 10)
                      {
                        nextMatch = true;
                        fullBox.w = pbw2.loc.x + pbw2.loc.w - pbw.loc.x;
                      }
                  }
                });
                if(!nextMatch) stillGood = false;
              }
            }
            if(stillGood)
            {
              result.anchorFound = true;
              result.x = fullBox.x;
              result.y = fullBox.y;
              result.width = fullBox.w;
              result.height = fullBox.h;
            };
          }
        }

        });
      }

    return result;
}

  getZoneWords(zone, pageId, floatZone)
  {
    if (
      this.state.currentSamplePage.pageData !== undefined &&
      this.state.currentSamplePage.pageData !== null
    ) {
      let wordsOnPage = [];
      if(floatZone === null || floatZone === undefined)
      {
        let pageToRead = this.state.currentSample.pages[pageId -1];
        if(zone.scanType === 0 && pageToRead !== undefined)
        {
          // regular capture.

          if(pageToRead.pageData.textData !== null && pageToRead.pageData.textData !== undefined)
          {
              pageToRead.pageData.textData.words.forEach((word) => {
              if (
                zone.x <= word.loc.x &&
                zone.y <= word.loc.y &&
                zone.x + zone.width >= word.loc.x + word.loc.w &&
                zone.y + zone.height >= word.loc.y + word.loc.h
              ) {
                wordsOnPage.push({...word});
              }
            });
          }
        }
        if((zone.scanType === 0 || zone.scanType === 2) && pageToRead !== undefined)
        {
          if(wordsOnPage.length === 0 && pageToRead.pageData.ocrData !== null && pageToRead.pageData.ocrData !== undefined)
          {
            pageToRead.pageData.ocrData.words.forEach((word) => {
            if (
              zone.x <= word.loc.x &&
              zone.y <= word.loc.y &&
              zone.x + zone.width >= word.loc.x + word.loc.w &&
              zone.y + zone.height >= word.loc.y + word.loc.h
            ) {
              wordsOnPage.push({...word});
            }
          });

          }
        }
        if(zone.scanType === 1 && pageToRead !== undefined)
        {
          console.log(pageToRead);
          if(wordsOnPage.length === 0 && pageToRead.pageData.barcodeData !== null && pageToRead.pageData.ocrData !== undefined)
          {
            pageToRead.pageData.barcodeData.barcodes.forEach((bc) => {
            if (
              zone.x <= bc.bounds.x &&
              zone.y <= bc.bounds.y &&
              zone.x + zone.width >= bc.bounds.x + bc.bounds.width &&
              zone.y + zone.height >= bc.bounds.y + bc.bounds.height
            ) {
              wordsOnPage.push({
                conf: 90,
                loc: {
                  x: bc.bounds.x,
                  y: bc.bounds.y,
                  height: bc.bounds.height,
                  width: bc.bounds.width
                },
                value: bc.barcodeValue
              });
            }
          });

          }

        }
        if(zone.scanType === 3 && pageToRead !== undefined)
        {
          console.log(pageToRead);
          if(wordsOnPage.length === 0 && pageToRead.pageData.barcodeData !== null)
          {
            pageToRead.pageData.barcodeData.barcodes.forEach((bc) => {
              wordsOnPage.push({
                conf: 90,
                loc: {
                  x: bc.bounds.x,
                  y: bc.bounds.y,
                  height: bc.bounds.height,
                  width: bc.bounds.width
                },
                value: bc.barcodeValue
              });
            });
          }
        }

      }
      else {
        let pageToRead = this.state.currentSample.pages[pageId -1];
        if(pageToRead.pageData.textData !== null && pageToRead.pageData.textData !== undefined)
        {
            pageToRead.pageData.textData.words.forEach((word) => {
            if (
              floatZone.x + zone.offsetX <= word.loc.x &&
              floatZone.y + zone.offsetY <= word.loc.y &&
              floatZone.x + zone.offsetX + zone.width >= word.loc.x + word.loc.w &&
              floatZone.y + zone.offsetY + zone.height >= word.loc.y + word.loc.h
            ) {
              wordsOnPage.push({...word});
            }
          });
        }
        if(wordsOnPage.length === 0 && pageToRead.pageData.ocrData !== null && pageToRead.pageData.ocrData !== undefined)
        {
          pageToRead.pageData.ocrData.words.forEach((word) => {
          if (
            floatZone.x + zone.offsetX <= word.loc.x &&
              floatZone.y + zone.offsetY <= word.loc.y &&
              floatZone.x + zone.offsetX + zone.width >= word.loc.x + word.loc.w &&
              floatZone.y + zone.offsetY + zone.height >= word.loc.y + word.loc.h
          ) {
            wordsOnPage.push({...word});
          }
        });

        }
      }
      return wordsOnPage;
    }
  }



   calculateZoneValue(zone, defaultFloat, move = true) {
    if (
      this.state.currentSamplePage.pageData !== undefined &&
      this.state.currentSamplePage.pageData !== null
    ) {
      let pagesToRead = [];

      if (zone.zoneType === this.parseZoneType('floating')) {
        let page = this.state.currentSamplePage.pageId;
        let anchor = this.calculateAnchor2(zone, page);
        if(anchor.anchorFound === true){

          zone.childZone = {
            x: anchor.x,
            y: anchor.y,
            width: anchor.width,
            height: anchor.height
          }
          this.state.currentTemplate.templateZones.forEach(
            (refZone) => {
               if (refZone.anchorZoneId === zone.screenId) {
                   refZone.x = zone.childZone.x + refZone.offsetX;
                   refZone.y = zone.childZone.y + refZone.offsetY;

                   refZone.value = this.calculateZoneValue(refZone);
                 }
               }
            );
        } else {
          zone.childZone = undefined;
        }

      }
      let returnVal = '';
      if (zone.zoneType === this.parseZoneType('capture')) {
        let anchorZone = undefined;
        if(zone.anchorZoneId !== null && zone.anchorZoneId !== undefined && zone.anchorZoneId !== 0)
        {
          anchorZone =  this.state.currentTemplate.templateZones.find(tz => tz.screenId === zone.anchorZoneId);
        }

        let words = [];
        let anchor = undefined;
        if(zone.readFrom === 'first') {

          if(anchorZone !== undefined) anchor = this.calculateAnchor2(anchorZone, 1)
          words = this.getZoneWords(zone, 1, anchor);
        }
        if(zone.readFrom === 'last') {
          if(anchorZone !== undefined) anchor = this.calculateAnchor2(anchorZone, this.state.currentSample.pages.length)
          words = this.getZoneWords(zone, this.state.currentSample.pages.length, anchor);
        }
        if(zone.readFrom === 'start') {
          if(anchorZone !== undefined) anchor = this.calculateAnchor2(anchorZone, zone.page)
          words = this.getZoneWords(zone, zone.page, anchor);
        }
        if(zone.readFrom === 'end') {
          if(anchorZone !== undefined) anchor = this.calculateAnchor2(anchorZone, this.state.currentSample.pages.length - zone.page + 1)
          words = this.getZoneWords(zone, this.state.currentSample.pages.length - zone.page + 1, anchor);
        }
        if(zone.readFrom === 'firstv')
        {
          for(let p = 1; p <= this.state.currentSample.pages.length; p++)
          {
            if(anchorZone !== undefined) anchor = this.calculateAnchor2(anchorZone, p)
            words = this.getZoneWords(zone, p, anchor);
            if(words.length > 0)
            {
              zone.pageFound = p;
              break;
            }
          }
        }
        if(zone.readFrom === 'lastv')
        {
          for(let p = this.state.currentSample.pages.length; p >= 1; p--)
          {
            let anchor = undefined;
            if(anchorZone !== undefined) anchor = this.calculateAnchor2(anchorZone, p)
            words = this.getZoneWords(zone, p, anchor);
            if(words.length > 0)
            {
              zone.pageFound = p;
              break;
            }
          }
        }
        if(zone.readFrom === 'all')
        {
          for(let p = 1; p <= this.state.currentSample.pages.length; p++)
          {
            let anchor = undefined;
            if(anchorZone !== undefined) anchor = this.calculateAnchor2(anchorZone, p)
            words.push = [...this.getZoneWords(zone, p, anchor)];
          }
        }


        let regex = null;
        try {
          regex = new RegExp(zone.barcodeFormula);
        } catch {
          regex = null;
        }
        if(regex !== null && zone.zoneType === 0 && zone.regExBehavior === 'matchingWords') {

          let wordsOnPage2 = [];
          let allString = '';
          words.forEach((w) => {
            allString = allString + w.value + ' ';

            let v = regex.test(w.value);
            if (v) {
              wordsOnPage2.push(w);
            }
          });

          words = [...wordsOnPage2];
        }

        if(regex !== null && zone.zoneType === 0 && zone.regExBehavior === 'matchingPhrase') {
          let fullstring = "";
          words.forEach((w) => {
            fullstring = fullstring + w.value + ' ';
          });
          let v = regex.exec(fullstring);

          if(v !== null)
          {

            let wordsOnPage2 = [];
            wordsOnPage2.push(words[0]);
            wordsOnPage2[0].value = v[0];
            words = [...wordsOnPage2];
          }
          else {
            words = [];
          }

        }

        if(regex !== null && zone.zoneType === 0 && zone.regExBehavior === 'replacement') {
          let wordsOnPage2 = [];

          let regexparts = zone.barcodeFormula.split('::');
          if(regexparts.length === 2)
          {
            try {
              regex = new RegExp(regexparts[0]);
            } catch {
              regex = null;
            }
            if(regex !== null)
            {
              words.forEach((w) => {

                let v = regex.exec(w.value);
                if (v !== null) {
                  w.value = w.value.replace(v[0], regexparts[1]);
                  wordsOnPage2.push(w);
                } else {
                  wordsOnPage2.push(w);
                }
              });
              words = [...wordsOnPage2];
            }
          }
        }

        if(regex !== null && zone.zoneType === 0 && zone.regExBehavior === 'appendStart') {
          let wordsOnPage2 = [];

          let regexparts = zone.barcodeFormula.split('::');
          if(regexparts.length === 2)
          {
            try {
              regex = new RegExp(regexparts[0]);
            } catch {
              regex = null;
            }
            if(regex !== null)
            {
              words.forEach((w) => {

                let v = regex.exec(w.value);
                if (v !== null) {
                  w.value = regexparts[1]+ w.value;

                  wordsOnPage2.push(w);
                } else {
                  wordsOnPage2.push(w);
                }
              });
              words = [...wordsOnPage2];
            }
          }
        }

        if(regex !== null && zone.zoneType === 0 && zone.regExBehavior === 'appendEnd') {
          let wordsOnPage2 = [];

          let regexparts = zone.barcodeFormula.split('::');
          if(regexparts.length === 2)
          {
            try {
              regex = new RegExp(regexparts[0]);
            } catch {
              regex = null;
            }
            if(regex !== null)
            {
              words.forEach((w) => {

                let v = regex.exec(w.value);
                if (v !== null) {
                  w.value = regexparts[1]+ w.value;

                  wordsOnPage2.push(w);
                } else {
                  wordsOnPage2.push(w);
                }
              });
              words = [...wordsOnPage2];
            }
          }
        }


        if(regex !== null && zone.zoneType === 0 && zone.regExBehavior === 'remove') {

            words.forEach((w) => {
              let v = regex.exec(w.value);
              let previousValue = '';
              while (v != null && previousValue !== w.value) {
                previousValue = w.value;
                w.value = w.value.replace(v[0], '');
                v = regex.exec(w.value);
              }
            });
        };



        returnVal = '';

        if(zone.multiWordSelect === "First" && words.length > 0)
        {
          returnVal = words[0].value;
        }

        if(zone.multiWordSelect === "Last" && words.length > 0)
        {
          returnVal = words[words.length - 1].value;
        }

        if(zone.multiWordSelect === "All" && words.length > 0)
        {
          words.forEach((w) => {
            if(returnVal.length) returnVal = returnVal + ' ' ;
            returnVal = returnVal + w.value;
          });
        }

        // remove spaces and commas
        if (zone.removeSpaces) {
          returnVal = returnVal.replace(/\s/g, '');
        }
        if (zone.removeCommas) {
          returnVal = returnVal.replace(/,/g, '');
        }


        if (zone.defaultValue !== '') returnVal = zone.defaultValue;

      }
      if(zone.zoneType === this.parseZoneType('split'))
      {
        let words = [];
        console.log(this.state.currentSamplePage);
        words = this.getZoneWords(zone, this.state.currentSamplePage.pageId, null);

        returnVal = '';
        words.forEach((w) => {
          if(returnVal.length) returnVal = returnVal + ' ' ;
          returnVal = returnVal + w.value;
        });
      }
      if (zone.zoneType === this.parseZoneType('identifier'))
      {
        let words = [];
        if(zone.readFrom === 'first') {
          words = this.getZoneWords(zone, 1, null);
        }
        if(zone.readFrom === 'start') {
          words = this.getZoneWords(zone, zone.page, null);
        }

        returnVal = '';

        if(zone.multiWordSelect === "First" && words.length > 0)
        {
          returnVal = words[0].value;
        }

        if(zone.multiWordSelect === "Last" && words.length > 0)
        {
          returnVal = words[words.length - 1].value;
        }

        if(zone.multiWordSelect === "All" && words.length > 0)
        {
          words.forEach((w) => {
            if(returnVal.length) returnVal = returnVal + ' ' ;
            returnVal = returnVal + w.value;
          });
        }

        if (zone.removeSpaces) {
          returnVal = returnVal.replace(/\s/g, '');
        }
        if (zone.removeCommas) {
          returnVal = returnVal.replace(/,/g, '');
        }


      }



      if (zone.zoneType === this.parseZoneType('table')) {

        pagesToRead = [...this.state.currentSample.pages];

        let tableWords = [];
        //for (let p = 0; p < pagesToRead.length; p++) {
        pagesToRead[
          this.state.currentSamplePage.pageId - 1
        ].pageData.textData.words.forEach((word) => {
          if (
            zone.x <= word.loc.x &&
            zone.y <= word.loc.y &&
            zone.x + zone.width >= word.loc.x + word.loc.w &&
            zone.y + zone.height >= word.loc.y + word.loc.h
          ) {

            tableWords.push( {...word} );
          }
        });
        //}

        //for (let p = 0; p < pagesToRead.length; p++) {
        if(tableWords.length === 0)
        {
          pagesToRead[
            this.state.currentSamplePage.pageId - 1
          ].pageData.ocrData.words.forEach((word) => {
            if (
              zone.x <= word.loc.x &&
              zone.y <= word.loc.y &&
              zone.x + zone.width >= word.loc.x + word.loc.w &&
              zone.y + zone.height >= word.loc.y + word.loc.h
            ) {
              tableWords.push({...word});
            }
          });
        }

        //}
        let keyColumn = undefined;
        zone.columns.forEach((col) => {
          if (col.isKeyColumn) keyColumn = col;
        });
        if (keyColumn) {
          console.log(keyColumn);
          let keyWords = [];
          tableWords.forEach((word) => {
            if (
              keyColumn.x + zone.x <= word.loc.x &&
              //keyColumn.y + zone.y <= word.loc.y &&
              keyColumn.x + zone.x + keyColumn.width >=
                word.loc.x + word.loc.w
             // keyColumn.y + zone.y + keyColumn.height >= word.loc.y + word.loc.h
            ) {
              let matchingWord = null;
              keyWords.forEach((wrd) => {
                if(word.loc.y > wrd.loc.y - 7 && word.loc.y + word.loc.h < wrd.loc.y + wrd.loc.h + 7)
                {
                  //wrd.value = wrd.value + ' ' + word.value;
                  matchingWord = wrd;
                }
              });
              if(matchingWord)
              {
                matchingWord.value = matchingWord.value + ' ' + word.value;
              } else {
                keyWords.push({...word});
              }
              // if (keyColumn.regEx !== '') {
              //   try {
              //     let re = new RegExp(keyColumn.regEx);
              //     if (re.test(word.value)) {
              //       keyWords.push(word);
              //     }
              //   } catch {
              //     keyWords.push(word);
              //   }
              // } else {
                //keyWords.push(word);
              //}
            }
          });
          let rows = [];
          let nextRowId = 1;

          // remove keyWors that don't meet the max /min length req
          let keysToRemove = [];
          keyWords.forEach((word) => {
            if(word.value.length > keyColumn.maximumDataLength && keyColumn.maximumDataLength !== 0)
            {
              keysToRemove.push(word);
            }
            if(word.value.length < keyColumn.minimumDataLength && keyColumn.minimumDataLength !== 0)
            {
              keysToRemove.push(word);
            }
          });
          keysToRemove.forEach((r) => {
            let idx = keyWords.indexOf(r);
            keyWords.splice(idx, 1);
          });
          console.log(keysToRemove);

          if (keyWords.length > 0) {
            keyWords.forEach((word) => {
              // does word fall in existing row
              let existingRow = undefined;
              if (keyColumn.removeSpaces) {
                word.value = word.value.replace(/\s/g, '');
              }
              if (keyColumn.removeCommas) {
                word.value = word.value.replace(/,/g, '');
              }

              let createRow = true;
              if (keyColumn.regEx !== '') {
                 try {
                  let re = new RegExp(keyColumn.regEx);
                   if (!re.test(word.value)) {
                     createRow = false;
                   }
                   } catch {
                     createRow = false;
                   }
              }

              if (createRow) {
                let newRow = {
                  top: word.loc.y - zone.y - 7,
                  left: word.loc.x - zone.x,
                  height: word.loc.h,
                  bottom: word.loc.y - zone.y + word.loc.h + 7,
                  rowId: nextRowId,
                };
                rows.push(newRow);
                nextRowId++;
              }
            });

            rows.forEach((row) => {
              let idx = rows.indexOf(row);
              let isLastRow = false;
              if (idx + 1 === rows.length) isLastRow = true;
              row.cells = [];
              let cellId = 1;
              zone.columns.forEach((col) => {
                let regex = null;
                // if(zone.col.regEx !== '')
                // {
                //   try {
                //   regex = new RegExp(col.regEx);
                //   } catch {
                //     regex = null;
                //   }
                // }
                if (col.regEx !== '' && col.regEx !== null) {
                  try {
                    regex = new RegExp(col.regEx);
                  } catch {
                    regex = null;
                  }
                }

                let maxbottom = row.bottom + 5;
                let newCell = {
                  top: row.top,
                  id: cellId,
                  left: col.x,
                  width: col.width,
                  height: row.height,
                  value: '',
                  active: false,
                };
                if (!isLastRow && col.multiLineRead === true) {
                  maxbottom = rows[idx + 1].top;
                }
                let cellText = '';
                tableWords.forEach((word) => {
                  if (
                    col.x + zone.x <= word.loc.x &&
                    col.y + zone.y + row.top <= word.loc.y &&
                    col.x + zone.x + col.width >= word.loc.x + word.loc.w &&
                    col.y + zone.y + maxbottom + 4 >= word.loc.y + word.loc.h
                  ) {
                    // if (regex !== null) {
                    //   try {
                    //     let v = regex.test(word.value);
                    //     if (v) {
                    //       cellText += word.value;
                    //       cellText += ' ';
                    //     }
                    //   } catch {}
                    // } else {
                         cellText += word.value;
                         if(!col.removeSpaces) {
                            cellText += ' ';
                         }
                    // }

                    if (
                      word.loc.y + word.loc.h - zone.y > row.bottom &&
                      col.multiLineRead === true
                    ) {
                      let cellBottom = word.loc.y + word.loc.h - zone.y;
                      newCell.height = cellBottom - newCell.top;
                    }
                  }
                });
                // last row, use word proximity to add
                if (isLastRow && col.multiLineRead === true) {
                  cellText = '';
                  newCell.height = 0;
                  // bottom of the table
                  maxbottom = zone.y + zone.height;
                  tableWords.forEach((word) => {
                    if (
                      col.x + zone.x <= word.loc.x &&
                      col.y + zone.y + row.top <= word.loc.y &&
                      col.x + zone.x + col.width >= word.loc.x + word.loc.w &&
                      col.y + zone.y + maxbottom + 4 >= word.loc.y + word.loc.h
                    ) {
                      if (
                        word.loc.y - (zone.y + newCell.top + newCell.height) <
                        20
                      ) {
                        cellText += word.value;
                        cellText += ' ';
                        let cellBottom = word.loc.y + word.loc.h - zone.y;
                        newCell.height = cellBottom - newCell.top;
                      }
                    }
                  });
                }

                if (col.removeSpaces) {
                  cellText = cellText.replace(/\s/g, '');
                }
                if (col.removeCommas) {
                  cellText = cellText.replace(/,/g, '');
                }

                if(cellText.length > col.maximumDataLength && col.maximumDataLength > 0)
                {
                  cellText = '';
                }
                if(cellText.length < col.minimumDataLength && col.minimumDataLength > 0)
                {
                  cellText = '';
                }
                if (regex !== null) {
                    try {
                      let v = regex.test(cellText);
                      if (v) {
                        newCell.value = cellText;
                      } else {
                        newCell.value = '';
                      }
                    } catch {}
                  } else {
                    newCell.value = cellText;
                  }


                row.cells.push(newCell);
                cellId++;
              });
            });
          }
          zone.rows = rows;
        }
      }
      this.updateTableVals();
      return returnVal;
    } else {
      return '';
    }
  }

  toggleActive() {
    let t = this.state.currentTemplate;
    t.isActive = !t.isActive;
    this.setState({ currentTemplate: t, isDirty: true });
  }

  updateZoneGeometry(id, newX, newWidth, newY, newHeight) {
    let currentTemp = this.state.currentTemplate;
    let tz = currentTemp.templateZones.find((f) => f.screenId === id);
    let preventChange = false;
    if (tz) {
      let h = Math.round(newHeight);
      let w = Math.round(newWidth);
      // also resize column children...
      if (tz.zoneType === 3) {
        let lastCol = tz.columns[tz.columns.length - 1];

        let newWidth = w - lastCol.x;
        if (newWidth < 15) {
          preventChange = true;
        } else {
          lastCol.width = Math.round(newWidth);
          lastCol.xOffset = Math.round(newWidth);

          tz.columns.forEach((col) => {
            col.height = Math.round(tz.height);
          });
        }
      }

      if (!preventChange) {
        tz.x = Math.round(newX);
        tz.width = w;
        tz.y = Math.round(newY);
        tz.height = h;
        tz.value = this.calculateZoneValue(tz, true, false);

        // calculate offset
        if (
          tz.anchorZoneId !== undefined &&
          tz.anchorZoneId !== null &&
          tz.anchorZoneId !== 0
        ) {
          var anchorZone = this.state.currentTemplate.templateZones.find(
            (t) => t.screenId === tz.anchorZoneId
          );
          if (anchorZone) {
            tz.offsetX = 0;
            tz.offsetY = 0;
            tz.anchorZoneId = anchorZone.screenId;
            if (anchorZone.childZone !== undefined) {
              tz.offsetX = tz.x - anchorZone.childZone.x;
              tz.offsetY = tz.y - anchorZone.childZone.y;
            } else {
              tz.offsetX = tz.x - anchorZone.x;
              tz.offsetY = tz.y - anchorZone.y;
            }
          }
        }
      }
      this.setState({ currentTemplate: currentTemp, isDirty: true });
      this.lookupFieldValues();
    }
  }

  toggleShowCapture() {
    let sc = this.state.showCapture;
    if (sc === true) {
      this.setState({ showCapture: false, captureLayer: '' });
    } else {
      this.setState({
        showCapture: true,
        showCaptureText: true,
        showCaptureOCR: true,
        captureLayer: 'to',
      });
    }
  }

  toggleShowText() {
    let layer = '';
    if (!this.state.showCaptureText) {
      layer = layer + 't';
    }
    if (this.state.showCaptureOCR) {
      layer = layer + 'o';
    }

    this.setState({
      showCaptureText: !this.state.showCaptureText,
      captureLayer: layer,
    });
  }

  toggleShowOCR() {
    let layer = '';
    if (this.state.showCaptureText) {
      layer = layer + 't';
    }
    if (!this.state.showCaptureOCR) {
      layer = layer + 'o';
    }

    this.setState({
      showCaptureOCR: !this.state.showCaptureOCR,
      captureLayer: layer,
    });
  }

  setActiveImage(imageIdx) {

    let cs = this.state.currentSample;
    console.log(cs);
    if (cs.pages.length >= imageIdx && imageIdx > 0) {
      this.setState(
        { currentSamplePage: cs.pages[imageIdx - 1] },
        () => this.updateAllZones()
        // function() {
        // this.updateAllZones();
        // }
      );
    }
  }

  updateAllZones() {
    console.log('update all');
    let t = this.state.currentTemplate;
    t.templateZones.forEach((z) => {
      console.log('calculate');
      let val = this.calculateZoneValue(z);
      if (z.value !== val) {
        z.value = val;
      }
    });
    this.setState({ currentTemplate: t });
  }

  lookupFieldValues() {
    console.log('lookupFieldValues');
    let headers = this.state.headers;
    let zones = this.state.currentTemplate.templateZones;
    headers.forEach((h) => {

      let hZone = zones.find(
        (z) => z.fieldId === h.fieldId && z.zoneType === 0
      );

      if (hZone) {

        h.value = hZone.value;
        //updateCount++;
      } else {
        h.value = '';
      }
    });

    this.setState({ headers: headers });

    let tables = this.state.tables;
    tables.forEach((table) => {
      let tZone = zones.find((z) => z.fieldSetId === table.fieldSetId);
      if (tZone !== undefined) {
        this.calculateZoneValue(tZone);
      }
    });

    let idZones = this.state.currentTemplate.templateZones;
    let idCount = 0;
    let matchCount = 0;
    let forcedZoneCount = 0;
    let forcedMatches = 0;
    let optionalZoneCount = 0;
    let optionalMatches = 0;
    idZones.forEach((tz) => {
      if (tz.zoneType === this.parseZoneType('identifier')) {
        idCount++;
        if(tz.splitType === 1) forcedZoneCount++;
        else optionalZoneCount++;

        if(tz.barcodeFormula === undefined || tz.barcodeFormula === '')
        {
          if (tz.value === tz.templateIdentifier) {
            if(tz.splitType === 1) forcedMatches++;
            else optionalMatches++;
          }
        } else {
          let regex = null;
          try {
            regex = new RegExp(tz.barcodeFormula);
          } catch {
            regex = null;
          }
          if(regex !== null)
          {
            console.log(regex);
            let v = regex.test(tz.value);
            if(v)
            {
              console.log(v);
              if(tz.splitType === 1) forcedMatches++;
              else optionalMatches++;
            }
          }

        }

      }
    });

    if (idCount === 0) {
      this.setState({ templateIdentifies: undefined });
    } else if (forcedZoneCount === forcedMatches && forcedMatches > 0) {
      this.setState({ templateIdentifies: true });
    } else if (forcedZoneCount === 0 && optionalMatches > 1) {
      this.setState({ templateIdentifies: true });
    } else {
      this.setState({ templateIdentifies: false });
    }
  }

  initializeNewZone(box) {
    let adjBox = box;
    adjBox.x = Math.round(box.x);
    adjBox.y = Math.round(box.y);
    this.setState({ newZone: adjBox, showZoneSelect: true });
  }

  editZoneValue(e, zone, prop) {
    let ct = this.state.currentTemplate;
    let currentZones = ct.templateZones;
    let z = currentZones.find((tz) => tz.screenId === zone.screenId);

    if (z) {
      if (prop === 'zoneName') {
        z.zoneName = e.target.value;
      }
      if (prop === 'zoneFieldId') {
        z.fieldId = parseInt(e.target.value, 10);
        if (z.zoneName.includes('Capture')) {
          let header = this.state.headers.find(
            (tz) => tz.fieldId === z.fieldId
          );
          z.zoneName = header.systemName;
        }
        // make sure only one field per field Id
        console.log(zone);
        let eZones = currentZones.find(ez => ez.fieldId === z.fieldId && ez.screenId != z.screenId);
        if(eZones !== undefined)
        {
          let idx = currentZones.indexOf(eZones);

          currentZones.splice(idx, 1);
        }
      }
      if (prop === 'fieldSetId') {
        z.fieldSetId = parseInt(e.target.value, 10);
      }
      if (prop === 'defaultValue') {
        z.defaultValue = e.target.value;
      }
      if (prop === 'extractMethod') {
        z.scanType = parseInt(e.target.value, 10);
      }
      if (prop === 'templateIdentifier') {
        z.templateIdentifier = e.target.value;
      }
      if (prop === 'anchor') {
        z.anchorZoneId = parseInt(e.target.value, 10);

        // this changes everything
        // find the anchor zone
        var anchorId = parseInt(e.target.value, 10);
        if (anchorId !== 0) {
          var anchorZone = this.state.currentTemplate.templateZones.find(
            (t) => t.screenId === anchorId
          );
          if (anchorZone) {
            z.offsetX = 0;
            z.offsetY = 0;
            z.anchorZoneId = anchorZone.screenId;
            if (anchorZone.childZone !== undefined) {
              z.offsetX = z.x - anchorZone.childZone.x;
              z.offsetY = z.y - anchorZone.childZone.y;
              //z.x = z.x - anchorZone.childZone.x;
              //z.y = z.y - anchorZone.childZone.y;
              //z.offsetX = anchorZone.childZone.x;
              //z.offsetY = anchorZone.childZone.y;
            }
          }
        }
      }
      if (prop === 'barcodeFormula') {
        z.barcodeFormula = e.target.value;
      }
      if (prop === 'removeSpaces') {
        z.removeSpaces = e.target.checked;
      }
      if (prop === 'removeCommas') {
        z.removeCommas = e.target.checked;
      }
      if (prop === 'readFrom') {
        z.readFrom = e.target.value;
      }
      if (prop === 'pageId') {
        console.log(prop);
        console.log(e.target.value);
        if(e.target.value === '') z.page = '';
        else {
          z.page = parseInt(e.target.value, 10);
        }
      }

      if (prop === 'multiWordSelect') {
        z.multiWordSelect = e.target.value;
      }

      if (prop === 'splitType') {
        z.splitType = parseInt(e.target.value, 10);
      }

      if (prop === 'regExBehavior') {
        z.regExBehavior = e.target.value;
      }

      if (prop === 'fieldSetId') {
        z.fieldSetId = parseInt(e.target.value, 10);
        if (z.fieldSetId !== 0) {
          let tables = this.state.tables;
          let t = tables.find((fs) => fs.fieldSetId === z.fieldSetId);
          if (t) {
            this.setState({ currentTableFields: t.fields });
          }
        } else {
          this.setState({ currentTableFields: [] });
        }
      }

      z.value = this.calculateZoneValue(z);
      this.setState({ currentTemplate: ct, currentZone: z, isDirty: true });
      this.lookupFieldValues();
    }
  }

  editColumnValue(e, prop) {
    let z = this.state.currentColumn;
    let parent = this.getParentTableZone(z);
    if (prop === 'columnName') {
      z.columnName = e.target.value;
    }
    if (prop === 'zoneFieldId') {
      z.fieldId = parseInt(e.target.value, 10);
    }
    if (prop === 'isKeyColumn') {
      if (parent) {
        parent.columns.forEach((p2) => {
          p2.isKeyColumn = false;
        });
      }
      z.isKeyColumn = e.target.checked;
    }

    if (prop === 'regEx') {
      z.regEx = e.target.value;
    }
    if (prop === 'removeSpaces') {
      z.removeSpaces = e.target.checked;
    }
    if (prop === 'removeCommas') {
      z.removeCommas = e.target.checked;
    }
    if (prop === 'maximumDataLength') {
      z.maximumDataLength = parseInt(e.target.value, 10);
    }
    if (prop === 'minimumDataLength') {
      z.minimumDataLength = parseInt(e.target.value, 10);
    }
    if (prop === 'multiLineRead') {
      z.multiLineRead = e.target.checked;
    }

    this.calculateZoneValue(parent);
    this.setState({ currentColumn: z, isDirty: true });
  }

  getParentTableZone(column) {
    let zones = this.state.currentTemplate.templateZones;
    let parentZone = undefined;
    zones.forEach((z) => {
      if (z.zoneType === 3) {
        // table zone
        if (z.columns) {
          z.columns.forEach((col) => {
            if (col.screenId === column.screenId) {
              parentZone = z;
            }
          });
        }
      }
    });
    return parentZone;
  }

  createZone(zoneType) {
    let ct = this.state.currentTemplate;
    let currentZones = ct.templateZones;
    let maxScreenId = 1;

    currentZones.forEach((z) => {
      if (z.screenId >= maxScreenId) {
        maxScreenId = z.screenId + 1;
      }
      if (z.zoneType === 3) {
        z.columns.forEach((z2) => {
          if (z2.screenId >= maxScreenId) {
            maxScreenId = z2.screenId + 1;
          }
        });
      }
    });

    if (zoneType === this.parseZoneType('capture')) {
      let nextZoneId = 1;
      currentZones.forEach((z) => {
        if (z.zoneName.startsWith('Capture')) {
          let id = parseInt(z.zoneName.substring(7), 10);
          if (!isNaN(id)) {
            if (id >= nextZoneId) nextZoneId = id + 1;
          }
        }
      });
      let zone = this.state.newZone;
      zone.templateId = this.state.currentTemplate.templateId;
      zone.zoneType = zoneType;
      zone.regExBehavior = 'matchingWords';
      zone.templateZoneId = 0;
      zone.screenId = maxScreenId;
      zone.zoneName = 'Capture' + nextZoneId.toString();
      zone.defaultValue = '';
      zone.offsetX = 0;
      zone.offsetY = 0;
      zone.fieldId = 0;
      zone.scanType = 0;
      zone.anchorZoneId = 0;
      zone.barcodeFormula = '';
      zone.removeSpaces = false;
      zone.removeCommas = false;
      zone.multiWordSelect = 'All';
      zone.readFrom = 'start';
      zone.page = this.state.currentSamplePage.pageId;
      //zone.show = false;
      zone.value = this.calculateZoneValue(zone);
      zone.templateIdentifier = '';
      currentZones.push(zone);
      zone.width = Math.round(zone.width);
      zone.height = Math.round(zone.height);
      this.setState({ currentTemplate: ct, currentZone: zone, isDirty: true });
      this.lookupFieldValues();
    }

    if (zoneType === this.parseZoneType('identifier')) {
      let nextZoneId = 1;
      currentZones.forEach((z) => {
        if (z.zoneName.startsWith('Identifier')) {
          let id = parseInt(z.zoneName.substring(10), 10);
          if (!isNaN(id)) {
            if (id >= nextZoneId) nextZoneId = id + 1;
          }
        }
      });
      let zone = this.state.newZone;
      zone.templateId = this.state.currentTemplate.templateId;
      zone.zoneType = zoneType;
      zone.templateZoneId = 0;
      zone.screenId = maxScreenId;
      zone.zoneName = 'Identifier' + nextZoneId.toString();
      zone.defaultValue = '';
      zone.offsetX = 0;
      zone.offsetY = 0;
      zone.fieldId = 0;
      zone.scanType = 0;
      zone.splitType = 1;
      zone.anchorZoneId = 0;
      zone.barcodeFormula = '';
      zone.removeSpaces = false;
      zone.removeCommas = false;
      zone.multiWordSelect = 'All';
      zone.readFrom = 'first';
      zone.page = this.state.currentSamplePage.pageId;
      //zone.show = false;
      zone.value = this.calculateZoneValue(zone);
      zone.width = Math.round(zone.width);
      zone.height = Math.round(zone.height);
      zone.templateIdentifier = this.calculateZoneValue(zone);
      currentZones.push(zone);

      this.setState({ currentTemplate: ct, currentZone: zone, isDirty: true });
      this.lookupFieldValues();
    }

    if (zoneType === this.parseZoneType('split')) {
      let nextZoneId = 1;
      currentZones.forEach((z) => {
        if (z.zoneName.startsWith('Split')) {
          let id = parseInt(z.zoneName.substring(5), 10);
          if (!isNaN(id)) {
            if (id >= nextZoneId) nextZoneId = id + 1;
          }
        }
      });

      let zone = this.state.newZone;
      zone.templateId = this.state.currentTemplate.templateId;
      zone.zoneType = zoneType;
      zone.templateZoneId = 0;
      zone.screenId = maxScreenId;
      zone.zoneName = 'Split' + nextZoneId.toString();
      zone.defaultValue = '';
      zone.offsetX = 0;
      zone.offsetY = 0;
      zone.fieldId = 0;
      zone.scanType = 0;
      zone.anchorZoneId = 0;
      zone.barcodeFormula = '';
      zone.removeSpaces = false;
      zone.removeCommas = false;
      zone.multiWordSelect = 'All';
      zone.readFrom = 'first';
      zone.page = this.state.currentSamplePage.pageId;
      //zone.show = false;
      zone.value = this.calculateZoneValue(zone);
      zone.splitType = 1;
      zone.templateIdentifier = this.calculateZoneValue(zone);
      zone.width = Math.round(zone.width);
      zone.height = Math.round(zone.height);
      currentZones.push(zone);

      this.setState({ currentTemplate: ct, currentZone: zone, isDirty: true });
      this.lookupFieldValues();
    }

    if (zoneType === this.parseZoneType('table')) {
      let nextZoneId = 1;
      currentZones.forEach((z) => {
        if (z.zoneName.startsWith('Table')) {
          let id = parseInt(z.zoneName.substring(5), 10);
          if (!isNaN(id)) {
            if (id >= nextZoneId) nextZoneId = id + 1;
          }
        }
      });

      let zone = this.state.newZone;
      zone.templateId = this.state.currentTemplate.templateId;
      zone.zoneType = zoneType;
      zone.templateZoneId = 0;
      zone.screenId = maxScreenId;
      zone.zoneName = 'Table' + nextZoneId.toString();
      zone.defaultValue = '';
      zone.offsetX = 0;
      zone.offsetY = 0;
      zone.fieldId = 0;
      zone.scanType = 0;
      zone.anchorZoneId = 0;
      zone.barcodeFormula = '';
      zone.removeSpaces = false;
      zone.removeCommas = false;
      zone.multiWordSelect = 'All';
      zone.readFrom = 'first';
      zone.page = this.state.currentSamplePage.pageId;
      //zone.show = false;
      zone.columns = [];
      zone.rows = [];
      zone.fieldSetId = null;
      zone.fieldSysName = null;
      zone.splitType = null;
      zone.value = '';
      zone.width = Math.round(zone.width);
      zone.height = Math.round(zone.height);

      let initialColumn = {
        isKeyColumn: true,
        fieldId: 0,
        endingOffset: Math.round(zone.width),
        screenId: maxScreenId + 1,
        zoneType: 5,
        columnName: 'Column1',
        regEx: '',
        templateZoneId: 0,
        minimumDataLength: 0,
        maximumDataLength: 0,
        xOffset: Math.round(zone.width),
        removeSpaces: false,
        removeCommas: false,
        x: 0,
        y: 0,
        width: Math.round(zone.width),
        height: Math.round(zone.height),
        multiLineRead: false,
      };
      zone.columns.push(initialColumn);
      //zone.value = this.calculateZoneValue(zone);
      zone.templateIdentifier = '';
      currentZones.push(zone);

      this.setState({
        currentTemplate: ct,
        currentZone: zone,
        currentColumn: initialColumn,
        isDirty: true,
      });
      this.calculateZoneValue(zone);
    }

    if (zoneType === this.parseZoneType('floating')) {
      let nextZoneId = 1;
      currentZones.forEach((z) => {
        if (z.zoneName.startsWith('Floating')) {
          let id = parseInt(z.zoneName.substring(8), 10);
          if (!isNaN(id)) {
            if (id >= nextZoneId) nextZoneId = id + 1;
          }
        }
      });

      let zone = this.state.newZone;
      zone.templateId = this.state.currentTemplate.templateId;
      zone.zoneType = zoneType;
      zone.templateZoneId = 0;
      zone.screenId = maxScreenId;
      zone.zoneName = 'Floating' + nextZoneId.toString();
      zone.fieldId = 0;
      zone.offsetX = 0;
      zone.offsetY = 0;
      zone.scanType = 0;
      zone.barcodeFormula = '';
      zone.width = Math.round(zone.width);
      zone.height = Math.round(zone.height);
      zone.readFrom = 'all';
      zone.page = 1;
      zone.value = this.calculateZoneValue(zone, true);

      //zone.barcodeFormulat = zone.value;
      currentZones.push(zone);

      this.setState({ currentTemplate: ct, currentZone: zone });
    }

    this.setState({ showZoneSelect: false });
  }

  parseZoneType(zoneType) {
    if (zoneType === 'capture') return 0;
    if (zoneType === 'identifier') return 1;
    if (zoneType === 'split') return 2;
    if (zoneType === 'table') return 3;
    if (zoneType === 'floating') return 4;
    if (zoneType === 'column') return 5;
    return 0;
  }

  setActiveZone(zone) {
    let noZone = {
      screenId: 0,
    };
    if (zone === undefined) {
      this.setState({ currentZone: noZone });
      this.setState({ currentColumn: noZone });
    } else {
      let z = this.state.currentTemplate.templateZones.find(
        (tz) => tz.screenId === zone.screenId
      );
      //z.value = 'readValue';
      if (z) {
        this.setState({ currentZone: z });
      } else if (zone.zoneType === 5) {
        this.setState({ currentZone: zone });
      }
      if (z.zoneType !== 3) {
        this.setState({ currentColumn: noZone });
      }
      if (z.zoneType === 3) {
        if (z.fieldSetId !== 0) {
          let tables = this.state.tables;
          let t = tables.find((fs) => fs.fieldSetId === z.fieldSetId);
          if (t) {
            this.setState({ currentTableFields: t.fields });
          }
        } else {
          this.setState({ currentTableFields: [] });
        }
      }
    }
  }

  setActiveColumn(column) {
    let zones = this.state.currentTemplate.templateZones;
    let parentZone = undefined;
    zones.forEach((z) => {
      if (z.zoneType === 3) {
        // table zone
        if (z.columns) {
          z.columns.forEach((col) => {
            if (col.screenId === column.screenId) {
              parentZone = z;
            }
          });
        }
      }
    });

    if (column === undefined) {
      let noColumn = {
        screenId: 0,
      };

      this.setState({ currentColumn: noColumn });
    } else {
      //let z = this.state.currentTemplate.templateZones.find(tz => tz.screenId === zone.screenId);
      //z.value = 'readValue';
      if (parentZone !== undefined) {
        this.setState({ currentZone: parentZone });
      }

      this.setState({ currentColumn: column });
    }
  }

  onSelectZoneClose() {
    this.setState({ showZoneSelect: false });
  }

  editSampleName(e) {
    this.setState({ sampleNameEdit: e.target.value });
  }

  closeSampleInfo() {
    this.setState({ showSampleInfo: false });
  }

  deleteSample(sampleId) {
    let samp = this.state.currentSample;
    DeleteSample(sampleId);
  }

  showSampleInfo(sampleId) {
    let sample = this.state.samples.find(f => f.sampleId === sampleId);
    if(sample)
    {
      this.setState({
        showSampleInfo: true,
        sampleNameEdit: sample.sampleName,
        currentSampleInfo: {
          sampleId: sample.sampleId,
          sampleName: sample.sampleName
        }
      });
      GetSampleInfo(sampleId);
    }
  }

  showDocSampleInfo()
  {
    let sample = this.state.docSample;
    GetDocSampleInfo(sample.sampleName);
  }

  deleteZone(z) {
    let t = this.state.currentTemplate;
    let idx = t.templateZones.indexOf(z);
    t.templateZones.forEach((z2) => {
      if (z2.anchorZoneId === z.templateZoneId) {
        z2.anchorZoneId = 0;
        z2.offsetX = null;
        z2.offsetY = null;
      }
    });
    t.templateZones.splice(idx, 1);
    let current = {
      screenId: 0,
    };

    this.setState({ currentZone: current, currentTemplate: t, isDirty: true });
  }

  deleteColumn(column) {
    let zones = this.state.currentTemplate.templateZones;
    let targetColumn = undefined;
    let targetTable = undefined;
    zones.forEach((z) => {
      if (z.zoneType === 3) {
        // table zone
        if (z.columns) {
          z.columns.forEach((col) => {
            if (col.screenId === column.screenId) {
              targetTable = z;
              targetColumn = col;
            }
          });
        }
      }
    });

    if (targetColumn !== undefined) {
      let idx = targetTable.columns.indexOf(targetColumn);
      if (targetTable.columns.length === 1) {
      } else if (idx === 0) {
        let rightCol = targetTable.columns[1];
        rightCol.x = targetColumn.x;

        rightCol.width = targetColumn.width + rightCol.width;
        rightCol.xOffset = rightCol.width;
        targetTable.columns.splice(0, 1);
      } else {
        let leftCol = targetTable.columns[idx - 1];
        leftCol.width = targetColumn.width + leftCol.width;
        leftCol.xOffset = leftCol.width;
        targetTable.columns.splice(idx, 1);
      }
      this.calculateZoneValue(targetTable);
    }
  }

  getNextScreenId() {
    let activeIds = [];
    let zones = this.state.currentTemplate.templateZones;
    zones.forEach((z) => {
      activeIds.push(z.screenId);
      if (z.zoneType === 3) {
        // table zone
        if (z.columns) {
          z.columns.forEach((col) => {
            activeIds.push(col.screenId);
          });
        }
      }
    });
    let maxId = Math.max(...activeIds);
    return maxId + 1;
  }

  splitColumn(zone, columnClickX) {
    // where is this column?
    let zones = this.state.currentTemplate.templateZones;
    let targetColumn = undefined;
    let targetTable = undefined;
    let nextScreenId = this.getNextScreenId();
    zones.forEach((z) => {
      if (z.zoneType === 3) {
        // table zone
        if (z.columns) {
          z.columns.forEach((col) => {
            if (col.screenId === zone.screenId) {
              targetTable = z;
              targetColumn = col;
            }
          });
        }
      }
    });

    if (targetColumn !== undefined) {
      let usedIds = [];
      let sortedTargetTableColumns = targetTable.columns.sort(function(a,b) {
        return a.xOffset - b.xOffset;
      });
      sortedTargetTableColumns.forEach((col) => {
        const re = new RegExp('Column[0-9]+');
        if (re.test(col.columnName)) {
          let numpart = col.columnName.substring(6);
          let num = parseInt(numpart, 10);
          if (num > 0) usedIds.push(num);
        }
      });
      let maxId = 1;
      if (usedIds.length > 0) {
        maxId = Math.max(...usedIds);
      }
      maxId++;

      let idx = sortedTargetTableColumns.indexOf(targetColumn);
      let newColumn = {
        isKeyColumn: false,
        endingOffset: Math.round(
          targetColumn.width + targetColumn.x - columnClickX + targetTable.x
        ),
        screenId: nextScreenId,
        zoneType: 5,
        columnName: 'Column' + maxId.toString(),
        templateZoneId: 0,
        minimumDataLength: 0,
        maximumDataLength: 0,
        removeSpaces: false,
        removeCommas: false,
        fieldId: 0,
        xOffset: Math.round((
          columnClickX - targetTable.x)
        ),
        regEx: '',
        multiLineRead: false,
        x: Math.round(columnClickX - targetTable.x),
        y: Math.round(targetColumn.y),
        width: Math.round(
          targetColumn.width + targetColumn.x - columnClickX + targetTable.x
        ),
        height: Math.round(targetColumn.height),
      };


      if (idx + 1 === sortedTargetTableColumns.length){
        // if we are splitting the last zone, need to calculate xoffset differently
        newColumn.xOffset = newColumn.x;
      }


      sortedTargetTableColumns.splice(idx + 1, 0, newColumn);
      targetColumn.width = Math.round(
        columnClickX - targetColumn.x - targetTable.x
      );


      targetColumn.xOffset = Math.round(
        targetColumn.xOffset - newColumn.width
      );

      this.calculateZoneValue(targetTable);
      this.setState({ currentColumn: newColumn, isDirty: true });
    }
  }

  showSaveTemplateAs() {
    let sa = this.state.showSaveAsDialog;
    let fts = TemplateStore.getFormTypes();
    if (!sa) {
      let status = { status: 'collecting', newTemplateId: 0, errorMessage: '' };
      this.setState({
        newTemplateName: '',
        formTypes: fts,
        saveAsStatus: status,
        showSaveAs: false,
      });
    } else {
      let status = { status: 'none', newTemplateId: 0, errorMessage: '' };
      this.setState({
        newTemplateName: '',
        formTypes: fts,
        saveAsStatus: status,
        showSaveAs: false,
      });
    }
    this.setState({ showSaveAsDialog: !sa });
  }

  editNewTemplateName(e) {
    this.setState({ newTemplateName: e.target.value });
  }

  editNewFT(e) {
    this.setState({ newFT: parseInt(e.target.value) });
  }

  createNewTemplate() {

    let name = this.state.newTemplateName;
    let ft = this.state.newFT;
    if (ft !== 0 && name !== '') {
      CreateTemplate(name, ft, this.state.currentTemplate.templateId);
      let status = { status: 'saving', newTemplateId: 0, errorMessage: '' };
      this.setState({
        newTemplateName: '',
        saveAsStatus: status,
      });
    }
  }

  updateTableVals() {
    let tables = [];
    if (this.state.currentTemplate.templateZones) {
      this.state.currentTemplate.templateZones.forEach((tzone) => {
        if (tzone.zoneType === 3) {

          let fs = this.state.tables.find(
            (t) => t.fieldSetId === tzone.fieldSetId
          );

          let table = {
            name: tzone.zoneName,
            fieldSetName: tzone.name,
            rows: tzone.rows,
            cols: [],
          };
          tzone.columns.forEach((col) => {
            let column = {
              Id: col.Id,
              name: col.columnName,
              fieldId: col.fieldId,
              fieldName: col.columnName,
              isMapped: false,
            };
            if (fs) {
              let field = fs.fields.find((f) => f.fieldId === col.fieldId);
              if (field) {
                column.isMapped = true;
                column.fieldName = field.name;
              }
            }
            table.cols.push(column);
          });
          if (fs) {
            table.fieldSetName = fs.name;
          }

          tables.push(table);
        }
      });
      this.setState({ tableCapture: tables });
    }
  }

  onShowTables() {
    let tables = [];
    if (this.state.currentTemplate.templateZones) {
      this.state.currentTemplate.templateZones.forEach((tzone) => {
        if (tzone.zoneType === 3) {
          let fs = this.state.tables.find(
            (t) => t.fieldSetId === tzone.fieldSetId
          );
          let table = {
            name: tzone.zoneName,
            fieldSetName: tzone.name,
            rows: tzone.rows,
            cols: [],
          };
          tzone.columns.forEach((col) => {
            let column = {
              Id: col.Id,
              name: col.columnName,
              fieldId: col.fieldId,
              fieldName: col.columnName,
              isMapped: false,
            };
            if (fs) {
              let field = fs.fields.find((f) => f.fieldId === col.fieldId);
              if (field) {
                column.isMapped = true;
                column.fieldName = field.name;
              }
            }
            table.cols.push(column);
          });
          if (fs) {
            table.fieldSetName = fs.name;
          }
          tables.push(table);
        }
      });
    }
    this.setState({ showTables: true, tableCapture: tables }, () => {
      console.log(this.state.tableCapture);
    });
  }

  onHideTables() {
    this.setState({ showTables: false });
  }

  onSaveAndNav() {
    this.updateTemplate();
    AcceptNav();
  }

  onStay() {
    this.setState({ showNav: false });
  }

  onDiscardAndNav() {
    AcceptNav();
  }

  downloadSample() {
    let sample = this.state.currentSampleInfo;
    console.log(sample);
    let samplefileName = `${this.state.currentTemplate.name}-${sample.sampleName}.pdf`;
    DownloadSamplePDF(sample.sampleId, samplefileName);

  }

  convertToSample() {
    let sample = this.state.currentSampleInfo;
    console.log(sample);
    ConvertToSample(sample.sampleName,  this.state.currentTemplate.templateId);
  }

  render() {
    return (
      <div className='sidebarLayout d-flex flex-row flex-fill'>
        <div className='flex-fill'>
          <div className='h-100 d-flex flex-column'>
            <div className='d-flex flex-row justify-content-between aligh-items-start te-toolbar1'>
              <div>
                {!this.state.showSamples && (
                  <button
                    className='btn btn-primary mx-1 my-1 float-start'
                    onClick={this.toggleShowSample}
                  >
                    <FontAwesomeIcon icon={regular('images')} /> Samples
                  </button>
                )}
                {this.state.showSamples && (
                  <button
                    className='btn btn-info mx-1 my-1 float-start'
                    onClick={this.toggleShowSample}
                  >
                    <FontAwesomeIcon icon={solid('list')} /> Fields
                  </button>
                )}
                <div className='dropdown float-start'>
                  <div className='btn-group mx-1 my-1 dropdown'>
                    <button
                      className='btn btn-success'
                      disabled={!this.state.isDirty}
                      onClick={this.updateTemplate}
                    >
                      <FontAwesomeIcon icon={solid('Save')} /> Save
                    </button>
                    <button
                      className='btn btn-success'
                      onClick={this.toggleSaveAs}
                    >
                      <FontAwesomeIcon icon={solid('chevron-down')} />
                    </button>
                  </div>
                  {this.state.showSaveAs && (
                    <div className='dropdown-content'>
                      <div className='dropdown-menuitem m-1 p-0'>
                        <div
                          className='m-1 p-2'
                          onClick={this.showSaveTemplateAs}
                        >
                          <FontAwesomeIcon
                            icon={regular('save')}
                            style={{
                              color: '#38C158',
                              marginRight: '8px',
                            }}
                          />{' '}
                          Save Template As...
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                <button
                  className='btn btn-sm mt-2'
                  onClick={this.toggleShowCapture}
                  style={{
                    backgroundColor: this.state.showCapture
                      ? '#FAFCD5'
                      : '#DDD',
                  }}
                >
                  Show capture data
                </button>
                {this.state.showCapture && (
                  <div className='btn-group ms-1'>
                    <button
                      className='btn btn-sm mt-2'
                      onClick={this.toggleShowOCR}
                      style={{
                        backgroundColor: this.state.showCaptureOCR
                          ? '#FF6F6F'
                          : '#DDD',
                      }}
                    >
                      OCR
                    </button>
                    <button
                      className='btn btn-sm mt-2'
                      onClick={this.toggleShowText}
                      style={{
                        backgroundColor: this.state.showCaptureText
                          ? '#A1FF7E'
                          : '#DDD',
                      }}
                    >
                      Text
                    </button>
                  </div>
                )}
              </div>
              {!this.state.editingName && (
                <div>
                  <span className='te-templateName'>
                    {this.state.currentTemplate.name}
                  </span>
                  <FontAwesomeIcon
                    onClick={this.toggleEditName}
                    className='ms-2 te-editbutton'
                    icon={solid('pencil')}
                  />
                </div>
              )}
              {this.state.editingName && (
                <div className='row'>
                  <div className='col-auto pe-0'>
                    <label
                      className='form-label mt-2 '
                      style={{ fontSize: '15pt' }}
                    >
                      Name:{' '}
                    </label>
                  </div>
                  <div className='col-auto'>
                    <input
                      id='in-temp-name'
                      className='form-control mt-1'
                      value={this.state.currentTemplate.name}
                      onChange={this.editTemplateName}
                    />
                  </div>
                  <div className='col-auto'>
                    <button
                      className='btn btn-success btn-sm mt-2'
                      onClick={this.toggleEditName}
                    >
                      <FontAwesomeIcon icon={solid('check')} />
                    </button>
                  </div>
                </div>
              )}
              <div>
                <table style={{ marginTop: '8px', marginRight: '8px' }}>
                  <tbody>
                    <tr>
                      <td style={{ paddingRight: '20px' }}>
                        <div className='form-check form-switch'>
                          <input
                            className='form-check-input'
                            type='checkbox'
                            checked={this.state.currentTemplate.isActive}
                            onChange={this.toggleActive}
                          />
                          {this.state.currentTemplate.isActive && (
                            <label className='form-check-label'>Active</label>
                          )}
                          {!this.state.currentTemplate.isActive && (
                            <label className='form-check-label'>Inactive</label>
                          )}
                        </div>
                      </td>
                      <td>
                        <div>
                          {!this.state.isDirty && (
                            <Link to='/TemplateEditor'>
                              <button
                                className='btn-close'
                                aria-label='close'
                              ></button>
                            </Link>
                          )}
                          {this.state.isDirty && (
                            <button
                              className='btn-close'
                              disabled
                              aria-label='close'
                            ></button>
                          )}
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className='d-flex flex-row flex-fill'>
              {this.state.showSamples && (
                <div
                  className='coolScroll'
                  style={{
                    width: '200px',
                    backgroundColor: '#333',
                    textAlign: 'center',
                    maxHeight: this.state.imageviewerHeight + 30,
                    overflow: 'auto',
                  }}
                >
                  {this.state.samples.map((sample) => (
                    <div
                      className='template-thumbnail'
                      key={sample.sampleId}

                    >
                      <img
                        alt={`sample${sample.sampleId}`}
                        style={{ maxHeight: '120px', maxWidth: '120px' }}
                        src={sample.base64Thumb}
                        onClick={() => this.onSwitchSample(sample.sampleId)}
                        className='template-image'
                      ></img>
                      <div className='mt-2'>
                      <button className='btn btn-sm btn-primary mtm-2' onClick={() => this.showSampleInfo(sample.sampleId)} style={{fontWeight: 'bold'}}>
                      <FontAwesomeIcon icon={solid('info-circle')} /> {sample.sampleName}
                      </button>
                      </div>
                      <div className='mt-2'>
                      <button onClick={() => this.deleteSample(sample.sampleId)} className='btn btn-sm btn-danger mtm-2'>
                        Delete
                      </button>
                      </div>

                    </div>
                  ))}
                   <button
                    className='btn btn-primary'
                    style={{ marginTop: '12px' }}
                    onClick={this.onCreateSample}
                  >
                    Create sample
                  </button>
                  <hr style={{color:'#fff'}} />

                  {this.state.docSample !== undefined &&
                      <div
                      className='template-thumbnail'
                    >
                      <img
                        alt={`docSample`}
                        style={{ maxHeight: '120px', maxWidth: '120px' }}
                        src={this.state.docSample.base64Thumb}
                        onClick={() => this.onSwitchDocSample()}
                        className='template-image'
                      ></img>
                      <div className='mt-2'>
                      <button className='btn btn-sm btn-success mtm-2' onClick={() => this.showDocSampleInfo()} style={{fontWeight: 'bold'}}>
                      <FontAwesomeIcon icon={solid('info-circle')} /> {this.state.docSample.sampleName}
                      </button>
                      </div>


                    </div>
                  }


                </div>
              )}
              {!this.state.showSamples && (
                <div
                  style={{
                    width: 'auto',
                    backgroundColor: '#F2F2F2',
                    overflow: 'auto',
                    maxWidth: '250px',
                    minWidth: '150px',
                  }}
                >
                  <div
                    className='p-2'
                    style={{
                      backgroundColor: '#CCFFFD',
                      borderBottom: 'solid 1px #84D8D5',
                      width: 'auto',
                      maxWidth: '250px',
                      overflow: 'auto',
                      minWidth: '150px',
                    }}
                  >
                    Fields
                  </div>
                  {this.state.templateIdentifies === false && (
                    <div
                      className='p-2'
                      style={{ fontSize: '10pt', color: '#858585' }}
                    >
                      <div>
                        <FontAwesomeIcon icon={regular('stop-circle')} /> Not a
                        match
                      </div>
                    </div>
                  )}
                  {this.state.templateIdentifies === true && (
                    <div className='p-2' style={{ fontSize: '10pt' }}>
                      <div>
                        <FontAwesomeIcon
                          style={{ color: '#3C8F2A' }}
                          icon={regular('check-circle')}
                        />{' '}
                        Template identifies
                      </div>
                    </div>
                  )}
                  <div className='p-1'>
                    <table>
                      <tbody>
                        {this.state.headers.map((head) => (
                          <tr key={head.fieldId} style={{ fontSize: '9pt' }}>
                            <td style={{ borderBottom: 'solid 1px #9BD4CF' }}>
                              <strong>{head.name}:</strong>
                            </td>
                            <td
                              style={{
                                borderBottom: 'solid 1px #9BD4CF',
                                textAlign: 'right',
                              }}
                            >
                              {head.value}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                  <button
                    onClick={this.onShowTables}
                    className='btn btn-primary btn-sm ms-2 mt-1'
                  >
                    Show Tables ...
                  </button>
                </div>
              )}
              <div id='te-image-container' className='flex-fill' style={{position: 'relative'}}>
                <TemplateImageViewer
                  scale='80'
                  captureLayer={this.state.captureLayer}
                  pageData={this.state.currentSamplePage.pageData}
                  viewHeight={this.state.imageviewerHeight}
                  base64={this.state.currentSamplePage.image.fullBytes}
                  viewWidth={this.state.imageviewerWidth}
                  imageCount={this.state.currentSample.pageCount}
                  currentPage={this.state.currentSamplePage.pageId}
                  currentZone={this.state.currentZone}
                  currentColumn={this.state.currentColumn}
                  imageHeight={this.state.currentSamplePage.image.height}
                  imageWidth={this.state.currentSamplePage.image.width}
                  zones={this.state.currentTemplate.templateZones}
                  updateZoneGeometry={this.updateZoneGeometry}
                  initializeNewZone={this.initializeNewZone}
                  setActiveZone={this.setActiveZone}
                  setActiveColumn={this.setActiveColumn}
                  onPageNext={this.setActiveImage}
                  showSampleInfo={this.showSampleInfo}
                  sampleName={this.state.currentSample.sampleName}
                  deleteZone={this.deleteZone}
                  splitColumn={this.splitColumn}
                  deleteColumn={this.deleteColumn}
                  reCalculate={this.calculateZoneValue}
                />
                {this.state.showTables && (
                <div style={{background: '#fff', position:'absolute', left: '0px', top: `${this.state.imageviewerHeight-150}px`, width: `${this.state.imageviewerWidth-20}px`, height: '182px'}}>
                  <div className='table-header' style={{height: '25px', paddingLeft: '4px'}}>
                    Tables
                    <button onClick={this.onHideTables} className='btn-close' />
                  </div>
                  <div style={{height: '157px', overflow: 'auto'}}>
                  {this.state.tableCapture.map((table) => (
                  <div key={table.name}>
                    <div style={{ fontSize: '18pt' }}>
                      <FontAwesomeIcon
                        style={{ color: '#D35400' }}
                        icon={solid('table')}
                      />{' '}
                      {table.fieldSetName}
                    </div>
                    <table className='teplate-tableResult'>
                      <thead>
                        <tr>
                          {table.cols.map((col) => (
                            <th
                              style={{
                                color: col.isMapped ? '#000' : '#C8C8C8',
                              }}
                              key={col.Id}
                            >
                              {col.fieldName}
                            </th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {table.rows.map((row) => (
                          <tr key={row.rowId}>
                            {row.cells.map((cell) => (
                              <td key={cell.id}>{cell.value}</td>
                            ))}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                ))}
                  </div>
                </div>
                )}
              </div>
              <div
                className='coolScroll'
                style={{
                  width: '280px',
                  height: this.state.imageviewerHeight + 30,
                  background: '#51657A',
                  borderLeft: 'solid 1px #303030',
                  overflow: 'auto',
                }}
              >
                {this.state.currentZone !== undefined &&
                  this.state.currentZone.screenId !== 0 && (
                    <div>
                      {this.state.currentZone.zoneType ===
                        this.parseZoneType('capture') && (
                        <div className='te-capture-header'>Capture Zone</div>
                      )}
                      {this.state.currentZone.zoneType ===
                        this.parseZoneType('floating') && (
                        <div className='te-scan-header'>Floating Zone</div>
                      )}
                      {this.state.currentZone.zoneType ===
                        this.parseZoneType('identifier') && (
                        <div className='te-identifier-header'>
                          Identifier Zone
                        </div>
                      )}
                      {this.state.currentZone.zoneType ===
                        this.parseZoneType('split') && (
                        <div className='te-split-header'>Split Zone</div>
                      )}
                      {this.state.currentZone.zoneType ===
                        this.parseZoneType('table') && (
                        <div className='te-table-header'>Table Zone</div>
                      )}

                      <div className='te-edit-area'>
                        <div className='p-1'>
                          <label className='form-label form-label-sm'>
                            Name
                          </label>
                          <input
                            type='text'
                            value={this.state.currentZone.zoneName}
                            onChange={(e) =>
                              this.editZoneValue(
                                e,
                                this.state.currentZone,
                                'zoneName'
                              )
                            }
                            className='form-control form-control-sm'
                            placeholder='zone name'
                          />
                        </div>
                        {this.state.currentZone.zoneType ===
                          this.parseZoneType('capture') && (
                          <div className='p-1'>
                            <label className='form-label form-label-sm'>
                              Field
                            </label>
                            <select
                              value={this.state.currentZone.fieldId}
                              onChange={(e) =>
                                this.editZoneValue(
                                  e,
                                  this.state.currentZone,
                                  'zoneFieldId'
                                )
                              }
                              className='form-select form-select-sm'
                              placeholder='select a field'
                            >
                              <option value='0'></option>
                              {this.state.headers.map((head) => (
                                <option key={head.fieldId} value={head.fieldId}>
                                  {head.name}
                                </option>
                              ))}
                            </select>
                          </div>
                        )}
                        {this.state.currentZone.zoneType ===
                          this.parseZoneType('table') && (
                          <div className='p-1'>
                            <label className='form-label form-label-sm'>
                              Destination Table
                            </label>
                            <select
                              value={this.state.currentZone.fieldSetId}
                              onChange={(e) =>
                                this.editZoneValue(
                                  e,
                                  this.state.currentZone,
                                  'fieldSetId'
                                )
                              }
                              className='form-select form-select-sm'
                              placeholder='select a field'
                            >
                              <option value='0'></option>
                              {this.state.tables.map((table) => (
                                <option
                                  key={table.fieldSetId}
                                  value={table.fieldSetId}
                                >
                                  {table.name}
                                </option>
                              ))}
                            </select>
                          </div>
                        )}
                      </div>
                      <div className='te-edit-area'>
                        {this.state.currentZone.zoneType ===
                          this.parseZoneType('capture') && (
                          <div className='p-1'>
                            <label className='form-label form-label-sm'>
                              Default Value
                            </label>
                            <input
                              value={this.state.currentZone.defaultValue}
                              onChange={(e) =>
                                this.editZoneValue(
                                  e,
                                  this.state.currentZone,
                                  'defaultValue'
                                )
                              }
                              type='text'
                              className='form-control form-control-sm'
                            />
                          </div>
                        )}
                        {this.state.currentZone.zoneType !==
                          this.parseZoneType('table') && (
                          <div className='p-1'>
                            <label className='form-label form-label-sm'>
                              Extract Method
                            </label>
                            <select
                              className='form-select form-select-sm'
                              value={this.state.currentZone.scanType}
                              onChange={(e) =>
                                this.editZoneValue(
                                  e,
                                  this.state.currentZone,
                                  'extractMethod'
                                )
                              }
                              placeholder='select a field'
                            >
                              <option value='0'>Any text</option>
                              <option value='2'>OCR only</option>
                              <option value='1'>Barcode</option>
                              <option value='3'>Barcode (FullPage)</option>
                            </select>
                          </div>
                        )}
                        {(this.state.currentZone.zoneType ===
                          this.parseZoneType('capture') ||
                          this.state.currentZone.zoneType ===
                            this.parseZoneType('table')) && (
                          <div className='p-1'>
                            <label className='form-label form-label-sm'>
                              Floating Point
                            </label>
                            <select
                              value={this.state.currentZone.anchorZoneId}
                              onChange={(e) =>
                                this.editZoneValue(
                                  e,
                                  this.state.currentZone,
                                  'anchor'
                                )
                              }
                              className='form-select form-select-sm'
                              placeholder='select a field'
                            >
                              <option value='0'>None</option>
                              {this.state.currentTemplate.templateZones
                                .filter(
                                  (z) =>
                                    z.zoneType ===
                                    this.parseZoneType('floating')
                                )
                                .map((sz) => (
                                  <option key={sz.screenId} value={sz.screenId}>
                                    {sz.zoneName}
                                  </option>
                                ))}
                            </select>
                          </div>
                        )}
                        {this.state.currentZone.zoneType === 3 && (
                          <div className='ps-2'>
                            <button
                              className='btn btn-danger m2-3'
                              onClick={() =>
                                this.deleteZone(this.state.currentZone)
                              }
                            >
                              Delete zone
                            </button>
                          </div>
                        )}

                        {this.state.currentZone.zoneType ===
                          this.parseZoneType('split') && (
                          <div className='p-1'>
                            <label className='form-label form-label-sm'>
                              Split Type
                            </label>
                            <select
                              className='form-select form-select-sm'
                              value={this.state.currentZone.splitType}
                              onChange={(e) =>
                                this.editZoneValue(
                                  e,
                                  this.state.currentZone,
                                  'splitType'
                                )
                              }
                              placeholder='split type'
                            >
                              <option value='1'>Split on Match</option>
                              <option value='0'>Split on Difference</option>
                            </select>
                          </div>
                        )}

                        {this.state.currentZone.zoneType ===
                          this.parseZoneType('split') &&
                          this.state.currentZone.splitType === 1 && (
                            <div className='p-1'>
                              <label className='form-label form-label-sm'>
                                Matching Value
                              </label>
                              <input
                                value={
                                  this.state.currentZone.templateIdentifier
                                }
                                onChange={(e) =>
                                  this.editZoneValue(
                                    e,
                                    this.state.currentZone,
                                    'templateIdentifier'
                                  )
                                }
                                type='text'
                                className='form-control form-control-sm'
                              />
                            </div>
                          )}

                        {this.state.currentZone.zoneType ===
                          this.parseZoneType('identifier') && (
                          <div className='p-1'>
                            <label className='form-label form-label-sm'>
                              Matching Value
                            </label>
                            <input
                              value={this.state.currentZone.templateIdentifier}
                              onChange={(e) =>
                                this.editZoneValue(
                                  e,
                                  this.state.currentZone,
                                  'templateIdentifier'
                                )
                              }
                              disabled={this.state.currentZone.barcodeFormula !== ''}
                              type='text'
                              className='form-control form-control-sm'
                            />
                            {this.state.currentZone.barcodeFormula !== '' && <span style={{fontSize:'8pt',}}><i>Using Regular expression instead</i></span>}
                          </div>
                        )}
                        {this.state.currentZone.zoneType ===
                          this.parseZoneType('identifier') && (
                        <div className='p-1'>
                            <label className='form-label form-label-sm'>
                              Match Type
                            </label>
                            <select
                              className='form-select form-select-sm'
                              value={this.state.currentZone.splitType}
                              onChange={(e) =>
                                this.editZoneValue(
                                  e,
                                  this.state.currentZone,
                                  'splitType'
                                )
                              }
                              placeholder='split type'
                            >
                              <option value='1'>Required</option>
                              <option value='0'>Optional</option>
                            </select>
                          </div>
                           )}
                      </div>

                      {this.state.currentZone.zoneType !== 3 && (
                        <div
                          className='te-advance-options'
                          onClick={this.toggleAdvance}
                          style={{ cursor: 'pointer' }}
                        >
                          {this.state.showAdvanceSettings && (
                            <FontAwesomeIcon icon={solid('chevron-down')} />
                          )}
                          {!this.state.showAdvanceSettings && (
                            <FontAwesomeIcon icon={solid('chevron-right')} />
                          )}{' '}
                          Advanced Options
                        </div>
                      )}
                      {this.state.showAdvanceSettings &&
                        this.state.currentZone.zoneType !== 3 && (
                          <>
                            <div className='te-edit-area'>
                              <div className='p-1'>
                                <label className='form-label form-label-sm'>
                                  Regex formula
                                </label>
                                <input
                                  type='text'
                                  value={this.state.currentZone.barcodeFormula}
                                  onChange={(e) =>
                                    this.editZoneValue(
                                      e,
                                      this.state.currentZone,
                                      'barcodeFormula'
                                    )
                                  }
                                  className='form-control form-control-sm'
                                />
                              </div>
                              {this.state.currentZone.zoneType === 0 && (
                              <div className='p-1'>
                                    <label className='form-label form-label-sm'>
                                      Regex behavior
                                    </label>
                                    <select
                                      className='form-select form-select-sm'
                                      value={this.state.currentZone.regExBehavior}
                                      onChange={(e) =>
                                        this.editZoneValue(
                                          e,
                                          this.state.currentZone,
                                          'regExBehavior'
                                        )
                                      }
                                      placeholder='select custom behavior'
                                    >
                                      <option value='matchingWords'>
                                        Matching words only
                                      </option>
                                      <option value='matchingPhrase'>
                                        Matching phrase capture
                                      </option>
                                      <option value='replacement'>
                                        Replacement match
                                      </option>
                                      <option value='appendStart'>
                                        Append start
                                      </option>
                                      <option value='appendEnd'>
                                        Append end
                                      </option>
                                      <option value='remove'>
                                        Remove
                                      </option>
                                      <option value='all'>All pages</option>
                                    </select>
                              </div>
                              )}
                              <div className='p-1'>
                                <input
                                  className='form-check-input'
                                  type='checkbox'
                                  checked={this.state.currentZone.removeSpaces}
                                  onChange={(e) =>
                                    this.editZoneValue(
                                      e,
                                      this.state.currentZone,
                                      'removeSpaces'
                                    )
                                  }
                                  style={{ paddingTop: '4px' }}
                                />
                                <label className='form-label form-label-sm'>
                                  Remove spaces
                                </label>
                              </div>
                              <div className='p-1'>
                                <input
                                  className='form-check-input'
                                  type='checkbox'
                                  checked={this.state.currentZone.removeCommas}
                                  onChange={(e) =>
                                    this.editZoneValue(
                                      e,
                                      this.state.currentZone,
                                      'removeCommas'
                                    )
                                  }
                                  style={{ paddingTop: '4px' }}
                                />
                                <label className='form-label form-label-sm'>
                                  Remove commas
                                </label>
                              </div>

                            </div>

                            <div className='te-edit-area'>
                              <div className='p-1'>
                                {this.state.currentZone.zoneType !==
                                  this.parseZoneType('split') && (
                                  <div className='p-1'>
                                    <label className='form-label form-label-sm'>
                                      Page(s) to read from
                                    </label>
                                    <select
                                      className='form-select form-select-sm'
                                      value={this.state.currentZone.readFrom}
                                      onChange={(e) =>
                                        this.editZoneValue(
                                          e,
                                          this.state.currentZone,
                                          'readFrom'
                                        )
                                      }
                                      placeholder='select pages to read'
                                    >
                                      <option value='first'>
                                        First page only
                                      </option>
                                      <option value='last'>
                                        Last page only
                                      </option>
                                      <option value='firstv'>
                                        First page with value
                                      </option>
                                      <option value='lastv'>
                                        Last page with value
                                      </option>
                                      <option value='start'>
                                        Specific page from beginning
                                      </option>
                                      <option value='end'>
                                        Specific page from end
                                      </option>
                                      <option value='all'>All pages</option>
                                    </select>
                                  </div>
                                )}
                                {this.state.currentZone.zoneType !==
                                  this.parseZoneType('split') && (this.state.currentZone.readFrom === 'start' || this.state.currentZone.readFrom === 'end') && (
                                  <div className='p-1'>
                                    <label className='form-label form-label-sm'>
                                      Page Number
                                    </label>
                                    <input
                                      type='text'
                                      className='form-control form-control-sm'
                                      value={this.state.currentZone.page}
                                      onChange={(e) =>
                                        this.editZoneValue(
                                          e,
                                          this.state.currentZone,
                                          'pageId'
                                        )
                                      }
                                    />
                                  </div>
                                )}
                              </div>
                              </div>
                              <div className='te-edit-area'>

                              {this.state.currentZone.zoneType !==
                                this.parseZoneType('split') && (
                                <div className='p-1'>
                                  <label className='form-label form-label-sm'>
                                    Multi word select
                                  </label>
                                  <select
                                    className='form-select form-select-sm'
                                    value={
                                      this.state.currentZone.multiWordSelect
                                    }
                                    onChange={(e) =>
                                      this.editZoneValue(
                                        e,
                                        this.state.currentZone,
                                        'multiWordSelect'
                                      )
                                    }
                                  >
                                    <option value='All'>All words in zone</option>
                                    <option value='First'>First word in zone</option>
                                    <option value='Last'>Last word in zone</option>
                                  </select>
                                </div>
                              )}
                              <div className='ps-2 mt-3'>
                                <button
                                  className='btn btn-danger m2-3'
                                  onClick={() =>
                                    this.deleteZone(this.state.currentZone)
                                  }
                                >
                                  Delete zone
                                </button>
                              </div>
                            </div>
                          </>
                        )}

                      {this.state.currentColumn.screenId !== 0 && (
                        <>
                          <div className='te-table-header'>Column</div>
                          <div className='te-edit-area'>
                            <div className='p-1'>
                              <label className='form-label form-label-sm'>
                                Name
                              </label>
                              <input
                                type='text'
                                value={this.state.currentColumn.columnName}
                                onChange={(e) =>
                                  this.editColumnValue(e, 'columnName')
                                }
                                className='form-control form-control-sm'
                                placeholder='zone name'
                              />
                            </div>
                            <div className='p-1'>
                              <label className='form-label form-label-sm'>
                                Field
                              </label>
                              <select
                                value={this.state.currentColumn.fieldId}
                                onChange={(e) =>
                                  this.editColumnValue(e, 'zoneFieldId')
                                }
                                className='form-select form-select-sm'
                                placeholder='select a field'
                              >
                                <option value='0'></option>
                                {this.state.currentTableFields.map((field) => (
                                  <option
                                    key={field.fieldId}
                                    value={field.fieldId}
                                  >
                                    {field.name}
                                  </option>
                                ))}
                              </select>
                            </div>
                            <div className='p-1'>
                              <input
                                className='form-check-input'
                                type='checkbox'
                                checked={this.state.currentColumn.isKeyColumn}
                                onChange={(e) =>
                                  this.editColumnValue(e, 'isKeyColumn')
                                }
                                style={{ paddingTop: '4px' }}
                              />
                              <label className='form-label form-label-sm'>
                                Key Column
                              </label>
                            </div>
                          </div>
                          <div
                            className='te-advance-options'
                            onClick={this.toggleAdvance}
                            style={{ cursor: 'pointer' }}
                          >
                            {this.state.showAdvanceSettings && (
                              <FontAwesomeIcon icon={solid('chevron-down')} />
                            )}
                            {!this.state.showAdvanceSettings && (
                              <FontAwesomeIcon icon={solid('chevron-right')} />
                            )}{' '}
                            Advanced Options
                          </div>
                          {this.state.showAdvanceSettings && (
                            <div className='te-edit-area'>
                              <div className='p-1'>
                                <label className='form-label form-label-sm'>
                                  Regex formula
                                </label>
                                <input
                                  type='text'
                                  value={this.state.currentColumn.regEx}
                                  onChange={(e) =>
                                    this.editColumnValue(e, 'regEx')
                                  }
                                  className='form-control form-control-sm'
                                />
                              </div>
                              <div className='p-1'>
                                <input
                                  className='form-check-input'
                                  type='checkbox'
                                  checked={
                                    this.state.currentColumn.removeSpaces
                                  }
                                  onChange={(e) =>
                                    this.editColumnValue(e, 'removeSpaces')
                                  }
                                  style={{ paddingTop: '4px' }}
                                />
                                <label className='form-label form-label-sm'>
                                  Remove spaces
                                </label>
                              </div>
                              <div className='p-1'>
                                <input
                                  className='form-check-input'
                                  type='checkbox'
                                  checked={
                                    this.state.currentColumn.removeCommas
                                  }
                                  onChange={(e) =>
                                    this.editColumnValue(e, 'removeCommas')
                                  }
                                  style={{ paddingTop: '4px' }}
                                />
                                <label className='form-label form-label-sm'>
                                  Remove commas
                                </label>
                              </div>
                              <div className='p-1'>
                                <label className='form-label form-label-sm'>
                                  Max value length
                                </label>
                                <input
                                  type='text'
                                  value={
                                    this.state.currentColumn.maximumDataLength
                                  }
                                  onChange={(e) =>
                                    this.editColumnValue(e, 'maximumDataLength')
                                  }
                                  className='form-control form-control-sm'
                                />
                              </div>
                              <div className='p-1'>
                                <label className='form-label form-label-sm'>
                                  Min value length
                                </label>
                                <input
                                  type='text'
                                  value={
                                    this.state.currentColumn.minimumDataLength
                                  }
                                  onChange={(e) =>
                                    this.editColumnValue(e, 'minimumDataLength')
                                  }
                                  className='form-control form-control-sm'
                                />
                              </div>
                              <div className='p-1'>
                                <input
                                  className='form-check-input'
                                  type='checkbox'
                                  checked={
                                    this.state.currentColumn.multiLineRead
                                  }
                                  onChange={(e) =>
                                    this.editColumnValue(e, 'multiLineRead')
                                  }
                                  style={{ paddingTop: '4px' }}
                                />
                                <label className='form-label form-label-sm'>
                                  MultiLine capture
                                </label>
                              </div>
                              <div className='ps-2'>
                                <button
                                  className='btn btn-danger m2-3'
                                  onClick={() =>
                                    this.deleteZone(this.state.currentZone)
                                  }
                                >
                                  Delete zone
                                </button>
                              </div>
                            </div>
                          )}
                        </>
                      )}
                    </div>
                  )}
                <div className='te-node-tree'>
                  <div className='te-node-1'>
                    <FontAwesomeIcon icon={solid('circle')} size='2xs' />{' '}
                    Capture Zones
                  </div>
                  {this.state.currentTemplate.templateZones
                    .filter((z) => z.zoneType === this.parseZoneType('capture'))
                    .map((cz) => (
                      <div
                        key={cz.screenId}
                        className='te-node-2 te-node-capture'
                        onClick={() => this.setActiveZone(cz)}
                      >
                        <FontAwesomeIcon icon={solid('vector-square')} />{' '}
                        {cz.zoneName}
                        {' { '}
                        {cz.value}
                        {' }'}
                      </div>
                    ))}
                  <div className='te-node-1'>
                    <FontAwesomeIcon icon={solid('circle')} size='2xs' />{' '}
                    Identifier Zones
                  </div>
                  {this.state.currentTemplate.templateZones
                    .filter(
                      (z) => z.zoneType === this.parseZoneType('identifier')
                    )
                    .map((cz) => (
                      <div
                        key={cz.screenId}
                        className='te-node-2 te-node-identifier'
                        onClick={() => this.setActiveZone(cz)}
                      >
                        <FontAwesomeIcon icon={solid('vector-square')} />{' '}
                        {cz.zoneName}
                        {' { '}
                        {cz.value}
                        {' }'}
                      </div>
                    ))}
                  <div className='te-node-1'>
                    <FontAwesomeIcon icon={solid('circle')} size='2xs' /> Split
                    Zones
                  </div>
                  {this.state.currentTemplate.templateZones
                    .filter((z) => z.zoneType === this.parseZoneType('split'))
                    .map((cz) => (
                      <div
                        key={cz.screenId}
                        className='te-node-2 te-node-split'
                        onClick={() => this.setActiveZone(cz)}
                      >
                        <FontAwesomeIcon icon={solid('vector-square')} />{' '}
                        {cz.zoneName}
                        {' { '}
                        {cz.value}
                        {' }'}
                      </div>
                    ))}
                  <div className='te-node-1'>
                    <FontAwesomeIcon icon={solid('circle')} size='2xs' /> Table
                    Zones
                  </div>
                  {this.state.currentTemplate.templateZones
                    .filter((z) => z.zoneType === this.parseZoneType('table'))
                    .map((tz) => (
                      <React.Fragment key={tz.screenId}>
                        <div
                          className='te-node-2 te-node-table'
                          onClick={() => this.setActiveZone(tz)}
                        >
                          <FontAwesomeIcon icon={solid('vector-square')} />{' '}
                          {tz.zoneName}
                        </div>
                        {tz.columns.map((col) => (
                          <div
                            key={col.screenId}
                            onClick={() => this.setActiveColumn(col)}
                            className='te-node-3 te-node-column'
                          >
                            <FontAwesomeIcon icon={solid('table-columns')} />{' '}
                            {col.columnName}
                          </div>
                        ))}
                      </React.Fragment>
                    ))}
                  <div className='te-node-1'>
                    <FontAwesomeIcon icon={solid('circle')} size='2xs' />{' '}
                    Floating Zones
                  </div>
                  {this.state.currentTemplate.templateZones
                    .filter(
                      (z) => z.zoneType === this.parseZoneType('floating')
                    )
                    .map((cz) => (
                      <div
                        key={cz.screenId}
                        className='te-node-2 te-node-scan'
                        onClick={() => this.setActiveZone(cz)}
                      >
                        <FontAwesomeIcon icon={solid('vector-square')} />{' '}
                        {cz.zoneName}
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </div>
        </div>
        {this.state.showZoneSelect && (
          <div className='td-dialogue-container'>
            <div className='td-dialoge card'>
              <div className='card-header'>
                Select zone type:{' '}
                <button
                  type='button'
                  style={{ float: 'right' }}
                  className='btn-close'
                  onClick={this.onSelectZoneClose}
                />
              </div>
              <div className='card-body'>
                <button
                  onClick={() => this.createZone(this.parseZoneType('capture'))}
                  className='btn btn-primary'
                >
                  Capture
                </button>
                <button
                  onClick={() => this.createZone(this.parseZoneType('split'))}
                  className='btn btn-danger ms-2'
                >
                  Split
                </button>
                <button
                  onClick={() =>
                    this.createZone(this.parseZoneType('identifier'))
                  }
                  className='btn btn-success ms-2'
                >
                  Identifier
                </button>
                <button
                  onClick={() => this.createZone(this.parseZoneType('table'))}
                  className='btn btn-warning ms-2'
                >
                  Table
                </button>
                <button
                  onClick={() =>
                    this.createZone(this.parseZoneType('floating'))
                  }
                  className='btn ms-2'
                  style={{ backgroundColor: '#FFFBC7' }}
                >
                  Floating
                </button>
              </div>
            </div>
          </div>
        )}

        {this.state.showSampleDialogue && (
          <div className='td-dialogue-container'>
            <div
              className='td-dialogue card'
              style={{ width: '500px', height: this.state.newSampleHeight }}
            >
              <div className='card-header'>
                New sample{' '}
                <button
                  type='button'
                  className='btn-close'
                  onClick={this.onSampleClose}
                />
              </div>
              <div className='card-body'>
                <div className='row g-3'>
                  <div className='col-auto'>
                    <label className='col-form-label'>Number of pages:</label>
                  </div>
                  <div className='col-auto'>
                    <input
                      type='text'
                      className='form-control'
                      value={this.state.currentDrop.imageCount}
                      onChange={this.editDropImageCount}
                      style={{ width: '40px' }}
                    />
                  </div>
                  <div className='col-auto'>
                    <div className='form-check form-check-inline mt-2'>
                      <input
                        className='form-check-input'
                        type='checkbox'
                        checked={
                          this.state.currentDrop.includeLastPage === true
                        }
                        onChange={(e) => this.editIncludeLastPage(e)}
                        value='lastPage'
                      />
                      <label className='form-check-label'>
                        Include last page
                      </label>
                    </div>
                  </div>
                </div>

                <div style={{ marginTop: '12px' }}>
                <div className='form-check form-check-inline'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      checked={(this.state.currentDrop.ocrType === 1 || this.state.currentDrop.ocrType === 2 || this.state.currentDrop.ocrType === 3)}
                      onChange={() => this.editOCRType(1)}
                      value='fullOCR'
                    />
                    <label className='form-check-label'>OCR 1.0</label>
                  </div>
                  {(this.state.currentDrop.ocrType === 1 || this.state.currentDrop.ocrType === 2 || this.state.currentDrop.ocrType === 3) && (
                    <span>
                      <div className='form-check form-check-inline'>
                        <input
                          className='form-check-input'
                          type='checkbox'
                          checked={this.state.currentDrop.ocrType === 1}
                          onChange={() => this.editOCRType(1)}
                          value='textOnly'
                        />
                        <label className='form-check-label'>Text Only</label>
                      </div>
                      <div className='form-check form-check-inline'>
                        <input
                          className='form-check-input'
                          type='checkbox'
                          checked={this.state.currentDrop.ocrType === 2}
                          onChange={() => this.editOCRType(2)}
                          value='smartOCR'
                        />
                        <label className='form-check-label'>Smart OCR</label>
                      </div>
                      <div className='form-check form-check-inline'>
                        <input
                          className='form-check-input'
                          type='checkbox'
                          checked={this.state.currentDrop.ocrType === 3}
                          onChange={() => this.editOCRType(3)}
                          value='fullOCR'
                        />
                        <label className='form-check-label'>Full OCR</label>
                      </div>
                    </span>
                  )}
                  <br></br>
                  <div className='form-check form-check-inline'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      checked={this.state.currentDrop.ocrType === 4}
                      onChange={() => this.editOCRType(4)}
                      value='fullOCR'
                    />
                    <label className='form-check-label'>OCR 2.0</label>
                  </div>
                  <FontAwesomeIcon
                    icon={solid('circle-info')}
                    style={{ color: 'black' }}
                    onClick={this.toggleOCRDescriptions}
                    cursor='pointer'
                  />{' '}
                </div>
                <div className='mt-2'> OCR Quality: </div>
                <div>
                  <div className='form-check form-check-inline'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      checked={this.state.currentDrop.ocrQuality === 1}
                      onChange={() => this.editOCRQuality(1)}
                      value='standard'
                    />
                    <label className='form-check-label'>Standard</label>
                  </div>
                  <div className='form-check form-check-inline'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      checked={this.state.currentDrop.ocrQuality === 2}
                      onChange={() => this.editOCRQuality(2)}
                      value='high'
                    />
                    <label className='form-check-label'>High</label>
                  </div>
                  <div className='form-check form-check-inline'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      checked={this.state.currentDrop.ocrQuality === 3}
                      onChange={() => this.editOCRQuality(3)}
                      value='highest'
                    />
                    <label className='form-check-label'>Highest</label>
                  </div>
                </div>
                {this.state.showOCRDescriptions && (
                  <div className='card-body'>
                    {this.state.currentOcrDescription}
                  </div>
                )}
                <div
                  className='form-check form-switch'
                  style={{ marginTop: '12px' }}
                >
                  <input
                    className='form-check-input'
                    type='checkbox'
                    checked={this.state.currentDrop.readBC}
                    onChange={this.editBarcodeSample}
                  />
                  <label className='form-check-label'>Read Barcodes</label>
                </div>
                {this.state.currentDrop.drop === undefined && (
                  <div style={{ marginTop: '12px' }}>
                    <Dropzone
                      className='ui raised segment'
                      multiple={false}
                      onDrop={this.onDrop}
                      accept={{
                        'application/pdf': ['.pdf'],
                      }}
                    >
                      {({ getRootProps, getInputProps }) => (
                        <div className='drop-container'>
                          <div {...getRootProps({ className: 'dropzone' })}>
                            <input {...getInputProps()} />
                            <div className='drop-content'>
                              <p className='drop-subheader'>
                                Drop sample pdf here
                              </p>
                            </div>
                          </div>
                        </div>
                      )}
                    </Dropzone>
                  </div>
                )}
                {this.state.currentDrop.drop !== undefined && (
                  <div style={{ marginTop: '12px' }}>
                    <span
                      style={{
                        fontSize: '12pt',
                        marginRight: '12px',
                        marginTop: '12px',
                        maxWidth: '350px',
                        textOverflow: 'ellipsis',
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                      }}
                    >
                      <FontAwesomeIcon
                        icon={solid('file-pdf')}
                        style={{ color: 'red' }}
                      />{' '}
                      {this.state.currentDrop.dropName}
                    </span>
                    <button
                      className='btn btn-danger btn-sm'
                      onClick={this.removeDrop}
                    >
                      <FontAwesomeIcon icon={solid('times')} />
                    </button>
                  </div>
                )}
                {!this.state.isCreatingSample && (
                  <span className='d-flex justify-content-center'>
                    <button
                      className='btn btn-primary'
                      style={{
                        marginTop: '12px',
                        width: '180px',
                      }}
                      onClick={this.onSampleSubmit}
                    >
                      Submit
                    </button>
                  </span>
                )}
                {this.state.isCreatingSample && (
                  <button
                    className='btn btn-primary'
                    style={{
                      marginTop: '12px',
                      marginLeft: '85px',
                      width: '180px',
                    }}
                  >
                    <FontAwesomeIcon icon={solid('spinner')} spin /> Creating...
                  </button>
                )}
              </div>
            </div>
          </div>
        )}
        {this.state.showSampleInfo && (
          <div className='td-dialogue-container'>
            <div
              className='td-dialogue card'
              style={{ width: '400px', height: '300px', zIndex: 300 }}
            >
              <div className='card-header'>
                Sample Info{' '}
                <button
                  type='button'
                  className='btn-close'
                  onClick={this.closeSampleInfo}
                />
              </div>
              <div className='card-body'>
                {this.state.currentSampleInfo.type === 'sample' && <div className='row g-3'>
                  <div className='col-auto'>
                    <label className='col-form-label'>Sample Name:</label>
                  </div>
                  <div className='col-auto'>
                    <input
                      type='text'
                      className='form-control'
                      value={this.state.sampleNameEdit}
                      onChange={this.editSampleName}
                    />
                    {this.state.sampleNameEdit !==
                      this.state.currentSampleInfo.sampleName && (
                      <div>
                        <button
                          className='btn btn-sm btn-success mt-1'
                          onClick={() =>
                            UpdateSampleName(
                              this.state.currentSampleInfo.sampleId,
                              this.state.sampleNameEdit
                            )
                          }
                        >
                          Save
                        </button>
                      </div>
                    )}
                  </div>
                </div> }
                {this.state.currentSampleInfo.type === 'doc' && <div>
                {this.state.currentSampleInfo.sampleName}
                </div> }

                <div style={{ marginTop: '12px' }}>
                  {this.state.currentSampleInfo !== undefined && this.state.currentSampleInfo.OCRType !== undefined &&
                    <div>OCR Type: <span>{this.state.currentSampleInfo.OCRType}</span></div>
                  }
                  {this.state.currentSampleInfo !== undefined && this.state.currentSampleInfo.OCRQuality !== undefined &&
                    <div>OCR Quality: <span>{this.state.currentSampleInfo.OCRQuality}</span></div>
                  }
                  {this.state.currentSampleInfo !== undefined && this.state.currentSampleInfo.Barcodes !== undefined &&
                    <div>Read Barcodes: <span>{this.state.currentSampleInfo.Barcodes}</span></div>
                  }
                  {this.state.currentSampleInfo !== undefined && this.state.currentSampleInfo.PageCount !== undefined &&
                    <div>Pages: <span>{this.state.currentSampleInfo.PageCount}</span></div>
                  }

                </div>
                {/*
                <div className='form-check form-switch' style={{marginTop: '12px'}}>
                  <input className='form-check-input' type='checkbox' checked={this.state.currentSample.readBC} onChange={this.editBarcodeSample} />
                  <label className='form-check-label'>Read Barcodes</label>
                </div>
              */}


                {/* save for later */}
                {/*<button className='btn btn-info' style={{marginTop: '12px', marginLeft: '85px', width: '180px'}} onClick={this.reOCRSample}>Re-OCR</button>*/}

                {this.state.currentSampleInfo.type === 'sample' &&<button
                  className='btn btn-primary'
                  style={{
                    marginTop: '12px',
                    marginLeft: '4px',
                    width: '160px',
                  }}
                  onClick={this.downloadSample}
                  >Download PDF</button>}

                {this.state.currentSampleInfo.type === 'doc' &&<button
                  className='btn btn-success'
                  style={{
                    marginTop: '12px',
                    marginLeft: '4px',
                    width: '160px',
                  }}
                  onClick={this.convertToSample}
                  >Convert to Sample</button>}
                  </div>

            </div>
          </div>
        )}
        {this.state.showSaveAsDialog && (
          <div className='td-dialogue-container'>
            <div
              className='td-dialogue card bg-light'
              style={{ height: '220px', width: '450px' }}
            >
              <div className='card-header'>
                Save Template As
                <button
                  onClick={this.showSaveTemplateAs}
                  className='btn-close'
                />
              </div>
              <div className='card-body'>
                {this.state.saveAsStatus.status === 'collecting' && (
                  <>
                    <div className='row mb-2'>
                      <label className='col-4 col-form-label'>
                        Template name:
                      </label>
                      <div className='col-8 '>
                        <input
                          className='form-control'
                          placeholder='name'
                          value={this.state.newTemplateName}
                          onChange={this.editNewTemplateName}
                        />
                      </div>
                    </div>
                    <div className='row'>
                      <label className='col-4 col-form-label'>Form Type:</label>
                      <div className='col-8 '>
                        <select
                          className='form-select'
                          value={this.state.newFT}
                          onChange={this.editNewFT}
                        >
                          <option value='0'>Select a form type</option>
                          {this.state.formTypes.map((ft) => (
                            <option value={ft.formTypeId} key={ft.formTypeId}>
                              {ft.name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <button
                      onClick={this.createNewTemplate}
                      className='btn btn-primary mt-3  w-100'
                    >
                      Create
                    </button>
                  </>
                )}
                {this.state.saveAsStatus.status === 'saving' && (
                  <div>Saving</div>
                )}
                {this.state.saveAsStatus.status === 'success' && (
                  <div>
                    <div className='alert alert-success'>
                      Your template has been created. Click{' '}
                      <Link
                        to={`/TemplateEditor/${this.state.saveAsStatus.newTemplateId}`}
                      >
                        here
                      </Link>{' '}
                      to view your template!
                    </div>
                  </div>
                )}
                {this.state.saveAsStatus.status === 'error' && (
                  <div>
                    <div className='alert alert-success'>
                      Your template failed to create. Please see system log.
                    </div>
                  </div>
                )}

                <Link
                  style={{ display: 'none' }}
                  id='navLink'
                  to={`/TemplateEditor/${this.state.templateId}`}
                >
                  ClickMe
                </Link>
              </div>
            </div>
          </div>
        )}
        {this.state.showTables && 1 === 0 && (
          <div className='td-dialogue-container'>
            <div
              className='td-dialogue card bg-light'
              style={{ height: '450px', width: '800px' }}
            >
              <div className='card-header'>
                Tables
                <button onClick={this.onHideTables} className='btn-close' />
              </div>
              <div
                className='card-body'
                style={{ height: '400px', width: '798px', overflow: 'auto' }}
              >
                {this.state.tableCapture.map((table) => (
                  <div key={table.name}>
                    <div style={{ fontSize: '18pt' }}>
                      <FontAwesomeIcon
                        style={{ color: '#D35400' }}
                        icon={solid('table')}
                      />{' '}
                      {table.fieldSetName}
                    </div>
                    <table className='teplate-tableResult'>
                      <thead>
                        <tr>
                          {table.cols.map((col) => (
                            <th
                              style={{
                                color: col.isMapped ? '#000' : '#C8C8C8',
                              }}
                              key={col.Id}
                            >
                              {col.fieldName}
                            </th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {table.rows.map((row) => (
                          <tr key={row.rowId}>
                            {row.cells.map((cell) => (
                              <td key={cell.id}>{cell.value}</td>
                            ))}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}
        {this.state.showNav && (
          <div className='td-dialogue-container'>
            <div
              className='td-dialogue card bg-light'
              style={{ height: '259px', width: '400px' }}
            >
              <div className='card-header'>Unsaved changes</div>
              <div className='card-body' style={{ display: 'block' }}>
                <div>
                  <FontAwesomeIcon icon={solid('warning')} /> You have unsaved
                  changes
                </div>

                <button
                  className='btn btn-success btn-block mt-2'
                  style={{ width: '360px' }}
                  onClick={this.onSaveAndNav}
                >
                  Save changes and go to {this.state.navDestination}
                </button>
                <button
                  className='btn btn-info btn-block mt-2'
                  style={{ width: '360px' }}
                  onClick={this.onStay}
                >
                  Stay in Template Editor
                </button>
                <button
                  className='btn btn-secondary btn-block mt-2'
                  style={{ width: '360px' }}
                  onClick={this.onDiscardAndNav}
                >
                  Discard changes and go to {this.state.navDestination}
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default TemplateEditor;
