import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import ScheduleSidebar from './ScheduleSidebar';

class ScheduleHome extends React.Component {
  constructor() {
    // state
    super();
    this.state = {};
  }

  componentDidMount() {}
  componentWillUnmount() {}
  componentDidUpdate() {}

  render() {
    return (
      <div className='sidebarLayout d-flex flex-row flex-fill'>
        <div className='sidebar-container'>{<ScheduleSidebar />}</div>
        <div className='flex-fill'>
          <div className='h-100 d-flex flex-column align-items-center justify-content-center'>
            <FontAwesomeIcon icon={solid('calendar-check')} size='6x' />
            <h2
              style={{
                fontWeight: 'bolder',
                fontStyle: 'italic',
                margin: '16px',
              }}
            >
              Schedules
            </h2>
          </div>
        </div>
      </div>
    );
  }
}

export default ScheduleHome;
