import dispatcher from '../Dispatcher';
import actionTypes from './ActionTypes';
import axios from 'axios';
import * as Globals from '../Globals';
import { PostAlert } from './AlertActions';
//import { WebUpload } from '../models/WebUpload';
//import { InboundAutomation } from '../Models/Admin/InboundAutomation';
//import { CompanionRecord } from '../Models/Admin/CompanionSchema';

export function GetModule(id) {
  axios
    .get(`${Globals.baseUrl}/Module/GetModule/${id}`, {
      headers: {
        'content-type': 'application/json; charset=utf-8',
        Authorization: `Bearer ${Globals.getJwtToken()}`,
      },
    })
    .then((res) => {
      if (res.status === 200) {
        dispatcher.dispatch({
          actionType: actionTypes.ModulesGetOne,
          Result: res.data,
        });
      } else if (res.status === 400 || res.status === 401) {
        console.log('error: 401');
      }
    })
    .catch((res) => {
      let result = {
        updateStatus: 'Error',
        errorMessage: '401: Bad request',
      };
      dispatcher.dispatch({
        actionType: actionTypes.IAList,
        Result: result,
      });
    });
}

export function GetModuleList() {
  axios
    .get(`${Globals.baseUrl}/Module/GetModuleList`, {
      headers: {
        'content-type': 'application/json; charset=utf-8',
        Authorization: `Bearer ${Globals.getJwtToken()}`,
      },
    })
    .then((res) => {
      if (res.status === 200) {
        dispatcher.dispatch({
          actionType: actionTypes.ModulesGetList,
          Result: res.data,
        });
      } else if (res.status === 400 || res.status === 401) {
        console.log('error: 401');
      }
    })
    .catch((res) => {
      let result = {
        updateStatus: 'Error',
        errorMessage: '401: Bad request',
      };
      dispatcher.dispatch({
        actionType: actionTypes.WebUploadGetStatus,
        Result: result,
      });
    });
}

export function UploadModule(upload, name) {
  console.log('upload mod');
  let fd = new FormData();
  fd.append('file', upload.file, upload.file.name);
  fd.append('name', name);

  axios
    .post(`${Globals.baseUrl}/Module/UploadModule`, fd, {
      headers: {
        'content-type': 'multipart/form-data',
        Authorization: `Bearer ${Globals.getJwtToken()}`,
      },
    })
    .then((res) => {
      if (res.status === 200) {
        dispatcher.dispatch({
          actionType: actionTypes.ModulesUpload,
          Result: res.data,
        });
      } else if (res.status === 400 || res.status === 401) {
        console.log('error: 401');
      }
    })
    .catch((res) => {
      let result = {
        updateStatus: 'Error',
        errorMessage: '401: Bad request',
      };
      dispatcher.dispatch({
        actionType: actionTypes.WebUploadUpload,
        Result: result,
      });
    });
}

export function ReUploadModule(upload, name, id) {
  let fd = new FormData();
  fd.append('file', upload.file, upload.file.name);
  fd.append('name', name);
  fd.append('id', id);

  axios
    .post(`${Globals.baseUrl}/Module/ReUploadModule`, fd, {
      headers: {
        'content-type': 'multipart/form-data',
        Authorization: `Bearer ${Globals.getJwtToken()}`,
      },
    })
    .then((res) => {
      if (res.status === 200) {
        dispatcher.dispatch({
          actionType: actionTypes.ModulesUpload,
          Result: res.data,
        });
      } else if (res.status === 400 || res.status === 401) {
        console.log('error: 401');
      }
    })
    .catch((res) => {
      let result = {
        updateStatus: 'Error',
        errorMessage: '401: Bad request',
      };
      dispatcher.dispatch({
        actionType: actionTypes.WebUploadUpload,
        Result: result,
      });
    });
}

export function UpdateModuleName(id, name) {
  let fd = new FormData();
  fd.append('id', id);
  fd.append('name', name);

  axios
    .post(`${Globals.baseUrl}/Module/UpdateModuleName`, fd, {
      headers: {
        'content-type': 'multipart/form-data',
        Authorization: `Bearer ${Globals.getJwtToken()}`,
      },
    })
    .then((res) => {
      if (res.status === 200) {
        dispatcher.dispatch({
          actionType: actionTypes.ModulesRename,
          Result: res.data,
        });
      } else if (res.status === 400 || res.status === 401) {
        console.log('error: 401');
      }
    })
    .catch((res) => {
      let result = {
        updateStatus: 'Error',
        errorMessage: '401: Bad request',
      };
      //dispatcher.dispatch({
        //actionType: actionTypes.WebUploadUpload,
        //Result: result,
      //});
    });
}

export function MakeCurrentModule(id) {
  console.log('make current mod');
  axios
    .get(`${Globals.baseUrl}/Module/MakeCurrent/${id}`, {
      headers: {
        'content-type': 'multipart/form-data',
        Authorization: `Bearer ${Globals.getJwtToken()}`,
      },
    })
    .then((res) => {
      if (res.status === 200) {
        dispatcher.dispatch({
          actionType: actionTypes.ModulesUpload,
          Result: res.data,
        });
      } else if (res.status === 400 || res.status === 401) {
        console.log('error: 401');
      }
    })
    .catch((res) => {
      let result = {
        updateStatus: 'Error',
        errorMessage: '401: Bad request',
      };
      dispatcher.dispatch({
        actionType: actionTypes.WebUploadUpload,
        Result: result,
      });
    });
}

export function GetModuleDataList(id) {
  axios
    .get(`${Globals.baseUrl}/Module/GetModuleDataList/${id}`, {
      headers: {
        'content-type': 'application/json; charset=utf-8',
        Authorization: `Bearer ${Globals.getJwtToken()}`,
      },
    })
    .then((res) => {
      if (res.status === 200) {
        dispatcher.dispatch({
          actionType: actionTypes.ModuleDataGetList,
          Result: res.data,
        });
      } else if (res.status === 400 || res.status === 401) {
        console.log('error: 401');
      }
    })
    .catch((res) => {
      let result = {
        updateStatus: 'Error',
        errorMessage: '401: Bad request',
      };
      dispatcher.dispatch({
        actionType: actionTypes.WebUploadGetStatus,
        Result: result,
      });
    });
}

export function DownloadModule(id, modName) {
  axios
    .get(`${Globals.baseUrl}/Module/DownloadModule/${id}`, {
      headers: {
        'content-type': 'application/json; charset=utf-8',
        Authorization: `Bearer ${Globals.getJwtToken()}`,
      },
      responseType: 'blob',
    })
    .then((res) => {
      if (res.status === 200) {
        dispatcher.dispatch({
          actionType: actionTypes.ModuleDownload,
          Result: res.data,
        });
        var link = document.createElement('a');
        link.setAttribute('download', '');
        link.href = window.URL.createObjectURL(new Blob([res.data]));
        link.download = modName + '.zip';
        document.body.appendChild(link);
        link.click();
      } else if (res.status === 400 || res.status === 401) {
        console.log('error: 401');
      }
    })
    .catch((res) => {});
}

export function RunPlugin(id) {
  axios
    .post(
      `${Globals.baseUrl}/Module/RunPlugin`,
      {
        pluginId: id,
      },
      {
        headers: {
          'content-type': 'application/json; charset=utf-8',
          Authorization: `Bearer ${Globals.getJwtToken()}`,
        },
      }
    )
    .then((res) => {
      if (res.status === 200) {
        dispatcher.dispatch({
          actionType: actionTypes.ModuleRunPlugin,
          Result: res.data,
        });
        PostAlert('success', 'Execution reqest sent to TrinDocs');
      } else if (res.status === 400 || res.status === 401) {
        console.log('error: 401');
      }
    })
    .catch((res) => {
      // let result = {
      //   updateStatus: 'Error',
      //   errorMessage: '401: Bad request',
      // };
    });
}

export function DeleteModule(id) {
  axios
    .get(`${Globals.baseUrl}/Module/DeleteModule/${id}`, {
      headers: {
        'content-type': 'application/json; charset=utf-8',
        Authorization: `Bearer ${Globals.getJwtToken()}`,
      },
    })
    .then((res) => {
      if (res.status === 200) {
        dispatcher.dispatch({
          actionType: actionTypes.ModuleDelete,
          Result: res.data,
        });
        if (res.data.resultStatus === 'success') {
          PostAlert('success', 'Module Deleted');
        }
      } else if (res.status === 400 || res.status === 401) {
        console.log('error: 401');
      }
    })
    .catch((res) => {
      // let result = {
      //   updateStatus: 'Error',
      //   errorMessage: '401: Bad request',
      // };
    });
}

export function ReanalyzeModule(id) {
  axios
    .get(`${Globals.baseUrl}/Module/AnalyzeModule/${id}`, {
      headers: {
        'content-type': 'application/json; charset=utf-8',
        Authorization: `Bearer ${Globals.getJwtToken()}`,
      },
    })
    .then((res) => {
      if (res.status === 200) {
        dispatcher.dispatch({
          actionType: actionTypes.ModuleAnalyze,
          Result: res.data,
        });
        if (res.data.resultStatus === 'success') {
          PostAlert('success', 'Module Analyzed');
        }
      } else if (res.status === 400 || res.status === 401) {
        console.log('error: 401');
      }
    })
    .catch((res) => {
      // let result = {
      //   updateStatus: 'Error',
      //   errorMessage: '401: Bad request',
      // };
    });
}
