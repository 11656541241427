// @flow
import * as React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';

import ArchiveStore from '../../../../stores/ArchiveStore';
import { GetArchiveList } from '../../../../actions/ArchiveActions';

class ArchiveSidebar extends React.Component {
  //isList: Array<BPModel>;
  constructor(props) {
    super(props);
    this.state = {
      archiveList: [],
      archivesLoaded: false,
    };

    this.onReceiveArchiveChange = this.onReceiveArchiveChange.bind(this);
  }

  componentDidMount() {
    ArchiveStore.addChangeListener(this.onReceiveArchiveChange);
    GetArchiveList();
  }

  componentWillUnmount() {
    ArchiveStore.removeChangeListener(this.onReceiveArchiveChange);
  }

  onReceiveArchiveChange() {
    let lastChange = ArchiveStore.lastChange();
    if (lastChange === 'ArchiveList') {
      let arch = ArchiveStore.getArchives();
      this.setState({
        archiveList: arch,
        archivesLoaded: true,
      });
    }
  }

  render() {
    let listOfArchs;
    if (this.state.archiveList !== undefined) {
      listOfArchs = this.state.archiveList.map((dir) => (
        <div style={{ marginBottom: '8px' }} key={dir.archiveId}>
          <Link
            className='sidebar-link'
            to={'/Admin/ArchiveView/' + dir.archiveId}
            //onClick={() => this.handleBPClick(bp)}
            style={{ cursor: 'Pointer', wordWrap: 'break-word' }}
          >
            <FontAwesomeIcon
              style={{ color: '#86AFC0' }}
              icon={solid('archive')}
            />{' '}
            {dir.rootPath}{' '}
            {dir.isCurrent && (
              <FontAwesomeIcon
                style={{ color: '#36AB26' }}
                icon={solid('check')}
              />
            )}
          </Link>
        </div>
      ));
    } else {
      listOfArchs = <div>No archives defined</div>;
    }
    return (
      <div
        className='d-flex flex-column p-3 text-white bg-dark'
        style={{ height: '100%', width: '245px' }}
      >
        <Link
          to='/Admin/Directories'
          className='d-flex align-items-center mb-3 mb-md-0 me-md-auto text-white text-decoration-none'
        >
          <span className='fs-4'>
            <FontAwesomeIcon icon={solid('archive')} />
            {'   '}
            Archives
          </span>
        </Link>
        <hr />

        <div style={{ maxHeight: '50%', overflow: 'auto' }}>{listOfArchs}</div>

        <hr />
        <Link className='sidebar-link' to='/Admin/ArchiveView/0'>
          New Archive
        </Link>
      </div>
    );
  }
}

export default ArchiveSidebar;
