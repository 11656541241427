import dispatcher from '../Dispatcher';
import actionTypes from './ActionTypes';
import axios from 'axios';
import * as Globals from '../Globals';
import { Logout } from './LoginActions';
import { PostAlert } from './AlertActions';

export function GetRoleList() {
  axios
    .get(`${Globals.baseUrl}/Roles/GetRoles`, {
      headers: {
        'content-type': 'application/json; charset=utf-8',
        Authorization: `Bearer ${Globals.getJwtToken()}`,
      },
    })
    .then((res) => {
      if (res.status === 200) {
        dispatcher.dispatch({
          actionType: actionTypes.RolesGetRoles,
          Result: res.data,
        });
      } else if (res.status === 400 || res.status === 401) {
        Logout();
      }
    })
    .catch((res) => {
      //Logout();
    });
}

export function GetBPList() {
  axios
    .get(`${Globals.baseUrl}/Roles/GetBPList`, {
      headers: {
        'content-type': 'application/json; charset=utf-8',
        Authorization: `Bearer ${Globals.getJwtToken()}`,
      },
    })
    .then((res) => {
      if (res.status === 200) {
        dispatcher.dispatch({
          actionType: actionTypes.RoleBPGetList,
          Result: res.data,
        });
      } else if (res.status === 400 || res.status === 401) {
        Logout();
      }
    })
    .catch((res) => {
      console.log('error');
      console.log(res.response);
      // Logout();
    });
}

export function GetRole(roleId) {
  axios
    .get(`${Globals.baseUrl}/Roles/GetRole/${roleId}`, {
      headers: {
        'content-type': 'application/json; charset=utf-8',
        Authorization: `Bearer ${Globals.getJwtToken()}`,
      },
    })
    .then((res) => {
      if (res.status === 200) {
        dispatcher.dispatch({
          actionType: actionTypes.RolesGetOne,
          Result: res.data,
        });
      } else if (res.status === 400 || res.status === 401) {
        Logout();
      }
    })
    .catch((res) => {});
}

export function GetUsersInRole(roleId) {
  axios
    .get(`${Globals.baseUrl}/Roles/GetUsersInRole/${roleId}`, {
      headers: {
        'content-type': 'application/json; charset=utf-8',
        Authorization: `Bearer ${Globals.getJwtToken()}`,
      },
    })
    .then((res) => {
      if (res.status === 200) {
        dispatcher.dispatch({
          actionType: actionTypes.RolesGetUsers,
          Result: res.data,
        });
      } else if (res.status === 400 || res.status === 401) {
        Logout();
      }
    })
    .catch((res) => {});
}

export function GetUsersForRole(roleId) {
  axios
    .get(`${Globals.baseUrl}/Roles/GetUsersForRole/${roleId}`, {
      headers: {
        'content-type': 'application/json; charset=utf-8',
        Authorization: `Bearer ${Globals.getJwtToken()}`,
      },
    })
    .then((res) => {
      if (res.status === 200) {
        dispatcher.dispatch({
          actionType: actionTypes.RolesUsersFor,
          Result: res.data,
        });
      } else if (res.status === 400 || res.status === 401) {
        Logout();
      }
    })
    .catch((res) => {});
}

export function SaveRole(role, permissions, wfStepRp, bpId, grps) {
  axios
    .post(
      `${Globals.baseUrl}/Roles/UpdateRole`,
      {
        Role: role,
        Permissions: permissions,
        wfStepRp: wfStepRp,
        businessProcessId: bpId,
        groups: grps,
      },
      {
        headers: {
          'content-type': 'application/json; charset=utf-8',
          Authorization: `Bearer ${Globals.getJwtToken()}`,
        },
      }
    )
    .then((res) => {
      if (res.status === 200) {
        dispatcher.dispatch({
          actionType: actionTypes.RolesUpdateOne,
          Result: res.data,
        });
        PostAlert('success', 'Role updated');
      } else if (res.status === 400 || res.status === 401) {
        Logout();
      }
    })
    .catch((res) => {});
}

export function UpdateUsersInRole(role, users) {
  axios
    .post(
      `${Globals.baseUrl}/Roles/UpdateUsersInRole`,
      {
        roleId: parseInt(role, 10),
        users: users,
      },
      {
        headers: {
          'content-type': 'application/json; charset=utf-8',
          Authorization: `Bearer ${Globals.getJwtToken()}`,
        },
      }
    )
    .then((res) => {
      if (res.status === 200) {
        console.log('dispatch');
        dispatcher.dispatch({
          actionType: actionTypes.RolesUpdateUsers,
          Result: res.data,
        });
        PostAlert('success', 'Role users updated');
      } else if (res.status === 400 || res.status === 401) {
        Logout();
      }
    })
    .catch((res) => {});
}

export function DeleteRole(id) {
  axios
    .get(`${Globals.baseUrl}/Roles/DeleteRole/${id}`, {
      headers: {
        'content-type': 'application/json; charset=utf-8',
        Authorization: `Bearer ${Globals.getJwtToken()}`,
      },
    })
    .then((res) => {
      if (res.status === 200) {
        console.log(res.data);
        if (res.data.deleteStatus === 'error') {
          PostAlert('error', res.data.deleteMessage);
        } else {
          console.log('dispatch');
          dispatcher.dispatch({
            actionType: actionTypes.RolesDeleteOne,
            Result: res.data,
          });
          PostAlert('success', 'Role deleted');
        }
      } else if (res.status === 400 || res.status === 401) {
        Logout();
      }
    })
    .catch((res) => {});
}
