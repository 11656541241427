import { EventEmitter } from 'events';
import Dispatcher from '../Dispatcher';
import actionTypes from '../actions/ActionTypes';

const CHANGE_EVENT = 'orgChange';
let _ouList = [];
let _currentOU = {};
let _lastChange = '';
let _lastError = '';

class OUStore extends EventEmitter {
  addChangeListener(callback) {
    this.on(CHANGE_EVENT, callback);
  }

  removeChangeListener(callback) {
    this.removeListener(CHANGE_EVENT, callback);
  }

  emitChange() {
    this.emit(CHANGE_EVENT);
  }

  getOUList() {
    return _ouList;
  }

  getLastChange() {
    return _lastChange;
  }

  getCurrentOU() {
    return _currentOU;
  }

  getLastError() {
    return _lastError;
  }
}

const store = new OUStore();

Dispatcher.register((action) => {
  switch (action.actionType) {
    case actionTypes.OUGetList:
      _ouList = action.Result;
      _lastChange = action.actionType;
      store.emitChange();
      break;
    case actionTypes.OUGetOne:
      _currentOU = action.Result;
      _lastChange = action.actionType;
      store.emitChange();
      break;
    case actionTypes.OUUpdate:
      _currentOU = action.Result.data;
      _lastChange = action.actionType;
      _lastError = action.Result.updateMessage;
      store.emitChange();
      break;

    case actionTypes.OUDelete:
      _lastChange = action.actionType;
      store.emitChange();
      break;
    default:
  }
});

export default store;
