import { EventEmitter } from 'events';
import Dispatcher from '../Dispatcher';
import actionTypes from '../actions/ActionTypes';

const CHANGE_EVENT = 'directoryChange';
let _allDirectories = undefined;
let _currentDir = undefined;
let _lastChange = '';
let _lastError = '';

class DirectoryStore extends EventEmitter {
  addChangeListener(callback) {
    this.on(CHANGE_EVENT, callback);
  }

  removeChangeListener(callback) {
    this.removeListener(CHANGE_EVENT, callback);
  }

  emitChange() {
    this.emit(CHANGE_EVENT);
  }

  getDirectories() {
    return _allDirectories;
  }

  getCurrentDirectory() {
    return _currentDir;
  }

  lastChange() {
    return _lastChange;
  }

  getLastError() {
    return _lastError;
  }
}

const store = new DirectoryStore();

Dispatcher.register((action) => {
  _lastChange = action.actionType;
  switch (action.actionType) {
    // loading the full list of Companion schemas
    case actionTypes.DirectoriesList:
      _lastChange = 'DirectoriesList';
      _allDirectories = action.Result;
      store.emitChange();
      break;
    case actionTypes.DirectoriesLoadOne:
      _lastChange = 'DirectoriesLoadOne';
      _currentDir = action.Result;
      store.emitChange();
      break;
    case actionTypes.DirectoriesUpdateOne:
      _lastChange = 'DirectoriesUpdateOne';
      if (action.Result.updateStatus === 'Success') {
        _lastError = '';
        _currentDir = action.Result.result;
      } else {
        _lastError = action.Result.errorMessage;
      }
      store.emitChange();
      break;
    case actionTypes.DirectoriesDelete:
      _lastChange = 'DirectoriesDelete';
      if (action.Result.updateStatus === 'Success') {
        _lastError = '';
        _currentDir = action.Result.result;
      } else {
        _lastError = action.Result.errorMessage;
      }
      store.emitChange();
      break;
    default:
  }
});

export default store;
