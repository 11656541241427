import { EventEmitter } from 'events';
import Dispatcher from '../Dispatcher';
import actionTypes from '../actions/ActionTypes';

const CHANGE_EVENT = 'imageReviewChange';
let _irTree = undefined;
let _irNode = undefined;
let _lastChange = '';
let _currentDocs = [];
let _columns = [];
let _docCount = 0;
let _summary = undefined;
let _reviewDocIds = [];
let _imageReviewDocs = [];
let _currentBP = undefined;
let _imageReviewResults = undefined;
let _currentImage = undefined;

class ImageReviewStore extends EventEmitter {
  addChangeListener(callback) {
    this.on(CHANGE_EVENT, callback);
  }

  removeChangeListener(callback) {
    this.removeListener(CHANGE_EVENT, callback);
  }

  emitChange() {
    this.emit(CHANGE_EVENT);
  }

  getIRTree() {
    return _irTree;
  }

  getDocCount() {
    return _docCount;
  }

  getColumns() {
    return _columns;
  }

  getDocs() {
    return _currentDocs;
  }

  getLastNodeChange() {
    return _irNode;
  }

  lastChange() {
    return _lastChange;
  }

  getSummary() {
    return _summary;
  }

  getReviewDocIds() {
    return _reviewDocIds;
  }

  setReviewDocIds(docIds) {
    _reviewDocIds = docIds;
  }

  getImageReviewDocs() {
    return _imageReviewDocs;
  }

  getBP() {
    return _currentBP;
  }

  getResults() {
    return _imageReviewResults;
  }

  getImage() {
    return _currentImage;
  }
}

const store = new ImageReviewStore();

Dispatcher.register((action) => {
  _lastChange = action.actionType;
  switch (action.actionType) {
    // loading the full list of Companion schemas
    case actionTypes.IRGetADTree:
      _lastChange = actionTypes.IRGetADTree;
      _irTree = action.Result;

      store.emitChange();
      break;
    case actionTypes.IRGetStep:
      _lastChange = actionTypes.IRGetStep;
      _currentDocs = action.Result.docData;
      _docCount = action.Result.docCount;
      _columns = action.Result.columns;
      store.emitChange();
      break;
    case actionTypes.IRGetBP:
      _lastChange = actionTypes.IRGetBP;
      _currentDocs = action.Result.docData;
      _docCount = action.Result.docCount;
      _columns = action.Result.columns;
      _summary = {
        formType: action.Result.formType,
        formTypeId: action.Result.formTypeId,
        workflow: action.Result.workflow,
      };
      store.emitChange();
      break;
    case actionTypes.IRGetImageReview:
      _lastChange = actionTypes.IRGetImageReview;
      _imageReviewDocs = action.Result.documents;
      _currentBP = {
        bpId: action.Result.bpId,
        bpName: action.Result.bpName,
      };
      store.emitChange();
      break;
    case actionTypes.IRUpdate:
      _lastChange = actionTypes.IRUpdate;
      _imageReviewResults = action.Result;

      store.emitChange();
      break;
    case actionTypes.IRFetchImage:
      _lastChange = actionTypes.IRFetchImage;
      _currentImage = action.Result;
      store.emitChange();
      break;
    default:
  }
});

export default store;
