import * as React from 'react';
import Dropzone from 'react-dropzone';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import TemplateSidebar from './TemplateSidebar';
import actionTypes from '../../../actions/ActionTypes';
import TemplateStore from '../../../stores/TemplateStore';
import {

    GetFormTypesICanEditTemplates,
    GetTemplateDetails,
    UpdateTemplateActive,
    GetTemplateAudit,
    DownloadTemplate,
    UploadTemplate,
    GetTemplateUsage,
  } from '../../../actions/TemplateActions';

class TemplateManager extends React.Component {
  constructor() {
    // state
    super();
    this.state = {
      templateList: [],
      searchText: '',
      filteredTemplateList: [],
      newTemplateName: '',
      showNewTemplate: false,
      formTypes: [],
      newFT: 0,
      templateId: 7,
      maxHeight: window.innerHeight - 200,
      activeFilter: 'all',
      formTypeFilter: 0,
      templateId: 0,
      showAudit: true,
      currentAudit: undefined,
      shopUpload: false,
      uploadFT: 0,
      recentUploads: [],
      templateUsage: [],
      showUsage: false,
      goToDocId: 0,
    };

    this.onReceiveTemplateChange = this.onReceiveTemplateChange.bind(this);
    this.onSearchChange = this.onSearchChange.bind(this);
    this.setActiveFilter = this.setActiveFilter.bind(this);
    this.performFilter = this.performFilter.bind(this);
    this.setFormTypeFilter = this.setFormTypeFilter.bind(this);
    this.toggleActive = this.toggleActive.bind(this);
    this.onTemplateClick = this.onTemplateClick.bind(this);
    this.closeAudit = this.closeAudit.bind(this);
    this.onAuditClick = this.onAuditClick.bind(this);
    this.toggleAudit = this.toggleAudit.bind(this);
    this.closeUsage = this.closeUsage.bind(this);
    this.onDownloadTemplateLink = this.onDownloadTemplateLink.bind(this);
    this.onCloseUploadTemplates = this.onCloseUploadTemplates.bind(this);
    this.openUpload = this.openUpload.bind(this);
    this.onDrop = this.onDrop.bind(this);
    this.chooseUploadFT = this.chooseUploadFT.bind(this);
    this.onWindowResize = this.onWindowResize.bind(this);
    this.onUsageClick = this.onUsageClick.bind(this);
    this.goToDoc = this.goToDoc.bind(this);


  }

  componentDidMount() {
    TemplateStore.addChangeListener(this.onReceiveTemplateChange);
    window.onresize = this.onWindowResize;
    GetTemplateDetails();
    GetFormTypesICanEditTemplates();
    document.title = 'TrinDocs - Templates'

    let ftFilter = sessionStorage.getItem("TEFormTypeFilter");
    console.log(ftFilter);
    if(ftFilter !== undefined && ftFilter !== null)
    {
      let intFt = parseInt(ftFilter, 10);
      this.setState({formTypeFilter: intFt});
    } else {
      this.setState({formTypeFilter: 0});
    }

  }

  componentWillUnmount() {
    TemplateStore.removeChangeListener(this.onReceiveTemplateChange);
  }

  componentDidUpdate() {}

  onReceiveTemplateChange() {
    let lastChange = TemplateStore.getLastChange();
    if (lastChange === actionTypes.TemplateGetDetails) {
      let templates = TemplateStore.getTemplateDetails();
      this.setState({
        templateList: templates,
        searchText: '',
      }, this.performFilter);
    }
    if (lastChange === actionTypes.TemplateFormTypes) {
      let fts = TemplateStore.getFormTypes();
      this.setState({
        formTypes: fts,
      });
    }
    if (lastChange === actionTypes.TemplateUpdateActive) {
      let actionUpdate = TemplateStore.getActiveUpdate();
      console.log(actionUpdate);
       if(actionUpdate !== undefined)
       {
           let fgs = this.state.templateList;
           let filter = this.state.filteredTemplateList;
           fgs.forEach((fg) => {
            let temp = fg.templates.find(t => t.templateId === actionUpdate.templateId);
            if(temp !== undefined)
            {

              temp.isActive = actionUpdate.isActive;
            }
          })

          filter.forEach((fg) => {
            let temp = fg.templates.find(t => t.templateId === actionUpdate.templateId);
            if(temp !== undefined)
            {
              temp.isActive = actionUpdate.isActive;
            }
          })
          this.setState({filteredTemplateList: filter});




      }

    }

    if(lastChange === actionTypes.TemplateGetAudit)
    {
      let audit = TemplateStore.getAudit();
      if(audit.length > 0)
      {
        audit.forEach(aud => {
          aud.isOpen = false;
        });
      }
      console.log(audit);
      this.setState({currentAudit: audit, showAudit: true});
    }
    if(lastChange === actionTypes.TemplateGetUsage)
    {
      let usage = TemplateStore.getTemplateUsage();
      this.setState({templateUsage: usage, showUsage: true});
    }

    if(lastChange === actionTypes.TemplateUploadTemplate)
    {
      let lastUpload = TemplateStore.getLastUpload();
      console.log(lastUpload);
      let recentUploads = this.state.recentUploads;
      recentUploads.push(lastUpload);
      this.setState({recentUploads: recentUploads});
      GetTemplateDetails();
    }

  }

  onAuditClick(id)
  {
    GetTemplateAudit(id);
  }

  onWindowResize(e) {
    let newH = window.innerHeight - 200;
    this.setState({ maxHeight: newH });
  }

  onSearchChange(e) {

    let search = e.target.value;
    // if (search === '') {
    //   let newList = [...this.state.templateList];
    //   this.setState({ searchText: search, filteredTemplateList: newList });
    // } else {

    //   let ftgs = this.state.templateList;
    //   let filter = [];
    //   ftgs.forEach((fg) => {
    //     let filterGroup = { formTypeId: fg.formTypeId, formTypeName: fg.formTypeName, templates: [] };
    //     let temps = fg.templates;
    //     temps.forEach((temp) => {
    //       if(temp.templateName.toLowerCase().includes(search.toLowerCase()))
    //       {
    //           filterGroup.templates.push(temp);
    //       }

    //     });
    //     if(filterGroup.templates.length > 0)
    //     {
    //       filter.push(filterGroup);
    //     }
    //   });

      this.setState({ searchText: search }, this.performFilter);

  }

  setActiveFilter(filter) {
    this.setState({activeFilter: filter}, this.performFilter);
  }

  toggleActive(template) {
    UpdateTemplateActive(template.templateId, !template.isActive);
  }

  toggleAudit(audit)
  {
    if(audit.isOpen) {
      audit.isOpen = false;
    } else {
      audit.isOpen = true;
    }

  }

  performFilter() {
    let search = this.state.searchText;
    let activeFilter = this.state.activeFilter;
    let ftId = this.state.formTypeFilter;
    console.log(ftId);
      let ftgs = this.state.templateList;
      let filter = [];
      ftgs.forEach((fg) => {
        let filterGroup = { formTypeId: fg.formTypeId, formTypeName: fg.formTypeName, templates: [] };
        let temps = fg.templates;
        if(ftId === 0 || ftId === fg.formTypeId){

        temps.forEach((temp) => {
          if(temp.templateName.toLowerCase().includes(search.toLowerCase()) || search === '')
          {
            if(temp.isActive && activeFilter === 'active' || !temp.isActive && activeFilter === 'inactive' || activeFilter === 'all')
            {

                filterGroup.templates.push(temp);

            }
          }

        });
        }
        if(filterGroup.templates.length > 0)
        {
          filter.push(filterGroup);
        }
      });

      filter = filter.sort((a,b) => { return a.formTypeName < b.formTypeName ? -1 : 1 });
      this.setState({filteredTemplateList: filter });

  }

  setFormTypeFilter(formType)
  {
    console.log(formType);
    let ftFilter = 0;
    if(formType !== 'all')
    {
      ftFilter = formType.formTypeId;
    }
    console.log(ftFilter);
    sessionStorage.setItem("TEFormTypeFilter", ftFilter);
    this.setState({formTypeFilter: ftFilter}, this.performFilter);
  }

  onTemplateClick(e, id) {
    console.log(id);
    if(e.ctrlKey) {
      // new tab
      window.open(`/TemplateManager/${id}`);
    } else {
      // go direct
      this.setState({ templateId: id }, () => {
        let link = document.getElementById('navLink');
        link.click();
      });
    }
  }

  closeAudit() {
    this.setState({showAudit: false});
  }

  closeUsage() {
    this.setState({showUsage: false});
  }

  onCloseUploadTemplates() {
    this.setState({showUpload: false});
  }

  openAudit() {
    this.setState({showAudit: true});
  }

  openUpload() {
    let fts = TemplateStore.getFormTypes();

    this.setState({showUpload: true, formTypes: fts});
  }

  onDownloadTemplateLink(template) {
    DownloadTemplate(template.templateId, template.templateName);
  }

  onDrop(files) {
    files.forEach((file) => {
      console.log(file);
      UploadTemplate(file, this.state.uploadFT);
      // let upload = new WebUpload();
      // upload.userName = userName;
      // upload.file = file;
      // upload.routingType = this.state.routingType;
      // upload.defaultFtp = this.state.selectedFtp;
      // upload.defaultTemplate = this.state.selectedTemplate;
      // upload.intakeStrategyId = this.state.selectedIntakeStrategy;
      // UploadFile(upload);
    });
  }

  chooseUploadFT(e) {
    this.setState({ uploadFT: parseInt(e.target.value) });
  }

  onUsageClick(templateId)
  {
    GetTemplateUsage(templateId);
  }


  convertDate(strDate) {
    let strDate2 = strDate.toString();

    let date = new Date(strDate2);
    let d1 = date.getDate();
    let day = d1.toString();
    let m1 = date.getMonth();
    m1 = m1 + 1;
    let mon = m1.toString();
    let year = date.getFullYear().toString();
    let h1 = date.getHours();

    let mer = 'AM';
    if (h1 >= 12) mer = 'PM';
    if (h1 > 12) {
      h1 = h1 - 12;
    }
    if (h1 === 0) h1 = 12;

    let h = h1.toString();
    let m = date.getUTCMinutes().toString();
    if (m.length === 1) {
      m = '0' + m;
    }

    let fulldtime =
      mon + '/' + day + '/' + year + ' ' + h + ':' + m + ' ' + mer;
    return fulldtime;
  }

  goToDoc(e, docId) {
    //docLink.setAttribute('href', `/Document/${docId}`);
    if(e.ctrlKey) {
      window.open('/Document/' + docId.toString());
    } else {
      let returnPath = window.location.pathname + window.location.search;
      localStorage.setItem('docReturn', returnPath);
      //docLink.click();
      this.setState({ goToDocId: docId }, () => {
        let docLink = document.getElementById('docLink');
        docLink.click();
      });
    }
  }

  render() {
    return (
      <div className='sidebarLayout d-flex flex-row flex-fill'>
        <div className='sidebar-container2'>
          <TemplateSidebar setFTFilter={this.setFormTypeFilter} onUploadTemplate={this.openUpload} />
        </div>
        <div className='flex-fill'>
            <div
                className='card'
                style={{
                marginTop: '20px',
                marginLeft: '30px',
                marginRight: '30px',
                maxHeight: `${this.state.maxHeight}px`,
                }}
            >
                <div className='card-header'>
                    <table>
                        <tbody>
                            <tr>
                                <td><h4>Templates</h4></td>
                                <td className='row g-3' style={{paddingLeft: '20px'}}>
                                <div className='input-group rounded' >
          <input
            type='search'
            value={this.state.searchText}
            className='form-control'
            placeholder='Search'
            aria-label='Search'
            onChange={this.onSearchChange}
            aria-describedby='search-addon'
          />
          <span className='input-group-text border-0' id='search-addon'>
            <FontAwesomeIcon icon={solid('search')} />
          </span>
        </div>
                                </td>
                                <td className='ml-4' style={{paddingLeft: '20px'}}>
                                <div className='ml-4 form-check form-check-inline'>
                                    <input className='form-check-input' type='radio' onChange={() => this.setActiveFilter('active')}  checked={this.state.activeFilter === 'active'}/><label className='form-check-label'>Active</label>
                                </div>
                                <div className='ml-4 form-check form-check-inline'>
                                    <input className='form-check-input' type='radio' onChange={() => this.setActiveFilter('inactive')} checked={this.state.activeFilter === 'inactive'}/><label className='form-check-label'>Inactive</label>
                                </div>
                                <div className='ml-4 form-check form-check-inline'>
                                    <input className='form-check-input' type='radio' onChange={() => this.setActiveFilter('all')} checked={this.state.activeFilter === 'all'}/><label className='form-check-label'>All</label>
                                </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>


                </div>
                <div
                className='card-body'
                style={{
                    overflowY: 'auto',
                    overflow: 'scroll',
                    maxHeight: '20%',
                }}
                >
            <table className='table'>
                <thead className='thead-dark'>
                    <tr>
                    <th></th>
                    <th >Template Name</th>
                    <th style={{textAlign: 'center'}}>Sample Count</th>
                    <th style={{textAlign: 'center'}}>Last Used</th>
                    <th style={{textAlign: 'center'}}>Create Date</th>
                    <th>Active</th>
                    <th>Audit</th>
                    <th>Usage</th>
                    <th>Download</th>
                    </tr>
                </thead>
                <tbody >
                    {this.state.filteredTemplateList.map((ftg) =>
                        <React.Fragment key={ftg.formTypeId}>
                            <tr key={ftg.formTypeId}>
                                <td colSpan='9' style={{fontSize: '16pt', fontWeight: 'bold'}}><span style={{color: '#40A430'}} ><FontAwesomeIcon icon={solid('file-invoice')} /></span>{' '}{ftg.formTypeName}</td>
                            </tr>
                            {ftg.templates.map((temp) =>
                            <tr key={temp.templateId}>
                            <td><button className='btn btn-sm btn-info' onClick={(e) => this.onTemplateClick(e, temp.templateId)}><FontAwesomeIcon icon={solid('pen-to-square')} /></button></td>
                            <td>{temp.templateName}</td>
                            <td style={{textAlign: 'center'}}>{temp.sampleCount}</td>
                            <td style={{textAlign: 'center'}}>
                                {temp.lastUsedDate === null && <div style={{color:'#999'}}>Never</div>}
                                {temp.lastUsedDate !== null && <div>{temp.lastUsedDate}</div>}
                            </td>
                            <td>{temp.creationDate !== null && <span>{temp.creationDate}</span>}</td>
                            <td><div className='form-check form-switch'>
                                <input className='form-check-input' style={{cursor: 'pointer'}} onChange={()=> this.toggleActive(temp)} type='checkbox' checked={temp.isActive}></input>
                            </div></td>
                            <td><button className='btn btn-sm' style={{backgroundColor: '#8B5EC9', color: '#fff'}} onClick={() => this.onAuditClick(temp.templateId)}><FontAwesomeIcon icon={solid('timeline')} />{' '}Audit</button></td>
                            <td><button className='btn btn-sm' style={{backgroundColor: '#1D8F8D', color: '#fff'}} onClick={() => this.onUsageClick(temp.templateId)}><FontAwesomeIcon icon={solid('hammer')} />{' '}Usage</button></td>
                            <td><button className='btn btn-sm' style={{backgroundColor: '#28A70F', color: '#fff'}} onClick={() => this.onDownloadTemplateLink(temp)}><FontAwesomeIcon icon={solid('download')} />{' '}Download</button></td>


                        </tr>
                            )}
                        </React.Fragment>
                    )}
                </tbody>
            </table>
            </div>

          </div>
        </div>
        {this.state.showAudit && this.state.currentAudit !== undefined && (
          <div className='td-dialogue-container'>
            <div className='td-dialogue card bg-light'
              style={{ height: '520px', width: '450px' }}
              >
                <div className='card-header'>
                  TemplateName - Audit
                  <button onClick={this.closeAudit} className='btn-close' />
                </div>
                <div className='card-body'>
                  <div style={{margin: '20px', maxHeight:'400px', overflow:'auto'}} >
                    <table>
                      <tbody style={{borderTop: '1px solid #ddd'}}>
                        {this.state.currentAudit.map((aud) => (
                          <React.Fragment key={aud.auditId}>
                           <tr style={{borderBottom: '1px solid #ddd'}}>
                            <td className='pe-3 pb-2'><FontAwesomeIcon style={{color: '#8B5EC9'}} icon={solid('User')} />{' '} {aud.userName}</td>
                            <td className='pe-3 pb-2'>{aud.auditType}</td>
                            <td className='pe3 pb-2'>{aud.updateDate}</td>
                            <td className='pe-3 pb-2 ps-2'>
                              {!aud.isOpen && aud.zones.length > 0 && <FontAwesomeIcon icon={solid('angle-down')} onClick={() => this.toggleAudit(aud)} />}
                              {aud.isOpen && aud.zones.length > 0 && <FontAwesomeIcon icon={solid('angle-up')}  onClick={() => this.toggleAudit(aud)} />}
                            </td>
                          </tr>
                          {aud.isOpen && <tr>
                            <td colSpan='4'>
                              <table style={{marginLeft:'8px'}}>
                                <tbody>
                                  {aud.zones.map((zone) => (
                                    <tr style={{borderBottom: '1px solid #ddd'}}>
                                      <td>{zone.updateType}</td>
                                      <td className='ps-2 pe-2'><FontAwesomeIcon icon={solid('arrow-right')} /></td>
                                      {zone.zoneType === 'Capture' &&
                                        <td style={{padding:'4px', backgroundColor: '#A5C8E3'}}>{zone.zoneName}</td>
                                      }
                                      {zone.zoneType === 'Table' &&
                                        <td style={{padding:'4px', backgroundColor: '#E99F5E'}}>{zone.zoneName}</td>
                                      }
                                       {zone.zoneType === 'Identifier' &&
                                        <td style={{padding:'4px', backgroundColor: '#90C58D'}}>{zone.zoneName}</td>
                                      }

                                     {zone.zoneType === 'Floating' &&
                                        <td style={{padding:'4px', backgroundColor: '#FFFA98'}}>{zone.zoneName}</td>
                                      }
                                       {zone.zoneType === 'Split' &&
                                        <td style={{padding:'4px', backgroundColor: '#FF9898'}}>{zone.zoneName}</td>
                                      }
                                      {zone.zoneType === 'Unknown' &&
                                        <td style={{padding:'4px', backgroundColor: '#D5D5D5'}}>{zone.zoneName}</td>
                                      }

                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            </td>
                          </tr> }
                          </React.Fragment>

                        ))}

                      </tbody>
                    </table>
                  </div>
                </div>

              </div>
          </div>
        )}


    {this.state.showUsage && this.state.templateUsage !== undefined && (
          <div className='td-dialogue-container'>
            <div className='td-dialogue card bg-light'
              style={{ height: '520px', width: '450px' }}
              >
                <div className='card-header'>
                  Template Usage
                  <button onClick={this.closeUsage} className='btn-close' />
                </div>
                <div className='card-body'>
                  <div style={{margin: '20px', maxHeight:'400px', overflow:'auto'}} >
                    <table className="table">
                      <thead>
                        <tr>
                          <th>Document Id</th>
                          <th>Upload Date/Time</th>
                        </tr>
                      </thead>
                      <tbody style={{borderTop: '1px solid #ddd'}}>
                        {this.state.templateUsage.map((usage) => (
                          <React.Fragment key={usage.documentId}>
                           <tr style={{borderBottom: '1px solid #ddd'}}>
                            <td className='pe-3 pb-2' onClick={(e) => this.goToDoc(e, usage.documentId)} style={{cursor: 'pointer'}}><FontAwesomeIcon style={{color: '#10A0A0'}} icon={solid('file-lines')} />{' '} {usage.documentId}</td>
                            <td className='pe-3 pb-2'>{this.convertDate(usage.regDtime)}</td>

                          </tr>

                          </React.Fragment>

                        ))}

                      </tbody>
                    </table>
                  </div>
                </div>

              </div>
          </div>
        )}

        {this.state.showUpload && (
          <div className='td-dialogue-container'>
            <div
              className='td-dialogue card'
              style={{ width: '700px', height: '500px' }}
            >
              <div className='card-header'>
                Upload templates{' '}
                <button
                  type='button'
                  className='btn-close'
                  onClick={this.onCloseUploadTemplates}
                />
              </div>
              <div className='card-body'>
                <h4>Upload Templates</h4>
                <table>
                  <tbody>
                    <tr>
                      <td style={{paddingRight: '6px'}}>Form Type:{' '}</td>
                      <td><select
                          className='form-select'
                          value={this.state.uploadFT}
                          onChange={this.chooseUploadFT}
                        >
                          <option value='0'>Select a form type</option>
                          {this.state.formTypes.map((ft) => (
                            <option value={ft.formTypeId} key={ft.formTypeId}>
                              {ft.name}
                            </option>
                          ))}
                        </select></td>
                    </tr>
                  </tbody>
                </table>

                {this.state.uploadFT !== 0 && <div style={{ marginTop: '12px' }}>
                  <div>
                    <Dropzone
                      className='ui raised segment'
                      multiple={false}
                      onDrop={this.onDrop}
                      accept={{
                        'application/zip': ['.zip'],
                      }}
                    >
                      {({ getRootProps, getInputProps }) => (
                        <div className='drop-container'>
                          <div {...getRootProps({ className: 'dropzone' })}>
                            <input {...getInputProps()} />
                            <div className='drop-content'>
                              <FontAwesomeIcon icon={solid('box')} size='3x' />
                              <div className='drop-header'>Upload Templates</div>
                              <p className='drop-subheader'>
                                Drop template packages here
                              </p>
                            </div>
                          </div>
                        </div>
                      )}
                    </Dropzone>
                  </div>
                  <div style={{padding:'6px', maxHeight:'200px', overflow: 'auto'}}>
                        {this.state.recentUploads.map((upload) => (
                          <div>
                            {upload.responseStatus === 'Success' &&
                            <span style={{color:'#27AE60', fontWeight: '500'}}>New Template: <span style={{textDecoration: 'underline', cursor:'pointer'}} onClick={(e) => this.onTemplateClick(e, upload.uploadedTemplateId)}>{upload.uploadedTemplate}</span></span>
                            }
                          {upload.responseStatus === 'Error' && <span style={{color:'red', fontWeight: '700'}}>
                            Error:{' '}{upload.errorMessage}

                            </span>}

                          </div>
                        ))}
                  </div>
                  </div>
                }
              </div>
            </div>
          </div>
        )}
        <Link
                  style={{ display: 'none' }}
                  id='navLink'
                  to={`/TemplateEditor/${this.state.templateId}`}
                >
                  ClickMe
                </Link>
                <Link
            id='docLink'
            to={'/Document/' + this.state.goToDocId}
            style={{ display: 'None' }}
          />
      </div>
    );
  }
}

export default TemplateManager;
