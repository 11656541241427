import { EventEmitter } from 'events';
import Dispatcher from '../Dispatcher';
import actionTypes from '../actions/ActionTypes';

const CHANGE_EVENT = 'documentUploadHistChange';
let _currentDocuments = [];
let _lastAction = '';

class DocumentUploadHistoryStore extends EventEmitter {
  addChangeListener(callback) {
    this.on(CHANGE_EVENT, callback);
  }

  removeChangeListener(callback) {
    this.removeListener(CHANGE_EVENT, callback);
  }

  emitChange() {
    this.emit(CHANGE_EVENT);
  }

  getCurrentDocuments() {
    return _currentDocuments;
  }

  getLastAction() {
    return _lastAction;
  }
}

const store = new DocumentUploadHistoryStore();

Dispatcher.register((action) => {
  switch (action.actionType) {
    case actionTypes.DocHistorySearch:
      _currentDocuments = action.Result.uploads;
      _lastAction = 'load';
      store.emitChange();
      break;
    case actionTypes.DocHistorySearchMore:
      _lastAction = 'load';
      //console.log('store');
      _currentDocuments = _currentDocuments.concat(action.Result.uploads);
      store.emitChange();
      break;
    default:
  }
});

export default store;
