import { EventEmitter } from 'events';
import Dispatcher from '../Dispatcher';
import actionTypes from '../actions/ActionTypes';

const CHANGE_EVENT = 'workflowChange';
let _currentWorkflows = [];
let _currentWorkflow = {};
let _currentLayout = {};
let _lastAction = '';
let _allowedFormTypes = [];
let _availablePlugins = [];
let _currentWorkflowName = '';
let _formTypes = [];

class WorkflowStore extends EventEmitter {
  addChangeListener(callback) {
    this.on(CHANGE_EVENT, callback);
  }

  removeChangeListener(callback) {
    this.removeListener(CHANGE_EVENT, callback);
  }

  emitChange() {
    this.emit(CHANGE_EVENT);
  }

  getCurrentWorkflows() {
    return _currentWorkflows;
  }

  getCurrentWorkflow() {
    return _currentWorkflow;
  }

  getCurrentLayout() {
    return _currentLayout;
  }

  getLastAction() {
    return _lastAction;
  }

  getPlugins() {
    return _availablePlugins;
  }

  getAllowedFormTypes() {
    return _allowedFormTypes;
  }

  getCurrentWorkflowName() {
    return _currentWorkflowName;
  }

  getFormTypes() {
    return _formTypes;
  }
}

const store = new WorkflowStore();

Dispatcher.register((action) => {
  switch (action.actionType) {
    case actionTypes.WorkflowGetWorkflows:
      _currentWorkflows = action.Result;
      _lastAction = action.actionType;
      store.emitChange();
      break;
    case actionTypes.WorkflowUpdate:
      _currentWorkflow = action.Result.workflow;
      _currentLayout = action.Result.layout;
      _lastAction = action.actionType;
      store.emitChange();
      break;
    case actionTypes.WorkflowGetOne:
      _currentWorkflow = action.Result.workflow;
      _currentLayout = action.Result.layout;
      _availablePlugins = action.Result.plugins;
      _lastAction = action.actionType;
      store.emitChange();
      break;
    case actionTypes.WorkflowAllowedFormTypes:
      _allowedFormTypes = action.Result;
      _lastAction = action.actionType;
      store.emitChange();
      break;
    case actionTypes.WorkflowGetFromStep:
      _currentWorkflowName = action.Result;
      _lastAction = action.actionType;
      store.emitChange();
      break;
    case actionTypes.WorkflowFTAndTemp:
      _formTypes = action.Result;
      _lastAction = action.actionType;
      store.emitChange();
      break;

    default:
  }
});

export default store;
