import * as React from 'react';
// import { Link } from 'react-router';
import { Link } from 'react-router-dom';
import FormTypeStore from '../../../../stores/FormTypeStore.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';

import { GetAllFormTypes } from '../../../../actions/FormTypeActions';

class FormTypeSidebar extends React.Component {
  constructor() {
    super();
    this.state = {
      formTypeList: [],
      searchText: '',
      filteredFormTypeList: [],
    };
    this.onReceiveFormTypeChange = this.onReceiveFormTypeChange.bind(this);
    this.onSearchChange = this.onSearchChange.bind(this);
  }

  componentDidMount() {
    FormTypeStore.addChangeListener(this.onReceiveFormTypeChange);
    GetAllFormTypes();
  }

  componentWillUnmount() {
    FormTypeStore.removeChangeListener(this.onReceiveFormTypeChange);
  }

  onReceiveFormTypeChange() {
    let lastChange = FormTypeStore.getLastAction();
    if (lastChange.actionType === 'FTGetAll') {
      let formTypes = FormTypeStore.getAllFormTypes();

      this.setState({
        formTypeList: formTypes,
        searchText: '',
        filteredFormTypeList: formTypes,
      });
    }
  }

  onSearchChange(e) {
    let search = e.target.value;
    if (search === '') {
      // no search, show all
      let newList = [...this.state.formTypeList];
      this.setState({ searchText: search, filteredFormTypeList: newList });
    } else {
      // filter results
      let newList = [];
      this.state.formTypeList.forEach((formType) => {
        if (formType.name.toLowerCase().includes(search.toLowerCase())) {
          newList.push(formType);
        }
      });
      this.setState({ searchText: search, filteredFormTypeList: newList });
    }
  }

  render() {
    let listOfDirs;
    if (this.state.filteredFormTypeList !== undefined) {
      listOfDirs = this.state.filteredFormTypeList.map((formType) => (
        <div key={formType.formTypeId}>
          <Link
            className='sidebar-link'
            to={'/Admin/FormType/' + formType.formTypeId}
            //onClick={() => this.handleBPClick(bp)}
            style={{ cursor: 'Pointer', wordWrap: 'break-word' }}
          >
            {formType.name}
          </Link>{' '}
        </div>
      ));
    } else {
      listOfDirs = <div />;
    }
    return (
      <div
        className='d-flex flex-column p-3 text-white bg-dark'
        style={{ height: '100%', width: '245px' }}
      >
        <Link
          to='/Admin/formtypes'
          className='d-flex align-items-center mb-3 mb-md-0 me-md-auto text-white text-decoration-none'
        >
          <div className='fs-4'>
            <span style={{ color: '#23B0DD' }}>
              <FontAwesomeIcon icon={regular('file-alt')} />
              {'   '}
            </span>
            Form Types
          </div>
        </Link>{' '}
        {/* link */}
        <hr />
        <div className='input-group rounded' style={{ marginBottom: '16px' }}>
          <input
            type='search'
            value={this.state.searchText}
            className='form-control'
            placeholder='Search'
            aria-label='Search'
            onChange={this.onSearchChange}
            aria-describedby='search-addon'
          />
          <span className='input-group-text border-0' id='search-addon'>
            <FontAwesomeIcon icon={solid('search')} />
          </span>
        </div>
        <div>{listOfDirs}</div>
        <hr />
      </div>
    );
  }
}

export default FormTypeSidebar;
