import dispatcher from '../Dispatcher';
import actionTypes from './ActionTypes';
import axios from 'axios';
import * as Globals from '../Globals';
import { Logout } from './LoginActions';
import { PostAlert } from './AlertActions';

export function LoadFormType(id) {
  console.log('loading formtype ' + id);
  axios
    .post(
      `${Globals.baseUrl}/FormType/Lookup`,
      { ftId: id.toString() },
      {
        headers: {
          'content-type': 'application/json; charset=utf-8',
          Authorization: `Bearer ${Globals.getJwtToken()}`,
        },
      }
    )
    .then((res) => {
      if (res.status === 200) {
        dispatcher.dispatch({
          actionType: actionTypes.FTLoad,
          Result: res.data,
        });
      } else if (res.status === 400 || res.status === 401) {
        console.log('error: 401');
      }
    })
    .catch((res) => {
      console.log('error');
    });
}

export function UpdateFormType(ft) {
  axios
    .post(`${Globals.baseUrl}/FormType/Update`, ft, {
      headers: {
        'content-type': 'application/json; charset=utf-8',
        Authorization: `Bearer ${Globals.getJwtToken()}`,
      },
    })
    .then((res) => {
      console.log(res);

      if (res.status === 200) {
        let result = res.data;
        console.log(res);
        if (result.status === 'Success') {
          console.log('update');
          PostAlert('success', result.updateMessage);
        }
        if (result.status === 'Error') {
          PostAlert('error', result.updateMessage);
        }
        dispatcher.dispatch({
          actionType: actionTypes.FTUpdate,
          Result: res.data,
        });
      }
    })
    .catch((res) => {
      let errorMsg = res.toString();
      if (errorMsg.includes('401')) {
        console.log('Unauthorized');
        Logout();
      }
    });
}

export function GetAllFormTypes() {
  //let newUrl = getNewUrl();
  axios
    .post(
      `${Globals.baseUrl}/FormType/GetAll`,
      {},
      {
        headers: {
          'content-type': 'application/json; charset=utf-8',
          Authorization: `Bearer ${Globals.getJwtToken()}`,
        },
      }
    )
    .then((res) => {
      if (res.status === 200) {
        dispatcher.dispatch({
          actionType: actionTypes.FTGetAll,
          Result: res.data,
        });
      } else if (res.status === 400 || res.status === 401) {
        console.log('error: 401');
      }
    })
    .catch((res) => {
      let errorMsg = res.toString();
      if (errorMsg.includes('401')) {
        console.log('Unauthorized');
        Logout();
      }
    });
}

export function GetAllFormTypesWithPermission() {
  axios
    .post(
      `${Globals.baseUrl}/FormType/GetAllFormTypesWithPermission`,
      {},
      {
        headers: {
          'content-type': 'application/json; charset=utf-8',
          Authorization: `Bearer ${Globals.getJwtToken()}`,
        },
      }
    )
    .then((res) => {
      if (res.status === 200) {
        dispatcher.dispatch({
          actionType: actionTypes.FTGetAll,
          Result: res.data,
        });
      } else if (res.status === 400 || res.status === 401) {
        console.log('error: 401');
      }
    })
    .catch((res) => {
      let errorMsg = res.toString();
      if (errorMsg.includes('401')) {
        console.log('Unauthorized');
        Logout();
      }
    });
}

export function UpdateExtender(extender) {
  //extender.extenderDependencies = [];
  console.log(extender);
  axios
    .post(
      `${Globals.baseUrl}/Extender/UpdateFieldExtender`,
      { update: extender },
      {
        headers: {
          'content-type': 'application/json; charset=utf-8',
          Authorization: `Bearer ${Globals.getJwtToken()}`,
        },
      }
    )
    .then((res) => {
      if (res.status === 200) {
        dispatcher.dispatch({
          actionType: actionTypes.FTUpdateExtender,
          Result: res.data,
        });
        PostAlert('success', 'Extender updated');
      } else if (res.status === 400 || res.status === 401) {
        console.log('error: 401');
      }
    })
    .catch((res) => {
      console.log('error');
    });
}

export function RemoveExtender(extender) {
  extender.extenderDependencies = [];
  console.log(extender);
  axios
    .post(
      `${Globals.baseUrl}/Extender/RemoveFieldExtender`,
      { update: extender },
      {
        headers: {
          'content-type': 'application/json; charset=utf-8',
          Authorization: `Bearer ${Globals.getJwtToken()}`,
        },
      }
    )
    .then((res) => {
      if (res.status === 200) {
        dispatcher.dispatch({
          actionType: actionTypes.FTRemoveExtender,
          Result: res.data,
        });
        PostAlert('success', 'Extender updated');
      } else if (res.status === 400 || res.status === 401) {
        console.log('error: 401');
      }
    })
    .catch((res) => {
      console.log('error');
    });
}

export function GetAllDataSources() {
  axios
    .get(`${Globals.baseUrl}/FormType/GetAllDataSources`, {
      headers: {
        'content-type': 'application/json; charset=utf-8',
        Authorization: `Bearer ${Globals.getJwtToken()}`,
      },
    })
    .then((res) => {
      if (res.status === 200) {
        //FTGetAllDataSource
        dispatcher.dispatch({
          actionType: actionTypes.FTGetAllDataSource,
          Result: res.data,
        });
      } else if (res.status === 400 || res.status === 401) {
        console.log('error: 401');
      }
    })
    .catch((res) => {
      console.log('error');
    });
}

export function GetPluginModel(ftId) {
  axios
    .get(`${Globals.baseUrl}/FormType/GetCSharpClass/${ftId}`, {
      headers: {
        'content-type': 'application/json; charset=utf-8',
        Authorization: `Bearer ${Globals.getJwtToken()}`,
      },
    })
    .then((res) => {
      if (res.status === 200) {
        console.log(res.data);

        navigator.clipboard.writeText(res.data).then(() => {
          PostAlert('success', 'Model written to clipboard');
        });
      } else if (res.status === 400 || res.status === 401) {
        console.log('error: 401');
      }
    })
    .catch((res) => {
      console.log('error');
    });
}
