import { Component } from 'react';
import { Routes, Route } from 'react-router-dom';
import logo from '../images/TD AI Logo 2 Color Clear.png';

import AppHeader from '../components/appHeader';
import { RemoveAlert } from '../actions/AlertActions';
import AlertStore from '../stores/AlertStore';
import SiteMenu from './SiteMenu';
import LoginStore from '../stores/LoginStore';
import actionTypes from '../actions/ActionTypes';

// public
import WebUploadView from './Regular/WebUpload';
import TemplateHome from './Regular/TemplateEditor/TemplateHome';
import TemplateEditor from './Regular/TemplateEditor/TemplateEditor';
import TemplateManager from './Regular/TemplateEditor/TemplateManager';
import ActiveDocuments from './Regular/ActiveDocuments/ActiveDocuments';
import DocumentEditor from './Regular/Document/DocumentEditor';
import Search from './Regular/Search/Search';
import SysPanel from './Admin/SysPanel';
import ImageReviewHome from './Regular/ImageReview/ImageReviewHome';
import ImageReviewEdit from './Regular/ImageReview/ImageReviewEdit';
import UserProfileView from './Regular/UserProfile/UserProfileView';
import Inbox from './Regular/Inbox/Inbox';
import Filters from './Regular/Inbox/Filters';
import FiltersHome from './Regular/Inbox/FiltersHome';

// user management
import ADOAuthSettingsView from '../Views/Admin/Users/ADOAuthSettings';
import Users from '../Views/Admin/Users/Users';
import UsersView from '../Views/Admin/Users/UsersView';
import Roles from '../Views/Admin/Users/Roles';
import RolesView from '../Views/Admin/Users/RolesView';

// business process
import BusinessProcessHome from './Admin/Business Process/BusinessProcess/BusinessProcess';
import BusinessProcessView from './Admin/Business Process/BusinessProcess/BusinessProcessView';
import OrgUnitHome from './Admin/Business Process/OrgUnits/OrgUnit';
import OrgUnitView from './Admin/Business Process/OrgUnits/OrgUnitView';
import WorkflowsHome from './Admin/Business Process/Workflows/Workflows';
import WorkflowView from './Admin/Business Process/Workflows/WorkflowView2';
import FormTypeView from './Admin/Business Process/FormTypes/FormTypeView';
import FormTypesHome from './Admin/Business Process/FormTypes/FormTypes';

// integration
import ModuleHome from './Admin/Integration/Modules/ModuleHome';
import ModuleView from './Admin/Integration/Modules/ModuleView';
import ScheduleHome from './Admin/Integration/Schedules/ScheduleHome';
import ScheduleView from './Admin/Integration/Schedules/ScheduleView';
// intake
import Directories from './Admin/Intake/DirectoryUpload/Directories';
import DirectoryView from './Admin/Intake/DirectoryUpload/DirectoryView';
import UploadTrackerView from '../Views/Admin/UploadTracker/UploadTrackerView';
import IntakeStrategyHome from './Admin/Intake/IntakeStrategies/IntakeStrategy';
import IntakeStrategyNew from './Admin/Intake/IntakeStrategies/IntakeStrategyNew';
import IntakeStrategyView from './Admin/Intake/IntakeStrategies/IntakeStrategyView';
import IntakeSettings from './Admin/Intake/IntakeSettings/IntakeSettings';
import InboundAutomationHome from './Admin/Intake/InboundAutomation/InboundAutomationHome';
import InboundAutomationView from './Admin/Intake/InboundAutomation/InboundAutomationView';
import InboundAutomationNew from './Admin/Intake/InboundAutomation/InboundAutomationNew';
import AutoOcrDocumentTypeView from './Admin/Intake/AutoOCR/AutoOcrDocumentTypeView';
import AutoOcrMappingView from './Admin/Intake/AutoOCR/AutoOcrMappingView';
import AutoOcrHome from './Admin/Intake/AutoOCR/AutoOcrHome';
import DocumentUploadHistoryView from './Regular/DocumentUploadHistory/DocumentUploadHistoryView';

// config
import ArchiveHome from './Admin/Config/Archive/ArchiveHome';
import ArchiveView from './Admin/Config/Archive/ArchiveView';
import LicenseView from '../Views/Admin/License/License';
import LicenceAllocation from '../Views/Admin/License/LicenseAllocation';
import LogView from '../Views/Regular/Logging/LogsView';
import SecretHome from './Admin/Config/Secret/SecretHome';
import SecretView from './Admin/Config/Secret/SecretView';
import MailboxHome from './Admin/Config/Mailboxes/MailboxHome';
import MailboxView from './Admin/Config/Mailboxes/MailboxView';
import ConnectionHome from './Admin/Config/Connection/ConnectionHome';
import ConnectionView from './Admin/Config/Connection/ConnectionView';
import Companion from './Admin/CompanionTables/Companion';
import CompanionView from './Admin/CompanionTables/CompanionView';
import SystemSettings from './Admin/SystemSettings/SystemSettings';
import DocAction from './Regular/DocAction';
import TableEditor from './Admin/TableEditor/TableEditor';
import TableEditorView from './Admin/TableEditor/TableEditorView';

import SystemAuditLogsView from './Admin/SystemAudit/SystemAuditLogs';

// "I am root"
class AppRoot extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userId: '',
      showSiteMenu: false,
      userName: '',
      licenseLevel: 0,
      canImageReview: false,
      alerts: [],
      showAboutTrinDocs: false,
      defaultHome: 'Active Documents'
    };

    this.toggleSiteMenu = this.toggleSiteMenu.bind(this);
    this.onBodyClick = this.onBodyClick.bind(this);
    this.onAlertUpdate = this.onAlertUpdate.bind(this);
    this.toggleShowAboutTrinDocs = this.toggleShowAboutTrinDocs.bind(this);
  }

  componentDidMount() {
    LoginStore.addChangeListener(this.onLoginUpdate);
    AlertStore.addChangeListener(this.onAlertUpdate);
    this.updateUserInfo();
    this.onAlertUpdate();
  }

  updateUserInfo() {
    let user = LoginStore.getUser();
    if (user !== undefined && user.userName !== undefined) {
      this.setState({
        userName: user.userName,
        licenseLevel: user.licenseLevel,
        canImageReview: user.permissions.canImageReview,
        defaultHome: user.defaultHome,
      });
    }
  }

  componentWillUnmount() {
    LoginStore.removeChangeListener(this.onLoginUpdate);
    AlertStore.removeChangeListener(this.onAlertUpdate);
  }

  onLoginUpdate() {
    let lastAction = LoginStore.getLastAction();
    if (lastAction === 'Login') {
      this.updateUserInfo();
    }
    if(lastAction === actionTypes.LoginCheckVer)
    {
        let serverVersion = LoginStore.getTDVersion();
        let appVersion = process.env.REACT_APP_VERSION;
        if(serverVersion !== appVersion && appVersion !== '6.0.0.23')
        {
          alert("It appears that TrinDocs has been updated and your browser does not have the latest version.  Please hit Control-F5 or Control-Shift-R to update your local browser.")

        }
    }
  }

  onAlertUpdate() {
    let currentAlerts = AlertStore.getAlerts();
    this.setState({ alerts: currentAlerts });
  }

  onAlertRemove(id) {
    RemoveAlert(id);
  }

  onBodyClick() {
    this.setState({ showSiteMenu: false });
  }

  toggleSiteMenu() {
    this.setState({ showSiteMenu: !this.state.showSiteMenu });
  }

  toggleShowAboutTrinDocs() {
    this.setState({ showAboutTrinDocs: !this.state.showAboutTrinDocs });
  }

  render() {
    return (
      <div
        className='AppRoot h-100 d-flex flex-column'
        style={{ overflowX: 'hidden', overflowY: 'auto' }}
      >
        <AppHeader
          user='admin'
          onToggle={this.toggleSiteMenu}
          toggleAboutTrinDocs={this.toggleShowAboutTrinDocs}
        />
        <div className='flex-fill'>
          <div className='d-flex flex-row h-100'>
            {this.state.showSiteMenu && (
              <div className='tdSideNavbar coolScroll'>
                <SiteMenu
                  licenseLevel={this.state.licenseLevel}
                  canImageReview={this.state.canImageReview}
                  onToggle={this.toggleSiteMenu}
                />
              </div>
            )}
            <div
              className='flex-fill d-flex flex-row'
              onClick={this.onBodyClick}
            >
              <Routes>
                {this.state.defaultHome !== 'Active Documents' &&
                <Route path='/' element={<Inbox />}>
                  {/* <Route path='Inbox' element={<HomeView />} /> */}
                </Route>
                }
                {this.state.defaultHome === 'Active Documents' &&
                <Route path='/' element={<ActiveDocuments />}>
                  {/* <Route path='Inbox' element={<HomeView />} /> */}
                </Route>
                }
                <Route path='/Inbox' element={<Inbox />} />
                <Route path='/Filters' element={<FiltersHome />} />
                <Route path='/Filters/:id' element={<Filters />} />
                <Route path='/ActiveDocuments' element={<ActiveDocuments />} />
                <Route path='/Search' element={<Search />} />
                <Route path='/Document/:id' element={<DocumentEditor />} />
                <Route path='/DocAction' element={<DocAction />} />
                {this.state.canImageReview && (
                  <>
                    <Route path='/ImageReview' element={<ImageReviewHome />} />
                    <Route
                      path='/ImageReviewEdit'
                      element={<ImageReviewEdit />}
                    />
                  </>
                )}
                <Route path='/WebUpload' element={<WebUploadView />} />
                <Route path='/UserProfile' element={<UserProfileView />} />
                <Route
                  path='/DocUploadHist'
                  element={<DocumentUploadHistoryView />}
                />
                {this.state.licenseLevel >= 3 && (
                  <>
                    <Route path='/TemplateEditor' element={<TemplateManager />} />
                    <Route
                      path='/TemplateEditor/:id'
                      element={<TemplateEditor />}
                    />
                    <Route
                      path='/TemplateEditor/:id/:id2'
                      element={<TemplateEditor />}
                    />

                  </>
                )}
                {this.state.licenseLevel >= 4 && (
                  <>
                    <Route path='/SysPanel' element={<SysPanel />} />
                    <Route path='/Admin/OrgUnits' element={<OrgUnitHome />} />
                    <Route
                      path='/Admin/OrgUnit/:id'
                      element={<OrgUnitView />}
                    />
                    <Route path='/Admin/Logs' element={<LogView />} />
                    <Route
                      path='/Admin/BusinessProcess'
                      element={<BusinessProcessHome />}
                    />
                    <Route
                      path='/Admin/BusinessProcess/:id'
                      element={<BusinessProcessView />}
                    />
                    <Route
                      path='/Admin/Workflows'
                      element={<WorkflowsHome />}
                    />

                    <Route
                      path='/Admin/WorkflowView/:id'
                      element={<WorkflowView />}
                    />
                    <Route
                      path='/Admin/FormType/:id'
                      element={<FormTypeView />}
                    />
                    <Route
                      path='/Admin/FormTypes'
                      element={<FormTypesHome />}
                    />
                    <Route path='/Admin/Modules' element={<ModuleHome />} />
                    <Route path='/Admin/Module/:id' element={<ModuleView />} />

                    <Route path='/Admin/Schedules' element={<ScheduleHome />} />
                    <Route
                      path='/Admin/Schedule/:id'
                      element={<ScheduleView />}
                    />

                    <Route path='/Admin/Users' element={<Users />} />
                    <Route
                      path='/Admin/UsersView/:id'
                      element={<UsersView />}
                    />
                    <Route path='/Admin/Roles' element={<Roles />} />
                    <Route
                      path='/Admin/RolesView/:id'
                      element={<RolesView />}
                    />
                    <Route
                      path='/Admin/LicenseAllocation'
                      element={<LicenceAllocation />}
                    />
                    <Route
                      path='/Admin/LoginSettings'
                      element={<ADOAuthSettingsView />}
                    />
                    <Route
                      path='/Admin/Directories'
                      element={<Directories />}
                    />
                    <Route
                      path='/Admin/DirectoryView/:id'
                      element={<DirectoryView />}
                    />
                    <Route
                      path='/Admin/IntakeStrategies'
                      element={<IntakeStrategyHome />}
                    />
                    <Route
                      path='/Admin/IntakeStrategies/new'
                      element={<IntakeStrategyNew />}
                    />
                    <Route
                      path='/Admin/IntakeStrategies/:id'
                      element={<IntakeStrategyView />}
                    />
                    <Route
                      path='/Admin/InboundAutomationView/:id'
                      element={<InboundAutomationView />}
                    />
                    <Route
                      path='/Admin/InboundAutomationHome'
                      element={<InboundAutomationHome />}
                    />
                    <Route
                      path='/Admin/newIAView'
                      element={<InboundAutomationNew />}
                    />

                    <Route
                      path='/Admin/UploadTracker'
                      element={<UploadTrackerView />}
                    />
                    <Route
                      path='/Admin/IntakeSettings'
                      element={<IntakeSettings />}
                    />
                    <Route
                      path='/Admin/SystemSettings'
                      element={<SystemSettings />}
                    />
                    <Route path='/Admin/AutoOCR' element={<AutoOcrHome />} />
                    <Route
                      path='/Admin/AutoOCRDocumentType/:id'
                      element={<AutoOcrDocumentTypeView />}
                    />
                    <Route
                      path='/Admin/AutoOcrMapping/:id'
                      element={<AutoOcrMappingView />}
                    />
                    <Route path='/Admin/Archive' element={<ArchiveHome />} />
                    <Route
                      path='/Admin/ArchiveView/:id'
                      element={<ArchiveView />}
                    />

                    <Route path='/Admin/License' element={<LicenseView />} />

                    <Route path='/Admin/Secret' element={<SecretHome />} />
                    <Route
                      path='/Admin/SecretView/:id'
                      element={<SecretView />}
                    />

                    <Route
                      path='/Admin/Connection'
                      element={<ConnectionHome />}
                    />
                    <Route
                      path='/Admin/ConnectionView/:id'
                      element={<ConnectionView />}
                    />

                    <Route path='/Admin/Mailbox' element={<MailboxHome />} />
                    <Route
                      path='/Admin/MailboxView/:id'
                      element={<MailboxView />}
                    />

                    <Route path='/Admin/Companion' element={<Companion />} />
                    <Route
                      path='/Admin/CompanionView/:id'
                      element={<CompanionView />}
                    />

                    <Route path='/Admin/TableEditor' element={<TableEditor />} />
                    <Route
                      path='/Admin/TableEditorView/:id'
                      element={<TableEditorView />}
                    />
                    <Route path='/Admin/SystemAuditLogs/:id/:id2' element={<SystemAuditLogsView />} />
                  </>
                )}
              </Routes>
            </div>
            {this.state.alerts.length > 0 && (
              <div
                className='toast-container position-fixed bottom-0 end-0'
                style={{ padding: '6px', paddingBottom: '20px', zIndex: '100' }}
              >
                {this.state.alerts.map((alert) => (
                  <div
                    key={alert.id}
                    className={`toast toast-${alert.alertType} show`}
                    role='alert'
                    display='block'
                  >
                    <div
                      className='toast-header td-toast-header'
                      style={{
                        borderRadius: '8px',
                        paddingRight: '15px',
                      }}
                    >
                      <span
                        className='me-auto toast-message'
                        style={{ marginLeft: '6px' }}
                      >
                        {alert.message}
                      </span>
                      <button
                        type='button'
                        className='btn-close'
                        data-bs-dismiss='toast'
                        area-label='Close'
                        onClick={() => this.onAlertRemove(alert.id)}
                      ></button>
                    </div>
                  </div>
                ))}
              </div>
            )}

            {this.state.showAboutTrinDocs && (
              <div className='td-dialogue-container'>
                <div
                  className='td-dialogue card'
                  style={{ height: '220px', width: '400px' }}
                >
                  <div className='card-header'>
                    About TrinDocs
                    <button
                      onClick={this.toggleShowAboutTrinDocs}
                      className='btn-close'
                    />
                  </div>
                  <div className='card-body'>
                    <div className='d-flex justify-content-center '>
                      <img style={{maxWidth: '300px'}} src={logo} className='App-logo' alt='logo' />
                    </div>
                    <div className='d-flex justify-content-center'>
                      <div className='App-version'>
                        {' '}
                        Version {process.env.REACT_APP_VERSION}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default AppRoot;
