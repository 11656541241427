import dispatcher from '../Dispatcher';
import actionTypes from './ActionTypes';
import axios from 'axios';
import * as Globals from '../Globals';

export function GetIRTree() {
  axios
    .get(`${Globals.baseUrl}/ImageReview/GetIRTree`, {
      headers: {
        'content-type': 'application/json; charset=utf-8',
        Authorization: `Bearer ${Globals.getJwtToken()}`,
      },
    })
    .then((res) => {
      if (res.status === 200) {
        dispatcher.dispatch({
          actionType: actionTypes.IRGetADTree,
          Result: res.data,
        });
      } else if (res.status === 400 || res.status === 401) {
        console.log('error: 401');
      }
    })
    .catch((res) => {});
}

export function GetIRStep(id, bpId) {
  axios
    .get(`${Globals.baseUrl}/ImageReview/GetIRStep/${id}/${bpId}`, {
      headers: {
        'content-type': 'application/json; charset=utf-8',
        Authorization: `Bearer ${Globals.getJwtToken()}`,
      },
    })
    .then((res) => {
      if (res.status === 200) {
        dispatcher.dispatch({
          actionType: actionTypes.IRGetStep,
          Result: res.data,
        });
      } else if (res.status === 400 || res.status === 401) {
        console.log('error: 401');
      }
    })
    .catch((res) => {});
}

export function GetIRBP(bpId) {
  axios
    .get(`${Globals.baseUrl}/ImageReview/GetIRBP/${bpId}`, {
      headers: {
        'content-type': 'application/json; charset=utf-8',
        Authorization: `Bearer ${Globals.getJwtToken()}`,
      },
    })
    .then((res) => {
      if (res.status === 200) {
        dispatcher.dispatch({
          actionType: actionTypes.IRGetBP,
          Result: res.data,
        });
      } else if (res.status === 400 || res.status === 401) {
        console.log('error: 401');
      }
    })
    .catch((res) => {});
}

export function GetImageReview(docIds) {
  axios
    .post(
      `${Globals.baseUrl}/ImageReview/GetImageReview`,
      {
        docIds: docIds,
      },
      {
        headers: {
          'content-type': 'application/json; charset=utf-8',
          Authorization: `Bearer ${Globals.getJwtToken()}`,
        },
      }
    )
    .then((res) => {
      if (res.status === 200) {
        console.log(res.data);
        dispatcher.dispatch({
          actionType: actionTypes.IRGetImageReview,
          Result: res.data,
        });
      } else if (res.status === 400 || res.status === 401) {
        console.log('error: 401');
      }
    })
    .catch((res) => {});
}

export function UpdateReview(originals, news, bp) {
  axios
    .post(
      `${Globals.baseUrl}/ImageReview/UpdateReview`,
      {
        originalDocs: originals,
        newDocs: news,
        bpId: bp,
      },
      {
        headers: {
          'content-type': 'application/json; charset=utf-8',
          Authorization: `Bearer ${Globals.getJwtToken()}`,
        },
      }
    )
    .then((res) => {
      if (res.status === 200) {
        console.log(res.data);
        dispatcher.dispatch({
          actionType: actionTypes.IRUpdate,
          Result: res.data,
        });
      } else if (res.status === 400 || res.status === 401) {
        console.log('error: 401');
      }
    })
    .catch((res) => {});
}

export function FetchImage(documentId, imageId) {
  console.log('Fetch');
  axios
    .post(
      `${Globals.baseUrl}/Image/FetchImage`,
      { documentId: documentId, pageId: imageId },
      {
        headers: {
          'content-type': 'application/json; charset=utf-8',
          Authorization: `Bearer ${Globals.getJwtToken()}`,
        },
      }
    )
    .then((res) => {
      if (res.status === 200) {
        dispatcher.dispatch({
          actionType: actionTypes.IRFetchImage,
          Result: res.data,
        });
      } else if (res.status === 400 || res.status === 401) {
        console.log('error: 401');
      }
    })
    .catch((res) => {
      console.log(res);
    });
}
