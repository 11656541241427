import * as React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import actionTypes from '../../../../actions/ActionTypes';
import IntakeStrategySidebar from './IntakeStrategySidebar';
import { createBrowserHistory } from 'history';
import {
  LoadIntakeStrategies,
  LoadIntakeStrategy,
  UpdateIntakeStrategy,
  DeleteIntakeStrategy,
} from '../../../../actions/ISActions';

import { GetBPList } from '../../../../actions/BPActions';
import { GetTemplateList } from '../../../../actions/TemplateActions';
import ISStore from '../../../../stores/IntakeStrategyStore';
import TemplateStore from '../../../../stores/TemplateStore';
import BPStore from '../../../../stores/BPStore';
import { IntakeStrategyTarget } from '../../../../models/admin/IntakeStrategy';

const customHistory = createBrowserHistory();

class BPSelection {
  selectionId;
  display;
  typeId;
  bpId;
  wfId;
  ftpId;
  ftId;
  workflow;
  formType;
}

class IntakeStrategyView extends React.Component {
  constructor(props) {
    super(props);
    // state
    this.state = {
      currentIS: undefined,
      showEditName: false,
      editName: '',
      showBCDialog: false,
      showBCAdder: false,
      //barcodes: [],
      selectedBarcode: '',
      editBarcodes: [],
      legacyBPs: [],
      bpSelections: [],
      allTemplates: [],
      filteredTemplates: [],
      selectedBPId: 0,
      selectedTemplateId: 0,
      isDirty: false,
      isRearranging: false,
      templateError: '',
      deleteStatus: 0,
      newBarcodeFormula: '',
      doesIronServiceExist: false,
      doesOcrServiceExist: false,
    };

    this.onISChange = this.onISChange.bind(this);
    this.checkForUpdate = this.checkForUpdate.bind(this);
    this.isNameChangeClick = this.isNameChangeClick.bind(this);
    this.handleNameChange = this.handleNameChange.bind(this);
    this.handleNameSaveClick = this.handleNameSaveClick.bind(this);
    this.handleNameCancelClick = this.handleNameCancelClick.bind(this);
    this.ocrRadioChange = this.ocrRadioChange.bind(this);
    this.onFastPassChange = this.onFastPassChange.bind(this);
    this.onReadBarcodeChange = this.onReadBarcodeChange.bind(this);
    this.onEnablePreSplitChange = this.onEnablePreSplitChange.bind(this);
    this.onEnableBarcodeSplitChange =
      this.onEnableBarcodeSplitChange.bind(this);
    //this.openBCDialog = this.openBCDialog.bind(this);
    this.hideBCDialog = this.hideBCDialog.bind(this);
    this.openBCAdder = this.openBCAdder.bind(this);
    this.hideBCAdder = this.hideBCAdder.bind(this);
    //this.onBCChange = this.onBCChange.bind(this);
    this.handleBCSelectionChange = this.handleBCSelectionChange.bind(this);
    this.handleAddBarcode = this.handleAddBarcode.bind(this);
    this.onEditBarcode = this.onEditBarcode.bind(this);
    this.onDeleteBCRow = this.onDeleteBCRow.bind(this);
    this.onAddFormula = this.onAddFormula.bind(this);
    //this.onSaveBarcodes = this.onSaveBarcodes.bind(this);
    this.onCancelBarcodes = this.onCancelBarcodes.bind(this);
    this.onTemplateStrategyChange = this.onTemplateStrategyChange.bind(this);
    this.onLegacyBPChange = this.onLegacyBPChange.bind(this);
    this.onTemplateChange = this.onTemplateChange.bind(this);
    this.handleBPSelection = this.handleBPSelection.bind(this);
    this.onOCRQualityChange = this.onOCRQualityChange.bind(this);
    this.onSaveClick = this.onSaveClick.bind(this);
    this.onAddTarget = this.onAddTarget.bind(this);
    this.handleSelectTarget = this.handleSelectTarget.bind(this);
    this.onTargetOk = this.onTargetOk.bind(this);
    this.onTargetEdit = this.onTargetEdit.bind(this);
    this.onTargetDel = this.onTargetDel.bind(this);
    this.onRearrangeClick = this.onRearrangeClick.bind(this);
    this.onMoveTargetDown = this.onMoveTargetDown.bind(this);
    this.onMoveTargetUp = this.onMoveTargetUp.bind(this);
    this.onChangeDupeDetection = this.onChangeDupeDetection.bind(this);
    this.handleTemplateSelection = this.handleTemplateSelection.bind(this);
    this.updateTemplateFilter = this.updateTemplateFilter.bind(this);
    this.handleDeleteBarcode = this.handleDeleteBarcode.bind(this);
    this.deleteIntakeStrategy = this.deleteIntakeStrategy.bind(this);
    this.startDelete = this.startDelete.bind(this);
    this.cancelDelete = this.cancelDelete.bind(this);
    this.selectTargetTemplate = this.selectTargetTemplate.bind(this);
    this.handleBarcodeEdit = this.handleBarcodeEdit.bind(this);
    this.onPreFlattenChange = this.onPreFlattenChange.bind(this);
  }

  componentDidMount() {
    ISStore.addChangeListener(this.onISChange);
    BPStore.addChangeListener(this.onLegacyBPChange);
    TemplateStore.addChangeListener(this.onTemplateChange);
    LoadIntakeStrategies();
    this.updateIS();
    document.title = 'TrinDocs - Intake Strategies'
  }

  componentWillUnmount() {
    ISStore.removeChangeListener(this.onISChange);
    BPStore.removeChangeListener(this.onLegacyBPChange);
    TemplateStore.removeChangeListener(this.onTemplateChange);
  }

  componentDidUpdate() {
    this.checkForUpdate();
  }

  onISChange() {
    let iStrategy = ISStore.getCurrentIS();
    console.log(iStrategy);

    let lastAction = ISStore.getLastAction();
    if (lastAction === actionTypes.IntakeStrategyDelete) {
      let link = document.getElementById('intakeStrategyHomeLink');
      link.click();
    } else if (iStrategy !== undefined) {
      let ironOcrValid = ISStore.getIronService();
      let ocrValid = ISStore.getOcrService();

      this.setState(
        {
          currentIS: iStrategy,
          selectedBPId: iStrategy.bpId,
          isDirty: false,
          doesIronServiceExist: ironOcrValid,
          doesOcrServiceExist: ocrValid
        },
        this.updateTemplateFilter()
      );
    }
  }

  checkForUpdate() {
    let loc = window.location.pathname;
    let pathParts = loc.split('/');
    let isId = parseInt(pathParts[pathParts.length - 1], 10);
    if (
      isId !== 0 &&
      this.state.currentIS !== undefined &&
      this.state.currentIS.id !== isId
    ) {
      this.updateIS();
    }
  }

  // onBCChange() {
  //    let newBarcodes = BarcodeStore.getBarcodes();
  //    this.setState({ barcodes: newBarcodes });
  // }

  onLegacyBPChange() {
    let legacy = BPStore.getBPList();
    this.setState({ legacyBPs: legacy });
    console.log(legacy);
    let selections = [];

    let def = new BPSelection();
    def.selectionId = 0;
    def.display = 'Select a business process';
    def.bpId = 0;
    def.ftpId = 0;
    def.typeId = -1;
    selections.push(def);

    let nextId = 1;
    legacy.forEach((ftp) => {
      let s = new BPSelection();
      s.selectionId = nextId;
      s.display = ftp.bpName;
      s.bpId = ftp.bpId;
      s.typeId = 0;
      s.wfId = ftp.workflowId;
      s.workflow = '';
      s.formType = '';
      s.ftId = ftp.formTypeId;
      //s.ftpId = ftp.formTypeProductId;
      selections.push(s);
      nextId++;
    });
    this.setState({ bpSelections: selections }, this.updateTemplateFilter);
  }

  onTemplateChange() {
    console.log('templateChange');
    let templates = [];
    let templateGroups = TemplateStore.getTemplateList();
    console.log(templateGroups);
    if (templateGroups !== null && templateGroups !== undefined) {
      templateGroups.forEach((tg) => {
        tg.templates.forEach((t) => {
          templates.push(t);
        });
      });
      console.log(templates);
      this.setState({ allTemplates: templates }, () => {
        if (this.state.currentIS !== null) {
          this.updateTemplateFilter();
        }
      });
    }
  }

  updateIS() {
    let loc = window.location.pathname;
    let pathParts = loc.split('/');
    let isId = parseInt(pathParts[pathParts.length - 1], 10);
    LoadIntakeStrategy(isId);
    //LoadBarcodes();
    GetBPList();
    GetTemplateList();
  }

  handleNameSaveClick() {
    //UpdateName(this.state.currentIS.id, this.state.editName);
    let newIs = this.state.currentIS;
    newIs.isName = this.state.editName;
    this.setState({ currentIS: newIs });
    this.setState({ showEditName: false, isDirty: true });
  }

  ocrRadioChange(id) {
    let newIS = this.state.currentIS;
    newIS.ocrMethod = id;
    console.log(newIS);
    this.setState({ currentIS: newIS, isDirty: true });
  }

  onFastPassChange(e) {
    let newIS = this.state.currentIS;
    newIS.useFastPass = e.target.checked;
    this.setState({ currentIS: newIS, isDirty: true });
  }

  onReadBarcodeChange(e) {
    let newIS = this.state.currentIS;
    newIS.readBarcode = e.target.checked;
    newIS.enableBarcodeSplit = false;
    newIS.enablePreSplit = false;
    this.setState({ currentIS: newIS, isDirty: true });
  }

  onChangeDupeDetection(e) {
    let newIS = this.state.currentIS;
    newIS.dupeDetection = e.target.value;
    this.setState({ currentIS: newIS, isDirty: true });
  }

  onEnablePreSplitChange(e) {
    if (this.state.currentIS.readBarcode === true) {
      let newIS = this.state.currentIS;
      newIS.enablePreSplit = e.target.checked;
      this.setState({ currentIS: newIS });
    }
  }

  onEnableBarcodeSplitChange(e) {
    if (this.state.currentIS.readBarcode === true) {
      let newIS = this.state.currentIS;
      newIS.enableBarcodeSplit = e.target.checked;
      this.setState({ currentIS: newIS });
    }
  }

  handleNameCancelClick() {
    this.setState({ showEditName: false });
  }

  handleNameChange(event) {
    this.setState({ editName: event.target.value });
  }

  isNameChangeClick() {
    let currentName = this.state.currentIS.isName;
    this.setState({ showEditName: true, editName: currentName });
  }

  // openBCDialog() {
  //    let newEdits = [];
  //    this.state.barcodes.forEach((bc) => {
  //       let bcEdit: BarcodeEdit = {
  //          id: bc.barcodeId,
  //          formula: bc.formula,
  //          name: bc.name,
  //          isNew: false,
  //          isDirty: false,
  //          isDeleted: false,
  //       };
  //       newEdits.push(bcEdit);
  //    });
  //    this.setState({ editBarcodes: newEdits });
  //    this.setState({ showBCDialog: true });
  // }

  hideBCDialog() {
    this.setState({ showBCDialog: false });
  }

  openBCAdder() {
    this.setState({ selectedBarcode: '', showBCAdder: true });
  }

  hideBCAdder() {
    this.setState({ showBCAdder: false });
  }

  handleBCSelectionChange(e) {
    this.setState({ selectedBarcode: e.target.value });
  }

  handleTemplateSelection(e) {
    let updateIS = this.state.currentIS;
    updateIS.templateId = parseInt(e.target.value, 10);
    this.setState({ currentIS: updateIS, isDirty: true });
  }

  updateTemplateFilter() {
    console.log('tempalteFilter');
    if (this.state.currentIS !== undefined) {
      let filter = [];
      let bpId = this.state.currentIS.bpId;
      let ftp = this.state.legacyBPs.find((f) => f.bpId === bpId);
      if (ftp !== undefined) {
        this.state.allTemplates.forEach((t) => {
          if (t.formTypeId === ftp.formTypeId) {
            let template = {
              templateId: t.templateId,
              name: t.name,
              formTypeId: t.formTypeId,
              isActive: true,
              isAutoOcr: t.isAutoOcr,
            };
            filter.push(template);
          }
        });
        console.log(filter);
        this.setState({ filteredTemplates: filter });
      }
    }
  }

  validateTemplates() {
    if (this.state.currentIS.templateStrategy === 1) {
      // needs to have a template a
      let bp = parseInt(this.state.selectedBPId.toString(), 10);

      if (bp === 0) {
        this.setState({
          templateError: 'Please select a business process',
        });
        return false;
      }

      if (
        this.state.currentIS.templateId === 0 ||
        this.state.currentIS.templateId === undefined
      ) {
        this.setState({
          templateError: 'Please select a template',
        });
        return false;
      }
    }
    this.setState({ templateError: '' });
    return true;
  }

  handleBPSelection(e) {
    this.setState({ selectedBPId: e.target.value });
    // update selectable templates
    let updateIS = this.state.currentIS;
    let filter = [];
    updateIS.bpId = parseInt(e.target.value, 10);
    let selection = this.state.bpSelections.find(
      (b) => b.bpId === Number(e.target.value)
    );
    if (selection !== undefined && selection.typeId === 0) {
      // legacy ftps
      let ftp = this.state.legacyBPs.find((f) => f.bpId === selection.bpId);
      this.state.allTemplates.forEach((t) => {
        if (t.formTypeId === ftp.formTypeId) {
          let template = {
            templateId: t.templateId,
            name: t.name,
            formTypeId: t.formTypeId,
            isActive: true,
            isAutoOcr: t.isAutoOcr,
          };
          filter.push(template);
        }
      });
    }
    this.setState({
      filteredTemplates: filter,
      selectedTemplateId: 0,
      currentIS: updateIS,
      isDirty: true,
    });
  }

  handleAddBarcode() {
    let maxId = 1;
    let newIS = this.state.currentIS;
    if (newIS.barCodeSplits === null || newIS.barCodeSplits === undefined) {
      newIS.barCodeSplits = [];
    }
    newIS.barCodeSplits.forEach((bc) => {
      if (bc.barCodeId >= maxId) {
        maxId = bc.barCodeId + 1;
      }
    });
    newIS.barCodeSplits.push({
      barCodeId: maxId,
      barCodeFormula: this.state.newBarcodeFormula,
    });
    this.setState({ newBarcodeFormula: '', currentIS: newIS });
    // if (this.state.selectedBarcode === '') {
    // } else {
    //    if (
    //       this.state.currentIS.barCodeSplits.find(
    //          (b) => b.barCodeId.toString() === this.state.selectedBarcode
    //       )
    //    ) {
    //       this.setState({ showBCAdder: false });
    //    } else {
    //       let bc = this.state.barcodes.find(
    //          (f) => f.barcodeId.toString() === this.state.selectedBarcode
    //       );
    //       let newIS = this.state.currentIS;
    //       //newIS.barCodeSplits.push(bc.barcodeId);
    //       let newBCSplit = new BarcodeSplit();
    //       newBCSplit.barCodeId = bc.barcodeId;
    //       newBCSplit.barCodeName = bc.name;
    //       newBCSplit.barCodeFormula = bc.formula;
    //       newIS.barCodeSplits.push(newBCSplit);
    //       this.setState({ currentIS: newIS });
    //       this.setState({ showBCAdder: false, isDirty: true });
    //    }
    // }
  }

  handleDeleteBarcode(bcId) {
    let newIS = this.state.currentIS;
    let bcToRemove = newIS.barCodeSplits.find((f) => f.barCodeId === bcId);
    let bcIndex = newIS.barCodeSplits.indexOf(bcToRemove);
    newIS.barCodeSplits.splice(bcIndex, 1);
    this.setState({ currentIS: newIS });
  }

  // Barcode editor
  onEditBarcode(e) {
    let editBcs = this.state.editBarcodes;
    let parts = e.target.id.split('_');
    let idx = parseInt(parts[1], 10);
    let bcToEdit = editBcs.find((bc) => bc.id === idx);
    if (parts[0] === 'bcInfoName') {
      bcToEdit.name = e.target.value;
      bcToEdit.isDirty = true;
    }
    if (parts[0] === 'bcInfoFormula') {
      bcToEdit.formula = e.target.value;
      bcToEdit.isDirty = true;
    }
    this.setState({ editBarcodes: editBcs });
  }

  onDeleteBCRow(rowId) {
    let editBcs = this.state.editBarcodes;
    let bcToDelete = editBcs.find((bc) => bc.id === rowId);
    if (bcToDelete.isNew) {
      let idx = editBcs.indexOf(bcToDelete);
      editBcs.splice(idx, 1);
    } else {
      bcToDelete.isDeleted = true;
    }
    this.setState({ editBarcodes: editBcs });
  }

  onAddTarget() {
    let nextPriority = 1;
    // get max priority
    this.state.currentIS.intakeTargets.forEach((t) => {
      if (t.priority >= nextPriority) {
        nextPriority = t.priority + 1;
      }
    });
    let newTarget = new IntakeStrategyTarget();
    newTarget.priority = nextPriority;
    newTarget.isEditing = true;
    newTarget.bpId = 0;
    newTarget.defaultBpId = 0;
    newTarget.defaultBpName = '';
    newTarget.bpName = '';
    newTarget.templates = [];
    let updatedIS = this.state.currentIS;
    updatedIS.intakeTargets.push(newTarget);
    this.setState({ currentIS: updatedIS, isDirty: false });
  }

  handleSelectTarget(e, t) {
    let c = this.state.currentIS;
    let target = c.intakeTargets.find((tg) => tg.priority === t.priority);
    //let c = this.state.currentIS;
    t.bpId = parseInt(e.target.value, 10);
    let bp = this.state.bpSelections.find(
      (x) => x.bpId === parseInt(e.target.value, 10)
    );

    if (bp !== undefined) {
      target.defaultBpId = bp.bpId;
      target.defaultBpName = bp.display;
      target.formTypeId = bp.ftId;
      target.workflowId = bp.wfId;
      target.template = undefined;
      target.defaultTemplateId = 0;
      target.defaultTemplateName = 'None';
      target.templates = [];

      this.state.allTemplates.forEach((t) => {
        if (t.formTypeId === bp.ftId) {
          let template = {
            templateId: t.templateId,
            name: t.name,
            formTypeId: t.formTypeId,
            isActive: true,
            isAutoOcr: t.isAutoOCR,
          };
          target.templates.push(template);
        }
      });

      this.setState({ currentIS: c });
    }
  }

  onTargetOk(t) {
    let c = this.state.currentIS;
    let target = c.intakeTargets.find((tg) => tg.priority === t.priority);
    target.isEditing = false;
    this.setState({ currentIS: c, isDirty: true });
  }

  onTargetEdit(t) {
    let c = this.state.currentIS;
    let target = c.intakeTargets.find((tg) => tg.priority === t.priority);
    target.isEditing = true;
    if (target.priority === 1) {
      target.templates = [];

      this.state.allTemplates.forEach((t) => {
        if (t.formTypeId === target.formTypeId) {
          let template = {
            templateId: t.templateId,
            name: t.name,
            formTypeId: t.formTypeId,
            isActive: true,
            isAutoOcr: t.isAutoOCR,
          };
          target.templates.push(template);
        }
      });
    }
    // set isDirty to false becuase users cannot save until target changes are confirmed
    this.setState({ currentIS: c, isDirty: false });
  }
  onTargetDel(t) {
    let c = this.state.currentIS;
    let target = c.intakeTargets.find((tg) => tg.priority === t.priority);
    let p = target.priority;
    let idx = c.intakeTargets.indexOf(target);
    c.intakeTargets.splice(idx, 1);
    // renumber priority
    c.intakeTargets.forEach((tg) => {
      if (tg.priority >= p) {
        tg.priority--;
      }
    });
    this.setState({ currentIS: c, isDirty: true });
  }

  onRearrangeClick() {
    if (this.state.isRearranging) {
      this.setState({ isRearranging: false });
    } else {
      this.setState({ isRearranging: true });
    }
  }

  onMoveTargetDown(t) {
    let c = this.state.currentIS;
    let target = c.intakeTargets.find((tg) => tg.priority === t.priority);
    let nextTarget = c.intakeTargets.find(
      (tg) => tg.priority === t.priority + 1
    );
    if (target !== undefined && nextTarget !== undefined) {
      target.priority = target.priority + 1;
      nextTarget.priority = nextTarget.priority - 1;
    }
    c.intakeTargets.sort((a, b) => a.priority - b.priority);
    this.setState({ currentIS: c, isDirty: true });
  }

  onMoveTargetUp(t) {
    let c = this.state.currentIS;
    let target = c.intakeTargets.find((tg) => tg.priority === t.priority);
    let preTarget = c.intakeTargets.find(
      (tg) => tg.priority === t.priority - 1
    );
    if (target !== undefined && preTarget !== undefined) {
      target.priority = target.priority - 1;
      preTarget.priority = preTarget.priority + 1;
    }
    c.intakeTargets.sort((a, b) => a.priority - b.priority);
    this.setState({ currentIS: c, isDirty: true });
  }

  onAddFormula() {
    // find next id
    let maxId = 0;
    this.state.editBarcodes.forEach((bc) => {
      if (bc.id > maxId) {
        maxId = bc.id;
      }
    });
    maxId++;
    let newEdits = this.state.editBarcodes;
    let newEdit = {
      id: maxId,
      formula: '',
      name: '',
      isNew: true,
      isDirty: false,
      isDeleted: false,
    };
    newEdits.push(newEdit);
    this.setState({ editBarcodes: newEdits });
  }

  handleBarcodeEdit(e) {
    let formulaText = e.target.value;
    this.setState({ newBarcodeFormula: formulaText });
  }

  onCancelBarcodes() {
    this.setState({ showBCDialog: false });
  }

  onTemplateStrategyChange(templateStrategy) {
    let intake = this.state.currentIS;
    intake.templateStrategy = templateStrategy;
    if (templateStrategy === 1 || templateStrategy === 0) {
      //intake.intakeTargets = [];
    }

    // update filtered templates
    this.updateTemplateFilter();

    this.setState({ currentIS: intake, isDirty: true });
  }

  onPreFlattenChange(e) {
    let newIS = this.state.currentIS;
    newIS.flattenFields = e.target.checked;
    this.setState({ currentIS: newIS, isDirty: true });
  }

  onOCRQualityChange(e) {
    let currentIS = this.state.currentIS;
    currentIS.ocrQuality = parseInt(e.target.value, 0);
    this.setState({ currentIS: currentIS, isDirty: true });
  }

  onSaveClick(e) {
     let intakeStrat = this.state.currentIS;

    intakeStrat.intakeTargets.forEach(function(tg){
      tg.isEditing = false
     });

    if (this.validateTemplates()) {
      console.log('save');
      UpdateIntakeStrategy(intakeStrat);
    }
  }

  startDelete() {
    this.setState({ deleteStatus: 1 });
  }

  deleteIntakeStrategy() {
    console.log('delete strategy');
    DeleteIntakeStrategy(this.state.currentIS);
    this.setState({ deleteStatus: 0 });
    //LoadIntakeStrategies();
    //routeTo('administration/IntakeStrategies');
    customHistory.push('/Admin/IntakeStrategies');
  }

  cancelDelete() {
    this.setState({ deleteStatus: 0 });
  }

  selectTargetTemplate(e, target) {
    let currentIS = this.state.currentIS;

    let templates = target.templates;
    console.log(currentIS);
    console.log(e.target.value);
    console.log(templates);
    let update = currentIS.intakeTargets.find(
      (it) => it.priority === target.priority
    );
    let selectedTemplate = templates.find(
      (t) => t.templateId === parseInt(e.target.value, 10)
    );
    console.log(selectedTemplate);
    update.defaultTemplateId = selectedTemplate.templateId;
    update.defaultTemplateName = selectedTemplate.name;
    update.template = selectedTemplate;

    this.setState({ currentIS: currentIS });
  }

  render() {
    return (
      <div className='container-fluid' style={{ height: '100%' }}>
        <div className='row flex-nowrap' style={{ height: '100%' }}>
          <IntakeStrategySidebar activePage='is' />
          {this.state.currentIS !== undefined && (
            <div
              className='flex-fill'
              style={{ backgroundColor: '#efefef', padding: '15px' }}
            >
              <div
                className='card'
                style={{
                  marginTop: '5px',
                }}
              >
                <div className='card-header'>Intake Strategy</div>
                <div className='card-body'>
                  {this.state.showEditName !== true && (
                    <h3 style={{ fontSize: '14pt' }}>
                      {this.state.currentIS !== undefined
                        ? this.state.currentIS.isName
                        : ''}
                      {'    '}
                      <FontAwesomeIcon
                        icon={solid('pen')}
                        className='fas fa-pen ms-1'
                        onClick={this.isNameChangeClick}
                        style={{
                          color: '#2D359B',
                          cursor: 'pointer',
                        }}
                      />
                    </h3>
                  )}
                  {this.state.showEditName && (
                    <div className='row g-3 align-items-center'>
                      <div className='col-auto'>
                        <label htmlFor='isNameTxt' className='col-form-label'>
                          Intake Strategy Name:
                        </label>
                      </div>
                      <div className='col-auto'>
                        <input
                          type='text'
                          id='isNameTxt'
                          className='form-control'
                          value={this.state.editName}
                          onChange={this.handleNameChange}
                        />
                      </div>
                      <div className='col-auto'>
                        <button
                          className='btn btn-primary'
                          onClick={this.handleNameSaveClick}
                        >
                          OK
                        </button>
                      </div>
                      <div className='col-auto'>
                        <button
                          className='btn btn-secondary'
                          onClick={this.handleNameCancelClick}
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              </div>

              {/* middle section, OCR and Barcode */}
              <div>
                <div className='row'>
                  <div className='col'>
                    <div
                      className='card'
                      style={{
                        marginTop: '20px',
                        marginRight: '10px',
                      }}
                    >
                      <div className='card-header'>OCR</div>
                      <div className='card-body'>
                        <div className='form-check'>
                          <input
                            className='form-check-input'
                            type='radio'
                            name='flexRadioDefault'
                            id='ocrRadioNoOCR'
                            onChange={(id) => this.ocrRadioChange(1)}
                            checked={this.state.currentIS.ocrMethod === 1}
                          />
                          <label
                            className='form-check-label'
                            htmlFor='flexRadioDefault2'
                          >
                            No OCR
                          </label>
                        </div>
                        <div className='form-check'>
                          <input
                            className='form-check-input'
                            type='radio'
                            name='flexRadioDefault'
                            id='ocrRadioTextOnly'
                            onChange={() => this.ocrRadioChange(2)}
                            checked={this.state.currentIS.ocrMethod === 2}
                          />
                          <label
                            className='form-check-label'
                            htmlFor='flexRadioDefault2'
                          >
                            Text Only
                          </label>
                        </div>
                        {this.state.doesOcrServiceExist && (
                          <span>
                            <div className='form-check'>
                                <input
                                  className='form-check-input'
                                  type='radio'
                                  name='flexRadioDefault'
                                  id='ocrRadioSmartOCR'
                                  onChange={() => this.ocrRadioChange(4)}
                                  checked={this.state.currentIS.ocrMethod === 3 || this.state.currentIS.ocrMethod === 4}
                                />
                                <label
                                  className='form-check-label'
                                  htmlFor='flexRadioDefault2'
                                >
                                  OCR 1.0
                                </label>
                              </div>
                              {(this.state.currentIS.ocrMethod === 3 || this.state.currentIS.ocrMethod === 4) && (
                                <span className=''>
                                    <div className='form-check form-switch ms-3'>
                                      <input
                                        className='form-check-input'
                                        type='checkbox'
                                        id='fullOcr'
                                        onChange={() => this.ocrRadioChange(4)}
                                        checked={this.state.currentIS.ocrMethod === 4}
                                      />
                                      <label
                                        className='form-check-label'
                                        htmlFor='preSplitChange'
                                      >
                                        Full
                                      </label>
                                    </div>
                                    <div className='form-check form-switch ms-3'>
                                      <input
                                        className='form-check-input'
                                        type='checkbox'
                                        id='barcodeSplit'
                                        onChange={() => this.ocrRadioChange(3)}
                                        checked={this.state.currentIS.ocrMethod === 3}
                                      />
                                      <label
                                        className='form-check-label'
                                        htmlFor='barcodeSplit'
                                      >
                                        Smart
                                      </label>
                                    </div>
                                </span>
                              )}
                          </span>
                        )}
                        {this.state.doesIronServiceExist && (
                          <div className='form-check'>
                            <input
                              className='form-check-input'
                              type='radio'
                              name='flexRadioDefault'
                              id='ocrRadioIronOCR'
                              onChange={() => this.ocrRadioChange(5)}
                              checked={this.state.currentIS.ocrMethod === 5}
                            />
                              <label
                                className='form-check-label'
                                htmlFor='flexRadioDefault2'
                              >
                              OCR 2.0
                            </label>
                          </div>
                        )}
                        <div
                          className='form-check form-switch'
                          style={{ marginTop: '10px' }}
                        >
                          <input
                            className='form-check-input'
                            type='checkbox'
                            id='flexSwitchCheckChecked'
                            onChange={this.onFastPassChange}
                            checked={this.state.currentIS.useFastPass}
                          />
                          <label
                            className='form-check-label'
                            htmlFor='flexSwitchCheckChecked'
                          >
                            Use Fast Pass
                          </label>
                        </div>
                        <div style={{ marginTop: '8px' }}>
                          <div className='form-check form-switch'>
                            <input
                              className='form-check-input'
                              type='checkbox'
                              id='preFlattenFlexSwitchCheckChecked'
                              onChange={this.onPreFlattenChange}
                              checked={this.state.currentIS.flattenFields}
                            />
                            <label
                              className='form-check-label'
                              htmlFor='preFlattenFlexSwitchCheckChecked'
                            >
                              Pre flatten fillable fields
                            </label>
                          </div>
                        </div>
                        <div className='mb-3' style={{ marginTop: '8px' }}>
                          <label
                            className='form-label'
                            htmlFor='flexSwitchCheckChecked'
                          >
                            OCR Quality:
                          </label>
                          <select
                            className='form-control'
                            aria-label='Select OCR Quality'
                            value={this.state.currentIS.ocrQuality}
                            onChange={this.onOCRQualityChange}
                          >
                            <option value='0'>Select OCR Quality</option>
                            <option value='1'>Standard</option>
                            <option value='2'>High</option>
                            <option value='3'>Highest</option>
                            <option value='4'>System Default</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='col'>
                    <div
                      className='card'
                      style={{
                        marginTop: '20px',
                        marginRight: '10px',
                      }}
                    >
                      <div className='card-header'>
                        Barcodes / Splits / Duplicates
                      </div>
                      <div className='card-body'>
                        <div className='form-check form-switch'>
                          <input
                            className='form-check-input'
                            type='checkbox'
                            id='readBarcodeChange'
                            onChange={this.onReadBarcodeChange}
                            checked={this.state.currentIS.readBarcode}
                          />
                          <label
                            className='form-check-label'
                            htmlFor='readBarcodeChange'
                          >
                            Read Barcodes
                          </label>
                        </div>
                        <div className='form-check form-switch ms-3'>
                          <input
                            className='form-check-input'
                            type='checkbox'
                            id='preSplitChange'
                            onChange={this.onEnablePreSplitChange}
                            checked={this.state.currentIS.enablePreSplit}
                            disabled={this.state.currentIS.readBarcode === false}
                          />
                          <label
                            className='form-check-label'
                            htmlFor='preSplitChange'
                          >
                            Enable Barcode Pre-Splits
                          </label>
                        </div>
                        <div className='form-check form-switch ms-3'>
                          <input
                            className='form-check-input'
                            type='checkbox'
                            id='barcodeSplit'
                            onChange={this.onEnableBarcodeSplitChange}
                            checked={this.state.currentIS.enableBarcodeSplit}
                            disabled={this.state.currentIS.readBarcode === false}
                          />
                          <label
                            className='form-check-label'
                            htmlFor='barcodeSplit'
                          >
                            Enable Barcode Splits
                          </label>
                        </div>
                        {this.state.currentIS.enableBarcodeSplit && (
                          <div
                            className='card bcCard'
                            style={{ marginTop: '10px' }}
                          >
                            <div className='card-header'>
                              Splitting barcodes
                            </div>

                            {this.state.currentIS.enableBarcodeSplit && (
                              <div className='card-body'>
                                <ul className='list-group'>
                                  {this.state.currentIS.barCodeSplits.map(
                                    (bc) => (
                                      <li
                                        className='list-group-item d-flex justify-content-between align-items-center'
                                        key={bc.barCodeId}
                                      >
                                        <span>{bc.barCodeFormula}</span>
                                        <span
                                          style={{
                                            color: 'red',
                                            cursor: 'pointer',
                                          }}
                                          onClick={() =>
                                            this.handleDeleteBarcode(
                                              bc.barCodeId
                                            )
                                          }
                                        >
                                          x
                                        </span>
                                      </li>
                                    )
                                  )}
                                </ul>
                                <div className='row'>
                                  <div className='col-9'>
                                    <input
                                      type='text'
                                      className='form-control'
                                      placeholder='New formula'
                                      value={this.state.newBarcodeFormula}
                                      onChange={this.handleBarcodeEdit}
                                    />
                                  </div>
                                  <div className='col-3'>
                                    <button
                                      className='btn btn-primary btn-sm'
                                      onClick={this.handleAddBarcode}
                                    >
                                      Add Barcode
                                    </button>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        )}

                        <div style={{ marginTop: '8px' }}>
                          <label className='form-label'>Dupe Detection:</label>
                        </div>
                        <div className='form-check form-check-inline'>
                          <input
                            className='form-check-input'
                            type='radio'
                            id='dupe1'
                            value='reject'
                            onChange={this.onChangeDupeDetection}
                            checked={
                              this.state.currentIS.dupeDetection === 'reject'
                            }
                          />
                          <label className='form-check-label' htmlFor='dupe1'>
                            Reject
                          </label>
                        </div>
                        <div className='form-check form-check-inline'>
                          <input
                            className='form-check-input'
                            type='radio'
                            id='dupe2'
                            value='allow'
                            onChange={this.onChangeDupeDetection}
                            checked={
                              this.state.currentIS.dupeDetection === 'allow'
                            }
                          />
                          <label className='form-check-label' htmlFor='dupe2'>
                            Allow
                          </label>
                        </div>
                        <div className='form-check form-check-inline'>
                          <input
                            className='form-check-input'
                            type='radio'
                            id='dupe3'
                            value='system'
                            onChange={this.onChangeDupeDetection}
                            checked={
                              this.state.currentIS.dupeDetection === 'system'
                            }
                          />
                          <label className='form-check-label' htmlFor='dupe3'>
                            System Default
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className='row'>
                  <div className='col'>
                    <div
                      className='card'
                      style={{
                        marginTop: '20px',
                        marginRight: '10px',
                      }}
                    >
                      <div className='card-header'>
                        Templates / Form recognition
                      </div>
                      <div className='card-header'>
                        <ul className='nav nav-pills card-header-pills'>
                          <li className='nav-item'>
                            <button
                              className={`nav-link ${
                                this.state.currentIS.templateStrategy === 0
                                  ? 'active'
                                  : ''
                              }`}
                              onClick={() => this.onTemplateStrategyChange(0)}
                            >
                              No Template
                            </button>
                          </li>
                          <li className='nav-item'>
                            <button
                              className={`nav-link ${
                                this.state.currentIS.templateStrategy === 1
                                  ? 'active'
                                  : ''
                              }`}
                              onClick={() => this.onTemplateStrategyChange(1)}
                            >
                              One Template
                            </button>
                          </li>
                          <li className='nav-item'>
                            <button
                              className={`nav-link ${
                                this.state.currentIS.templateStrategy === 2
                                  ? 'active'
                                  : ''
                              }`}
                              onClick={() => this.onTemplateStrategyChange(2)}
                            >
                              Smart Template Matching
                            </button>
                          </li>
                        </ul>
                      </div>
                      <div className='card-body'>
                        {this.state.templateError !== '' && (
                          <div className='alert alert-danger'>
                            {this.state.templateError}
                          </div>
                        )}
                        {this.state.currentIS.templateStrategy === 0 && (
                          <div>
                            <h5 className='card-title'>Business Process</h5>

                            <select
                              className='form-select form-select mb-3'
                              aria-label='.form-select example'
                              value={this.state.selectedBPId}
                              onChange={this.handleBPSelection}
                            >
                              {this.state.bpSelections.map((bp) => (
                                <option key={bp.bpId} value={bp.bpId}>
                                  {bp.display}
                                </option>
                              ))}
                            </select>
                            <p className='card-text'>
                              No data will be pre-populated.
                            </p>
                          </div>
                        )}
                        {this.state.currentIS.templateStrategy === 1 && (
                          <div>
                            <h5 className='card-title'>Business Process</h5>

                            <select
                              className='form-select form-select mb-3'
                              aria-label='.form-select example'
                              value={this.state.selectedBPId}
                              onChange={this.handleBPSelection}
                            >
                              {this.state.bpSelections.map((bp) => (
                                <option key={bp.bpId} value={bp.bpId}>
                                  {bp.display}
                                </option>
                              ))}
                            </select>
                            <h5 className='card-title'>Template</h5>

                            <select
                              className='form-select form-select mb-3'
                              aria-label='.form-select example'
                              value={this.state.currentIS.templateId}
                              onChange={this.handleTemplateSelection}
                            >
                              <option value={0}>Select a Template</option>
                              {this.state.filteredTemplates.map((tp) => (
                                <option
                                  key={tp.templateId}
                                  value={tp.templateId}
                                >
                                  {tp.name}
                                </option>
                              ))}
                            </select>
                            <p className='card-text'>
                              This template will be used every time.
                            </p>
                          </div>
                        )}
                        {this.state.currentIS.templateStrategy === 2 && (
                          <div>
                            <table className='table'>
                              <thead>
                                <tr>
                                  <th scope='col'>#</th>
                                  <th scope='col'>
                                    <FontAwesomeIcon icon={solid('building')} />{' '}
                                    Business Process
                                  </th>
                                  <th scope='col'>Default Template</th>
                                  <th scope='col'></th>
                                </tr>
                              </thead>
                              <tbody>
                                {this.state.currentIS.intakeTargets.map(
                                  (target) => (
                                    <tr key={target.priority}>
                                      <th scope='row'>{target.priority}</th>
                                      {target.isEditing && (
                                        <>
                                          <td colSpan={1}>
                                            <select
                                              className='form-select form-select'
                                              aria-label='.form-select example'
                                              value={target.defaultBpId}
                                              onChange={(e) =>
                                                this.handleSelectTarget(
                                                  e,
                                                  target
                                                )
                                              }
                                            >
                                              {this.state.bpSelections.map(
                                                (bp) => (
                                                  <option
                                                    key={bp.bpId}
                                                    value={bp.bpId}
                                                  >
                                                    {bp.display}
                                                  </option>
                                                )
                                              )}
                                            </select>
                                          </td>
                                          {target.priority === 1 && (
                                            <td>
                                              <select
                                                className='form-select'
                                                value={target.defaultTemplateId}
                                                onChange={(e) =>
                                                  this.selectTargetTemplate(
                                                    e,
                                                    target
                                                  )
                                                }
                                              >
                                                <option value='None'>
                                                  None
                                                </option>

                                                {target.templates !== undefined && target.templates.map(
                                                  (template) => (
                                                    <option
                                                      value={
                                                        template.templateId
                                                      }
                                                      key={template.templateId}
                                                    >
                                                      {template.name}
                                                    </option>
                                                  )
                                                )}
                                              </select>
                                            </td>
                                          )}
                                          {target.priority !== 1 && <td></td>}
                                        </>
                                      )}
                                      {!target.isEditing && (
                                        <>
                                          <td>{target.defaultBpName}</td>
                                          {target.priority === 1 &&
                                            target.defaultTemplateId !== 0 && (
                                              <td>
                                                {target.defaultTemplateName}
                                              </td>
                                            )}
                                          {target.priority === 1 &&
                                            target.defaultTemplateId === 0 && (
                                              <td>No Template</td>
                                            )}
                                          {target.priority !== 1 && <td></td>}
                                          <td>
                                            <button
                                              className='btn btn-danger btn-sm'
                                              style={{
                                                marginRight: '3px',
                                              }}
                                              onClick={() =>
                                                this.onTargetDel(target)
                                              }
                                            >
                                              <FontAwesomeIcon
                                                icon={solid('times')}
                                              />
                                            </button>
                                            <button
                                              className='btn btn-primary btn-sm'
                                              onClick={() =>
                                                this.onTargetEdit(target)
                                              }
                                            >
                                              <FontAwesomeIcon
                                                icon={solid('pencil-alt')}
                                              />
                                            </button>
                                            {this.state.isRearranging && (
                                              <button
                                                className='btn btn-secondary round-button btn-sm ms-1'
                                                onClick={() =>
                                                  this.onMoveTargetUp(target)
                                                }
                                              >
                                                <FontAwesomeIcon
                                                  icon={solid('arrow-up')}
                                                />
                                              </button>
                                            )}
                                            {this.state.isRearranging && (
                                              <button
                                                className='btn btn-secondary round-button btn-sm ms-1'
                                                onClick={() =>
                                                  this.onMoveTargetDown(target)
                                                }
                                              >
                                                <FontAwesomeIcon
                                                  icon={solid('arrow-down')}
                                                />
                                              </button>
                                            )}
                                          </td>
                                        </>
                                      )}
                                      {target.isEditing && (
                                        <td>
                                          <button
                                            className='btn btn-success btn-sm'
                                            onClick={() =>
                                              this.onTargetOk(target)
                                            }
                                          >
                                            OK
                                          </button>
                                        </td>
                                      )}
                                    </tr>
                                  )
                                )}
                              </tbody>
                            </table>
                            <button
                              className='btn btn-primary'
                              onClick={this.onAddTarget}
                            >
                              Add Business Process
                            </button>
                            <button
                              className='btn btn-primary'
                              style={{ marginLeft: '4px' }}
                              onClick={this.onRearrangeClick}
                            >
                              Rearrange
                            </button>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                {this.state.isDirty && (
                  <button
                    style={{ marginTop: '8px', padding: '12px' }}
                    className='btn btn-success btn-lg'
                    onClick={this.onSaveClick}
                  >
                    <i
                      className='fas fa-save'
                      style={{ marginRight: '8px' }}
                    ></i>
                    {'   '}
                    Save changes
                  </button>
                )}
                <span className='float-right'>
                  <button
                    style={{
                      marginTop: '8px',
                      padding: '12px',
                      marginLeft: '6px',
                    }}
                    className='btn btn-danger btn-lg float-end'
                    onClick={this.startDelete}
                  >
                    <i
                      className='fas fa-times'
                      style={{ marginRight: '8px' }}
                    ></i>
                    {'   '}
                    Delete Intake Strategy
                  </button>

                  {this.state.deleteStatus === 1 && (
                    <div
                      className='alert alert-danger float-end'
                      style={{ marginTop: '6px' }}
                      role='alert'
                    >
                      <p>
                        Are you sure you want to delete this intake stategy?
                      </p>

                      <button
                        className='btn btn-danger'
                        onClick={this.deleteIntakeStrategy}
                      >
                        Yes - Delete
                      </button>
                      <button
                        className='btn btn-secondary'
                        style={{ marginLeft: '6px' }}
                        onClick={this.cancelDelete}
                      >
                        Cancel
                      </button>
                    </div>
                  )}
                </span>
              </div>
            </div>
          )}
        </div>
        <Link
          id='intakeStrategyHomeLink'
          to='/Admin/IntakeStrategies'
          style={{ display: 'none' }}
        >
          HiddenLink
        </Link>
      </div>
    );
  }
}

export default IntakeStrategyView;
