import { EventEmitter } from 'events';
import Dispatcher from '../Dispatcher';
import actionTypes from '../actions/ActionTypes';

const CHANGE_EVENT = 'userChange';
let _docCount = 0;
let _docIds = [];
let _currentDocs = [];
let _currentFt = 0;
let _columns = [];
let _docId = 0;
let _lastChange = '';
let _adTree = undefined;

let _indexFields = [];
let _formTypes = [];
let _wfSteps = [];
let _bpName = '';
let _stepName = '';
let _offset = 0;
let _recentQuery = '';
let _order = '';
let _column = '';
//let _lastError = '';
let _lastSearch = undefined;
let _fullQuery = '';

class SearchStore extends EventEmitter {
  addChangeListener(callback) {
    this.on(CHANGE_EVENT, callback);
  }

  removeChangeListener(callback) {
    this.removeListener(CHANGE_EVENT, callback);
  }

  emitChange() {
    this.emit(CHANGE_EVENT);
  }

  getDocCount() {
    return _docCount;
  }

  getDocument() {
    return _docId;
  }

  getAdTree() {
    return _adTree;
  }

  getColumns() {
    return _columns;
  }

  getDocs() {
    return _currentDocs;
  }

  getIndexFields() {
    return _indexFields;
  }

  getFormTypes() {
    return _formTypes;
  }

  getDocList() {
    return _docIds;
  }

  getLastAction() {
    return _lastChange;
  }

  getUserWfSteps() {
    return _wfSteps;
  }

  getCurrentFormType() {
    return _currentFt;
  }

  getBPName() {
    return _bpName;
  }

  getStepName() {
    return _stepName;
  }

  getOffset() {
    return _offset;
  }

  getRecentQuery() {
    return _recentQuery;
  }

  getOrder() {
    return _order;
  }

  getColumn() {
    return _column;
  }

  getLastSearch() {
    return _lastSearch;
  }

  getFullQuery() {
    return _fullQuery;
  }

  removeDocFromList(docId) {
    let idx = _docIds.indexOf(docId);
    if(idx > -1) {
      _docIds.splice(idx, 1);
    }
  }
}

const store = new SearchStore();

Dispatcher.register((action) => {
  switch (action.actionType) {
    case actionTypes.SearchQuickSearch:
      _docCount = action.Result.documentCount;
      _docIds = action.Result.documentIds;
      _lastChange = action.actionType;
      _lastSearch = action.Result.searchText;
      if(action.Result.searchResult !== null) {
        _adTree = action.Result.searchResult.adTree;
      }
      store.emitChange();
      break;
    case actionTypes.SearchByDocId:
      _docId = action.Result.documentId;
      _lastChange = action.actionType;

      store.emitChange();
      break;
    case actionTypes.SearchByValues:
      _lastSearch = action.Result.searchText;
      _docCount = action.Result.documentCount;
      _docIds = action.Result.documentIds;
      _adTree = action.Result.adTree;
      _lastChange = action.actionType;
      store.emitChange();
      break;

    case actionTypes.SearchGetStep:
      _lastChange = actionTypes.SearchGetStep;
      _currentDocs = action.Result.docData;
      _docCount = action.Result.docCount;
      _currentFt = action.Result.formTypeId;
      _columns = action.Result.columns;
      _lastSearch = action.Result.request;
      _docIds = action.Result.docIds;
      _bpName = action.Result.bpName;
      _stepName = action.Result.step;
      _offset = action.Result.offset;
      _recentQuery = action.Result.fullQuery;
      _column = action.Result.column;
      _order = action.Result.order;
      store.emitChange();
      break;
    case actionTypes.SearchGetBp:
      _lastChange = actionTypes.SearchGetBp;
      _currentDocs = action.Result.docData;
      _docCount = action.Result.docCount;
      _currentFt = action.Result.formTypeId;
      _columns = action.Result.columns;
      _lastSearch = action.Result.request;
      _docIds = action.Result.docIds;
      _bpName = action.Result.bpName;
      _stepName = action.Result.step;
      _offset = action.Result.offset;
      _recentQuery = action.Result.fullQuery;
      _column = action.Result.column;
      _order = action.Result.order;
      store.emitChange();
      break;
    case actionTypes.GetIndexFields:
      _lastChange = actionTypes.GetIndexFields;
      _formTypes = action.Result.formTypes;
      _indexFields = action.Result.fields;
      store.emitChange();
      break;

    case actionTypes.SearchByFieldValue:
      _docCount = action.Result.documentCount;
      _docIds = action.Result.documentIds;
      _adTree = action.Result.adTree;
      _lastSearch = undefined;
      _lastChange = action.actionType;
      store.emitChange();
      break;

    case actionTypes.SearchByUserAudit:
      _docCount = action.Result.documentCount;
      _docIds = action.Result.documentIds;
      _adTree = action.Result.adTree;
      _lastSearch = undefined;
      _lastChange = action.actionType;
      store.emitChange();
      break;

    case actionTypes.SearchByUserQueue:
      _docCount = action.Result.documentCount;
      _docIds = action.Result.documentIds;
      _adTree = action.Result.adTree;
      _lastSearch = undefined;
      _lastChange = action.actionType;
      store.emitChange();
      break;

    case actionTypes.SearchByNotes:
      _docCount = action.Result.documentCount;
      _docIds = action.Result.documentIds;
      _adTree = action.Result.adTree;
      _lastSearch = undefined;
      _lastChange = action.actionType;
      store.emitChange();
      break;

    case actionTypes.SearchByProperties:
      _docCount = action.Result.documentCount;
      _docIds = action.Result.documentIds;
      _adTree = action.Result.adTree;
      _lastSearch = undefined;
      _lastChange = action.actionType;
      store.emitChange();
      break;

    case actionTypes.WorkflowGetUserSteps:
      _wfSteps = action.Result;
      _lastChange = action.actionType;
      store.emitChange();
      break;


    case actionTypes.ADGetStep:
      let loadingMore = localStorage.getItem('loadingMore');

      _lastChange = actionTypes.ADGetStep;
      _lastSearch = action.Result.request;
      _fullQuery = action.Result.fullQuery;

      if(loadingMore === 'true')
      {
        _docIds = _docIds.concat(action.Result.docIds);
      } else {
        _docIds = action.Result.docIds;
      }
      store.emitChange();
      break;

    case actionTypes.InboxGetBp:
      _lastChange = actionTypes.InboxGetBp;
      _docIds = action.Result.docIds;
      store.emitChange();
      break;

    case actionTypes.InboxGetStep:
      _lastChange = actionTypes.InboxGetStep;
      _docIds = action.Result.docIds;
      store.emitChange();
      break;



    default:
  }
});

export default store;
