import * as React from 'react';
import AutoOcrSidebar from './AutoOcrSidebar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import {
    GetAutoOcrDocumentTypeList,
    GetAutoOcrFieldList,
    GetAutoOcrFieldMapping,
    UpdateAutoOcrMapping,
    GetAutoOcrFieldMappingDocumentType
  } from '../../../../actions/AutoOcrActions';
  import {
    LoadFormType
  } from '../../../../actions/FormTypeActions';
import AutoOcrStore from '../../../../stores/AutoOcrStore';
import FormTypeStore from '../../../../stores/FormTypeStore';
import actionTypes from '../../../../actions/ActionTypes';

class AutoOcrMappingView extends React.Component {
  constructor(props) {
    super(props);
    // state
    this.state = {
      currentFormType: undefined,
      currentDocTypeId: 0,
      currentDocTypeName: '',
      isDirty: false,
      isSecOneSaving: false,
      errorOne: '',
      autoOcrFields: [],

      selectedDocumentTypeId: 0,
      documentTypes: [],
      autoOcrFieldMappingList: [],
    };
    this.updateMappings = this.updateMappings.bind(this);
    this.onFormTypeStoreUpdate = this.onFormTypeStoreUpdate.bind(this);

    this.save = this.save.bind(this);
    this.editSelectedDocumentType = this.editSelectedDocumentType.bind(this);
    this.onAutoOcrStoreUpdate = this.onAutoOcrStoreUpdate.bind(this);
    this.addNewFieldMapping = this.addNewFieldMapping.bind(this);
    this.editExistingFieldMapping = this.editExistingFieldMapping.bind(this);
    this.deleteFieldMapping = this.deleteFieldMapping.bind(this);
    this.removeAll = this.removeAll.bind(this);
  }

  componentDidMount() {
    FormTypeStore.addChangeListener(this.onFormTypeStoreUpdate);
    AutoOcrStore.addChangeListener(this.onAutoOcrStoreUpdate);
    this.updateMappings();
    GetAutoOcrDocumentTypeList();
  }

  componentWillUnmount() {
    FormTypeStore.removeChangeListener(this.onFormTypeStoreUpdate);
    AutoOcrStore.removeChangeListener(this.onAutoOcrStoreUpdate);
  }

  componentDidUpdate() {
    this.updateMappings();
  }

  updateMappings() {
    let loc = window.location.pathname;
    let pathParts = loc.split('/');
    let formTypeId = parseInt(pathParts[pathParts.length - 1], 10);
    if (
        formTypeId !== 0 &&
      (this.state.currentDocTypeId === undefined ||
        this.state.currentDocTypeId !== formTypeId)
    ) {
      this.setState({ currentDocTypeId: formTypeId });
      LoadFormType(formTypeId);
      GetAutoOcrFieldMapping(formTypeId);
      GetAutoOcrFieldMappingDocumentType(formTypeId);
    }
  }

  onFormTypeStoreUpdate() {
    let lastAction = FormTypeStore.getLastAction();

    if (lastAction.actionType === 'FTLoad') {
        let currentFT = FormTypeStore.getCurrentFT();
        this.setState({
          currentFormType: currentFT
        });
      }
  }

  onAutoOcrStoreUpdate() {
    let lastAction = AutoOcrStore.lastChange();

    if (lastAction === actionTypes.AutoOcrFieldGetList) {
      let fields = AutoOcrStore.getFields();
      this.setState({
        autoOcrFields: fields,
      });
    }

    if (lastAction === actionTypes.AutoOcrMappingList) {
        let mappings = AutoOcrStore.getMappings();
        this.setState({
            autoOcrFieldMappingList: mappings,
        });
    }

    if (lastAction === actionTypes.AutoOcrMappingDocumentType) {
        let currentDocType = AutoOcrStore.getCurrentDocumentTypes();
        this.setState({
            selectedDocumentTypeId: currentDocType.id,
        });
        GetAutoOcrFieldList(currentDocType.id);
    }


    if (lastAction === actionTypes.AutoOcrDocumentTypeGetList) {
        let docTypes = AutoOcrStore.getDocumentTypes();
        this.setState({
          documentTypes: docTypes,
        });
    }
  }


  save() {
    this.setState({isDirty: false});
    UpdateAutoOcrMapping(this.state.autoOcrFieldMappingList, this.state.currentFormType.formTypeId);
  }

  removeAll() {
    this.setState({selectedDocumentTypeId: 0, autoOcrFieldMappingList: [], isDirty: true});
    GetAutoOcrFieldList(0);
  }

  addNewFieldMapping(value, field) {
    var fieldMapping = {
        id: 0,
        formTypeId: this.state.currentFormType.formTypeId,
        ocrFieldId: parseInt(value),
        documentFieldId: field.fieldId
    };

    var list = this.state.autoOcrFieldMappingList;

    list.push(fieldMapping);

    this.setState({autoOcrFieldMappingList: list, isDirty: true});
  }

  editExistingFieldMapping(value, field) {
    var indexOfObject = this.state.autoOcrFieldMappingList.findIndex(object => {
        return object.documentFieldId === field.fieldId;
      });

    // we want to delete previous iteration
    var list = this.state.autoOcrFieldMappingList;
    list[indexOfObject].ocrFieldId = parseInt(value);

    this.setState({autoOcrFieldMappingList: list, isDirty: true});
  }


  editSelectedDocumentType(event) {
    this.setState({selectedDocumentTypeId: event.target.value, autoOcrFieldMappingList: [], isDirty: true});
    GetAutoOcrFieldList(event.target.value);
  }
  
  deleteFieldMapping(field) {
    var list = this.state.autoOcrFieldMappingList.filter(x => x.documentFieldId !== field.fieldId)
    this.setState({autoOcrFieldMappingList: list, isDirty: true});
  }

  render() {
    return (
      <div className='container-fluid' style={{ height: '100%' }}>
        <div className='row flex-nowrap' style={{ height: '100%' }}>
          <AutoOcrSidebar />
          

            <div
              className='flex-fill'
              style={{ backgroundColor: '#efefef', overflow: 'scroll' }}
            >
                {true && (
                    <div
                    className='card'
                    style={{
                      marginTop: '20px',
                      marginLeft: '30px',
                      marginRight: '30px',
                    }}
                  >
    
                    <div className='card-header' key={'header'}>
                      <div className='mt-3 fw-bold fs-5'>
                        <FontAwesomeIcon icon={solid('wand-magic')} />
                        {'  '}

                        {this.state.currentFormType !== undefined && (
                            <span style={{ fontSize: '14pt' }}>
                                {this.state.currentFormType.name}
                            </span>
                        )}
    
                        <div className='mb-3' style={{ float: 'right' }}>
                            <div>
                                {this.state.isDirty && (
                                    <button className='btn btn-primary'
                                    onClick={this.save}>
                                    Save
                                    </button>
                                )}
                                <button
                                className='btn btn-danger'
                                style={{ marginLeft: '6px' }}
                                onClick={this.removeAll}
                                >
                                Remove all
                                </button>
                            </div>
                            {this.state.isSecOneSaving && (
                            <i className='fas fa-spinner fa-spin fa-2x' />
                            )}
        
                            {this.state.errorOne !== '' && (
                            <div
                                className='alert alert-danger'
                                style={{ marginTop: '6px' }}
                                role='alert'
                            >
                                {this.state.errorOne}
                            </div>
                            )}
                        </div>
                      </div>
    
    
                    </div>
                
                    {true && (
                        <>
                            <div className='card-body'>
                                <div className=''>


                                <select
                                    className='form-select'
                                    value={this.state.selectedDocumentTypeId}
                                    onChange={this.editSelectedDocumentType}
                                    >
                                    <option value='0'></option>
                                    {this.state.documentTypes.map((docType) => (
                                        <option
                                            value={docType.id}
                                            key={docType.id}
                                            >
                                            {docType.name}
                                        </option>
                                    ))}
                                </select>


                                <div className='row pt-3 fw-bold fs-6 text-center'>
                                    <div className='col-3'>
                                        TrinDocs Header Field
                                    </div>
                                    <div className='col-3 fw-bold fs-6 text-center'>
                                        Auto Ocr Field
                                    </div>
                                </div>
                                {this.state.currentFormType !== undefined && (
                                    this.state.currentFormType.fieldSets.filter(a => a.name === 'Header')[0].fields.map((field) => (
                                        <div className='row' key={`field-${field.fieldId}`}>
                                            <div className='col-3 pt-2'>
                                               <span value={field.name}
                                                   key={field.fieldId}
                                                   className=''
                                                   >
                                                    {field.name}
                                               </span>
                                           </div>
                                           <>
                                            {this.state.autoOcrFieldMappingList.filter(x => x.documentFieldId === field.fieldId).length > 0 && (
                                                <>
                                                <div className='col-3 pt-2'>
                                                    <select
                                                        className='form-select'
                                                        value={this.state.autoOcrFieldMappingList.filter(x => x.documentFieldId === field.fieldId)[0].ocrFieldId}
                                                        onChange={event => this.editExistingFieldMapping(event.target.value, field)}
                                                        >
                                                        <option value='0'></option>
                                                        {this.state.autoOcrFields.map((field) => (
                                                            <option
                                                                value={field.id}
                                                                key={'mapping-'+field.id}
                                                                >
                                                                {field.name}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>
                                                <div className='col-2 mt-2'>
                                                {
                                                    field.fieldId > -1 &&
                                                    <span>
                                                        <button
                                                            className='btn btn-danger ms-2'
                                                            onClick={ () => {this.deleteFieldMapping(field) }}
                                                            >
                                                            Delete
                                                        </button>
                                                    </span>
                                                }
                                            </div> 
                                            </>
                                            )}
                                            {this.state.autoOcrFieldMappingList.filter(x => x.documentFieldId === field.fieldId).length <= 0 && (
                                                <div className='col-3 pt-2'>
                                                    <select
                                                        className='form-select'
                                                        value={0}
                                                        onChange={event => this.addNewFieldMapping(event.target.value, field)}
                                                        >
                                                        <option value='0'></option>
                                                        {this.state.autoOcrFields.map((field) => (
                                                            <option
                                                                value={field.id}
                                                                key={'mapping-'+field.id}
                                                                >
                                                                {field.name}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>
                                            )}
                                           </>
                                        </div>
                                   ))
                                )}
                                
                                </div>
                            </div>
                        </>
                    )}
    
                </div>
                )}
              
        </div>


        </div>
      </div>
    );
  }
}

export default AutoOcrMappingView;
