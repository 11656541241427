import dispatcher from '../Dispatcher';
import actionTypes from './ActionTypes';
import axios from 'axios';
import * as Globals from '../Globals';
//import { WebUpload } from '../models/WebUpload';
//import { InboundAutomation } from '../Models/Admin/InboundAutomation';
//import { CompanionRecord } from '../Models/Admin/CompanionSchema';

export function GetUploads(ids) {
  axios
    .post(
      `${Globals.baseUrl}/WebUpload/GetUploads`,
      { uploadIds: ids },
      {
        headers: {
          'content-type': 'application/json; charset=utf-8',
          Authorization: `Bearer ${Globals.getJwtToken()}`,
        },
      }
    )
    .then((res) => {
      if (res.status === 200) {
        dispatcher.dispatch({
          actionType: actionTypes.WebUploadGetMany,
          Result: res.data,
        });
      } else if (res.status === 400 || res.status === 401) {
        console.log('error: 401');
      }
    })
    .catch((res) => {
      let result = {
        updateStatus: 'Error',
        errorMessage: '401: Bad request',
      };
      dispatcher.dispatch({
        actionType: actionTypes.IAList,
        Result: result,
      });
    });
}

export function GetUploadStatus(id) {
  axios
    .post(
      `${Globals.baseUrl}/WebUpload/GetUploadStatus`,
      { uploadId: id },
      {
        headers: {
          'content-type': 'application/json; charset=utf-8',
          Authorization: `Bearer ${Globals.getJwtToken()}`,
        },
      }
    )
    .then((res) => {
      if (res.status === 200) {
        dispatcher.dispatch({
          actionType: actionTypes.WebUploadGetStatus,
          Result: res.data,
        });
      } else if (res.status === 400 || res.status === 401) {
        console.log('error: 401');
      }
    })
    .catch((res) => {
      let result = {
        updateStatus: 'Error',
        errorMessage: '401: Bad request',
      };
      dispatcher.dispatch({
        actionType: actionTypes.WebUploadGetStatus,
        Result: result,
      });
    });
}

export function ClearCompletedUploads() {
  dispatcher.dispatch({
    actionType: actionTypes.ClearCompletedUploads,
    Result: null,
  });
}

export function UploadFile(upload) {
  let fd = new FormData();
  fd.append('file', upload.file, upload.file.name);
  fd.append('userName', upload.userName);
  fd.append('defaultFtp', upload.defaultFtp.toString());
  fd.append('routingType', upload.routingType.toString());
  fd.append('defaultTemplate', upload.defaultTemplate.toString());
  fd.append('intakeStrategyId', upload.intakeStrategyId.toString());
  axios
    .post(`${Globals.baseUrl}/WebUpload/UploadDocument`, fd, {
      headers: {
        'content-type': 'multipart/form-data',
        Authorization: `Bearer ${Globals.getJwtToken()}`,
      },
    })
    .then((res) => {
      if (res.status === 200) {
        dispatcher.dispatch({
          actionType: actionTypes.WebUploadUpload,
          Result: res.data,
        });
      } else if (res.status === 400 || res.status === 401) {
        console.log('error: 401');
      }
    })
    .catch((res) => {
      let result = {
        updateStatus: 'Error',
        errorMessage: '401: Bad request',
      };
      dispatcher.dispatch({
        actionType: actionTypes.WebUploadUpload,
        Result: result,
      });
    });
}
