import * as React from 'react';
import { FormControl, InputGroup } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { faFileShield } from '@fortawesome/free-solid-svg-icons';
//import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';

class AutoCompleteExtenderRow extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      currentText: '',
      filteredValues: [],
      allowedValues: [],
      selKey: '',
      currentSelection: undefined,
      selIndex: 0,
      showList: false,
      previousValue: '',
      extender: null,
      extenderKey: '',
      textValue: '',
      isKeyValue: false,
      doc: null,
      rowId: -1,
      enabledControl: true,
      collection: [],
      arrowSelection: false,
    };

    this.onEditAutoComplete = this.onEditAutoComplete.bind(this);
    this.onBlurAutoComplete = this.onBlurAutoComplete.bind(this);
    this.onKeyDown = this.onKeyDown.bind(this);
    this.onClickAutoComplete = this.onClickAutoComplete.bind(this);
    this.onFocus = this.onFocus.bind(this);
    this.onMouseEnter = this.onMouseEnter.bind(this);
    //this.handleHeaderFieldEdit = this.handleHeaderFieldEdit.bind(this);
    this.myRef = React.createRef();
    this.onKeyDownList = this.onKeyDownList.bind(this);
    this.setError = this.setError.bind(this);
    this.onClick = this.onClick.bind(this);
  }

  componentDidMount() {
    if (this.props.allowedValues.length > 0) {
      let x = this.props.allowedValues[0];
      if (x.description !== null) {
        this.setState({ isKeyValue: true });
      }
    }
    this.setState({
      currentText: this.props.value,
      extenderKey: this.props.keyValue,
      textValue: this.props.value,
      showList: false,
    });
  }

  componentWillUnmount() {}

  componentDidUpdate(PrevProps, prevState) {
    if (this.props.allowedValues !== PrevProps.allowedValues) {
      console.log('ExtenderUpdate');
      let filter = [];

      var valueCap = 20;
      //if(val.length>=1){
      for (
        let i = 0;
        i < this.props.allowedValues.length && i < valueCap;
        i++
      ) {
        filter.push(this.props.allowedValues[i]);
      }

      this.setState({
        filteredValues: filter,
        showList:false,
      });
    }

    //   if (this.props.allowedValues.length > 0) {
    //     let x = this.props.allowedValues[0];
    //     this.setState({
    //       currentText: x.textValue,
    //       extenderKey: x.extenderKey,
    //       textValue: x.textValue,
    //     });
    //     this.props.OnAutoCompleteEdit(
    //       x.textValue,
    //       this.props.extender.fieldId,
    //       x.extenderKey
    //     );
    //   }
    // }

    if (this.props.value === 0 && this.state.currentText === 0) {
      this.setState({ currentText: '' });
    }
    if (PrevProps.value !== this.props.value) {
      this.setState({ currentText: this.props.value });
    }
  }
  // handleHeaderFieldEdit(editText, fieldId, extenderId) {

  // }
  setError(text, fieldId, extenderKey) {
    this.props.setError(text, fieldId, extenderKey);
  }
  onEditAutoComplete(e) {
    let filter = [];
    let val = e.target.value;

    var valueCap = 20;

    for (let i = 0; i < this.props.allowedValues.length; i++) {
      if (
        this.props.allowedValues[i].textValue
          .substring(0, val.length)
          .toUpperCase() === val.toUpperCase() ||
        (this.props.allowedValues[i].description !== null &&
          this.props.allowedValues[i].description
            .substring(0, val.length)
            .toUpperCase() === val.toUpperCase())
      ) {
        if (filter.length <= valueCap) {
          filter.push(this.props.allowedValues[i]);
        }
      }
    }
    if (filter.length > 0) {
      let selIndex = 0;
      let selKey = filter[0].extenderKey;
      this.setState({
        selKey: selKey,
        selIndex: selIndex,
        showList: true,
      });
    } else {
      this.setState({
        //selIndex: 0,
        //currentSelection: null,
        showList: false,
      });
    }
    //}
    this.setState({
      currentText: e.target.value,
      filteredValues: filter,
    });
  }

  onClick() {
    this.setState({ showList: true });
  }

  onClickAutoComplete(e, sel) {
    this.props.OnAutoCompleteEdit(
      sel.textValue,
      this.props.extender.fieldId,
      sel.extenderKey
    );

    this.setState({
      currentText: sel.textValue,
      extenderKey: sel.extenderKey,

      showList: false,
    });
    //this.state({showList:false})

    e.preventDefault();
  }

  onBlurAutoComplete(e) {
    let editText = e.target.value;

    if (this.state.showList) {
      let match = this.props.allowedValues.find(
        (x) => x.textValue === editText
      );

      let keyVal = this.state.extenderKey;
      let fieldId = this.props.extender.fieldId;

      if (
        match !== null &&
        match !== undefined &&
        match.extenderKey !== this.props.keyValue
      ) {
        keyVal = match.extenderKey;

        //this.state({showList:false})
        this.props.OnAutoCompleteEdit(editText, fieldId, keyVal);
      } else if (editText.length > 0) {
        console.log('error: no matching value');
      }
      this.setState({
        //selIndex: sindex,
        //currentSelection: sel,
        showList: false,
      });
    } else {
      if (editText.length > 0) {
        console.log('check for bad value');
        let match = this.props.allowedValues.find(
          (x) => x.textValue === editText
        );
        if (match === null || match === undefined) {
          console.log('bad value');
          console.log(this.props.fieldId);
          this.props.setError(this.props.id, this.props.fieldId, `invalid value: '${editText}'`);
        } else {
          console.log('value is good');
          this.props.clearError(this.props.id, this.props.fieldId);
        }
      }
    }
  }

  onFocus(e) {
    let filter = [];
    this.props.onGainFocus();
    var valueCap = 20;
    //if(val.length>=1){
    for (let i = 0; i < this.props.allowedValues.length && i < valueCap; i++) {
      filter.push(this.props.allowedValues[i]);
    }
    if (filter.length > 0) {
      this.setState({
        showList: true,
      });
    } else {
      this.setState({
        showList: false,
      });
    }
    this.setState({
      currentText: e.target.value,
      filteredValues: filter,
      showList: true,
    });

    //e.target.select();
  }

  onKeyDownList(e) {
    let sindex = this.state.selIndex;
    let collection = this.state.collection;
    //on enter or tab
    if (e.keyCode === 9 || e.keyCode === 13) {
      let t = collection[sindex];
      console.log(sindex);
      console.log(t);
      let t2 = t.getElementsByClassName('keyValue')[0].textContent;
      console.log(t2);
      let val = this.state.filteredValues.filter((x) => x.textValue === t2);
      console.log(val);
      if (val !== undefined) {
        this.props.OnAutoCompleteEdit(
          t2,
          this.props.extender.fieldId,
          val[0].extenderKey
        );
        this.setState({
          selIndex: 0,
          currentText: t2,
          showList: false,
        });
      }
      this.setState({
        selIndex: 0,
        currentText: t2,
        showList: false,
      });

      e.preventDefault();
    }
    /*
    if (e.keyCode === 13) {
      var form = e.target;

      let sel = this.state.currentSelection;
      this.setState({
        currentText: sel[this.props.displayField],
        showList: false,
      });
      e.preventDefault();
    }
*/
    // up
    if (e.keyCode === 38) {
      //let sindex = this.state.selIndex;
      //this.myRef.current.focus();
      console.log('arrowup');
      sindex--;
      if (sindex >= 0) {
        for (var i = 0; i < collection.length; i++) {
          collection[i].classList.remove('activeExtender');
        }

        collection[sindex].classList.add('activeExtender');
        let t = collection[sindex];

        //keyValue
        let t2 = t.getElementsByClassName('keyValue')[0].textContent;

        this.setState({
          selIndex: sindex,
          currentText: t2,
        });
      }
      e.preventDefault();
    }
    // down
    if (e.keyCode === 40) {
      for (var y = 0; y < collection.length; y++) {
        collection[y].classList.remove('activeExtender');
      }
      console.log('arrowdown');
      sindex++;
      collection[sindex].classList.add('activeExtender');
      let t = collection[sindex];

      //keyValue
      let t2 = t.getElementsByClassName('keyValue')[0].textContent;
      if (sindex < this.state.collection.length - 1) {
        this.setState({
          selIndex: sindex,
          currentText: t2,
          currentSelection: this.state.filteredValues[sindex],
        });
      }
      e.preventDefault();
    }
  }

  onKeyDown(e) {
    let collection2 = this.state.collection;
    let sindex = this.state.selIndex;
    if (collection2.length === 0) {
      collection2 = document.getElementsByClassName('linkReplacement');
    }
    if (e.keyCode === 13 || e.keyCode === 9) {
      // when then hit Enter(13) or Tab(9)
      if (this.state.showList) {
        let editText = e.target.value;
        if(this.state.arrowSelection === true && sindex > 0)
        {
            let nextKey = this.state.filteredValues[sindex];
            this.props.OnAutoCompleteEdit(
              nextKey.textValue,
              this.props.extender.fieldId,
              nextKey.extenderKey
            );
            this.setState({
              currentText: nextKey.textValue,
              showList: false,
              collection: collection2,
            });
        } else {
          let lookup = this.props.allowedValues.find(
            (l) => l.textValue === this.state.currentText
          );
          if (lookup === undefined) {
            if (this.state.selKey !== '')
              var match = this.props.allowedValues.find(
                (x) => x.extenderKey === this.state.selKey
              );

            if (match !== null && match !== undefined) {
              this.props.OnAutoCompleteEdit(
                match.textValue,
                this.props.extender.fieldId,
                match.extenderKey
              );
              this.setState({
                currentText: match.textValue,
                showList: false,
                collection: collection2,
              });
            } else {
              //this.props.onEdit(match.textValue, this.props.extender.fieldId, match.extenderKey);
              this.setState({
                currentText: e.target.value,
                showList: false,
                collection: collection2,
                extenderKey: '-1',
              });

              this.setError(
                this.state.currentText,
                this.props.extender.fieldId,
                '-1'
              );
            }
          } else {
            this.props.OnAutoCompleteEdit(
              lookup.textValue,
              this.props.extender.fieldId,
              lookup.extenderKey
            );
            this.setState({
              currentText: lookup.textValue,
              showList: false,
              collection: collection2,
            });
          }
        }
      }
      if (e.keyCode === 13) {
        console.log('moveNext');
        this.props.onMoveCell('next');
      }
      //e.preventDefault();
    } else if (e.keyCode === 40) {
      let sindex = this.state.selIndex;
      console.log('arrowdown');
      sindex++;
      if (sindex >= 0 && sindex < this.state.filteredValues.length) {
        let nextKey = this.state.filteredValues[sindex];

        this.setState({ selIndex: sindex, selKey: nextKey.extenderKey, arrowSelection: true });
      }
      e.preventDefault();
    } else if (e.keyCode === 38) {
      let sindex = this.state.selIndex;
      sindex--;
      if (sindex >= 0 && sindex < this.state.filteredValues.length) {
        let nextKey = this.state.filteredValues[sindex];

        this.setState({ selIndex: sindex, selKey: nextKey.extenderKey, arrowSelection: true  });
      }
      e.preventDefault();
    }
  }

  onMouseEnter(e, sel) {
    let sindex = this.state.selIndex;
    let collection2 = this.state.collection;
    if (collection2 !== undefined && collection2.length === 0) {
      collection2 = document.getElementsByClassName('linkReplacement');
    }

    let hoverItem = this.state.filteredValues.find(
      (fv) => fv.extenderKey === sel.extenderKey
    );
    if (hoverItem) {
      sindex = this.state.filteredValues.indexOf(hoverItem);
    }

    this.setState({
      selIndex: sindex,
      selKey: sel.extenderKey,
      collection: collection2,
    });
    e.preventDefault();
  }

  onMouseLeaveList(e, sel) {
    this.setState({ currentSelection: sel });
  }

  render() {
    const isKeyValue = this.props.isKeyValue;
    return (
      <div style={{ position: 'relative' }}>
        <div id={`${this.props.row}_${this.props.id}`}>
          <InputGroup>
            <FormControl
              id={`edit_${this.props.id}`}
              //style=''
              className={`form-control form-control-sm  ${
                this.props.error === '' ? 'fieldEdit' : 'fieldEditError'
              }`}
              value={this.state.currentText}
              role='presentation'
              autoComplete='off'
              onBlur={this.onBlurAutoComplete}
              onChange={this.onEditAutoComplete}
              onFocus={this.onFocus}
              onError={this.setError}
              onKeyDown={this.onKeyDown}
              onClick={this.onClick}
              disabled={this.props.disabled ? 'disabled' : ''}
              //onKeyDown={this.onKeyDown}

              title={this.props.error}
              type='text'
            />
            <div className='search-icon-line'>
              <FontAwesomeIcon icon={solid('search')} id='icon' />
            </div>
          </InputGroup>
        </div>
        {this.state.showList && (
          <div
            id={`${this.props.row}_${this.props.id}_autocomplete`}
            tabIndex='0'
            onKeyDown={this.onKeyDownList}
            ref={this.myRef}
            className={
              isKeyValue
                ? 'autoCompleteExtenderSelectLines coolScroll'
                : 'autoCompleteExtenderSelectBiggerLines  coolScroll'
            } //`${autoCompleteExtenderSelect coolScroll'>
          >
            {this.state.filteredValues.map((sel) => (
              <span
                className={`linkReplacement ${
                  sel.extenderKey === this.state.selKey ? 'activeExtender' : ''
                }`}
                id={`${this.props.row}_${sel.extenderKey}_span`}
                key={sel.extenderKey}
                value={sel.textValue}
                onMouseLeave={(e) => this.onMouseLeaveList(e, sel)}
                onMouseDown={(e) => this.onClickAutoComplete(e, sel)}
                onMouseEnter={(e) => this.onMouseEnter(e, sel)}
              >
                <span className='keyValue'>{sel.textValue}</span>
                <span className='description'>
                  {isKeyValue ? '' : sel.description}{' '}
                </span>
              </span>
            ))}
          </div>
        )}
      </div>
    );
  }
}

export default AutoCompleteExtenderRow;
