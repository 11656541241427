import * as React from 'react';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import { brands } from '@fortawesome/fontawesome-svg-core/import.macro';
import {
  GetOAuthSettings,
  UpdateOAuthSettings,
  UpdateADSettings,
} from '../../../actions/OAuthActions';
import ADSettingsStore from '../../../stores/ADSettingsStore';

class ADOAuthSettingsView extends React.Component {
  constructor(props) {
    // state
    super(props);

    this.state = {
      currentSettings: undefined,
    };

    this.onADSettingsUpdate = this.onADSettingsUpdate.bind(this);
    this.toggleEnableAD = this.toggleEnableAD.bind(this);
    this.toggleEnableOAuth = this.toggleEnableOAuth.bind(this);
    this.onEditLDAP = this.onEditLDAP.bind(this);
    this.onEditClientId = this.onEditClientId.bind(this);
    this.onEditClientSecret = this.onEditClientSecret.bind(this);
    this.onEditTenant = this.onEditTenant.bind(this);
    this.saveOAuthSettings = this.saveOAuthSettings.bind(this);
    this.saveADSettings = this.saveADSettings.bind(this);
  }

  componentDidMount() {
    ADSettingsStore.addChangeListener(this.onADSettingsUpdate);
    GetOAuthSettings();

    //let a = 1;
  }

  componentWillUnmount() {
    ADSettingsStore.removeChangeListener(this.onADSettingsUpdate);
    //let a = 1;
  }

  onADSettingsUpdate() {
    let settings = ADSettingsStore.getADSettings();
    this.setState({ currentSettings: settings });

    console.log('update');
    console.log(settings);
  }

  onEditLDAP(e) {
    let settings = this.state.currentSettings;
    settings.ldapConnection = e.target.value;
    this.setState({ currentSettings: settings });
  }
  onEditClientId(e) {
    let settings = this.state.currentSettings;
    settings.clientId = e.target.value;
    this.setState({ currentSettings: settings });
  }
  onEditClientSecret(e) {
    let settings = this.state.currentSettings;
    settings.clientSecret = e.target.value;
    this.setState({ currentSettings: settings });
  }
  onEditTenant(e) {
    let settings = this.state.currentSettings;
    settings.tenantId = e.target.value;
    this.setState({ currentSettings: settings });
  }

  toggleEnableAD() {
    let cs = this.state.currentSettings;
    cs.enableAD = !cs.enableAD;
    this.setState({ currentSettings: cs });
  }

  toggleEnableOAuth() {
    let cs = this.state.currentSettings;
    cs.enableOAuth = !cs.enableOAuth;
    this.setState({ currentSettings: cs });
  }

  saveOAuthSettings() {
    UpdateOAuthSettings(this.state.currentSettings);
  }

  saveADSettings() {
    UpdateADSettings(this.state.currentSettings);
  }

  render() {
    return (
      <div className='container'>
        {this.state.currentSettings !== undefined && (
          <div
            style={{ maxWidth: '60%', margin: '12px' }}
            id='userInfo'
            className='card'
          >
            <div className='card-header'>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='22'
                height='20'
                viewBox='0 0 448 512'
              >
                <path d='M0 93.7l183.6-25.3v177.4H0V93.7zm0 324.6l183.6 25.3V268.4H0v149.9zm203.8 28L448 480V268.4H203.8v177.9zm0-380.6v180.1H448V32L203.8 65.7z' />
              </svg>
              {'  '}
              Windows Active Directory
            </div>
            <div className='card-body'>
              <div className='form-check form-switch'>
                <label className='form-check-label' htmlFor='useADswitch'>
                  Enable AD authentication
                </label>
                <input
                  className='form-check-input'
                  type='checkbox'
                  checked={this.state.currentSettings.enableAD}
                  onChange={this.toggleEnableAD}
                  id='useADswitch'
                />
              </div>
              <div style={{ marginLeft: '36px', marginTop: '10px' }}>
                {this.state.currentSettings.enableAD && (
                  <div>
                    <label htmlFor='txtLDAP' className='form-label'>
                      LDAP Connection String:
                    </label>
                    <input
                      type='text'
                      className='form-control'
                      id='txtLDAP'
                      value={this.state.currentSettings.ldapConnection}
                      onChange={this.onEditLDAP}
                      placeholder='LDAP Connection String'
                    />
                  </div>
                )}
                <button
                  style={{ marginTop: '8px' }}
                  className='btn btn-success btn-sm'
                  onClick={this.saveADSettings}
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        )}
        <div className='row'>
          <div className='col-6'>
            {this.state.currentSettings !== undefined && (
              <div
                id='userInfo'
                className='card'
                style={{ maxWidth: '80%', margin: '12px' }}
              >
                <div className='card-header'>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    viewBox='0 0 448 512'
                    width='22'
                    height='20'
                  >
                    <path d='M0 32h214.6v214.6H0V32zm233.4 0H448v214.6H233.4V32zM0 265.4h214.6V480H0V265.4zm233.4 0H448V480H233.4V265.4z' />
                  </svg>
                  {'  '}
                  Microsoft 365 OAuth
                </div>
                <div className='card-body'>
                  <div className='form-check form-switch'>
                    <label className='form-check-label' htmlFor='useADswitch'>
                      Enable Office 365 OAuth Authentication
                    </label>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      checked={this.state.currentSettings.enableOAuth}
                      onChange={this.toggleEnableOAuth}
                      id='useADswitch'
                    />
                  </div>
                  <div style={{ marginLeft: '36px', marginTop: '10px' }}>
                    {this.state.currentSettings.enableOAuth && (
                      <div>
                        <div>
                          <label htmlFor='txtClientId' className='form-label'>
                            Client Id:
                          </label>
                          <input
                            type='text'
                            className='form-control'
                            id='txtClientId'
                            value={this.state.currentSettings.clientId}
                            onChange={this.onEditClientId}
                            placeholder='ClientId'
                          />
                        </div>
                        <div>
                          <label
                            htmlFor='txtClientSecret'
                            className='form-label'
                          >
                            Client Secret:
                          </label>
                          <input
                            type='password'
                            className='form-control'
                            id='txtClientSecret'
                            value={this.state.currentSettings.clientSecret}
                            onChange={this.onEditClientSecret}
                            placeholder='Client Secret'
                          />
                        </div>
                        <div>
                          <label htmlFor='txtTenant' className='form-label'>
                            Tenant Id:
                          </label>
                          <input
                            type='text'
                            className='form-control'
                            id='txtTenant'
                            value={this.state.currentSettings.tenantId}
                            onChange={this.onEditTenant}
                            placeholder='Tenant Id'
                          />
                        </div>
                      </div>
                    )}
                    <button
                      style={{ marginTop: '8px' }}
                      className='btn btn-success btn-sm'
                      onClick={this.saveOAuthSettings}
                    >
                      Save
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className='col-6'></div>
        </div>
      </div>
    );
  }
}

export default ADOAuthSettingsView;
