import { EventEmitter } from 'events';
import Dispatcher from '../Dispatcher';
import actionTypes from '../actions/ActionTypes';

const CHANGE_EVENT = 'adSettingsChanges';
let _currentADSettings = {};
let _lastChange = '';
let _lastError = '';

class ADSettingsStore extends EventEmitter {
  addChangeListener(callback) {
    this.on(CHANGE_EVENT, callback);
  }

  removeChangeListener(callback) {
    this.removeListener(CHANGE_EVENT, callback);
  }

  emitChange() {
    this.emit(CHANGE_EVENT);
  }

  getADSettings() {
    return _currentADSettings;
  }

  getLastChange() {
    return _lastChange;
  }

  getLastError() {
    return _lastError;
  }
}

const store = new ADSettingsStore();

Dispatcher.register((action) => {
  switch (action.actionType) {
    case actionTypes.UserGetADOauthSettings:
      _currentADSettings = action.Result;
      _lastChange = action.actionType;

      store.emitChange();
      break;
    case actionTypes.USerUpdateADOauthSettings:
      _currentADSettings = action.Result;
      _lastChange = action.actionType;
      store.emitChange();
      break;
    default:
  }
});

export default store;
