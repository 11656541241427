import { EventEmitter } from 'events';
import Dispatcher from '../Dispatcher';
import actionTypes from '../actions/ActionTypes';

const CHANGE_EVENT = 'webUploadChange';
let _currentUploads = [];

class WebUploadStore extends EventEmitter {
  addChangeListener(callback) {
    this.on(CHANGE_EVENT, callback);
  }

  removeChangeListener(callback) {
    this.removeListener(CHANGE_EVENT, callback);
  }

  emitChange() {
    this.emit(CHANGE_EVENT);
  }

  getCurrentUploads() {
    return _currentUploads;
  }
}

const store = new WebUploadStore();

Dispatcher.register((action) => {
  switch (action.actionType) {
    case actionTypes.WebUploadGetStatus:
      let matchingUpload = _currentUploads.findIndex(
        (u) => u.uploadId === action.Result.uploadId
      );
      if (matchingUpload !== -1) {
        _currentUploads[matchingUpload] = action.Result;
      } else {
        _currentUploads.push(action.Result);
      }
      //_currentUploads = action.Result;
      store.emitChange();
      break;
    case actionTypes.WebUploadUpload:
      _currentUploads.push(action.Result);
      store.emitChange();
      break;
    case actionTypes.ClearCompletedUploads:
      let cu = [];
      _currentUploads.forEach((u) => {
        console.log(u);
        if (u.regStatus === 'Not Registered') {
          cu.push(u);
        }
      });
      _currentUploads = cu;
      store.emitChange();
      break;
    default:
  }
});

export default store;
