import dispatcher from '../Dispatcher';
import actionTypes from './ActionTypes';
import axios from 'axios';
import * as Globals from '../Globals';
import { PostAlert } from './AlertActions';

export function GetSchedule(id) {
  axios
    .get(`${Globals.baseUrl}/Schedule/GetSchedule/${id}`, {
      headers: {
        'content-type': 'application/json; charset=utf-8',
        Authorization: `Bearer ${Globals.getJwtToken()}`,
      },
    })
    .then((res) => {
      if (res.status === 200) {
        dispatcher.dispatch({
          actionType: actionTypes.SchedulesGetOne,
          Result: res.data,
        });
      } else if (res.status === 400 || res.status === 401) {
        console.log('error: 401');
      }
    })
    .catch((res) => {
      let result = {
        updateStatus: 'Error',
        errorMessage: '401: Bad request',
      };
      dispatcher.dispatch({
        actionType: actionTypes.SchedulesGetOne,
        Result: result,
      });
    });
}

export function GetScheduleList() {
  axios
    .get(`${Globals.baseUrl}/Schedule/GetList`, {
      headers: {
        'content-type': 'application/json; charset=utf-8',
        Authorization: `Bearer ${Globals.getJwtToken()}`,
      },
    })
    .then((res) => {
      if (res.status === 200) {
        dispatcher.dispatch({
          actionType: actionTypes.SchedulesGetList,
          Result: res.data,
        });
      } else if (res.status === 400 || res.status === 401) {
        console.log('error: 401');
      }
    })
    .catch((res) => {
      let result = {
        updateStatus: 'Error',
        errorMessage: '401: Bad request',
      };
      dispatcher.dispatch({
        actionType: actionTypes.SchedulesGetList,
        Result: result,
      });
    });
}

export function UpdateSchedule(schedule) {
  axios
    .post(
      `${Globals.baseUrl}/Schedule/UpdateSchedule`,
      { ScheduleId: schedule.id, Sched: schedule },
      {
        headers: {
          'content-type': 'application/json; charset=utf-8',
          Authorization: `Bearer ${Globals.getJwtToken()}`,
        },
      }
    )
    .then((res) => {
      console.log(res);
      if (res.status === 200) {
        dispatcher.dispatch({
          actionType: actionTypes.ScheduleUpdateOne,
          Result: res.data,
        });

        if (res.data.status === 'Success') {
          PostAlert('success', 'Schedule updated');
        }
      } 
      else if (res.status === 400 || res.status === 401) {
        //Logout();
      }
    })
    .catch((res) => {});
}

export function DeleteSchedule(id) {
  axios
    .post(
      `${Globals.baseUrl}/Schedule/DeleteSchedule`,
      { id: id },
      {
        headers: {
          'content-type': 'application/json; charset=utf-8',
          Authorization: `Bearer ${Globals.getJwtToken()}`,
        },
      }
    )
    .then((res) => {

      if (res.status === 200) {
        if (res.data.status === 'Success') {
          PostAlert('success', 'Schedule deleted');
        }
        else if (res.data.status === 'Error') {
          PostAlert('error', 'Error deleting Schedule');
        }
        dispatcher.dispatch({
          actionType: actionTypes.ScheduleDelete,
          Result: res.data,
        });
      } else if (res.status === 400 || res.status === 401) {
        console.log('error: 401');
      }
    })
    .catch((res) => {
      console.log(res);
    });
}


export function RunNow(id) {
  axios
    .post(
      `${Globals.baseUrl}/Schedule/RunNow`,
      { id: id },
      {
        headers: {
          'content-type': 'application/json; charset=utf-8',
          Authorization: `Bearer ${Globals.getJwtToken()}`,
        },
      }
    )
    .then((res) => {
      console.log(res);
      if (res.status === 200) {
        dispatcher.dispatch({
          actionType: actionTypes.ScheduleRunNow,
          Result: res.data,
        });
        if (res.data.updateStatus === 'Success') {
          PostAlert('success', 'Job has been scheduled for immediate execution');
        }
        if (res.data.updateStatus === 'Error') {
          PostAlert('error', res.data.errorMessage);
        }
      } else if (res.status === 400 || res.status === 401) {
        console.log('error: 401');
      }
    })
    .catch((res) => {
      console.log(res);
    });
}


