import dispatcher from '../Dispatcher';
import actionTypes from './ActionTypes';
import axios from 'axios';
import * as Globals from '../Globals';
import { Logout } from './LoginActions';
import { PostAlert } from './AlertActions';

export function UpdateIndex(index) {
  //let newUrl = getNewUrl();
  axios
    .post(`${Globals.baseUrl}/SearchIndex/Update`, index, {
      headers: {
        'content-type': 'application/json; charset=utf-8',
        Authorization: `Bearer ${Globals.getJwtToken()}`,
      },
    })
    .then((res) => {
      if (res.status === 200) {
        let result = res.data;
        if (result.status === 'Error') {
          PostAlert('error', 'Failed to create index.  See logs for details');
        } else {
          dispatcher.dispatch({
            actionType: actionTypes.IndexUpdate,
            Result: res.data,
          });
          PostAlert('success', 'Search index updated');
        }
      } else if (res.status === 400 || res.status === 401) {
        console.log('error: 401');
      }
    })
    .catch((res) => {
      let errorMsg = res.toString();
      if (errorMsg.includes('401')) {
        console.log('Unauthorized');
        Logout();
      }
    });
}

export function GetIndexFieldsForFormType(formTypeId) {
  console.log('getIndex');
  axios
    .get(`${Globals.baseUrl}/SearchIndex/GetIndexFields/${formTypeId}`, {
      headers: {
        'content-type': 'application/json; charset=utf-8',
        Authorization: `Bearer ${Globals.getJwtToken()}`,
      },
    })
    .then((res) => {
      if (res.status === 200) {
        dispatcher.dispatch({
          actionType: actionTypes.GetIndexFieldsByFormTypeId,
          Result: res.data,
        });
      } else if (res.status === 400 || res.status === 401) {
        console.log('error: 401');
      }
    })
    .catch((res) => {
      let result = {
        updateStatus: 'Error',
        errorMessage: '401: Bad request',
      };
      dispatcher.dispatch({
        actionType: actionTypes.GetIndexFieldsByFormTypeId,
        Result: result,
      });
    });
}

export function GetIndexDocCounts(formTypeId) {
  axios
    .get(`${Globals.baseUrl}/SearchIndex/GetIndexDocCounts/${formTypeId}`, {
      headers: {
        'content-type': 'application/json; charset=utf-8',
        Authorization: `Bearer ${Globals.getJwtToken()}`,
      },
    })
    .then((res) => {
      if (res.status === 200) {
        dispatcher.dispatch({
          actionType: actionTypes.IndexGetDocCounts,
          Result: res.data,
        });
      } else if (res.status === 400 || res.status === 401) {
        console.log('error: 401');
      }
    })
    .catch((res) => {
      let result = {
        updateStatus: 'Error',
        errorMessage: '401: Bad request',
      };
    });
}

export function ReIndexFormType(formTypeId) {
  axios
    .get(`${Globals.baseUrl}/SearchIndex/ReIndexFormType/${formTypeId}`, {
      headers: {
        'content-type': 'application/json; charset=utf-8',
        Authorization: `Bearer ${Globals.getJwtToken()}`,
      },
    })
    .then((res) => {
      if (res.status === 200) {
        dispatcher.dispatch({
          actionType: actionTypes.ReIndexFormType,
          Result: res.data,
        });
      } else if (res.status === 400 || res.status === 401) {
        console.log('error: 401');
      }
    })
    .catch((res) => {
      let result = {
        updateStatus: 'Error',
        errorMessage: '401: Bad request',
      };
      dispatcher.dispatch({
        actionType: actionTypes.ReIndexFormType,
        Result: result,
      });
    });
}

export function ReIndexFormTypeWithParams(formTypeId, reindexOptions) {
  console.log(reindexOptions);
  axios
    .post(
      `${Globals.baseUrl}/SearchIndex/ReIndexFormTypeWithParams`,
      {
        formTypeId: formTypeId,
        includeActive: reindexOptions.includeActive,
        includeCompleted: reindexOptions.includeCompleted,
        includeDeleted: reindexOptions.includeDeleted,
        populateColumns: reindexOptions.populateColumns,
        coulumnsToPopulate: reindexOptions.coulumnsToPopulate,
        rebuildTextIndex: reindexOptions.rebuildTextIndex,
        rebuildRelatedCounts: reindexOptions.rebuildRelatedCounts,
      },
      {
        headers: {
          'content-type': 'application/json; charset=utf-8',
          Authorization: `Bearer ${Globals.getJwtToken()}`,
        },
      }
    )
    .then((res) => {
      if (res.status === 200) {
        dispatcher.dispatch({
          actionType: actionTypes.ReIndexFormType,
          Result: res.data,
        });
      } else if (res.status === 400 || res.status === 401) {
        console.log('error: 401');
      }
    })
    .catch((res) => {
      let result = {
        updateStatus: 'Error',
        errorMessage: '401: Bad request',
      };
      dispatcher.dispatch({
        actionType: actionTypes.ReIndexFormType,
        Result: result,
      });
    });
}

export function GetBuildPercentage(formTypeId) {
  if (isNaN(formTypeId)) {
    return false;
  }
  axios
    .get(`${Globals.baseUrl}/SearchIndex/GetBuildPercentage/${formTypeId}`, {
      headers: {
        'content-type': 'application/json; charset=utf-8',
        Authorization: `Bearer ${Globals.getJwtToken()}`,
      },
    })
    .then((res) => {
      if (res.status === 200) {
        dispatcher.dispatch({
          actionType: actionTypes.GetBuildPercentage,
          Result: res.data,
        });
      } else if (res.status === 400 || res.status === 401) {
        console.log('error: 401');
      }
    })
    .catch((res) => {
      let result = {
        updateStatus: 'Error',
        errorMessage: '401: Bad request',
      };
      dispatcher.dispatch({
        actionType: actionTypes.GetBuildPercentage,
        Result: result,
      });
    });
}

export function DeleteIndex(index) {
  //let newUrl = getNewUrl();
  axios
    .post(`${Globals.baseUrl}/SearchIndex/Delete`, index, {
      headers: {
        'content-type': 'application/json; charset=utf-8',
        Authorization: `Bearer ${Globals.getJwtToken()}`,
      },
    })
    .then((res) => {
      if (res.status === 200) {
        dispatcher.dispatch({
          actionType: actionTypes.IndexDelete,
          Result: res.data,
        });
        PostAlert(res.data.status, res.data.updateMessage);

      } else if (res.status === 400 || res.status === 401) {
        console.log('error: 401');
      }
    })
    .catch((res) => {
      let errorMsg = res.toString();
      if (errorMsg.includes('401')) {
        console.log('Unauthorized');
        Logout();
      }
    });
}

export function CancelReindexing(formTypeId) {
  axios
    .get(`${Globals.baseUrl}/SearchIndex/CancelReindexing/${formTypeId}`, {
      headers: {
        'content-type': 'application/json; charset=utf-8',
        Authorization: `Bearer ${Globals.getJwtToken()}`,
      },
    })
    .then((res) => {
      if (res.status === 200) {
        dispatcher.dispatch({
          actionType: actionTypes.CancelReindex,
          Result: res.data,
        });
      } else if (res.status === 400 || res.status === 401) {
        console.log('error: 401');
      }
    })
    .catch((res) => {
      let result = {
        updateStatus: 'Error',
        errorMessage: '401: Bad request',
      };
      dispatcher.dispatch({
        actionType: actionTypes.CancelReindex,
        Result: result,
      });
    });
}

export function UpdateBuildStatus(formTypeId, status) {
  axios
    .get(`${Globals.baseUrl}/SearchIndex/UpdateBuildStatus/${formTypeId}/${status}`, {
      headers: {
        'content-type': 'application/json; charset=utf-8',
        Authorization: `Bearer ${Globals.getJwtToken()}`,
      },
    })
    .then((res) => {
      if (res.status === 200) {
        dispatcher.dispatch({
          actionType: actionTypes.UpdateBuildStatus,
          Result: res.data,
        });
      } else if (res.status === 400 || res.status === 401) {
        console.log('error: 401');
      }
    })
    .catch((res) => {
      let result = {
        updateStatus: 'Error',
        errorMessage: '401: Bad request',
      };
      dispatcher.dispatch({
        actionType: actionTypes.UpdateBuildStatus,
        Result: result,
      });
    });
}
