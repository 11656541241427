import * as React from 'react';
class TableEditorCell extends React.Component {
  constructor() {
    super();
    // state
    this.state = {
      currentVal: '',
    };

    this.handleCellClick = this.handleCellClick.bind(this);
    this.handleCellEdit = this.handleCellEdit.bind(this);
    this.handleKeyEvent = this.handleKeyEvent.bind(this);
    this.handleKeyEvent2 = this.handleKeyEvent2.bind(this);
  }

  handleCellEdit(newText) {
    this.props.onEditHandler(this.props.rowId, this.props.colId, newText);
  }

  handleCellClick() {
    this.props.onClickHandler(this.props.rowId, this.props.colId);
  }

  handleKeyEvent(e) {
    if (e.keyCode === 40) {
      this.props.onMoveCell('down');
    }
    if (e.keyCode === 38) {
      this.props.onMoveCell('up');
    }
    if (e.keyCode === 37 && e.ctrlKey) {
      this.props.onMoveCell('left');
    }
    if (e.keyCode === 39 && e.ctrlKey) {
      this.props.onMoveCell('right');
    }
  }

  handleKeyEvent2(e) {
    if (e.keyCode === 9) {
      e.preventDefault();
      this.props.onMoveCell('tab');
    }
  }

  render() {
    if (this.props.isEditMode) {
      return (
        <td onClick={this.handleCellClick}>
          <input
            type='text'
            style={{
              padding: '2px',
              minWidth: '80px',
            }}
            className='form-control'
            id={`edit_${this.props.rowId}_${this.props.colId}`}
            value={this.props.value}
            onChange={(e) => this.handleCellEdit(e.target.value)}
            onKeyUp={this.handleKeyEvent}
            onKeyDown={this.handleKeyEvent2}
          />
        </td>
      );
    } else if (this.props.isReadOnly) {
      return (
        /* <td style={{ backgroundColor: '#E1E1E1' }}>{this.props.value}</td> */
        <td style={{ color: '#999' }}>{this.props.value}</td>
      );
    } else {
      return (
        <td onClick={this.handleCellClick} style={{ cursor: 'pointer' }}>
          {this.props.value}
        </td>
      );
    }
  }
}

export default TableEditorCell;
