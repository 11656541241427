import * as React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';

import WorkflowStore from '../../../../stores/WorkflowStore';
import { GetWorkflowList } from '../../../../actions/WorkflowActions';
import actionTypes from '../../../../actions/ActionTypes';

class WorkflowSidebar extends React.Component {
  constructor() {
    super();
    this.state = {
      workflowList: [],
      searchText: '',
      filteredWorkflowList: [],
    };

    this.onReceiveWorkflowChange = this.onReceiveWorkflowChange.bind(this);
    this.onSearchChange = this.onSearchChange.bind(this);
  }

  componentDidMount() {
    WorkflowStore.addChangeListener(this.onReceiveWorkflowChange);
    GetWorkflowList();
  }

  componentWillUnmount() {
    WorkflowStore.removeChangeListener(this.onReceiveWorkflowChange);
  }

  onReceiveWorkflowChange() {
    let lastChange = WorkflowStore.getLastAction();
    if (lastChange === actionTypes.WorkflowGetWorkflows) {
      let workflows = WorkflowStore.getCurrentWorkflows();
      workflows.sort(function (a, b) {
        return ('' + a.name).localeCompare(b.name);
      });
      this.setState({
        workflowList: workflows,
        searchText: '',
        filteredWorkflowList: workflows,
      });
    }
  }

  onSearchChange(e) {
    let search = e.target.value;
    if (search === '') {
      let newList = [...this.state.workflowList];
      this.setState({ searchText: search, filteredWorkflowList: newList });
    } else {
      let newList = [];
      this.state.workflowList.forEach((wf) => {
        if (wf.name.toLowerCase().includes(search.toLowerCase())) {
          newList.push(wf);
        }
      });
      this.setState({ searchText: search, filteredWorkflowList: newList });
    }
  }

  render() {
    let listOfWorkflows;
    if (this.state.workflowList !== null) {
      listOfWorkflows = this.state.filteredWorkflowList.map((wf) => (
        <div key={wf.workflowId}>
          <Link
            className='sidebar-link'
            to={'/Admin/WorkflowView/' + wf.workflowId}
            //onClick={() => this.handleBPClick(bp)}
            style={{ cursor: 'Pointer', wordWrap: 'break-word' }}
          >
            {wf.name}
          </Link>{' '}
        </div>
      ));
    } else {
      listOfWorkflows = <div />;
    }
    return (
      <div
        className='d-flex flex-column p-3 text-white bg-dark'
        style={{ height: '100%', width: '245px' }}
      >
        <Link
          to='/Admin/Workflows'
          className='d-flex align-items-center mb-3 mb-md-0 me-md-auto text-white text-decoration-none'
        >
          <span className='fs-4'>
            <FontAwesomeIcon icon={solid('sitemap')} />
            {'   '}
            Workflows
          </span>
        </Link>
        <hr />
        <div className='input-group rounded' style={{ marginBottom: '16px' }}>
          <input
            type='search'
            value={this.state.searchText}
            className='form-control'
            placeholder='Search'
            aria-label='Search'
            onChange={this.onSearchChange}
            aria-describedby='search-addon'
          />
          <span className='input-group-text border-0' id='search-addon'>
            <FontAwesomeIcon icon={solid('search')} />
          </span>
        </div>

        <div>{listOfWorkflows}</div>
      </div>
    );
  }
}

export default WorkflowSidebar;
