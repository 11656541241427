import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import actionTypes from '../../../actions/ActionTypes';
import { GetSystemAuditLogs } from '../../../actions/SystemAuditLogsActions';
import SystemAuditLoggingStore from '../../../stores/SystemAuditLoggingStore';

class SystemAuditLogsView extends React.Component {
	constructor() {
		// state
		super();

		this.state = {
			toDate: '',
			fromDate: '',
			toTime: '',
			fromTime: '',
			categories: [],
			subCategories: [],
			logs: [],
			cat1: 'All',
			cat2: 'All',
			sev: -1,
			showPopup: false,
			showMessageText: '',
			currentLog: [],
			currentLogNumber: -1,
			beforeTableHtmlHeader: '',
			beforeTableHtmlRows: '',
			tableName: '',
			userName: '',
			date: '',
			hasPrevious: false,
			hasNext: false,
			seg1: '',
			seg2: '',
		};

		this.onLogUpdate = this.onLogUpdate.bind(this);
		this.updateLogs = this.updateLogs.bind(this);
		this.getPrevious = this.getPrevious.bind(this);
		this.getNext = this.getNext.bind(this);
		this.convertDate = this.convertDate.bind(this);
	}

	componentDidMount() {

		let loc = window.location.pathname;
		let pathParts = loc.split('/');
		var compName = pathParts[pathParts.length - 1];
		var compName2 = pathParts[pathParts.length - 2];

		this.setState({ seg1: compName2 });
		this.setState({ seg2: compName });
		SystemAuditLoggingStore.addChangeListener(this.onLogUpdate);
		this.updateLogs();

	}

	componentWillUnmount() {
		SystemAuditLoggingStore.removeChangeListener(this.onLogUpdate);
	}

	componentDidUpdate() { }

	updateLogs() {
		let loc = window.location.pathname;
		let pathParts = loc.split('/');

		var compName = pathParts[pathParts.length - 1];
		var compName2 = pathParts[pathParts.length - 2];
		GetSystemAuditLogs(compName2, compName);

	}

	onLogUpdate() {
		let change = SystemAuditLoggingStore.getLastChange();
		switch (change) {
			case actionTypes.LogGetCategories:
				let cat = SystemAuditLoggingStore.getCategories();
				this.setState({ categories: cat });
				break;
			case actionTypes.SystemAuditLogGetLogs:
				let logs2 = SystemAuditLoggingStore.getLogs();
				if (logs2.length > 0) {
					this.setState({ logs: logs2 });
					this.setState({ currentLog: logs2[0] });
					this.setState({ currentLogNumber: 0 });
					this.setState({ tableName: logs2[0].message.subCategory });
					this.setState({ userName: logs2[0].message.username });

					let tempDate = new Date(logs2[0].message.messageTime);
					let d2 = this.convertDate(tempDate);
					this.setState({ date: d2 });
					if (logs2.length > 1) {
						this.setState({ hasNext: true });
					}
				}
				break;
			default:
		}
	}


	convertDate(dateString) {
		let d = new Date(dateString + 'Z');
		let h = d.getHours();
		let mrdn = 'AM';
		if (h > 11) {
			mrdn = 'PM';
		}
		if (h === 24) {
			mrdn = 'AM';
		}
		if (h > 12) {
			h = h - 12;
		}
		if (h === 0) {
			h = 12;
		}
		let m = d.getMonth() + 1;
		//let m2 = m.toString().padStart(2, '0');
		let d2 = d.getDate().toString();
		let m3 = d.getMinutes().toString().padStart(2, '0');

		return `${m}/${d2}/${d.getFullYear()} ${h}:${m3} ${mrdn}`;
	}

	getPrevious() {
		let list = this.state.logs;
		let currNum = this.state.currentLogNumber;
		currNum = currNum - 1;
		if (list.length >= currNum) {
			this.setState({ currentLog: list[currNum] });
			this.setState({ currentLogNumber: currNum });
			this.setState({ tableName: list[currNum].message.subCategory });
			this.setState({ userName: list[currNum].message.username });

			let tempDate = new Date(list[currNum].message.messageTime);
			let d2 = this.convertDate(tempDate);
			this.setState({ date: d2 });
		}

		if (list.length > currNum) {
			this.setState({ hasNext: true });
		} else {
			this.setState({ hasNext: false });
		}
		if (currNum > 0) {
			this.setState({ hasPrevious: true });
		} else {
			this.setState({ hasPrevious: false });
		}

	}




	getNext() {
		let list = this.state.logs;
		let currNum = this.state.currentLogNumber;
		currNum = currNum + 1;
		if (list.length > currNum) {
			this.setState({ currentLog: list[currNum] });
			this.setState({ currentLogNumber: currNum });
			this.setState({ tableName: list[currNum].message.subCategory });
			this.setState({ userName: list[currNum].message.username });

			let tempDate = new Date(list[currNum].message.messageTime);
			let d2 = this.convertDate(tempDate);
			this.setState({ date: d2 });
		}

		if (list.length - 1 > currNum) {
			this.setState({ hasNext: true });
		}
		else {
			this.setState({ hasNext: false });
		}
		if (currNum > 0) {
			this.setState({ hasPrevious: true });
		}
		else {
			this.setState({ hasPrevious: false });
		}
	}
	render() {
		return (
			<div className='d-flex flex-row flex-fill'>
				<div className='flex-fill' style={{ textAlign: 'center' }}>
					<div style={{ textAlign: 'center' }}><div className='card-header' style={{ textAlign: 'center' }}>

						<span style={{ display: 'inline-block', textAlign: 'left' }}><FontAwesomeIcon icon={solid('gear')} /> System Audit Changes
							<br />Table: <b>{((`${this.state.tableName}`))}</b>
							<br />Changes by Username: <b>{((`${this.state.userName}`))} at {((`${this.state.date}`))}</b>
							<br /><span>Log Number: <b>{((`${this.state.currentLogNumber + 1}`))}</b></span>
							<br /><br /><button disabled={!this.state.hasPrevious} className='btn btn-sm btn-primary ms-3' onClick={this.getPrevious}>Previous</button>
							<button disabled={!this.state.hasNext} className='btn btn-sm btn-primary ms-3' onClick={this.getNext}>Next</button></span >

					</div>
						<div className='card-body' style={{ textAlign: 'center' }}>
							<table style={{ width: '100%' }}><tr><td style={{ verticalAlign: 'top', maxWidth: '49vw', width: '49vw', minWidth: '49vw', overflow: 'auto', paddingRight: '10px', }}>
								<div>
									Before Changes

									{this.state.currentLog.beforeChange && this.state.currentLog.beforeChange.records.length ? <span></span>:  (<span><br/>No records</span>)}
									<table key="beforeTable" className='table table-striped' style={{
										fontSize: '8pt',
										border: 'solid 1px #4C8CAE',
										paddingTop: '0',
									}}>
										<thead>
											<tr key="fff" style={{ textAlign: 'left', whiteSpace: 'nowrap' }}>
												{
													this.state.currentLog.beforeChange && this.state.currentLog.beforeChange.columns.map((col) =>
														((<th key={col.columnName}>{col.columnName}</th>)))}
											</tr>
										</thead>
										<tbody>{this.state.currentLog.beforeChange && this.state.currentLog.beforeChange.records.map((record) => (
											<tr key={record.rowId} className={`${record.isDirty ? "highlight" : ""}`} >
												{record.entities && record.entities.map((entity) => (
													<td style={{ textAlign: 'left', whiteSpace: 'nowrap' }} className={`${record.isDirty ? "highlight" : ""}`}>
														{entity.value}
													</td>
												))}
											</tr>)
										)}</tbody>
									</table>
								</div>
							</td>
								<td style={{ verticalAlign: 'top', maxWidth: '49vw', width: '49vw', minWidth: '49vw', overflow: 'auto' }}>
									<div>
										After Changes
										{this.state.currentLog.afterChange && this.state.currentLog.afterChange.records.length ? <span></span>:  (<span><br/>No records</span>)}
										<table key="afterTable" className='table table-striped' style={{
											fontSize: '8pt',
											border: 'solid 1px #4C8CAE',
											paddingTop: '0',
										}}>
											<thead>
												<tr key="fff" style={{ textAlign: 'left', whiteSpace: 'nowrap' }}>
													{
														this.state.currentLog.afterChange && this.state.currentLog.afterChange.columns.map((col) =>
															((<th key={col.columnName}>{col.columnName}</th>)))}
												</tr>
											</thead>
											<tbody>
												{this.state.currentLog.afterChange && this.state.currentLog.afterChange.records.map((record) => (
													<tr key={record.rowId} className={`${record.isDirty ? "highlight" : ""}`}>
														{record.entities && record.entities.map((entity) => (
															<td style={{ textAlign: 'left', whiteSpace: 'nowrap' }} className={`${record.isDirty ? "highlight" : ""}`}>{entity.value}</td>
														))}

													</tr>)
												)}
											</tbody>
										</table>
									</div>
								</td>
							</tr>
							</table>
						</div>
					</div>
				</div>

			</div>
		);
	}
}

export default SystemAuditLogsView;
