import * as React from 'react';
import { Link } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { Dropdown } from 'react-bootstrap';
import RolesSidebar from './RolesSidebar';
import actionTypes from '../../../actions/ActionTypes';
import BPStore from '../../../stores/BPStore';
import RolePermissionStore from '../../../stores/RolePermissionStore';
import RoleStore from '../../../stores/RoleStore';
import WorkflowStepRolePermissionStore from '../../../stores/WorkflowStepRolePermissionStore';
import {
  GetFieldRolePermissions,
  GetRolePermissions,
  UpdateFieldRolePermission,
} from '../../../actions/RolePermissionActions';
import { GetWorkflowStepRolePermissions } from '../../../actions/WorkflowStepRolePermissionAction';
import {
  GetRole,
  GetBPList,
  SaveRole,
  DeleteRole,
  GetUsersInRole,
  GetUsersForRole,
  UpdateUsersInRole,
} from '../../../actions/RoleActions';

import UserSelect from '../../../components/UserSelect';

const customHistory = createBrowserHistory();

class RolesView extends React.Component {
  constructor() {
    // state
    super();
    this.state = {
      currentRp: {
        //id: 0,
        roleId: -1,
        businessProcessId: false,
        canEditProps: false,
        canEditTemplate: false,
        canDelete: false,
        canImageReview: false,
        canApprove: false,
        canEdit: false,
        canAddNote: false,
        canViewActiveDoc: false,
        canViewConf: false,
        canUpload: false,
        canDownload: false,
        canView: false,
        businessProcessName: '',
      },
      currentView: 'roles',
      newRole: {
        id: 0,
        name: '',
        licenseLevel: 0,
        permissions: [],
      },
      currentRole: {
        id: 0,
        name: '',
        licenseLevel: 0,
        permissions: [],
      },
      bpList: [],
      showAddUser: false,
      addUserDirty: false,
      currentLicenseLevel: '3',
      currentLicenseName: 'Admin',
      rolePermissionList: [],
      selectedBp: 'Select BP',
      roleId: 0,
      displayBPSelectDropdown: false,
      currentRoleId: 0,
      currentLicStyle: 'btn-primary',
      confirmDeleteBox: false,
      wfStepRPList: [],
      allFields: [],
      fieldPermissionsDirty: false,
      currentFieldPermissionSet: [],
      wfStepsDisplay: false,
      allStepsChecked: false,
      users: [],
      allUsers: [],
      currentBp: 0,
      displayUsers: false,
      selectedUser: undefined,
      screenHeight: (window.innerHeight - 140),
      bottomHeight: (window.innerHeight - 230) * 0.4,
      isDirty: false,
    };

    this.componentDidUpdate = this.componentDidUpdate.bind(this);
    this.setLicenseLevel = this.setLicenseLevel.bind(this);
    this.onBPUpdate = this.onBPUpdate.bind(this);
    this.onSaveClick = this.onSaveClick.bind(this);
    //
    this.onRoleUpdate = this.onRoleUpdate.bind(this);
    this.onRolePermissionUpdate = this.onRolePermissionUpdate.bind(this);
    this.onWfStepRolePermissionUpdate =
      this.onWfStepRolePermissionUpdate.bind(this);
    this.onChangeEditPropsPermission =
      this.onChangeEditPropsPermission.bind(this);
    this.onChangeEditTemplatesPermission =
      this.onChangeEditTemplatesPermission.bind(this);
    this.onChangeDeletePermission = this.onChangeDeletePermission.bind(this);
    this.onChangeImageReviewPermission =
      this.onChangeImageReviewPermission.bind(this);
    this.onChangeApprovePermission = this.onChangeApprovePermission.bind(this);
    this.onChangeEditPermission = this.onChangeEditPermission.bind(this);
    this.onChangeAddNotePermission = this.onChangeAddNotePermission.bind(this);
    this.onChangeViewActiveDocPermission =
      this.onChangeViewActiveDocPermission.bind(this);
    this.onChangeViewConfPermission =
      this.onChangeViewConfPermission.bind(this);
    this.onChangeUploadPermission = this.onChangeUploadPermission.bind(this);
    this.onChangeDownloadPermission =
      this.onChangeDownloadPermission.bind(this);
    this.onChangeViewPermission = this.onChangeViewPermission.bind(this);
    this.handleNewBPDropdown = this.handleNewBPDropdown.bind(this);
    this.addNewRPToList = this.addNewRPToList.bind(this);

    this.loadBPFromDB = this.loadBPFromDB.bind(this);
    this.filterBPList = this.filterBPList.bind(this);
    this.onChangeRoleName = this.onChangeRoleName.bind(this);
    this.checkParams = this.checkParams.bind(this);

    this.openDeleteBox = this.openDeleteBox.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.closeWfStepSection = this.closeWfStepSection.bind(this);
    this.deleteRoleConfirmed = this.deleteRoleConfirmed.bind(this);
    this.deleteRolePermission = this.deleteRolePermission.bind(this);
    this.showWorkflowSteps = this.showWorkflowSteps.bind(this);
    this.onFieldPermissionAdd = this.onFieldPermissionAdd.bind(this);
    this.onFieldPermissionDelete = this.onFieldPermissionDelete.bind(this);
    this.updateFieldPermissions = this.updateFieldPermissions.bind(this);
    this.onChangeWfStepRolePermission =
      this.onChangeWfStepRolePermission.bind(this);
    this.onChangeSearchAllStepsPermission =
      this.onChangeSearchAllStepsPermission.bind(this);
    this.onChangeAllWfStepRolePermission =
      this.onChangeAllWfStepRolePermission.bind(this);
    this.onSelectAllAvailable = this.onSelectAllAvailable.bind(this);
    this.onShowUsers = this.onShowUsers.bind(this);
    this.onShowAddUser = this.onShowAddUser.bind(this);
    this.onSelectUser = this.onSelectUser.bind(this);
    this.onAddUser = this.onAddUser.bind(this);
    this.onDeleteUser = this.onDeleteUser.bind(this);
    this.onSaveUsers = this.onSaveUsers.bind(this);
    this.switchBP = this.switchBP.bind(this);
  }

  componentDidMount() {
    console.log('mount');
    RoleStore.addChangeListener(this.onRoleUpdate);
    RolePermissionStore.addChangeListener(this.onRolePermissionUpdate);
    BPStore.addChangeListener(this.onBPUpdate);
    WorkflowStepRolePermissionStore.addChangeListener(
      this.onWfStepRolePermissionUpdate
    );
    this.loadBPFromDB();
    GetBPList();
    document.title = 'TrinDocs - Roles'
  }

  componentWillUnmount() {
    RoleStore.removeChangeListener(this.onRoleUpdate);
    BPStore.removeChangeListener(this.onBPUpdate);
    RolePermissionStore.removeChangeListener(this.onRolePermissionUpdate);
    WorkflowStepRolePermissionStore.removeChangeListener(
      this.onWfStepRolePermissionUpdate
    );
  }
  componentDidUpdate() {
    this.checkParams();
  }

  checkParams() {
    let loc = window.location.pathname;
    let pathParts = loc.split('/');
    console.log('checkParams');
    let roleId = parseInt(pathParts[pathParts.length - 1], 10);
    if (
      this.state.currentRole !== undefined &&
      this.state.currentRole.id !== undefined
    ) {
      if (roleId.toString() !== this.state.currentRole.id.toString()) {
        console.log('loadBPFromDB');
        this.loadBPFromDB();
      } else {
        console.log(this.state.currentRole.id);
      }

    }
  }

  loadBPFromDB() {
    console.log('loadBPFromDB');
    let loc = window.location.pathname;
    let pathParts = loc.split('/');
    let roleId = parseInt(pathParts[pathParts.length - 1], 10);

    console.log(this.state.currentRole.id);
    if (roleId === 0) {
      console.log('role 0');
      console.log(this.state.newRole);
      let newRole = { id: 0,
        name: '',
        licenseLevel: 1,
        permissions: []

      };
      this.setState({
        //currentRole: this.state.newRole,
        currentRole: newRole,
        roleId: roleId,
        rolePermissionList: [],
        wfStepsDisplay: false,
        currentLicenseLevel: 1,
        currentLicenseName: 'Viewer',
        currentLicStyle: 'btn-warning',
        rolePermissionList: newRole.permissions,
      });

      //this.setLicenseLevel(1);
      //GetRole(roleId);
      //GetRolePermissions(roleId);
    } else if (this.state.currentRole.id !== roleId) {
      GetBPList();
      let role = this.state.currentRole;
      role.id = roleId;
      this.setState({
        currentRole: role,
        roleId: roleId,
        wfStepsDisplay: false,

      });
      //this.setState({ wfStepsDisplay: false });
      GetRole(roleId);
      //GetRolePermissions(roleId);
    }
  }

  onBPUpdate() {
    let lastChange = BPStore.getLastChange();
    if (lastChange === actionTypes.BPGetList) {
      let bplist = BPStore.getBPList();
      this.setState({ bpList: bplist });
    }
  }

  onRoleUpdate() {

    let lastChange = RoleStore.getLastChange();
    console.log(lastChange);
    if (lastChange === actionTypes.RolesGetOne) {
      let role = RoleStore.getCurrentRole();
      let name = '';
      let style = '';
      switch (role.licenseLevel) {
        case 4:
          name = 'System Manager';
          style = 'btn-danger';
          break;
        case 3:
          name = 'Admin';
          style = 'btn-success';
          break;
        case 2:
          name = 'Approver';
          style = 'btn-primary';
          break;
        case 1:
          name = 'Viewer';
          style = 'btn-warning';
          break;
      default:
      }
      console.log(role);

      this.setState({rolePermissionList: role.permissions});

      if(role.permissions.length > 0)
      {
        this.setState({currentBp: role.permissions[0].bpId,
          wfStepsDisplay: true,
        allStepsChecked: false,
      wfStepRPList: role.permissions[0].stepPermissions,
      currentFieldPermissionSet: role.permissions[0].groups,
      allFields: role.permissions[0].allFields,
    });
      }else {
        this.setState({currentBp: 0, wfStepsDisplay: false});
      }
      //let currentBp = this.state.currentBp;

      // if (this.state.currentRole.name !== role.name) {
      //   console.log('zero');
      //   currentBp = 0;
      //   if(this.state.rolePermissionList != null && this.state.rolePermissionList.length > 0)
      //   {
      //     console.log('permissionList');
      //     console.log(this.state.rolePermissionList[0]);
      //    // this.showWorkflowSteps(this.state.rolePermissionList[0]);
      //     currentBp = this.state.rolePermissionList[0].id;

      //   }
      // }

      this.setState({
        currentRole: role,
        currentLicenseLevel: role.licenseLevel,
        currentLicenseName: name,
        currentLicStyle: style,
       // currentBp: currentBp,
      }, () => {
        // if(this.state.rolePermissionList != null && this.state.rolePermissionList.length > 0)
        // {
        //   this.showWorkflowSteps(this.state.rolePermissionList[0]);
        // }
      });
      //console.log(currentBp);
    }

    if(lastChange === actionTypes.RoleBPGetList)
    {
      let allBPs = RoleStore.getAllBPs();
      this.setState({bpList: allBPs});
    }

    if (lastChange === actionTypes.RolesUpdateOne) {
      this.setState({isDirty: false});
      let roleId = this.state.currentRp.roleId;
      console.log(roleId);
      console.log(this.state.currentRp);
      let roleId2 = parseInt(this.state.currentRp.roleId, 10);
      if(roleId2 === 0)

      {
        console.log('getRole');
        let newRole = RoleStore.getCurrentRole();
        console.log(newRole);
        customHistory.push(`/Admin/RolesView/${newRole.roleId}`);
        GetRole(newRole.roleId);
      }
      // this might be an update, might be a create
      // let role = RoleStore.getCurrentRole();
      // GetRole(role.roleId);
      // //GetRolePermissions(role.roleId);
      // GetBPList();
      // customHistory.push(`/Admin/RolesView/${role.roleId}`);
      // this.setState({
      //   currentRole: role,
      //   currentLicenseLevel: role.licenseLevel,
      // });
    }

    if (lastChange === actionTypes.RolesDeleteOne) {
      let link = document.getElementById('rolesHomeLink');
      link.click();
    }

    if (lastChange === actionTypes.RolesGetUsers) {
      let users = RoleStore.getUsersInRole();
      this.setState({ users: users });
    }
    if (lastChange === actionTypes.RolesUsersFor) {
      let users = RoleStore.getUsersForRole();
      this.setState({ allUsers: users });
    }
    if (lastChange === actionTypes.RolesUpdateUsers) {
      this.setState({
        showAddUser: false,
        addUserDirty: false,
        displayUsers: false,
      });
    }
  }

  switchBP(bp) {
    console.log(bp.groups);
    this.setState({currentBp: bp.bpId});
    this.setState({wfStepsDisplay: true,
        allStepsChecked: false,
      wfStepRPList: bp.stepPermissions,
      currentFieldPermissionSet: bp.groups,

      allFields: bp.allFields,
     });
  }

  onRolePermissionUpdate() {
    let lastChange = RolePermissionStore.getLastChange();
    switch (lastChange) {

      case actionTypes.RolePermissionGetAll:
        console.log('RolePermissionGetAll');
        var bplist = RolePermissionStore.getRolePermissionList();
        this.setState({ rolePermissionList: bplist });
        // if (bplist.length > 0 && this.state.currentBp === 0) {
        //   this.showWorkflowSteps(bplist[0]);
        // }
        break;
      case actionTypes.RolePermissionGetFields:

        var fieldPermission = RolePermissionStore.getFieldRolePermissions();
        console.log(fieldPermission);
        this.setState({
          allFields: fieldPermission.allFields,
          currentFieldPermissionSet: fieldPermission.groups,
        });
        break;

      default:
        break;
    }
  }

  onWfStepRolePermissionUpdate() {
    let lastChange = WorkflowStepRolePermissionStore.getLastChange();
    if (lastChange === actionTypes.WorkflowStepRolePermissionGetList) {
      var bplist = WorkflowStepRolePermissionStore.getWfStepRpList();
      this.setState({ wfStepRPList: bplist });
    }
  }

  onShowUsers() {
    let loc = window.location.pathname;
    let pathParts = loc.split('/');
    let roleId = parseInt(pathParts[pathParts.length - 1], 10);
    GetUsersInRole(roleId);
    GetUsersForRole(roleId);

    this.setState({ displayUsers: true });
  }

  onSaveClick() {
    SaveRole(
      this.state.currentRole,

    );
  }

  openDeleteBox() {
    this.setState({ confirmDeleteBox: true });
  }

  closeModal() {
    this.setState({ confirmDeleteBox: false, displayUsers: false });
  }

  closeWfStepSection() {
    this.setState({ wfStepsDisplay: false });
  }

  deleteRoleConfirmed() {
    DeleteRole(this.state.currentRole.id);
    this.setState({ confirmDeleteBox: false });
    //window.location.reload();
  }

  deleteRolePermission(rp) {
    let rpList = this.state.rolePermissionList;
    let role = this.state.currentRole;
    let permission = role.permissions.find(p => p.bpId === rp.bpId);
    console.log(permission);
    if(permission)
    {
      let idx = role.permissions.indexOf(permission);
      console.log(idx);
      role.permissions.splice(idx,1);
    }
    // for (let i = 0; i < this.state.rolePermissionList.length; i++) {
    //   if (this.state.rolePermissionList[i] === rp) {
    //     rpList = this.state.rolePermissionList.filter(
    //       (data) => data.id !== rp.id
    //     );
    //   }
    // }
    this.setState({ rolePermissionList: role.permissions });
  }

  showWorkflowSteps(rp) {
    //GetWorkflowStepRolePermissions(rp);
    GetFieldRolePermissions(rp.businessProcessId, rp.roleId);
    this.setState({
      wfStepsDisplay: true,
      currentBp: rp.businessProcessId,
      fieldPermissionsDirty: false,
    });
  }

  setLicenseLevel(levelId) {
    let name = '';
    let style = '';
    switch (levelId) {

      case 3:
        name = 'Admin';
        style = 'btn-success';
        break;
      case 2:
        name = 'Approver';
        style = 'btn-primary';
        break;
      case 1:
        name = 'Viewer';
        style = 'btn-warning';
        break;
      default:
    }
    let role = this.state.currentRole;
    role.licenseLevel = levelId;
    this.setState({isDirty: true});
    this.state.rolePermissionList.forEach((rp) => {
      switch (levelId) {
        case 4:
          break;
        case 3:
          name = 'Admin';
          break;
        case 2:
          name = 'Approver';
          rp.canEditProps = false;
          rp.canEditTemplate = false;
          rp.canDelete = false;
          rp.canImageReview = false;
          break;
        case 1:
          rp.canEditProps = false;
          rp.canEditTemplate = false;
          rp.canDelete = false;
          rp.canImageReview = false;
          rp.canApprove = false;
          rp.canEdit = false;
          name = 'Viewer';
          style = 'btn-warning';
          break;
        default:
      }
      return 0;
    });

    this.setState({ currentLicenseLevel: levelId });
    this.setState({ currentLicenseName: name, currentLicStyle: style });
    this.setState({ currentRole: role });
  }

  calcLicenseStyle(licLevel) {
    if (licLevel === 3) {
      return 'btn-success';
    }
    if (licLevel === 2) {
      return 'btn-primary';
    }
    if (licLevel === 1) {
      return 'btn-warning';
    }
    return 'btn-success';
  }

  handleNewBPDropdown(e) {
    // add a new role permission to list for selected bp
    let bpId = parseInt(e.target.value);
    let bp = this.state.bpList.find((f) => f.bpId === bpId);
    if (bp) {
      let newRolePermission = {
        id: 0,
        roleId: this.state.roleId,
        bpId: bp.bpId,
        canEditProps: false,
        canEditTemplate: false,
        canDelete: false,
        canImageReview: false,
        canApprove: false,
        canEdit: false,
        canAddNote: false,
        canViewActiveDoc: false,
        canViewConf: false,
        canUpload: false,
        canDownload: false,
        canView: false,
        bpName: bp.bpName,
        currentBp: 0,
      };
      console.log(newRolePermission);
      console.log(bp);
      this.setState({ selectedBp: bp.bpName });
      this.setState({ currentRp: newRolePermission });
    }
  }

  addNewRPToList() {
    let rpList = this.state.rolePermissionList;
    console.log(this.state.currentRp);
    console.log(this.state.bpList);
    var chosenBp = this.state.bpList.find(bp => bp.bpId === this.state.currentRp.bpId);
    if(chosenBp){
      var newBp = {
        allFields: [...chosenBp.allFields],
        bpId: chosenBp.bpId,
        bpName: chosenBp.bpName,
        bpType: null,
        canAddNotes: false,
        canApprove: false,
        canDelete: false,
        canDownload: false,
        canEditFields: false,
        canImageReview: false,
        canMoveAssign: false,
        canSearchAllSteps: false,
        canTemplate: false,
        canUpload: false,
        canView: false,
        canViewActiveDocs: false,
        ftpId: null,
        groups: [],
        stepPermissions: [...chosenBp.steps]
      };
      rpList.push(newBp);
      console.log(newBp);
    }
    //rpList.push(this.state.currentRp);
    this.setState({ rolePermissionList: rpList });
    this.setState({ displayBPSelectDropdown: false, isDirty: true });
    //this.showWorkflowSteps(this.state.currentRp);
  }

  filterBPList() {
    // this is where we filter the list of BPs to only show the ones
    // that do NOT already have a Role Permission shown in the list for this role
    let bpListCopy = this.state.bpList.slice();
    this.state.bpList.forEach((element) => {
      // go through all BPs and try to find a role permission with bpID
      if (
        this.state.rolePermissionList.filter(
          (e) => e.businessProcessId === element.bpId
        ).length > 0
      ) {
        let permissionMaybe = bpListCopy.filter((e) => e.bpId === element.bpId);
        const index = bpListCopy.indexOf(permissionMaybe[0]);
        if (index > -1) {
          // if you find it, remove it from list
          bpListCopy.splice(index, 1); // 2nd parameter means remove one item only
        }
      }
    });

    if (bpListCopy.length > 0) {
      let bp = bpListCopy[0];
      console.log(bp);
      // default the selection to the first option
      let newRolePermission = {
        id: 0,
        bpId: bp.bpId,
        bpName: bp.bpName,
        roleId: this.state.roleId,
        businessProcessId: bp.bpId,
        canEditProps: false,
        canEditTemplate: false,
        canDelete: false,
        canImageReview: false,
        canApprove: false,
        canEdit: false,
        canAddNote: false,
        canViewActiveDoc: false,
        canViewConf: false,
        canUpload: false,
        canDownload: false,
        canView: false,
        groups: [],
        stepPermissions: [...bp.steps]
      };
      this.setState({ currentRp: newRolePermission });
    }

    // will only display list of bps that don't have a permission displayed
    this.setState({ bpList: bpListCopy });
    this.setState({ displayBPSelectDropdown: true });
  }

  onSelectAllAvailable(rp) {
    if (rp.allChecked === true) {
      rp.allChecked = false;
      rp.canMoveAssign = false;
      rp.canEditProps = false;
      rp.canTemplate = false;
      rp.canDelete = false;
      rp.canImageReview = false;
      rp.canApprove = false;
      rp.canEditFields = false;
      rp.canAddNotes = false;
      rp.canViewActiveDocs = false;
      rp.canViewConf = false;
      rp.canUpload = false;
      rp.canDownload = false;
      rp.canView = false;
      rp.canSearchAllSteps =false;
      this.setState({ rolePermissionList: this.state.rolePermissionList });
      this.setState({isDirty: true});
    } else {
      rp.allChecked = true;
      if (this.state.currentLicenseLevel >= 3) {
        rp.canEditProps = true;
        rp.canTemplate = true;
        rp.canDelete = true;
        rp.canImageReview = true;
        rp.canMoveAssign = true;
      }
      if (this.state.currentLicenseLevel >= 2) {
        rp.canApprove = true;
        rp.canEditFields = true;
      }
      if (this.state.currentLicenseLevel >= 1) {
        rp.canAddNotes = true;
        rp.canViewActiveDocs = true;
        rp.canViewConf = true;
        rp.canUpload = true;
        rp.canDownload = true;
        rp.canView = true;
        rp.canSearchAllSteps =true;
      }

      this.setState({ rolePermissionList: this.state.rolePermissionList });
      this.setState({isDirty: true});
    }
  }

  onChangeEditPropsPermission(e) {
    if (e.canMoveAssign === true) {
      e.canMoveAssign = false;
      this.setState({ rolePermissionList: this.state.rolePermissionList });
    } else {
      e.canMoveAssign = true;
      this.setState({ rolePermissionList: this.state.rolePermissionList });
    }
    this.setState({isDirty: true});
  }
  onChangeEditTemplatesPermission(e) {
    if (e.canTemplate === true) {
      e.canTemplate = false;
      this.setState({ rolePermissionList: this.state.rolePermissionList });
    } else {
      e.canTemplate = true;
      this.setState({ rolePermissionList: this.state.rolePermissionList });
    }
    this.setState({isDirty: true});
  }
  onChangeDeletePermission(e) {
    if (e.canDelete === true) {
      e.canDelete = false;
      this.setState({ rolePermissionList: this.state.rolePermissionList });
    } else {
      e.canDelete = true;
      this.setState({ rolePermissionList: this.state.rolePermissionList });
    }
    this.setState({isDirty: true});
  }
  onChangeImageReviewPermission(e) {
    if (e.canImageReview === true) {
      e.canImageReview = false;
      this.setState({ rolePermissionList: this.state.rolePermissionList });
    } else {
      e.canImageReview = true;
      this.setState({ rolePermissionList: this.state.rolePermissionList });
    }
    this.setState({isDirty: true});
  }
  onChangeApprovePermission(e) {
    if (e.canApprove === true) {
      e.canApprove = false;
      this.setState({ rolePermissionList: this.state.rolePermissionList });
    } else {
      e.canApprove = true;
      this.setState({ rolePermissionList: this.state.rolePermissionList });
    }
    this.setState({isDirty: true});
  }
  onChangeEditPermission(e) {
    if (e.canEditFields === true) {
      e.canEditFields = false;
      this.setState({ rolePermissionList: this.state.rolePermissionList });
    } else {
      e.canEditFields = true;
      this.setState({ rolePermissionList: this.state.rolePermissionList });
    }
    this.setState({isDirty: true});
  }
  onChangeAddNotePermission(e) {
    if (e.canAddNotes === true) {
      e.canAddNotes = false;
      this.setState({ rolePermissionList: this.state.rolePermissionList }, () => {console.log(this.state.currentRole); });
    } else {
      e.canAddNotes = true;
      this.setState({ rolePermissionList: this.state.rolePermissionList }, () => {console.log(this.state.currentRole); });
    }
    this.setState({isDirty: true});
  }
  onChangeViewActiveDocPermission(e) {
    if (e.canViewActiveDocs === true) {
      e.canViewActiveDocs = false;
      this.setState({ rolePermissionList: this.state.rolePermissionList });
    } else {
      e.canViewActiveDocs = true;
      this.setState({ rolePermissionList: this.state.rolePermissionList });
    }
    this.setState({isDirty: true});
  }
  onChangeViewConfPermission(e) {
    if (e.canViewConf === true) {
      e.canViewConf = false;
      this.setState({ rolePermissionList: this.state.rolePermissionList });
    } else {
      e.canViewConf = true;
      this.setState({ rolePermissionList: this.state.rolePermissionList });
    }
    this.setState({isDirty: true});
  }
  onChangeUploadPermission(e) {
    if (e.canUpload === true) {
      e.canUpload = false;
      this.setState({ rolePermissionList: this.state.rolePermissionList });
    } else {
      e.canUpload = true;
      this.setState({ rolePermissionList: this.state.rolePermissionList });
    }
    this.setState({isDirty: true});
  }
  onChangeDownloadPermission(e) {
    if (e.canDownload === true) {
      e.canDownload = false;
      this.setState({ rolePermissionList: this.state.rolePermissionList });
    } else {
      e.canDownload = true;
      this.setState({ rolePermissionList: this.state.rolePermissionList });
    }
    this.setState({isDirty: true});
  }
  onChangeViewPermission(e) {
    if (e.canView === true) {
      e.canView = false;
      this.setState({ rolePermissionList: this.state.rolePermissionList });
    } else {
      e.canView = true;
      this.setState({ rolePermissionList: this.state.rolePermissionList });
    }
    this.setState({isDirty: true});
  }

  onChangeSearchAllStepsPermission(e) {
    if (e.canSearchAllSteps === true) {
      e.canSearchAllSteps = false;
      this.setState({ rolePermissionList: this.state.rolePermissionList });
    } else {
      e.canSearchAllSteps = true;
      this.setState({ rolePermissionList: this.state.rolePermissionList });
    }
    this.setState({isDirty: true});
  }

  onChangeRoleName(e) {
    let role = this.state.currentRole;
    role.name = e.target.value;
    this.setState({ currentRole: role });
    this.setState({isDirty: true});
  }

  onChangeWfStepRolePermission(e) {

    let stepList = this.state.wfStepRPList;
    if (e.isPermitted === true) {
      e.isPermitted = false;
      this.setState({ wfStepRPList: stepList });
    } else {
      e.isPermitted = true;
      this.setState({ wfStepRPList: stepList });
    }
    this.setState({isDirty: true});
  }

  onChangeAllWfStepRolePermission() {
    if (this.state.allStepsChecked === true) {
      this.state.wfStepRPList.forEach((step) => {
        step.isPermitted = false;
      });
      this.setState({
        wfStepRPList: this.state.wfStepRPList,
        allStepsChecked: false,
      });
    } else {
      this.state.wfStepRPList.forEach((step) => {
        step.isPermitted = true;
      });
      this.setState({
        wfStepRPList: this.state.wfStepRPList,
        allStepsChecked: true,
      });
    }
    this.setState({isDirty: true});
  }

  onFieldPermissionAdd() {
    let fieldInput = document.getElementById('fp-fieldSelect');
    let operatorInput = document.getElementById('fp-operator');
    let valInput = document.getElementById('fp-value');
    let groupIdInput = document.getElementById('fp-groupId');

    if (fieldInput.value > 0 && operatorInput.value !== '') {
      let groupId = 1;
      if (groupIdInput.value !== '') groupId = parseInt(groupIdInput.value, 10);

      let fieldPermissions = this.state.currentFieldPermissionSet;
      let permission = fieldPermissions.find((p) => p.groupId === groupId);
      if (permission) {
        // let oldRule = permission.rules.find(
        //   (r) => r.fieldId === parseInt(fieldInput.value, 10)
        // );
        let targetField = this.state.allFields.find(
          (a) => a.fieldId === parseInt(fieldInput.value, 10)
        );
        // if (oldRule) {
        //   oldRule.description = `${targetField.displayName} ${operatorInput.value} ${valInput.value}`;
        //   oldRule.operator = operatorInput.value;
        //   oldRule.value = valInput.value;
        // } else {
        let newRule = {};
        newRule.description = `${targetField.displayName} ${operatorInput.value} ${valInput.value}`;
        newRule.operator = operatorInput.value;
        newRule.fieldId = targetField.fieldId;
        newRule.value = valInput.value;
        permission.rules.push(newRule);
        //}
      } else {
        let targetField = this.state.allFields.find(
          (a) => a.fieldId === parseInt(fieldInput.value, 10)
        );
        let newGroup = { groupId: groupId };
        newGroup.rules = [];
        let newRule = {};
        newRule.description = `${targetField.displayName} ${operatorInput.value} ${valInput.value}`;
        newRule.operator = operatorInput.value;
        newRule.fieldId = targetField.fieldId;
        newRule.value = valInput.value;
        newGroup.rules.push(newRule);
        fieldPermissions.push(newGroup);
      }
      this.setState({
        currentFieldPermissionSet: fieldPermissions,
        fieldPermissionsDirty: true,
        isDirty: true,
      });

      fieldInput.value = '0';
      operatorInput.value = '';
      valInput.value = '';
      groupIdInput = '';
    }
  }

  onFieldPermissionDelete(grpId, fieldId) {
    let fieldPermissions = this.state.currentFieldPermissionSet;

    let group = fieldPermissions.find((g) => g.groupId === grpId);
    let ruleToDelete = group.rules.find((r) => r.fieldId === fieldId);
    if (ruleToDelete) {
      let idx = group.rules.indexOf(ruleToDelete);
      group.rules.splice(idx, 1);
      if (group.rules.length === 0) {
        let idx2 = fieldPermissions.indexOf(group);
        fieldPermissions.splice(idx2, 1);
      }
    }

    this.setState({
      currentFieldPermissionSet: fieldPermissions,
      fieldPermissionsDirty: true,
    });
  }

  updateFieldPermissions() {
    UpdateFieldRolePermission(
      this.state.currentRole.id,
      this.state.currentBp,
      this.state.currentFieldPermissionSet
    );
  }

  onShowAddUser() {
    this.setState({ showAddUser: true });
  }

  onSelectUser(user) {
    this.setState({ selectedUser: user });
  }

  onAddUser() {
    if (this.state.selectedUser !== undefined) {
      let user = this.state.allUsers.find(
        (u) => u.userId === this.state.selectedUser
      );
      if (user) {
        let users = this.state.users;
        let exUser = users.find((u) => u.userId === user.userId);
        if (exUser === undefined) {
          users.push(user);
          this.setState({ users: users, addUserDirty: true });
        }
      }
    }
  }

  onDeleteUser(user) {
    let u2 = this.state.users.find((u) => u.userId === user.userId);
    let idx = this.state.users.indexOf(u2);
    if (idx >= 0) {
      let users = this.state.users;
      users.splice(idx, 1);
      this.setState({ users: users, addUserDirty: true });
    }
  }

  onSaveUsers() {
    console.log(this.state.currentRole);
    UpdateUsersInRole(this.state.currentRole.id, this.state.users);
  }

  render() {
    let listOfBps;
    if (this.state.rolePermissionList !== null) {
      listOfBps = this.state.rolePermissionList.map((rp) => (
        <div key={rp.bpId} className='row'>
          <div className='col-sm-2 ' style={{ fontWeight: '600' }}>
            <span
              className={`${
                this.state.currentBp === rp.bpId
                  ? 'role-permission-selection'
                  : ''
              } `}
              style={{ cursor: 'pointer' }}
              onClick={() => {
                this.switchBP(rp);
              }}
            >
              <FontAwesomeIcon icon={solid('shield-alt')} />{' '}
              {rp.bpName}
            </span>

            <span
              onClick={() => {
                this.deleteRolePermission(rp);
              }}
              style={{
                cursor: 'pointer',
                color: '#C0392B',
                paddingLeft: '10px',
              }}
            >
              <FontAwesomeIcon icon={solid('trash')} />
            </span>
          </div>

          <div className='col rolePermissionCheck'>
            <input
              className='mt-1'
              onChange={() => this.onSelectAllAvailable(rp)}
              checked={rp.allChecked}
              type='checkbox'
              style={{ width: '18px', height: '18px' }}
            ></input>
          </div>

          {/* manager */}

          <div className='col rolePermissionCheck'>
            <input
              className='mt-1'
              onChange={() => this.onChangeEditPropsPermission(rp)}
              checked={rp.canMoveAssign}
              type='checkbox'
              style={{ width: '18px', height: '18px' }}
              disabled={this.state.currentLicenseLevel < 3 ? 'disabled' : ''}
            ></input>
          </div>
          <div
            className='col  rolePermissionCheck'
            style={{ textAlign: 'center' }}
          >
            <input
              onChange={() => this.onChangeEditTemplatesPermission(rp)}
              checked={rp.canTemplate}
              type='checkbox'
              disabled={this.state.currentLicenseLevel < 3 ? 'disabled' : ''}
            ></input>
          </div>
          <div
            className='col  rolePermissionCheck'
            style={{ textAlign: 'center' }}
          >
            <input
              onChange={() => this.onChangeDeletePermission(rp)}
              checked={rp.canDelete}
              type='checkbox'
              disabled={this.state.currentLicenseLevel < 3 ? 'disabled' : ''}
            ></input>
          </div>
          <div
            className='col rolePermissionCheck'
            style={{ textAlign: 'center' }}
          >
            <input
              onChange={() => this.onChangeImageReviewPermission(rp)}
              checked={rp.canImageReview}
              type='checkbox'
              disabled={this.state.currentLicenseLevel < 3 ? 'disabled' : ''}
            ></input>
          </div>

          {/* Approver */}
          <div
            className='col rolePermissionCheck'
            style={{ textAlign: 'center' }}
          >
            <input
              onChange={() => this.onChangeApprovePermission(rp)}
              checked={rp.canApprove}
              type='checkbox'
              disabled={this.state.currentLicenseLevel < 2 ? 'disabled' : ''}
            ></input>
          </div>
          <div
            className='col rolePermissionCheck'
            style={{ textAlign: 'center' }}
          >
            <input
              onChange={() => this.onChangeEditPermission(rp)}
              checked={rp.canEditFields}
              type='checkbox'
              disabled={this.state.currentLicenseLevel < 2 ? 'disabled' : ''}
            ></input>
          </div>

          {/* Viewer */}
          <div className='col rolePermissionCheck'>
            <input
              onChange={() => this.onChangeAddNotePermission(rp)}
              checked={rp.canAddNotes}
              type='checkbox'
              disabled={this.state.currentLicenseLevel < 1 ? 'disabled' : ''}
            ></input>
          </div>
          <div
            className='col rolePermissionCheck'
            style={{ textAlign: 'center' }}
          >
            <input
              onChange={() => this.onChangeViewActiveDocPermission(rp)}
              checked={rp.canViewActiveDocs}
              type='checkbox'
              disabled={this.state.currentLicenseLevel < 1 ? 'disabled' : ''}
            ></input>
          </div>
          {/*<div
            className='col rolePermissionCheck'
            style={{ textAlign: 'center' }}
          >
            <input
              onChange={() => this.onChangeViewConfPermission(rp)}
              checked={rp.canViewConf}
              type='checkbox'
              disabled={this.state.currentLicenseLevel < 1 ? 'disabled' : ''}
            ></input>
            </div>*/}
          <div
            className='col rolePermissionCheck'
            style={{ textAlign: 'center' }}
          >
            <input
              onChange={() => this.onChangeUploadPermission(rp)}
              checked={rp.canUpload}
              type='checkbox'
              disabled={this.state.currentLicenseLevel < 1 ? 'disabled' : ''}
            ></input>
          </div>
          <div
            className='col rolePermissionCheck'
            style={{ textAlign: 'center' }}
          >
            <input
              onChange={() => this.onChangeDownloadPermission(rp)}
              checked={rp.canDownload}
              type='checkbox'
              disabled={this.state.currentLicenseLevel < 1 ? 'disabled' : ''}
            ></input>
          </div>
          <div
            className='col rolePermissionCheck'
            style={{ textAlign: 'center' }}
          >
            <input
              onChange={() => this.onChangeViewPermission(rp)}
              checked={rp.canView}
              type='checkbox'
              disabled={this.state.currentLicenseLevel < 1 ? 'disabled' : ''}
            ></input>
          </div>
          <div
            className='col rolePermissionCheck'
            style={{ textAlign: 'center' }}
          >
            <input
              onChange={() => this.onChangeSearchAllStepsPermission(rp)}
              checked={rp.canSearchAllSteps}
              type='checkbox'
              disabled={this.state.currentLicenseLevel < 1 ? 'disabled' : ''}
            ></input>
          </div>
        </div>
      ));
    } else {
      listOfBps = <div />;
    }

    return (
      <div className='sidebarLayout d-flex flex-row flex-fill'>
        <div className='sidebar-container'>
          <RolesSidebar />
        </div>

        <div className='flex-fill'>
          <div className='d-flex flex-column'>
            <div className='p-2' style={{backgroundColor: 'rgba(0,0,0,0.03)', borderBottom: 'solid 1px #CCC'}}>
              <div style={{ float: 'left', paddingTop: '10px' }}>Roles</div>
              <div
                style={{
                  float: 'left',
                  marginTop: '2px',
                  paddingLeft: '20px',
                }}
              >
              <button
                className='btn btn-primary'
                onClick={this.onShowUsers}
                style={{ marginLeft: '20px' }}
              >
                Users in Role
              </button>
            </div>
            <div style={{ float: 'right', marginTop: '-12px' }}>
              <div className='col'>
                <button
                  className='btn btn-success mt-3'
                  onClick={this.onSaveClick}
                  style={{ marginLeft: '30px' }}
                  disabled={!this.state.isDirty}
                >
                  <FontAwesomeIcon icon={solid('save')} /> Save Changes
                </button>
              </div>
            </div>
          </div>
          </div>
          <div className='flex-fill' style={{backgroundColor: '#FFF', height: `${this.state.screenHeight}px`, overflowY: 'auto'}}>



            {this.state.currentView === 'roles' && (
              <div id='userRoles' className='card m-3'>
                <div className='card-header'>
                  <div>Business Processes</div>


                </div>
                <div className='card-body'  >
                  <div>
                    <div className='form-group'>
                      <label htmlFor='txtUserName'>Name</label>
                      <input
                        type='text'
                        className={`form-control`}
                        id='txtUserName'
                        value={this.state.currentRole.name}
                        onChange={this.onChangeRoleName}
                        placeholder='Name'
                      />
                    </div>
                  </div>

                  <div className='row'>
                    <div className='col-sm-2'>
                      <div className='form-group'>
                        <label htmlFor='txtUserName'>License Level</label>
                        <Dropdown>
                          <Dropdown.Toggle
                            id='ddLicenseLevel'
                            className={this.state.currentLicStyle}
                          >
                            {this.state.currentLicenseName}
                          </Dropdown.Toggle>

                          <Dropdown.Menu>
                            <Dropdown.Item
                              onClick={() => this.setLicenseLevel(1)}
                            >
                              Viewer
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={() => this.setLicenseLevel(2)}
                            >
                              Approver
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={() => this.setLicenseLevel(3)}
                            >
                              Admin
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                      <span className='td-appLink' onClick={this.onShowUsers}>
                        Manage Users
                      </span>
                    </div>
                    <div
                      className='p-2'
                      style={{
                        //backgroundColor: 'rgb(143,188,143)',
                        textAlign: 'center',
                        verticalAlign: 'middle',
                        display: 'table',
                        width: '45px',
                        maxWidth: '45px',
                        borderTop: 'solid 1px #777',
                        borderBottom: 'solid 1px #777',
                        borderLeft: 'solid 1px #777',
                      }}
                    >
                      <span
                        style={{
                          writingMode: 'vertical-rl',
                          transform: 'scale(-1)',
                          display: 'table-cell',
                          verticalAlign: 'middle',
                          fontSize: '14pt',
                        }}
                      >
                        Select All
                      </span>
                    </div>
                    <div
                      className='p-2 rolePermissionLabel'
                      style={{
                        backgroundColor: 'rgb(143,188,143)',
                      }}
                    >
                      <span
                        style={{
                          writingMode: 'vertical-rl',
                          transform: 'scale(-1)',
                          display: 'table-cell',
                          verticalAlign: 'middle',
                          fontSize: '14pt',
                        }}
                      >
                        Move/Assign
                      </span>
                    </div>
                    <div
                      className='p-2 rolePermissionLabel'
                      style={{
                        backgroundColor: 'rgb(143,188,143)',
                      }}
                    >
                      <span
                        style={{
                          writingMode: 'vertical-rl',
                          transform: 'scale(-1)',
                          display: 'table-cell',
                          verticalAlign: 'middle',
                          maxWidth: '20px',
                          fontSize: '14pt',
                        }}
                      >
                        Edit Templates
                      </span>
                    </div>
                    <div
                      className='p-2 rolePermissionLabel'
                      style={{
                        backgroundColor: 'rgb(143,188,143)',
                      }}
                    >
                      <span
                        style={{
                          writingMode: 'vertical-rl',
                          transform: 'scale(-1)',
                          display: 'table-cell',
                          verticalAlign: 'middle',
                          maxWidth: '20px',
                          fontSize: '14pt',
                        }}
                      >
                        Delete Documents
                      </span>
                    </div>
                    <div
                      className='p-2 rolePermissionLabel'
                      style={{
                        backgroundColor: 'rgb(143,188,143)',
                        textAlign: 'center',
                        verticalAlign: 'middle',
                        display: 'table',
                        width: '45px',
                        maxWidth: '45px',
                      }}
                    >
                      <span
                        style={{
                          writingMode: 'vertical-rl',
                          transform: 'scale(-1)',
                          display: 'table-cell',
                          verticalAlign: 'middle',
                          fontSize: '14pt',
                        }}
                      >
                        Image Review
                      </span>
                    </div>
                    <div
                      className='p-2 rolePermissionLabel'
                      style={{
                        backgroundColor: 'rgb(30, 144, 255)',
                        textAlign: 'center',
                        verticalAlign: 'middle',
                        display: 'table',
                        width: '45px',
                        maxWidth: '45px',
                      }}
                    >
                      <span
                        style={{
                          writingMode: 'vertical-rl',
                          transform: 'scale(-1)',
                          display: 'table-cell',
                          verticalAlign: 'middle',
                          fontSize: '14pt',
                        }}
                      >
                        Approve
                      </span>
                    </div>
                    <div
                      className='p-2 rolePermissionLabel'
                      style={{
                        backgroundColor: 'rgb(30, 144, 255)',
                        textAlign: 'center',
                        verticalAlign: 'middle',
                        display: 'table',
                        width: '45px',
                        maxWidth: '45px',
                      }}
                    >
                      <span
                        style={{
                          writingMode: 'vertical-rl',
                          transform: 'scale(-1)',
                          display: 'table-cell',
                          verticalAlign: 'middle',
                          fontSize: '14pt',
                        }}
                      >
                        Edit
                      </span>
                    </div>
                    <div
                      className='p-2 rolePermissionLabel'
                      style={{
                        backgroundColor: 'rgb(250, 250, 210)',
                        textAlign: 'center',
                        verticalAlign: 'middle',
                        display: 'table',
                        width: '45px',
                        maxWidth: '45px',
                      }}
                    >
                      <span
                        style={{
                          writingMode: 'vertical-rl',
                          transform: 'scale(-1)',
                          display: 'table-cell',
                          verticalAlign: 'middle',
                          fontSize: '14pt',
                        }}
                      >
                        Add Notes
                      </span>
                    </div>
                    <div
                      className='p-2 rolePermissionLabel'
                      style={{
                        backgroundColor: 'rgb(250, 250, 210)',
                        textAlign: 'center',
                        verticalAlign: 'middle',
                        display: 'table',
                        width: '45px',
                        maxWidth: '45px',
                      }}
                    >
                      <span
                        style={{
                          writingMode: 'vertical-rl',
                          transform: 'scale(-1)',
                          display: 'table-cell',
                          verticalAlign: 'middle',
                          fontSize: '14pt',
                        }}
                      >
                        View in Active Docs
                      </span>
                    </div>
                    {/*<div
                      className='col p-2 rolePermissionLabel'
                      style={{
                        backgroundColor: 'rgb(250, 250, 210)',
                        textAlign: 'center',
                        verticalAlign: 'middle',
                        display: 'table',
                        maxWidth: '20px',
                      }}
                    >
                      <span
                        style={{
                          writingMode: 'vertical-rl',
                          transform: 'scale(-1)',
                          display: 'table-cell',
                          verticalAlign: 'middle',
                          fontSize: '14pt',
                        }}
                      >
                        View Confidential
                      </span>
                      </div>*/}
                    <div
                      className='p-2 rolePermissionLabel'
                      style={{
                        backgroundColor: 'rgb(250, 250, 210)',
                        textAlign: 'center',
                        verticalAlign: 'middle',
                        display: 'table',
                        width: '45px',
                        maxWidth: '45px',
                      }}
                    >
                      <span
                        style={{
                          writingMode: 'vertical-rl',
                          transform: 'scale(-1)',
                          display: 'table-cell',
                          verticalAlign: 'middle',
                          fontSize: '14pt',
                        }}
                      >
                        Web Upload
                      </span>
                    </div>
                    <div
                      className='p-2 rolePermissionLabel'
                      style={{
                        backgroundColor: 'rgb(250, 250, 210)',
                        textAlign: 'center',
                        verticalAlign: 'middle',
                        display: 'table',
                        width: '45px',
                        maxWidth: '45px',
                      }}
                    >
                      <span
                        style={{
                          writingMode: 'vertical-rl',
                          transform: 'scale(-1)',
                          display: 'table-cell',
                          verticalAlign: 'middle',
                          fontSize: '14pt',
                        }}
                      >
                        Download
                      </span>
                    </div>
                    <div
                      className='p-2 rolePermissionLabel'
                      style={{
                        backgroundColor: 'rgb(250, 250, 210)',
                        textAlign: 'center',
                        verticalAlign: 'middle',
                        display: 'table',
                        width: '45px',
                        maxWidth: '45px',
                      }}
                    >
                      <span
                        style={{
                          writingMode: 'vertical-rl',
                          transform: 'scale(-1)',
                          display: 'table-cell',
                          verticalAlign: 'middle',
                          fontSize: '14pt',
                        }}
                      >
                        View
                      </span>
                    </div>
                    <div
                      className='p-2 rolePermissionLabel'
                      style={{
                        backgroundColor: 'rgb(250, 250, 210)',
                        textAlign: 'center',
                        verticalAlign: 'middle',
                        display: 'table',
                        width: '45px',
                        maxWidth: '45px',
                      }}
                    >
                      <span
                        style={{
                          writingMode: 'vertical-rl',
                          transform: 'scale(-1)',
                          display: 'table-cell',
                          verticalAlign: 'middle',
                          fontSize: '14pt',
                        }}
                      >
                        Search all Steps
                      </span>
                    </div>
                  </div>
                  {listOfBps}
                  <div className='pt-4'>
                    {!this.state.displayBPSelectDropdown && (
                      <button
                        className='btn btn-success'
                        onClick={this.filterBPList}
                      >
                        + Add Business Process
                      </button>
                    )}

                    {this.state.displayBPSelectDropdown && (
                      <div className='form-group'>
                        <div style={{ float: 'left' }} className='me-2'>
                          <select
                            className='form-select me-2'
                            value={this.state.currentRp.businessProcessId}
                            onChange={this.handleNewBPDropdown}
                          >
                            {this.state.bpList.map((bp) => (
                              <option key={bp.bpId} value={bp.bpId}>
                                {bp.bpName}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div>
                          <button
                            className='btn btn-success'
                            onClick={this.addNewRPToList}
                          >
                            <FontAwesomeIcon icon={solid('plus')} /> Add
                          </button>
                        </div>
                      </div>
                    )}

                    {true && (
                      <button
                        className='btn btn-danger'
                        style={{ float: 'right' }}
                        onClick={this.openDeleteBox}
                      >
                        Delete
                      </button>
                    )}
                  </div>
                </div>
              </div>
            )}

            {this.state.wfStepsDisplay && (
              <div className='row gx-0'>
                <div className='col-6'>
                  <div id='wfSteps' className='card m-3'>
                    <div className='card-header'>
                      <input
                        className='form-check-input'
                        type='checkbox'
                        checked={this.state.allStepsChecked}
                        onChange={() => this.onChangeAllWfStepRolePermission()}
                      />{' '}
                      Step Permission
                      <div style={{ float: 'right' }}>
                        <span onClick={this.closeWfStepSection}>
                          <FontAwesomeIcon icon={solid('xmark')} />
                        </span>
                      </div>
                    </div>
                    <div className='card-body ps-4' >
                      <div className='form-group'>
                        {this.state.wfStepRPList.map((wfStepRp) => (
                          <div key={wfStepRp.stepId} className='row mb-3'>
                            <div className='form-check'>
                              <label className='form-check-label'>
                                {wfStepRp.stepName}
                              </label>
                              <input
                                className='form-check-input'
                                onChange={() =>
                                  this.onChangeWfStepRolePermission(wfStepRp)
                                }
                                checked={wfStepRp.isPermitted}
                                type='checkbox'
                                style={{ width: '18px', height: '18px' }}
                              />
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>

                <div className='col-6'>
                  <div id='fieldPermission' className='card m-3'>
                    <div className='card-header'>Field Permissions</div>
                    <div className='card-body ps-4' >
                      <div className='row'>
                        <div className='col-6'>
                          <label className='form-label'>Field</label>
                          <select id='fp-fieldSelect' className='form-select'>
                            <option value='0'></option>
                            {this.state.allFields.map((field) => (
                              <option key={field.fieldId} value={field.fieldId}>
                                {field.displayName}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div className='col-6'>
                          <label className='form-label'>Operator</label>
                          <select id='fp-operator' className='form-select'>
                            <option></option>
                            <option>=</option>
                            <option>{'>'}</option>
                            <option>{'<'}</option>
                            <option>{'>='}</option>
                            <option>{'<='}</option>
                            <option>{'≠'}</option>
                          </select>
                        </div>
                      </div>
                      <div className='row mt-2'>
                        <div className='col-6'>
                          <label className='form-label'>Value</label>
                          <input id='fp-value' className='form-control'></input>
                        </div>
                        <div className='col-6'>
                          <label className='form-label'>Group</label>
                          <input
                            id='fp-groupId'
                            type='number'
                            className='form-control'
                          ></input>
                        </div>
                      </div>
                      <button
                        onClick={this.onFieldPermissionAdd}
                        className='btn btn-success mt-2'
                      >
                        Add
                      </button>
                      <hr />
                      {this.state.currentFieldPermissionSet.map((group) => (
                        <div key={group.groupId} className='mt-3'>
                          {group.groupId > 1 && (
                            <div
                              style={{
                                fontWeight: '600',
                                marginLeft: '40px',
                                marginBottom: '8px',
                                fontStyle: 'italic',
                              }}
                            >
                              or
                            </div>
                          )}
                          <div className='card'>
                            <div className='card-header card-header-sm'>
                              Group {group.groupId}
                            </div>
                            <div className='card-body'>
                              {group.rules.map((rule) => (
                                <div key={rule.fieldId} className='mt-1'>
                                  <span>{rule.description}</span>
                                  <button
                                    className='btn btn-danger btn-sm ms-3'
                                    onClick={() =>
                                      this.onFieldPermissionDelete(
                                        group.groupId,
                                        rule.fieldId
                                      )
                                    }
                                  >
                                    x
                                  </button>
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>

              </div>
            )}
          </div>

          {this.state.confirmDeleteBox && (
            <div className='td-dialogue-container'>
              <div className='td-dialogue card'>
                <div className='card-header'>
                  Confirm Delete
                  <i onClick={this.closeModal} style={{ paddingLeft: '18px' }}>
                    <FontAwesomeIcon icon={solid('xmark')} />
                  </i>
                </div>
                <div className='card-body'>
                  <button
                    className='btn btn-danger'
                    onClick={this.deleteRoleConfirmed}
                  >
                    Delete Role
                  </button>
                </div>
              </div>
            </div>
          )}

          {this.state.displayUsers && (
            <div className='td-dialogue-container'>
              <div className='td-dialogue card'>
                <div className='card-header'>
                  Users in Role
                  <i
                    onClick={this.closeModal}
                    style={{ float: 'right', cursor: 'pointer' }}
                  >
                    <FontAwesomeIcon icon={solid('xmark')} />
                  </i>
                </div>
                {this.state.users.length >= 0 && (
                  <div className='card-body' style={{ overflow: 'auto' }}>
                    <div style={{ maxHeight: '300px', overflow: 'auto' }}>


                      {this.state.addUserDirty && (
                        <button
                          onClick={this.onSaveUsers}
                          className='btn btn-success'
                        >
                          Save Changes
                        </button>
                      )}
                      <table id='usersInRole' className='wf-StepTable'>
                        <thead>
                          <tr>
                            <th style={{ paddingRight: '15px' }}>First Name</th>
                            <th style={{ paddingRight: '15px' }}>Last Name</th>
                            <th style={{ paddingRight: '15px' }}>User Name</th>
                            <th style={{ paddingRight: '15px' }}>Email</th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.users.map((user) => (
                            <tr key={user.id} className='mt-1'>
                              <td>{user.firstName}</td>
                              <td>{user.lastName}</td>
                              <td>{user.userName}</td>
                              <td>{user.emailAddress}</td>
                              <td>
                                <button
                                  onClick={() => this.onDeleteUser(user)}
                                  className='btn btn-danger btn-sm'
                                >
                                  <FontAwesomeIcon icon={solid('times')} />
                                </button>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                    {!this.state.showAddUser && (
                      <button
                        onClick={this.onShowAddUser}
                        className='btn btn-success btn-sm mt-2'
                      >
                        Add Users
                      </button>
                    )}
                    {this.state.showAddUser && (
                      <div className='row mt-2'>
                        <div
                          className='col-6'
                          style={{ marginBottom: '200px' }}
                        >
                          <UserSelect
                            id='role-addUser-ac'
                            value={this.state.selectedUser}
                            allowedValues={this.state.allUsers}
                            valueField='userId'
                            displayField='userName'
                            onChange={this.onSelectUser}
                          />
                        </div>

                        <div className='col-6'>
                          <button
                            onClick={this.onAddUser}
                            className='btn btn-success'
                          >
                            Add
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                )}

              </div>
            </div>
          )}
        </div>
        <Link id='rolesHomeLink' to='/Admin/Roles' style={{ display: 'none' }}>
          HiddenLink
        </Link>
      </div>
    );
  }
}

export default RolesView;
