// @flow
import * as React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';

import ConnectionStore from '../../../../stores/ConnectionStore';
import { GetConnectionList } from '../../../../actions/ConnectionActions';

class ConnectionSidebar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      connectionList: [],
      connectionsLoaded: false,
    };

    this.onReceiveConnectionChange = this.onReceiveConnectionChange.bind(this);
  }

  componentDidMount() {
    ConnectionStore.addChangeListener(this.onReceiveConnectionChange);
    GetConnectionList();
  }

  componentWillUnmount() {
    ConnectionStore.removeChangeListener(this.onReceiveConnectionChange);
  }

  onReceiveConnectionChange() {
    let lastChange = ConnectionStore.lastChange();
    if (lastChange === 'ConnectionList') {
      let connections = ConnectionStore.getConnections();
      this.setState({
        connectionList: connections,
        connectionsLoaded: true,
      });
    }
  }

  render() {
    let listOfConnections;
    if (this.state.connectionList !== undefined) {
      listOfConnections = this.state.connectionList.map((connection) => (
        <div style={{ marginBottom: '8px' }} key={connection.id}>
          <Link
            className='sidebar-link'
            to={'/Admin/ConnectionView/' + connection.id}
            style={{ cursor: 'Pointer', wordWrap: 'break-word' }}
          >
            <FontAwesomeIcon style={{ color: '#86AFC0' }} icon={solid('database')} />{' '}
            {connection.name}
          </Link>
        </div>
      ));
    } else {
      listOfConnections = <div>No connections defined</div>;
    }
    return (
      <div
        className='d-flex flex-column p-3 text-white bg-dark'
        style={{ height: '100%', width: '245px' }}
      >
        <Link
          to='/Admin/Connection'
          className='d-flex align-items-center mb-3 mb-md-0 me-md-auto text-white text-decoration-none'
        >
          <span className='fs-4'>
            <FontAwesomeIcon style={{ color: '#FFCA48' }} icon={solid('database')} />
            {'   '}
            Connections
          </span>
        </Link>
        <hr />

        <div style={{ maxHeight: '50%', overflow: 'auto' }}>
          {listOfConnections}
        </div>

        <hr />
        <Link className='sidebar-link' to='/Admin/ConnectionView/0'>
          New Connection
        </Link>
      </div>
    );
  }
}

export default ConnectionSidebar;
