import * as React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';

import RoleStore from '../../../stores/RoleStore';
import { GetRoleList } from '../../../actions/RoleActions';
import actionTypes from '../../../actions/ActionTypes';

class RolesSidebar extends React.Component {
  constructor() {
    super();
    this.state = {
      roleList: [],
      searchText: '',
      filteredRoleList: [],
    };

    this.onReceiveRoleChange = this.onReceiveRoleChange.bind(this);
    this.onSearchChange = this.onSearchChange.bind(this);
  }

  componentDidMount() {
    RoleStore.addChangeListener(this.onReceiveRoleChange);
    GetRoleList();
  }

  componentWillUnmount() {
    RoleStore.removeChangeListener(this.onReceiveRoleChange);
  }

  onReceiveRoleChange() {
    let lastChange = RoleStore.getLastChange();
    if (lastChange === actionTypes.RolesGetRoles) {
      let roles = RoleStore.getRoleList();

      this.setState({
        roleList: roles,
        searchText: '',
        filteredRoleList: roles,
      });
    }
    if (lastChange === actionTypes.RolesUpdateOne) {
      // let roles = RoleStore.getRoleList();
      GetRoleList();
      // this.setState({
      //   roleList: roles,
      //   searchText: '',
      //   filteredRoleList: roles,
      // });
    }
  }

  onSearchChange(e) {
    let search = e.target.value;
    if (search === '') {
      let newList = [...this.state.roleList];
      this.setState({ searchText: search, filteredRoleList: newList });
    } else {
      let newList = [];
      this.state.roleList.forEach((role) => {
        if (role.name.toLowerCase().includes(search.toLowerCase())) {
          newList.push(role);
        }
      });
      this.setState({ searchText: search, filteredRoleList: newList });
    }
  }

  render() {
    let listOfRoles;
    if (this.state.roleList !== null) {
      listOfRoles = this.state.filteredRoleList.map((role) => (
        <div key={role.id}>
          <Link
            className='sidebar-link'
            to={'/Admin/RolesView/' + role.id}
            //onClick={() => this.handleBPClick(bp)}
            style={{ cursor: 'Pointer', wordWrap: 'break-word' }}
          >
            {role.name}
          </Link>
        </div>
      ));
    } else {
      listOfRoles = <div />;
    }
    return (
      <div
        className='d-flex flex-column p-3 text-white bg-dark'
        style={{ height: '100%', width: '245px' }}
      >
        <Link
          to='/v/administration/roles'
          className='d-flex align-items-center mb-3 mb-md-0 me-md-auto text-white text-decoration-none'
        >
          <span className='fs-4'>
            <FontAwesomeIcon icon={solid('user-friends')} />
            {'   '}
            Roles
          </span>
        </Link>
        <hr />
        <div className='input-group rounded' style={{ marginBottom: '16px' }}>
          <input
            type='search'
            value={this.state.searchText}
            className='form-control'
            placeholder='Search'
            aria-label='Search'
            onChange={this.onSearchChange}
            aria-describedby='search-addon'
          />
          <span className='input-group-text border-0' id='search-addon'>
            <FontAwesomeIcon icon={solid('search')} />
          </span>
        </div>

        <Link className='sidebar-link' to='/Admin/RolesView/0'>
          New Role
        </Link>
        <hr />
        <div>{listOfRoles}</div>

      </div>
    );
  }
}

export default RolesSidebar;
