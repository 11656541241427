import { EventEmitter } from 'events';
import Dispatcher from '../Dispatcher';
import actionTypes from '../actions/ActionTypes';

const CHANGE_EVENT = 'workflowStepRolePermissionChange';
let _userSettingList = [];
let _currentUser = {};
let _lastChange = '';

class UserInRoleStore extends EventEmitter {
  addChangeListener(callback) {
    this.on(CHANGE_EVENT, callback);
  }

  removeChangeListener(callback) {
    this.removeListener(CHANGE_EVENT, callback);
  }

  emitChange() {
    this.emit(CHANGE_EVENT);
  }

  getUserSettingList() {
    return _userSettingList;
  }

  getLastChange() {
    return _lastChange;
  }

  getCurrentUser() {
    return _currentUser;
  }
}

const store = new UserInRoleStore();

Dispatcher.register((action) => {
  switch (action.actionType) {
    case actionTypes.GetUserSettings:
      _userSettingList = action.Result;
      _lastChange = action.actionType;
      store.emitChange();
      break;

    case actionTypes.GetCurrentUser:
      _currentUser = action.Result;
      _lastChange = action.actionType;
      store.emitChange();
      break;
    case actionTypes.ChangeUserSettings:
      _lastChange = action.actionType;
      store.emitChange();
    default:
  }
});

export default store;
