const actionTypes = {
  Login_CheckMSButton: 'LOGIN_MSBTN',
  Login_ViaPassword: 'LOGIN_VIAPWD',
  Login_Silent: 'LOGIN_SILENT',
  Login_Logout: 'LOGIN_LOGOUT',
  LoginViaPwd: 'LOGIN_PWD',
  LoginViaJwt: 'LOGIN_JWT',
  LoginCheckVer: 'LOGIN_CHKVER',
  UpdateUserInfo: 'UPDATE_USER_INFO',
  UpdateUserPwd: 'UPDATE_USER_PWD',
  UpdateSysSetting: 'UPDATE_SYS_SETTING',
  InitSysSettings: 'INIT_SYS_SETTINGS',
  InitIAConfigs: 'INIT_IA_CONFIGS',
  UpdateIAConfigs: 'UPDATE_IA_CONFIGS',
  InitFormTypes: 'INIT_FORM_TYPES',
  UpdateFTConfigs: 'UPDATE_FT_CONFIGS',
  InitBuiltInFormTypes: 'INIT_BI_FORM_TYPES',
  // Mailbox Config
  TestMailboxConfig: 'MB_CONFIG_TEST',
  AuthOfficeInstr: 'MB_CONFIG_AUTH_INST',
  AuthOffice365MB: 'MB_CONFIG_AUTH365',
  MailboxLoad: 'MB_LOAD',
  FTLoad: 'FT_LOAD',
  FTUpdate: 'FT_UPDATE',
  FTGetAll: 'FT_ALL',
  FTGetAllDataSource: 'FT_ALLDS',
  FTUpdateExtender: 'FT_UPDATEEXT',
  FTRemoveExtender: 'FT_REMEXT',
  FTGetPlugInModel: 'FT_PMODEL',
  WFLoad: 'WF_LOAD',
  WFUpdate: 'WF_UPDATE',
  IntakeStrategyCreate: 'IS_CREATE',
  IntakeStrategyUpdate: 'IS_UPDATE',
  IntakeStrategyDelete: 'IS_DELETE',
  IntakeStrategyLoad: 'IS_LOAD',
  IntakeStrategyLoadOne: 'IS_LOAD_ONE',
  BarcodeCreate: 'BC_CREATE',
  BarcodeLoad: 'BC_LOAD',
  BarcodeLoadOne: 'BC_LOAD_ONE',
  BarcodeUpdate: 'BC_UPDATE',
  BarcodeDelete: 'BC_DELETE',
  LegacyBPLoad: 'LBP_LOAD',
  TemplateList: 'TMP_LIST',
  CompanionList: 'COMP_LIST',
  CompanionLoadOne: 'COMP_LOAD_ONE',
  CompanionGetData: 'COMP_GET_DATA',
  GetLicenseLevel: 'GET_LICENSE_LEVEL',
  DirectoriesList: 'DIR_LIST',
  DirectoriesUpdateOne: 'DIR_UPDATE1',
  DirectoriesLoadOne: 'DIR_LOAD_ONE',
  DirectoriesDelete: 'DIR_DELETE',
  ArchiveList: 'ARCH_LIST',
  ArchiveUpdateOne: 'ARCH_UPDATE1',
  ArchiveLoadOne: 'ARCH_LOAD_ONE',
  ArchiveDelete: 'ARCH_DELETE',
  IAList: 'IA_LIST',
  IAUpdateOne: 'IA_UPDATE1',
  IALoadOne: 'IA_LOAD_ONE',
  IADelete: 'IA_DELETE',
  IACreate: 'IA_CREATE',
  WebUploadGetMany: 'WU_GETMANY',
  WebUploadGetStatus: 'WU_GETSTATUS',
  WebUploadUpload: 'WU_UPLOAD',
  LicenseGetLicense: 'LIC_GETLIC',
  LicenseGetSummary: 'LIC_SUMM',
  UploadTrackerGet: 'UT_GET',
  UploadTrackerGetMore: 'UT_GETMORE',
  UploadTrackerUpdateOne: 'UT_UPDATE',
  UploadTrackerSearch: 'UT_SEARCH',
  UploadTrackerGetDetails: 'UT_DETAIL',
  UploadTrackerReprocess: 'UT_REPO',
  DocHistorySearch: 'DH_SEARCH',
  DocHistorySearchMore: 'DH_SEARCHMORE',
  LookupCreate: 'LU_CREATE',
  LookupGetList: 'LU_GETLIST',
  LookupGetOne: 'LU_GETONE',
  LookupUpdate: 'LU_UPDATE',
  LookupDelete: 'LU_DELETE',
  LookupDataUpdate: 'LU_UPDATA',
  LookupGetData: 'LU_GETDATA',
  LookupRowEdit: 'LU_ROWEDIT',
  LookupDeleteDataSource: 'LU_DELETEDS',
  DataSourceGetList: 'DS_GETLIST',
  DataSourceGetOne: 'DS_GETONE',
  DocumentLoadOne: 'DOC_LOAD',
  DataSourceUpdateOne: 'DS_UPDATEONE',
  DataSourceCreateOne: 'DS_CREATEONE',
  DataSourceDelete: 'DS_DELETE',
  UsersGetUsers: 'USERS_GETLIST',
  UsersGetOne: 'USERS_GETONE',
  UsersCreate: 'USERS_CREATE',
  UsersUpdateUser: 'USERS_UPDATE',
  UsersDeactivate: 'USERS_DEACT',
  UsersReactivate: 'USERS_REACT',
  UserChangePassword: 'USERS_CHPWD',
  UserChangeSetting: 'USER_SETTING',
  RolesGetRoles: 'ROLES_GETLIST',
  RolesGetOne: 'ROLES_GETONE',
  RolesGetUsers: 'ROLES_GET_USERS',
  RolesUpdateOne: 'ROLES_UPDATEONE',
  RolesDeleteOne: 'ROLES_DELETEONE',
  RolesUsersFor: 'ROLES_USERSFOR',
  RolesUpdateUsers: 'ROLES_UPUSERS',
  UserGetADOauthSettings: 'USER_GETSETTIGNS',
  UserUpdateADOauthSettings: 'USER_UPDATESETTINGS',
  WorkflowGetWorkflows: 'WF_GETWORKFLOWS',
  WorkflowUpdate: 'WF_UPDATE',
  WorkflowGetOne: 'WF_GETONE',
  WorkflowCreate: 'WF_CREATE',
  WorkflowGetFromStep: 'WF_GETFROMSTEP',
  WorkflowGetUserSteps: 'WF_GETUSERSTEPS',
  WorkflowFTAndTemp: 'WF_FTANDTEMP',
  WorkflowAllowedFormTypes: 'WF_ALL_FTS',
  BPCreate: 'BP_CREATE',
  BPGetList: 'BP_GETLIST',
  BPGetListByFormType: 'BP_GETLIST_BYFORMTYPE',
  BPGetOne: 'BP_GETONE',
  BPUpdate: 'BP_Update',
  ExportBP: 'BP_EXPORT',
  ImportBP: 'BP_IMPORT',
  OUCreate: 'OU_CRATE',
  OUGetOne: 'OU_GETONE',
  OUUpdate: 'OU_UPDATE',
  OUDelete: 'OU_DELETE',
  AlertAdd: 'ALERT_ADD',
  AlertDelete: 'ALERT_DELETE',
  IndexUpdate: 'INDEX_UPDATE',
  IntakeGetSettings: 'INTAKE_GETSETTINGS',
  IntakeUpdateSettings: 'INTAKE_UPDATESETTINGS',
  SystemGetSettings: 'SYSTEM_GETSETTINGS',
  SystemUpdateSettings: 'SYSTEM_UPDATESETTINGS',
  TemplateCreate: 'TEMP_CREATE',
  TemplateGetList: 'TEMP_GETLIST',
  TempalteGetOne: 'TEMP_GETONE',
  TemplateUpdate: 'TEMP_Update',
  TemplateCreateSample: 'TEMP_SAMPLE',
  TemplateGetSamples: 'TEMP_GETSAMPLES',
  TemplateCreateTemplate: 'TEMP_CREATE',
  TemplateFormTypes: 'TEMP_FT',
  TemplateTables: 'TEMP_TABLES',
  TemplateGetSample: 'TEMP_GETSAMPLE',
  TemplateCheckData: 'TEMP_CHECKDATA',
  TemplateUpdateSampleName: 'TEMP_UPSAMPNAME',
  TemplateHeaders: 'TEMP_HEADERS',
  TemplateDeleteSample: 'TEMP_DELSAMPLE',
  TemplateGetSampleForDoc: 'TEMP_SAMPLEDOC',
  TemplateGetSampleInfo: 'TEMP_SAMPINFO',
  TemplateGetDetails: 'TEMP_DETAILS',
  TemplateUpdateActive: 'TEMP_ACTIVE',
  TemplateGetAudit: 'TEMP_AUDIT',
  TemplateUploadTemplate: 'TEMP_UPLOAD',
  TemplateDocSample: 'TEMP_DOCSAMP',
  TemplateDocSampleInfo: 'TEMP_DOCSAMPINFO',
  TemplateConvertSample: 'TEMP_CONVSAMPLE',
  TemplateGetUsage: 'TEMP_USAGE',
  ADGetADTree: 'AD_GETTREE',
  ADGetStep: 'AD_GETSTEP',
  ADExport: 'AD_Export',
  BPGetPermission: 'BP_PERMISSION',
  IRGetADTree: 'IR_GETTREE',
  IRGetStep: 'IR_GETSTEP',
  IRGetBP: 'IR_GETBP',
  IRGetImageReview: 'IR_GETIR',
  IRGetImages: 'IR_GETIMAGES',
  IRUpdate: 'IR_UPDATE',
  IRFetchImage: 'IR_FETCHIMAGE',
  DocumentLoad: 'DOC_LOAD',
  DocumentSave: 'DOC_SAVE',
  DocumentSubmit: 'DOC_SUBMIT',
  DocumentFetch: 'DOC_FETCH',
  DocumentEndOfList: 'DOC_END',
  DocumentGetAudit: 'DOC_AUDIT',
  DocumentUpdateTemp: 'DOC_UTEMP',
  DocumentGetAvailTemplates: 'DOC_ATEMPS',
  DocumentGetAvailTemplatesByBp: 'DOC_ATEMPSBYBP',
  DocumentGetAvailTemplatesByBp2: 'DOC_ATEMPSBYBP2',
  DocumentGetAvailBpByBp: 'DOC_BpsByBp',
  DocumentUpdateTempWDoc: 'DOC_UTEMP2',
  DocumentUpdateTempWDocs: 'DOC_UTEMP3',
  DocumentUpdateMoveTemp: 'DOC_UTEMP4',
  DocumentsUpdateTemp: 'DOC_UTEMP4',
  DocumentExtender: 'DOC_EXTEND',
  DocumentExtenderRow: 'DOC_EXTROW',
  DocumentFirstExtenderRow: 'DOC_FIRST_EXTROW',
  DocumentToImageReview: 'DOC_IMAGEREV',
  DocumentChagneQueue: 'DOC_CHQUEUE',
  DocumentDelete: 'DOC_DELETE',
  DocumentAddNote: 'DOC_ADDNOTE',
  DocumentGetStatusInfo: 'DOC_STATUSINFO',
  DocumentDownloadAttachment: 'DOC_DLATTCH',
  DocumentDeleteAttachment: 'DOC_DEATTCH',
  DocumentUploadAttachment: 'DOC_UPATTCH',
  DocumentAssignUsers: 'DOC_UASSIGN',
  DocumentPull: 'DOC_PULL',
  DocumentPdf: 'DOC_PDF',
  DocumentGetAssignees: 'DOC_GETASSIGNEES',
  DocumentSendEmail: 'DOC_SENDMAIL',
  DocumentGetRelatedDocs: 'DOC_RELATED',
  DocActionProcess: 'DOCACT_PROC',
  DocumentFullExtender: 'DOC_FULLEXTENDER',
  DocumentUndelete: 'DOC_UNDELETE',
  HotKey: 'HOTKEY',
  ImageLoad: 'IMAGE_LOAD',
  ImageFetch: 'IMAGE_FETCH',
  RolePermissionGetAll: 'RP_GETALL',
  RolePermissionSaveList: 'RP_SAVELIST',
  RolePermissionGetFields: 'RP_GETFIELD',
  RolePermissionUpdateFields: 'RP_UPFIELD',
  RoleBPGetList: 'RP_BPLIST',
  RoleUpdate: 'ROLE_UPDATE',
  WorkflowStepRolePermissionGetList: 'WFSTEPRP_GETLIST',
  ModulesGetList: 'MOD_GETLIST',
  ModuleDataGetList: 'MOD_DATA_GETLIST',
  ModulesUpload: 'MOD_UPLOAD',
  ModulesRename: 'MOD_RENAME',
  ModulesGetOne: 'MOD_GETONE',
  ModulesUpdate: 'MOD_UPDATE',
  ModuleRunPlugin: 'MOD_RUN',
  ModuleDownload: 'MOD_DLOAD',
  ModuleDelete: 'MOD_DELETE',
  ModuleAnalyze: 'MOD_ANALYZE',
  UserInRoleGetList: 'USERROLE_GETLIST',
  CanUserWebUpload: 'USER_WEBUPLOAD',
  SecretList: 'SECRET_LIST',
  SecretUpdateOne: 'SECRET_UPDATE',
  SecretCreateOne: 'SECRET_CREATE',
  SecretLoadOne: 'SECRET_LOAD_ONE',
  SecretDelete: 'SECRET_DELETE',
  MailboxList: 'MAILBOX_LIST',
  MailboxUpdateOne: 'MAILBOX_UPDATE',
  MailboxCreateOne: 'MAILBOX_CREATE',
  MailboxLoadOne: 'MAILBOX_LOAD_ONE',
  MailboxDelete: 'MAILBOX_DELETE',
  MailboxPassword: 'MAILBOX_PASSWORD',
  SchedulesGetOne: 'SCHEDULE_GETONE',
  SchedulesGetList: 'SCHEDULE_GETLIST',
  ScheduleUpdateOne: 'SCHEDULE_UPDATEONE',
  ScheduleDelete: 'SCHEDULE_DELETE',
  ScheduleRunNow: 'SCHEDULE_RUNNOW',
  IntegrationsGetList: 'INTEGRATIONS_GETLIST',
  MailboxTest: 'MAILBOX_TEST',
  MailboxAuthenticate: 'MAILBOX_AUTH',
  MailboxInstructions: 'MAILBOX_INSTR',
  NavRequest: 'NAV_REQUEST',
  NavAccept: 'NAV_ACCEPT',
  SearchQuickSearch: 'SEARCH_QSEARCH',
  SearchByDocId: 'SEARCH_DOCID',
  SearchByValues: 'SEARCH_VALUES',
  SearchByFieldValue: 'SEARCH_FIELDVALUE',
  SearchByUserAudit: 'SEARCH_USERAUDIT',
  SearchByUserQueue: 'SEARCH_QUEUE',
  SearchByNotes: 'SEARCH_NOTES',
  SearchByProperties: 'SEARCH_PROPERTIES',
  SearchGetStep: 'SEARCH_GETSTEP',
  SearchGetBp: 'SEARCH_GETBP',
  GetIndexFields: 'GETINDEXFIELDS',
  GetIndexFieldsByFormTypeId: 'GETINDEXFIELDS_BYFTID',
  IndexGetDocCounts: 'INDEXGETDOCCOUNTS',
  ReIndexFormType: 'REINDEX_FORMTYPE',
  CancelReindex: 'CANCEL_REINDEX',
  GetBuildPercentage: 'GETBUILDPERCENTAGE',
  UpdateBuildStatus: 'UPDATEBUILDSTATUS',
  IndexDelete: 'INDEX_DELETE',
  LogGetCategories: 'LOG_CATEGORIES',
  LogGetLogs: 'LOG_GETLOGS',
  SystemAuditLogGetCategories: 'SYSTEM_AUDIT_LOG_CATEGORIES',
  SystemAuditLogGetLogs: 'SYSTEM_AUDIT_LOG_GETLOGS',
  AutoOcrDocumentTypeGetList: 'AUTOOCR_DOCTYPE_LIST',
  AutoOcrFieldGetList: 'AUTOOCR_FIELD_LIST',
  AutoOcrFieldIdentifierList: 'AUTOOCR_FIELDIDENTIFIER_LIST',
  AutoOcrPatternList: 'AUTOOCR_PATTERN_LIST',
  AutoOcrMappingList: 'AUTOOCR_MAPPING_LIST',
  AutoOcrMappingDocumentType: 'AUTOOCR_MAPPING_DOCUMENTTYPE',
  AutoOcrFieldDataList: 'AUTOOCR_FIELDDATA_LIST',
  AutoOcrDataUpdate: 'AUTOOCR_DATA_UPDATE',
  AutoOcrMappingUpdate: 'AUTOOCR_MAPPING_UPDATE',
  AutoOcrDocTypeDelete: 'AUTOOCR_DOCTYPE_DELETE',
  ConnectionList: 'CONNECTION_LIST',
  ConnectionUpdateOne: 'CONNECTION_UPDATE',
  ConnectionCreateOne: 'CONNECTION_CREATE',
  ConnectionLoadOne: 'CONNECTION_LOAD_ONE',
  ConnectionDelete: 'CONNECTION_DELETE',
  GetUserSettings: 'GET_USERSETTINGS',
  GetCurrentUser: 'GET_CURRENTUSER',
  ChangeUserSettings: 'CHANGE_USERSETTINGS',
  DocListSetting: 'DOCLIST_USETTINGS',
  DocListColumns: 'DOCLIST_COLUMNSETTINGS',
  BulkAction: 'BULK_Action',
  BulkDownload: 'BULK_DOWNLOAD',
  WorkflowStepAction: 'WORKFLOW_STEPS',
  InboxGetQueueTree: 'INBOX_GET_QUEUETREE',
  InboxGetErrorTree: 'INBOX_GET_ERRORTREE',
  InboxGetDeletedTree: 'INBOX_GET_DELETEDTREE',
  InboxGetImageReviewTree: 'INBOX_GET_IMAGEREVIEW_TREE',
  InboxGetFilterTree: 'INBOX_GET_FILTERTREE',
  InboxGetStep: 'INBOX_GET_STEP',
  InboxGetBp: 'INBOX_GET_BP',
  InboxGetFilters: 'INBOX_GET_FILTERS',
  InboxUpdateFilter: 'INBOX_UPDATE_FILTER',
  InboxDeleteFilter: 'INBOX_DELETE_FILTER',
  GetWorkflowStepsForBPs: 'GET_WFSTEPS_FORBPS',
  GetFieldsByFormType: 'GET_FIELDS_BYFORMTYPE',
  GetHeaderFieldsByFormType: 'GET_HEADERFIELDS_BYFORMTYPE',
  GetFilterData: 'GET_FILTERDATA',
  GetFieldRelations: 'GET_FIELDRELATIONS',
  GetAllFormTypes: 'GET_ALL_FT',
  GetFieldsOtherFormType: 'GET_FIELDS_OTHER_FT',
  UpdateFieldRelation: 'UPDATE_FIELD_RELATION',
  DeleteFieldRelation: 'DELETE_FIELD_RELATION',
  DocumentZoomSettingUpdate: 'DOC_ZOOM_SETTING_UPDATE',
  GetDocumentZoomSetting: 'GET_DOC_ZOOM_SETTING',
};

export default actionTypes;
