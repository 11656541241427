import dispatcher from '../Dispatcher';
import actionTypes from './ActionTypes';
import axios from 'axios';
import * as Globals from '../Globals';
import { PostAlert } from './AlertActions';
import { Logout } from './LoginActions';

export function GetADTree(completed) {
  axios
    .get(`${Globals.baseUrl}/ActiveDocument/GetADTree/${completed}`, {
      headers: {
        'content-type': 'application/json; charset=utf-8',
        Authorization: `Bearer ${Globals.getJwtToken()}`,
      },
    })

    .then((res) => {
      if (res.status === 200) {
        dispatcher.dispatch({
          actionType: actionTypes.ADGetADTree,
          Result: res.data,
        });
      } else if (res.status === 400 || res.status === 401) {
        console.log('error: 401');
      }
    })
    .catch((res) => {
      if (res.response.status === 401) {
        console.log('unauthorized');
        Logout();
      }
    });
}

export function GetBPPermissions(bpId) {
  let bp = bpId;
  axios
    .get(`${Globals.baseUrl}/RolePermission/GetBPPermission/${bpId}`, {
      headers: {
        'content-type': 'application/json; charset=utf-8',
        Authorization: `Bearer ${Globals.getJwtToken()}`,
      },
    })
    .then((res) => {
      if (res.status === 200) {
        dispatcher.dispatch({
          actionType: actionTypes.BPGetPermission,
          Result: res.data,
        });
      } else if (res.status === 400 || res.status === 401) {
        console.log('error: 401');
      }
    })
    .catch((res) => {
      if (res.response.status === 401) {
        console.log('unauthorized');
        Logout();
      }
    });
}

export function GetBP(bpId, column, order, showCompleted, offset) {
  console.log(showCompleted);
  console.log(offset);
  axios
    .get(
      `${Globals.baseUrl}/ActiveDocument/GetADBP/${bpId}/${column}/${order}/${showCompleted}/${offset}`,
      {
        headers: {
          'content-type': 'application/json; charset=utf-8',
          Authorization: `Bearer ${Globals.getJwtToken()}`,
        },
      }
    )
    .then((res) => {
      if (res.status === 200) {
        dispatcher.dispatch({
          actionType: actionTypes.ADGetStep,
          Result: res.data,
        });
      } else if (res.status === 400 || res.status === 401) {
        console.log('error: 401');
      }
    })
    .catch((res) => {
      if (res.response.status === 401) {
        console.log('unauthorized');
        Logout();
      }
    });
}

export function GetADStep(id, bpId, column, order, offset, showCompleted) {
  axios
    .get(
      `${Globals.baseUrl}/ActiveDocument/GetADStep/${id}/${bpId}/${column}/${order}/${showCompleted}/${offset}`,
      {
        headers: {
          'content-type': 'application/json; charset=utf-8',
          Authorization: `Bearer ${Globals.getJwtToken()}`,
        },
      }
    )
    .then((res) => {
      if (res.status === 200) {
        let result = res.data;
        result.request = {
          stepId: id,
          bpId:  bpId,
          sortCol: column,
          sortOrder: order,
          offset: offset,
          showCompleted: showCompleted,
        };
        dispatcher.dispatch({
          actionType: actionTypes.ADGetStep,
          Result: result,
        });
      } else if (res.status === 400 || res.status === 401) {
        console.log('error: 401');
      }
    })
    .catch((res) => {
      console.log(res);
      if (res.response.status === 401) {
        console.log('unauthorized');
        Logout();
      }
    });
}

export function ExportActiveDocuments(
  query,
  formatDocumentIdAsTrinDocsLink,
  currentUrl,
  downloadName,
  formTypeId
) {
  axios
    .post(
      `${Globals.baseUrl}/ActiveDocument/ExportActiveDocuments`,
      {
        query: query,
        formatDocumentIdAsTrinDocsLink: formatDocumentIdAsTrinDocsLink,
        currentUrl: currentUrl,
        formTypeId: formTypeId,
      },
      {
        headers: {
          'content-type': 'application/json; charset=utf-8',
          Authorization: `Bearer ${Globals.getJwtToken()}`,
        },
        responseType: 'blob',
      }
    )
    .then((res) => {
      if (res.status === 200) {
        dispatcher.dispatch({
          actionType: actionTypes.ADExport,
          Result: res.data,
        });
        var link = document.createElement('a');
        link.setAttribute('download', '');
        link.href = window.URL.createObjectURL(new Blob([res.data]));
        link.download = downloadName;
        document.body.appendChild(link);
        link.click();
      } else if (res.status === 400 || res.status === 401) {
        console.log('error: 401');
      }
    })
    .catch((res) => {
      if (res.response.status === 401) {
        console.log('unauthorized');
        Logout();
      }
    });
}

export function UpdateUserHeaderSettings(formTypeId, settings) {
  console.log(settings);
  axios
    .post(
      `${Globals.baseUrl}/ActiveDocument/UpdateUserHeaderSettings`,
      {
        formTypeId: formTypeId,
        settings: settings,
      },
      {
        headers: {
          'content-type': 'application/json; charset=utf-8',
          Authorization: `Bearer ${Globals.getJwtToken()}`,
        },
      }
    )
    .then((res) => {
      if (res.status === 200) {
        //let result = res.data;
        PostAlert('success', 'User settings updated');
      }
    })
    .catch((res) => {
      if (res.response.status === 401) {
        console.log('unauthorized');
        Logout();
      }
    });
}
