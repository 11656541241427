//@flow
import dispatcher from '../Dispatcher';
import actionTypes from './ActionTypes';
import axios from 'axios';
import * as Globals from '../Globals';
import { PostAlert } from './AlertActions';
//import { CompanionRecord } from '../Models/Admin/CompanionSchema';

export function GetArchiveList() {
  axios
    .get(`${Globals.baseUrl}/Archive/GetArchives`, {
      headers: {
        'content-type': 'application/json; charset=utf-8',
        Authorization: `Bearer ${Globals.getJwtToken()}`,
      },
    })
    .then((res) => {
      if (res.status === 200) {
        dispatcher.dispatch({
          actionType: actionTypes.ArchiveList,
          Result: res.data,
        });
      } else if (res.status === 400 || res.status === 401) {
        console.log('error: 401');
      }
    })
    .catch((res) => {
      let result = {
        updateStatus: 'Error',
        errorMessage: '401: Bad request',
      };
      dispatcher.dispatch({
        actionType: actionTypes.DirectoriesList,
        Result: result,
      });
    });
}

export function LoadArchive(id) {
  axios
    .post(
      `${Globals.baseUrl}/Archive/GetArchive`,
      { archiveId: id },
      {
        headers: {
          'content-type': 'application/json; charset=utf-8',
          Authorization: `Bearer ${Globals.getJwtToken()}`,
        },
      }
    )
    .then((res) => {
      if (res.status === 200) {
        dispatcher.dispatch({
          actionType: actionTypes.ArchiveLoadOne,
          Result: res.data,
        });
      } else if (res.status === 400 || res.status === 401) {
        console.log('error: 401');
      }
    })
    .catch((res) => {
      console.log('error');
    });
}

export function CreateArchive(uploadPath, isActive) {
  axios
    .post(
      `${Globals.baseUrl}/Archive/CreateArchive`,
      { archId: 0, archivePath: uploadPath, isCurrent: isActive },
      {
        headers: {
          'content-type': 'application/json; charset=utf-8',
          Authorization: `Bearer ${Globals.getJwtToken()}`,
        },
      }
    )
    .then((res) => {
      if (res.status === 200) {
        dispatcher.dispatch({
          actionType: actionTypes.ArchiveUpdateOne,
          Result: res.data,
        });
        console.log(res.data);
        if (res.data.status === 'Success') {
          PostAlert('success', 'Archive created');
        }
        if (res.data.status === 'Error') {
          PostAlert('error', 'Unable to create archive');
          PostAlert('error', res.data.updateMessage);
        }
      } else if (res.status === 400 || res.status === 401) {
        console.log('error: 401');
      }
    })
    .catch((res) => {
      console.log(res);
    });
}

export function UpdateArchive(archiveId, uploadPath, isActive) {
  axios
    .post(
      `${Globals.baseUrl}/Archive/UpdateArchive`,
      { archId: archiveId, archivePath: uploadPath, isCurrent: isActive },
      {
        headers: {
          'content-type': 'application/json; charset=utf-8',
          Authorization: `Bearer ${Globals.getJwtToken()}`,
        },
      }
    )
    .then((res) => {
      if (res.status === 200) {
        dispatcher.dispatch({
          actionType: actionTypes.ArchiveUpdateOne,
          Result: res.data,
        });
        console.log(res.data);
        if (res.data.status === 'Success') {
          PostAlert('success', 'Archive updated');
        }
        if (res.data.status === 'Error') {
          PostAlert('error', 'Unable to update archive');
          PostAlert('error', res.data.updateMessage);
        }
      } else if (res.status === 400 || res.status === 401) {
        console.log('error: 401');
      }
    })
    .catch((res) => {
      console.log(res);
    });
}

export function DeleteArchive(archiveId) {
  axios
    .post(
      `${Globals.baseUrl}/Archive/DeleteArchive`,
      { archiveId: archiveId },
      {
        headers: {
          'content-type': 'application/json; charset=utf-8',
          Authorization: `Bearer ${Globals.getJwtToken()}`,
        },
      }
    )
    .then((res) => {
      if (res.status === 200) {
        dispatcher.dispatch({
          actionType: actionTypes.ArchiveDelete,
          Result: res.data,
        });
      } else if (res.status === 400 || res.status === 401) {
        console.log('error: 401');
      }
    })
    .catch((res) => {
      console.log(res);
    });
}
