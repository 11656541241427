import { EventEmitter } from 'events';
import Dispatcher from '../Dispatcher';
import actionTypes from '../actions/ActionTypes';

const CHANGE_EVENT = 'rolePermissionChange';
let _rolePermissionList = [];
let _fieldRolePermission = {};
//let _currentRolePermission = {};
let _lastChange = '';

class RoleStore extends EventEmitter {
  addChangeListener(callback) {
    this.on(CHANGE_EVENT, callback);
  }

  removeChangeListener(callback) {
    this.removeListener(CHANGE_EVENT, callback);
  }

  emitChange() {
    this.emit(CHANGE_EVENT);
  }

  getRolePermissionList() {
    return _rolePermissionList;
  }

  getLastChange() {
    return _lastChange;
  }

  getFieldRolePermissions() {
    return _fieldRolePermission;
  }

  //    getCurrentRole() {
  //       return _currentRole;
  //    }
}

const store = new RoleStore();

Dispatcher.register((action) => {
  switch (action.actionType) {
    case actionTypes.RolePermissionGetAll:
      _rolePermissionList = action.Result;
      _lastChange = action.actionType;

      store.emitChange();
      break;
    case actionTypes.RolePermissionGetFields:
      _fieldRolePermission = action.Result;
      _lastChange = action.actionType;
      store.emitChange();
      break;

    default:
  }
});

export default store;
