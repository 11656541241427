import * as React from 'react';
import { createBrowserHistory } from 'history';
import InboxSidebar from './InboxSidebar';
import InboxStore from '../../../stores/InboxStore';
import WorkflowStore from '../../../stores/WorkflowStore';
import { GetWorkflowFromStep } from '../../../actions/WorkflowActions';
import { GetInboxStep, GetInboxBp } from '../../../actions/InboxActions';
import actionTypes from '../../../actions/ActionTypes';
import DocumentList from '../DocumentList/DocumentList';
import { GetBPPermissions } from '../../../actions/ActiveDocumentActions';

const customHistory = createBrowserHistory();

class Inbox extends React.Component {
  constructor(props) {
    super(props);
    // state
    this.state = {
      currentDocs: [],
      docCount: 0,
      columns: [],
      scrollWidth: window.innerWidth - 320,
      scrollHeight: window.innerHeight - 58,
      //colSizes: [180, 150, 150, 150, 150, 150],
      //colOffsets: [0, 180, 330, 480, 630, 780],
      downloadConfirmation: false,
      formatDocumentIdAsTDLink: false,
      currentWfName: '',
      currentFormType: 0,
      gridMode: 'INBOX',
      activeStep: null,
      activeBP: null,
      currentOffset: 0,
      checkAll: false,
    };

    this.LoadStep = this.LoadStep.bind(this);
    this.LoadBP = this.LoadBP.bind(this);
    this.onInboxChange = this.onInboxChange.bind(this);
    this.onWfChange = this.onWfChange.bind(this);
    this.checkParams = this.checkParams.bind(this);
    this.onWindowResize = this.onWindowResize.bind(this);
    this.goToDoc = this.goToDoc.bind(this);
    this.toggleCheckAll = this.toggleCheckAll.bind(this);
    this.toggleCheckAll2 = this.toggleCheckAll2.bind(this);
    this.toggleDocument = this.toggleDocument.bind(this);
  }

  componentDidMount() {
    InboxStore.addChangeListener(this.onInboxChange);
    WorkflowStore.addChangeListener(this.onWfChange);
    window.onresize = this.onWindowResize;
    this.checkParams();
    document.title = 'TrinDocs - Inbox'
  }

  componentWillUnmount() {
    InboxStore.removeChangeListener(this.onInboxChange);
    WorkflowStore.removeChangeListener(this.onWfChange);
  }

  LoadStep(id, bpId, type) {
    customHistory.push(`/Inbox?Step=${id.toString()}&BP=${bpId.toString()}`);

    let defaultPages = localStorage.getItem('DefaultParams');
    if (defaultPages === null) {
      defaultPages = {
        ActiveDocuments: { BP: null, Step: null },
        Inbox: { BP: null, Step: null, Type: null, Column: null, Order: null },
        Search: { BP: null, Step: null },
      };
    } else defaultPages = JSON.parse(localStorage.getItem('DefaultParams'));

    defaultPages.Inbox.BP = bpId;
    defaultPages.Inbox.Step = id;
    defaultPages.Inbox.Type = type;
    defaultPages.Inbox.Column = '0';
    defaultPages.Inbox.Order = 'asc';

    localStorage.setItem('DefaultParams', JSON.stringify(defaultPages));

    GetBPPermissions(bpId);
    GetInboxStep(id, bpId, type, '0', 'asc', 0);
    GetWorkflowFromStep(id);
  }

  LoadBP(id, type) {
    customHistory.push(`/Inbox?BP=${id.toString()}`);

    let defaultPages = localStorage.getItem('DefaultParams');
    if (defaultPages === null) {
      defaultPages = {
        ActiveDocuments: { BP: null, Step: null },
        Inbox: { BP: null, Step: null, Type: null, Column: null, Order: null },
        Search: { BP: null, Step: null },
      };
    } else defaultPages = JSON.parse(localStorage.getItem('DefaultParams'));

    defaultPages.Inbox.BP = id;
    defaultPages.Inbox.Step = '0';
    defaultPages.Inbox.Type = type;
    defaultPages.Inbox.Column = '0';
    defaultPages.Inbox.Order = 'asc';

    localStorage.setItem('DefaultParams', JSON.stringify(defaultPages));

    GetBPPermissions(id);
    GetInboxBp(id, type, '0', 'asc', 0);
  }

  goToDoc(docId) {
    if (window.location.pathname === '/ActiveDocuments') {
      var docLink = document.getElementById(`docLink${docId}`);
      var returnPath = window.location.pathname + window.location.search;
      localStorage.setItem('docReturn', returnPath);

      docLink.click();
    }
  }

  onWindowResize(e) {
    //setViewWidth
    this.setState({ scrollWidth: window.innerWidth - 320 });
    this.setState({ scrollHeight: window.innerHeight - 58 });
  }

  // onDocChange() {
  //   var lastChange = ADStore.lastChange();
  //   if (lastChange === actionTypes.ADGetStep) {
  //     var docs = ADStore.getDocs();
  //     var count = ADStore.getDocCount();
  //     var cols = ADStore.getColumns();
  //     let ft = ADStore.getFormTypeId();
  //     let mode = 'INBOX';
  //     console.log(mode);
  //     console.log(mode);
  //     if (docs[0].status === 5) {
  //       mode = 'IMAGE_REVIEW';
  //       console.log(mode);
  //     }
  //     this.setState({
  //       currentDocs: docs,
  //       docCount: count,
  //       columns: cols,
  //       currentFormType: ft,
  //       gridMode: mode,
  //     });
  //   }
  // }

  onInboxChange() {
    var lastChange = InboxStore.lastChange();
    if (
      lastChange === actionTypes.InboxGetStep ||
      lastChange === actionTypes.InboxGetBp
    ) {
      var docs = InboxStore.getDocs();
      var count = InboxStore.getDocCount();
      var cols = InboxStore.getColumns();
      let ft = InboxStore.getFormTypeId();
      let bp = InboxStore.getBPName();
      let step = InboxStore.getStepName();
      let offset = InboxStore.getOffset();
      let mode = 'INBOX';

      if (docs[0].status === 5) {
        mode = 'IMAGE_REVIEW';
      }



      let loadingMore = localStorage.getItem('loadingMore');

      //let currentDocArray = docs;
      if (loadingMore === 'true'){
        let currentDocArray = this.state.currentDocs;
        let toReturn = currentDocArray.concat(docs);
        this.setState({
          currentDocs: toReturn,
          docCount: count,
          columns: cols,
          currentFormType: ft,
          gridMode: mode,
          activeStep: step,
          activeBP: bp,
          currentOffset: offset
        });

        localStorage.setItem('loadingMore', false);
      }
      else{
        this.setState({
          currentDocs: docs,
          docCount: count,
          columns: cols,
          currentFormType: ft,
          gridMode: mode,
          activeStep: step,
          activeBP: bp,
          currentOffset: offset
        });
      }




      // this.setState({
      //   currentDocs: docs,
      //   docCount: count,
      //   columns: cols,
      //   currentFormType: ft,
      //   gridMode: mode,
      //   activeStep: step,
      //   activeBP: bp,
      //   currentOffset: offset,
      // });


    }
  }

  onWfChange() {
    var lastChange = WorkflowStore.getLastAction();
    if (lastChange === actionTypes.WorkflowGetFromStep) {
      var currentWfName = WorkflowStore.getCurrentWorkflowName();
      this.setState({ currentWfName: currentWfName.name });
    }
  }

  checkParams() {
    let params = new URLSearchParams(window.location.search);
    var stepParam = params.get('Step');
    var bpParam = params.get('BP');

    let defaultPages = localStorage.getItem('DefaultParams');
    if (defaultPages === null) {
      defaultPages = {
        ActiveDocuments: { BP: null, Step: null },
        Inbox: { BP: null, Step: null, Type: null, Column: null, Order: null },
        Search: { BP: null, Step: null },
      };
    } else defaultPages = JSON.parse(localStorage.getItem('DefaultParams'));

    if (stepParam) {
      this.LoadStep(stepParam, bpParam, defaultPages.Inbox.Type);
    }

    if (!stepParam && bpParam) {
      this.LoadBP(bpParam, defaultPages.Inbox.Type);
    }
  }

  toggleCheckAll() {
    //dont do anything
    return;
  }

  toggleDocument(docId) {
    let docs = this.state.currentDocs;
    let doc = docs.find((d) => d.documentId === docId);
    if (doc) {
      doc.isChecked = !doc.isChecked;
      let checkCount = 0;
      docs.forEach((d) => {
        if (d.isChecked) checkCount++;
      });

      if (!doc.isChecked){
        this.setState({ checkAll: false });
      }

      this.setState({ currentDocs: docs, checkCount: checkCount });
    }
  }

  toggleCheckAll2() {
    let tempCheckAll = null;
    if (this.state.checkAll === false) {
      tempCheckAll = true;
      this.setState({ checkAll: true });
    } else {
      tempCheckAll = !this.state.checkAll;
      this.setState({ checkAll: tempCheckAll });
    }
    let checkCount = 0;
    let docs = this.state.currentDocs;
    docs.forEach((d) => {
      d.isChecked = tempCheckAll;
      if (tempCheckAll) checkCount++;
    });
    this.setState({ currentDocs: docs, checkCount: checkCount });

    // set same check to all others.
  }

  render() {
    return (
      <div className='sidebarLayout d-flex flex-row flex-fill'>
        <InboxSidebar LoadStepClick={this.LoadStep} LoadBPClick={this.LoadBP} />
        <DocumentList
          mode={this.state.gridMode}
          documents={this.state.currentDocs}
          FormTypeId={this.state.currentFormType}
          Reload={this.checkParams}
          scrollWidth={this.state.scrollWidth}
          scrollHeight={this.state.scrollHeight}
          toggleCheckAll2={this.toggleCheckAll2}
          toggleDocument={this.toggleDocument}
          checkAll={this.state.checkAll}
          checkCount={this.state.checkCount}
          bpName={this.state.activeBP}
          stepName={this.state.activeStep}
          docCount={this.state.docCount}
          offset={this.state.currentOffset}
        />
      </div>
    );
  }
}

export default Inbox;
