import * as React from 'react';
import { Link } from 'react-router-dom';
import InboundAutomationSidebar from './InboundAutomationSidebar';
import IAStore from '../../../../stores/IAStore';
import MailboxStore from '../../../../stores/MailboxStore';
import { CreateIA, GetIAList } from '../../../../actions/IAActions';
import { GetMailboxList } from '../../../../actions/MailboxActions';


class InboundAutomationNew extends React.Component {
   constructor(props) {
      super(props);
      // state
      this.state = {
         newIAMailboxId: 0,
         newIAMailboxName: '',
         mailboxes: [],
         hasError: false,
         currentError: '',
         newIAId: 0,
      };

      this.handleCreate = this.handleCreate.bind(this);
      this.onMailboxSelection = this.onMailboxSelection.bind(this);
      this.onIAChange = this.onIAChange.bind(this);
      this.onMBChange = this.onMBChange.bind(this);
   }

   componentDidMount() {
      IAStore.addChangeListener(this.onIAChange);
      MailboxStore.addChangeListener(this.onMBChange);
      GetMailboxList();
   }

   componentWillUnmount() {
      IAStore.removeChangeListener(this.onIAChange);
      MailboxStore.removeChangeListener(this.onMBChange);
   }

   //    public handleNameChange(e: any) {
   //       this.setState({ newISName: e.target.value });
   //    }

   onIAChange() {
      let lastErr = IAStore.getLastError();
      let lastChange = IAStore.lastChange();

      if (lastChange === 'IACreate') {
         if (lastErr !== '') {
            this.setState({ hasError: true, currentError: lastErr });
            // we have an error.  show it.
         } else {
            let newIA = IAStore.getCurrentIA();
            this.setState({ hasError: false });
            GetIAList();
            //routeTo(
            //   `administration/inboundAutomationView/${newIA.inboundAutomationId}`
            //);
            this.setState({ newIAId: newIA.inboundAutomationId }, () => {
                let link = document.getElementById('newIALink');
                link.click();
              });
         }
      }
   }

   onMBChange() {
      let mbs = MailboxStore.getMailboxes();
      this.setState({ mailboxes: mbs });
   }

   onMailboxSelection(e) {
      let mailBoxId = Number(e.target.value);

      let mb = this.state.mailboxes.find((f) => f.id === mailBoxId);
      let mbName = '';

      if (mb !== undefined) {
         mbName = mb.name;
      }

      this.setState({ newIAMailboxId: mailBoxId, newIAMailboxName: mbName });
   }

   handleCreate() {
      // create new IA
      if (this.state.hasError) {
         this.setState({ hasError: false });
      }
      CreateIA(this.state.newIAMailboxId, this.state.newIAMailboxName);
   }

   render() {
      return (
         <div className='container-fluid' style={{ height: '100%' }}>
            <div className='row flex-nowrap' style={{ height: '100%' }}>
               <InboundAutomationSidebar />
               <div
                  className='flex-fill'
                  style={{ backgroundColor: '#efefef' }}
               >
                  <div
                     className='card'
                     style={{
                        marginTop: '20px',
                     }}
                  >
                     <div className='card-header'>
                        <h4>New InboundAutomation</h4>
                     </div>
                     <div className='card-body'>
                        <div className='mb-3' style={{ marginTop: '8px' }}>
                           <label
                              className='form-label'
                              htmlFor='flexSwitchCheckChecked'
                           >
                              <i className='fas fa-envelope-square' /> Mailbox:
                           </label>
                           <select
                              className='form-control'
                              aria-label='Select a mailbox'
                              value={this.state.newIAMailboxId}
                              onChange={this.onMailboxSelection}
                           >
                              <option value={0}>Select a mailbox</option>
                              {this.state.mailboxes.map((mb) =>
                                 mb.mailboxType === 11 &&
                                 <option value={mb.mailboxId} key={mb.mailboxId}>
                                    {mb.name}
                                 </option>

                              )}
                           </select>
                        </div>
                        {this.state.hasError && (
                           <div
                              className='alert alert-danger'
                              role='alert'
                              style={{ marginTop: '10px' }}
                           >
                              <p>{this.state.currentError}</p>
                           </div>
                        )}
                        <button
                           className='btn btn-primary'
                           onClick={this.handleCreate}
                        >
                           Create
                        </button>
                     </div>
                  </div>
               </div>
            </div>
            <Link
                id='newIALink'
                to={'/Admin/InboundAutomationView/' + this.state.newIAId}
                style={{ display: 'none' }}
              >
                HiddenLink
              </Link>
         </div>
      );
   }
}

export default InboundAutomationNew;
