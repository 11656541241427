import dispatcher from '../Dispatcher';
import actionTypes from './ActionTypes';
import axios from 'axios';
import * as Globals from '../Globals';
import { Logout } from './LoginActions';

export function GetRolePermissions(roleId) {
  axios
    .get(`${Globals.baseUrl}/RolePermission/GetRolePermissions/${roleId}`, {
      headers: {
        'content-type': 'application/json; charset=utf-8',
        Authorization: `Bearer ${Globals.getJwtToken()}`,
      },
    })
    .then((res) => {
      if (res.status === 200) {
        dispatcher.dispatch({
          actionType: actionTypes.RolePermissionGetAll,
          Result: res.data,
        });
      } else if (res.status === 400 || res.status === 401) {
        Logout();
      }
    })
    .catch((res) => {
      let result = {
        updateStatus: 'Error',
        errorMessage: '401: Bad request',
      };
      dispatcher.dispatch({
        actionType: actionTypes.RolePermissionGetAll,
        Result: result,
      });
    });
}

export function SaveRolePermissions(permissions) {
  axios
    .post(
      `${Globals.baseUrl}/RolePermission/UpdateRolePermission`,
      { permissions: permissions },
      {
        headers: {
          'content-type': 'application/json; charset=utf-8',
          Authorization: `Bearer ${Globals.getJwtToken()}`,
        },
      }
    )
    .then((res) => {
      if (res.status === 200) {
        dispatcher.dispatch({
          actionType: actionTypes.RolePermissionSaveList,
          Result: res.data,
        });
      } else if (res.status === 400 || res.status === 401) {
        Logout();
      }
    })
    .catch((res) => {});
}

export function GetFieldRolePermissions(id, roleId) {
  axios
    .get(
      `${Globals.baseUrl}/RolePermission/GetFieldRolePermission/${id}/${roleId}`,
      {
        headers: {
          'content-type': 'application/json; charset=utf-8',
          Authorization: `Bearer ${Globals.getJwtToken()}`,
        },
      }
    )
    .then((res) => {
      if (res.status === 200) {
        dispatcher.dispatch({
          actionType: actionTypes.RolePermissionGetFields,
          Result: res.data,
        });
      } else if (res.status === 400 || res.status === 401) {
        Logout();
      }
    })
    .catch((res) => {});
}

export function UpdateFieldRolePermission(id, bpId, grps) {
  axios
    .post(
      `${Globals.baseUrl}/RolePermission/UpdateFieldRolePermission`,
      {
        roleId: id,
        businessProcessId: bpId,
        groups: grps,
      },
      {
        headers: {
          'content-type': 'application/json; charset=utf-8',
          Authorization: `Bearer ${Globals.getJwtToken()}`,
        },
      }
    )
    .then((res) => {
      if (res.status === 200) {
        dispatcher.dispatch({
          actionType: actionTypes.RolePermissionUpdateFields,
          Result: res.data,
        });
      } else if (res.status === 400 || res.status === 401) {
        Logout();
      }
    })
    .catch((res) => {});
}
