import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { createBrowserHistory } from 'history';
import actionTypes from '../../../../actions/ActionTypes';
import ScheduleSidebar from './ScheduleSidebar';
import ScheduleStore from '../../../../stores/ScheduleStore';
import IntegrationStore from '../../../../stores/IntegrationStore';
import MailboxStore from '../../../../stores/MailboxStore';
import FormTypeStore from '../../../../stores/FormTypeStore';
import { GetScheduledIntegrationList } from '../../../../actions/IntegrationActions';
import {
  GetSchedule,
  UpdateSchedule,
  DeleteSchedule,
  RunNow,
} from '../../../../actions/ScheduleActions';
import { GetMailboxList } from '../../../../actions/MailboxActions';
import { GetAllFormTypes } from '../../../../actions/FormTypeActions';
import { Link } from 'react-router-dom';

const customHistory = createBrowserHistory();

class ScheduleView extends React.Component {
  constructor(props) {
    super(props);
    // state
    this.state = {
      currentSchedule: {
        id: 0,
        name: '',
        unitOfTime: '2',
        frequency: '1',
        anchorDateTime: '',
        weekdays: '',
        jobId: '',
        jobType: 1,
        time: '',
        date: '',
        mailBoxId: 0,
        isActive: true,
        isRepeating: true,
        data: {
          active: false,
          completed: false,
          deleted: false,
          rebuildTextIndex: false,
          rebuildRelatedCount: false,
        }
      },
      isDirty: false,
      isSaving: false,
      isNew: false,
      name: '',
      scheduleStartDate: '',
      scheduleStartTime: '',
      jobType: '',
      interval: '',
      isUploading: false,
      allIntegrations: [],
      certainDaysOfTheWeek: false,
      sunday: false,
      monday: false,
      tuesday: false,
      wednesday: false,
      thursday: false,
      friday: false,
      saturday: false,
      mailboxes: [],
      formTypes: [],
      
    };
    this.onScheduleUpdate = this.onScheduleUpdate.bind(this);
    this.onIntegrationUpdate = this.onIntegrationUpdate.bind(this);
    this.updateSchedule = this.updateSchedule.bind(this);
    this.onEditName = this.onEditName.bind(this);
    this.onEditScheduleStartDate = this.onEditScheduleStartDate.bind(this);
    this.onEditScheduleStartTime = this.onEditScheduleStartTime.bind(this);
    this.onEditJobType = this.onEditJobType.bind(this);
    this.onEditJob = this.onEditJob.bind(this);
    this.onEditMailbox = this.onEditMailbox.bind(this);
    this.onEditInterval = this.onEditInterval.bind(this);
    this.unitOfTimeChange = this.unitOfTimeChange.bind(this);
    this.onChangeView = this.onChangeView.bind(this);
    this.onRename = this.onRename.bind(this);
    this.onRenaming = this.onRenaming.bind(this);
    this.saveSchedule = this.saveSchedule.bind(this);
    this.deleteSchedule = this.deleteSchedule.bind(this);
    this.startDelete = this.startDelete.bind(this);
    this.cancelDelete = this.cancelDelete.bind(this);
    this.toggleCertainDaysOfTheWeek =
      this.toggleCertainDaysOfTheWeek.bind(this);
    this.toggleSunday = this.toggleSunday.bind(this);
    this.toggleMonday = this.toggleMonday.bind(this);
    this.toggleTuesday = this.toggleTuesday.bind(this);
    this.toggleWednesday = this.toggleWednesday.bind(this);
    this.toggleThursday = this.toggleThursday.bind(this);
    this.toggleFriday = this.toggleFriday.bind(this);
    this.toggleSaturday = this.toggleSaturday.bind(this);
    this.runNow = this.runNow.bind(this);
    this.onMBChange = this.onMBChange.bind(this);
    this.onFormTypeChange = this.onFormTypeChange.bind(this);
    this.isActiveChange = this.isActiveChange.bind(this);
    this.isRepeatingChange = this.isRepeatingChange.bind(this);
    this.onEditFormType = this.onEditFormType.bind(this);
  }

  componentDidMount() {
    ScheduleStore.addChangeListener(this.onScheduleUpdate);
    IntegrationStore.addChangeListener(this.onIntegrationUpdate);
    MailboxStore.addChangeListener(this.onMBChange);
    FormTypeStore.addChangeListener(this.onFormTypeChange);
    this.updateSchedule();
    GetMailboxList();
    GetAllFormTypes();
  }

  componentWillUnmount() {
    ScheduleStore.removeChangeListener(this.onScheduleUpdate);
    IntegrationStore.removeChangeListener(this.onIntegrationUpdate);
    MailboxStore.removeChangeListener(this.onMBChange);
    FormTypeStore.removeChangeListener(this.onFormTypeChange);
  }

  onMBChange() {
    let mbs = MailboxStore.getMailboxes();
    this.setState({ mailboxes: mbs });
  }

  onFormTypeChange() {
    let fts = FormTypeStore.getAllFormTypes();
    this.setState({ formTypes: fts });
  }

  componentDidUpdate() {
    this.updateSchedule();
  }

  onScheduleUpdate() {
    let lastChange = ScheduleStore.getLastChange();
    if (lastChange === actionTypes.ScheduleUpdateOne) {
      let schedule = ScheduleStore.getSchedule();
      if (schedule.data.id !== this.state.currentSchedule.id) {
        customHistory.push(`/Admin/Schedule/${schedule.data.id}`);
      }

      this.setState({ currentSchedule: schedule.data, isDirty: false });
    }

    if (lastChange === actionTypes.SchedulesGetOne) {
      let sched = ScheduleStore.getSchedule();
      let integrations = this.state.allIntegrations;

      //sched.data = JSON.parse(sched.data);
      if (sched.data === "" || sched.data === null){
        sched.data = {
          active: false,
          completed: false,
          deleted: false,
          rebuildTextIndex: false,
          rebuildRelatedCount: false,
        };
      }
      else {
        sched.data = JSON.parse(sched.data);
      }

      this.setState({ currentSchedule: sched, isNew: false, isDirty: false });
     

      if (sched.weekdays.length > 0) {
        let sunday = false;
        let monday = false;
        let tuesday = false;
        let wednesday = false;
        let thursday = false;
        let friday = false;
        let saturday = false;
        if (sched.weekdays.includes('0')) {
          sunday = true;
        }
        if (sched.weekdays.includes('1')) {
          monday = true;
        }
        if (sched.weekdays.includes('2')) {
          tuesday = true;
        }
        if (sched.weekdays.includes('3')) {
          wednesday = true;
        }
        if (sched.weekdays.includes('4')) {
          thursday = true;
        }
        if (sched.weekdays.includes('5')) {
          friday = true;
        }
        if (sched.weekdays.includes('6')) {
          saturday = true;
        }

        this.setState({
          certainDaysOfTheWeek: true,
          sunday: sunday,
          monday: monday,
          tuesday: tuesday,
          wednesday: wednesday,
          thursday: thursday,
          friday: friday,
          saturday: saturday,
        });
      } else {
        this.setState({
          certainDaysOfTheWeek: false,
          sunday: false,
          monday: false,
          tuesday: false,
          wednesday: false,
          thursday: false,
          friday: false,
          saturday: false,
        });
      }
    }

    if (lastChange === actionTypes.ScheduleDelete) {
      let result = ScheduleStore.getDeleteResult();
      if (result.status !== 'Error'){
        let link = document.getElementById('scheduleHome');
        link.click();
      }
    }
  }

  onIntegrationUpdate() {
    let lastChange = ScheduleStore.getLastChange();
    if (lastChange === actionTypes.IntegrationsGetList) {
      let integrations = IntegrationStore.getIntegrationList();

      // by default, job id is the first option in the list until overwritten
      var currentSched = this.state.currentSchedule;
      //currentSched.jobId = String(integrations[0].id);
      this.setState({
        allIntegrations: integrations,
        isDirty: false,
        currentSchedule: currentSched,
      });
    }
  }

  updateSchedule() {
    let loc = window.location.pathname;
    let pathParts = loc.split('/');
    let schedId = parseInt(pathParts[pathParts.length - 1], 10);
    if (
      schedId !== 0 &&
      (this.state.currentSchedule === undefined ||
        this.state.currentSchedule.id !== schedId)
    ) {
      let Schedule = {
        id: schedId,
      };
      this.setState({ currentSchedule: Schedule, isNew: false });
      GetSchedule(schedId);
      GetScheduledIntegrationList();
    } else if (schedId === 0 && this.state.isNew === false) {
      let currentSchedule = {
        id: 0,
        name: '',
        unitOfTime: '2',
        frequency: '1',
        anchorDateTime: '',
        weekdays: '',
        jobId: '',
        jobType: 1,
        time: '',
        date: '',
        mailBoxId: 1,
        data: {
          active: false,
          completed: false,
          deleted: false,
          rebuildTextIndex: false,
          rebuildRelatedCount: false,
        }
      };
      this.setState({
        currentSchedule: currentSchedule,
        isNew: true,
        certainDaysOfTheWeek: false,
        sunday: false,
        monday: false,
        tuesday: false,
        wednesday: false,
        thursday: false,
        friday: false,
        saturday: false,
      });

      GetScheduledIntegrationList();
    }
  }

  onRename() {
    if (this.state.isRenaming === true) {
      this.setState({ isRenaming: false });
    } else {
      this.setState({ isRenaming: true });
    }
  }

  onEditName(e) {
    var currentSched = this.state.currentSchedule;
    let n = e.target.value;
    currentSched.name = n;
    this.setState({ currentSchedule: currentSched });
  }

  onEditScheduleStartDate(e) {
    var currentSched = this.state.currentSchedule;
    let n = e.target.value;
    currentSched.date = n;
    this.setState({ currentSchedule: currentSched });
  }

  onEditScheduleStartTime(e) {
    var currentSched = this.state.currentSchedule;
    let n = e.target.value;
    currentSched.time = n;
    this.setState({ currentSchedule: currentSched });
  }

  onEditJobType(e) {
    var currentSched = this.state.currentSchedule;
    let n = parseInt(e.target.value);
    currentSched.jobType = n;
    this.setState({ currentSchedule: currentSched });
  }

  onEditJob(e) {
    var currentSched = this.state.currentSchedule;
    let n = e.target.value;
    currentSched.jobId = n;
    this.setState({ currentSchedule: currentSched });
  }

  onEditMailbox(e) {
    var currentSched = this.state.currentSchedule;
    let x2 = this.state.mailboxes;
    let n = e.target.value;
    let x = e.target.key;
    this.state.currentSchedule.mailBoxId = parseInt(n);
    this.setState({ currentSchedule: currentSched });
  }

  onEditFormType(e) {
    var currentSched = this.state.currentSchedule;
    let n = e.target.value;
    this.state.currentSchedule.jobId = n;

    this.setState({ currentSchedule: currentSched });
  }

  onEditInterval(e) {
    var currentSched = this.state.currentSchedule;
    let n = e.target.value;
    currentSched.frequency = n;
    this.setState({ currentSchedule: currentSched });
  }

  unitOfTimeChange(e) {
    var currentSched = this.state.currentSchedule;
    let n = e.target.value;
    currentSched.unitOfTime = n;
    this.setState({ currentSchedule: currentSched });
  }

  onRenaming(e) {
    let cm = this.state.currentSchedule;
    cm.ScheduleName = e.target.value;
    this.setState({ currentSchedule: cm });
  }

  onChangeView(newView) {
    this.setState({ currentView: newView });
  }

  toggleCertainDaysOfTheWeek() {
    if (this.state.certainDaysOfTheWeek === true) {
      var currentSched = this.state.currentSchedule;
      currentSched.weekdays = '';
      this.setState({
        certainDaysOfTheWeek: false,
        sunday: false,
        monday: false,
        tuesday: false,
        wednesday: false,
        thursday: false,
        friday: false,
        saturday: false,
        currentSchedule: currentSched,
      });
    } else {
      this.setState({ certainDaysOfTheWeek: true });
    }
  }

  handleCommas(weekdays) {
    weekdays = weekdays.replaceAll(',,', ',');
    weekdays = weekdays.replace(/^,/, '');
    weekdays = weekdays.replace(/,\s*$/, '');
    return weekdays;
  }

  toggleSunday() {
    var currentSched = this.state.currentSchedule;

    if (this.state.sunday === true) {
      this.setState({ sunday: false });
      currentSched.weekdays = currentSched.weekdays.replaceAll('0', '');
    } else {
      this.setState({ sunday: true });
      currentSched.weekdays = currentSched.weekdays + ',0';
    }

    currentSched.weekdays = this.handleCommas(currentSched.weekdays);
    this.setState({ currentSchedule: currentSched });
  }

  toggleMonday() {
    var currentSched = this.state.currentSchedule;

    if (this.state.monday === true) {
      this.setState({ monday: false });
      currentSched.weekdays = currentSched.weekdays.replaceAll('1', '');
    } else {
      this.setState({ monday: true });
      currentSched.weekdays = currentSched.weekdays + ',1';
    }

    currentSched.weekdays = this.handleCommas(currentSched.weekdays);
    this.setState({ currentSchedule: currentSched });
  }

  toggleTuesday() {
    var currentSched = this.state.currentSchedule;

    if (this.state.tuesday === true) {
      this.setState({ tuesday: false });
      currentSched.weekdays = currentSched.weekdays.replaceAll('2', '');
    } else {
      this.setState({ tuesday: true });
      currentSched.weekdays = currentSched.weekdays + ',2';
    }

    currentSched.weekdays = this.handleCommas(currentSched.weekdays);
    this.setState({ currentSchedule: currentSched });
  }

  toggleWednesday() {
    var currentSched = this.state.currentSchedule;

    if (this.state.wednesday === true) {
      this.setState({ wednesday: false });
      currentSched.weekdays = currentSched.weekdays.replaceAll('3', '');
    } else {
      this.setState({ wednesday: true });
      currentSched.weekdays = currentSched.weekdays + ',3';
    }

    currentSched.weekdays = this.handleCommas(currentSched.weekdays);
    this.setState({ currentSchedule: currentSched });
  }

  toggleThursday() {
    var currentSched = this.state.currentSchedule;

    if (this.state.thursday === true) {
      this.setState({ thursday: false });
      currentSched.weekdays = currentSched.weekdays.replaceAll('4', '');
    } else {
      this.setState({ thursday: true });
      currentSched.weekdays = currentSched.weekdays + ',4';
    }

    currentSched.weekdays = this.handleCommas(currentSched.weekdays);
    this.setState({ currentSchedule: currentSched });
  }

  toggleFriday() {
    var currentSched = this.state.currentSchedule;

    if (this.state.friday === true) {
      this.setState({ friday: false });
      currentSched.weekdays = currentSched.weekdays.replaceAll('5', '');
    } else {
      this.setState({ friday: true });
      currentSched.weekdays = currentSched.weekdays + ',5';
    }

    currentSched.weekdays = this.handleCommas(currentSched.weekdays);
    this.setState({ currentSchedule: currentSched });
  }

  toggleSaturday() {
    var currentSched = this.state.currentSchedule;

    if (this.state.saturday === true) {
      this.setState({ saturday: false });
      currentSched.weekdays = currentSched.weekdays.replaceAll('6', '');
    } else {
      this.setState({ saturday: true });
      currentSched.weekdays = currentSched.weekdays + ',6';
    }

    currentSched.weekdays = this.handleCommas(currentSched.weekdays);
    this.setState({ currentSchedule: currentSched });
  }

  saveSchedule() {

    var schedule = {
      id: this.state.currentSchedule.id,
      name: this.state.currentSchedule.name,
      unitOfTime: this.state.currentSchedule.unitOfTime,
      frequency: this.state.currentSchedule.frequency,
      anchorDateTime: this.state.currentSchedule.anchorDateTime,
      weekdays: this.state.currentSchedule.weekdays,
      jobId: this.state.currentSchedule.jobId,
      jobType: this.state.currentSchedule.jobType,
      time: this.state.currentSchedule.time,
      date: this.state.currentSchedule.date,
      mailBoxId: this.state.currentSchedule.mailBoxId,
      isActive: this.state.currentSchedule.isActive,
      isRepeating: this.state.currentSchedule.isRepeating,
      data: JSON.stringify(this.state.currentSchedule.data)
    }

    UpdateSchedule(schedule);
  }

  runNow() {
    RunNow(this.state.currentSchedule.id);
  }

  isActiveChange(e) {
    let cs = this.state.currentSchedule;
    cs.isActive = e.target.checked;
    this.setState({ currentSchedule: cs, isDirty: true });
  }

  isRepeatingChange(e) {
    let cs = this.state.currentSchedule;
    cs.isRepeating = e.target.checked;
    this.setState({ currentSchedule: cs, isDirty: true });
  }

  changeReindexSettings(e, change) {
    let cs = this.state.currentSchedule;
    if (change === 'active') {
      cs.data.active = e.target.checked;
    }
    if (change === 'completed') {
      cs.data.completed = e.target.checked;
    }
    if (change === 'deleted') {
      cs.data.deleted = e.target.checked;
    }

    if (change === 'rebuildTextIndex') {
      cs.data.rebuildTextIndex = e.target.checked;
    }
    if (change === 'rebuildRelatedCount') {
      cs.data.rebuildRelatedCount = e.target.checked;
    }

    this.setState({ currentSchedule: cs });
  }


  startDelete() {
    this.setState({ deleteStatus: 1 });
  }

  deleteSchedule() {
    DeleteSchedule(this.state.currentSchedule.id);
  }

  cancelDelete() {
    this.setState({ deleteStatus: 0 });
  }

  render() {
    return (
      <div className='container-fluid' style={{ height: '100%' }}>
        <div className='row flex-nowrap' style={{ height: '100%' }}>
          <ScheduleSidebar />
          <Link
            id='scheduleHome'
            className='nav-link'
            to={`/Admin/Schedules`}
            style={{ display: 'none' }}
          ></Link>
          <div className='flex-fill' style={{ backgroundColor: '#efefef' }}>
            {this.state.currentSchedule !== undefined && (
              <div className='flex-fill' style={{ backgroundColor: '#efefef' }}>
                <div className='card m-3'>
                  <div className='card-header'>
                    {this.state.isNew && <span>Create new Schedule</span>}
                    {!this.state.isNew && (
                      <>
                        <span>Update Schedule</span>
                        <span style={{ margin: '1%' }}>
                          <button
                            className='btn btn-success'
                            disabled={!this.state.currentSchedule.isActive ? 'disabled' : ''}
                            onClick={() => this.runNow()}
                          >
                            <FontAwesomeIcon icon={solid('play')} /> Run Now
                          </button>
                        </span>
                      </>
                    )}
                    <span style={{ float: 'right' }}>
                      <button
                        className='btn btn-success'
                        onClick={() => this.saveSchedule()}
                      >
                        <FontAwesomeIcon icon={solid('save')} /> Save
                      </button>
                    </span>
                  </div>
                  <div className='card-body p-4'>
                    {this.state.isUploading && (
                      <div style={{ height: '80px', textAlign: 'center' }}>
                        <span style={{ color: '#30E830' }}>
                          <FontAwesomeIcon
                            icon={solid('spinner')}
                            size='4x'
                            spin
                          />
                        </span>
                        <div className='mt-2 mb-2'>Uploading...</div>
                      </div>
                    )}
                    {!this.state.isUploading &&
                      this.state.upload === undefined && (
                        <>
                          <div className='row'>
                            <div className='mb-3 col-4'>
                              <label className='form-label'>Name:</label>
                              <input
                                value={this.state.currentSchedule.name}
                                onChange={this.onEditName}
                                type='text'
                                className='form-control'
                              />
                            </div>
                            <div className='form-check form-switch m-3 mt-0 mb-2'>
                              <input
                                type='checkbox'
                                className='form-check-input'
                                checked={this.state.currentSchedule.isActive}
                                onChange={this.isActiveChange}
                                id='chkActive'
                              />
                              <label className='form-check-label' htmlFor='chkActive'>
                                Active
                              </label>
                            </div>
                          </div>
                          <div className='row'>
                            <div className='mb-3 col-4'>
                              <label className='form-label'>Job Type:</label>
                              <select
                                className='form-control'
                                aria-label='Select Job Type'
                                value={this.state.currentSchedule.jobType}
                                onChange={this.onEditJobType}
                              >
                                <option value='1'>Scheduled Plugin</option>
                                <option value='2'>Notification Schedule</option>
                                <option value='3'>
                                  Checked out document reset
                                </option>
                                <option value='4'>Error document reset</option>
                                <option value='5'>Scheduled Index</option>
                              </select>
                            </div>
                          </div>
                          {this.state.currentSchedule.jobType === 1 && (
                            <div className='row'>
                              <div className='mb-3 col-4'>
                                <label className='form-label'>Job:</label>
                                <select
                                  value={this.state.currentSchedule.jobId}
                                  onChange={this.onEditJob}
                                  type='text'
                                  className='form-control'
                                >
                                  <option value='-1'></option>
                                  {this.state.allIntegrations.map(
                                    (integration) => (
                                      <option
                                        key={integration.id}
                                        value={integration.id}
                                      >
                                        {integration.integrationName}
                                      </option>
                                    )
                                  )}
                                  ;
                                </select>
                              </div>
                            </div>
                          )}
                          {this.state.currentSchedule.jobType === 2 && (
                            <div className='row'>
                              <div className='mb-3 col-4'>
                                <label className='form-label'>Mailbox:</label>
                                <select
                                  value={this.state.currentSchedule.mailBoxId}
                                  onChange={this.onEditMailbox}
                                  type='text'
                                  className='form-control'
                                >
                                  <option value='-1'></option>
                                  {this.state.mailboxes
                                    .filter((r) => r.mailboxType === 12)
                                    .map((mailbox) => (
                                      <option
                                        key={mailbox.mailboxId}
                                        value={mailbox.mailboxId}
                                      >
                                        {mailbox.name}
                                      </option>
                                    ))}
                                  ;
                                </select>
                              </div>
                            </div>
                          )}
                          {this.state.currentSchedule.jobType === 3 && (
                            <div className='row'>
                              <div className='mb-3 col-4'>
                                <label className='form-label'>
                                  Reset documents that have been checked out for
                                  how many hours?
                                </label>
                                <input
                                  value={this.state.currentSchedule.jobId}
                                  onChange={this.onEditJob}
                                  type='number'
                                  className='form-control'
                                />
                              </div>
                            </div>
                          )}
                          {this.state.currentSchedule.jobType === 5 && (
                            <span>
                            <div className='row'>
                              <div className='mb-3 col-4'>
                                <label className='form-label'>
                                  Form Type to Index:
                                </label>
                                <select
                                  value={this.state.currentSchedule.jobId}
                                  onChange={this.onEditFormType}
                                  type='text'
                                  className='form-control'
                                >
                                  <option value='-1'></option>
                                  {this.state.formTypes
                                  
                                    .map((formType) => (
                                      <option
                                        key={formType.formTypeId}
                                        value={formType.formTypeId}
                                      >
                                        {formType.name}
                                      </option>
                                    ))}
                                  ;
                                </select>
                              </div>
                            </div>

                            <div className='card w-25 mb-2'>
                              <div className='card-header'>
                                Documents to Index
                              </div>
                            <div className='card-body'>
                              <div className='form-check'>
                                <input
                                  className='form-check-input'
                                  type='checkbox'
                                  checked={this.state.currentSchedule.data.active}
                                  onChange={(e) =>
                                    this.changeReindexSettings(e, 'active')
                                  }
                                />
                                <label className='form-check-label'>
                                  Active
                                </label>
                              </div>
                              <div className='form-check'>
                                <input
                                  className='form-check-input'
                                  type='checkbox'
                                  checked={this.state.currentSchedule.data.completed}
                                  onChange={(e) =>
                                    this.changeReindexSettings(e, 'completed')
                                  }
                                />
                                <label className='form-check-label'>
                                  Completed
                                </label>
                              </div>
                              <div className='form-check'>
                                <input
                                  className='form-check-input'
                                  type='checkbox'
                                  checked={this.state.currentSchedule.data.deleted}
                                  onChange={(e) =>
                                    this.changeReindexSettings(e, 'deleted')
                                  }
                                />
                                <label className='form-check-label'>
                                  Deleted
                                </label>
                              </div>
                            </div>
                            <hr />
                           
                            <div className='card-body'>
                              <div className='form-check'>
                                <input
                                  className='form-check-input'
                                  type='checkbox'
                                  checked={this.state.currentSchedule.data.rebuildTextIndex}
                                  onChange={(e) =>
                                    this.changeReindexSettings(e, 'rebuildTextIndex')
                                  }
                                />
                                <label className='form-check-label'>
                                  Rebuild Text Index{' '}
                                </label>
                              </div>
                              <div className='form-check'>
                                <input
                                  className='form-check-input'
                                  type='checkbox'
                                  checked={this.state.currentSchedule.data.rebuildRelatedCount}
                                  onChange={(e) =>
                                    this.changeReindexSettings(e, 'rebuildRelatedCount')
                                  }
                                />
                                <label className='form-check-label'>
                                  Rebuild Related Counts{' '}
                                </label>
                              </div>
                            </div>


                            </div>
                            </span>
                          )}
                          <div className='row'>
                            <div className='mb-3 col-2'>
                              <label className='form-label'>Start Date:</label>
                              <input
                                value={this.state.currentSchedule.date}
                                onChange={this.onEditScheduleStartDate}
                                type='date'
                                className='form-control'
                              />
                            </div>
                            <div className='mb-3 col-2'>
                              <label className='form-label'>Start Time:</label>
                              <input
                                value={this.state.currentSchedule.time}
                                onChange={this.onEditScheduleStartTime}
                                type='time'
                                className='form-control'
                              />
                            </div>
                          </div>

                          <div className='form-check form-switch m-3 mt-0 mb-2'>
                              <input
                                type='checkbox'
                                className='form-check-input'
                                checked={this.state.currentSchedule.isRepeating}
                                onChange={this.isRepeatingChange}
                                id='chkActive'
                              />
                              <label className='form-check-label' htmlFor='chkRepeating'>
                                Repeating
                              </label>
                            </div>

                          {this.state.currentSchedule.isRepeating && (
                            <span>
                            <div className='row'>
                            <div className='mb-3 col-2'>
                              <label className='form-label'>
                                Execute every:
                              </label>
                              <input
                                value={this.state.currentSchedule.frequency}
                                onChange={this.onEditInterval}
                                type='number'
                                className='form-control'
                              />
                            </div>
                            <div className='mb-3 col-2'>
                              <label className='form-label'>
                                Unit of Time:
                              </label>
                              <select
                                className='form-control'
                                aria-label='Select Unit of Time'
                                value={this.state.currentSchedule.unitOfTime}
                                onChange={this.unitOfTimeChange}
                              >
                                <option value='1'>Minute(s)</option>
                                <option value='2'>Hour(s)</option>
                                <option value='3'>Day(s)</option>
                                <option value='4'>Week(s)</option>
                              </select>
                            </div>
                          </div>

                          <div className='row'>
                            <div className='mb-3 col-2'>
                              <div className='form-check form-switch'>
                                <label className='form-label'>
                                  Only on certain days of the week:
                                </label>
                                <input
                                  value={this.state.certainDaysOfTheWeek}
                                  checked={this.state.certainDaysOfTheWeek}
                                  onChange={this.toggleCertainDaysOfTheWeek}
                                  type='checkbox'
                                  className='form-check-input'
                                />
                              </div>
                            </div>
                          </div>

                          {this.state.certainDaysOfTheWeek && (
                            <div className='row'>
                              <div className='form-check form-switch col'>
                                <label className='form-label'>Sunday</label>
                                <input
                                  value={this.state.sunday}
                                  checked={this.state.sunday}
                                  onChange={this.toggleSunday}
                                  type='checkbox'
                                  className='form-check-input'
                                />
                              </div>

                              <div className='form-check form-switch col'>
                                <label className='form-label'>Monday</label>
                                <input
                                  value={this.state.monday}
                                  checked={this.state.monday}
                                  onChange={this.toggleMonday}
                                  type='checkbox'
                                  className='form-check-input'
                                />
                              </div>

                              <div className='form-check form-switch col'>
                                <label className='form-label'>Tuesday</label>
                                <input
                                  value={this.state.tuesday}
                                  checked={this.state.tuesday}
                                  onChange={this.toggleTuesday}
                                  type='checkbox'
                                  className='form-check-input'
                                />
                              </div>

                              <div className='form-check form-switch col'>
                                <label className='form-label'>Wednesday</label>
                                <input
                                  value={this.state.wednesday}
                                  checked={this.state.wednesday}
                                  onChange={this.toggleWednesday}
                                  type='checkbox'
                                  className='form-check-input'
                                />
                              </div>

                              <div className='form-check form-switch col'>
                                <label className='form-label'>Thursday</label>
                                <input
                                  value={this.state.thursday}
                                  checked={this.state.thursday}
                                  onChange={this.toggleThursday}
                                  type='checkbox'
                                  className='form-check-input'
                                />
                              </div>

                              <div className='form-check form-switch col'>
                                <label className='form-label'>Friday</label>
                                <input
                                  value={this.state.friday}
                                  checked={this.state.friday}
                                  onChange={this.toggleFriday}
                                  type='checkbox'
                                  className='form-check-input'
                                />
                              </div>

                              <div className='form-check form-switch col'>
                                <label className='form-label'>Saturday</label>
                                <input
                                  value={this.state.saturday}
                                  checked={this.state.saturday}
                                  onChange={this.toggleSaturday}
                                  type='checkbox'
                                  className='form-check-input'
                                />
                              </div>
                            </div>
                          )}
                            </span>
                          )}

                        </>
                      )}
                  </div>
                </div>
                {!this.state.isNew && (
                  <span className='float-right'>
                    <button
                      style={{
                        marginTop: '8px',
                        padding: '12px',
                        marginLeft: '6px',
                        marginRight: '15px',
                      }}
                      className='btn btn-danger btn-lg float-end'
                      onClick={this.startDelete}
                    >
                      <i
                        className='fas fa-times'
                        style={{ marginRight: '8px' }}
                      ></i>
                      {'   '}
                      Delete Scheduled Action
                    </button>

                    {this.state.deleteStatus === 1 && (
                      <div
                        className='alert alert-danger float-end'
                        style={{ marginTop: '6px' }}
                        role='alert'
                      >
                        <p>Are you sure you want to delete this schedule?</p>

                        <button
                          className='btn btn-danger'
                          onClick={this.deleteSchedule}
                        >
                          Yes - Delete
                        </button>
                        <button
                          className='btn btn-secondary'
                          style={{ marginLeft: '6px' }}
                          onClick={this.cancelDelete}
                        >
                          Cancel
                        </button>
                      </div>
                    )}
                  </span>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default ScheduleView;
