import * as React from 'react';
import AutoCompleteExtenderRow from '../../../components/AutoCompleteExtenderRow';

class TableCellEditor extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      currentText: '',
      currentValue: '',
      extenderKey: '',
      isKeyValue: false,
      myFieldTitle: '',
      errorMsg: '',
    };

    this.onEditField = this.onEditField.bind(this);
    this.onCopyField = this.onCopyField.bind(this);
    this.handleKeyEvent = this.handleKeyEvent.bind(this);
    this.gotFocus = this.gotFocus.bind(this);
    this.lostFocus = this.lostFocus.bind(this);
    this.editText = this.editText.bind(this);
    this.editNumText = this.editNumText.bind(this);
    this.onEditExtenderField = this.onEditExtenderField.bind(this);
    this.OnAutoCompleteEdit = this.OnAutoCompleteEdit.bind(this);
    this.onMouseDownCapture = this.onMouseDownCapture.bind(this);

    this.onEditDateField = this.onEditDateField.bind(this);
    this.onBlurDateField = this.onBlurDateField.bind(this);
    this.setError = this.setError.bind(this);
    this.clearError = this.clearError.bind(this);
  }

  componentDidMount() {
    this.setState({
      currentText: this.props.textValue,
      currentValue: this.props.value,
      extenderKey: this.props.extenderKey,
    });

    if (this.props.fieldDef.dataType === 1) {
      var tempNumber = 0;

      let numDecimalPlaces = this.props.fieldDef.decimalPlaces;

      if (numDecimalPlaces === null || numDecimalPlaces === undefined) {
        numDecimalPlaces = 2;
      }

      this.setState({
        myFieldTitle: 'Format: ' + tempNumber.toFixed(numDecimalPlaces),
      });
    }
    if (this.props.fieldDef.dataType === 2) {
      this.setState({ myFieldTitle: 'Format: ' + this.props.fieldDef.format });
    }

    if (
      this.props.error !== undefined &&
      this.props.error !== null &&
      this.props.error !== ''
    ) {
      this.setState({ myFieldTitle: this.props.error });
    }

    if (this.props.fieldDef.showTotal) {
      this.props.calculateRunningTotal(
        this.props.fieldDef.decimalPlaces,
        this.props.fieldDef.fieldId,
        this.props.fieldSetId.fieldSetId
      );
    }
    if (this.props.colId === 253) {
      //console.log('here');
    }

    if (
      this.props.allowedValues !== null &&
      this.props.allowedValues !== null &&
      this.props.allowedValues.length > 0
    ) {
      if (this.props.extender.type === 4) {
        let x = this.props.allowedValues[0];
        if (x.description !== null) {
          this.setState({ isKeyValue: true });
        }
      }
      //if value from database doesnt exist in allowed values create value and set error
      let val = this.props.allowedValues.find(
        (av) => av.textValue === this.props.textValue
      );
      if (val === undefined || val === null) {
        //let x = this.props.allowedValues;
        //x.push()
        if (this.props.extender.type === 4) {
          this.props.setLineFieldError(
            this.props.fieldDef.fieldId,
            'Value is not available in drop down list: ( ' +
              this.props.textValue +
              ' )',
            this.props.fieldSetId.fieldSetId,
            this.props.rowId
          );
          this.setState({
            currentText: this.props.textValue,
            currentValue: '-1',
          });
          this.setState({
            currentText: this.props.textValue,
            currentValue: '-1',
          });
        } else if (
          this.props.extender.type === 1 ||
          this.props.extender.type === 2
        ) {
          this.props.setLineFieldError(
            this.props.fieldDef.fieldId,
            'Value is not available in drop down list: ( ' +
              this.props.textValue +
              ' )',
            this.props.fieldSetId.fieldSetId,
            this.props.rowId
          );
          this.setState({
            currentText: this.props.textValue,
            currentValue: '-1',
          });
        }

        //console.log("uhoh");
      }

      this.setState({
        currentText: this.props.textValue,
        currentValue: this.props.value,
        extenderKey: this.props.extenderKey,
      });
    }
  }

  componentWillUnmount() {}
  componentDidUpdate(PrevProps, prevState) {
    if (
      this.props.error !== undefined &&
      this.props.error !== null &&
      this.props.error !== '' &&
      PrevProps.error !== this.props.error
    ) {
      this.setState({ myFieldTitle: this.props.error });
    }
    if (this.props.allowedValues !== PrevProps.allowedValues) {
      if (this.props.allowedValues.length > 0) {
        if (this.props.extender.type === 4) {
          // let x = this.props.allowedValues[0];
          // this.setState({
          //   currentValue: x.extenderKey,
          //   currentText: x.textValue,
          //   extenderKey: x.extenderKey,
          //   textValue: x.textValue,
          //   showList: false,
          // });
          //       this.props.updateValue(
          //         x.textValue,
          //         this.props.rowId,
          //         this.props.colId,
          //         x.extenderKey,
          //         this.props.fieldSetId.fieldSetId
          //        );
        }
      }
    }
  }

  onMouseDownCapture(e) {
    var newText = e.target.value;
    if (this.props.error !== '') {
      this.props.setLineFieldError(
        this.props.fieldDef.fieldId,
        '',
        this.props.fieldSetId.fieldSetId,
        this.props.rowId
      );
      this.props.updateValue(
        newText,
        this.props.rowId,
        this.props.colId,
        this.state.extenderKey,
        this.props.fieldSetId.fieldSetId
      );
    }
  }
  onEditField(e) {
    // lots of work to go here
    var newText = e.target.value;
    var allowChange = true;
    if (this.props.fieldType === 1) {
      // its a number
      if (newText !== '') {
        if (isNaN(newText)) {
          allowChange = false;
        }
      }
      if (newText === '-') {
        allowChange = true;
      }
      if (newText === '') {
        this.props.updateValue(
          newText,
          this.props.rowId,
          this.props.colId,
          this.state.extenderKey,
          this.props.fieldSetId.fieldSetId
        );
        this.props.setLineFieldError(
          this.props.fieldDef.fieldId,
          '',
          this.props.fieldSetId.fieldSetId,
          this.props.rowId
        );
      }
    }

    //console.log('fieldType');
    //console.log(this.props.fieldType);
    if (allowChange) {

      this.props.updateValue(
        newText,
        this.props.rowId,
        this.props.colId,
        this.state.extenderKey,
        this.props.fieldSetId.fieldSetId
      );

      if (this.props.fieldDef.showTotal) {
        this.props.calculateRunningTotal(
          this.props.fieldDef.decimalPlaces,
          this.props.fieldDef.fieldId,
          this.props.fieldSetId.fieldSetId
        );
      }
    }
  }

  onEditDateField(e) {
    let newValue = e.target.value;
    let allowChange = true;
    newValue = newValue.replace('/', '');
    newValue = newValue.replace('/', '');
    newValue = newValue.replace('.', '');
    newValue = newValue.replace('.', '');
    newValue = newValue.replace('-', '');
    newValue = newValue.replace('-', '');
    //let m = 0;
    //let d = 0;
    //let y = 0;
    //let separator = '/';
    //if (this.props.format === 'mm-dd-yyyy') separator = '-';
    if (isNaN(newValue)) {
      allowChange = false;
    }
    if (
      allowChange &&
      (this.props.fieldDef.format === 'mm/dd/yyyy' ||
        this.props.fieldDef.format === 'mm-dd-yyyy')
    ) {
      if (newValue.length === 2) {
      }
      if (newValue.length === 4) {
      }
      if (newValue.length === 6) {
        // m = parseInt(newVsetLineFieldErroralue.substring(0, 2), 10);
        // d = parseInt(newValue.substring(2, 4), 10);
        // y = parseInt(newValue.substring(4, 6), 10);
        // y = 2000 + y;
        //let date = new Date(y, m - 1, d);
      }
    }

    if (allowChange) {
      this.props.updateValue(
        e.target.value,
        this.props.rowId,
        this.props.colId,
        this.state.extenderKey,
        this.props.fieldSetId.fieldSetId
      );
    }
  }
  onBlurDateField(e) {
    let newValue = e.target.value;
    if (newValue.length === 5 && !isNaN(newValue)) {
      newValue = '0' + newValue;
    }
    let format = this.props.fieldDef.format;

    if (newValue === '') {
      this.props.updateValue(
        newValue,
        this.props.fieldId,
        this.props.systemName
      );
      return;
    }
    let allowChange = true;

    if (
      format === 'mm/dd/yyyy' ||
      format === 'dd/mm/yyyy' ||
      format === 'mm-dd-yyyy'
    ) {

      let parts1 = newValue.split('/');
      if (format === 'mm-dd-yyyy') parts1 = newValue.split('-');
      if (parts1.length === 3) {
        if (parts1[0].length === 1) {
          parts1[0] = '0' + parts1[0];
        }
        if (parts1[1].length === 1) {
          parts1[1] = '0' + parts1[1];
        }
        if (parts1[2].length === 2) {
          parts1[2] = '20' + parts1[2];
        }
        newValue = parts1[0] + '/' + parts1[1] + '/' + parts1[2];
      }

    }

    newValue = newValue.replace('/', '');
    newValue = newValue.replace('/', '');
    newValue = newValue.replace('.', '');
    newValue = newValue.replace('.', '');
    newValue = newValue.replace('-', '');
    newValue = newValue.replace('-', '');


    let m = 0;
    let d = 0;
    let y = 0;

    if (isNaN(newValue)) {
      allowChange = false;
    }

    if (
      allowChange &&
      (format === 'mm/dd/yyyy' ||
        format === 'mm-dd-yyyy')
    ) {
      console.log('checkFormat2');
      if (newValue.length === 6) {
        m = parseInt(newValue.substring(0, 2), 10);
        d = parseInt(newValue.substring(2, 4), 10);
        y = parseInt(newValue.substring(4, 6), 10);
        y = 2000 + y;

        let date = new Date(y, m - 1, d);

        if (date.toString() === 'Invalid Date') {
          allowChange = false;
        }
        if (date.getDate() !== d) {
          allowChange = false;
        }
        let newDate = this.props.fieldDef.format;
        newDate = newDate.replace('mm', newValue.substring(0, 2));
        newDate = newDate.replace('dd', newValue.substring(2, 4));
        newDate = newDate.replace('yyyy', '20' + newValue.substring(4, 6));
        if (allowChange) {
          this.props.updateValue(
            newDate,
            this.props.rowId,
            this.props.colId,
            this.state.extenderKey,
            this.props.fieldSetId.fieldSetId
          );
        }
      } else if (newValue.length === 8) {
        m = parseInt(newValue.substring(0, 2), 10);
        d = parseInt(newValue.substring(2, 4), 10);
        y = parseInt(newValue.substring(4, 8), 10);
        //y = 2000 + y;
        console.log(m);
        if (m > 12) {
          allowChange = false;
        }

        let date = new Date(y, m - 1, d);
        console.log(date);
        if (date.toString() === 'Invalid Date') {
          allowChange = false;
        }
        if (date.getDate() !== d) {
          allowChange = false;
        }
        let newDate = this.props.fieldDef.format;
        newDate = newDate.replace('mm', newValue.substring(0, 2));
        newDate = newDate.replace('dd', newValue.substring(2, 4));
        newDate = newDate.replace('yyyy', newValue.substring(4, 8));
        if (allowChange) {
          this.props.updateValue(
            newDate,
            this.props.rowId,
            this.props.colId,
            this.state.extenderKey,
            this.props.fieldSetId.fieldSetId
          );
        }
      } else {
        allowChange = false;
      }
    } else if (
      allowChange &&
      (this.props.fieldDef.format === 'dd/mm/yyyy' ||
        this.props.fieldDef.format === 'dd-mm-yyyy')
    ) {
      if (newValue.length === 6) {
        d = parseInt(newValue.substring(0, 2), 10);
        m = parseInt(newValue.substring(2, 4), 10);
        y = parseInt(newValue.substring(4, 6), 10);
        y = 2000 + y;
        console.log(m);
        let date = new Date(y, m - 1, d);

        if (date.toString() === 'Invalid Date') {
          allowChange = false;
        }
        if (date.getDate() !== d) {
          allowChange = false;
        }
        let newDate = this.props.fieldDef.format;
        newDate = newDate.replace('mm', newValue.substring(2, 4));
        newDate = newDate.replace('dd', newValue.substring(0, 2));
        newDate = newDate.replace('yyyy', '20' + newValue.substring(4, 6));
        if (allowChange) {
          this.props.updateValue(
            newDate,
            this.props.rowId,
            this.props.colId,
            this.state.extenderKey,
            this.props.fieldSetId.fieldSetId
          );
        }
      } else if (newValue.length === 8) {
        d = parseInt(newValue.substring(0, 2), 10);
        m = parseInt(newValue.substring(2, 4), 10);
        y = parseInt(newValue.substring(4, 8), 10);
        //y = 2000 + y;
        console.log(m);
        let date = new Date(y, m - 1, d);
        console.log(date);
        if (m > 12) {
          allowChange = false;
        }
        if (date.toString() === 'Invalid Date') {
          allowChange = false;
        }
        if (date.getDate() !== d) {
          allowChange = false;
        }
        let newDate = format;
        newDate = newDate.replace('dd', newValue.substring(0, 2));
        newDate = newDate.replace('mm', newValue.substring(2, 4));
        newDate = newDate.replace('yyyy', newValue.substring(4, 8));
        if (allowChange) {
          this.props.updateValue(
            newDate,
            this.props.rowId,
            this.props.colId,
            this.state.extenderKey,
            this.props.fieldSetId.fieldSetId
          );
        }
      } else {
        allowChange = false;
      }
    }
    if (!allowChange) {
      this.props.setLineFieldError(
        this.props.fieldDef.fieldId,
        `Value entered in this field is invalid. Field must follow date format ${this.props.fieldDef.format}`,
        this.props.fieldSetId.fieldSetId,
        this.props.rowId
      );
    }
    if (allowChange && this.props.error === '') {
      this.props.setLineFieldError(
        this.props.fieldDef.fieldId,
        '',
        this.props.fieldSetId.fieldSetId,
        this.props.rowId
      );
    }
  }

  onEditExtenderField(e, keyValue) {
    // lots of work to go here
    let newText = e.target.value;
    //if(newText===this.state.currentText){
    //return;
    //}

    let allowChange = true;
    let extMatch = this.props.allowedValues.find(
      (p) => p.textValue.trim() === newText
    );
    let key = '0';

    let fSetId = this.props.fieldSetId.fieldSetId;

    if (extMatch) key = extMatch.extenderKey;

    this.setState({
      currentText: newText,
      currentValue: newText,
      extenderKey: key,
    });

    if (allowChange) {
      this.props.updateValue(
        newText,
        this.props.rowId,
        this.props.colId,
        key,
        fSetId
      );
    }
  }

  onCopyField() {
    navigator.clipboard.writeText(this.props.value);
  }

  handleKeyEvent(e) {
    if (e.keyCode === 40) {
      e.preventDefault();
      this.props.onMoveCell('down');
    }
    if (e.keyCode === 38) {
      this.props.onMoveCell('up');
    }
    if (e.keyCode === 37 && e.ctrlKey) {
      this.props.onMoveCell('left');
    }
    if (e.keyCode === 39 && e.ctrlKey) {
      this.props.onMoveCell('right');
    }
    if (e.code === "Enter") {
      this.props.onMoveCell('right');
    }
  }

  gotFocus() {
    this.props.onFocus(this.props.rowId, this.props.colId, this.props.orderId);
  }
  OnAutoCompleteEdit(textValue, fieldId, extenderKey) {
    let val = this.props.allowedValues.find((av) => av.textValue === textValue);
    if (val) {

      console.log(this.props.extenderId);
      if (val !== undefined) {
        this.props.updateValue(
          val.textValue,
          this.props.rowId,
          fieldId,
          extenderKey,
          this.props.fieldSetId.fieldSetId
        );
      }
    }
  }
  lostFocus(e) {
    if (this.props.fieldDef.dataType === 1 && e.target.value !== '') {
      // numeric,  check for extra digits.
      let newValue = e.target.value;
      let indexOfDecimal = newValue.indexOf('.');
      if (indexOfDecimal === -1) {
        // add the decimal
        newValue = newValue + '.';
        indexOfDecimal = newValue.indexOf('.');
      }
      let currentDigits = newValue.length - indexOfDecimal - 1;
      if (currentDigits < this.props.fieldDef.decimalPlaces) {
        while (currentDigits < this.props.fieldDef.decimalPlaces) {
          newValue = newValue + '0';
          currentDigits++;
        }
        this.props.updateField(
          newValue,
          this.props.rowId,
          this.props.colId,
          '-1',
          this.props.fieldSetId.fieldSetId
        );
      }
    } else if (this.props.fieldDef.dataType === 1 && e.target.value === '') {
      this.props.updateValue(
        e.target.value,
        this.props.rowId,
        this.props.colId,
        this.state.extenderKey,
        this.props.fieldSetId.fieldSetId
      );
      this.props.setLineFieldError(
        this.props.fieldDef.fieldId,
        '',
        this.props.fieldSetId.fieldSetId,
        this.props.rowId
      );
    }
  }

  editText(e) {
    //this.setState({currentText: e.target.value});
    let x = this.props.fieldSetId.fieldSetId;
    if (x === undefined) {
      console.log('test');
    }
    this.props.updateField(
      e.target.value,
      this.props.rowId,
      this.props.colId,
      '-1',
      this.props.fieldSetId.fieldSetId
    );
  }

  editNumText(e) {
    let newValue = e.target.value;
    let allowUpdate = false;
    let currentVal = this.props.value;
    let re = /^-?[0-9]*\.?[0-9]*$/;

    if (newValue === '') allowUpdate = true;
    if (newValue === '-') allowUpdate = true;
    if (newValue === '-.') {
      allowUpdate = true;
      newValue = '-0.';
    }
    if(newValue === '.')
    {
      newValue = '0.';
    }
    let test = re.test(newValue);
    if(test) allowUpdate = true;
    if (!isNaN(+newValue)) {
      let indexOfDecimal = newValue.indexOf('.');
      if (indexOfDecimal > -1) {
        allowUpdate = true;
        // there is a decimal
        if (this.props.fieldDef.decimalPlaces === 0) {
          // no need for decimal place when using 0 decimal places
          allowUpdate = false;
        }
        let currentDigits = newValue.length - indexOfDecimal - 1;
        if (
          currentDigits > this.props.fieldDef.decimalPlaces &&
          newValue.length > currentVal.length
        ) {
          allowUpdate = false;
        }
      } else {
        allowUpdate = true;
      }
    }
    //this.setState({currentText: e.target.value});
    if (allowUpdate) {
      this.props.updateField(
        newValue,
        this.props.rowId,
        this.props.colId,
        this.props.extenderKey,
        this.props.fieldSetId.fieldSetId
      );
      if (this.props.fieldDef.showTotal) {
        this.props.calculateRunningTotal(
          this.props.fieldDef.decimalPlaces,
          this.props.fieldDef.fieldId,
          this.props.fieldSetId.fieldSetId
        );
      }
    }
  }

  setError(id, fieldId, errMsg) {
    this.setState({ errorMsg: errMsg });
    console.log(id);
    let idSplit = id.split('_');

    if (idSplit.length === 2) {
      this.props.setError(idSplit[0], fieldId, errMsg);
    }
  }

  clearError(id, fieldId)
  {
    this.setState({ errorMsg: ''});
    let idSplit = id.split('_');
    if (idSplit.length === 2) {
      this.props.clearError(idSplit[0], fieldId);
    }
  }

  render() {
    return (
      <div style={{ position: 'relative' }}>
        {this.props.hasExtender && this.props.extender.type === 4 && (
          <select
            disabled={this.props.readOnly}
            id={`edit_${this.props.rowId}_${this.props.orderId}`}
            className={`form-select form-select-sm ${
              this.props.fontSize === 0 ? 'form-select-sm' : ''
            } ${this.props.error === '' ? 'fieldEdit' : 'fieldEditError'}`}
            value={this.props.textValue}
            onFocus={this.gotFocus}
            onBlur={this.lostFocus}
            onChange={this.onEditExtenderField}

            onKeyDown={this.handleKeyEvent}
            error={this.props.error}
            title={this.props.error}
            onMouseDownCapture={this.onMouseDownCapture} //onMouseEnterSelect(e)}
          >
            <option value='-1'></option>
            {this.props.allowedValues.map((op) => (
              <option key={op.extenderKey} value={op.textValue}>
                {op.textValue}
              </option>
            ))}
          </select>
        )}
        {this.props.hasExtender && this.props.extender.type === 1 && (
          <div>
            <AutoCompleteExtenderRow
              id={`${this.props.rowId}_${this.props.orderId}`}
              value={this.props.textValue}
              fieldId={this.props.fieldDef.fieldId}
              keyValue={this.props.extenderKey}
              isKeyValue={this.state.isKeyValue}
              disabled={this.props.readOnly}
              allowedValues={this.props.allowedValues}
              allowedValuesDefault={this.props.extender.allowedValues}
              onMoveCell={this.props.onMoveCell}
              //onFocus={this.gotFocus}
              onGainFocus={this.gotFocus}
              valueField=''
              displayField=''
              extender={this.props.extender}
              rowId={this.props.rowId}
              OnAutoCompleteEdit={this.OnAutoCompleteEdit}
              error={this.props.error}
              setError={this.setError}
              clearError={this.clearError}
              onMouseDownCapture={(e) => this.onMouseDownCapture(e)}
              title={this.props.error}
            />
          </div>
        )}
        {this.props.fieldDef.dataType === 0 && !this.props.hasExtender && (
          <input
            id={`edit_${this.props.rowId}_${this.props.orderId}`}
            style={{
              textAlign: this.props.fieldDef.dataType === 1 ? 'right' : 'left',
            }}
            disabled={this.props.readOnly}
            className='form-control form-control-sm fieldEdit'
            onFocus={this.gotFocus}
            onBlur={this.lostFocus}
            value={this.props.value}
            onKeyDown={this.handleKeyEvent}
            onChange={this.onEditField}
            title={this.props.error}
            error={this.props.error}
            autoComplete='off'
          />
        )}

        {this.props.fieldDef.dataType === 1 && !this.props.hasExtender && (
          <>
            <input
              id={`edit_${this.props.rowId}_${this.props.orderId}`}
              style={{ textAlign: 'right' }}
              disabled={this.props.readOnly}
              className='form-control form-control-sm fieldEdit'
              onFocus={this.gotFocus}
              onBlur={this.lostFocus}
              onKeyDown={(e) => this.handleKeyEvent(e)}
              value={this.props.value}
              onChange={this.editNumText}
              title={this.state.myFieldTitle}
            />
            {this.props.fieldDef.currencyCode !== null && (
              <div
                style={{
                  position: 'absolute',
                  top: '3px',
                  left: '4px',
                  color: '#45A442',
                  fontSize: '12pt',
                }}
              >
                {this.props.fieldDef.currencyCode === 'USD' && <span>$</span>}
                {this.props.fieldDef.currencyCode === 'GBP' && <span>£</span>}
                {this.props.fieldDef.currencyCode === 'EUR' && <span>€</span>}
                {this.props.fieldDef.currencyCode === 'RUB' && <span>₽</span>}
                {this.props.fieldDef.currencyCode === 'JPY' && <span>¥</span>}
                {this.props.fieldDef.currencyCode === 'PHP' && <span>₱</span>}
              </div>
            )}
          </>
        )}

        {this.props.fieldDef.dataType === 2 && (
          <input
            disabled={this.props.readOnly}
            onBlur={this.onBlurDateField}
            className={`form-control form-control-sm ${
              this.props.error === '' ? 'fieldEdit' : 'fieldEditError'
            }`}
            value={this.props.value}
            onChange={this.onEditDateField}
            title={this.state.myFieldTitle}

          />
        )}
      </div>
    );
  }
}

export default TableCellEditor;
