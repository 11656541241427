export class Directory {
  uploadPath;
  uploadId;
  defaultFormTypeProductId;
  defaultFormTypeProductName;
  defaultTemplateId;
  defaultTemplateName;
  isActive;
  intakeRoutingType;
  intakeStrategyId;
  intakeStrategyName;
  ftps;
}
