import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';

//import './style.css';

var canvas = undefined;
var ctx = undefined;
var p = undefined;

const userLighter = '#FFFDCB';
const userBright = '#FFFCA6';
const userDark = '#E9C341';
const scriptLighter = '#DDDDDD';
const scriptLight = '#AAAAAA';
const scriptDark = '#6A6A6A';
const intLighter = '#A3D1FF';
const intLight = '#5FA1E1';
const intDark = '#1D7EDC';
const endLighter = '#9F8D8D';
const endLight = '#7C7171';
const endDark = '#534C4C';
const connHandle = '#FF0000';
var maxId = 0;
const startTask = 'start';
const userTask = 'user';
const scriptTask = 'auto';
const intTask = 'integration';
const endTask = 'end';

const pointerCursor = 'pointer';
const regularCursor = 'auto';

var isHover = false;
var isConnectHover = false;
var isRouteHover = false;
var isResizing = false;
var draggingTask = undefined;
var hoverTask = undefined;
var connectTask = undefined;
var selectedTask = undefined;
var resizeMode = undefined;
var resizePoint = undefined;
var hoverRoute = undefined;
var isConnected = false;
var connectedTask = undefined;
var hasProximity = false;
var hadProximity = false;
//var wasHovering = false;
var isHovering = false;
var isDragging = false;

var connectStart = undefined;
var connectEnd = undefined;
var routeWithProximity = undefined;
//var selectedRoute = undefined;
var isOverNewUserTask = false;
var isOverNewScriptTask = false;
var isOverNewIntTask = false;
var isOverNewEndTask = false;
var isDraggingNewUserTask = false;
var isDraggingNewScriptTask = false;
var isDraggingNewIntTask = false;
var isDraggingNewEndTask = false;
var isPanHover = false;
var isPanning = false;
var isPanDragging = false;
//var panStart;
//var panEnd;
var currentTranslation = { x: 0, y: 0 };

var mouse = {
  x: undefined,
  y: undefined,
  downx: undefined,
  downy: undefined,
  isDragging: false,
  isStartDrag: false,
  isConnecting: false,
};
var tasks = [];

var routes = [];

var routeNodes = [];

class AdvWorkflowEdit extends Component {
  constructor(props) {
    super(props);
    canvas = document.getElementById('canvas1');
    p = props;
    tasks = props.tasks;
    routes = props.routes;
    this.state = {
      scale: 100,
    };
    try {
      this.draw();
    } catch (error) {}

    this.resizeListener = this.resizeListener.bind(this);
    this.keydown = this.keydown.bind(this);
    this.mouseUp = this.mouseUp.bind(this);
    this.mouseMove = this.mouseMove.bind(this);
    this.mouseDown = this.mouseDown.bind(this);
    this.scaleImage = this.scaleImage.bind(this);
  }

  componentDidMount() {
    canvas = document.getElementById('canvas1');

    //canvas.height = canvas.parentElement.clientHeight;
    //canvas.width = canvas.parentElement.clientWidth;
    canvas.height = this.props.canvasHeight;
    canvas.width = this.props.canvasWidth;

    ctx = canvas.getContext('2d');
    canvas.onmousedown = this.mouseDown;
    canvas.onmouseup = this.mouseUp;
    canvas.onmousemove = this.mouseMove;
    window.addEventListener('keydown', this.keydown);
    window.addEventListener('resize', this.resizeListener);
    routes.forEach((r) => {
      this.generateNodalPoints(r);
    });
    this.draw();
  }

  componentDidUpdate() {
    routes.forEach((r) => {
      this.generateNodalPoints(r);
    });
    this.draw();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resizeListener);
    window.removeEventListener('keydown', this.keydown);
  }

  /*componentWillReceiveProps = (nextProps) => {
      routes.forEach((r) => {
         this.generateNodalPoints(r);
      });
      this.draw();
   };
   */

  scaleImage(e) {
    this.setState({ scale: e.target.value });
    this.draw();
  }

  render() {
    tasks = this.props.tasks;
    routes = this.props.routes;
    return (
      <div>
        <div style={{ background: '#242424', padding: '4px' }}>
          <div className='row'>
            <div className='col-auto mt-1 ms-2'>
              <input
                type='range'
                tabIndex='-1'
                min='30'
                max='200'
                value={this.state.scale}
                className='slider'
                id='myRange'
                onChange={this.scaleImage}
              />
            </div>
            <div
              className='col-auto'
              style={{ color: '#fff', cursor: 'pointer' }}
            >
              <FontAwesomeIcon
                icon={solid('expand')}
                onClick={() => this.props.toggleFullScreen()}
              />
            </div>
          </div>
        </div>
        <div
          id='canvas-container'
          style={{
            height: `${this.props.viewHeight}px`,
            width: `${this.props.viewWidth}px`,
            overflow: 'auto',
            background: '#4F4F4F',
            paddingLeft: '2px',
            border: 'solid 1px #242424',
          }}
        >
          <canvas
            id='canvas1'
            style={{
              backgroundColor: '#fff',
              boxShadow: '5px 5px 10px',
              height: `${(this.props.canvasHeight * this.state.scale) / 100}px`,
              width: `${(this.props.canvasWidth * this.state.scale) / 100}px`,
            }}
          />
        </div>
      </div>
    );
  }

  mouseDown(e) {
    // set mouse position
    mouse.downx = mouse.x;
    mouse.downy = mouse.y;
    canvas = document.getElementById('canvas1');
    var rect = canvas.getBoundingClientRect();
    mouse.x = e.clientX - rect.left;
    mouse.y = e.clientY - rect.top;

    // if we're over a task, initialize the drag start
    if (isHover) {
      mouse.isStartDrag = true;
      draggingTask = hoverTask;
    }

    if (resizeMode !== undefined) {
      isResizing = true;
      resizePoint = {
        x: mouse.x,
        y: mouse.y,
      };
    }

    // check for task proximity
    tasks.forEach((t) => {
      if (t.proximity !== undefined) {
        connectStart = this.getPointFromProximity(t, t.proximity);
        connectTask = t;
        mouse.isConnecting = true;
        hasProximity = true;
      }
    });

    if (isConnectHover && !isResizing) {
      mouse.isConnecting = true;
    }

    if (isRouteHover && !isResizing) {
      hoverRoute.isSelected = true;
    }

    if (isOverNewUserTask) isDraggingNewUserTask = true;
    if (isOverNewScriptTask) isDraggingNewScriptTask = true;
    if (isOverNewIntTask) isDraggingNewIntTask = true;
    if (isOverNewEndTask) isDraggingNewEndTask = true;

    if (isPanHover) {
      // toggle Panning
      if (isPanning === true) isPanning = false;
      else isPanning = true;
    }

    //  if (isPanning && !isResizing && !isHover) {
    //    // panStart = {
    //    //   x: mouse.x - canvas.offsetLeft,
    //    //   y: mouse.y - canvas.offsetTop,
    //    // };

    //    isPanDragging = true;
    //  }
  }

  mouseUp(e) {
    if (!isResizing) {
      tasks.forEach((t) => (t.isSelected = false));
      selectedTask = undefined;
    }
    if (isHover && !isResizing) {
      // select task and send it to editor
      hoverTask.isSelected = true;
      selectedTask = hoverTask;
    }

    if (isPanDragging) isPanDragging = false;

    if (selectedTask !== undefined) {
      this.props.SetSelectedTask(selectedTask);
    } else {
      this.props.SetSelectedTask();
    }

    // check for route proximity
    if (
      hasProximity &&
      routeWithProximity !== undefined &&
      selectedTask === undefined
    ) {
      this.props.SetSelectedRoute(routeWithProximity);
      routeWithProximity.isSelected = true;
      routes.forEach((r) => {
        if (r.objectId !== routeWithProximity.objectId) r.isSelected = false;
      });
    } else {
      routes.forEach((r) => {
        r.isSelected = false;
      });
      routeWithProximity = undefined;
    }

    // create route
    if (mouse.isConnecting && isConnected) {
      // make sure we're not connecting to and from the same point
      if (
        //connectedTask.objectId !== connectTask.objectId ||
        connectedTask.id !== connectTask.id ||
        connectStart.d !== connectEnd.d
      ) {
        var newRoute = {
          id: maxId + 1,
          // objectId: this.createGuid(),
          startTaskId: connectTask.id,
          endTaskId: connectedTask.id,
          from: connectTask,
          to: connectedTask,
          start: connectStart,
          end: connectEnd,
        };
        // remove any routes with same start and end
        var dupes = routes.filter(
          (r) =>
            r.startTaskId === connectTask.id && r.endTaskId === connectedTask.id
        );
        dupes.forEach((dupe) => {
          var idx = routes.indexOf(dupe);
          routes.splice(idx, 1);
        });
        routes.push(newRoute);
        p.onUpdateTree(tasks, routes);
        this.generateNodalPoints(newRoute);
      }
    }

    // create task
    if (
      isDraggingNewUserTask ||
      isDraggingNewScriptTask ||
      isDraggingNewIntTask ||
      isDraggingNewEndTask
    ) {
      var newTask = {
        id: maxId + 1,
        //objectId: this.createGuid(),
        x: mouse.x - 90,
        y: mouse.y - 30,
        height: 60,
        width: 180,
        taskType: 'user',
        name: 'UserTask',
        actions: [
          {
            icon: 1,
            desc: 'Approve',
            actionCode: '',
            routeCode: 'APPROVE',
          },
          {
            icon: 2,
            desc: 'Reject',
            actionCode: '',
            routeCode: 'REJECT',
          },
        ],
      };
      if (isDraggingNewScriptTask) {
        newTask.taskType = 'script';
        newTask.name = 'ScriptTask';
      }
      if (isDraggingNewIntTask) {
        newTask.taskType = intTask;
        newTask.name = 'IntegrationTask';
      }
      if (isDraggingNewEndTask) {
        newTask.taskType = endTask;
        newTask.name = 'EndTask';
      }

      tasks.push(newTask);
      p.onUpdateTree(tasks, routes);
    }

    if (isResizing) {
      isResizing = false;
      resizeMode = undefined;
      p.onUpdateTree(tasks, routes);
    }

    if (isDragging) {
      isDragging = false;
      p.onUpdateTree(tasks, routes);
      routes.forEach((r) => {
        this.generateNodalPoints(r);
      });
    }

    this.draw();
    isDragging = false;
    mouse.isStartDrag = false;
    mouse.isConnecting = false;
    isDraggingNewUserTask = false;
    isDraggingNewScriptTask = false;
    isDraggingNewIntTask = false;
    isDraggingNewEndTask = false;
  }

  mouseMove(e) {
    if (e.target.id === 'canvas1') {
      // set mouse position
      canvas = document.getElementById('canvas1');
      var rect = canvas.getBoundingClientRect();
      mouse.x = e.clientX - rect.left;
      mouse.y = e.clientY - rect.top;

      // store previous values
      hadProximity = hasProximity;
      hasProximity = false;
      //wasHovering = isHovering;
      isHovering = false;

      // check for drag threshhold
      if (mouse.isStartDrag === true && isDragging === false) {
        if (
          Math.abs(mouse.x - mouse.downx) > 4 ||
          Math.abs(mouse.y - mouse.downy) > 4
        ) {
          mouse.isStartDrag = false;
          isDragging = true;
        }
      }

      // Select a route
      if (hasProximity && routeWithProximity !== undefined) {
      }

      // Dragging
      if (isDragging && !isResizing) {
        // move the targetTask and reset down point
        draggingTask.x = draggingTask.x + mouse.x - mouse.downx;
        draggingTask.y = draggingTask.y + mouse.y - mouse.downy;
        this.recalcRouteEndPoints(draggingTask);
        mouse.downx = mouse.x;
        mouse.downy = mouse.y;
      }

      // Check for hover
      isHover = false;
      isConnectHover = false;

      // Check each task for Proximity
      isConnected = false;
      tasks.forEach((t) => {
        // hover over task
        var hoverOverTask = false;
        // var hoverOverConnector = false;
        if (
          t.taskType === scriptTask ||
          t.taskType === userTask ||
          t.taskType === intTask ||
          t.taskType === endTask
        ) {
          hoverOverTask =
            mouse.x > (t.x * this.state.scale) / 100 &&
            mouse.y > (t.y * this.state.scale) / 100 &&
            mouse.x < ((t.x + t.width) * this.state.scale) / 100 &&
            mouse.y < ((t.y + t.height) * this.state.scale) / 100;
        }

        // check for external proximity (not inside but close)
        t.proximity = undefined;
        // inside the task
        if (!t.isSelected && this.state.scale > 80) {
          // to the left
          if (
            mouse.x > ((t.x - 6) * this.state.scale) / 100 &&
            mouse.x <= ((t.x + 6) * this.state.scale) / 100 &&
            mouse.y >= (t.y * this.state.scale) / 100 &&
            mouse.y < ((t.y + t.height) * this.state.scale) / 100
          ) {
            let p =
              ((mouse.y - (t.y * this.state.scale) / 100) * 100) /
              ((t.height * this.state.scale) / 100);

            p = Math.round(p);
            if (p > 40 && p < 60) p = 50;
            t.proximity = 'l:' + p.toString();
          }
          // above
          if (
            mouse.y > ((t.y - 6) * this.state.scale) / 100 &&
            mouse.y <= ((t.y + 6) * this.state.scale) / 100 &&
            mouse.x >= (t.x * this.state.scale) / 100 &&
            mouse.x < ((t.x + t.width) * this.state.scale) / 100
          ) {
            let p =
              ((mouse.x - (t.x * this.state.scale) / 100) * 100) /
              ((t.width * this.state.scale) / 100);
            p = Math.round(p);
            if (p > 40 && p < 60) p = 50;
            t.proximity = 't:' + p.toString();
          }
          // below
          if (
            mouse.y > ((t.y + t.height - 6) * this.state.scale) / 100 &&
            mouse.y <= ((t.y + t.height + 6) * this.state.scale) / 100 &&
            mouse.x >= (t.x * this.state.scale) / 100 &&
            mouse.x < ((t.x + t.width) * this.state.scale) / 100
          ) {
            let p =
              ((mouse.x - (t.x * this.state.scale) / 100) * 100) /
              ((t.width * this.state.scale) / 100);
            p = Math.round(p);
            if (p > 40 && p < 60) p = 50;
            t.proximity = 'b:' + p.toString();
          }
          // to the right
          if (
            mouse.x > ((t.x + t.width - 6) * this.state.scale) / 100 &&
            mouse.x <= ((t.x + t.width + 6) * this.state.scale) / 100 &&
            mouse.y >= (t.y * this.state.scale) / 100 &&
            mouse.y < ((t.y + t.height) * this.state.scale) / 100
          ) {
            let p =
              ((mouse.y - (t.y * this.state.scale) / 100) * 100) /
              ((t.height * this.state.scale) / 100);
            p = Math.round(p);
            if (p > 40 && p < 60) p = 50;
            t.proximity = 'r:' + p.toString();
          }
          // if (
          //   t.proximity === undefined &&
          //   mouse.x > (t.x * this.state.scale) / 100 &&
          //   mouse.y > (t.y * this.state.scale) / 100 &&
          //   mouse.x < ((t.x + t.width) * this.state.scale) / 100 &&
          //   mouse.y < ((t.y + t.height) * this.state.scale) / 100
          // ) {
          //   // which center point is best?
          //
          //   var dy = Math.abs(
          //     ((t.y + t.height) * this.state.scale) / 100 - mouse.y
          //   );
          //   var dx = Math.abs(mouse.x - (t.x * this.state.scale) / 100);
          //   var dx2 = Math.abs(
          //     ((t.x + t.width) * this.state.scale) / 100 - mouse.x
          //   );
          //   var tl = false;
          //   var tr = false;
          //   var bl = false;
          //   var br = false;
          //   if (dy / dx > t.height / t.width) tl = true;
          //   else br = true;
          //   if (dy / dx2 > t.height / t.width) tr = true;
          //   else bl = true;
          //   if (tl && tr) t.proximity = 't:50';
          //   if (tl && bl) t.proximity = 'l:50';
          //   if (tr && br) t.proximity = 'r:50';
          //   if (bl && br) t.proximity = 'b:50';

          //   // no proximity if within 15 pixels of box.
          //   if (
          //     mouse.x > t.x + 15 &&
          //     mouse.x < t.x + t.width - 15 &&
          //     mouse.y > (t.y + 15 && mouse.y < t.y + t.height - 15)
          //   )
          //     t.proximity = undefined;
          // }
          if (t.taskType === 'start') {
            if (
              mouse.x >= ((t.x - 36) * this.state.scale) / 100 &&
              mouse.x < ((t.x - 12) * this.state.scale) / 100 &&
              mouse.y > ((t.y - 12) * this.state.scale) / 100 &&
              mouse.y < ((t.y + 12) * this.state.scale) / 100
            ) {
              t.proximity = 'l:50';
            }
            if (
              mouse.x >= ((t.x + 12) * this.state.scale) / 100 &&
              mouse.x < ((t.x + 35) * this.state.scale) / 100 &&
              mouse.y > ((t.y - 12) * this.state.scale) / 100 &&
              mouse.y < ((t.y + 12) * this.state.scale) / 100
            ) {
              t.proximity = 'r:50';
            }
            if (
              mouse.x >= ((t.x - 12) * this.state.scale) / 100 &&
              mouse.x < ((t.x + 12) * this.state.scale) / 100 &&
              mouse.y > ((t.y + 12) * this.state.scale) / 100 &&
              mouse.y < ((t.y + 35) * this.state.scale) / 100
            ) {
              t.proximity = 'b:50';
            }
          }

          if (t.proximity !== undefined && mouse.isConnecting) {
            isConnected = true;
            connectedTask = t;
            connectEnd = this.getPointFromProximity(connectedTask, t.proximity);
          }
        }

        if (t.taskType === startTask)
          hoverOverTask =
            mouse.x > ((t.x - 24) * this.state.scale) / 100 &&
            mouse.x < ((t.x + 24) * this.state.scale) / 100 &&
            mouse.y > ((t.y - 24) * this.state.scale) / 100 &&
            mouse.y < ((t.y + 24) * this.state.scale) / 100;
        if (hoverOverTask && t.proximity === undefined) {
          t.hover = true;
          isHover = true;
          hoverTask = t;
        } else {
          t.hover = false;
        }
        if (t.proximity !== undefined) hasProximity = true;
      });

      // check for route proximity
      routeWithProximity = undefined;
      routes.forEach((r) => this.checkForRouteProximity(r, mouse.x, mouse.y));

      // set cursor

      if (!isResizing) canvas.style.cursor = regularCursor;
      if (isPanning) canvas.style.cursor = 'move';
      if (isHover && !isResizing) canvas.style.cursor = pointerCursor;
      if (isOverNewUserTask) canvas.style.cursor = pointerCursor;
      if (isOverNewScriptTask) canvas.style.cursor = pointerCursor;
      if (isOverNewIntTask) canvas.style.cursor = pointerCursor;
      if (isOverNewEndTask) canvas.style.cursor = pointerCursor;

      // check for hover over resize handle
      if (selectedTask && !isResizing && selectedTask.taskType !== startTask) {
        if (
          mouse.x > (selectedTask.x * this.state.scale) / 100 - 7 &&
          mouse.x < (selectedTask.x * this.state.scale) / 100 + 7 &&
          mouse.y > (selectedTask.y * this.state.scale) / 100 - 7 &&
          mouse.y < (selectedTask.y * this.state.scale) / 100 + 7
        ) {
          canvas.style.cursor = 'nw-resize';
          resizeMode = 'topleft';
        } else if (
          mouse.x > selectedTask.x - 7 &&
          mouse.x < selectedTask.x + 7 &&
          mouse.y > selectedTask.y + selectedTask.height / 2 - 7 &&
          mouse.y < selectedTask.y + selectedTask.height / 2 + 7
        ) {
          canvas.style.cursor = 'ew-resize';
          resizeMode = 'midleft';
        } else if (
          mouse.x > selectedTask.x - 7 &&
          mouse.x < selectedTask.x + 7 &&
          mouse.y > selectedTask.y + selectedTask.height - 7 &&
          mouse.y < selectedTask.y + selectedTask.height + 7
        ) {
          canvas.style.cursor = 'sw-resize';
          resizeMode = 'bottomleft';
        } else if (
          mouse.x > selectedTask.x + selectedTask.width / 2 - 7 &&
          mouse.x < selectedTask.x + selectedTask.width / 2 + 7 &&
          mouse.y > selectedTask.y - 7 &&
          mouse.y < selectedTask.y + 7
        ) {
          canvas.style.cursor = 'ns-resize';
          resizeMode = 'topmid';
        } else if (
          mouse.x > selectedTask.x + selectedTask.width - 7 &&
          mouse.x < selectedTask.x + selectedTask.width + 7 &&
          mouse.y > selectedTask.y - 7 &&
          mouse.y < selectedTask.y + 7
        ) {
          canvas.style.cursor = 'ne-resize';
          resizeMode = 'topright';
        } else if (
          mouse.x > selectedTask.x + selectedTask.width - 7 &&
          mouse.x < selectedTask.x + selectedTask.width + 7 &&
          mouse.y > selectedTask.y + selectedTask.height / 2 - 7 &&
          mouse.y < selectedTask.y + selectedTask.height / 2 + 7
        ) {
          canvas.style.cursor = 'ew-resize';
          resizeMode = 'midright';
        } else if (
          mouse.x > selectedTask.x + selectedTask.width / 2 - 7 &&
          mouse.x < selectedTask.x + selectedTask.width / 2 + 7 &&
          mouse.y > selectedTask.y + selectedTask.height - 7 &&
          mouse.y < selectedTask.y + selectedTask.height + 7
        ) {
          canvas.style.cursor = 'ns-resize';
          resizeMode = 'bottommid';
        } else if (
          mouse.x > selectedTask.x + selectedTask.width - 7 &&
          mouse.x < selectedTask.x + selectedTask.width + 7 &&
          mouse.y > selectedTask.y + selectedTask.height - 7 &&
          mouse.y < selectedTask.y + selectedTask.height + 7
        ) {
          canvas.style.cursor = 'se-resize';
          resizeMode = 'bottomright';
        } else {
          resizeMode = undefined;
        }
      }

      // Resizing
      if (isResizing && selectedTask) {
        if (resizeMode === 'topmid') {
          selectedTask.y = selectedTask.y - resizePoint.y + mouse.y;
          selectedTask.height = selectedTask.height + resizePoint.y - mouse.y;
        }
        if (resizeMode === 'bottommid') {
          selectedTask.height = selectedTask.height - resizePoint.y + mouse.y;
        }
        if (resizeMode === 'topright') {
          selectedTask.y = selectedTask.y - resizePoint.y + mouse.y;
          selectedTask.height = selectedTask.height + resizePoint.y - mouse.y;
          //selectedTask.x = selectedTask.x - resizePoint.x + mouse.x;
          selectedTask.width = selectedTask.width - resizePoint.x + mouse.x;
        }
        if (resizeMode === 'midleft') {
          selectedTask.x = selectedTask.x - resizePoint.x + mouse.x;
          selectedTask.width = selectedTask.width + resizePoint.x - mouse.x;
        }
        if (resizeMode === 'midright') {
          selectedTask.width = selectedTask.width - resizePoint.x + mouse.x;
        }
        if (resizeMode === 'bottomright') {
          selectedTask.width = selectedTask.width - resizePoint.x + mouse.x;
          selectedTask.height = selectedTask.height - resizePoint.y + mouse.y;
        }
        if (resizeMode === 'topleft') {
          selectedTask.y =
            (((selectedTask.y * this.state.scale) / 100 -
              resizePoint.y +
              mouse.y) /
              this.state.scale) *
            100;
          selectedTask.height =
            selectedTask.height +
            ((resizePoint.y - mouse.y) / this.state.scale) * 100;
          selectedTask.x = selectedTask.x - resizePoint.x + mouse.x;
          selectedTask.width = selectedTask.width + resizePoint.x - mouse.x;
        }
        if (resizeMode === 'bottomleft') {
          selectedTask.height = selectedTask.height - resizePoint.y + mouse.y;
          selectedTask.x = selectedTask.x - resizePoint.x + mouse.x;
          selectedTask.width = selectedTask.width + resizePoint.x - mouse.x;
        }

        resizePoint = {
          x: mouse.x,
          y: mouse.y,
        };
        this.recalcRouteEndPoints(selectedTask);
      }

      if (
        isHovering ||
        isResizing ||
        isDragging ||
        isDraggingNewEndTask ||
        isDraggingNewUserTask ||
        isDraggingNewScriptTask ||
        isDraggingNewIntTask ||
        //panHoverChange ||
        hasProximity ||
        mouse.isConnecting ||
        hadProximity !== hasProximity ||
        isPanDragging
      ) {
        this.draw();
      }
    }
  }

  resizeListener() {
    //canvas.height = canvas.parentElement.clientHeight;
    //canvas.width = canvas.parentElement.clientWidth;
    this.draw();
  }

  keydown(e) {
    /*var needToRedraw = false;
      if (e.key === 'Delete') {
         // remove any selected routes or tasks
         selectedRoute = undefined;
         routes.forEach((r) => {
            if (r.isSelected) selectedRoute = r;
         });
         if (selectedRoute !== undefined) {
            var routeToRemove = routes.find(
               (r) => r.objectId === selectedRoute.objectId
            );
            var posOfRoute = routes.indexOf(routeToRemove);
            routes.splice(posOfRoute, 1);
            needToRedraw = true;
         }

         if (selectedTask != undefined && selectedTask.taskType !== 'start') {
            var posOfTask = tasks.indexOf(selectedTask);
            tasks.splice(posOfTask, 1);
            needToRedraw = true;
            selectedTask = undefined;
         }
      }

      if (needToRedraw) this.draw();
      */
  }

  drawToolbar() {
    //ctx.setTransform(1, 0, 0, 1, 0, 0);

    ctx.fillStyle = '#343434';
    ctx.strokeStyle = '#220000';
    ctx.lineWidth = 2;
    ctx.fillRect(0, 0, canvas.width, 50);
    //ctx.fill();
    if (isOverNewUserTask) ctx.fillStyle = userLighter;
    else ctx.fillStyle = userBright;
    ctx.fillRect(25, 15, 35, 20);
    if (isOverNewScriptTask) ctx.fillStyle = scriptLighter;
    else ctx.fillStyle = scriptLight;
    ctx.fillRect(75, 15, 35, 20);
    if (isOverNewIntTask) ctx.fillStyle = intLighter;
    else ctx.fillStyle = intLight;
    ctx.fillRect(125, 15, 35, 20);

    //endtask
    ctx.beginPath();
    ctx.moveTo(180, 15);
    ctx.lineTo(205, 15);
    ctx.quadraticCurveTo(210, 25, 205, 35);
    ctx.lineTo(180, 35);
    ctx.quadraticCurveTo(175, 25, 180, 15);
    ctx.closePath();
    if (isOverNewEndTask) ctx.fillStyle = endLighter;
    else ctx.fillStyle = endLight;
    ctx.fill();

    // pan button
    if (isPanHover) ctx.fillStyle = userBright;
    else {
      if (isPanning) ctx.fillStyle = intLighter;
      else ctx.fillStyle = '#ffffff';
    }

    ctx.beginPath();
    ctx.arc(250, 25, 15, 0, 2 * Math.PI);
    ctx.closePath();
    ctx.fill();
    var resizeIcon =
      'M352.201 425.775l-79.196 79.196c-9.373 9.373-24.568 9.373-33.941 0l-79.196-79.196c-15.119-15.119-4.411-40.971 16.971-40.97h51.162L228 284H127.196v51.162c0 21.382-25.851 32.09-40.971 16.971L7.029 272.937c-9.373-9.373-9.373-24.569 0-33.941L86.225 159.8c15.119-15.119 40.971-4.411 40.971 16.971V228H228V127.196h-51.23c-21.382 0-32.09-25.851-16.971-40.971l79.196-79.196c9.373-9.373 24.568-9.373 33.941 0l79.196 79.196c15.119 15.119 4.411 40.971-16.971 40.971h-51.162V228h100.804v-51.162c0-21.382 25.851-32.09 40.97-16.971l79.196 79.196c9.373 9.373 9.373 24.569 0 33.941L425.773 352.2c-15.119 15.119-40.971 4.411-40.97-16.971V284H284v100.804h51.23c21.382 0 32.09 25.851 16.971 40.971z';
    this.drawIcon(resizeIcon, 238, 13, 24, 24, 512, '#777777');
  }

  drawIcon(svgData, x, y, width, height, base_size, color) {
    var scale = width / base_size;
    var transX = x / scale;
    var transY = y / scale;
    var p2 = new Path2D(svgData);
    var m = new DOMMatrix(
      'scale(' +
        scale.toString() +
        ') translate(' +
        transX.toString() +
        'px, ' +
        transY.toString() +
        'px)'
    );
    var p = new Path2D();
    p.addPath(p2, m);
    //ctx.stroke(p);
    ctx.fillStyle = color;
    ctx.fill(p);
    // const p = new Path2D();
  }

  draw() {
    canvas.width = (this.props.canvasWidth * this.state.scale) / 100;
    canvas.height = (this.props.canvasHeight * this.state.scale) / 100;
    ctx.clearRect(0, 0, canvas.width, canvas.height);

    ctx.setTransform(1, 0, 0, 1, 0, 0);
    //ctx.scale(1,1);
    //ctx.translate(currentTranslation.x, currentTranslation.y);

    tasks.forEach((t) => {
      this.drawTask(
        ctx,
        (t.x * this.state.scale) / 100,
        (t.y * this.state.scale) / 100,
        (t.width * this.state.scale) / 100,
        (t.height * this.state.scale) / 100,
        t.name,
        t.hover,
        t.connectHover,
        t.isSelected,
        t.taskType,
        t
      );
    });
    routes.forEach((r) => {
      this.drawRoute(ctx, r);
    });

    //this.drawToolbar();

    if (mouse.isConnecting) {
      ctx.lineWidth = 1;
      ctx.strokeStyle = '#33C53B';

      // var startPointX = 0;
      //var startPointY = 0;
      var endPointX = 0;
      var endPointY = 0;
      // var startDir = '';
      var endDir = '';
      // if (
      //    connectTask.taskType === userTask ||
      //    connectTask.taskType === scriptTask ||
      //    connectTask.taskType === intTask
      // ) {
      //    startPointX = connectStart.x;
      //    startPointY = connectStart.y;
      // }
      // if (connectTask.taskType === startTask) {
      //    startPointX = connectTask.x;
      //    startPointY = connectTask.y + 24;
      //    startDir = 'b';
      // }
      // check if we're in proximity of a task
      var hasProx = false;
      var proxTask = undefined;
      var proxPos = '';
      tasks.forEach((t) => {
        if (t.proximity !== undefined) {
          hasProx = true;
          proxTask = t;
          proxPos = t.proximity;
        }
      });
      // if in proximity, snap to local proximity point
      if (hasProx) {
        var endPoint = this.getPointFromProximity(proxTask, proxPos);
        endPointX = endPoint.x;
        endPointY = endPoint.y;
        endDir = endPoint.d.split(':')[0];
      } else {
        // go to mouse

        endPointX = mouse.x;
        endPointY = mouse.y;
        var startd = connectStart.d.split(':')[0];
        if (startd === 'b') endDir = 't';
        if (startd === 't') endDir = 'b';
        if (startd === 'l') endDir = 'r';
        if (startd === 'r') endDir = 'l';
      }
      this.drawBezier(
        ctx,
        connectStart.x + currentTranslation.x,
        connectStart.y + currentTranslation.y,
        endPointX + currentTranslation.x,
        endPointY + currentTranslation.y,
        connectStart.d.split(':')[0],
        endDir
      );
    }

    //draw toolbar last

    // draw new task adorner
    if (
      isDraggingNewUserTask ||
      isDraggingNewScriptTask ||
      isDraggingNewIntTask
    ) {
      ctx.lineWidth = 1;
      ctx.strokeStyle = '#000000';
      ctx.strokeRect(mouse.x - 17, mouse.y - 10, 35, 20);
      if (isDraggingNewUserTask) ctx.fillStyle = userBright;
      if (isDraggingNewScriptTask) ctx.fillStyle = scriptLight;
      if (isDraggingNewIntTask) ctx.fillStyle = intLight;

      ctx.fillRect(mouse.x - 17, mouse.y - 9, 35, 18);
    }
    if (isDraggingNewEndTask) {
      ctx.lineWidth = 1;
      ctx.strokeStyle = '#000000';
      ctx.beginPath();
      ctx.moveTo(mouse.x - 12, mouse.y - 10);
      ctx.lineTo(mouse.x + 12, mouse.y - 10);
      ctx.quadraticCurveTo(mouse.x + 17, mouse.y, mouse.x + 12, mouse.y + 10);
      ctx.lineTo(mouse.x - 12, mouse.y + 10);
      ctx.quadraticCurveTo(mouse.x - 17, mouse.y, mouse.x - 12, mouse.y - 10);
      ctx.closePath();
      ctx.fillStyle = endLight;
      //ctx.fill();
    }
  }

  getPointFromProximity(t, proximity) {
    var pointX = 0;
    var pointY = 0;
    var dir = '';
    var proxParts = proximity.split(':');
    let r = parseInt(proxParts[1]);

    if (proxParts) {
      pointX = mouse.x;
      pointY = mouse.y;
      dir = proxParts[0];
      if (t.taskType === 'start') {
        if (dir === 'b') {
          pointX = t.x;
          pointY = t.y + 24;
        }
        if (dir === 'l') {
          pointX = t.x - 24;
          pointY = t.y;
        }
        if (dir === 'r') {
          pointX = t.x + 24;
          pointY = t.y;
        }
        var startPoint = {
          x: pointX,
          y: pointY,
          d: proximity,
        };

        return startPoint;
      }
      if (dir === 't') {
        pointX = t.x + (t.width * r) / 100;
        pointY = t.y;
      }
      if (dir === 'b') {
        //if(r === 50) pointX = t.x + (t.width/2);
        //else pointX = mouse.x;

        pointX = t.x + (t.width * r) / 100;
        pointY = t.y + t.height;
      }
      if (dir === 'l') {
        pointY = t.y + (t.height * r) / 100;
        pointX = t.x;
      }
      if (dir === 'r') {
        pointY = t.y + (t.height * r) / 100;
        pointX = t.x + t.width;
      }
    }
    var result = {
      x: (pointX * this.state.scale) / 100,
      y: (pointY * this.state.scale) / 100,
      d: proximity,
    };

    return result;
  }

  drawBezier2(
    ctx,
    scale,
    startTaskId,
    startDir,
    endTaskId,
    endDir,
    isSelected,
    hasProximity
  ) {
    ctx.strokeStyle = connHandle;
    var startTask = tasks.find((t) => t.id === startTaskId);
    var endTask = tasks.find((t) => t.id === endTaskId);
    var startParts = startDir.split(':');
    var endParts = endDir.split(':');
    if (startTask !== undefined && endTask !== undefined) {
      var startPoint = { x: 0, y: 0 };
      var endPoint = { x: 0, y: 0 };

      if (startParts[0] === 't') {
        startPoint = {
          x:
            (startTask.x * scale) / 100 +
            (((startTask.width * scale) / 100) * parseInt(startParts[1], 10)) /
              100,
          y: (startTask.y * scale) / 100,
        };
      }
      if (startParts[0] === 'b') {
        startPoint = {
          x:
            (startTask.x * scale) / 100 +
            (((startTask.width * scale) / 100) * parseInt(startParts[1], 10)) /
              100,
          y: ((startTask.y + startTask.height) * scale) / 100,
        };
      }
      if (startParts[0] === 'l') {
        startPoint = {
          x: (startTask.x * scale) / 100,
          y:
            (startTask.y * scale) / 100 +
            (((startTask.height * scale) / 100) * parseInt(startParts[1], 10)) /
              100,
        };
      }
      if (startParts[0] === 'r') {
        startPoint = {
          x: ((startTask.x + startTask.width) * scale) / 100,
          y:
            (startTask.y * scale) / 100 +
            (((startTask.height * scale) / 100) * parseInt(startParts[1], 10)) /
              100,
        };
      }

      if (endParts[0] === 't') {
        endPoint = {
          x:
            (endTask.x * scale) / 100 +
            (((endTask.width * scale) / 100) * parseInt(endParts[1], 10)) / 100,
          y: (endTask.y * scale) / 100,
        };
      }
      if (endParts[0] === 'b') {
        endPoint = {
          x:
            (endTask.x * scale) / 100 +
            (((endTask.width * scale) / 100) * parseInt(endParts[1], 10)) / 100,
          y: ((endTask.y + endTask.height) * scale) / 100,
        };
      }
      if (endParts[0] === 'l') {
        endPoint = {
          x: (endTask.x * scale) / 100,
          y:
            (endTask.y * scale) / 100 +
            (((endTask.height * scale) / 100) * parseInt(endParts[1], 10)) /
              100,
        };
      }
      if (endParts[0] === 'r') {
        endPoint = {
          x: ((endTask.x + endTask.width) * scale) / 100,
          y:
            (endTask.y * scale) / 100 +
            (((endTask.height * scale) / 100) * parseInt(endParts[1], 10)) /
              100,
        };
      }

      if (startTask.taskType === 'start') {
        if (startParts[0] === 'b') {
          startPoint = {
            x: (startTask.x * scale) / 100,
            y: ((startTask.y + 24) * scale) / 100,
          };
        }
        if (startParts[0] === 'l') {
          startPoint = {
            x: ((startTask.x - 24) * scale) / 100,
            y: (startTask.y * scale) / 100,
          };
        }
        if (startParts[0] === 'r') {
          startPoint = {
            x: ((startTask.x + 24) * scale) / 100,
            y: (startTask.y * scale) / 100,
          };
        }
      }

      //Control Point 1
      var controlPoints = this.calculateControlPoints(
        { x: startPoint.x, y: startPoint.y, d: startDir },
        { x: endPoint.x, y: endPoint.y, d: endDir },
        false
      );

      var c1 = controlPoints.c1;
      var c2 = controlPoints.c2;
      ctx.beginPath();
      ctx.moveTo(startPoint.x, startPoint.y);

      if (isSelected) ctx.lineWidth = 3;
      else ctx.lineWidth = 1;
      ctx.strokeStyle = '#33C53B';
      if (hasProximity) {
        ctx.strokeStyle = '#3FEB48';
        ctx.lineWidth = 3;
      }
      ctx.bezierCurveTo(c1.x, c1.y, c2.x, c2.y, endPoint.x, endPoint.y);
      ctx.stroke();

      // draw arrow
      ctx.beginPath();
      ctx.moveTo(endPoint.x, endPoint.y);
      if (endParts[0] === 't') {
        ctx.lineTo(endPoint.x + 5, endPoint.y - 12);
        ctx.lineTo(endPoint.x - 5, endPoint.y - 12);
      }
      if (endParts[0] === 'b') {
        ctx.lineTo(endPoint.x + 5, endPoint.y + 12);
        ctx.lineTo(endPoint.x - 5, endPoint.y + 12);
      }
      if (endParts[0] === 'l') {
        ctx.lineTo(endPoint.x - 12, endPoint.y + 5);
        ctx.lineTo(endPoint.x - 12, endPoint.y - 5);
      }
      if (endParts[0] === 'r') {
        ctx.lineTo(endPoint.x + 12, endPoint.y + 5);
        ctx.lineTo(endPoint.x + 12, endPoint.y - 5);
      }
      ctx.closePath();
      ctx.fillStyle = '#33C53B';
      ctx.fill();
    }
  }

  drawBezier(
    ctx,
    startPointX,
    startPointY,
    endPointX,
    endPointY,
    startDir,
    endDir,
    hasProximity,
    isSelected
  ) {
    ctx.strokeStyle = connHandle;

    //Control Point 1
    var controlPoints = this.calculateControlPoints(
      { x: startPointX, y: startPointY, d: startDir },
      { x: endPointX, y: endPointY, d: endDir },
      false
    );
    var c1 = controlPoints.c1;
    var c2 = controlPoints.c2;
    ctx.beginPath();
    ctx.moveTo(startPointX, startPointY);

    if (isSelected) ctx.lineWidth = 3;
    else ctx.lineWidth = 1;
    ctx.strokeStyle = '#33C53B';
    if (hasProximity) {
      ctx.strokeStyle = '#3FEB48';
      ctx.lineWidth = 3;
    }
    ctx.bezierCurveTo(c1.x, c1.y, c2.x, c2.y, endPointX, endPointY);
    ctx.stroke();

    // draw arrow
    ctx.beginPath();
    ctx.moveTo(endPointX, endPointY);
    if (endDir === 't') {
      ctx.lineTo(endPointX + 7, endPointY - 12);
      ctx.lineTo(endPointX - 7, endPointY - 12);
    }
    if (endDir === 'b') {
      ctx.lineTo(endPointX + 7, endPointY + 12);
      ctx.lineTo(endPointX - 7, endPointY + 12);
    }
    if (endDir === 'l') {
      ctx.lineTo(endPointX - 12, endPointY + 7);
      ctx.lineTo(endPointX - 12, endPointY - 7);
    }
    if (endDir === 'r') {
      ctx.lineTo(endPointX + 12, endPointY + 7);
      ctx.lineTo(endPointX + 12, endPointY - 7);
    }
    ctx.closePath();
    ctx.fillStyle = '#33C53B';
    ctx.fill();
  }

  drawRoute(ctx, route) {
    ctx.lineWidth = 1;
    ctx.strokeStyle = '#33C53B';
    if (route.routeCode === 'REJECT') ctx.strokeStye = '#C61E1E';
    //var startDir = route.start.d.split(':')[0];
    //var endDir = route.end.d.split(':')[0];

    this.drawBezier2(
      ctx,
      this.state.scale,
      route.startTaskId,
      route.start.d,
      route.endTaskId,
      route.end.d,
      route.isSelected,
      route.hasProximity
    );
  }

  createGuid() {
    function s4() {
      return Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1);
    }
    return (
      s4() +
      s4() +
      '-' +
      s4() +
      '-' +
      s4() +
      '-' +
      s4() +
      '-' +
      s4() +
      s4() +
      s4()
    );
  }

  drawTask(
    ctx,
    x,
    y,
    width,
    height,
    label,
    hover,
    connectHover,
    isSelected,
    taskType,
    task
  ) {
    var radius = { tl: 8, tr: 8, br: 8, bl: 8 };
    var endRadius = height / 2;
    if (
      taskType === userTask ||
      taskType === scriptTask ||
      taskType === intTask ||
      taskType === endTask
    ) {
      ctx.strokeStyle = 'black';
      if (taskType === endTask) {
        ctx.beginPath();
        ctx.moveTo(x + endRadius, y);
        ctx.lineTo(x + width - endRadius, y);
        ctx.quadraticCurveTo(
          x + width,
          y + height / 2,
          x + width - endRadius,
          y + height
        );
        ctx.lineTo(x + endRadius, y + height);
        ctx.quadraticCurveTo(x, y + height / 2, x + endRadius, y);
        ctx.closePath();
      } else {
        ctx.beginPath();
        //ctx.fillStyle = my_gradient;
        ctx.moveTo(x + radius.tl, y);
        ctx.lineTo(x + width - radius.tr, y);
        ctx.quadraticCurveTo(x + width, y, x + width, y + radius.tr);
        ctx.lineTo(x + width, y + height - radius.br);
        ctx.quadraticCurveTo(
          x + width,
          y + height,
          x + width - radius.br,
          y + height
        );
        ctx.lineTo(x + radius.bl, y + height);
        ctx.quadraticCurveTo(x, y + height, x, y + height - radius.bl);
        ctx.lineTo(x, y + radius.tl);
        ctx.quadraticCurveTo(x, y, x + radius.tl, y);
        ctx.closePath();
      }

      //ctx.fillRect(10, 10, 150, 80);
      if (hover || isSelected) {
        ctx.lineWidth = 3;
        if (taskType === userTask) ctx.strokeStyle = userDark;
        if (taskType === scriptTask) ctx.strokeStyle = scriptDark;
        if (taskType === intTask) ctx.strokeStyle = intDark;
        if (taskType === endTask) ctx.strokeStyle = endDark;
      } else {
        ctx.strokeStyle = 'black';
        ctx.lineWidth = 1;
      }
      if (taskType === userTask) ctx.fillStyle = userBright;
      if (taskType === scriptTask) ctx.fillStyle = scriptLight;
      if (taskType === intTask) ctx.fillStyle = intLight;
      if (taskType === endTask) ctx.fillStyle = endLight;

      ctx.fill();
      ctx.stroke();
      ctx.fillStyle = 'black';

      // write the name inside
      var fs = (16 * this.state.scale) / 100;
      ctx.font = `${fs}px Arial`;
      ctx.textAlign = 'center';
      ctx.fillText(label, x + width / 2, y + 8 + height / 2);

      // draw stamp icon
      if (task.applyStamp) {
        var stampIconPath =
          'M32 512h448v-64H32v64zm384-256h-66.56c-16.26 0-29.44-13.18-29.44-29.44v-9.46c0-27.37 8.88-53.41 21.46-77.72 9.11-17.61 12.9-38.39 9.05-60.42-6.77-38.78-38.47-70.7-77.26-77.45C212.62-9.04 160 37.33 160 96c0 14.16 3.12 27.54 8.69 39.58C182.02 164.43 192 194.7 192 226.49v.07c0 16.26-13.18 29.44-29.44 29.44H96c-53.02 0-96 42.98-96 96v32c0 17.67 14.33 32 32 32h448c17.67 0 32-14.33 32-32v-32c0-53.02-42.98-96-96-96z';
        this.drawIcon(
          stampIconPath,
          x + width - 25,
          y + 5,
          20,
          20,
          512,
          '#7D0B0B'
        );
      }

      // draw proximity connectors
      if (task.proximity !== undefined) {
        ctx.lineWidth = 1;
        ctx.strokeStyle = connHandle;
        ctx.fillStyle = '#FFFFFF';

        /*
            // top center
            ctx.beginPath();
            ctx.arc(x + width / 2, y, 7, 0, 2 * Math.PI);
            ctx.stroke();
            ctx.fill();
            // left center
            ctx.beginPath();
            ctx.arc(x, y + height / 2, 7, 0, 2 * Math.PI);
            ctx.stroke();
            ctx.fill();
            // right center
            ctx.beginPath();
            ctx.arc(x + width, y + height / 2, 7, 0, 2 * Math.PI);
            ctx.stroke();
            ctx.fill();
            // bottom center
            ctx.beginPath();
            ctx.arc(x + width / 2, y + height, 7, 0, 2 * Math.PI);
            ctx.stroke();
            ctx.fill();
            */

        // fill connector if in 5% proximity to center
        var proxType = task.proximity[0];
        //var proxSplit = task.proximity.split(':');
        //var ratio = parseInt(proxSplit[1]);

        if (
          proxType === 'r' ||
          proxType === 'b' ||
          proxType === 't' ||
          proxType === 'l'
        ) {
          // draw side edge connector
          ctx.fillStyle = '#930000';
          ctx.beginPath();
          if (proxType === 't') ctx.arc(mouse.x, y, 3, 0, 2 * Math.PI);
          if (proxType === 'b') ctx.arc(mouse.x, y + height, 3, 0, 2 * Math.PI);
          if (proxType === 'l') ctx.arc(x, mouse.y, 3, 0, 2 * Math.PI);
          if (proxType === 'r') ctx.arc(x + width, mouse.y, 3, 0, 2 * Math.PI);
          ctx.stroke();
          ctx.fill();
          // }
        }
      }

      if (isSelected) {
        var scaleRatio = this.state.scale / 100;
        // draw resize handles
        ctx.lineWidth = 1;
        ctx.strokeStyle = 'black';
        ctx.fillStyle = '#ffffff';
        ctx.beginPath();
        ctx.rect((task.x - 5) * scaleRatio, (task.y - 5) * scaleRatio, 10, 10);
        ctx.stroke();
        ctx.fill();
        ctx.rect(
          (task.x + task.width - 5) * scaleRatio,
          (task.y - 5) * scaleRatio,
          10,
          10
        );
        ctx.stroke();
        ctx.fill();
        ctx.rect(
          (task.x + task.width / 2 - 5) * scaleRatio,
          (task.y - 5) * scaleRatio,
          10,
          10
        );
        ctx.stroke();
        ctx.fill();
        ctx.rect(
          (task.x - 5) * scaleRatio,
          (task.y + task.height / 2 - 5) * scaleRatio,
          10,
          10
        );
        ctx.stroke();
        ctx.fill();
        ctx.rect(
          (task.x + task.width - 5) * scaleRatio,
          (task.y + task.height / 2 - 5) * scaleRatio,
          10,
          10
        );
        ctx.stroke();
        ctx.fill();
        ctx.rect(
          (task.x - 5) * scaleRatio,
          (task.y + task.height - 5) * scaleRatio,
          10,
          10
        );
        ctx.stroke();
        ctx.fill();
        ctx.rect(
          (task.x + task.width - 5) * scaleRatio,
          (task.y + task.height - 5) * scaleRatio,
          10,
          10
        );
        ctx.stroke();
        ctx.fill();
        ctx.rect(
          (task.x + task.width / 2 - 5) * scaleRatio,
          (task.y + task.height - 5) * scaleRatio,
          10,
          10
        );
        ctx.stroke();
        ctx.fill();
      }
    }

    if (taskType === 'start') {
      // draw circle
      if (isSelected) ctx.lineWidth = 4;
      else ctx.lineWidth = 1;
      ctx.strokeStyle = 'black';
      ctx.fillStyle = '#5FE165';
      ctx.beginPath();
      ctx.arc(x, y, (24 * this.state.scale) / 100, 0, 2 * Math.PI);
      ctx.stroke();
      ctx.fill();

      // draw proximity connectors
      if (task.proximity !== undefined) {
        // draw connection points
        if (task.proximity === 'l:50') {
          ctx.lineWidth = 1;
          ctx.strokeStyle = '#FF0000';
          ctx.fillStyle = '#930000';
          ctx.beginPath();
          ctx.arc(
            ((task.x - 24) * this.state.scale) / 100,
            (task.y * this.state.scale) / 100,
            4,
            0,
            2 * Math.PI
          );
          ctx.stroke();
          ctx.fill();
        }

        if (task.proximity === 'b:50') {
          ctx.lineWidth = 1;
          ctx.strokeStyle = '#FF0000';
          ctx.fillStyle = '#930000';
          ctx.beginPath();
          ctx.arc(
            (task.x * this.state.scale) / 100,
            ((task.y + 24) * this.state.scale) / 100,
            3,
            0,
            2 * Math.PI
          );
          ctx.stroke();
          ctx.fill();
        }

        if (task.proximity === 'r:50') {
          ctx.lineWidth = 1;
          ctx.strokeStyle = '#FF0000';
          ctx.fillStyle = '#930000';
          ctx.beginPath();
          ctx.arc(
            ((task.x + 24) * this.state.scale) / 100,
            (task.y * this.state.scale) / 100,
            3,
            0,
            2 * Math.PI
          );
          ctx.stroke();
          ctx.fill();
        }

        /*
            ctx.lineWidth = 1;
            ctx.strokeStyle = '#FF0000';
            ctx.fillStyle = '#FFFFFF';
            ctx.beginPath();
            ctx.arc((task.x - 24) * this.state.scale / 100, (task.y * this.state.scale / 100), 7, 0, 2 * Math.PI);
            ctx.stroke();
            if (task.proximity === 'l:50') ctx.fillStyle = '#FF0000';
            else ctx.fillStyle = '#FFFFFF';
            ctx.fill();
            ctx.beginPath();
            ctx.arc((task.x + 24) * this.state.scale / 100, (task.y) * this.state.scale / 100, 7, 0, 2 * Math.PI);
            ctx.stroke();
            if (task.proximity === 'r:50') ctx.fillStyle = '#FF0000';
            else ctx.fillStyle = '#FFFFFF';
            ctx.fill();
            ctx.beginPath();
            ctx.arc((task.x) * this.state.scale / 100, (task.y + 24) * this.state.scale / 100, 7, 0, 2 * Math.PI);
            ctx.stroke();
            if (task.proximity === 'b:50') ctx.fillStyle = '#FF0000';
            else ctx.fillStyle = '#FFFFFF';
            ctx.fill();
            */
      }
      // draw connection point
      // if(isSelected) {
      //     ctx.lineWidth = 1;
      //     ctx.strokeStyle = "#33C53B";
      //     ctx.beginPath();
      //     ctx.arc(x, y + 24 + 7,7, 0, 2 * Math.PI);
      //     ctx.stroke();
      //     if(connectHover)
      //     {
      //         ctx.fillStyle = "#33C53B";
      //         ctx.fill();
      //     }
      // }
    }
  }

  recalcRouteEndPoints(task) {
    routes.forEach((r) => {
      if (r.startTaskId === task.id) {
        r.start = this.getPointFromProximity(task, r.start.d);
      }
      if (r.endTaskId === task.id) {
        r.end = this.getPointFromProximity(task, r.end.d);
      }
    });
  }

  checkForRouteProximity(route, x, y) {
    //check for weak proximity
    var weakProximity = false;
    var strongProximity = false;
    var nodes = routeNodes.find((n) => n.objectId === route.objectId);
    if (nodes !== undefined) {
      nodes.nodes.forEach((n) => {
        if (x > n.x - 50 && x < n.x + 50 && y > n.y - 50 && y < n.y + 50)
          weakProximity = true;
      });
    }
    //route.hasProximity = weakProximity;

    if (weakProximity) {
      var previousNode = undefined;

      nodes.nodes.forEach((n) => {
        if (previousNode !== undefined) {
          // calculate three lengths of triangel
          // mouse - previousNode - n
          var b = Math.sqrt(
            (previousNode.x - n.x) ** 2 + (previousNode.y - n.y) ** 2
          );
          var l1 = Math.sqrt(
            (previousNode.x - x) ** 2 + (previousNode.y - y) ** 2
          );
          var l2 = Math.sqrt((n.x - x) ** 2 + (n.y - y) ** 2);

          // use Heron formula to calculate area
          var s = (b + l1 + l2) / 2;
          var A = Math.sqrt(s * (s - b) * (s - l1) * (s - l2));
          var h = (2 * A) / b;
          var maxL = Math.sqrt((b + 5) ** 2 + 100);
          if (h < 10 && l1 < maxL && l2 < maxL) strongProximity = true;
        }
        previousNode = n;
      });
    }
    if (strongProximity) {
      hasProximity = true;
      route.hasProximity = true;
      routeWithProximity = route;
    } else {
      route.hasProximity = false;
    }
  }

  calculateControlPoints(startPoint, endPoint, output) {
    var c1 = { x: 0, y: 0 };
    var c2 = { x: 0, y: 0 };
    if (startPoint.d !== undefined) {
      var endDir = endPoint.d;
      var startDir = startPoint.d;
      if (startPoint.d.split(':').length > 1)
        startDir = startPoint.d.split(':')[0];
      if (endPoint.d.split(':').length > 1) {
        endDir = endPoint.d.split(':')[0];
      }
      var lambda1 = 100;
      var lambda2 = 100;

      lambda1 = (Math.abs(startPoint.x - endPoint.x) * 50) / 100;
      if (lambda1 < 20) {
        lambda1 = 20;
      }
      lambda2 = (Math.abs(startPoint.y - endPoint.y) * 50) / 100;
      if (lambda2 < 20) {
        lambda2 = 20;
      }
      if (startDir === endDir) {
        lambda1 = 80;
        lambda2 = 80;
      }
      //lambda1 = 100;
      if (startDir === 'b') {
        //if (endPoint.y > startPoint.y) {
        //   c1.y = startPoint.y + (endPoint.y - startPoint.y) / 2;
        //} else {
        c1.y = startPoint.y + lambda2;
        //}
        c1.x = startPoint.x;
      }
      if (startDir === 't') {
        //if (endPoint.y < startPoint.y) {
        //   c1.y = startPoint.y - (startPoint.y - endPoint.y) / 2;
        //} else {
        c1.y = startPoint.y - lambda2;
        //}
        c1.x = startPoint.x;
      }
      if (startDir === 'r') {
        //if (endPoint.x > startPoint.x) {
        //   c1.x = startPoint.x + (endPoint.x - startPoint.x) / 2;
        //} else {
        c1.x = startPoint.x + lambda1;
        // }
        c1.y = startPoint.y;
      }
      if (startDir === 'l') {
        //if (endPoint.x < startPoint.x) {
        // c1.x = startPoint.x - (startPoint.x - endPoint.x) / 2;
        //} else {
        c1.x = startPoint.x - lambda1;
        //}
        c1.y = startPoint.y;
      }
      // Control Point 2
      if (endDir === 'b') {
        //if (startPoint.y > endPoint.y) {
        //   c2.y = endPoint.y + (startPoint.y - endPoint.y) / 2;
        // } else {
        c2.y = endPoint.y + lambda2;
        // }
        c2.x = endPoint.x;
      }
      if (endDir === 't') {
        //if (startPoint.y < endPoint.y) {
        // c2.y = endPoint.y - (endPoint.y - startPoint.y) / 2;
        // } else {
        c2.y = endPoint.y - lambda2;
        // }
        c2.x = endPoint.x;
      }
      if (endDir === 'r') {
        //  if (startPoint.x > endPoint.x) {
        //    c2.x = endPoint.x + (startPoint.x - endPoint.x) / 2;
        // } else {
        c2.x = endPoint.x + lambda1;
        //  }
        c2.y = endPoint.y;
      }
      if (endDir === 'l') {
        // if (startPoint.x < endPoint.x) {
        //   c2.x = endPoint.x - (endPoint.x - startPoint.x) / 2;
        // } else {
        c2.x = endPoint.x - lambda1;
        //  }
        c2.y = endPoint.y;
      }
    }

    return { c1, c2 };
  }

  calculateEndPoints(route) {
    var startPoint = { x: 0, y: 0 };
    var endPoint = { x: 0, y: 0 };
    var scale = this.state.scale;
    var startTask = tasks.find((t) => t.id === route.startTaskId);
    if (startTask !== undefined && startTask.x !== undefined) {
      var endTask = tasks.find((t) => t.id === route.endTaskId);
      var startParts = route.start.d.split(':');
      var endParts = route.end.d.split(':');

      if (endTask !== undefined && endTask.x !== undefined) {
        if (startParts[0] === 't') {
          startPoint = {
            x:
              (startTask.x * scale) / 100 +
              (((startTask.width * scale) / 100) *
                parseInt(startParts[1], 10)) /
                100,
            y: (startTask.y * scale) / 100,
            d: route.start.d,
          };
        }
        if (startParts[0] === 'b') {
          startPoint = {
            x:
              (startTask.x * scale) / 100 +
              (((startTask.width * scale) / 100) *
                parseInt(startParts[1], 10)) /
                100,
            y: ((startTask.y + startTask.height) * scale) / 100,
            d: route.start.d,
          };
        }
        if (startParts[0] === 'l') {
          startPoint = {
            x: (startTask.x * scale) / 100,
            y:
              (startTask.y * scale) / 100 +
              (((startTask.height * scale) / 100) *
                parseInt(startParts[1], 10)) /
                100,
            d: route.start.d,
          };
        }
        if (startParts[0] === 'r') {
          startPoint = {
            x: ((startTask.x + startTask.width) * scale) / 100,
            y:
              (startTask.y * scale) / 100 +
              (((startTask.height * scale) / 100) *
                parseInt(startParts[1], 10)) /
                100,
            d: route.start.d,
          };
        }

        if (endParts[0] === 't') {
          endPoint = {
            x:
              (endTask.x * scale) / 100 +
              (((endTask.width * scale) / 100) * parseInt(endParts[1], 10)) /
                100,
            y: (endTask.y * scale) / 100,
            d: route.end.d,
          };
        }
        if (endParts[0] === 'b') {
          endPoint = {
            x:
              (endTask.x * scale) / 100 +
              (((endTask.width * scale) / 100) * parseInt(endParts[1], 10)) /
                100,
            y: ((endTask.y + endTask.height) * scale) / 100,
            d: route.end.d,
          };
        }
        if (endParts[0] === 'l') {
          endPoint = {
            x: (endTask.x * scale) / 100,
            y:
              (endTask.y * scale) / 100 +
              (((endTask.height * scale) / 100) * parseInt(endParts[1], 10)) /
                100,
            d: route.end.d,
          };
        }
        if (endParts[0] === 'r') {
          endPoint = {
            x: ((endTask.x + endTask.width) * scale) / 100,
            y:
              (endTask.y * scale) / 100 +
              (((endTask.height * scale) / 100) * parseInt(endParts[1], 10)) /
                100,
            d: route.end.d,
          };
        }

        if (startTask.taskType === 'start') {
          if (startParts[0] === 'b') {
            startPoint = {
              x: (startTask.x * scale) / 100,
              y: ((startTask.y + 24) * scale) / 100,
              d: route.start.d,
            };
          }
          if (startParts[0] === 'l') {
            startPoint = {
              x: ((startTask.x - 24) * scale) / 100,
              y: (startTask.y * scale) / 100,
              d: route.start.d,
            };
          }
          if (startParts[0] === 'r') {
            startPoint = {
              x: ((startTask.x + 24) * scale) / 100,
              y: (startTask.y * scale) / 100,
              d: route.start.d,
            };
          }
        }
      }
    }

    return {
      start: startPoint,
      end: endPoint,
    };
  }

  generateNodalPoints(route) {
    // step 1 - determine 4 points

    if (route.objectId === null || route.objectId === undefined) {
      route.objectId = this.createGuid();
    }

    var p = this.calculateEndPoints(route);

    var p0 = p.start;

    var controlPoints = this.calculateControlPoints(p.start, p.end, true);
    var p1 = controlPoints.c1;
    var p2 = controlPoints.c2;
    var p3 = p.end;

    var nodeList = this.generateNodalPointsBetween(p0, p1, p2, p3, 0, 1, true);
    var fullNodeList = [p0, ...nodeList, p3];

    var existingNodeList = routeNodes.find(
      (n) => n.objectId === route.objectId
    );
    if (existingNodeList === undefined) {
      routeNodes.push({
        objectId: route.objectId,
        nodes: fullNodeList,
      });
    } else {
      existingNodeList.nodes = fullNodeList;
    }
  }

  generateNodalPointsBetween(p0, p1, p2, p3, t0, t1, force) {
    // calculate start and end point
    //  Cubic Bezier curve
    //  B(t) = (1-t)^3*P0 + 3(1-t)^2tP1 + 3(1-t)t^2P2 + t^3P3
    var startx =
      (1 - t0) ** 3 * p0.x +
      3 * (1 - t0) ** 2 * t0 * p1.x +
      3 * (1 - t0) * t0 ** 2 * p2.x +
      t0 ** 3 * p3.x;
    var endx =
      (1 - t1) ** 3 * p0.x +
      3 * (1 - t1) ** 2 * t1 * p1.x +
      3 * (1 - t1) * t1 ** 2 * p2.x +
      t1 ** 3 * p3.x;
    var starty =
      (1 - t0) ** 3 * p0.y +
      3 * (1 - t0) ** 2 * t0 * p1.y +
      3 * (1 - t0) * t0 ** 2 * p2.y +
      t0 ** 3 * p3.y;
    var endy =
      (1 - t1) ** 3 * p0.y +
      3 * (1 - t1) ** 2 * t1 * p1.y +
      3 * (1 - t1) * t1 ** 2 * p2.y +
      t1 ** 3 * p3.y;

    // calculate distance between
    var distance = Math.sqrt((startx - endx) ** 2 + (starty - endy) ** 2);
    if (distance > 75 || force) {
      // get mid t
      var midt = t0 + (t1 - t0) / 2;
      var midx =
        (1 - midt) ** 3 * p0.x +
        3 * (1 - midt) ** 2 * midt * p1.x +
        3 * (1 - midt) * midt ** 2 * p2.x +
        midt ** 3 * p3.x;
      var midy =
        (1 - midt) ** 3 * p0.y +
        3 * (1 - midt) ** 2 * midt * p1.y +
        3 * (1 - midt) * midt ** 2 * p2.y +
        midt ** 3 * p3.y;

      var list1 = this.generateNodalPointsBetween(
        p0,
        p1,
        p2,
        p3,
        t0,
        midt,
        false
      );
      var list2 = this.generateNodalPointsBetween(
        p0,
        p1,
        p2,
        p3,
        midt,
        t1,
        false
      );
      var result = [...list1, { x: midx, y: midy, d: '' }, ...list2];
      return result;
    } else return [];
  }
}

export default AdvWorkflowEdit;
