import { EventEmitter } from 'events';
import Dispatcher from '../Dispatcher';
import actionTypes from '../actions/ActionTypes';

const CHANGE_EVENT = 'connectionChange';
let _allConnections = undefined;
let _currentConnection = undefined;
let _lastChange = '';
let _lastError = '';

class ConnectionStore extends EventEmitter {
  addChangeListener(callback) {
    this.on(CHANGE_EVENT, callback);
  }

  removeChangeListener(callback) {
    this.removeListener(CHANGE_EVENT, callback);
  }

  emitChange() {
    this.emit(CHANGE_EVENT);
  }

  getConnections() {
    return _allConnections;
  }

  getCurrentConnection() {
    return _currentConnection;
  }

  lastChange() {
    return _lastChange;
  }

  getLastError() {
    return _lastError;
  }
}

const store = new ConnectionStore();

Dispatcher.register((action) => {
  _lastChange = action.actionType;
  switch (action.actionType) {
    // loading the full list of Companion schemas
    case actionTypes.ConnectionList:
      _lastChange = 'ConnectionList';
      _allConnections = action.Result;
      store.emitChange();
      break;
    case actionTypes.ConnectionLoadOne:
      _lastChange = 'ConnectionLoadOne';
      _currentConnection = action.Result;
      store.emitChange();
      break;
    case actionTypes.ConnectionUpdateOne:
      _lastChange = 'ConnectionUpdateOne';
      if (action.Result.status === 'Success') {
        _lastError = '';
        _currentConnection = action.Result;
      } else {
        _lastError = action.Result.updateMessage;
      }
      store.emitChange();
      break;
    case actionTypes.ConnectionCreateOne:
        _lastChange = 'ConnectionCreateOne';
        if (action.Result.status === 'Success') {
          _lastError = '';
          _currentConnection = action.Result;
        } else {
          _lastError = action.Result.updateMessage;
        }
        store.emitChange();
        break;
    case actionTypes.ConnectionDelete:
      _lastChange = 'ConnectionDelete';
      if (action.Result.updateStatus === 'Success') {
        _lastError = '';
        _currentConnection = action.Result.result;
      } else {
        _lastError = action.Result.errorMessage;
      }
      store.emitChange();
      break;
    default:
  }
});

export default store;
