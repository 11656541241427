import { EventEmitter } from 'events';
import Dispatcher from '../Dispatcher';
import actionTypes from '../actions/ActionTypes';

const CHANGE_EVENT = 'activeDocChange';
let _adTree = undefined;
let _adNode = undefined;
let _lastChange = '';
let _formTypeId = 0;
let _currentDocs = [];
let _columns = [];
let _docCount = 0;
let _bpName = '';
let _stepName = '';
let _bpPermissions = undefined;
let _offset = 0;
let _recentQuery = '';

class ActiveDocumentStore extends EventEmitter {
  addChangeListener(callback) {
    this.on(CHANGE_EVENT, callback);
  }

  removeChangeListener(callback) {
    this.removeListener(CHANGE_EVENT, callback);
  }

  emitChange() {
    this.emit(CHANGE_EVENT);
  }

  getADTree() {
    return _adTree;
  }

  getDocCount() {
    return _docCount;
  }

  getColumns() {
    return _columns;
  }

  getDocs() {
    return _currentDocs;
  }

  getLastNodeChange() {
    return _adNode;
  }

  getFormTypeId() {
    return _formTypeId;
  }

  getBPPermissions() {
    return _bpPermissions;
  }

  getBPName() {
    return _bpName;
  }

  getStepName() {
    return _stepName;
  }

  getOffset() {
    return _offset;
  }

  getRecentQuery() {
    return _recentQuery;
  }

  lastChange() {
    return _lastChange;
  }
}

const store = new ActiveDocumentStore();

Dispatcher.register((action) => {
  _lastChange = action.actionType;
  switch (action.actionType) {
    // loading the full list of Companion schemas
    case actionTypes.ADGetADTree:
      _lastChange = actionTypes.ADGetADTree;
      _adTree = action.Result;

      store.emitChange();
      break;
    case actionTypes.ADGetStep:
      //console.log(action.Result);
      _lastChange = actionTypes.ADGetStep;
      _currentDocs = action.Result.docData;
      _docCount = action.Result.docCount;
      _columns = action.Result.columns;
      _formTypeId = action.Result.formTypeId;
      _bpName = action.Result.bpName;
      _stepName = action.Result.step;
      _offset = action.Result.offset;
      _recentQuery = action.Result.fullQuery;
      store.emitChange();
      break;
    case actionTypes.BPGetPermission:
      _lastChange = actionTypes.BPGetPermission;
      _bpPermissions = action.Result;
      store.emitChange();
      break;
    default:
  }
});

export default store;
