import * as React from 'react';
import Dropzone from 'react-dropzone';
import { Link } from 'react-router-dom';
import * as Globals from '../../Globals';
import { createBrowserHistory } from 'history';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';

import { GetBPList } from '../../actions/BPActions';
import actionTypes from '../../actions/ActionTypes';
import { GetTemplateList } from '../../actions/TemplateActions';
import {
  UploadFile,
  GetUploadStatus,
  ClearCompletedUploads,
} from '../../actions/WebUploadActions';
import { CanUserWebUpload } from '../../actions/LoginActions';
import { LoadIntakeStrategies } from '../../actions/ISActions';
import { GetActiveLicense } from '../../actions/LicenseActions';

import BPStore from '../../stores/BPStore';
import LoginStore from '../../stores/LoginStore';
import TemplateStore from '../../stores/TemplateStore';
import ISStore from '../../stores/IntakeStrategyStore';
import WebUploadStore from '../../stores/WebUploadStore';
import LicenseStore from '../../stores/LicenseStore';
import { WebUpload } from '../../models/admin/WebUpload';

const customHistory = createBrowserHistory();

class WebUploadView extends React.Component {
  constructor(props) {
    super(props);
    // state
    this.interval = undefined;
    this.state = {
      currentUser: '',
      userCanUpload: false,
      routingType: 1,
      legacyBPs: [],
      selectedFtp: 0,
      selectedTemplate: 0,
      filteredTemplates: [],
      allTemplates: [],
      showUpload: false,
      selectedIntakeStrategy: 0,
      intakeStrats: [],
      currentUploads: [],
      validLicense: true,
      screenHeight: window.innerHeight - 480,
    };

    // bindings
    this.handleRoutingChange = this.handleRoutingChange.bind(this);
    this.onLoginUpdate = this.onLoginUpdate.bind(this);
    this.onLegacyBPUpdate = this.onLegacyBPUpdate.bind(this);
    this.onISUpdate = this.onISUpdate.bind(this);
    this.handleDefaultFtpChange = this.handleDefaultFtpChange.bind(this);
    this.onTemplateStoreUpdate = this.onTemplateStoreUpdate.bind(this);
    this.handleTemplateChange = this.handleTemplateChange.bind(this);
    this.evaluateUploadVisibility = this.evaluateUploadVisibility.bind(this);
    this.handleIntakeStrategyChange =
      this.handleIntakeStrategyChange.bind(this);
    this.onDrop = this.onDrop.bind(this);
    this.onWebUploadUpdate = this.onWebUploadUpdate.bind(this);
    this.onTimerClick = this.onTimerClick.bind(this);
    this.checkForCachedSetting = this.checkForCachedSetting.bind(this);
    this.reloadPreviousUploads = this.reloadPreviousUploads.bind(this);
    this.onLicenseUpdate = this.onLicenseUpdate.bind(this);
    this.sortUploads = this.sortUploads.bind(this);
    this.ClearUploads = this.ClearUploads.bind(this);
    this.onWindowResize = this.onWindowResize.bind(this);
  }

  componentDidMount() {
    BPStore.addChangeListener(this.onLegacyBPUpdate);
    LoginStore.addChangeListener(this.onLoginUpdate);
    TemplateStore.addChangeListener(this.onTemplateStoreUpdate);
    ISStore.addChangeListener(this.onISUpdate);
    WebUploadStore.addChangeListener(this.onWebUploadUpdate);
    LicenseStore.addChangeListener(this.onLicenseUpdate);
    this.interval = setInterval(this.onTimerClick, 1500);
    CanUserWebUpload();
    GetBPList();
    GetTemplateList();
    LoadIntakeStrategies();
    GetActiveLicense();
    this.checkForCachedSetting();
    this.reloadPreviousUploads();
    window.onresize = this.onWindowResize;
    CanUserWebUpload();
  }

  componentWillUnmount() {
    BPStore.removeChangeListener(this.onLegacyBPUpdate);
    LoginStore.removeChangeListener(this.onLoginUpdate);
    TemplateStore.removeChangeListener(this.onTemplateStoreUpdate);
    ISStore.removeChangeListener(this.onISUpdate);
    WebUploadStore.removeChangeListener(this.onWebUploadUpdate);
    LicenseStore.removeChangeListener(this.onLicenseUpdate);

    clearInterval(this.interval);
  }

  onWindowResize(e) {
    this.setState({screenHeight: window.innerHeight - 480});
  }

  onLegacyBPUpdate() {
    let bps = BPStore.getBPList();
    this.setState({ legacyBPs: bps }, this.checkForCachedSetting);
  }

  onTemplateStoreUpdate() {
    let templates = TemplateStore.getTemplateList();
    this.setState({ allTemplates: templates }, this.checkForCachedSetting);
  }

  onISUpdate() {
    let intakes = ISStore.getIntakeStrategies();
    this.setState({ intakeStrats: intakes }, this.checkForCachedSetting);
  }

  onWebUploadUpdate() {
    let uploads = WebUploadStore.getCurrentUploads();
    let activeUploads = '';

    uploads.forEach((upload) => {
      if (upload.regStatus === 'Not Registered') {
        if (activeUploads.length > 0) {
          activeUploads = activeUploads + ',';
        }
        activeUploads = activeUploads + upload.uploadId.toString();
      }
    });

    localStorage.setItem('uploadsInProgress', activeUploads);
    this.setState({ currentUploads: uploads }, this.sortUploads);
  }

  onLoginUpdate() {
    let lastAction = LoginStore.getLastAction();
    if (lastAction === actionTypes.CanUserWebUpload) {
      let canWebUpload = LoginStore.getCanWebUpload();
      this.setState({ userCanUpload: canWebUpload });
    }
  }

  onLicenseUpdate() {
    let lic = LicenseStore.getCurrentLicense();
    let canUpload = false;
    if (
      lic.hasLicense === true &&
      lic.isValid === true &&
      lic.isExpired === false
    ) {
      canUpload = true;
    }
    this.setState({ validLicense: canUpload });
  }

  checkForCachedSetting() {
    let settings = JSON.parse(localStorage.getItem('webUploadDefault'));

    if (settings !== null) {
      // ftp
      if (
        this.state.legacyBPs.find((f) => f.bpId === settings.defaultFtp) !==
        undefined
      ) {
        let bp = this.state.legacyBPs.find(
          (f) => f.bpId === settings.defaultFtp
        );
        this.setState(
          {
            selectedFtp: settings.defaultFtp,
            routingType: settings.routingType,
          },
          this.evaluateUploadVisibility
        );
        this.updateTemplateFromSettings(
          bp.formTypeId,
          settings.selectedTemplate
        );
      }
      // template
      if (
        this.state.filteredTemplates.find(
          (f) => f.templateId === settings.selectedTemplate
        ) !== undefined
      ) {
        this.setState(
          { selectedTemplate: settings.selectedTemplate },
          this.evaluateUploadVisibility
        );
      }
      if (
        this.state.intakeStrats.find(
          (f) => f.id === settings.selectedIntakeStrategy
        ) !== undefined
      ) {
        this.setState(
          { selectedIntakeStrategy: settings.selectedIntakeStrategy },
          this.evaluateUploadVisibility
        );
      }
    }
  }

  updateLocalSettings() {
    let settings = {
      defaultFtp: this.state.selectedFtp,
      routingType: this.state.routingType,
      selectedIntakeStrategy: this.state.selectedIntakeStrategy,
      selectedTemplate: this.state.selectedTemplate,
    };
    localStorage.setItem('webUploadDefault', JSON.stringify(settings));
  }

  handleRoutingChange(e) {
    this.setState(
      {
        routingType: parseInt(e.target.value, 10),
      },
      this.evaluateUploadVisibility
    );
  }

  onTimerClick() {
    this.state.currentUploads.forEach((upload) => {
      if (upload.regStatus === 'Not Registered') {
        GetUploadStatus(upload.uploadId);
      }
    });
  }

  reloadPreviousUploads() {
    let uip = localStorage.getItem('uploadsInProgress');
    if (uip !== null && uip !== '') {
      let uploadIds = uip.split(',');
      uploadIds.forEach((uploadId) => {
        GetUploadStatus(parseInt(uploadId, 10));
      });
    }
  }

  updateTemplateFromSettings(ftpId, templateId) {
    let temp = [];
    let formType = this.state.allTemplates.find(
      (ft) => ft.formTypeId === ftpId
    );
    if (formType !== undefined) {
      formType.templates.forEach((t) => {
        if(t.isActive) {
          temp.push(t);
        }
      });
      this.setState(
        {
          filteredTemplates: temp,
        },
        () => this.updateTemplateSelection(templateId)
      );
    }
    let defaultTemplate = temp.find((t) => t.templateId === templateId);
    if(defaultTemplate === undefined)
    {
      // invalid default Template
      console.log('default template to 0');
      this.setState({selectedTemplate: 0});
    }
  }

  updateTemplateSelection(templateId) {
    this.setState({ selectedTemplate: templateId });
  }

  handleDefaultFtpChange(e) {
    let temp = [];
    let bp = this.state.legacyBPs.find(
      (b) => b.bpId === parseInt(e.target.value, 10)
    );
    if (bp !== undefined) {
      this.state.allTemplates.forEach((t) => {
        if (t.formTypeId === bp.formTypeId) {
          t.templates.forEach((template) => {
            if(template.isActive) {
              temp.push(template);
            }
          });
        }
      });
    }
    this.setState(
      {
        selectedFtp: parseInt(e.target.value, 10),
        selectedTemplate: 0,
        filteredTemplates: temp,
      },
      this.evaluateUploadVisibility
    );
  }

  handleIntakeStrategyChange(e) {
    this.setState(
      {
        selectedIntakeStrategy: parseInt(e.target.value, 10),
      },
      this.evaluateUploadVisibility
    );
  }

  handleTemplateChange(e) {
    this.setState({
      selectedTemplate: parseInt(e.target.value, 10),
    });
  }

  evaluateUploadVisibility() {
    let routing = this.state.routingType;
    let currentVis = this.state.showUpload;
    if (routing === 1) {
      if (this.state.selectedFtp !== 0 && !currentVis) {
        this.setState({ showUpload: true });
      }
      if (this.state.selectedFtp === 0 && currentVis) {
        this.setState({ showUpload: false });
      }
    }
    if (routing === 2) {
      if (this.state.selectedIntakeStrategy !== 0 && !currentVis) {
        this.setState({ showUpload: true });
      }
      if (this.state.selectedIntakeStrategy === 0 && currentVis) {
        this.setState({ showUpload: false });
      }
    }
  }

  onDocLinkClick(docId) {
    //routeTo('/document/' + docId.toString());
    customHistory.push(`/Document/${docId.toString()}`);
  }

  sortUploads() {
    let currentUploads = this.state.currentUploads;
    let sorted = currentUploads.sort((a, b) => {
      return b.uploadId - a.uploadId;
    });
    this.setState({ currentUploads: sorted });
  }

  onDrop(files) {
    let userName = localStorage.getItem('userName');

    this.updateLocalSettings();

    files.forEach((file) => {
      let upload = new WebUpload();
      upload.userName = userName;
      upload.file = file;
      upload.routingType = this.state.routingType;
      upload.defaultFtp = this.state.selectedFtp;
      upload.defaultTemplate = this.state.selectedTemplate;
      upload.intakeStrategyId = this.state.selectedIntakeStrategy;
      console.log(upload);
      UploadFile(upload);
    });
  }

  ClearUploads() {
    ClearCompletedUploads();
  }

  DropZoneAccept() {
    return Globals.pdfFileType;
  }

  render() {
    return (
      <div
        className='d-flex flex-column flex-fill px-4 td-background'
        style={{ padding: '10px' }}
      >
        {!this.state.validLicense && (
          <div
            className='alert alert-warning h4'
            role='alert'
            style={{ marginTop: '30px', padding: '24px' }}
          >
            <i className='fas fa-exclamation-triangle'></i> Your license is
            currently expired. Please renew your license to enable web uploads.
          </div>
        )}
        {!this.state.userCanUpload && (
          <div
            className='alert alert-info'
            role='alert'
            style={{ marginTop: '30px', padding: '24px' }}
          >
            <FontAwesomeIcon icon={solid('hand')} /> Your account does not have
            permission to upload to any form types.
          </div>
        )}
        {this.state.validLicense && this.state.userCanUpload && (
          <div
            className='card'
            style={{
              marginTop: '20px',
              marginLeft: '30px',
              marginRight: '30px',
              maxHeight: '620px',
            }}
          >
            <div className='card-header'>
              <h4>Web Upload</h4>
            </div>
            <div className='card-body'>
              <div className='row'>
                <div className='col-3'>
                  <div className='form-check'>
                    <input
                      className='form-check-input'
                      type='radio'
                      name='stdRoutingRadio'
                      id='stdRoutingRdo'
                      value='1'
                      checked={this.state.routingType === 1}
                      onChange={this.handleRoutingChange}
                    />
                    <label className='form-check-label' htmlFor='stdRoutingRdo'>
                      Legacy
                    </label>
                  </div>
                </div>
                <div className='col-3'>
                  <div className='form-check'>
                    <input
                      className='form-check-input'
                      type='radio'
                      name='isRoutingRadio'
                      id='isRoutingRdo'
                      value='2'
                      checked={this.state.routingType === 2}
                      onChange={this.handleRoutingChange}
                    />
                    <label className='form-check-label' htmlFor='stdRoutingRdo'>
                      Intake Strategy
                    </label>
                  </div>
                </div>
              </div>
              {this.state.routingType === 1 && (
                <div className='row'>
                  <div className='col'>
                    <div className='mt-2'>Business Process:</div>
                    <select
                      className='form-select'
                      aria-label='Select default business process'
                      value={this.state.selectedFtp}
                      onChange={this.handleDefaultFtpChange}
                    >
                      <option value='0'>Select a Business Process</option>
                      {this.state.legacyBPs.map((bp) => (
                        <option key={bp.bpId} value={bp.bpId}>
                          {bp.bpName}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className='col'>
                    <div className='mt-2'>Template:</div>
                    <select
                      className='form-select'
                      aria-label='Select default business process'
                      value={this.state.selectedTemplate}
                      onChange={this.handleTemplateChange}
                    >
                      <option value='0'>Smart Template Matching</option>
                      {this.state.filteredTemplates.map((t) => (
                        <option key={t.templateId} value={t.templateId}>
                          {t.name}
                        </option>
                      ))}
                      <option value='999'>No Template</option>
                    </select>
                  </div>
                </div>
              )}
              {this.state.routingType === 2 && (
                <div>
                  <div className='row'>
                    <div className='col-6'>
                      <div className='h5' style={{ marginTop: '10px' }}>
                        Intake Strategy
                      </div>
                      <select
                        className='form-select'
                        aria-label='Select default business process'
                        value={this.state.selectedIntakeStrategy}
                        onChange={this.handleIntakeStrategyChange}
                      >
                        <option value='0'>Select an Intake Strategy</option>
                        {this.state.intakeStrats.map((is) => (
                          <option key={is.id} value={is.id}>
                            {is.isName}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
              )}
              <hr />
              {this.state.showUpload && (
                <Dropzone
                  className='ui raised segment'
                  multiple={true}
                  onDrop={this.onDrop}
                  accept={{
                    'application/pdf': ['.pdf'],
                  }}
                >
                  {/*{() => (<div className='ui raised segment'>
                    <div className='ui center aligned container'>
                    <h2 className='ui icon header'>
                      <FontAwesomeIcon icon={solid('upload')} size='2x'/>

                      <div className='content'>
                        Document Upload
                        <div className='sub header'>
                          Drag & Drop a file onto this space to begin uploading.
                          You can also click this space to open a file browser
                        </div>
                      </div>
                    </h2>
              </div></div>)}*/}
                  {({ getRootProps, getInputProps }) => (
                    <div className='drop-container'>
                      <div {...getRootProps({ className: 'dropzone' })}>
                        <input {...getInputProps()} />
                        <div className='drop-content'>
                          <FontAwesomeIcon icon={solid('upload')} size='3x' />
                          <div className='drop-header'>Document Upload</div>
                          <p className='drop-subheader'>
                            Drag {'&'} drop some files here, or click to select
                            files
                          </p>
                        </div>
                      </div>
                    </div>
                  )}
                </Dropzone>
              )}
              {!this.state.showUpload && (
                <div className='alert alert-secondary'>
                  Please select either a business process, or intake strategy.
                </div>
              )}
            </div>
          </div>
        )}
        {this.state.validLicense && (
          <div
            className='card'
            style={{
              marginTop: '20px',
              marginLeft: '30px',
              marginRight: '30px',
              maxHeight: `${this.state.screenHeight}px`,
            }}
          >
            <div className='card-header'>
              <h4>Uploads in Progress</h4>
            </div>
            <div
              className='card-body'
              style={{ maxHeight: `${this.state.screenHeight}px`, overflow: 'auto' }}
            >
              {this.state.currentUploads.map((upload) => (
                <div key={upload.uploadId}>
                  {upload.regStatus === 'Not Registered' && (
                    <div className='row' style={{ paddingBottom: '8px' }}>
                      <div className='col-3'>
                        <span>
                          <strong>Upload {upload.uploadId}</strong>
                        </span>
                        <span>
                          {' '}
                          - <FontAwesomeIcon icon={regular('file-alt')} />
                          <i
                            style={{ color: '#0dcaf0' }}
                            className='far fa-file-alt'
                          ></i>
                        </span>
                        <span> {upload.pageCount} Pages</span>
                      </div>
                      <div className='col-6'>
                        <div className='progress'>
                          <div
                            className='progress-bar bg-info'
                            role='progressbar'
                            style={{
                              width: upload.percent.toString() + '%',
                            }}
                            aria-valuenow='50'
                            aria-valuemin='0'
                            aria-valuemax='100'
                          ></div>
                        </div>
                      </div>
                      <div className='col-3'>
                        <FontAwesomeIcon
                          className='me-2'
                          title='Abort'
                          icon={solid('stop')}
                          style={{ color: '#AB2626', cursor: 'pointer' }}
                        />

                        <strong>
                          {upload.uploadStatus.includes('Queue') && (
                            <FontAwesomeIcon
                              style={{ color: '#0dcaf0' }}
                              icon={solid('layer-group')}
                            />
                          )}
                          {!upload.uploadStatus.includes('Queue') && (
                            <FontAwesomeIcon
                              style={{ color: '#0dcaf0' }}
                              className='spin'
                              icon={solid('spinner')}
                              spin
                            />
                          )}{' '}
                          {upload.percent.toString()}% - {upload.uploadStatus}
                        </strong>
                      </div>
                    </div>
                  )}
                  {upload.regStatus === 'Registered' && (
                    <div className='row' style={{ paddingBottom: '8px' }}>
                      <div className='col-3'>
                        <span>
                          <strong>Upload {upload.uploadId}</strong>
                        </span>
                        <span>
                          {' '}
                          -{' '}
                          <FontAwesomeIcon
                            style={{ color: '#288C25' }}
                            icon={regular('file-alt')}
                          />
                        </span>
                        <span>
                          {' '}
                          {upload.pageCount}{' '}
                          {upload.pageCount === 1 && <span>Page</span>}
                          {upload.pageCount > 1 && <span>Pages</span>}
                        </span>
                      </div>
                      <div className='col-3'>
                        <span
                          style={{
                            fontSize: '18px',
                            color: '#288C25',
                          }}
                        >
                          <strong>Complete</strong>
                        </span>
                      </div>
                      <div className='col-3'>
                        <i
                          style={{ color: '#288C25' }}
                          className='far fa-file-alt'
                        ></i>
                        {upload.regCount === 1 && (
                          <span>
                            <strong> 1 Document - </strong>
                          </span>
                        )}
                        {upload.regCount > 1 && (
                          <span>
                            <strong> {upload.regCount} Documents - </strong>
                          </span>
                        )}
                        {upload.regCount >= 1 && (
                          <span className='d-flex flex-wrap'
                            style={{
                              color: '#3368FF',
                              textDecoration: 'underline',
                            }}
                          >
                            {upload.docs.map((d) => (
                              <Link
                                className='me-2'
                                to={'/Document/' + d.documentId}
                                //onClick={() => this.handleTemplateClick(bp)}
                                style={{
                                  cursor: 'Pointer',
                                  wordWrap: 'normal',
                                }}
                              >
                                {d.documentId}
                              </Link>
                              /*<span
                                key={d.documentId}
                                style={{
                                  cursor: 'pointer',
                                  marginRight: '4px',
                                }}
                                onClick={() =>
                                  this.onDocLinkClick(d.documentId)
                                }
                              >
                                {d.documentId}{' '}
                              </span>*/
                            ))}
                          </span>
                        )}
                      </div>
                    </div>
                  )}
                  {upload.regStatus === 'Rejected' && (
                    <div className='row' style={{ paddingBottom: '8px' }}>
                      <div className='col-3'>
                        <span>
                          <strong>Upload {upload.uploadId}</strong>
                        </span>
                        <span>
                          {' '}
                          -{' '}
                          <i
                            style={{ color: '#8C2525' }}
                            className='far fa-file-alt'
                          ></i>
                        </span>
                        <span> {upload.pageCount} Page</span>
                      </div>
                      <div className='col-3'>
                        <span
                          style={{
                            fontSize: '18px',
                            color: '#8C2525',
                          }}
                        >
                          <strong>Rejected</strong>
                        </span>
                      </div>
                      <div className='col-6'>
                        <span>
                          <strong>Duplicate of document - </strong>
                        </span>
                        <Link
                          className='me-2'
                          to={'/Document/' + upload.docLink}
                          style={{
                            cursor: 'Pointer',
                            wordWrap: 'break-word',
                          }}
                        >
                          {upload.docLink}
                        </Link>
                      </div>
                    </div>
                  )}
                  {upload.regStatus === 'Fail' && (
                    <div className='row' style={{ paddingBottom: '8px' }}>
                      <div className='col-3'>
                        <span>
                          <strong>Upload {upload.uploadId}</strong>
                        </span>
                        <span>
                          {' '}
                          -{' '}
                          <FontAwesomeIcon
                            style={{ color: 'red' }}
                            icon={regular('file-excel')}
                          />
                          <i
                            style={{ color: '#8C2525' }}
                            className='far fa-file-alt'
                          ></i>
                        </span>
                        <span> {upload.pageCount} Page</span>
                      </div>
                      <div className='col-3'>
                        <span
                          style={{
                            fontSize: '18px',
                            color: '#8C2525',
                          }}
                        >
                          <strong>Failed</strong>
                        </span>
                      </div>
                      <div className='col-6'></div>
                    </div>
                  )}
                </div>
              ))}
              <button
                className='btn btn-secondary btn-sm'
                onClick={this.ClearUploads}
              >
                Clear Completed
              </button>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default WebUploadView;
