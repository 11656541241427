export const baseUrl = setBaseUrl();

function setBaseUrl() {
  let apiUrl;

  if (window.location.hostname === 'localhost') {
    if (window.location.port && window.location.port === '3000') {
      apiUrl = `//${window.location.hostname}:13201`;
    } else {
      apiUrl = `//localhost:${window.location.port}`;
    }
  } else {
    if (window.location.port === '3000') {
      apiUrl = `//${window.location.hostname}:13201`;
    } else if (window.location.port && window.location.port !== '80') {
      apiUrl = `//${window.location.hostname}:${window.location.port}`;
    } else {
      apiUrl = `//${window.location.hostname}`;
    }
  }

  apiUrl = `${apiUrl}/api`;

  return apiUrl;
}

export function getJwtToken() {
  return window.localStorage.getItem('td6Token');
}

// export function getNewUrl() {
//    return _apiUrl;
// }
// export async function setNewUrl() {
//    if (_apiUrl !== '') {
//       return _apiUrl;
//    }
//    let httpPort = 5000;
//    let httpsPort = 5002;

//    await fetch('/content/public/portConfig.json')
//    .then(response => response.json())
//    .then(data => {

//       httpPort = data.httpPort;
//       httpsPort = data.httpsPort;

//       if (window.location.hostname === 'localhost') {
//          _apiUrl = '//localhost:' + httpPort.toString();
//       } else {
//          if (window.location.protocol === 'http:') {
//             _apiUrl = `//${window.location.hostname}:${httpPort.toString()}`;
//          } else {
//             _apiUrl = `//${window.location.hostname}:${httpsPort.toString()}`;
//          }
//       }
//       _apiUrl = `${_apiUrl}/api`;
//    })
//    .catch(err => {
//       if (window.location.hostname === 'localhost') {
//          _apiUrl = '//localhost:5000';
//       } else {
//          if (window.location.protocol === 'http:') {
//             _apiUrl = `//${window.location.hostname}:5000`;
//          } else {
//             _apiUrl = `//${window.location.hostname}:5002`;
//          }
//       }
//       _apiUrl = `${_apiUrl}/api`;
//    });

//    return _apiUrl;
// }

let _apiUrl = '';
//export const newUrl: string = setNewUrl();
export function getNewUrl() {
  return _apiUrl;
}

export const pdfFileType = 'application/pdf';
export const tifFileType = 'image/tif';
export const tiffFileType = 'image/tiff';
export const octetStream = 'application/octet-stream';
export const zipFileType = 'application/zip, application/x-zip-compressed';
export const csvFileType = 'text/csv';
