import { EventEmitter } from 'events';
import Dispatcher from '../Dispatcher';
import actionTypes from '../actions/ActionTypes';

const CHANGE_EVENT = 'mailboxChange';
let _allMailboxes = undefined;
let _currentMailbox = undefined;
let _lastChange = '';
let _lastError = '';
let _testResult = undefined;
let _instruction = undefined;
let _authResult = undefined;

class MailboxStore extends EventEmitter {
  addChangeListener(callback) {
    this.on(CHANGE_EVENT, callback);
  }

  removeChangeListener(callback) {
    this.removeListener(CHANGE_EVENT, callback);
  }

  emitChange() {
    this.emit(CHANGE_EVENT);
  }

  getMailboxes() {
    return _allMailboxes;
  }

  getCurrentMailbox() {
    return _currentMailbox;
  }

  lastChange() {
    return _lastChange;
  }

  getLastError() {
    return _lastError;
  }

  getTestResult() {
    return _testResult;
  }

  getInstructions() {
    return _instruction;
  }

  getAuthResult() {
    return _authResult;
  }
}

const store = new MailboxStore();

Dispatcher.register((action) => {
  _lastChange = action.actionType;
  switch (action.actionType) {
    // loading the full list of Companion schemas
    case actionTypes.MailboxList:
      _lastChange = 'MailboxList';
      _allMailboxes = action.Result;
      store.emitChange();
      break;
    case actionTypes.MailboxLoadOne:
      _lastChange = 'MailboxLoadOne';
      _currentMailbox = action.Result;
      store.emitChange();
      break;
    case actionTypes.MailboxUpdateOne:
      _lastChange = actionTypes.MailboxUpdateOne;
      if (action.Result.updateStatus === 'Success') {
        _lastError = '';
        _currentMailbox = action.Result.result;
      } else {
        _lastError = action.Result.updateMessage;
      }
      store.emitChange();
      break;
    case actionTypes.MailboxCreateOne:
      _lastChange = actionTypes.MailboxCreateOne;
      if (action.Result.updateStatus === 'Success') {
        _lastError = '';
        _currentMailbox = action.Result.result;
      } else {
        _lastError = action.Result.updateMessage;
      }
      store.emitChange();
      break;
    case actionTypes.MailboxDelete:
      _lastChange = actionTypes.MailboxDelete;
      if (action.Result.updateStatus === 'Success') {
        _lastError = '';
        _currentMailbox = action.Result.result;
      } else {
        _lastError = action.Result.errorMessage;
      }
      store.emitChange();
      break;
    case actionTypes.MailboxText:
      _lastChange = actionTypes.MailboxText;
      _testResult = action.Result;
      store.emitChange();
      break;
    case actionTypes.MailboxInstructions:
      _lastChange = actionTypes.MailboxInstructions;
      _instruction = action.Result;
      store.emitChange();
      break;
    case actionTypes.MailboxAuthenticate:
      _lastChange = actionTypes.MailboxAuthenticate;
      _authResult = action.Result;
      store.emitChange();
      break;
    default:
  }
});

export default store;
