import { EventEmitter } from 'events';
import Dispatcher from '../Dispatcher';
import actionTypes from '../actions/ActionTypes';
//import { FormType } from '../Models/admin/FTModel';

const CHANGE_EVENT = 'ftChange';

//let _lastError: string = '';
let _currentFt = undefined;
let _lastAction = { actionType: '', status: '', message: '' };
let _allFormTypes = [];
let _allDataSources = [];

class FTStore extends EventEmitter {
  addChangeListener(callback) {
    this.on(CHANGE_EVENT, callback);
  }

  removeChangeListener(callback) {
    this.removeListener(CHANGE_EVENT, callback);
  }

  emitChange() {
    this.emit(CHANGE_EVENT);
  }

  getCurrentFT() {
    return _currentFt;
  }

  getLastAction() {
    return _lastAction;
  }

  getAllFormTypes() {
    return _allFormTypes;
  }

  getAllDataSources() {
    return _allDataSources;
  }
}

const store = new FTStore();

Dispatcher.register((action) => {
  switch (action.actionType) {
    case actionTypes.FTGetAll:
      _lastAction = {
        actionType: 'FTGetAll',
        status: action.Result.status,
        message: action.Result.updateMessage,
      };
      _allFormTypes = action.Result;
      store.emitChange();
      break;
    case actionTypes.FTLoad:
      _lastAction = {
        actionType: 'FTLoad',
        status: action.Result.status,
        message: action.Result.updateMessage,
      };
      _currentFt = action.Result;
      store.emitChange();
      break;
    case actionTypes.FTUpdate:
      _lastAction = {
        actionType: 'Update',
        status: action.Result.status,
        message: action.Result.updateMessage,
      };
      if (_lastAction.status === 'Success') {
        _currentFt = action.Result.data;
      }
      //console.log(_lastAction);
      store.emitChange();
      break;
    case actionTypes.FTGetAllDataSource:
      _lastAction = {
        actionType: actionTypes.FTGetAllDataSource,
        status: 'success',
        message: 'success',
      };
      _allDataSources = action.Result;
      store.emitChange();
      break;
    case actionTypes.FTUpdateExtender:
      _lastAction = {
        actionType: 'ExtenderUpdate',
        status: 'Success',
        message: 'extender updated',
      };
      let update = action.Result;
      _currentFt.fieldSets.forEach((fs) => {
        fs.fields.forEach((f) => {
          if (f.fieldId === update.fieldId) {
            f.hasExtender = true;
            f.extender = update;
          }
        });
      });
      store.emitChange();
      break;
    case actionTypes.FTRemoveExtender:
      _lastAction = {
        actionType: 'RemoveExtender',
        status: 'Success',
        message: 'extender removed',
      };
      store.emitChange();
      break;
    default:
  }
});

export default store;
