import dispatcher from '../Dispatcher';
import actionTypes from './ActionTypes';
import axios from 'axios';
import * as Globals from '../Globals';
import { PostAlert } from './AlertActions';

export function GetAutoOcrDocumentTypeList() {
  axios
    .get(`${Globals.baseUrl}/AutoOcr/GetDocumentTypeList`, {
      headers: {
        'content-type': 'application/json; charset=utf-8',
        Authorization: `Bearer ${Globals.getJwtToken()}`,
      },
    })
    .then((res) => {
      if (res.status === 200) {
        dispatcher.dispatch({
          actionType: actionTypes.AutoOcrDocumentTypeGetList,
          Result: res.data,
        });
      } else if (res.status === 400 || res.status === 401) {
        console.log('error: 401');
      }
    })
    .catch((res) => {
      let result = {
        updateStatus: 'Error',
        errorMessage: '401: Bad request',
      };
      dispatcher.dispatch({
        actionType: actionTypes.AutoOcrDocumentTypeGetList,
        Result: result,
      });
    });
}

export function GetAutoOcrFieldList(id) {
  axios
    .get(`${Globals.baseUrl}/AutoOcr/GetFieldList/${id}`, {
      headers: {
        'content-type': 'application/json; charset=utf-8',
        Authorization: `Bearer ${Globals.getJwtToken()}`,
      },
    })
    .then((res) => {
      if (res.status === 200) {
        dispatcher.dispatch({
          actionType: actionTypes.AutoOcrFieldGetList,
          Result: res.data,
        });
      } else if (res.status === 400 || res.status === 401) {
        console.log('error: 401');
      }
    })
    .catch((res) => {
      let result = {
        updateStatus: 'Error',
        errorMessage: '401: Bad request',
      };
      dispatcher.dispatch({
        actionType: actionTypes.AutoOcrFieldGetList,
        Result: result,
      });
    });
}

export function GetFieldIdentifierList(id) {
  axios
    .get(`${Globals.baseUrl}/AutoOcr/GetFieldIdentifierList/${id}`, {
      headers: {
        'content-type': 'application/json; charset=utf-8',
        Authorization: `Bearer ${Globals.getJwtToken()}`,
      },
    })
    .then((res) => {
      if (res.status === 200) {
        dispatcher.dispatch({
          actionType: actionTypes.AutoOcrFieldIdentifierList,
          Result: res.data,
        });
      } else if (res.status === 400 || res.status === 401) {
        console.log('error: 401');
      }
    })
    .catch((res) => {
      let result = {
        updateStatus: 'Error',
        errorMessage: '401: Bad request',
      };
      dispatcher.dispatch({
        actionType: actionTypes.AutoOcrFieldIdentifierList,
        Result: result,
      });
    });
}

export function GetFieldPatternList(id) {
  axios
    .get(`${Globals.baseUrl}/AutoOcr/GetFieldPatternList/${id}`, {
      headers: {
        'content-type': 'application/json; charset=utf-8',
        Authorization: `Bearer ${Globals.getJwtToken()}`,
      },
    })
    .then((res) => {
      if (res.status === 200) {
        dispatcher.dispatch({
          actionType: actionTypes.AutoOcrFieldPatternList,
          Result: res.data,
        });
      } else if (res.status === 400 || res.status === 401) {
        console.log('error: 401');
      }
    })
    .catch((res) => {
      let result = {
        updateStatus: 'Error',
        errorMessage: '401: Bad request',
      };
      dispatcher.dispatch({
        actionType: actionTypes.AutoOcrFieldPatternList,
        Result: result,
      });
    });
}

export function GetPatternList(id) {
  axios
    .get(`${Globals.baseUrl}/AutoOcr/GetPatternList/${id}`, {
      headers: {
        'content-type': 'application/json; charset=utf-8',
        Authorization: `Bearer ${Globals.getJwtToken()}`,
      },
    })
    .then((res) => {
      if (res.status === 200) {
        dispatcher.dispatch({
          actionType: actionTypes.AutoOcrPatternList,
          Result: res.data,
        });
      } else if (res.status === 400 || res.status === 401) {
        console.log('error: 401');
      }
    })
    .catch((res) => {
      let result = {
        updateStatus: 'Error',
        errorMessage: '401: Bad request',
      };
      dispatcher.dispatch({
        actionType: actionTypes.AutoOcrPatternList,
        Result: result,
      });
    });
}

export function GetFieldDataForDocType(id) {
  axios
    .get(`${Globals.baseUrl}/AutoOcr/GetFieldDataForDocType/${id}`, {
      headers: {
        'content-type': 'application/json; charset=utf-8',
        Authorization: `Bearer ${Globals.getJwtToken()}`,
      },
    })
    .then((res) => {
      if (res.status === 200) {
        dispatcher.dispatch({
          actionType: actionTypes.AutoOcrFieldDataList,
          Result: res.data,
        });
      } else if (res.status === 400 || res.status === 401) {
        console.log('error: 401');
      }
    })
    .catch((res) => {
      let result = {
        updateStatus: 'Error',
        errorMessage: '401: Bad request',
      };
      dispatcher.dispatch({
        actionType: actionTypes.AutoOcrFieldDataList,
        Result: result,
      });
    });
}

export function UpdateAutoOcrData(data, docTypeName, docTypeId) {
  axios
    .post(
      `${Globals.baseUrl}/AutoOcr/UpdateAutoOcrData`,
      { data: data, documentTypeName: docTypeName, documentTypeId: docTypeId },
      {
        headers: {
          'content-type': 'application/json; charset=utf-8',
          Authorization: `Bearer ${Globals.getJwtToken()}`,
        },
      }
    )
    .then((res) => {
      if (res.status === 200) {
        dispatcher.dispatch({
          actionType: actionTypes.AutoOcrDataUpdate,
          Result: res.data,
        });
        if (res.data.status === 'Success') {
            console.log('post alert');
          PostAlert('success', 'Auto Ocr data updated');
        }
        if (res.data.status === 'Error') {
          PostAlert('error', 'Unable to update Auto Ocr data');
        }
      } else if (res.status === 400 || res.status === 401) {
        console.log('error: 401');
      }
    })
    .catch((res) => {
      console.log(res);
    });
}

export function DeleteDocumentType(id) {
  axios
    .post(
      `${Globals.baseUrl}/AutoOcr/DeleteDocumentType`,
      { id: id },
      {
        headers: {
          'content-type': 'application/json; charset=utf-8',
          Authorization: `Bearer ${Globals.getJwtToken()}`,
        },
      }
    )
    .then((res) => {
      if (res.status === 200) {
        dispatcher.dispatch({
          actionType: actionTypes.AutoOcrDocTypeDelete,
          Result: res.data,
        });
      } else if (res.status === 400 || res.status === 401) {
        console.log('error: 401');
      }
    })
    .catch((res) => {
      console.log(res);
    });
}

export function GetAutoOcrFieldMapping(id) {
    axios
      .get(`${Globals.baseUrl}/AutoOcr/GetFieldMapping/${id}`, {
        headers: {
          'content-type': 'application/json; charset=utf-8',
          Authorization: `Bearer ${Globals.getJwtToken()}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          dispatcher.dispatch({
            actionType: actionTypes.AutoOcrMappingList,
            Result: res.data,
          });
        } else if (res.status === 400 || res.status === 401) {
          console.log('error: 401');
        }
      })
      .catch((res) => {
        let result = {
          updateStatus: 'Error',
          errorMessage: '401: Bad request',
        };
        dispatcher.dispatch({
          actionType: actionTypes.AutoOcrMappingList,
          Result: result,
        });
      });
}

export function GetAutoOcrFieldMappingDocumentType(id) {
  axios
    .get(`${Globals.baseUrl}/AutoOcr/GetFieldMappingDocumentType/${id}`, {
      headers: {
        'content-type': 'application/json; charset=utf-8',
        Authorization: `Bearer ${Globals.getJwtToken()}`,
      },
    })
    .then((res) => {
      if (res.status === 200) {
        dispatcher.dispatch({
          actionType: actionTypes.AutoOcrMappingDocumentType,
          Result: res.data,
        });
      } else if (res.status === 400 || res.status === 401) {
        console.log('error: 401');
      }
    })
    .catch((res) => {
      let result = {
        updateStatus: 'Error',
        errorMessage: '401: Bad request',
      };
      dispatcher.dispatch({
        actionType: actionTypes.AutoOcrMappingDocumentType,
        Result: result,
      });
    });
}

export function UpdateAutoOcrMapping(data, formTypeId) {
  axios
    .post(
      `${Globals.baseUrl}/AutoOcr/UpdateAutoOcrMapping`,
      { data: data, formTypeId: formTypeId },
      {
        headers: {
          'content-type': 'application/json; charset=utf-8',
          Authorization: `Bearer ${Globals.getJwtToken()}`,
        },
      }
    )
    .then((res) => {
      if (res.status === 200) {
        dispatcher.dispatch({
          actionType: actionTypes.AutoOcrMappingUpdate,
          Result: res.data,
        });
        if (res.data === 'Success') {
            console.log('post alert');
          PostAlert('success', 'Auto Ocr data updated');
        }
        if (res.data === 'Error') {
          PostAlert('error', 'Unable to update Auto Ocr data');
          PostAlert('error', res.data.updateMessage);
        }
      } else if (res.status === 400 || res.status === 401) {
        console.log('error: 401');
      }
    })
    .catch((res) => {
      console.log(res);
    });
}
