import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid, regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { Link } from 'react-router-dom';
import IRStore from '../../../stores/ImageReviewStore';
import TemplateStore from '../../../stores/TemplateStore';
import {
  GetImageReview,
  UpdateReview,
  FetchImage,
} from '../../../actions/ImageReviewActions';
import { GetTemplateList } from '../../../actions/TemplateActions';
import { GetBP } from '../../../actions/BPActions';
import BPStore from '../../../stores/BPStore';
import actionTypes from '../../../actions/ActionTypes';

class ImageReviewEdit extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      appWidth: window.innerWidth - 40,
      appHeight: window.innerHeight - 130,
      originals: [],
      newDocHover: false,
      dragPage: undefined,
      dropTarget: '',
      newDocs: [],
      showIRPreview: false,
      currentBP: undefined,
      workStatus: 'ready',
      results: undefined,
      showLargeImage: false,
      imagePreviewBase: '',
      imagePreviewData: {},
      businessProcess: undefined,
      templates: [],
    };

    this.onWindowResize = this.onWindowResize.bind(this);
    this.onImageReviewChange = this.onImageReviewChange.bind(this);
    this.onNewDragEnter = this.onNewDragEnter.bind(this);
    this.onNewDragLeave = this.onNewDragLeave.bind(this);
    this.onNewDrop = this.onNewDrop.bind(this);
    this.onDragStart = this.onDragStart.bind(this);
    this.onPageDragOver = this.onPageDragOver.bind(this);
    this.dropInExisting = this.dropInExisting.bind(this);
    this.onDeleteDragOver = this.onDeleteDragOver.bind(this);
    this.onDocumentDragLeave = this.onDocumentDragLeave.bind(this);
    this.rotatePage = this.rotatePage.bind(this);
    this.splitAll = this.splitAll.bind(this);
    this.toggleIRPreview = this.toggleIRPreview.bind(this);
    this.submitUpdate = this.submitUpdate.bind(this);
    this.deletePage = this.deletePage.bind(this);
    this.toggleLargeImage = this.toggleLargeImage.bind(this);
    this.onTemplateChange = this.onTemplateChange.bind(this);
    this.onBPChange = this.onBPChange.bind(this);
    this.setTemplate = this.setTemplate.bind(this);
  }

  componentDidMount() {
    window.onresize = this.onWindowResize;
    IRStore.addChangeListener(this.onImageReviewChange);
    TemplateStore.addChangeListener(this.onTemplateChange);
    BPStore.addChangeListener(this.onBPChange);
    this.loadDocuments();
  }

  componentWillUnmount() {
    IRStore.removeChangeListener(this.onImageReviewChange);
    TemplateStore.removeChangeListener(this.onTemplateChange);
    BPStore.removeChangeListener(this.onBPChange);
  }

  loadDocuments() {
    let docIds = IRStore.getReviewDocIds();
    GetImageReview(docIds);
  }

  onImageReviewChange() {
    let lastChange = IRStore.lastChange();
    switch (lastChange) {
      case actionTypes.IRGetImageReview:
        let docs = IRStore.getImageReviewDocs();
        let bp = IRStore.getBP();
        docs.forEach((doc) => {
          doc.pages.forEach((page) => {
            page.originalDoc = doc.docId;
            page.newDoc = doc.docId;
          });
        });
        this.setState({ originals: docs, newDocs: [], currentBP: bp });
        GetBP(bp.bpId);
        //GetTemplateList();
        break;
      case actionTypes.IRUpdate:
        let results = IRStore.getResults();
        this.setState({ results: results, workStatus: 'done' });
        break;
      case actionTypes.IRFetchImage:
        let newImage = IRStore.getImage();
        let imageData = this.state.imagePreviewData;
        imageData.base = newImage.imageBytes;
        this.setState({ imageData: imageData });
        break;
      default:
        break;
    }
  }

  onTemplateChange() {
    let templateList = TemplateStore.getTemplateList();
    console.log(templateList);
    console.log(this.state.businessProcess);
    if(templateList.length > 0){
      templateList.forEach((ft) => {
        if(ft.formTypeId === this.state.businessProcess.formTypeId)
        {
          console.log(ft);
          let usableTemplates = [...ft.templates];
          this.setState({templates: usableTemplates});
        }
      });
    }
  }

  onBPChange() {
    let bp = BPStore.getCurrentBP();
    console.log(bp);
    this.setState({businessProcess: bp});
    GetTemplateList();
  }

  onWindowResize(e) {
    //setViewWidth
    this.setState({
      appWidth: window.innerWidth - 40,
      appHeight: window.innerHeight - 130,
    });
  }

  onDragStart(page) {
    this.setState({ dragPage: page });
  }

  onDragOver(event) {
    event.preventDefault();
  }

  onPageDragOver(event, page) {
    if (page.pageRef !== this.state.dragPage.pageRef) {
      event.preventDefault();
      let pc = document.getElementById(
        'pageContainer_' + page.pageId.toString()
      );
      let rect = pc.getBoundingClientRect();

      if (event.pageY < rect.top + rect.height / 2) {
        if (this.state.dropTarget !== page.pageRef + '-t') {
          this.setState({ dropTarget: page.pageRef + '-t' });
        }
      } else {
        if (this.state.dropTarget !== page.pageRef + '-b') {
          this.setState({ dropTarget: page.pageRef + '-b' });
        }
      }
    }
  }

  onDeleteDragOver(event, doc) {
    event.preventDefault();
    this.setState({ dropTarget: doc.docId.toString() + '-0-t' });
  }

  onDocumentDragLeave(event) {
    this.setState({ dropTarget: '' });
  }

  onNewDragEnter(event) {
    this.setState({ newDocHover: true });
  }

  onNewDragLeave(event) {
    this.setState({ newDocHover: false });
  }

  rotatePage(page, doc) {
    let originals = this.state.originals;
    let newDocs = this.state.newDocs;
    let srcDoc = originals.find((d) => d.docId === page.newDoc);
    if (page.newDoc < 0) {
      srcDoc = newDocs.find((d) => d.docId === page.newDoc);
    }
    let pageToRotate = srcDoc.pages.find((p) => p.pageRef === page.pageRef);
    switch (pageToRotate.rotation) {
      case undefined:
      case 0:
        pageToRotate.rotation = 90;
        break;
      case 90:
        pageToRotate.rotation = 180;
        break;
      case 180:
        pageToRotate.rotation = 270;
        break;
      case 270:
        pageToRotate.rotation = 0;
        break;
      default:
        break;
    }

    if (page.newDoc > 0) {
      this.setState({ originals: originals });
    } else {
      this.setState({ newDocs: newDocs });
    }
  }

  onNewDrop(ev) {
    this.setState({ newDocHover: false });
    let newDoc = {
      docId: -1,
      pages: [],
      templateId: -1,
    };
    this.state.newDocs.forEach((nd) => {
      if (nd.docId <= newDoc.docId) {
        newDoc.docId = nd.docId - 1;
      }
    });
    let dragPage = this.state.dragPage;
    let docSrcId = dragPage.newDoc;
    dragPage.newDoc = newDoc.docId;
    newDoc.pages.push(dragPage);
    let newDocs = this.state.newDocs;
    let originals = this.state.originals;

    let sourceDoc = originals.find((d) => d.docId === docSrcId);
    console.log(sourceDoc);
    if (docSrcId < 0) {
      sourceDoc = newDocs.find((d) => d.docId === docSrcId);
    }

    newDoc.templateId = -1;
    newDoc.templateName = 'Smart Template Matching';
    newDocs.push(newDoc);
    this.setState({ newDocs: newDocs });


    let sdoc = sourceDoc.pages.find((s) => s.pageRef === dragPage.pageRef);
    if (sdoc) {
      let sidx = sourceDoc.pages.indexOf(sdoc);
      sourceDoc.pages.splice(sidx, 1);
    }
    this.setState({
      originals: originals,
      newDocs: newDocs,
      dropTarget: '',
      dragPage: undefined,
    });
  }

  submitUpdate() {
    UpdateReview(
      this.state.originals,
      this.state.newDocs,
      this.state.currentBP.bpId
    );
    this.setState({ workStatus: 'working' });
  }

  toggleIRPreview() {
    let showPreview = this.state.showIRPreview;
    this.setState({ showIRPreview: !showPreview, workStatus: 'ready' });
  }

  dropInExisting(ev, doc) {
    let targetParts = this.state.dropTarget.split('-');
    let dragPage = this.state.dragPage;
    let originals = this.state.originals;
    let newDocs = this.state.newDocs;
    let sourceDoc = originals.find((d) => d.docId === dragPage.newDoc);
    if (dragPage.newDoc < 0) {
      sourceDoc = newDocs.find((d) => d.docId === dragPage.newDoc);
    }
    let destDoc = doc;

    if (sourceDoc !== null && destDoc != null) {
      // first remove from dest...
      let sdoc = sourceDoc.pages.find((s) => s.pageRef === dragPage.pageRef);
      if (sdoc) {
        let sidx = sourceDoc.pages.indexOf(sdoc);
        sourceDoc.pages.splice(sidx, 1);
      }

      // and insert into new
      let dref = targetParts[0] + '-' + targetParts[1];

      let ddoc = destDoc.pages.find((d) => d.pageRef === dref);
      if (ddoc) {
        let didx = destDoc.pages.indexOf(ddoc);
        dragPage.newDoc = destDoc.docId;
        if (targetParts[2] === 't') {
          destDoc.pages.splice(didx, 0, dragPage);
        }
        if (targetParts[2] === 'b') {
          destDoc.pages.splice(didx + 1, 0, dragPage);
        }
      } else if (targetParts[1] === '0') {
        dragPage.newDoc = destDoc.docId;
        destDoc.pages.push(dragPage);
      }
      if (sourceDoc.docId < 0 && sourceDoc.pages.length === 0) {
        // destroy new doc
        let nidx = newDocs.indexOf(sourceDoc);
        newDocs.splice(nidx, 1);
      }
      this.setState({
        originals: originals,
        newDocs: newDocs,
        dropTarget: '',
        dragPage: undefined,
      });
    }
  }

  splitAll(doc) {
    let pindex = 1;
    let originals = this.state.originals;
    let newDocs = this.state.newDocs;
    let nextDocId = -1;
    newDocs.forEach((d) => {
      if (d.docId <= nextDocId) {
        nextDocId = d.docId - 1;
      }
    });
    doc.pages.forEach((p) => {
      if (pindex > 1) {
        // create new doc and push
        let newDoc = {
          docId: nextDocId,
          pages: [],
        };
        p.newDoc = nextDocId;
        newDoc.pages.push(p);
        newDoc.templateId = doc.templateId;
        newDoc.templateName = doc.templateName;
        newDocs.push(newDoc);
        nextDocId--;

        // remove old page
        // let idx  = doc.pages.indexOf(p);
        // doc.pages.splice(idx,1);
      }
      pindex++;
    });
    doc.pages = [doc.pages[0]];
    this.setState({ originals: originals, newDocs: newDocs });
  }

  deletePage(page, doc) {
    let originals = this.state.originals;
    let newDocs = this.state.newDocs;
    let srcDoc = originals.find((d) => d.docId === doc.docId);

    if (srcDoc !== undefined) {
      let idx = srcDoc.pages.indexOf(page);
      if (idx >= 0) {
        srcDoc.pages.splice(idx, 1);
        this.setState({ originals: originals });
      }
    } else {
      srcDoc = newDocs.find((d) => d.docId === doc.docId);
      if (srcDoc !== undefined) {
        let idx = srcDoc.pages.indexOf(page);
        srcDoc.pages.splice(idx, 1);
        if (srcDoc.pages.length === 0) {
          let idx2 = newDocs.indexOf(srcDoc);
          newDocs.splice(idx2, 1);
        }
        this.setState({ newDocs: newDocs });
      }
    }
  }

  toggleLargeImage(page) {
    let show = this.state.showLargeImage;
    if (page) {
      FetchImage(page.originalDoc, page.pageId);
      let previewData = {
        pageId: page.pageId,
        docId: page.originalDoc,
        base: page.base64,
      };
      this.setState({ showLargeImage: !show, imagePreviewData: previewData });
    } else {
      this.setState({ showLargeImage: !show });
    }
  }

  setTemplate(e, doc)
  {
    let selectedTemplateId = parseInt(e.target.value, 10);
    let newDocs = this.state.newDocs;
    let newDoc = newDocs.find(d => d.docId === doc.docId);
    console.log(newDoc);
    newDoc.templateId = selectedTemplateId;
    if(selectedTemplateId === 0)
    {
      newDoc.templateName = 'No Template';
    }
    else if(selectedTemplateId === -1)
    {
      newDoc.templateName = 'Template Matching';
    }
    else {
      let template = this.state.templates.find(t => t.templateId === selectedTemplateId);
      console.log(template);
      if(template !== undefined)
      {
        newDoc.templateName = template.name;
      }
      console.log(newDoc);
    }
    this.setState({newDocs: newDocs});
    console.log(newDocs);

  }

  render() {
    return (
      <div className='h-100 d-flex flex-column flex-fill'>
        <div
          className='d-flex justify-content-end'
          style={{ background: '#EFEFEF', borderBottom: 'solid 1px #BBB' }}
        >
          <div>
            <button
              className='btn btn-secondary mt-1 mb-1 me-3'
              onClick={this.loadDocuments}
            >
              <FontAwesomeIcon icon={solid('undo')} /> Reset
            </button>{' '}
          </div>
          <div>
            <button
              className='btn btn-success mt-1 mb-1 me-3'
              onClick={this.toggleIRPreview}
            >
              <FontAwesomeIcon icon={solid('save')} /> Save
            </button>{' '}
          </div>
        </div>
        <div id='imageReviewWorkArea' className='d-flex flex-row' style={{ overflowX: 'auto', width: this.state.appWidth, height: '100%' }}>
          {this.state.originals.map((doc) => (
            <div
              key={doc.docId}
              onDrop={(e) => this.dropInExisting(e, doc)}
              className={`${
                doc.pages.length === 0
                  ? 'imageReview-deleted'
                  : 'imageReview-existing'
              }  coolScroll3 align-self-start`}
              style={{ minWidth: '170px', maxHeight: `${this.state.appHeight}px` }}
            >
              <div className='imageReview-documentTitle'>
                Document: {doc.docId}
              </div>
              {doc.pages.length > 1 && (
                <button
                  className='btn btn-sm ms-2'
                  onClick={() => this.splitAll(doc)}
                >
                  <FontAwesomeIcon icon={regular('clone')} />{' '}Split All
                </button>
              )}
              {doc.pages.length === 0 && (
                <div
                  className='p-3'
                  onDragOver={(e) => this.onDeleteDragOver(e, doc)}
                >
                  To be deleted
                </div>
              )}
              <div className='d-flex flex-column'>
                {doc.pages.map((page) => (
                  <div
                    onDragOver={(e) => this.onPageDragOver(e, page)}
                    id={`pageContainer_${page.pageId}`}
                    key={page.pageRef}
                    className='imageReview-imageContainer'
                  >
                    <div>{page.pageRef}</div>
                    <img
                      className={`imageReview-image ${
                        page.rotation === 90 ? 'imageReview-rotate90' : ''
                      } ${
                        page.rotation === 180 ? 'imageReview-rotate180' : ''
                      } ${
                        page.rotation === 270 ? 'imageReview-rotate270' : ''
                      }`}
                      alt=''
                      onDragStart={() => this.onDragStart(page)}
                      src={page.base64}
                      onClick={() => this.toggleLargeImage(page)}
                    />
                    <div></div>
                    <div>
                      <span
                        onClick={() => this.rotatePage(page, doc)}
                        style={{ cursor: 'pointer' }}
                      >
                        <FontAwesomeIcon icon={solid('redo')} />{' '}
                      </span>{' '}
                      <span
                        style={{
                          marginLeft: '8px',
                          color: '#CA1414',
                          cursor: 'pointer',
                        }}
                        onClick={() => this.deletePage(page, doc)}
                      >
                        <FontAwesomeIcon icon={solid('trash')} />
                      </span>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ))}

          <div
            className={`${
              this.state.newDocHover
                ? 'imageReview-createDoc-hover'
                : 'imageReview-createDoc'
            } d-flex align-items-center`}
            onDrop={this.onNewDrop}
            onDragOver={this.onDragOver}
            onDragEnter={this.onNewDragEnter}
            onDragLeave={this.onNewDragLeave}
          >
            <FontAwesomeIcon
              style={{ marginLeft: '14px' }}
              icon={solid('plus')}
            />
          </div>
          {this.state.newDocs.map((doc) => (
            <div
              key={doc.docId}
              onDrop={(e) => this.dropInExisting(e, doc)}
              className='imageReview-new coolScroll3 align-self-start'
              style={{ minWidth: '180px', maxHeight: `${this.state.appHeight}px` }}
            >
              <div className='imageReview-documentTitle'>New Document</div>
              <div><select className='form-select form-select-sm ms-1 me-1' style={{maxWidth:'150px'}} value={doc.templateId} onChange={(e) => this.setTemplate(e, doc)} >
                <option value={0}>No Template</option>
                {this.state.templates.map((temp) =>
                <option key={temp.templateId} value={temp.templateId}>{temp.name}</option>
                )}
                 <option value={-1}>Smart Template Matching</option>

                </select></div>

              <div className='d-flex flex-column'>
                {doc.pages.map((page) => (
                  <div
                    onDragOver={(e) => this.onPageDragOver(e, page)}
                    id={`pageContainer_${page.pageId}`}
                    key={page.pageRef}
                    className='imageReview-imageContainer'
                  >
                    {this.state.dropTarget === page.pageRef + '-t' && (
                      <div className='imageReview-dropbox'></div>
                    )}
                    <div>{page.pageRef}</div>
                    <img
                      className={`imageReview-image ${
                        page.rotation === 90 ? 'imageReview-rotate90' : ''
                      } ${
                        page.rotation === 180 ? 'imageReview-rotate180' : ''
                      } ${
                        page.rotation === 270 ? 'imageReview-rotate270' : ''
                      }`}
                      alt=''
                      onDragStart={() => this.onDragStart(page)}
                      src={page.base64}
                      onClick={() => this.toggleLargeImage(page)}
                    />

                    <div>
                      <FontAwesomeIcon
                        icon={solid('redo')}
                        onClick={() => this.rotatePage(page, doc)}
                        style={{ cursor: 'pointer' }}
                      />{' '}
                      <span
                        style={{
                          marginLeft: '8px',
                          color: '#CA1414',
                          cursor: 'pointer',
                        }}
                        onClick={() => this.deletePage(page, doc)}
                      >
                        <FontAwesomeIcon icon={solid('trash')} />
                      </span>
                    </div>
                    {this.state.dropTarget === page.pageRef + '-b' && (
                      <div className='imageReview-dropbox'></div>
                    )}
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
        {this.state.showIRPreview && (
          <div className='td-dialogue-container'>
            <div className='td-dialogue card' style={{ width: '800px' }}>
              <div className='card-header'>
                Image Review - Preview:
                <button
                  type='button'
                  className='btn-close'
                  onClick={this.toggleIRPreview}
                />
              </div>
              <div
                className='card-body'
                style={{ height: '450px', overflow: 'auto' }}
              >
                {this.state.workStatus === 'ready' && (
                  <table className='table'>
                    <thead>
                      <tr>
                        <th scope='col'>Doc Id</th>
                        <th scope='col'>Action</th>
                        <th scope='col'>Pages</th>
                        <th scope='col'>Template</th>
                        <th scope='col'>Step</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.originals.map((doc) => (
                        <tr key={doc.docId}>
                          <th scope='row'>{doc.docId}</th>
                          <td>
                            {doc.pages.length === 0 && (
                              <span style={{ color: '#CA1414' }}>Delete</span>
                            )}
                            {doc.pages.length > 0 && (
                              <span style={{ color: '#1553B2' }}>Update</span>
                            )}
                          </td>
                          <td>{doc.pages.length}</td>
                          <td>{doc.templateName}</td>
                          <td></td>
                        </tr>
                      ))}

                      {this.state.newDocs.map((doc) => (
                        <tr key={doc.docId}>
                          <th scope='row'>--</th>
                          <td>
                            <span style={{ color: '#28B215' }}>New</span>
                          </td>
                          <td>{doc.pages.length}</td>
                          <td>{doc.templateName}</td>
                          <td>
                            <span
                              style={{ fontStyle: 'italic', color: '#28B215' }}
                            >
                              Start
                            </span>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                )}
                {this.state.workStatus === 'working' && (
                  <div
                    className='h-100 d-flex flex-column align-items-center justify-content-center'
                    style={{ MinHeight: '160px' }}
                  >
                    <FontAwesomeIcon icon={solid('spinner')} size='2x' spin />
                    <h4>Processing...</h4>
                  </div>
                )}
                {this.state.workStatus === 'done' && (
                  <table className='table'>
                    <thead>
                      <tr>
                        <th scope='col'>Doc Id</th>
                        <th scope='col'>Action</th>
                        <th scope='col'>Status</th>
                        <th scope='col'>FormType</th>
                        <th scope='col'>Template</th>
                        <th scope='col'>Step</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.results.documents.map((doc) => (
                        <tr key={doc.documentId}>
                          {doc.documentId > 0 &&
                            doc.processAction !== 'Delete' && (
                              <th scope='row'>
                                <Link to={'/Document/' + doc.documentId}>
                                  <span>{doc.documentId}</span>
                                </Link>
                              </th>
                            )}
                          {doc.documentId === 0 && <th scope='row'>--</th>}
                          {doc.processAction === 'Delete' && (
                            <th scope='row'>{doc.documentId}</th>
                          )}
                          <td>{doc.processAction}</td>
                          <td>{doc.processStatus}</td>
                          <td>{doc.formType}</td>
                          <td>{doc.template}</td>
                          <td>{doc.step}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                )}
                {this.state.workStatus === 'ready' && (
                  <button
                    className='btn btn-primary'
                    onClick={this.submitUpdate}
                  >
                    Submit
                  </button>
                )}
                {this.state.workStatus === 'done' && (
                  <Link to='/ImageReview'>
                    <button className='btn btn-primary'>Close</button>
                  </Link>
                )}
              </div>
            </div>
          </div>
        )}
        {this.state.showLargeImage && (
          <div className='td-dialogue-container'>
            <div className='td-dialogue card' style={{ width: '800px' }}>
              <div className='card-header'>
                Document: {this.state.imagePreviewData.docId} Page:{' '}
                {this.state.imagePreviewData.pageId}
                <button
                  type='button'
                  className='btn-close'
                  onClick={this.toggleLargeImage}
                />
              </div>
              <div
                className='card-body'
                style={{
                  height: `${window.innerHeight - 100}px`,
                  overflow: 'auto',
                  backgroundColor: '#777',
                }}
              >
                <img alt='' src={this.state.imagePreviewData.base} />
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default ImageReviewEdit;
