import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import FiltersSidebar from './FiltersSidebar';

class FiltersHome extends React.Component {
  constructor() {
    // state
    super();
    this.state = {};
  }

  componentDidMount() {}
  componentWillUnmount() {}
  componentDidUpdate() {}

  render() {
    return (
      <div className='sidebarLayout d-flex flex-row flex-fill'>
        <FiltersSidebar LoadStepClick={this.LoadStep} LoadBPClick={this.LoadBP} />
        <div className='flex-fill'>
          <div className='h-100 d-flex flex-column align-items-center justify-content-center'>
            <FontAwesomeIcon icon={solid('filter')} size='6x' />
            <h2
              style={{
                fontWeight: 'bolder',
                fontStyle: 'italic',
                margin: '16px',
              }}
            >
              Filters
            </h2>
          </div>
        </div>
      </div>
    );
  }
}

export default FiltersHome;
