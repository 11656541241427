import { Component } from 'react';
import { Navbar, Nav, NavDropdown, Form } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid, regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import tdLogo from '../images/tdLogo2.png';
import tdLogo2 from '../images/TD Logo 2 Color.png';
import aiIcon from '../images/TD AI Icon 2 Color.png';
//import  HomeView  from './Home';
import { Logout, CanUserWebUpload } from '../actions/LoginActions';
import { QuickSearch } from '../actions/SearchActions';
import LoginStore from '../stores/LoginStore';
import SearchStore from '../stores/SearchStore';
import actionTypes from '../actions/ActionTypes';
import { RequestNav } from '../actions/NavActions';

class AppHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userName: '',
      canUserWebUpload: false,
      quickSearchText: '',
      gotoDocId: 0,
      adParams: '',
      isQuickSearching: false,
      lastSearch: '',
    };

    this.onLoginUpdate = this.onLoginUpdate.bind(this);
    this.updateUserInfo = this.updateUserInfo.bind(this);
    this.editQuickSearch = this.editQuickSearch.bind(this);
    this.onQSearchKeyDown = this.onQSearchKeyDown.bind(this);
    this.onSearchUpdate = this.onSearchUpdate.bind(this);
    this.navToActiveDocuments = this.navToActiveDocuments.bind(this);
    this.navToInbox = this.navToInbox.bind(this);
    this.navToSearch = this.navToSearch.bind(this);
    this.navToWebUpload = this.navToWebUpload.bind(this);
  }

  componentDidMount() {
    LoginStore.addChangeListener(this.onLoginUpdate);
    SearchStore.addChangeListener(this.onSearchUpdate);
    CanUserWebUpload();
    this.updateUserInfo();
  }

  updateUserInfo() {
    let user = LoginStore.getUser();
    if (user !== undefined && user.userName !== undefined) {
      this.setState({ userName: user.userName });
    }
  }

  componentWillUnmount() {
    LoginStore.removeChangeListener(this.onLoginUpdate);
    SearchStore.removeChangeListener(this.onSearchUpdate);
  }

  onLoginUpdate() {
    let lastAction = LoginStore.getLastAction();
    if (lastAction === 'Login') {
      this.updateUserInfo();
    }
    if (lastAction === actionTypes.CanUserWebUpload) {
      let canWebUpload = LoginStore.getCanWebUpload();
      this.setState({ canUserWebUpload: canWebUpload });
    }
  }

  onSearchUpdate() {
    let lastAction = SearchStore.getLastAction();
    if (lastAction === actionTypes.SearchQuickSearch) {
      console.log('Quick search: appHeader');
      this.setState({isQuickSearching: false});
      console.log('false');
      let docCount = SearchStore.getDocCount();
      if (docCount > 0) {
        this.setState({quickSearchText: ''});
      }
      if(docCount == 1)
      {
        let docIds = SearchStore.getDocList();
        this.setState({gotoDocId: docIds[0]});

      }

    }
  }

  navToWebUpload() {
    RequestNav('WebUpload', 'webUpLink');
  }

  editQuickSearch(e) {
    this.setState({ quickSearchText: e.target.value });
  }

  onQSearchKeyDown(e) {
    if (e.code === 'Enter' || e.code === 'NumpadEnter') {
      e.preventDefault();
      if (this.state.quickSearchText.length > 0) {
        let srch = this.state.quickSearchText;
        this.setState({isQuickSearching: true, lastSearch: srch});
        QuickSearch(this.state.quickSearchText);

      }
    }
  }

  LogoutTD() {
    Logout();
  }

  navToActiveDocuments(e) {
    let defaultPages = localStorage.getItem('DefaultParams');
    if (defaultPages === null) {
      RequestNav('ActiveDocuments', 'acdLink');
      // this.setState({ adParams: '' }, () => {
      //   let link = document.getElementById('acdLink');
      //   link.click();
      // });
    } else {
      defaultPages = defaultPages = JSON.parse(
        localStorage.getItem('DefaultParams')
      );
      let adP = '';
      if (
        defaultPages.ActiveDocuments !== undefined &&
        defaultPages.ActiveDocuments.BP !== null &&
        defaultPages.ActiveDocuments.Step === null
      ) {
        adP = `?BP=${defaultPages.ActiveDocuments.BP}`;
      }
      if (
        defaultPages.ActiveDocuments !== undefined &&
        defaultPages.ActiveDocuments.BP !== null &&
        defaultPages.ActiveDocuments.Step !== null
      ) {
        adP = `?Step=${defaultPages.ActiveDocuments.Step}&BP=${defaultPages.ActiveDocuments.BP}`;
      }
      if (e.ctrlKey) {
        window.open('/ActiveDocuments' + adP, '_blank');
      } else {
        let path = '/ActiveDocuments' + adP;

        // use NavAction

        this.setState({ adParams: adP }, () => {
          RequestNav('ActiveDocuments', 'acdLink');
        });
        //   let link = document.getElementById('acdLink');
        //   link.click();
        // });
      }
    }
  }

  navToInbox(e) {
    console.log('navToInbox');
    let defaultPages = localStorage.getItem('DefaultParams');
    if (defaultPages === null) {
      RequestNav('Inbox', 'inboxLink');
      // this.setState({ adParams: '' }, () => {
      //   // let link = document.getElementById('inboxLink');
      //   // link.click();

      // });
    } else {
      defaultPages = defaultPages = JSON.parse(
        localStorage.getItem('DefaultParams')
      );
      let adP = '';
      // if (
      //   defaultPages.Inbox !== undefined &&
      //   defaultPages.Inbox.BP !== null &&
      //   (defaultPages.Inbox.Step === null || defaultPages.Inbox.Step === '0')
      // ) {
      //   adP = `?BP=${defaultPages.Inbox.BP}`;
      // }
      // if (
      //   defaultPages.Inbox !== undefined &&
      //   defaultPages.Inbox.BP !== null &&
      //   defaultPages.Inbox.Step !== null &&
      //   defaultPages.Inbox.Step !== '0'
      // ) {
      //   adP = `?Step=${defaultPages.Inbox.Step}&BP=${defaultPages.Inbox.BP}`;
      // }
      if (e.ctrlKey) {
        window.open('/Inbox' + adP, '_blank');
      } else {
        let path = '/Inbox' + adP;

        this.setState({ adParams: adP }, () => {
          RequestNav('Inbox', 'inboxLink');
          //   let link = document.getElementById('inboxLink');
          //   link.click();
        });
      }
    }
  }

  navToSearch(e) {
    console.log('navToSearch');
    let defaultPages = localStorage.getItem('DefaultParams');
    if (defaultPages === null) {
      RequestNav('Search', 'searchLink');
      // this.setState({ adParams: '' }, () => {
      //   // let link = document.getElementById('inboxLink');
      //   // link.click();

      // });
    } else {
      defaultPages = defaultPages = JSON.parse(
        localStorage.getItem('DefaultParams')
      );
      let adP = '';

      if (e.ctrlKey) {
        window.open('/Search' + adP, '_blank');
      } else {
        let path = '/Search' + adP;

        this.setState({ adParams: adP }, () => {
          RequestNav('Search', 'searchLink');
          //   let link = document.getElementById('inboxLink');
          //   link.click();
        });
      }
    }
  }

  render() {
    return (
      <Navbar collapseOnSelect expand='lg' variant='light' id='tdAppHeader'>

          <Navbar.Brand>
          <div className='td-navButton Light'>
            <img
              alt=''
              src={tdLogo2}

              height='30px'

              className='d-inline-block align-top'
              onClick={this.props.onToggle}
              style={{ cursor: 'pointer', maxHeight:'40px' }}
            />

              <FontAwesomeIcon className='nav-icon'
                icon={solid('caret-down')}
                onClick={this.props.onToggle}
              />

          </div>
        </Navbar.Brand>

        <Navbar.Toggle aria-controls='responsive-navbar-nav' />
        <Navbar.Collapse id='responsive-navbar-nav'>
          <Nav className='me-auto'>
            {/*<Nav.Link><FontAwesomeIcon icon={solid('cogs')} />{' '}System Manager</Nav.Link>*/}
          </Nav>
          <Nav>
          <Link
              id='searchLink'

              to={`/Search`}
              style={{ display: 'none' }}
            >
              Search
            </Link>
            <span
              className='nav-link nav-icon'
              style={{ cursor: 'pointer', marginLeft:'0px' }}
              title='Advanced Search'
              onClick={(e) => this.navToSearch(e)}
            >
              <FontAwesomeIcon icon={solid('search')} />
            </span>
            <Form className='d-flex td-search-bar'>
              <div className='td-qsearch-container'>
                <input
                  type='search'
                  placeholder='Quick Search'
                  id='tdqSearchTxt'
                  className='td-search-input'
                  aria-label='Quick Search'
                  size='sm'
                  value={this.state.quickSearchText}
                  onChange={this.editQuickSearch}
                  onKeyDown={this.onQSearchKeyDown}
                  autoComplete='off'
                />
                <Link
                  id='searchDocGoto'
                  to={'/Document/' + this.state.gotoDocId}
                  style={{ display: 'none' }}
                >
                  HiddenLinkp
                </Link>
                {this.state.isQuickSearching &&
                <FontAwesomeIcon style={{position: 'absolute', right: '12px', top: '12px', color: '#111'}} icon={solid('spinner')} spin />
                }
              </div>

              {/*<Button size="sm" variant="outline-secondary"><FontAwesomeIcon icon={solid('caret-down')} /></Button>*/}
            </Form>
            {/*<Nav.Link><FontAwesomeIcon icon={solid('inbox')} />{' '} Inbox</Nav.Link>
                            <Nav.Link><FontAwesomeIcon icon={solid('cubes')} />{' '} Active Documents</Nav.Link>*/}

            {/* <Link className='nav-link' to='/Inbox'>
              <FontAwesomeIcon icon={solid('inbox')} /> Inbox
            </Link> */}



            <Link
              id='inboxLink'
              className='nav-link'
              to={`/Inbox${this.state.adParams}`}
              style={{ display: 'none' }}
            >
              Inbox
            </Link>
            <span
              className='nav-link'
              style={{ cursor: 'pointer' }}
              onClick={(e) => this.navToInbox(e)}
            >
              <FontAwesomeIcon icon={solid('inbox')} /> Inbox
            </span>

            <Link
              id='acdLink'
              className='nav-link'
              to={`/ActiveDocuments${this.state.adParams}`}
              style={{ display: 'none' }}
            >
              AD
            </Link>

            <Link
              id='advancedSearch'
              className='nav-link'
              to={`/Search?Value=${this.state.lastSearch}`}
              style={{ display: 'none' }}
            ></Link>
            <span
              className='nav-link'
              style={{ cursor: 'pointer' }}
              onClick={(e) => this.navToActiveDocuments(e)}
            >
              <FontAwesomeIcon icon={solid('cubes')} /> Active Documents
            </span>
            <Link
              id='webUpLink'
              className='nav-link'
              to={`/WebUpload`}
              style={{ display: 'none' }}
            ></Link>
            {this.state.canUserWebUpload && (
              <span
                className='nav-link'
                style={{ cursor: 'pointer' }}
                onClick={(e) => this.navToWebUpload(e)}
              >
                <FontAwesomeIcon icon={solid('upload')} /> Web Upload
              </span>
            )}

            <NavDropdown
              title={
                <span>
                  <FontAwesomeIcon icon={solid('question')} /> Help
                </span>
              }
              align='end'
              id='helpMenu'
            >
              <div className='dropdown-item'>
                <a
                  className='app-link'
                  href='https://trindocs.atlassian.net/wiki/spaces/TDSupport/pages/730759641/TrinDocs+University'
                  target='_blank'
                >
                  <FontAwesomeIcon icon={solid('film')} /> TrinDocs University
                  YouTube Channel
                </a>
              </div>

              <NavDropdown.Divider />

              <div className='dropdown-item'>
                <a
                  className='app-link'
                  href='https://trindocs.atlassian.net/wiki/spaces/TDSupport/pages/909213697/TrinDocs+6+How-To+and+Support+Articles'
                  target='_blank'
                >
                  <FontAwesomeIcon icon={solid('book')} /> Training
                  documentation
                </a>
              </div>

              <NavDropdown.Divider />

              <div className='dropdown-item'>
                <a
                  className='app-link'
                  href='https://trindocs.atlassian.net/wiki/spaces/TDSupport/pages/798785537/TrinDocs+Webinars'
                  target='_blank'
                >
                  <FontAwesomeIcon icon={solid('video')} /> Webinars
                </a>
              </div>

              <NavDropdown.Divider />

              <div className='dropdown-item'>
                <a
                  className='app-link'
                  href='https://trindocs.atlassian.net/wiki/spaces/TDSupport/overview'
                  target='_blank'
                >
                  <FontAwesomeIcon icon={solid('info-circle')} /> Support Policy
                </a>
              </div>

              <NavDropdown.Divider />

              <div className='dropdown-item'>
                <a
                  className='app-link'
                  href='https://trindocs.atlassian.net/wiki/spaces/TDSupport/pages/753664197/TrinDocs+Newsletters'
                  target='_blank'
                >
                  <FontAwesomeIcon icon={solid('calendar')} /> Monthly
                  Newsletters
                </a>
              </div>

              <NavDropdown.Divider />

              <div className='dropdown-item'>
                <FontAwesomeIcon icon={solid('headset')} /> Contact Us:{' '}
                <a href='mailto: support@trindocs.com'>support@trindocs.com</a>
              </div>
            </NavDropdown>

            <NavDropdown
              title={
                <span>
                  <FontAwesomeIcon icon={solid('user')} /> {this.state.userName}
                </span>
              }
              align='end'
              id='userMenu'
            >
              {/*<NavDropdown.Item href='/UserProfile'>*/}
              <Link className='dropdown-item app-link' to='/UserProfile'>
                <FontAwesomeIcon icon={solid('user')} /> User Profile
              </Link>
              {/*</NavDropdown.Item>*/}
              <NavDropdown.Divider />
              <Link className='dropdown-item app-link' to='/DocUploadHist'>
                <FontAwesomeIcon icon={solid('table')} /> Document Upload
                History
              </Link>

              <NavDropdown.Divider />
              <NavDropdown.Item onClick={this.props.toggleAboutTrinDocs}>
                <FontAwesomeIcon icon={solid('ellipsis')} /> About TrinDocs
              </NavDropdown.Item>

              <NavDropdown.Divider />
              <NavDropdown.Item onClick={this.LogoutTD}>
                <FontAwesomeIcon icon={solid('sign-out-alt')} /> Logout
              </NavDropdown.Item>
            </NavDropdown>
          </Nav>
          <Link id='logOutLink' to={'/'} style={{ display: 'none' }}>
            HiddenLink
          </Link>
        </Navbar.Collapse>
      </Navbar>
    );
  }
}

export default AppHeader;
