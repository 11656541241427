import dispatcher from '../Dispatcher';
import actionTypes from './ActionTypes';
import axios from 'axios';
import * as Globals from '../Globals';
import { Logout } from './LoginActions';
import { PostAlert } from './AlertActions';

export function GetUserList() {
  axios
    .get(`${Globals.baseUrl}/Users/GetUsers`, {
      headers: {
        'content-type': 'application/json; charset=utf-8',
        Authorization: `Bearer ${Globals.getJwtToken()}`,
      },
    })
    .then((res) => {
      if (res.status === 200) {
        dispatcher.dispatch({
          actionType: actionTypes.UsersGetUsers,
          Result: res.data,
        });
      } else if (res.status === 400 || res.status === 401) {
        Logout();
      }
    })
    .catch((res) => {
      let result = {
        updateStatus: 'Error',
        errorMessage: '401: Bad request',
      };
      dispatcher.dispatch({
        actionType: actionTypes.UsersGetUsers,
        Result: result,
      });
    });
}

export function GetAllUserList() {
  axios
    .get(`${Globals.baseUrl}/Users/GetAllUsers`, {
      headers: {
        'content-type': 'application/json; charset=utf-8',
        Authorization: `Bearer ${Globals.getJwtToken()}`,
      },
    })
    .then((res) => {
      if (res.status === 200) {
        dispatcher.dispatch({
          actionType: actionTypes.UsersGetUsers,
          Result: res.data,
        });
      } else if (res.status === 400 || res.status === 401) {
        Logout();
      }
    })
    .catch((res) => {});
}

export function GetUser(userId) {
  axios
    .get(`${Globals.baseUrl}/Users/GetUser/${userId}`, {
      headers: {
        'content-type': 'application/json; charset=utf-8',
        Authorization: `Bearer ${Globals.getJwtToken()}`,
      },
    })
    .then((res) => {
      if (res.status === 200) {
        dispatcher.dispatch({
          actionType: actionTypes.UsersGetOne,
          Result: res.data,
        });
      } else if (res.status === 400 || res.status === 401) {
        Logout();
      }
    })
    .catch((res) => {
      let result = {
        updateStatus: 'Error',
        errorMessage: '401: Bad request',
      };
      dispatcher.dispatch({
        actionType: actionTypes.UsersGetOne,
        Result: result,
      });
    });
}

export function ChangePassword(userId, password) {
  axios
    .post(
      `${Globals.baseUrl}/Users/ChangePassword`,
      { Password: password, UserId: userId },
      {
        headers: {
          'content-type': 'application/json; charset=utf-8',
          Authorization: `Bearer ${Globals.getJwtToken()}`,
        },
      }
    )
    .then((res) => {
      if (res.status === 200) {
        console.log(res);
        dispatcher.dispatch({
          actionType: actionTypes.UserChangePassword,
          Result: res.data,
        });
        PostAlert('success', 'Password changed');
      } else if (res.status === 400 || res.status === 401) {
        Logout();
      }
    })
    .catch((res) => {});
}

export function SaveUser(userId, userInfo) {
  axios
    .post(
      `${Globals.baseUrl}/Users/UpdateUser`,
      { UserId: userId, UserInfo: userInfo },
      {
        headers: {
          'content-type': 'application/json; charset=utf-8',
          Authorization: `Bearer ${Globals.getJwtToken()}`,
        },
      }
    )
    .then((res) => {
      if (res.status === 200) {
        dispatcher.dispatch({
          actionType: actionTypes.UsersUpdateUser,
          Result: res.data,
        });
        if (res.data === 'Success') {
          PostAlert('success', 'User data updated');
        }
        if (res.data === 'Error') {
          PostAlert('error', 'Unable to update user data');
          PostAlert('error', res.data.updateMessage);
        }
      } else if (res.status === 400 || res.status === 401) {
        Logout();
      }
    })
    .catch((res) => {});
}

export function UpdateSetting(setting, value) {
  //console.log('userSettings1');
  axios
    .post(
      `${Globals.baseUrl}/Users/UpdateSetting`,
      { settingName: setting, settingValue: value },
      {
        headers: {
          'content-type': 'application/json; charset=utf-8',
          Authorization: `Bearer ${Globals.getJwtToken()}`,
        },
      }
    )
    .then((res) => {
      if (res.status === 200) {
        dispatcher.dispatch({
          actionType: actionTypes.UserChangeSetting,
          Result: res.data,
        });
      } else if (res.status === 400 || res.status === 401) {
        Logout();
      }
    })
    .catch((res) => {});
}
