import * as React from 'react';
import ArchiveSidebar from './ArchiveSidebar';

import { createBrowserHistory } from 'history';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';

import {
  LoadArchive,
  GetArchiveList,
  CreateArchive,
  UpdateArchive,
  DeleteArchive,
} from '../../../../actions/ArchiveActions';
import ArchiveStore from '../../../../stores/ArchiveStore';
import { Link } from 'react-router-dom';

const customHistory = createBrowserHistory();

class ArchiveView extends React.Component {
  constructor(props) {
    super(props);
    // state
    this.state = {
      currentArchive: {
        archvieId: 0,
        rootPath: '',
        isCurrent: true,
      },
      archiveId: 0,
      archivePath: '',
      isCurrent: false,
      isDirty: false,
      errorOne: '',
      isSaving: false,
      deleteStatus: 0,
    };
    this.updateArchive = this.updateArchive.bind(this);
    this.onStoreUpdate = this.onStoreUpdate.bind(this);
    this.isCurrentChange = this.isCurrentChange.bind(this);
    this.handleArchivePathChange = this.handleArchivePathChange.bind(this);
    this.handleSaveOneClick = this.handleSaveOneClick.bind(this);

    this.startDelete = this.startDelete.bind(this);
    this.deleteArchive = this.deleteArchive.bind(this);
    this.cancelDelete = this.cancelDelete.bind(this);
  }

  componentDidMount() {
    ArchiveStore.addChangeListener(this.onStoreUpdate);
    this.updateArchive();
  }

  componentWillUnmount() {
    ArchiveStore.removeChangeListener(this.onStoreUpdate);
  }

  componentDidUpdate() {
    this.updateArchive();
  }

  updateArchive() {
    let loc = window.location.pathname;
    let pathParts = loc.split('/');
    let archId = parseInt(pathParts[pathParts.length - 1], 10);
    if (
      archId !== 0 &&
      (this.state.currentArchive === undefined ||
        this.state.currentArchive.archiveId !== archId)
    ) {
      LoadArchive(archId);
    } else if (
      archId === 0 &&
      this.state.currentArchive !== undefined &&
      this.state.currentArchive.archiveId !== 0
    ) {
      let currentArch = {};
      currentArch.rootPath = '';
      currentArch.archiveId = 0;
      currentArch.isCurrent = true;
      this.setState({
        currentArchive: currentArch,
        isCurrent: true,
        archivePath: '',
      });
    }
  }

  isCurrentChange(e) {
    this.setState({ isCurrent: e.target.checked, isDirty: true });
  }

  onStoreUpdate() {
    let lastAction = ArchiveStore.lastChange();

    if (lastAction === 'ArchiveLoadOne') {
      let arch = ArchiveStore.getCurrentArchive();

      this.setState({
        currentArchive: arch,
        archivePath: arch.rootPath,
        isCurrent: arch.isCurrent,
        isDirty: false,

        isSaving: false,
      });
    }
    if (lastAction === 'ArchiveUpdateOne') {
      let lastError = ArchiveStore.getLastError();
      if (lastError === '' || lastError === undefined) {
        let newDir = ArchiveStore.getCurrentArchive();
        if (newDir.uploadId !== this.state.currentArchive.archiveId) {
          customHistory.push(`/Admin/ArchiveView/${newDir.archiveId}`);
        }

        this.setState({
          errorOne: '',
          currentArchive: newDir,
          uploadPath: newDir.uploadPath,
          isCurrent: newDir.isCurrent,
          ftps: newDir.bPs,
          routingType: newDir.intakeRoutingType,
          selectedIs: newDir.intakeStrategyId,
          isSecOneSaving: false,
          isSecTwoDirty: false,
          isSecOneDirty: false,
          isSecTwoSaving: false,
        });
        GetArchiveList();
      } else {
        this.setState({ isSecOneSaving: false, errorOne: lastError });
      }
    }
    if (lastAction === 'ArchiveDelete') {
      let lastError = ArchiveStore.getLastError();
      if (lastError === '') {
        let link = document.getElementById('archiveHome');
        link.click();
      } else {
        this.setState({ isSecOneSaving: false, errorOne: lastError });
      }
    }
  }

  handleArchivePathChange(e) {
    this.setState({ archivePath: e.target.value, isDirty: true });
  }

  handleSaveOneClick(e) {
    this.setState({ isSecOneSaving: true });
    if (this.state.currentArchive.archiveId === 0) {
      CreateArchive(this.state.archivePath, this.state.isCurrent);
    } else {
      UpdateArchive(
        this.state.currentArchive.archiveId,
        this.state.archivePath,
        this.state.isCurrent
      );
    }
  }

  startDelete() {
    this.setState({ deleteStatus: 1 });
  }

  deleteArchive() {
    DeleteArchive(this.state.currentArchive.archiveId);
    this.setState({ deleteStatus: 0 });
  }

  cancelDelete() {
    this.setState({ deleteStatus: 0 });
  }

  render() {
    return (
      <div className='container-fluid' style={{ height: '100%' }}>
        <div className='row flex-nowrap' style={{ height: '100%' }}>
          <ArchiveSidebar />
          <Link
              id='archiveHome'
              className='nav-link'
              to={`/Admin/Archive`}
              style={{ display: 'none' }}
            >
            </Link>
          {this.state.currentArchive !== undefined && (
            <div
              className='flex-fill'
              style={{ backgroundColor: '#efefef', overflow: 'scroll' }}
            >
              <div
                className='card'
                style={{
                  marginTop: '20px',
                  marginLeft: '30px',
                  marginRight: '30px',
                  maxHeight: '620px',
                }}
              >
                <div className='card-header'>
                  <h4>
                    <FontAwesomeIcon icon={solid('archive')} />
                    {'  '}
                    {this.state.currentArchive.rootPath}
                  </h4>
                </div>
                <div className='card-body'>
                  <div className='mb-3'>
                    <label htmlFor='uploadPathTxt' className='form-label'>
                      Archive Path:
                    </label>
                    <input
                      type='text'
                      className='form-control'
                      id='uploadPathTxt'
                      value={this.state.archivePath}
                      onChange={this.handleArchivePathChange}
                    />
                  </div>

                  <div
                    className='form-check form-switch'
                    style={{ marginTop: '10px' }}
                  >
                    <input
                      className='form-check-input'
                      type='checkbox'
                      id='flexSwitchCheckChecked'
                      onChange={this.isCurrentChange}
                      checked={this.state.isCurrent}
                    />
                    <label
                      className='form-check-label'
                      htmlFor='flexSwitchCheckChecked'
                    >
                      {this.state.isCurrent && <span>Current</span>}
                      {!this.state.isCurrent && <span>Not current</span>}
                    </label>
                  </div>
                  <div className='mb-3' style={{ marginTop: '8px' }}>
                    {this.state.isDirty && !this.state.isSaving && (
                      <>
                          <button
                            className='btn btn-primary'
                            onClick={this.handleSaveOneClick}
                          >
                            Save
                          </button>
                            <button
                            className='btn btn-danger'
                            style={{ marginLeft: '6px' }}
                            onClick={this.startDelete}
                          >
                            Delete
                        </button>
                      </>
                    )}
                    {!this.state.isDirty && !this.state.isSaving && (
                      <div>
                        <button className='btn btn-primary disabled'>
                          Save
                        </button>
                        <button
                          className='btn btn-danger'
                          style={{ marginLeft: '6px' }}
                          onClick={this.startDelete}
                        >
                          Delete
                        </button>
                      </div>
                    )}
                    {this.state.isSaving && (
                      <FontAwesomeIcon icon={solid('spinner')} size='2x' spin />
                    )}
                    {this.state.deleteStatus === 1 && (
                      <div
                        className='alert alert-danger'
                        style={{ marginTop: '6px' }}
                        role='alert'
                      >
                        <p>Are you sure you want to delete this archive?</p>

                        <button
                          className='btn btn-danger'
                          onClick={this.deleteArchive}
                        >
                          Yes - Delete
                        </button>
                        <button
                          className='btn btn-secondary'
                          style={{ marginLeft: '6px' }}
                          onClick={this.cancelDelete}
                        >
                          Cancel
                        </button>
                      </div>
                    )}

                    {this.state.errorOne !== '' && (
                      <div
                        className='alert alert-danger'
                        style={{ marginTop: '6px' }}
                        role='alert'
                      >
                        {this.state.errorOne}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default ArchiveView;
