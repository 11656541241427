import { EventEmitter } from 'events';
import Dispatcher from '../Dispatcher';
import actionTypes from '../actions/ActionTypes';

const CHANGE_EVENT = 'intakeSettingsChanges';
let _currentIntakeSettings = {};
let _lastChange = '';
let _lastError = '';

class IntakeSettingsStore extends EventEmitter {
  addChangeListener(callback) {
    this.on(CHANGE_EVENT, callback);
  }

  removeChangeListener(callback) {
    this.removeListener(CHANGE_EVENT, callback);
  }

  emitChange() {
    this.emit(CHANGE_EVENT);
  }

  getIntakeSettings() {
    return _currentIntakeSettings;
  }

  getLastChange() {
    return _lastChange;
  }

  getLastError() {
    return _lastError;
  }
}

const store = new IntakeSettingsStore();

Dispatcher.register((action) => {
  switch (action.actionType) {
    case actionTypes.IntakeGetSettings:
      _currentIntakeSettings = action.Result;
      _lastChange = action.actionType;

      store.emitChange();
      break;
    case actionTypes.IntakeUpdateSettings:
      _currentIntakeSettings = action.Result;
      _lastChange = action.actionType;
      store.emitChange();
      break;
    default:
  }
});

export default store;
