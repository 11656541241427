import * as React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid, regular } from '@fortawesome/fontawesome-svg-core/import.macro';

import FormTypeSidebar from './FormTypeSidebar';
import FormTypeStore from '../../../../stores/FormTypeStore';
import IndexStore from '../../../../stores/IndexStore';
import FieldRelationsStore from '../../../../stores/FieldRelationsStore';
import FieldStore from '../../../../stores/FieldStore';
import {
  LoadFormType,
  UpdateFormType,
  UpdateExtender,
  GetPluginModel,
  GetAllDataSources,
  RemoveExtender,
} from '../../../../actions/FormTypeActions';
import {
  UpdateIndex,
  GetIndexFieldsForFormType,
  ReIndexFormType,
  GetBuildPercentage,
  DeleteIndex,
  CancelReindexing,
  UpdateBuildStatus,
  GetIndexDocCounts,
  ReIndexFormTypeWithParams,
} from '../../../../actions/IndexActions';
import {
  GetFieldRelations,
  UpdateFieldRelation,
  DeleteFieldRelation,
} from '../../../../actions/FieldRelationActions';
import {
  GetAllFieldsForFormType,
  GetAllFormTypes,
  GetFieldsForOtherFormType,
} from '../../../../actions/FieldActions';
import actionTypes from '../../../../actions/ActionTypes';
import { PostAlert } from '../../../../actions/AlertActions';

const windowOffset = 300;
// Define our state
class FormTypeView extends React.Component {
  dragField = {};
  dragTarget = {};
  constructor() {
    super();
    // state

    this.state = {
      currentFormType: {
        formTypeId: 0,
        name: '',
        fieldSets: [],
        tables: [],
        isNew: true,
        indexId: 0,
      },
      currentFieldSet: {
        name: '',
        orderId: 0,
        fields: [],
      },
      editField: {
        name: '',
        systemName: '',
        type: 'Text',
        format: '',
        isCalculated: false,
      },

      editExtender: {
        extenderId: -1,
        type: 1,
        fieldId: -1,
        controlsChildren: false,
        sortAlphabetical: true,
        fieldName: '',
        availableDependencies: [],
        dependencies: [],
      },

      isDirty: false,
      currentView: 'fields',
      addingField: false,
      isSaving: false,
      showFieldEditor: false,
      showExtenderEditor: false,
      newField: {
        fieldId: 0,
        name: '',
        systemName: '',
        type: 'Text',
        isDirty: true,
      },
      editFieldSet: {
        name: '',
        isActive: true,
      },
      allSources: [
        {
          id: 1,
          name: 'TrinDocs',
        },
        {
          id: 2,
          name: 'TWO',
        },
        {
          id: 3,
          name: 'GPACME',
        },
        {
          id: 4,
          name: 'TrinDocs_Companion',
        },
      ],
      showTableEdit: false,
      isRearranging: true,
      showNewIndex: false,
      showDeleteIndex: false,
      showAboutFieldRelations: false,
      defaultIndexName: '',
      currentIndexes: [],
      currentQuery: undefined,
      doesIndexExist: false,
      currentlyIndexing: false,
      currentIndexPercent: 0.0,
      isIndexSectionDirty: false,
      showRemoveExtender: false,
      fieldRelations: [],
      baseFields: [],
      formTypes: [],
      relatedFields: [],
      addingFieldRelation: false,
      selectedBaseField: undefined,
      selectedRelatedFormType: undefined,
      selectedRelatedField: undefined,
      isTwoWayLink: false,
      displayConfirmDeletePopup: false,
      fieldRelationToDelete: undefined,
      deleteTwoWayLink: false,
      startBuildImmediately: false,
      errorMessageForExtenders: '',
      showOptionRemoveExtender: false,
      workHeight: window.innerHeight - windowOffset,
      showReindex: false,
      activeCount: 0,
      completedCount: 0,
      deletedCount: 0,
      reindexActiveDocs: true,
      reindexCompleteDocs: false,
      reindexDeletedDocs: false,
      reindexColumns: true,
      reindexColumnOptions: {
        indexAll: true,
        colOptions: [],
      },
      reindexText: true,
      reindexRelated: false,
      showAboutFieldType: false,
      dropOverId: 0,
      dropUnderId: 0,
      buildStatus: 0
    };

    this.loadFormTypeFromDB = this.loadFormTypeFromDB.bind(this);
    this.onFormTypesChange = this.onFormTypesChange.bind(this);
    this.onFieldRelationsChange = this.onFieldRelationsChange.bind(this);
    this.onFieldsChange = this.onFieldsChange.bind(this);
    this.checkParams = this.checkParams.bind(this);
    //this.onFieldsChange = this.onFieldsChange.bind(this);
    this.editTableValue = this.editTableValue.bind(this);
    this.switchView = this.switchView.bind(this);
    this.onChangeFTName = this.onChangeFTName.bind(this);
    this.addFields = this.addFields.bind(this);
    this.hideaddFields = this.hideaddFields.bind(this);
    this.addFieldRelations = this.addFieldRelations.bind(this);
    this.hideaddFieldRelations = this.hideaddFieldRelations.bind(this);
    this.addFieldClick = this.addFieldClick.bind(this);
    this.onNewFieldKeyDown = this.onNewFieldKeyDown.bind(this);
    this.onSave = this.onSave.bind(this);
    this.addTableClick = this.addTableClick.bind(this);
    this.switchFieldSet = this.switchFieldSet.bind(this);
    this.addNewTableClick = this.addNewTableClick.bind(this);
    this.tableItemClick = this.tableItemClick.bind(this);
    this.cancleTableClick = this.cancleTableClick.bind(this);
    this.updateTableClick = this.updateTableClick.bind(this);
    this.rearrangeTableClick = this.rearrangeTableClick.bind(this);
    this.toggleRearrange = this.toggleRearrange.bind(this);
    this.editField = this.editField.bind(this);
    this.editExtender = this.editExtender.bind(this);
    this.editFieldValue = this.editFieldValue.bind(this);
    this.editCancel = this.editCancel.bind(this);
    this.editUpdate = this.editUpdate.bind(this);
    this.toggleNewIndex = this.toggleNewIndex.bind(this);
    this.toggleDeleteIndex = this.toggleDeleteIndex.bind(this);
    this.editDefaultIndexName = this.editDefaultIndexName.bind(this);
    this.indexFieldChange = this.indexFieldChange.bind(this);
    this.startIndexCreation = this.startIndexCreation.bind(this);
    this.closeExtenderEdit = this.closeExtenderEdit.bind(this);
    this.configureExtender = this.configureExtender.bind(this);
    this.removeExtender = this.removeExtender.bind(this);
    this.addDataSrc = this.addDataSrc.bind(this);
    this.switchDataSource = this.switchDataSource.bind(this);
    this.removeQuery = this.removeQuery.bind(this);
    this.saveExtenderChanges = this.saveExtenderChanges.bind(this);
    this.addExtenderDependency = this.addExtenderDependency.bind(this);
    this.injectDependency = this.injectDependency.bind(this);
    this.onIndexChange = this.onIndexChange.bind(this);
    this.reindexDocuments = this.reindexDocuments.bind(this);
    this.reindexingComplete = this.reindexingComplete.bind(this);
    this.cancelReindexing = this.cancelReindexing.bind(this);
    this.onTimerClick = this.onTimerClick.bind(this);
    this.updateIndex = this.updateIndex.bind(this);
    this.updateIndexAndReindex = this.updateIndexAndReindex.bind(this);
    this.deleteIndex = this.deleteIndex.bind(this);
    this.generatePluginModel = this.generatePluginModel.bind(this);
    this.toggleRemoveExtender = this.toggleRemoveExtender.bind(this);
    this.onConfirmRemoveExtender = this.onConfirmRemoveExtender.bind(this);
    this.selectFormType = this.selectFormType.bind(this);
    this.toggleTwoWayLink = this.toggleTwoWayLink.bind(this);
    this.editSelectedRelatedField = this.editSelectedRelatedField.bind(this);
    this.editSelectedBaseField = this.editSelectedBaseField.bind(this);
    this.addFieldRelationClick = this.addFieldRelationClick.bind(this);
    this.initialDeleteFieldRelation =
      this.initialDeleteFieldRelation.bind(this);
    this.toggleConfirmDelete = this.toggleConfirmDelete.bind(this);
    this.toggleDeleteTwoWayLink = this.toggleDeleteTwoWayLink.bind(this);
    this.toggleAboutFieldRelations = this.toggleAboutFieldRelations.bind(this);
    this.toggleAboutFieldType = this.toggleAboutFieldType.bind(this);
    this.confirmDeleteFieldRelation =
      this.confirmDeleteFieldRelation.bind(this);
    this.deleteField = this.deleteField.bind(this);
    this.newFieldBlur = this.newFieldBlur.bind(this);
    this.removeTableClick = this.removeTableClick.bind(this);
    this.editStartBuildImmediately = this.editStartBuildImmediately.bind(this);
    this.setFieldDragging = this.setFieldDragging.bind(this);
    this.onFieldDragOver = this.onFieldDragOver.bind(this);
    this.onFieldDrop = this.onFieldDrop.bind(this);
    this.toggleSelectAllIndex = this.toggleSelectAllIndex.bind(this);
    this.onWindowResize = this.onWindowResize.bind(this);
    this.RemoveIndexOption = this.RemoveIndexOption.bind(this);
    this.AddIndexOption = this.AddIndexOption.bind(this);
    this.toggleRebuildIndex = this.toggleRebuildIndex.bind(this);
    this.showRebuildIndex = this.showRebuildIndex.bind(this);
    this.changeReindexSettings = this.changeReindexSettings.bind(this);
    this.startIndexBuild = this.startIndexBuild.bind(this);
  }

  componentDidMount() {
    FormTypeStore.addChangeListener(this.onFormTypesChange);
    IndexStore.addChangeListener(this.onIndexChange);
    FieldRelationsStore.addChangeListener(this.onFieldRelationsChange);
    FieldStore.addChangeListener(this.onFieldsChange);
    this.loadFormTypeFromDB();
    this.interval = setInterval(this.onTimerClick, 1500);
    window.onresize = this.onWindowResize;
    document.title = 'TrinDocs - Form Types'
  }

  componentWillUnmount() {
    FormTypeStore.removeChangeListener(this.onFormTypesChange);
    IndexStore.removeChangeListener(this.onIndexChange);
    FieldRelationsStore.removeChangeListener(this.onFieldRelationsChange);
    FieldStore.removeChangeListener(this.onFieldsChange);
  }

  componentDidUpdate() {
    this.checkParams();
  }

  checkParams() {
    let loc = window.location.pathname;
    let pathParts = loc.split('/');
    let formTypeId = parseInt(pathParts[pathParts.length - 1], 10);

    if (formTypeId !== this.state.currentFormType.formTypeId) {
      console.log('checkParams');
      console.log(formTypeId);
      console.log(this.state.currentFormType.formTypeId);
      this.loadFormTypeFromDB();
    }
  }

  loadFormTypeFromDB() {
    //console.log('loadFromDB');
    let loc = window.location.pathname;
    let pathParts = loc.split('/');
    let formTypeId = parseInt(pathParts[pathParts.length - 1], 10);

    // id: 0  - new empty formtype
    if (this.state.currentFormType.formTypeId !== 0 && formTypeId === 0) {
      let newFormType = {
        formTypeId: 0,
        name: '',
        fields: [], // to do,  add default steps
        tables: [],
        isNew: true,
      };
      this.setState({
        showFieldEditor: false,
        showExtenderEditor: false,
      });
      this.setState({ currentFormType: newFormType, isDirty: true });
    } else if (this.state.currentFormType.formTypeId !== formTypeId) {
      //console.log('LoadFormType');
      LoadFormType(formTypeId);

      //GetIndexFieldsForFormType(formTypeId);
      //GetBuildPercentage(formTypeId);
      //GetAllDataSources();
    }
  }

  onWindowResize(e) {
    //setViewWidth
    this.setState({
      workHeight: window.innerHeight - windowOffset,
    });
  }

  onChangeFTName(e) {
    let ft = this.state.currentFormType;
    ft.name = e.target.value;
    this.setState({ currentFormType: ft, isDirty: true });
  }

  onFormTypesChange() {
    let la = FormTypeStore.getLastAction();

    if (la.actionType === 'Update') {
      console.log('update');
      if (la.status === 'Success') {
        let newFormType = FormTypeStore.getCurrentFT();
        let fs = newFormType.fieldSets.find((f) => f.orderId === 0);
        this.setState({
          currentFormType: newFormType,
          currentFieldSet: fs,
          isSaving: false,
          isDirty: false,
          showFieldEditor: false,
          showExtenderEditor: false,
        });
      } else if (la.status === 'Error') {
        this.setState({
          isSaving: false,
          isDirty: false,
          showFieldEditor: false,
          showExtenderEditor: false,
        });
        // on failed update, need to reload the correct data from the db.
        // you could have added/removed fields etc.. and we don't know which of your changes were handled
        let loc = window.location.pathname;
        let pathParts = loc.split('/');
        let formTypeId = parseInt(pathParts[pathParts.length - 1], 10);
        LoadFormType(formTypeId);
        GetIndexFieldsForFormType(formTypeId);
      }
      this.setState({ saveState: 'ready' });
    }
    if (la.actionType === 'FTLoad') {
      let newFormType = FormTypeStore.getCurrentFT();
      let fs = newFormType.fieldSets.find((f) => f.orderId === 0);
      // normalize the field display order

      newFormType.fieldSets.forEach((nfs) => {
        //console.log(nfs);
        let displayOrder = 1;
        nfs.fields.forEach((f) => {
          f.displayOrder = displayOrder;
          displayOrder++;
        });
      });
      this.setState({
        currentFormType: newFormType,
        currentFieldSet: fs,
        isSaving: false,
        isDirty: false,
        showFieldEditor: false,
        showExtenderEditor: false,
      });
      //console.log(newFormType.fieldSets);
      GetIndexFieldsForFormType(newFormType.formTypeId);
      GetBuildPercentage(newFormType.formTypeId);
      GetAllDataSources();
      GetIndexDocCounts(newFormType.formTypeId);
    }
    if (la.actionType === 'ExtenderUpdate') {
      let newFormType = FormTypeStore.getCurrentFT();
      //let fs = newFormType.fieldSets.find((f) => f.orderId === 0);
      this.setState({
        currentFormType: newFormType,
        //currentFieldSet: fs,
        showFieldEditor: false,
        showExtenderEditor: false,
      });
    }
    if (la.actionType === 'RemoveExtender') {
      let currentFieldSet = this.state.currentFieldSet;
      let editExtender = this.state.editExtender;
      currentFieldSet.fields.forEach((f) => {
        if (f.fieldId === editExtender.fieldId) {
          f.hasExtender = false;
        }
      });
      this.setState({
        showExtenderEditor: false,
        showRemoveExtender: false,
        currentFieldSet: currentFieldSet,
      });
    }
    if (la.actionType === actionTypes.FTGetAllDataSource) {
      let allSrc = FormTypeStore.getAllDataSources();
      this.setState({ allSources: allSrc });
    }
  }

  onFieldRelationsChange() {
    let la = FieldRelationsStore.lastChange();

    if (la === actionTypes.GetFieldRelations) {
      let fieldRelations = FieldRelationsStore.getFieldRelations();
      this.setState({ fieldRelations: fieldRelations });
    }

    if (la === actionTypes.UpdateFieldRelation) {
      GetFieldRelations(this.state.currentFormType.formTypeId);
    }

    if (la === actionTypes.DeleteFieldRelation) {
      this.setState({ displayConfirmDeletePopup: false });
      GetFieldRelations(this.state.currentFormType.formTypeId);
    }
  }

  onFieldsChange() {
    let la = FieldStore.getLastAction();

    if (la.actionType === actionTypes.FieldGetAllForFormType) {
      let fields = FieldStore.getAllFieldsForFormType();
      this.setState({ baseFields: fields });
    }

    if (la.actionType === actionTypes.GetFieldsOtherFormType) {
      let fields = FieldStore.getFieldsOtherFT();
      this.setState({ relatedFields: fields });
    }

    if (la.actionType === actionTypes.GetAllFormTypes) {
      let fts = FieldStore.getAllFormTypes();
      let indexedFts = [];

      fts.forEach((ft) => {
        if (ft.indexId !== null)
        {
          indexedFts.push(ft);
        }
      });

      this.setState({ formTypes: indexedFts });
    }
  }

  onIndexChange() {
    let la = IndexStore.getLastAction();

    if (la === actionTypes.IndexUpdate) {
      let currentFT = this.state.currentFormType;
      let indexId = IndexStore.getIndexId();
      currentFT.indexId = indexId;
      this.setState({
        isIndexSectionDirty: false,
        showNewIndex: false,
        doesIndexExist: true,
        currentFormType: currentFT,
      });

      if (this.state.startBuildImmediately) {
        this.setState({ startBuildImmediately: false });
        this.showRebuildIndex();
      }
    }

    if (la === actionTypes.GetIndexFieldsByFormTypeId) {
      let indexFields = IndexStore.getIndexFields();
      if (indexFields.length === 0) {
        this.setState({ currentIndexes: indexFields, doesIndexExist: false });
      } else {
        this.setState({ currentIndexes: indexFields, doesIndexExist: true });
      }
      let cft = this.state.currentFormType;
      let hfs = cft.fieldSets.find((fs) => fs.name === 'Header');
      let ihfs = indexFields.find((fs) => fs.fieldSetName === 'Header');
      if (hfs) {
        hfs.fields.forEach((field) => {
          let ifield = ihfs.fields.find((f) => f.fieldId === field.fieldId);
          if (ifield && ifield.hasBasicIndex) {
            field.hasBasicIndex = true;
          } else {
            field.hasBasicIndex = false;
          }
        });
      }

      let indexedFields = [];
      let header = indexFields.find((fs) => fs.fieldSetName === 'Header');
      let fields = header.fields;
      fields.forEach((field) => {
        if (field.hasBasicIndex){
          indexedFields.push(field);
        }
      });

      this.setState({ currentFormType: cft, baseFields: indexedFields });
    }

    if (la === actionTypes.GetBuildPercentage) {
      let buildPercent = IndexStore.getBuildPercent();
      let buildStatus = IndexStore.getBuildStatus();
      if (buildStatus === 0){
        this.setState({ currentIndexPercent: buildPercent, buildStatus: buildStatus, currentlyIndexing: false });
      }
      else{
        this.setState({ currentIndexPercent: buildPercent, buildStatus: buildStatus, currentlyIndexing: true });
      }

      if (
        buildPercent !== 100 &&
        buildPercent !== 0 &&
        !this.state.currentlyIndexing
      ) {
        this.setState({ currentlyIndexing: true });
      }
    }

    if (la === actionTypes.IndexDelete) {
      let currentFT = this.state.currentFormType;
      currentFT.indexId = 0;
      this.setState({
        doesIndexExist: false,
        currentIndexes: [],
        currentFormType: currentFT,
        defaultIndexName: '',
      });
    }

    if (la === actionTypes.IndexGetDocCounts) {
      let counts = IndexStore.getDocCounts();
      console.log(counts);
      this.setState({ activeCount: counts.activeCount });
      this.setState({ completedCount: counts.completeCount });
      this.setState({ deletedCount: counts.deleteCount });
    }
  }

  switchView(newView) {
    if (newView === 'relations') {
      GetFieldRelations(this.state.currentFormType.formTypeId);
      GetIndexFieldsForFormType(this.state.currentFormType.formTypeId);
      GetAllFormTypes();
    }
    this.setState({ currentView: newView });
  }

  addFields() {
    let nf = this.state.newField;
    nf.name = '';
    nf.type = 'Text';
    nf.systemName = '';
    this.setState({ addingField: true, newField: nf });
  }

  hideaddFields() {
    this.setState({ addingField: false });
  }

  addFieldRelations() {
    this.setState({ addingFieldRelation: true });
  }

  initialDeleteFieldRelation(fieldRelation) {
    this.setState({
      displayConfirmDeletePopup: true,
      fieldRelationToDelete: fieldRelation.id,
    });
  }

  hideaddFieldRelations() {
    this.setState({ addingFieldRelation: false });
  }

  editNewFieldValue(e, property) {
    let field = this.state.newField;
    switch (property) {
      case 'name':
        field.name = e.target.value;
        break;
      case 'type':
        field.type = e.target.value;
        break;
      case 'systemName':
        let sysName = e.target.value;
        if (sysName.length === 1) {
          sysName = sysName.toUpperCase();
        }
        let re = new RegExp('^[A-Z][a-zA-Z0-9]*$');
        if (re.test(sysName) || sysName === '') {
          field.systemName = sysName;
        }
        break;
      default:
        break;
    }
    this.setState({ newField: field });
  }

  camelCase(str) {
    console.log(str);
    let newStr =
    str
      .replace('#', 'Num')
      .replace('*', '')
      .replace('(', '')
      .replace(')', '')
      .replace('-', '_')
      .replace('?', '')
      .replace('%', '')
      .replace('/', '')
      .replace(' ', '')
      .replace(/([^a-zA-Z0-9]+)/g, '');
    let numFirst = /^[0-9]/;
    if(numFirst.test(newStr)) {
      newStr = '_' + newStr;
    }
    console.log(newStr);
      return newStr;

  }

  newFieldBlur() {

    let nf = this.state.newField;
    if (nf.systemName === '') {
      let s = nf.name;
      let s2 = this.camelCase(s);
      nf.systemName = s2;
      this.setState({ newField: nf });
    }
  }

  selectFormType(e, property) {
    GetFieldsForOtherFormType(e.target.value, this.state.selectedBaseField);
    this.setState({ selectedRelatedFormType: e.target.value });
  }

  editSelectedRelatedField(e) {
    this.setState({ selectedRelatedField: e.target.value });
  }

  editSelectedBaseField(e) {
    if (this.state.selectedRelatedFormType !== undefined){
      GetFieldsForOtherFormType(this.state.selectedRelatedFormType, e.target.value);
    }
    this.setState({ selectedBaseField: e.target.value });
  }

  editFieldValue(e, property) {
    let field = this.state.editField;
    switch (property) {
      case 'name':
        field.name = e.target.value;
        break;
      case 'type':
        console.log(e.target.value);
        field.type = e.target.value;
        break;
      case 'systemName':
        let sysName = e.target.value;
        if (sysName.length === 1) {
          sysName = sysName.toUpperCase();
        }
        let re = new RegExp('^[A-Z][a-zA-Z0-9]*$');
        if (re.test(sysName) || sysName === '') {
          field.systemName = sysName;
        }
        break;
      case 'isHidden':
        field.isHidden = e.target.checked;
        break;
      case 'isConfidential':
        field.isConfidential = e.target.checked;
        break;
      case 'currencyCode':
        field.currencyCode = e.target.value;
        break;
      case 'isCurrency':
        field.isCurrency = e.target.checked;
        if (
          field.isCurrency &&
          (field.currencyCode === '' ||
            field.currencyCode === undefined ||
            field.currencyCode === null)
        ) {
          field.currencyCode = 'USD';
        }
        break;
      case 'decimalPlaces':
        field.decimalPlaces = parseInt(e.target.value, 10);
        break;
      case 'isRunningTotal':
        field.isRunningTotal = e.target.checked;
        break;
      case 'format':
        field.format = e.target.value;
        break;
      default:
        break;
    }
    this.setState({ newField: field });
  }

  editExtenderValue(e, property) {
    let extender = this.state.editExtender;
    switch (property) {
      case 'type':
        extender.type = parseInt(e.target.value, 10);
        extender.isDirty = true;
        break;
      case 'controlsChildren':
        extender.controlsChildren = e.target.checked;
        extender.isDirty = true;
        break;
      case 'sortAlphabetical':
        extender.sortAlphabetical = e.target.checked;
        extender.isDirty = true;
        break;
      case 'query':
        let q = this.state.currentQuery;
        q.query = e.target.value;
        extender.isDirty = true;
        this.setState({ currentQuery: q });
        break;
      case 'defaultValue':
        let q2 = this.state.currentQuery;
        q2.defaultValue = e.target.value;
        extender.isDirty = true;
        this.setState({ currentQuery: q2 });
        break;
      default:
        break;
    }
    this.setState({ editExtender: extender });
  }

  editTableValue(e, property) {
    let fieldSet = this.state.editFieldSet;
    switch (property) {
      case 'tableName':
        fieldSet.name = e.target.value;
        break;
      case 'isActive':
        fieldSet.isActive = e.target.checked;
        break;
      case 'systemName':
        fieldSet.systemName = this.camelCase(e.target.value);

        break;
      default:
        break;
    }
    this.setState({ editFieldSet: fieldSet });
  }

  addFieldClick() {
    let hasValidationError = false;
    let validationError = '';
    if (this.state.newField.name.length < 3) {
      hasValidationError = true;
      validationError = 'Name is too short';
    }
    if (this.state.newField.systemName.length < 3) {
      hasValidationError = true;
      validationError = 'System name is too short';
    }
    let maxOrderId = 1;
    this.state.currentFieldSet.fields.forEach((s) => {
      if (s.displayOrder >= maxOrderId) {
        maxOrderId = s.displayOrder + 1;
      }
      if (s.name === this.state.newField.name) {
        hasValidationError = true;
        validationError = 'Cannot create field with duplicate name';
      }
      if (s.systemName === this.state.newField.systemName) {
        hasValidationError = true;
        validationError = 'Cannot create field with duplicate system name';
      }
    });

    if (!hasValidationError) {
      let fs = this.state.currentFieldSet;
      let ft = this.state.currentFormType;
      let nf = this.state.newField;
      nf.displayOrder = maxOrderId;
      fs.fields.push(nf);

      let newField = {
        name: '',
        type: 'Text',
        systemName: '',
        fieldId: 0,
        isDirty: true,
        isRunningTotal: false,
      };
      this.setState(
        {
          newField: newField,
          currentFieldSet: fs,
          currentFormType: ft,
          isDirty: true,
        },
        () => {
          document.getElementById('new-field-name').focus();
        }
      );
    } else {
      PostAlert('error', validationError);
    }
  }

  addFieldRelationClick() {
    UpdateFieldRelation(
      this.state.selectedBaseField,
      this.state.selectedRelatedFormType,
      this.state.selectedRelatedField,
      this.state.isTwoWayLink
    );

    this.hideaddFieldRelations();
  }

  onNewFieldKeyDown(e) {
    if (e.code === 'Enter') {
      this.addFieldClick();
    }
  }

  toggleRearrange() {
    let toggle = this.state.isRearranging;
    this.setState({ isRearranging: !toggle });
  }

  editField(field) {
    let edit = {
      name: field.name,
      systemName: field.systemName,
      type: field.type,
      displayOrder: field.displayOrder,
      isHidden: field.isHidden,
      decimalPlaces: field.decimalPlaces,
      isCurrency: field.isCurrency,
      isRunningTotal: field.isRunningTotal,
      currencyCode: field.currencyCode,
      isConfidential: field.isConfidential,
      format: field.format,
      hasBasicIndex: false,
    };
    let indexFields = this.state.currentIndexes;
    let ihfs = indexFields.find((fs) => fs.fieldSetName === 'Header');
    if (ihfs) {
      let ifield = ihfs.fields.find((f) => f.fieldId === field.fieldId);
      if (ifield && ifield.hasBasicIndex) {
        edit.hasBasicIndex = true;
      } else {
        edit.hasBasicIndex = false;
      }
    }

    this.setState({ showFieldEditor: true, editField: edit });
  }

  editExtender(field) {
    let extender = field.extender;
    if (extender === null) {
      extender = {
        extenderId: -1,
        type: 4,
        controlsChildren: false,
        sortAlphabetical: true,
        configured: false,
        dataSources: [],
        extenderDependencies: [],
        fieldId: field.fieldId,
      };
      console.log(extender);
    }
    extender.availableDependencies = [];
    //extender.extenderDependencies = [];
    let fieldSet = this.state.currentFieldSet;

    if (fieldSet.name !== 'Header') {
      // show header fields also.
      let hfieldSet = this.state.currentFormType.fieldSets.find(
        (f) => f.name === 'Header'
      );
      if (hfieldSet) {
        let hfields = hfieldSet.fields.sort(
          (a, b) => a.displayOrder < b.displayOrder
        );
        hfields.forEach((hfsField) => {
          if (hfsField.hasExtender) {
            let ex = extender.extenderDependencies.find(
              (f) => f.fieldId === hfsField.fieldId
            );
            if (!ex) {
              let availDep = {
                fieldId: hfsField.fieldId,
                fieldName: hfsField.name,
                controlsChildren: hfsField.extender.controlsChildren,
                sortAlphabetical: hfsField.extender.sortAlphabetical,
                parentFieldId: hfsField.fieldId,
                childFieldId: field.fieldId,
              };
              extender.availableDependencies.push(availDep);
            }
          }
        });
      }
    }
    let fields = fieldSet.fields.sort(
      (a, b) => a.displayOrder < b.displayOrder
    );

    fields.forEach((fsField) => {
      if (fsField.hasExtender ) {
        let ex = extender.availableDependencies.find(
          (f) => f.fieldId === fsField.fieldId
        );
        if (!ex) {
          let availDep = {
            fieldId: fsField.fieldId,
            fieldName: fsField.name,
            controlsChildren: fsField.extender.controlsChildren,
            sortAlphabetical: fsField.extender.sortAlphabetical,
            parentFieldId: fsField.fieldId,
            childFieldId: field.fieldId,
          };
          extender.availableDependencies.push(availDep);
        }
      }
    });

    if (extender === null) {
      extender = {
        extenderId: -1,
        type: 1,
        fieldId: field.fieldId,
      };
    } else {
      extender.configured = true;
    }
    extender.fieldName = field.name;
    extender.isDirty = false;
    extender.availableSources = [];
    extender.selectedSource = 0;

    this.state.allSources.forEach((src) => {
      let exSrc = extender.dataSources.find((ds) => ds.dataSourceId === src.id);
      if (!exSrc) {
        src.dataSourceId = src.id;
        extender.availableSources.push(src);
      }
    });
    if (extender.dataSources.length > 0) {
      var src = extender.dataSources[0];
      extender.selectedSource = src.dataSourceId;
      this.setState({ currentQuery: src });
    }

    let showDelete = this.state.showOptionRemoveExtender;
    //let saveExtender =this.state.sho;

    if (extender.extenderId !== -1) {
      showDelete = true;
    } else {
      showDelete = false;
    }

    this.setState({
      editExtender: extender,
      showExtenderEditor: true,
      showFieldEditor: false,
      showOptionRemoveExtender: showDelete,
    }); //, editField: edit });
  }

  removeQuery() {
    let src = this.state.currentQuery;
    let extender = this.state.editExtender;
    let idx = extender.dataSources.indexOf(src);
    if (idx >= 0) {
      extender.dataSources.splice(idx, 1);
      extender.availableSources.push(src);
      extender.selectedSource = 0;
    }
    this.setState({ editExtender: extender });
  }

  addDataSrc() {
    let newSrcId = document.getElementById('extenderNewSrcSelect').value;
    if (newSrcId) {
      let extender = this.state.editExtender;
      let allSrcs = extender.availableSources;
      let usedSrc = allSrcs.find((s) => s.id === parseInt(newSrcId));
      let newSrc = {
        dataSourceId: usedSrc.dataSourceId,
        name: usedSrc.name,
        query: '',
      };
      newSrc.dataSourceName = newSrc.name;
      extender.dataSources.push(newSrc);

      extender.selectedSource = newSrc.dataSourceId;

      let idx = extender.availableSources.indexOf(usedSrc);
      if (idx >= 0) {
        allSrcs.splice(idx, 1);
      }
      this.setState({ editExtender: extender, currentQuery: newSrc });
    }
  }

  switchDataSource(src) {
    let extender = this.state.editExtender;
    extender.selectedSource = src.dataSourceId;
    this.setState({ editExtender: extender, currentQuery: src });
  }

  closeExtenderEdit() {
    this.setState({ showExtenderEditor: false });
  }

  configureExtender(field) {
    let extender = this.state.editExtender;
    extender.extenderId = -1;
    extender.configured = true;
    extender.fieldId = field.fieldId;
    console.log('edit');
    console.log(extender);

    this.setState({ editExtender: extender });
  }

  removeExtender() {
    let extender = this.state.editExtender;
    extender.isDirty = true;
    extender.extenderId = -1;
    extender.configured = false;
    this.setState({ editExtender: extender });
  }

  addExtenderDependency() {
    let newDepId = document.getElementById('extenderNewDependency').value;
    if (newDepId) {
      let extender = this.state.editExtender;
      let allDeps = extender.availableDependencies;
      let usedDep = allDeps.find((s) => s.fieldId === parseInt(newDepId));
      let newDep = {
        fieldId: usedDep.fieldId,
        fieldName: usedDep.fieldName,
        controlsChildren: usedDep.controlsChildren,
        sortAlphabetical: usedDep.sortAlphabetical,
        parentFieldId: usedDep.parentFieldId,
        childFieldId: usedDep.fieldId,
      };

      extender.extenderDependencies.push(newDep);
      extender.isDirty = true;
      let idx = extender.availableDependencies.indexOf(usedDep);
      if (idx >= 0) {
        allDeps.splice(idx, 1);
      }
      this.setState({ editExtender: extender });
    }
  }

  injectDependency(dep) {
    let extender = this.state.editExtender;
    let queryText = document.getElementById('textareaQuery');
    if (queryText) {
      let start = queryText.selectionStart;
      let end = queryText.selectionEnd;

      let q = this.state.currentQuery;
      let textStart = q.query.substring(0, start);
      let textEnd = q.query.substring(end);

      q.query = textStart + ' @ParentKey' + dep.fieldId.toString() + textEnd;
      extender.isDirty = true;
      this.setState({ editExtender: extender, currentQuery: q });
    }
  }

  editCancel() {
    this.setState({ showFieldEditor: false });
  }

  toggleConfirmDelete() {
    this.setState({ displayConfirmDeletePopup: false });
  }

  toggleDeleteTwoWayLink() {
    let toggle = this.state.deleteTwoWayLink;
    this.setState({ deleteTwoWayLink: !toggle });
  }

  toggleAboutFieldRelations() {
    let toggle = this.state.toggleAboutFieldRelations;
    this.setState({ toggleAboutFieldRelations: !toggle });
  }

  toggleAboutFieldType() {
    let toggle = this.state.showAboutFieldType;
    this.setState({ showAboutFieldType: !toggle });
  }

  confirmDeleteFieldRelation() {
    DeleteFieldRelation(
      this.state.fieldRelationToDelete,
      this.state.deleteTwoWayLink
    );
  }

  editUpdate() {
    let edit = this.state.editField;
    console.log('editUpdate');
    // find the field
    let fs = this.state.currentFieldSet;
    let f = fs.fields.find((x) => x.displayOrder === edit.displayOrder);
    if (f) {
      console.log(f);
      f.name = edit.name;
      f.systemName = edit.systemName;
      f.type = edit.type;
      f.isHidden = edit.isHidden;
      f.isConfidential = edit.isConfidential;
      f.decimalPlaces = edit.decimalPlaces;
      f.isCurrency = edit.isCurrency;
      f.isRunningTotal = edit.isRunningTotal;
      f.currencyCode = edit.currencyCode;
      f.format = edit.format;
      this.setState({
        currentFieldSet: fs,
        isDirty: true,
        showFieldEditor: false,
      });
    }
  }

  switchFieldSet(orderId) {
    let ft = this.state.currentFormType;
    let fs = ft.fieldSets.find((s) => s.orderId === orderId);
    this.setState({ currentFieldSet: fs });
  }

  addNewTableClick() {
    if (this.state.currentFormType.fieldSets.length <= 5) {
      let efs = {
        name: '',
        isActive: true,
        orderId: -1,
        systemName: '',
      };
      this.setState({ editFieldSet: efs, showTableEdit: true });
    } else {
      PostAlert('error', 'You can only have up to 5 detail tables');
    }
  }
  addTableClick() {
    if (this.state.currentFormType.fieldSets.length <= 5) {
      let nfs = {
        name: '',
        fieldSetId: -1,
        orderId: -1,
        isActive: true,
        systemName: '',
      };
      nfs.name = this.state.editFieldSet.name;
      nfs.systemName = this.state.editFieldSet.systemName;
      let maxId = 1;
      this.state.currentFormType.fieldSets.forEach((fs) => {
        if (fs.orderId >= maxId) {
          maxId = fs.orderId + 1;
        }
      });
      nfs.orderId = maxId;
      nfs.fields = [];
      let ft = this.state.currentFormType;
      ft.fieldSets.push(nfs);
      this.setState({
        currentFormType: ft,
        showTableEdit: false,
        isDirty: true,
      });
    } else {
      PostAlert('error', 'You can only have up to 5 detail tables');
    }
  }

  tableItemClick(fieldSet) {
    let editFs = {
      name: fieldSet.name,
      systemName: fieldSet.systemName,
      isActive: fieldSet.isActive,
      orderId: fieldSet.orderId,
    };
    console.log(fieldSet);
    editFs.canDelete = true;
    if (fieldSet.fields !== undefined) {
      fieldSet.fields.forEach((f) => {
        console.log(f);
        if (f.hasData === true) {
          editFs.canDelete = false;
        }
      });
    }
    this.setState({ editFieldSet: editFs, showTableEdit: true });
  }

  cancleTableClick() {
    this.setState({ showTableEdit: false });
  }

  updateTableClick() {
    let efs = this.state.editFieldSet;
    let ft = this.state.currentFormType;
    let fs = ft.fieldSets.find((f) => f.orderId === efs.orderId);
    fs.name = efs.name;
    fs.systemName = efs.systemName;
    fs.isActive = efs.isActive;
    this.setState({ currentFormType: ft, showTableEdit: false, isDirty: true });
  }

  rearrangeTableClick(dir) {
    let efs = this.state.editFieldSet;
    let ft = this.state.currentFormType;
    let fs = ft.fieldSets.find((f) => f.orderId === efs.orderId);
    if (dir === 'up') {
      // find previous table
      let pfs = ft.fieldSets.find((f) => f.orderId === efs.orderId - 1);
      if (pfs.orderId > 0) {
        let o = fs.orderId;
        fs.orderId = pfs.orderId;
        efs.orderId = pfs.orderId;
        pfs.orderId = o;
      }
    }
    if (dir === 'down') {
      let nfs = ft.fieldSets.find((f) => f.orderId === efs.orderId + 1);
      if (nfs) {
        let o = fs.orderId;
        fs.orderId = nfs.orderId;
        efs.orderId = nfs.orderId;
        nfs.orderId = o;
      }
    }
    ft.fieldSets.sort((a, b) => a.orderId - b.orderId);
    this.setState({ currentFormType: ft, editFieldSet: efs, isDirty: true });
  }

  rearrangeFieldClick(dir, field) {
    let cfs = this.state.currentFieldSet;

    if (dir === 'up') {
      // find previous table
      let pf = cfs.fields.find(
        (f) => f.displayOrder === field.displayOrder - 1
      );
      if (pf) {
        let o = field.displayOrder;
        field.displayOrder = pf.displayOrder;
        pf.displayOrder = o;
      }
    }
    if (dir === 'down') {
      let nf = cfs.fields.find(
        (f) => f.displayOrder === field.displayOrder + 1
      );
      if (nf) {
        let o = field.displayOrder;
        field.displayOrder = nf.displayOrder;
        nf.displayOrder = o;
      }
    }
    cfs.fields.sort((a, b) => a.displayOrder - b.displayOrder);
    this.setState({ currentFieldSet: cfs, isDirty: true });
  }

  toggleNewIndex() {
    let showNew = this.state.showNewIndex;
    this.setState({ showNewIndex: !showNew });
  }

  toggleDeleteIndex() {
    let showDelete = this.state.showDeleteIndex;
    this.setState({ showDeleteIndex: !showDelete });
  }

  deleteIndex() {
    let newIndexRequest = {
      id: this.state.currentFormType.indexId,
      name: this.state.defaultIndexName,
      formTypeId: this.state.currentFormType.formTypeId,
      //isShared: false,
      startNow: false,
      fields: this.state.currentIndexes,
    };

    DeleteIndex(newIndexRequest);
    this.setState({ showDeleteIndex: false });
  }

  reindexDocuments() {
    let loc = window.location.pathname;
    let pathParts = loc.split('/');
    let formTypeId = parseInt(pathParts[pathParts.length - 1], 10);
    ReIndexFormType(formTypeId);
    this.setState({ currentlyIndexing: true });
  }

  cancelReindexing() {
    let loc = window.location.pathname;
    let pathParts = loc.split('/');
    let formTypeId = parseInt(pathParts[pathParts.length - 1], 10);
    CancelReindexing(formTypeId);
    this.setState({ currentlyIndexing: false, currentIndexPercent: 0 });
  }

  reindexingComplete() {
    let loc = window.location.pathname;
    let pathParts = loc.split('/');
    let formTypeId = parseInt(pathParts[pathParts.length - 1], 10);
    UpdateBuildStatus(formTypeId, 0);
    this.setState({ currentlyIndexing: false, currentIndexPercent: 0 });
  }

  editDefaultIndexName(e) {
    this.setState({ defaultIndexName: e.target.value });
  }

  editStartBuildImmediately(e) {
    this.setState({ startBuildImmediately: e.target.checked });
  }

  indexFieldChange(field) {
    let ci = this.state.currentIndexes.find((i) => i.fieldId === field.fieldId);
    let isHeader = false;
    let headerFieldSet = this.state.currentFormType.fieldSets.find(
      (fs) => fs.orderId === 0
    );
    if (headerFieldSet) {
      let headerFields = headerFieldSet.fields;
      let h = headerFields.find((s) => s.fieldId === field.fieldId);
      if (h) isHeader = true;
    }
    if (ci) {
      // remove

      let indexes = this.state.currentIndexes;
      let idx = indexes.indexOf(ci);
      indexes.splice(idx, 1);
      this.setState({ currentIndexes: indexes, isIndexSectionDirty: true });
    } else {
      // add
      let indexes = this.state.currentIndexes;
      indexes.push({
        systemName: field.systemName,
        type: field.type,
        isHeader: isHeader,
        fieldId: field.fieldId,
      });
      this.setState({ currentIndexes: indexes, isIndexSectionDirty: true });
    }
  }

  startIndexCreation() {
    let newIndexRequest = {
      id: this.state.currentFormType.indexId,
      name: this.state.defaultIndexName,
      formTypeId: this.state.currentFormType.formTypeId,
      //isShared: false,
      startNow: false,
      fieldSets: this.state.currentIndexes,
    };
    //this.setState({ startBuildImmediately: true });
    UpdateIndex(newIndexRequest);
  }

  updateIndex() {
    if (this.state.currentFormType.indexId === 0) {
      let ftName = this.state.currentFormType.name;
      this.setState({ showNewIndex: true, defaultIndexName: ftName });
    } else {
      let newIndexRequest = {
        id: this.state.currentFormType.indexId,
        name: this.state.defaultIndexName,
        formTypeId: this.state.currentFormType.formTypeId,
        //isShared: false,
        startNow: false,
        fieldSets: this.state.currentIndexes,
      };

      UpdateIndex(newIndexRequest);
    }
  }

  updateIndexAndReindex() {
    let newIndexRequest = {
      id: this.state.currentFormType.indexId,
      name: this.state.defaultIndexName,
      formTypeId: this.state.currentFormType.formTypeId,
      //isShared: false,
      startNow: false,
      fieldSets: this.state.currentIndexes,
    };

    UpdateIndex(newIndexRequest);
    this.setState({ startBuildImmediately: true });
  }

  onSave() {
    UpdateFormType(this.state.currentFormType);

    this.setState({ isSaving: true });
  }

  saveExtenderChanges() {
    console.log(this.state.editExtender);
    this.setState({ errorMessageForExtenders: '' });
    let ext = this.state.editExtender;
    if (ext.type === 0) {
      PostAlert('error', 'Please set or reselect a Control Type');
      //this.setState({errorMessageForExtenders: 'Please set or reselect a Control Type'});

      return;
    }

    if (ext.selectedSource <= 0) {
      PostAlert('error', 'Please set a data source');
      //this.setState({errorMessageForExtenders: 'Please set a data source'});
      return;
    }

    if (ext.dataSources.length <= 0) {
      PostAlert('error', 'Please configure a data source');
      //this.setState({errorMessageForExtenders: 'Please configure a data source'});
      return;
    }

    //PostAlert('Error','THIS IS A TEST');
    for (let i = 0; i < ext.dataSources.length; i++) {
      let x = ext.dataSources[i];

      if (x.query === '') {
        PostAlert('error', 'Please enter a query');
        return;
      }

      if (x.query.indexOf('Key') === -1) {
        PostAlert('error', 'Query must contain Key');
        return;
      }
      if (x.query.indexOf('Value') === -1) {
        PostAlert('error', 'Query must contain Value');
        return;
      }

      if (ext.extenderDependencies.length > 0) {
        for (let h = 0; h < ext.extenderDependencies.length; h++) {
          let dep = ext.extenderDependencies[h];
          if (!dep.controlsChildren) {
            let strToFind = '@ParentKey' + dep.fieldId.toString();

            if (x.query.indexOf(strToFind) === -1) {
              PostAlert(
                'error',
                'Query must contain a reference to its parent:' + strToFind
              );
              return;
            }
          }
        }
      }
    }

    UpdateExtender(this.state.editExtender);
  }

  onTimerClick() {
    if (this.state.currentlyIndexing === true) {
      //console.log('tick');
      let loc = window.location.pathname;
      let pathParts = loc.split('/');
      let formTypeId = parseInt(pathParts[pathParts.length - 1], 10);
      GetBuildPercentage(formTypeId);
    }
  }

  toggleRemoveExtender() {
    let removeExtender = this.state.showRemoveExtender;
    let showOptionRemoveExtender = this.state.showOptionRemoveExtender;
    this.setState({
      showRemoveExtender: !removeExtender,
      showOptionRemoveExtender: !showOptionRemoveExtender,
    });
  }

  generatePluginModel() {
    GetPluginModel(this.state.currentFormType.formTypeId);
  }

  onConfirmRemoveExtender() {
    let extender = this.state.editExtender;
    RemoveExtender(extender);
  }

  toggleTwoWayLink() {
    let toggle = this.state.isTwoWayLink;
    this.setState({ isTwoWayLink: !toggle });
  }

  deleteField(field) {
    let fs = this.state.currentFieldSet;
    let f = fs.fields.find((fd) => fd.fieldId === field.fieldId);

    let idx = fs.fields.indexOf(f);
    fs.fields.splice(idx, 1);
    this.setState({ currentFieldSet: fs, isDirty: true });
  }

  removeTableClick() {
    let fs = this.state.editFieldSet;
    let ft = this.state.currentFormType;
    console.log(fs);
    console.log(ft);
    let fs2 = ft.fieldSets.find((f) => f.orderId === fs.orderId);
    let idx = ft.fieldSets.indexOf(fs2);
    console.log(idx);
    ft.fieldSets.splice(idx, 1);
    console.log(ft);
    this.setState({ isDirty: true, currentFormType: ft, showTableEdit: false });
  }

  setFieldDragging(field) {
    this.dragField = field;
  }

  onFieldDragOver(e, field) {

    let fieldId = `fieldDiv-${field.displayOrder}`;
    let el = document.getElementById(fieldId);
    if(el)
    {
      let clientRect = el.getBoundingClientRect();

      if( clientRect.y < e.clientY && e.clientY < (clientRect.y + (clientRect.height / 2)))
      {
        this.setState({
          dropOverId: field.fieldId,
          dropUnderId: 0,
        });
      }

      if((clientRect.y + (clientRect.height / 2)) < e.clientY && e.clientY < (clientRect.y + clientRect.height))
      {
        this.setState({
          dropOverId: 0,
          dropUnderId: field.fieldId,
        });
      }
    }

    if (
      this.dragField !== undefined &&
      this.dragField.fieldId !== field.fieldId
    ) {
      let fs = this.state.currentFieldSet;
      this.dragTarget = field;
      this.setState({ currentFieldSet: fs });
    }
  }

  onFieldDrop() {
    let fs = this.state.currentFieldSet;

    let currentFields = fs.fields;
    currentFields.sort((a, b) => a.displayOrder - b.displayOrder);
    let idx = currentFields.indexOf(this.dragField);

    currentFields.splice(idx, 1);
    if(this.state.dropOverId !== 0)
    {
      // drop above
      let proxTarget = currentFields.find((f) => f.fieldId === this.state.dropOverId);
      if(proxTarget)
      {
        let idx2 = currentFields.indexOf(this.dragTarget);
        currentFields.splice(idx2, 0, this.dragField);
      }
    }
    if(this.state.dropUnderId !== 0)
    {
      // drop below
      let proxTarget = currentFields.find((f) => f.fieldId === this.state.dropUnderId);
      if(proxTarget)
      {
        let idx2 = currentFields.indexOf(proxTarget);
        currentFields.splice(idx2+1, 0, this.dragField);
      }
    }

    // let idx2 = currentFields.indexOf(this.dragTarget);
    // currentFields.splice(idx2, 0, this.dragField);

    let displayIdx = 1;
    currentFields.forEach((s) => {
      s.displayOrder = displayIdx;
      displayIdx++;
    });

    this.dragTarget = undefined;
    this.dragField = undefined;
    currentFields.sort((a, b) => a.displayOrder - b.displayOrder);
    this.setState({ currentFieldSet: fs, isDirty: true, dropOverId: 0, dropUnderId: 0 });
  }

  toggleSelectAllIndex(fs) {
    let indexes = this.state.currentIndexes;
    let headerFieldSet = indexes.find(
      (fs2) => fs2.fieldSetId === fs.fieldSetId
    );

    let allChecked = true;
    headerFieldSet.fields.forEach((field) => {
      if (!field.hasBasicIndex) {
        allChecked = false;
      }
    });

    if (allChecked) {
      // remove all.

      headerFieldSet.fields.forEach((field) => {
        field.hasBasicIndex = false;
        field.hasSqlIndex = false;
      });
    } else {
      // add all.
      headerFieldSet.fields.forEach((field) => {
        field.hasBasicIndex = true;
      });
    }
    this.setState({ currentIndexes: indexes, isIndexSectionDirty: true });
  }

  RemoveIndexOption(fieldSetId, fieldId, removeOption) {
    let indexes = this.state.currentIndexes;
    let fieldSet = indexes.find((fs) => fs.fieldSetId === fieldSetId);
    if (fieldSet) {
      let field = fieldSet.fields.find((f) => f.fieldId === fieldId);
      if (field) {
        switch (removeOption) {
          case 'sql':
            field.hasSqlIndex = false;
            field.useTextIndex = false;
            break;
          case 'text':
            field.useTextIndex = false;
            field.hasSqlIndex = false;
            break;
          case 'basic':
            field.hasBasicIndex = false;
            field.hasSqlIndex = false;
            break;
        }
        this.setState({ currentIndexes: indexes, isIndexSectionDirty: true });
      }
    }
  }

  AddIndexOption(fieldSetId, fieldId, addOption) {
    let indexes = this.state.currentIndexes;
    let fieldSet = indexes.find((fs) => fs.fieldSetId === fieldSetId);
    if (fieldSet) {
      let field = fieldSet.fields.find((f) => f.fieldId === fieldId);
      if (field) {
        switch (addOption) {
          case 'sql':
            field.hasSqlIndex = true;
            field.hasBasicIndex = true;
            break;
          case 'text':
            field.useTextIndex = true;
            break;
          case 'basic':
            field.hasBasicIndex = true;

            break;
        }
        this.setState({ currentIndexes: indexes, isIndexSectionDirty: true });
      }
    }
  }

  toggleRebuildIndex() {
    let show = this.state.showReindex;
    this.setState({ showReindex: !show });
  }

  showRebuildIndex() {
    var rco = {
      indexAll: true,
      colOptions: [],
    };
    let indexFieldSets = this.state.currentIndexes;
    let ihs = indexFieldSets.find((fs) => fs.fieldSetName === 'Header');
    if (ihs) {
      ihs.fields.forEach((field) => {
        rco.colOptions.push({
          colId: field.fieldId,
          colName: field.systemName,
          reindex: true,
        });
      });
    }
    this.setState({ reindexColumnOptions: rco });

    this.setState({ showReindex: true });
  }

  changeReindexSettings(e, change, colId) {
    console.log(change);
    if (change === 'active') {
      this.setState({ reindexActiveDocs: e.target.checked });
    }
    if (change === 'complete') {
      this.setState({ reindexCompleteDocs: e.target.checked });
    }
    if (change === 'delete') {
      this.setState({ reindexDeletedDocs: e.target.checked });
    }

    if (change === 'columns') {
      this.setState({ reindexColumns: e.target.checked });
    }
    if (change === 'text') {
      this.setState({ reindexText: e.target.checked });
    }
    if (change === 'related') {
      this.setState({ reindexRelated: e.target.checked });
    }

    if (change === 'allColumns') {
      let rco = this.state.reindexColumnOptions;
      rco.indexAll = e.target.checked;
      rco.colOptions.forEach((col) => {
        col.reindex = e.target.checked;
      });
      this.setState({ reindexColumnOptions: rco });
    }

    if (change === 'column') {
      let rco = this.state.reindexColumnOptions;

      let col = rco.colOptions.find((c) => c.colId === colId);
      if (col) {
        col.reindex = e.target.checked;
      }

      this.setState({ reindexColumnOptions: rco });
    }
  }

  startIndexBuild() {
    // let rco = this.state.reindexColumnOptions;
     let ftId = this.state.currentFormType.formTypeId;
    // let colsToPopulate = [];
    // if (this.state.reindexColumnOptions.indexAll) {
    //   colsToPopulate = ['ALL'];
    // } else {
    //   this.state.reindexColumnOptions.colOptions.forEach((col) => {
    //     if (col.reindex) {
    //       colsToPopulate.push(col.colName);
    //     }
    //   });
    // }
    //console.log(colsToPopulate);

    let params = {
      includeActive: this.state.reindexActiveDocs,
      includeDeleted: this.state.reindexDeletedDocs,
      includeCompleted: this.state.reindexCompleteDocs,
      rebuildTextIndex: this.state.reindexText,
      rebuildRelatedCounts: this.state.reindexRelated,
    };

    ReIndexFormTypeWithParams(ftId, params);
    this.setState({ showReindex: false });
    this.setState({ currentlyIndexing: true });
  }

  render() {
    return (
      <div className='sidebarLayout d-flex flex-row flex-fill'>
        <div className='sidebar-container'>
          <FormTypeSidebar />
        </div>
        <div
          className='flex-fill'
          style={{
            display: 'grid',
            gridTemplateColumns: '650px 1fr',
            gridTemplateRows: '150px 40px 1fr 15px',
          }}
        >
          <div
            id='ftInfo'
            className='card'
            style={{ gridColumn: '1', gridRow: '1', margin: '16px' }}
          >
            <div className='card-header'>
              <FontAwesomeIcon icon={solid('file-alt')} /> Form Type
            </div>
            <div className='card-body'>
              <div className='row mb-3'>
                <label className='col-sm-2 col-form-label' htmlFor='txtFTName'>
                  Name:
                </label>
                <div className='col-sm-10'>
                  <input
                    type='text'
                    className='form-control'
                    id='txtFTName'
                    value={this.state.currentFormType.name}
                    onChange={this.onChangeFTName}
                    placeholder='Form Type Name'
                  />
                </div>
              </div>
            </div>
          </div>

          <div
            id='userPills'
            style={{
              gridColumnStart: '1',
              gridColumnEnd: '3',
              gridRow: '2 / 2',
              marginLeft: '16px',
            }}
          >
            <ul className='nav nav-pills'>
              <li className='nav-item'>
                <span
                  className={`nav-link ${
                    this.state.currentView === 'fields' ? 'active' : ''
                  }`}
                  style={{ cursor: 'pointer' }}
                  onClick={() => this.switchView('fields')}
                >
                  Fields
                </span>
              </li>
              <li className='nav-item'>
                <span
                  className={`nav-link ${
                    this.state.currentView === 'tables' ? 'active' : ''
                  }`}
                  style={{ cursor: 'pointer' }}
                  onClick={() => this.switchView('tables')}
                >
                  Tables
                </span>
              </li>
              <li className='nav-item'>
                <span
                  className={`nav-link ${
                    this.state.currentView === 'relations' ? 'active' : ''
                  }`}
                  style={{ cursor: 'pointer' }}
                  onClick={() => this.switchView('relations')}
                >
                  Relations
                </span>
              </li>

              <li className='nav-item'>
                <span
                  className={`nav-link ${
                    this.state.currentView === 'index' ? 'active' : ''
                  }`}
                  style={{ cursor: 'pointer' }}
                  onClick={() => this.switchView('index')}
                >
                  Indexes
                </span>
              </li>
              {this.state.currentFormType.businessProcessId !== 0 && (
                <li className='nav-item pt-2 ms-4'>
                  <span style={{ cursor: 'pointer' }}>
                    <Link
                      to={`/Admin/BusinessProcess/${this.state.currentFormType.businessProcessId}`}
                      style={{ textDecoration: 'none', color: '#000' }}
                    >
                      <span style={{ color: '#B023DD' }}>
                        <FontAwesomeIcon icon={solid('th-large')} />
                      </span>{' '}
                      {this.state.currentFormType.businessProcessName}
                    </Link>
                  </span>
                </li>
              )}

              {this.state.isDirty && !this.state.isSaving && (
                <li className='nav_item'>
                  <button
                    className='btn btn-success'
                    onClick={this.onSave}
                    style={{ marginLeft: '30px' }}
                  >
                    <FontAwesomeIcon icon={solid('save')} /> Save Changes
                  </button>
                </li>
              )}
              {this.state.isSaving && (
                <li className='nav_item'>
                  <button
                    className='btn btn-success'
                    style={{ marginLeft: '30px', width: '100px' }}
                  >
                    <FontAwesomeIcon icon={solid('spinner')} spin />
                  </button>
                </li>
              )}
            </ul>
          </div>

          {this.state.currentView === 'fields' && (
            <div
              id='ftFields'
              className='card'
              style={{
                gridColumnStart: '1',
                gridColumnEnd: '3',
                gridRow: '3 / 3',
                margin: '16px',
                position: 'relative',
                maxHeight: this.state.workHeight,
                height: this.state.workHeight,
              }}
            >
              <div className='card-header'>Fields</div>
              <div className='card-body'>
                <div
                  className='row'
                  style={{
                    marginLeft: '10px',

                    maxHeight: this.state.workHeight - 80,
                    overflow: 'auto',
                    padding: '2px',
                  }}
                >
                  <div id='userPills' style={{ paddingLeft: '0px' }}>
                    <ul className='nav nav-pills'>
                      {this.state.currentFormType.fieldSets.sort((a, b) => a.orderId > b.orderId ? 1 : -1).map((fieldSet) => (
                        <li className='nav-item nav-sm' key={fieldSet.orderId}>
                          <span
                            className={`nav-link nav-sm ${
                              this.state.currentFieldSet.orderId ===
                              fieldSet.orderId
                                ? 'active'
                                : ''
                            }`}
                            style={{ cursor: 'pointer' }}
                            onClick={() =>
                              this.switchFieldSet(fieldSet.orderId)
                            }
                          >
                            {fieldSet.name}
                          </span>
                        </li>
                      ))}
                    </ul>
                  </div>

                  <table className='wf-StepTable'>
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Type</th>
                        <th>System Name</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.currentFieldSet !== undefined &&
                        this.state.currentFieldSet.fields.map((field) => (
                          <tr
                            key={field.displayOrder}
                            id={`fieldDiv-${field.displayOrder}`}
                            draggable='true'
                            onDragStart={() => this.setFieldDragging(field)}
                            onDragOver={(e) => this.onFieldDragOver(e, field)}
                            onDragEnd={() => this.onFieldDrop(field)}
                            className={
                              this.dragField === field ? 'dragging':
                                this.state.dropUnderId === field.fieldId ? 'borderUnder':
                                this.state.dropOverId === field.fieldId ? 'borderOver' : ''}

                          >
                            <td style={{ cursor: 'pointer' }}>
                              {field.type === 'Text' && (
                                <span style={{ color: '#1F8F8F' }}>
                                  <FontAwesomeIcon icon={solid('font')} />{' '}
                                </span>
                              )}
                              {field.type === 'Number' && (
                                <span style={{ color: '#1F8F8F' }}>
                                  <FontAwesomeIcon icon={solid('hashtag')} />{' '}
                                </span>
                              )}
                              {field.type === 'Date' && (
                                <span style={{ color: '#1F8F8F' }}>
                                  <FontAwesomeIcon icon={solid('calendar')} />{' '}
                                </span>
                              )}

                              {field.name}
                              {field.isHidden && (
                                <FontAwesomeIcon
                                  style={{ color: '#777' }}
                                  className='ms-2'
                                  icon={solid('eye-slash')}
                                />
                              )}
                            </td>
                            <td>{field.type}</td>
                            <td>{field.systemName}</td>
                            <td>
                              <button
                                className='btn btn-primary btn-sm btn-circle'
                                onClick={() => this.editField(field)}
                                style={{ marginLeft: '4px' }}
                              >
                                <FontAwesomeIcon
                                  title='Edit Field'
                                  icon={solid('pencil')}
                                />
                              </button>
                              <button
                                title='Edit Extender'
                                className='btn btn-sm btn-circle'
                                onClick={() => this.editExtender(field)}
                                style={{
                                  marginLeft: '4px',
                                  backgroundColor: field.hasExtender
                                    ? '#A1DA35'
                                    : '#E8E8E8',
                                  color: '#fff',
                                }}
                              >
                                <FontAwesomeIcon
                                  title='Edit Extender'
                                  icon={solid('list')}
                                />
                              </button>
                              {!field.hasData && (
                                <button
                                  className='btn btn-sm btn-circle'
                                  onClick={() => this.deleteField(field)}
                                  style={{
                                    marginLeft: '4px',
                                    backgroundColor: '#D22E2E',
                                    color: '#fff',
                                  }}
                                  title='Delete'
                                >
                                  <FontAwesomeIcon icon={solid('times')} />
                                </button>
                              )}
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                  {!this.state.addingField && (
                    <div style={{ marginTop: '8px' }}>
                      <button
                        className='btn btn-success'
                        onClick={this.addFields}
                      >
                        <FontAwesomeIcon icon={solid('plus')} /> Add fields{' '}
                      </button>
                      {/*  <button
                        className='btn btn-primary'
                        onClick={this.toggleRearrange}
                        style={{ marginLeft: '6px' }}
                      >
                        Rearrange Fields
                      </button>*/}
                    </div>
                  )}
                  <div>
                    <button
                      className='btn btn-primary btn-circle btn-sm'
                      style={{ marginTop: '8px' }}
                      title='integration setup'
                      onClick={this.generatePluginModel}
                    >
                      <FontAwesomeIcon icon={solid('code')} />
                    </button>
                  </div>
                  {this.state.addingField && (
                    <div>
                      <div
                        style={{
                          border: 'solid 1px #BBB',
                          borderRadius: '8px',
                          marginTop: '16px',
                          padding: '8px',
                          marginLeft: '-3px',
                          marginRight: '-1px',
                          backgroundColor: '#EEE',
                        }}
                      >
                        <strong>New Field:</strong>
                        <div className='row'>
                          <div className='col-4'>
                            <label className='form-label'>Name:</label>
                            <input
                              type='text'
                              id='new-field-name'
                              className='form-control'
                              value={this.state.newField.name}
                              onKeyDown={this.onNewFieldKeyDown}
                              onBlur={this.newFieldBlur}
                              onChange={(event) =>
                                this.editNewFieldValue(event, 'name')
                              }
                              placeholder='Field name'
                            />
                          </div>
                          <div className='col-4'>
                            <label className='form-label'>Type:</label>
                            <select
                              className='form-select'
                              value={this.state.newField.type}
                              onKeyDown={this.onNewFieldKeyDown}
                              onChange={(event) =>
                                this.editNewFieldValue(event, 'type')
                              }
                            >
                              <option value='Text'>Text</option>
                              <option value='Number'>Number</option>
                              <option value='Date'>Date</option>
                            </select>
                          </div>
                          <div className='col-4'>
                            <label className='form-label'>System Name:</label>
                            <input
                              type='text'
                              className='form-control'
                              value={this.state.newField.systemName}
                              onChange={(event) =>
                                this.editNewFieldValue(event, 'systemName')
                              }
                              onKeyDown={this.onNewFieldKeyDown}
                              placeholder='system Name'
                            />
                          </div>
                        </div>

                        <button
                          style={{ marginTop: '4px' }}
                          className='btn btn-success'
                          onClick={this.addFieldClick}
                        >
                          Add Field
                        </button>
                        <button
                          style={{ marginTop: '4px', marginLeft: '4px' }}
                          className='btn btn-secondary'
                          onClick={this.hideaddFields}
                        >
                          Close
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
          {this.state.currentView === 'fields' &&
            this.state.showFieldEditor && (
              <div
                className='card'
                style={{
                  gridColumnStart: '1',
                  gridColumnEnd: '3',
                  gridRow: '3 / 3',
                  margin: '45px',
                  zIndex: '95',
                  maxHeight: '380px',
                }}
              >
                <div className='card-header'>
                  Edit field properties:{' '}
                  <strong>{this.state.editField.systemName}</strong>
                </div>
                <div className='card-body'>
                  <div className='row'>
                    <div className='col-6'>
                      <div>
                        <label htmlFor='txtFieldName' className='form-label'>
                          Display as:
                        </label>
                        <input
                          type='text'
                          className='form-control'
                          id='txtFieldName'
                          value={this.state.editField.name}
                          onChange={(event) =>
                            this.editFieldValue(event, 'name')
                          }
                        />
                      </div>
                      <div className='mt-2'>
                        <label htmlFor='selType' className='form-label'>
                          Type:
                        </label>
                        {' '}
                          <FontAwesomeIcon icon={solid('circle-info')}
                              onClick={() =>
                                this.toggleAboutFieldType()
                              }
                          />
                        <select
                          className='form-select'
                          value={this.state.editField.type}
                          onChange={(event) =>
                            this.editFieldValue(event, 'type')
                          }
                          disabled={this.state.editField.hasBasicIndex}
                        >
                          <option value='Text'>Text</option>
                          <option value='Number'>Number</option>
                          <option value='Date'>Date</option>
                        </select>
                      </div>
                      <div className='form-check form-check-inline mt-2'>
                        <input
                          className='form-check-input me-1'
                          checked={this.state.editField.isHidden}
                          onChange={(event) =>
                            this.editFieldValue(event, 'isHidden')
                          }
                          type='checkbox'
                        />
                        <label className='form-check-label'>Hidden</label>
                      </div>
                      {/* <div className='form-check form-check-inline mt-2'>
                        <input
                          className='form-check-input me-1'
                          checked={this.state.editField.isConfidential}
                          onChange={(event) =>
                            this.editFieldValue(event, 'isConfidential')
                          }
                          type='checkbox'
                        />
                        <label className='form-check-label'>Confidential</label>
                      </div> */}
                    </div>
                    <div className='col-6'>
                      <div>
                        <label htmlFor='txtSysName' className='form-label'>
                          System Name:
                        </label>
                        <input
                          type='text'
                          className='form-control'
                          id='txtFieldName'
                          value={this.state.editField.systemName}
                          onChange={(event) =>
                            this.editFieldValue(event, 'systemName')
                          }
                          disabled={this.state.editField.hasBasicIndex}
                        />
                      </div>
                      {this.state.editField.type === 'Number' && (
                        <div>
                          <div className='mt-2'>
                            <label className='form-label'>
                              Number of decimal places:
                            </label>
                            <input
                              type='number'
                              className='form-control'
                              id='txtFieldName'
                              value={this.state.editField.decimalPlaces}
                              onChange={(event) =>
                                this.editFieldValue(event, 'decimalPlaces')
                              }
                            />
                          </div>
                          <div className='row mt-2'>
                            <div className='col-6'>
                              <div className='form-check form-check-inline'>
                                <input
                                  className='form-check-input me-1'
                                  checked={this.state.editField.isCurrency}
                                  onChange={(event) =>
                                    this.editFieldValue(event, 'isCurrency')
                                  }
                                  type='checkbox'
                                />
                                <label className='form-check-label'>
                                  Currency?
                                </label>
                              </div>
                            </div>
                            <div className='col-6'>
                              {this.state.editField.isCurrency && (
                                <select
                                  className='form-select'
                                  value={this.state.editField.currencyCode}
                                  onChange={(event) =>
                                    this.editFieldValue(event, 'currencyCode')
                                  }
                                >
                                  <option value='USD'>$ Dollar</option>
                                  <option value='GBP'>£ Pound</option>
                                  <option value='EUR'>€ Euro</option>
                                  <option value='RUB'>₽ Ruble</option>
                                  <option value='JPY'>¥ Yen</option>
                                  <option value='PHP'>₱ Peso</option>
                                </select>
                              )}
                            </div>
                          </div>
                          {this.state.currentFieldSet.name !== 'Header' && (
                            <div className='row mt-1'>
                              <div className='col-12'>
                                <div className='form-check form-check-inline'>
                                  <input
                                    className='form-check-input'
                                    checked={
                                      this.state.editField.isRunningTotal
                                    }
                                    onChange={(event) =>
                                      this.editFieldValue(
                                        event,
                                        'isRunningTotal'
                                      )
                                    }
                                    type='checkbox'
                                  />
                                  <label className='form-check-label'>
                                    Show column total
                                  </label>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      )}
                      {this.state.editField.type === 'Date' && (
                        <div>
                          <div className='mt-2'>
                            <label className='form-label'>Date Format:</label>
                            <select
                              className='form-select'
                              value={this.state.editField.format}
                              onChange={(event) =>
                                this.editFieldValue(event, 'format')
                              }
                            >
                              <option value='mm/dd/yyyy'>mm/dd/yyyy</option>
                              <option value='dd/mm/yyyy'>dd/mm/yyyy</option>
                              <option value='mm-dd-yyyy'>mm-dd-yyyy</option>
                            </select>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className='row mt-2 mb-2'></div>
                  <div>
                    <button
                      className='btn btn-success mt-2'
                      onClick={this.editUpdate}
                    >
                      Update
                    </button>
                    <button
                      className='btn btn-secondary mt-2 mx-1'
                      onClick={this.editCancel}
                    >
                      Cancel
                    </button>
                  </div>
                </div>

                {this.state.showAboutFieldType && (
                <div className='td-dialogue-container'>
                  <div
                    className='td-dialogue card'
                    style={{ height: '200px', width: '400px' }}
                  >
                    <div className='card-header'>
                      About Field Type
                      <button
                        onClick={this.toggleAboutFieldType}
                        className='btn-close'
                      />
                    </div>
                    <div className='card-body'>

                        <div className='row'>
                          <div className='col-auto'>
                            <label className='form-label'>
                              Field types cannot be changed while they are indexed. You must remove it from the index before you are able to update the field type.
                            </label>
                          </div>
                        </div>


                    </div>
                  </div>
                </div>
              )}
              </div>
            )}
          {this.state.currentView === 'fields' &&
            this.state.showExtenderEditor && (
              <div
                className='card'
                style={{
                  gridColumnStart: '1',
                  gridColumnEnd: '3',
                  gridRow: '3 / 3',
                  margin: '45px',
                  zIndex: '100',
                  maxHeight: '650px',
                }}
              >
                <div className='card-header'>
                  Extender for:{' '}
                  <strong>{this.state.editExtender.fieldName}</strong>
                  <button
                    type='button'
                    className='btn-close'
                    style={{ float: 'right' }}
                    onClick={this.closeExtenderEdit}
                  />
                </div>
                <div className='card-body'>
                  {this.state.editExtender.configured && (
                    <div>
                      <div className='row'>
                        <div className='col-6'>
                          <label className='form-label'>Control Type</label>
                          <select
                            className='form-select'
                            value={this.state.editExtender.type}
                            onChange={(e) => this.editExtenderValue(e, 'type')}
                          >
                            <option value='1'>AutoComplete</option>
                            <option value='4'>Dropdown</option>
                          </select>
                        </div>
                        <div className='col-6'>
                          <div className='form-check form-switch mt-4'>
                            <input
                              checked={this.state.editExtender.controlsChildren}
                              onChange={(e) =>
                                this.editExtenderValue(e, 'controlsChildren')
                              }
                              className='form-check-input'
                              type='checkbox'
                            />
                            <label className='form-check-label'>
                              Controls Data Source of Children
                            </label>
                          </div>
                          <div className='form-check form-switch mt-4'>
                            <input
                              checked={this.state.editExtender.sortAlphabetical}
                              onChange={(e) =>
                                this.editExtenderValue(e, 'sortAlphabetical')
                              }
                              className='form-check-input'
                              type='checkbox'
                            />
                            <label className='form-check-label'>
                              Alphabetize List
                            </label>
                          </div>
                        </div>
                      </div>
                      <label className='form-label mt-3'>Queries</label>
                      {this.state.editExtender.dataSources.length === 0 && (
                        <div
                          className='pt-4 pb-4 ms-2'
                          style={{ fontWeight: 700, color: '#A0A0A0' }}
                        >
                          There are no queries for this extender {':('}
                        </div>
                      )}
                      <div className='d-flex'>
                        <div className='me-auto'>
                          <ul className='nav nav-pills'>
                            {this.state.editExtender.dataSources.map((src) => (
                              <li className='nav-item' key={src.dataSourceId}>
                                <span
                                  className={`nav-link nav-sm ${
                                    src.dataSourceId ===
                                    this.state.editExtender.selectedSource
                                      ? 'active'
                                      : ''
                                  } `}
                                  style={{ cursor: 'pointer' }}
                                  onClick={() => this.switchDataSource(src)}
                                >
                                  {src.dataSourceName}
                                </span>
                              </li>
                            ))}
                          </ul>
                        </div>
                        <div className='ms-auto'>
                          {this.state.editExtender.selectedSource > 0 && (
                            <button
                              className='btn btn-sm btn-danger'
                              onClick={this.removeQuery}
                            >
                              Remove Query
                            </button>
                          )}
                        </div>
                      </div>

                      {this.state.editExtender.selectedSource > 0 &&
                        this.state.currentQuery !== undefined && (
                          <textarea
                            id='textareaQuery'
                            className='form-control mt-1'
                            value={this.state.currentQuery.query}
                            onChange={(e) => this.editExtenderValue(e, 'query')}
                            rows='5'
                          />
                        )}
                      <div className='d-flex'>
                        <div className='col-auto mt-3'>
                          <select
                            className='form-select'
                            id='extenderNewSrcSelect'
                          >
                            {this.state.editExtender.availableSources.map(
                              (src) => (
                                <option key={src.id} value={src.id}>
                                  {src.name}
                                </option>
                              )
                            )}
                          </select>
                        </div>
                        <div className='col-auto mt-3'>
                          <button
                            className='btn btn-success col-auto'
                            onClick={this.addDataSrc}
                          >
                            Add Query for this Data Source
                          </button>
                        </div>

                        <div className='row mt-2 ms-auto'>
                          {this.state.editExtender.selectedSource > 0 &&
                            this.state.currentQuery !== undefined && (
                              <div className='row me-auto mt-1'>
                                <div className='col-auto'>
                                  <label className='col-form-label'>
                                    Default Value
                                  </label>
                                </div>
                                <div className='col-auto'>
                                  <input
                                    type='text'
                                    className='form-control'
                                    value={this.state.currentQuery.defaultValue}
                                    onChange={(e) =>
                                      this.editExtenderValue(e, 'defaultValue')
                                    }
                                  />
                                </div>
                              </div>
                            )}

                          <div />
                        </div>
                      </div>

                      <hr className='mt-3' />
                      <div className='row'>
                        <div className='col-auto'>
                          <label className='form-label'>Dependencies:</label>
                        </div>
                        <div className='col-auto'>
                          <ul className='nav nav-pills'>
                            {this.state.editExtender.extenderDependencies.map(
                              (dep) => (
                                <li className='nav-item ms-2' key={dep.fieldId}>
                                  <span
                                    className={`${
                                      dep.controlsChildren
                                        ? 'nav-link3'
                                        : 'nav-link2'
                                    } nav-sm`}
                                  >
                                    {dep.controlsChildren && (
                                      <FontAwesomeIcon
                                        icon={solid('database')}
                                      />
                                    )}
                                    {!dep.controlsChildren && (
                                      <FontAwesomeIcon
                                        style={{ cursor: 'pointer' }}
                                        onClick={() =>
                                          this.injectDependency(dep)
                                        }
                                        icon={solid('at')}
                                      />
                                    )}{' '}
                                    {dep.fieldName}{' '}
                                    <FontAwesomeIcon
                                      style={{ cursor: 'pointer' }}
                                      icon={solid('times')}
                                    />
                                  </span>
                                </li>
                              )
                            )}
                          </ul>
                        </div>
                      </div>
                      <div className='row'>
                        <div className='col-auto mt-1'>
                          <select
                            className='form-select form-select-sm'
                            id='extenderNewDependency'
                          >
                            {this.state.editExtender.availableDependencies.map(
                              (dep) => (
                                <option key={dep.fieldId} value={dep.fieldId}>
                                  {dep.fieldName}
                                </option>
                              )
                            )}
                          </select>
                        </div>
                        <div className='col-auto mt-1'>
                          <button
                            className='btn btn-sm btn-success col-auto'
                            onClick={this.addExtenderDependency}
                          >
                            Add Dependency
                          </button>
                        </div>
                      </div>
                      <hr className='mt-3' />

                      {this.state.editExtender.isDirty && (
                        <button
                          className='btn btn-success mt-3'
                          onClick={this.saveExtenderChanges}
                        >
                          Save extender changes
                        </button>
                      )}

                      <button
                        className='btn btn-danger mt-3 ms-1'
                        onClick={this.toggleRemoveExtender}
                      >
                        Remove extender
                      </button>
                      <div
                        style={{
                          color: '#FF0000',
                          fontSize: '14pt',
                        }}
                      >
                        <br></br>
                        {this.state.errorMessageForExtenders}
                      </div>
                    </div>
                  )}
                  {!this.state.editExtender.configured && (
                    <div>
                      <div
                        style={{
                          color: '#9D9D9D',
                          fontSize: '12pt',
                        }}
                      >
                        No extender configured
                      </div>

                      <button
                        className='btn btn-primary mt-2'
                        onClick={this.configureExtender}
                      >
                        Configure extender
                      </button>
                    </div>
                  )}
                </div>



              </div>
            )}
          {/* Tables */}
          {this.state.currentView === 'tables' && (
            <div
              id='ftTables'
              className='card'
              style={{
                gridColumnStart: '1',
                gridColumnEnd: '3',
                gridRow: '3 / 3',
                margin: '16px',
                position: 'relative',
              }}
            >
              <div className='card-header'>Tables</div>
              <div className='card-body'>
                <div className='row'>
                  <div className='col-4'>
                    <div style={{ margin: '8px' }}>
                      <label className='form-label'>Detail Tables:</label>
                      <div className='td-detail-desc'>
                        Each entry here creates a new table tab.
                      </div>
                      <div className='td-sel-list'>
                        <ul>
                          {this.state.currentFormType.fieldSets.sort((a, b) => a.orderId > b.orderId ? 1 : -1).map((fs) => (
                            <li
                              key={fs.orderId}
                              style={{
                                display: fs.orderId === 0 ? 'none' : 'block',
                              }}
                              onClick={() => this.tableItemClick(fs)}
                            >
                              {fs.name}
                            </li>
                          ))}
                        </ul>
                      </div>
                      <button
                        className='btn btn-sm btn-success'
                        onClick={this.addNewTableClick}
                      >
                        Add Table
                      </button>
                    </div>
                  </div>
                  <div className='col-8'>
                    {this.state.showTableEdit && (
                      <div style={{ margin: '16px' }}>
                        <label className='form-label'>Table Name:</label>
                        <input
                          type='text'
                          className='form-control'
                          value={this.state.editFieldSet.name}
                          onChange={(event) =>
                            this.editTableValue(event, 'tableName')
                          }
                          placeholder='table name'
                        />
                        <label className='form-label'>System Name:</label>
                        <input
                          type='text'
                          className='form-control'
                          value={this.state.editFieldSet.systemName}
                          onChange={(event) =>
                            this.editTableValue(event, 'systemName')
                          }
                          placeholder='system name'
                        />
                        {this.state.editFieldSet.fieldSetId > -1 && (
                          <div
                            className='form-check form-switch'
                            style={{ marginTop: '6px' }}
                          >
                            <input
                              className='form-check-input'
                              type='checkbox'
                              checked={this.state.editFieldSet.isActive}
                              onChange={(event) =>
                                this.editTableValue(event, 'isActive')
                              }
                            />
                            <label className='form-check-label'>Active</label>
                          </div>
                        )}
                        <div style={{ marginTop: '4px' }}>
                          {this.state.editFieldSet.orderId === -1 && (
                            <button
                              className='btn btn-success'
                              style={{ marginRight: '4px' }}
                              onClick={this.addTableClick}
                              disabled={
                                this.state.editFieldSet.name.length === 0
                              }
                            >
                              Add
                            </button>
                          )}

                          {this.state.editFieldSet.orderId > -1 && (
                            <button
                              className='btn btn-success'
                              style={{ marginRight: '4px' }}
                              onClick={this.updateTableClick}
                              disabled={
                                this.state.editFieldSet.name.length === 0
                              }
                            >
                              Update
                            </button>
                          )}
                          {this.state.editFieldSet.canDelete && (
                            <button
                              className='btn btn-danger'
                              style={{ marginRight: '4px' }}
                              onClick={this.removeTableClick}
                            >
                              Remove
                            </button>
                          )}
                          <button
                            className='btn btn-secondary'
                            style={{ marginRight: '4px' }}
                            onClick={this.cancleTableClick}
                          >
                            Cancel
                          </button>
                          {this.state.editFieldSet.orderId > -1 && (
                            <button
                              className='btn btn-primary btn-circle'
                              style={{ marginRight: '4px' }}
                              disabled={this.state.editFieldSet.orderId === 1}
                              onClick={() => this.rearrangeTableClick('up')}
                            >
                              <FontAwesomeIcon icon={solid('arrow-up')} />
                            </button>
                          )}
                          {this.state.editFieldSet.orderId > -1 && (
                            <button
                              className='btn btn-primary btn-circle'
                              style={{ marginRight: '4px' }}
                              onClick={() => this.rearrangeTableClick('down')}
                            >
                              <FontAwesomeIcon icon={solid('arrow-down')} />
                            </button>
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}

          {/* Relations */}
          {this.state.currentView === 'relations' && (
            <div
              id='ftTables'
              className='card'
              style={{
                gridColumnStart: '1',
                gridColumnEnd: '3',
                gridRow: '3 / 3',
                margin: '16px',
                position: 'relative',
              }}
            >
              <div className='card-header'>
                <div
                  className='col-3 pt-3'
                  style={{
                    display: 'inline-block',
                    verticalAlign: 'middle',
                    fontWeight: 'bold',
                  }}
                >
                  Field Relations
                  {' '}
                  <FontAwesomeIcon icon={solid('circle-info')}
                    onClick={() =>
                      this.toggleAboutFieldRelations()
                    }
                  />
                </div>
              </div>

              <div className='card-body'>
                <div
                  id='ftRelations'
                  className=''
                  style={{
                    gridColumnStart: '1',
                    gridColumnEnd: '3',
                    gridRow: '3 / 3',
                    position: 'relative',
                  }}
                >
                  <div className='card-body'>
                    <div
                      className='row'
                      style={{
                        maxHeight: '100%',
                        overflow: 'auto',
                      }}
                    >
                      <table className='wf-StepTable'>
                        <thead>
                          <tr>
                            <th>Base Field</th>
                            <th>Related Form Type</th>
                            <th>Related Field</th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.fieldRelations.map((fieldRelation) => (
                            <tr key={fieldRelation.id}>
                              <td>{fieldRelation.baseFieldName}</td>
                              <td>{fieldRelation.relatedFormTypeName}</td>
                              <td>{fieldRelation.relatedFieldName}</td>
                              <td>
                                <span style={{ color: 'red' }}>
                                  <FontAwesomeIcon
                                    icon={solid('trash')}
                                    onClick={() =>
                                      this.initialDeleteFieldRelation(
                                        fieldRelation
                                      )
                                    }
                                  />{' '}
                                </span>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                      {!this.state.addingFieldRelation && (
                        <div style={{ marginTop: '8px', paddingLeft: '0px' }}>
                          <button
                            className='btn btn-success'
                            onClick={this.addFieldRelations}
                          >
                            <FontAwesomeIcon icon={solid('plus')} /> Add field
                            relation{' '}
                          </button>
                        </div>
                      )}

                      {this.state.addingFieldRelation && (
                        <div>
                          <div
                            style={{
                              border: 'solid 1px #BBB',
                              borderRadius: '8px',
                              marginTop: '16px',
                              padding: '8px',
                              marginLeft: '-3px',
                              marginRight: '-1px',
                              backgroundColor: '#EEE',
                            }}
                          >
                            <strong>New Field Relation:</strong>
                            <div className='row'>
                              <div className='col-4'>
                                <label className='form-label'>
                                  Base Fields
                                </label>
                                <select
                                  className='form-select'
                                  value={this.state.selectedBaseField}
                                  onChange={(event) =>
                                    this.editSelectedBaseField(event)
                                  }
                                >
                                  <option value=''>{''}</option>
                                  {this.state.baseFields.map((field) => (
                                    <option value={field.fieldId}>
                                      {field.name}
                                    </option>
                                  ))}
                                </select>
                              </div>
                              <div className='col-4'>
                                <label className='form-label'>
                                  Related Form Type:{' '}
                                </label>
                                <select
                                  className='form-select'
                                  value={this.state.selectedRelatedFormType}
                                  onChange={(event) =>
                                    this.selectFormType(event, 'type')
                                  }
                                >
                                  <option value=''>{''}</option>
                                  {this.state.formTypes.map((ft) => (
                                    <option value={ft.formTypeId}>
                                      {ft.name}
                                    </option>
                                  ))}
                                </select>
                              </div>
                              <div className='col-4'>
                                <label className='form-label'>
                                  Related Fields:
                                </label>
                                <select
                                  className='form-select'
                                  value={this.state.selectedRelatedField}
                                  onChange={(event) =>
                                    this.editSelectedRelatedField(event)
                                  }
                                >
                                  <option value=''>{''}</option>
                                  {this.state.relatedFields.map((field) => (
                                    <option value={field.fieldId}>
                                      {field.name}
                                    </option>
                                  ))}
                                </select>
                              </div>
                              <div className='col-4'>
                                <label className='form-label'>
                                  Two way link:{' '}
                                </label>
                                <input
                                  className='form-check-input'
                                  style={{ marginLeft: '4px' }}
                                  type='checkbox'
                                  checked={this.state.isTwoWayLink}
                                  onChange={() => this.toggleTwoWayLink()}
                                />
                              </div>
                            </div>

                            <button
                              style={{ marginTop: '4px' }}
                              className='btn btn-success'
                              onClick={this.addFieldRelationClick}
                            >
                              Add Field Relation
                            </button>
                            <button
                              style={{ marginTop: '4px', marginLeft: '4px' }}
                              className='btn btn-secondary'
                              onClick={this.hideaddFieldRelations}
                            >
                              Close
                            </button>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              {this.state.displayConfirmDeletePopup && (
                <div className='td-dialogue-container'>
                  <div
                    className='td-dialogue card'
                    style={{ height: '175px', width: '400px' }}
                  >
                    <div className='card-header'>
                      Delete Field Relation
                      <button
                        onClick={this.toggleConfirmDelete}
                        className='btn-close'
                      />
                    </div>
                    <div className='card-body'>
                      <div className='form-check  mt-2'>
                        <input
                          className='form-check-input'
                          type='checkbox'
                          checked={this.state.deleteTwoWayLink}
                          onChange={this.toggleDeleteTwoWayLink}
                        />
                        <label className='form-check-label'>
                          Delete Two Way Link (If exists)
                        </label>
                      </div>
                      <button
                        onClick={this.confirmDeleteFieldRelation}
                        className='btn btn-danger mt-3 w-100'
                      >
                        Confirm Delete
                      </button>
                    </div>
                  </div>
                </div>
              )}
              {this.state.toggleAboutFieldRelations && (
                <div className='td-dialogue-container'>
                  <div
                    className='td-dialogue card'
                    style={{ height: '240px', width: '400px' }}
                  >
                    <div className='card-header'>
                      About Field Relations
                      <button
                        onClick={this.toggleAboutFieldRelations}
                        className='btn-close'
                      />
                    </div>
                    <div className='card-body'>

                        <div className='row'>
                          <div className='col-auto'>
                            <label className='form-label'>
                            Field relations can only be established using form types and fields that are indexed; unindexed fields will not be displayed.
                            Additionally, a Base Field and a Related Field must share the same data type for a field relationship to be created.
                            If you encounter difficulties linking two fields, ensure that they have matching data types.
                            </label>
                          </div>
                        </div>


                    </div>
                  </div>
                </div>
              )}

            </div>
          )}

          {/* Indexes */}
          {this.state.currentView === 'index' && (
            <div
              id='ftTables'
              className='card'
              style={{
                gridColumnStart: '1',
                gridColumnEnd: '3',
                gridRow: '3 / 3',
                margin: '16px',
                position: 'relative',
              }}
            >
              <div className='card-header'>
                <div
                  className='col-3 pt-3'
                  style={{
                    display: 'inline-block',
                    verticalAlign: 'middle',
                    fontWeight: 'bold',
                  }}
                >
                  Searching
                </div>
                {this.state.doesIndexExist &&
                  !this.state.currentlyIndexing &&
                  !this.state.isIndexSectionDirty && (
                    <div
                      className='m-2'
                      style={{ display: 'inline-block', float: 'right' }}
                    >
                      <button
                        onClick={this.showRebuildIndex}
                        className='mt-1 me-1 btn btn-primary'
                      >
                        Reindex Documents
                      </button>
                    </div>
                  )}
              </div>

              <div className='card-body'>
                {(this.state.currentlyIndexing && this.state.buildStatus === 1) && (
                  <>
                    <div className='row justify-content-center'>
                      <div
                        className='col-6 m-1'
                        style={{ textAlign: 'center', fontWeight: 'bold' }}
                      >
                        Indexing Progress: {this.state.currentIndexPercent}%
                      </div>
                    </div>
                    <div className='row justify-content-center'>
                      <div className='col-lg m-3'>
                        <div className='progress'>
                          <div
                            className='progress-bar bg-info'
                            role='progressbar'
                            style={{
                              width:
                                this.state.currentIndexPercent.toString() + '%',
                            }}
                            aria-valuenow='50'
                            aria-valuemin='0'
                            aria-valuemax='100'
                          ></div>
                        </div>
                      </div>
                    </div>

                    {this.state.currentlyIndexing &&
                      this.state.currentIndexPercent === 100 && (
                        <>
                          <div className='row justify-content-center'>
                            <div
                              className='col-6 mb-3'
                              style={{ textAlign: 'center' }}
                            >
                              Reindexing Complete
                            </div>
                          </div>
                          <div className='row justify-content-center'>
                            <div
                              className='col-6'
                              style={{ textAlign: 'center' }}
                            >
                              <button
                                onClick={this.reindexingComplete}
                                className='mt-1 me-1 btn btn-success'
                              >
                                Ok!
                              </button>
                            </div>
                          </div>
                        </>
                      )}

                    {this.state.currentlyIndexing &&
                      this.state.currentIndexPercent !== 100 && (
                        <>
                          <div className='row justify-content-center mb-3'>
                            <div
                              className='col-6'
                              style={{ textAlign: 'center' }}
                            >
                              <button
                                onClick={this.cancelReindexing}
                                className='mt-1 me-1 btn btn-danger'
                              >
                                Cancel Reindexing
                              </button>
                            </div>
                          </div>
                        </>
                      )}
                  </>
                )}
                <div style={{ marginBottom: '8px' }}>
                  <span className='redCheck'>
                    <FontAwesomeIcon icon={solid('square-check')} />
                  </span>
                  <label
                    className='form-check-label'
                    style={{ marginLeft: '4px' }}
                  >
                    Show in Active Documents{' '}
                  </label>

                  <span className='greenCheck' style={{ marginLeft: '8px' }}>
                    <FontAwesomeIcon icon={solid('square-check')} />
                  </span>
                  <label
                    className='form-check-label'
                    style={{ marginLeft: '4px' }}
                  >
                    Enable Searching{' '}
                  </label>

                </div>
                <div className='row '>
                  {this.state.currentIndexes.map((fs) => (
                    <div className='col-auto' key={fs.orderId}>
                      <div className='ft-search-colheader'>
                        {' '}
                        {fs.orderId === 0 && (
                          <FontAwesomeIcon
                            style={{ cursor: 'pointer' }}
                            icon={solid('square-check')}
                            onClick={() => this.toggleSelectAllIndex(fs)}
                          />
                        )}{' '}
                        {fs.fieldSetName}
                      </div>
                      <div style={{ margin: '6px' }}>
                        {fs.fields.map((field) => (
                          <div key={field.displayOrder}>
                            {fs.fieldSetName === 'Header' && (
                              <span className='redCheck'>
                                {field.hasBasicIndex && (
                                  <FontAwesomeIcon
                                    icon={solid('square-check')}
                                    onClick={() =>
                                      this.RemoveIndexOption(
                                        fs.fieldSetId,
                                        field.fieldId,
                                        'basic'
                                      )
                                    }
                                  />
                                )}
                                {!field.hasBasicIndex && (
                                  <FontAwesomeIcon
                                    icon={regular('square')}
                                    onClick={() =>
                                      this.AddIndexOption(
                                        fs.fieldSetId,
                                        field.fieldId,
                                        'basic'
                                      )
                                    }
                                  />
                                )}
                              </span>
                            )}
                            {/* {fs.fieldSetName === 'Header' && (
                              <span className='greenCheck'>
                                {(field.hasSqlIndex || field.useTextIndex) && (
                                  <FontAwesomeIcon
                                    icon={solid('square-check')}
                                    onClick={() =>
                                      this.RemoveIndexOption(
                                        fs.fieldSetId,
                                        field.fieldId,
                                        'sql'
                                      )
                                    }
                                  />
                                )}
                                {(!field.hasSqlIndex && !field.useTextIndex) && (
                                  <FontAwesomeIcon
                                    icon={regular('square')}
                                    onClick={() =>
                                      this.AddIndexOption(
                                        fs.fieldSetId,
                                        field.fieldId,
                                        'sql'
                                      )
                                    }
                                  />
                                )}
                              </span>
                            )} */}
                            <span className='greenCheck'>
                              {(field.hasSqlIndex || field.useTextIndex) && (
                                <FontAwesomeIcon
                                  icon={solid('square-check')}
                                  onClick={() =>
                                    this.RemoveIndexOption(
                                      fs.fieldSetId,
                                      field.fieldId,
                                      'text'
                                    )
                                  }
                                />
                              )}
                              {(!field.hasSqlIndex && !field.useTextIndex) && (
                                <FontAwesomeIcon
                                  icon={regular('square')}
                                  onClick={() =>
                                    this.AddIndexOption(
                                      fs.fieldSetId,
                                      field.fieldId,
                                      'text'
                                    )
                                  }
                                />
                              )}
                            </span>

                            <label
                              className='form-check-label'
                              style={{ marginLeft: '4px' }}
                            >
                              {field.name}
                            </label>
                          </div>
                        ))}
                      </div>
                    </div>
                  ))}
                </div>

                {!this.state.currentlyIndexing && (
                  <div>
                    {this.state.doesIndexExist && (
                      <>
                        <button
                          onClick={this.updateIndex}
                          disabled={!this.state.isIndexSectionDirty}
                          className='mt-1 me-1 btn btn-success'
                        >
                          Update
                        </button>
                      </>
                    )}
                    {!this.state.doesIndexExist && (
                      <button
                        onClick={this.toggleNewIndex}
                        className='mt-1 me-1 btn btn-success'
                      >
                        Create Search Index
                      </button>
                    )}
                    {this.state.doesIndexExist && (
                      <button
                        onClick={this.toggleDeleteIndex}
                        className='mt-1 me-1 btn btn-danger'
                      >
                        Delete Search Index
                      </button>
                    )}
                  </div>
                )}
                {this.state.showNewIndex && (
                  <div className='td-dialogue-container'>
                    <div
                      className='td-dialogue card'
                      style={{ height: '220px', width: '400px' }}
                    >
                      <div className='card-header'>
                        New Search Index
                        <button
                          onClick={this.toggleNewIndex}
                          className='btn-close'
                        />
                      </div>
                      <div className='card-body'>
                        <div className='row'>
                          <div className='col-auto'>
                            <label className='form-label mt-1'>Name:</label>
                          </div>
                          <div className='col-auto '>
                            <input
                              className='form-control'
                              placeholder='index name'
                              value={this.state.defaultIndexName}
                              onChange={this.editDefaultIndexName}
                            />
                          </div>
                        </div>

                        <button
                          onClick={this.startIndexCreation}
                          className='btn btn-primary mt-3 w-100'
                        >
                          Create
                        </button>
                      </div>
                    </div>
                  </div>
                )}

                {this.state.showDeleteIndex && (
                  <div className='td-dialogue-container'>
                    <div
                      className='td-dialogue card'
                      style={{ height: '250px', width: '400px' }}
                    >
                      <div className='card-header'>
                        Delete Index
                        <button
                          onClick={this.toggleDeleteIndex}
                          className='btn-close'
                        />
                      </div>
                      <div className='card-body'>
                        <div className='row'>
                          <div className='col-auto'>
                            <label className='form-label mt-1'>Deleting this index will result in the removal of any related fields configured for this form type.
                            Additionally, it will eliminate fields from displaying on active documents and may impact search performance by slowing down response times.
                            </label>
                          </div>
                        </div>

                        <button
                          onClick={this.deleteIndex}
                          className='btn btn-danger mt-2 w-100'
                        >
                          Delete
                        </button>
                      </div>
                    </div>
                  </div>
                )}

                {this.state.showReindex && (
                  <div className='td-dialogue-container'>
                    <div
                      className='td-dialogue card'
                      style={{ height: '510px', width: '400px' }}
                    >
                      <div className='card-header'>
                        Rebuild Search Index
                        <button
                          onClick={this.toggleRebuildIndex}
                          className='btn-close'
                        />
                      </div>
                      <div className='card-body'>
                        <div>
                          <div className='form-check'>
                            <input
                              className='form-check-input'
                              type='checkbox'
                              checked={this.state.reindexActiveDocs}
                              onChange={(e) =>
                                this.changeReindexSettings(e, 'active')
                              }
                            />
                            <label className='form-check-label'>
                              Active {`(${this.state.activeCount})`}
                            </label>
                          </div>
                          <div className='form-check'>
                            <input
                              className='form-check-input'
                              type='checkbox'
                              checked={this.state.reindexCompleteDocs}
                              onChange={(e) =>
                                this.changeReindexSettings(e, 'complete')
                              }
                            />
                            <label className='form-check-label'>
                              Completed {`(${this.state.completedCount})`}
                            </label>
                          </div>
                          <div className='form-check'>
                            <input
                              className='form-check-input'
                              type='checkbox'
                              checked={this.state.reindexDeletedDocs}
                              onChange={(e) =>
                                this.changeReindexSettings(e, 'delete')
                              }
                            />
                            <label className='form-check-label'>
                              Deleted {`(${this.state.deletedCount})`}
                            </label>
                          </div>
                        </div>
                        <hr />
                        {/* <div>
                          <div className='form-check'>
                            <input
                              className='form-check-input'
                              type='checkbox'
                              checked={this.state.reindexColumns}
                              onChange={(e) =>
                                this.changeReindexSettings(e, 'columns')
                              }
                            />
                            <label className='form-check-label'>
                              Populate Columns
                            </label>
                          </div>
                          <div
                            style={{
                              height: '160px',
                              border: 'solid 1px #CCC',
                              overflow: 'auto',
                              paddingLeft: '4px',
                            }}
                          >
                            <div className='form-check'>
                              <input
                                className='form-check-input'
                                type='checkbox'
                                checked={
                                  this.state.reindexColumnOptions.indexAll
                                }
                                onChange={(e) =>
                                  this.changeReindexSettings(e, 'allColumns')
                                }
                              />
                              <label className='form-check-label'>All</label>
                            </div>
                            {this.state.reindexColumnOptions.colOptions.map(
                              (col) => (
                                <div className='form-check'>
                                  <input
                                    className='form-check-input'
                                    type='checkbox'
                                    checked={col.reindex}
                                    onChange={(e) =>
                                      this.changeReindexSettings(
                                        e,
                                        'column',
                                        col.colId
                                      )
                                    }
                                  />
                                  <label className='form-check-label'>
                                    {col.colName}
                                  </label>
                                </div>
                              )
                            )}
                          </div>
                        </div>
                        <hr /> */}

                        <div>
                          <div className='form-check'>
                            <input
                              className='form-check-input'
                              type='checkbox'
                              checked={this.state.reindexText}
                              onChange={(e) =>
                                this.changeReindexSettings(e, 'text')
                              }
                            />
                            <label className='form-check-label'>
                              Rebuild Text Index{' '}
                            </label>
                          </div>
                          <div className='form-check'>
                            <input
                              className='form-check-input'
                              type='checkbox'
                              checked={this.state.reindexRelated}
                              onChange={(e) =>
                                this.changeReindexSettings(e, 'related')
                              }
                            />
                            <label className='form-check-label'>
                              Rebuild Related Counts{' '}
                            </label>
                          </div>
                        </div>

                        <button
                          onClick={this.startIndexBuild}
                          className='btn btn-primary mt-3 w-100'
                        >
                          Start Build
                        </button>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
        {this.state.showRemoveExtender && (
          <div className='td-dialogue-container' style={{ zIndex: '200' }}>
            <div
              className='td-dialogue card'
              style={{ height: '220px', width: '400px', zIndex: '110' }}
            >
              <div className='card-header'>
                Remove extender
                <button
                  onClick={this.toggleRemoveExtender}
                  className='btn-close'
                />
              </div>
              <div className='card-body'>
                <div>Are you sure you want to remove this extender?</div>
                <div className='pt-2'>
                  <button
                    className='btn btn-danger'
                    onClick={this.onConfirmRemoveExtender}
                  >
                    Yes - Remove
                  </button>
                  <button
                    className='btn btn-secondary ms-2'
                    onClick={this.toggleRemoveExtender}
                  >
                    No - Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default FormTypeView;
