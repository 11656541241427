// @flow
import * as React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';

import DirectoryStore from '../../../../stores/DirectoryStore';
import { GetDirectoryList } from '../../../../actions/DirectoriesActions';

class DirectorySidebar extends React.Component {
  //isList: Array<BPModel>;
  constructor(props) {
    super(props);
    this.state = {
      dirList: [],
      searchText: '',
      filteredDirList: [],
      dirsLoaded: false,
    };

    this.onReceiveDirectoryChange = this.onReceiveDirectoryChange.bind(this);
    this.onSearchChange = this.onSearchChange.bind(this);
  }

  componentDidMount() {
    DirectoryStore.addChangeListener(this.onReceiveDirectoryChange);
    GetDirectoryList();
  }

  componentWillUnmount() {
    DirectoryStore.removeChangeListener(this.onReceiveDirectoryChange);
  }

  onReceiveDirectoryChange() {
    let lastChange = DirectoryStore.lastChange();
    if (lastChange === 'DirectoriesList') {
      let dirs = DirectoryStore.getDirectories();
      this.setState({
        dirList: dirs,
        searchText: '',
        filteredDirList: dirs,
      });
    }
  }

  onSearchChange(e) {
    let search = e.target.value;
    if (search === '') {
      // no search, show all
      let newList = [...this.state.dirList];
      this.setState({ searchText: search, filteredDirList: newList });
    } else {
      // filter results
      let newList = [];
      this.state.dirList.forEach((dir) => {
        if (dir.uploadPath.toLowerCase().includes(search.toLowerCase())) {
          newList.push(dir);
        }
      });
      this.setState({ searchText: search, filteredDirList: newList });
    }
  }

  render() {
    let listOfDirs;
    if (this.state.dirList !== undefined) {
      listOfDirs = this.state.filteredDirList.map((dir) => (
        <div style={{ marginBottom: '8px' }} key={dir.uploadId}>
          <Link
            className='sidebar-link'
            to={'/Admin/DirectoryView/' + dir.uploadId}
            style={{ cursor: 'Pointer', wordWrap: 'break-word' }}
          >
            {dir.uploadPath}
          </Link>
        </div>
      ));
    } else {
      listOfDirs = <div />;
    }
    return (
      <div
        className='d-flex flex-column p-3 text-white bg-dark'
        style={{ height: '100%', width: '245px' }}
      >
        <Link
          to='/Admin/Directories'
          className='d-flex align-items-center mb-3 mb-md-0 me-md-auto text-white text-decoration-none'
        >
          <span className='fs-4'>
            <FontAwesomeIcon icon={solid('folder-open')} />
            {'   '}
            Directories
          </span>
        </Link>
        <hr />
        <div className='input-group rounded' style={{ marginBottom: '16px' }}>
          <input
            type='search'
            value={this.state.searchText}
            className='form-control'
            placeholder='Search'
            aria-label='Search'
            onChange={this.onSearchChange}
            aria-describedby='search-addon'
          />
          <span className='input-group-text border-0' id='search-addon'>
            <FontAwesomeIcon icon={solid('search')} />
          </span>
        </div>

        <div style={{ maxHeight: '50%', overflow: 'auto' }}>{listOfDirs}</div>

        <hr />
        <Link className='sidebar-link' to='/Admin/DirectoryView/0'>
          New Directory
        </Link>
      </div>
    );
  }
}

export default DirectorySidebar;
