import * as React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';

import actionTypes from '../../../actions/ActionTypes';
import InboxStore from '../../../stores/InboxStore';
import LoginStore from '../../../stores/LoginStore';
import {
  GetQueueTree,
  GetErrorTree,
  GetDeletedTree,
  GetImageReviewTree,
  GetFilterTree,
} from '../../../actions/InboxActions';

class InboxSidebar extends React.Component {
  //isList: Array<BPModel>;
  constructor(props) {
    super(props);
    this.state = {
      queueNodeTree: [],
      filterNodeTree: [],
      errorNodeTree: [],
      deletedNodeTree: [],
      imageReviewNodeTree: [],
      h: window.innerHeight - 142,
      displayMyQueues: false,
      displayMyFilters: false,
      displayErrors: false,
      displayDeleted: false,
      displayImageReview: false,
      queueTotalDocCount: 0,
      filterTotalDocCount: 0,
      errorTotalDocCount: 0,
      deletedTotalDocCount: 0,
      imageReviewTotalDocCount: 0,
      fetchingQueueTree: false,
      fetchingErrorTree: false,
      fetchingDeletedTree: false,
      fetchingImageReviewTree: false,
      fetchingFilterTree: false,
      licenseLevel: 0,
    };

    this.onReceiveInboxChange = this.onReceiveInboxChange.bind(this);
    this.toggleNode = this.toggleNode.bind(this);
    this.toggleFilterNode = this.toggleFilterNode.bind(this);
    this.resizeH = this.resizeH.bind(this);
    this.displayMyQueues = this.displayMyQueues.bind(this);
    this.displayMyFilters = this.displayMyFilters.bind(this);
    this.displayErrors = this.displayErrors.bind(this);
    this.displayDeleted = this.displayDeleted.bind(this);
    this.displayImageReview = this.displayImageReview.bind(this);
    this.reloadQueues = this.reloadQueues.bind(this);
    this.reloadFilters = this.reloadFilters.bind(this);
    this.reloadErrors = this.reloadErrors.bind(this);
    this.reloadDeleted = this.reloadDeleted.bind(this);
    this.reloadImageReview = this.reloadImageReview.bind(this);

  }

  componentDidMount() {
    InboxStore.addChangeListener(this.onReceiveInboxChange);
    let u = LoginStore.getUser();
    this.setState({ licenseLevel: u.licenseLevel,
      fetchingQueueTree: true,
      fetchingFilterTree: true,
     });
    GetQueueTree();
    GetFilterTree();
    if (u.licenseLevel >= 3) {
      this.setState({
        fetchingErrorTree: true,
        fetchingDeletedTree: true,
        fetchingImageReviewTree: true,
       });
      GetErrorTree();
      GetDeletedTree();
      GetImageReviewTree();
    }
    this.resizeH();
    window.addEventListener('resize', this.resizeH);
  }

  componentWillUnmount() {
    InboxStore.removeChangeListener(this.onReceiveInboxChange);
    window.removeEventListener('resize', this.resizeH);
  }

  onReceiveInboxChange() {
    let lastChange = InboxStore.lastChange();

    var settings = this.getADSettings();
    if (lastChange === actionTypes.InboxGetQueueTree) {
      let adTree = InboxStore.getQueueTree();

      let queueDocCount = 0;
      adTree.forEach((tree) => {
        queueDocCount = queueDocCount + tree.count;
      });

      if (adTree.length > 0) {
        this.setState({
          displayMyQueues: true,
        });
      }

      this.setState({
        queueNodeTree: adTree,
        queueTotalDocCount: queueDocCount,
        fetchingQueueTree: false,
      });

      if (settings !== null) {
        adTree.forEach((ou) => {
          if (ou.type === 'ou') {
            if (settings.ouOpen.indexOf(ou.id) > -1) {
              ou.isOpen = true;
            } else {
              ou.isOpen = false;
            }
          }
        });

        adTree.forEach((oubp) => {
          if (oubp.type === 'bp') {
            if (settings.bpOpen.indexOf(oubp.id) > -1) {
              oubp.isOpen = true;
            } else {
              oubp.isOpen = false;
            }
          }
          if (oubp.type === 'ou') {
            oubp.children.forEach((bp) => {
              if (settings.bpOpen.indexOf(bp.id) > -1) {
                bp.isOpen = true;
              } else {
                bp.isOpen = false;
              }
            });
          }
        });

        this.setState({
          queueNodeTree: adTree,
        });
      }
    }

    if (lastChange === actionTypes.InboxGetFilterTree) {
      let adTree = InboxStore.getFilterTree();

      let filterDocCount = 0;
      adTree.forEach((filter) => {
        filter.tree.forEach((tree) => {
          filterDocCount = filterDocCount + tree.count;
        });
      });

      if (adTree.length > 0) {
        this.setState({
          displayMyFilters: true,
        });
      }

      this.setState({
        filterNodeTree: adTree,
        filterTotalDocCount: filterDocCount,
        fetchingFilterTree: false,
      });

      if (settings !== null) {
        adTree.forEach((ou) => {
          if (ou.type === 'ou') {
            if (settings.ouOpen.indexOf(ou.id) > -1) {
              ou.isOpen = true;
            } else {
              ou.isOpen = false;
            }
          }
        });

        adTree.forEach((oubp) => {
          if (oubp.type === 'bp') {
            if (settings.bpOpen.indexOf(oubp.id) > -1) {
              oubp.isOpen = true;
            } else {
              oubp.isOpen = false;
            }
          }
          if (oubp.type === 'ou') {
            oubp.children.forEach((bp) => {
              if (settings.bpOpen.indexOf(bp.id) > -1) {
                bp.isOpen = true;
              } else {
                bp.isOpen = false;
              }
            });
          }
        });

        this.setState({
          filterNodeTree: adTree,
        });
      }
    }

    if (lastChange === actionTypes.InboxGetErrorTree) {
      let adTree = InboxStore.getErrorTree();

      let errorDocCount = 0;
      adTree.forEach((tree) => {
        errorDocCount = errorDocCount + tree.count;
      });

      // if (adTree.length > 0) {
      //   this.setState({
      //     displayErrors: true,
      //   });
      // }

      this.setState({
        errorNodeTree: adTree,
        errorTotalDocCount: errorDocCount,
        fetchingErrorTree: false,
      });

      if (settings !== null) {
        adTree.forEach((ou) => {
          if (ou.type === 'ou') {
            if (settings.ouOpen.indexOf(ou.id) > -1) {
              ou.isOpen = true;
            } else {
              ou.isOpen = false;
            }
          }
        });

        adTree.forEach((oubp) => {
          if (oubp.type === 'bp') {
            if (settings.bpOpen.indexOf(oubp.id) > -1) {
              oubp.isOpen = true;
            } else {
              oubp.isOpen = false;
            }
          }
          if (oubp.type === 'ou') {
            oubp.children.forEach((bp) => {
              if (settings.bpOpen.indexOf(bp.id) > -1) {
                bp.isOpen = true;
              } else {
                bp.isOpen = false;
              }
            });
          }
        });

        this.setState({
          errorNodeTree: adTree,
        });
      }
    }

    if (lastChange === actionTypes.InboxGetDeletedTree) {
      let adTree = InboxStore.getDeletedTree();

      let deletedDocCount = 0;
      adTree.forEach((tree) => {
        deletedDocCount = deletedDocCount + tree.count;
      });

      // if (adTree.length > 0){
      //   this.setState({
      //     displayDeleted: true,
      //   });
      // }

      this.setState({
        deletedNodeTree: adTree,
        deletedTotalDocCount: deletedDocCount,
        fetchingDeletedTree: false,
      });

      if (settings !== null) {
        adTree.forEach((ou) => {
          if (ou.type === 'ou') {
            if (settings.ouOpen.indexOf(ou.id) > -1) {
              ou.isOpen = true;
            } else {
              ou.isOpen = false;
            }
          }
        });

        adTree.forEach((oubp) => {
          if (oubp.type === 'bp') {
            if (settings.bpOpen.indexOf(oubp.id) > -1) {
              oubp.isOpen = true;
            } else {
              oubp.isOpen = false;
            }
          }
          if (oubp.type === 'ou') {
            oubp.children.forEach((bp) => {
              if (settings.bpOpen.indexOf(bp.id) > -1) {
                bp.isOpen = true;
              } else {
                bp.isOpen = false;
              }
            });
          }
        });

        this.setState({
          deletedNodeTree: adTree,
        });
      }
    }

    if (lastChange === actionTypes.InboxGetImageReviewTree) {
      let adTree = InboxStore.getImageReviewTree();

      let imageReviewDocCount = 0;
      adTree.forEach((tree) => {
        imageReviewDocCount = imageReviewDocCount + tree.count;
      });

      // if (adTree.length > 0) {
      //   this.setState({
      //     displayImageReview: true,
      //   });
      // }

      this.setState({
        imageReviewNodeTree: adTree,
        imageReviewTotalDocCount: imageReviewDocCount,
        fetchingImageReviewTree: false,
      });

      if (settings !== null) {
        adTree.forEach((ou) => {
          if (ou.type === 'ou') {
            if (settings.ouOpen.indexOf(ou.id) > -1) {
              ou.isOpen = true;
            } else {
              ou.isOpen = false;
            }
          }
        });

        adTree.forEach((oubp) => {
          if (oubp.type === 'bp') {
            if (settings.bpOpen.indexOf(oubp.id) > -1) {
              oubp.isOpen = true;
            } else {
              oubp.isOpen = false;
            }
          }
          if (oubp.type === 'ou') {
            oubp.children.forEach((bp) => {
              if (settings.bpOpen.indexOf(bp.id) > -1) {
                bp.isOpen = true;
              } else {
                bp.isOpen = false;
              }
            });
          }
        });

        this.setState({
          imageReviewNodeTree: adTree,
        });
      }
    }
  }

  refreshNode(node) {}

  resizeH() {
    this.setState({ h: window.innerHeight - 142 });
  }

  copyNode(node) {
    var newNode = {
      id: node.id,
      type: node.type,
      name: node.name,
      isOpen: false,
      isFav: node.isFav,
      count: node.count,
      children: [],
    };
    node.children.forEach((n) => {
      newNode.children.push(this.copyNode(n));
    });
    return newNode;
  }

  getADSettings() {
    var settings = null;
    try {
      settings = JSON.parse(localStorage.getItem('ADSettings'));
    } catch {}

    if (settings === null) {
      settings = {
        fav: [],
        favOpen: [],
        ouOpen: [],
        bpOpen: [],
      };
    }
    return settings;
  }

  toggleNode(node, type) {
    if (node.type === 'bp') {
      this.props.LoadBPClick(node.id, type);
    }
    var settings = this.getADSettings();
    if (node.type === 'bp') {
      let idx = settings.bpOpen.indexOf(node.id);
      if (idx === -1 && !node.isOpen) {
        settings.bpOpen.push(node.id);
      } else if (idx > -1 && node.isOpen) {
        settings.bpOpen.splice(idx, 1);
      }
    }
    if (node.type === 'ou') {
      let idx2 = settings.ouOpen.indexOf(node.id);
      if (idx2 === -1 && !node.isOpen) {
        settings.ouOpen.push(node.id);
      } else if (idx2 > -1 && node.isOpen) {
        settings.ouOpen.splice(idx2, 1);
      }
    }
    localStorage.setItem('ADSettings', JSON.stringify(settings));

    let tree = undefined;
    switch (type) {
      case 'queues':
        tree = this.state.queueNodeTree;
        break;
      case 'filters':
        tree = this.state.filterNodeTree;
        break;
      case 'errors':
        tree = this.state.errorNodeTree;
        break;
      case 'deleted':
        tree = this.state.deletedNodeTree;
        break;
      case 'imageReview':
        tree = this.state.imageReviewNodeTree;
        break;
      default:
        break;
    }

    let node1 = tree.find((n1) => n1.type === node.type && n1.id === node.id);
    if (node1) {
      node1.isOpen = !node1.isOpen;
    } else {
      // search children
      tree.forEach((ou) => {
        if (ou.type === 'ou') {
          var node2 = ou.children.find(
            (n2) => n2.type === node.type && n2.id === node.id
          );
          if (node2) {
            node2.isOpen = !node2.isOpen;
          }
        }
      });
    }

    switch (type) {
      case 'queues':
        this.setState({ queueNodeTree: tree });
        break;
      case 'filters':
        this.setState({ filterNodeTree: tree });
        break;
      case 'errors':
        this.setState({ errorNodeTree: tree });
        break;
      case 'deleted':
        this.setState({ deletedNodeTree: tree });
        break;
      case 'imageReview':
        this.setState({ imageReviewNodeTree: tree });
        break;
      default:
        break;
    }
    this.setState({ nodeTree: tree });
  }

  toggleFilterNode(node, filter) {
    if (node.type === 'bp') {
      this.props.LoadBPClick(node.id, 'filters-' + filter.id);
    }
    var settings = this.getADSettings();
    if (node.type === 'bp') {
      let idx = settings.bpOpen.indexOf(node.id);
      if (idx === -1 && !node.isOpen) {
        settings.bpOpen.push(node.id);
      } else if (idx > -1 && node.isOpen) {
        settings.bpOpen.splice(idx, 1);
      }
    }
    if (node.type === 'ou') {
      let idx = settings.ouOpen.indexOf(node.id);
      if (idx === -1 && !node.isOpen) {
        settings.ouOpen.push(node.id);
      } else if (idx > -1 && node.isOpen) {
        settings.ouOpen.splice(idx, 1);
      }
    }
    localStorage.setItem('ADSettings', JSON.stringify(settings));

    var tree = filter.tree;

    var node1 = tree.find((n1) => n1.type === node.type && n1.id === node.id);
    if (node1) {
      node1.isOpen = !node1.isOpen;
    } else {
      // search children
      tree.forEach((ou) => {
        if (ou.type === 'ou') {
          var node2 = ou.children.find(
            (n2) => n2.type === node.type && n2.id === node.id
          );
          if (node2) {
            node2.isOpen = !node2.isOpen;
          }
        }
      });
    }

    var indexOfObject = this.state.filterNodeTree.findIndex((object) => {
      return object.name === filter.name;
    });

    var list = this.state.filterNodeTree;
    list[indexOfObject].tree = tree;

    this.setState({ filterNodeTree: list });
  }

  nodeMouseUp(e) {}

  displayMyQueues(e) {
    if (this.state.displayMyQueues) {
      this.setState({ displayMyQueues: false });
    } else {
      this.setState({ displayMyQueues: true });
    }
  }

  displayMyFilters(e) {
    if (this.state.displayMyFilters) {
      this.setState({ displayMyFilters: false });
    } else {
      this.setState({ displayMyFilters: true });
    }
  }

  displayErrors(e) {
    if (this.state.displayErrors) {
      this.setState({ displayErrors: false });
    } else {
      this.setState({ displayErrors: true });
    }
  }

  displayDeleted(e) {
    if (this.state.displayDeleted) {
      this.setState({ displayDeleted: false });
    } else {
      this.setState({ displayDeleted: true });
    }
  }

  displayImageReview(e) {
    if (this.state.displayImageReview) {
      this.setState({ displayImageReview: false });
    } else {
      this.setState({ displayImageReview: true });
    }
  }

  reloadQueues() {

    this.setState({fetchingQueueTree: true, displayMyQueues: false});
    GetQueueTree();
  }

  reloadFilters() {
    this.setState({fetchingFilterTree: true, displayMyFilters: false});
    GetFilterTree();
  }

  reloadErrors() {
    this.setState({fetchingErrorTree: true, displayErrors: false});
    GetErrorTree();
  }

  reloadDeleted() {
    this.setState({fetchingDeletedTree: true, displayDeleted: false});
    GetDeletedTree();
  }

  reloadImageReview() {
    this.setState({fetchingImageReviewTree: true, displayImageReview: false });
    GetImageReviewTree();
  }

  render() {
    return (
      <div
        className='d-flex flex-column pt-3 ps-3 text-white bg-dark'
        style={{ maxHeight: '100%', width: '320px' }}
      >
        <Link
          to='/Inbox'
          className='d-flex align-items-center mb-3 mb-md-0 me-md-auto text-white text-decoration-none'
        >
          <span className='fs-4'>
            <FontAwesomeIcon
              style={{ color: '#39A937' }}
              icon={solid('inbox')}
            />
            {'   '}
            Inbox
          </span>
        </Link>
        <hr />
        <div
          className='pe-3 coolScroll'
          style={{ maxHeight: `${this.state.h}px`, overflow: 'auto' }}
        >
          <ul className='list-group active-documents'>
            <Link
              to='/Filters'
              className='d-flex align-items-center mb-3 mb-md-0 me-md-auto text-white text-decoration-none'
            >
              <li className='list-group-item d-flex justify-content-between aligh-items-start'>
                <div className='me-auto'>
                  <FontAwesomeIcon
                    style={{ color: 'green' }}
                    icon={solid('plus')}
                  />{' '}
                  <strong>Manage My Filters</strong>
                </div>
              </li>
            </Link>
            <hr />
            <li className='list-group-item d-flex justify-content-between aligh-items-start'>
              <div className='me-auto' >
                <span onClick={(s) => this.displayMyQueues()}>
                <FontAwesomeIcon icon={solid('inbox')} />{' '}
                <strong>My Queues</strong>{' '}
                </span>
                <span onClick={() => this.reloadQueues()}>
                <FontAwesomeIcon className='redo-icon'  icon={solid('redo')} />
                </span>
              </div>

              {this.state.queueTotalDocCount > 0 && (
                <span
                  className='badge bg-success rounded-pill'
                  style={{ float: 'right' }}
                >
                  {this.state.queueTotalDocCount}
                </span>
              )}
            </li>
            {this.state.fetchingQueueTree === true &&
                <div className='d-flex flex-column align-items-center justify-content-center'>
                <div style={{color: '#8DF5F5', marginBottom: '2px'}}><FontAwesomeIcon icon={solid('circle-notch')} size='2x' spin /></div>
                <div style={{color: '#8DF5F5', fontSize: '10pt', fontFamily: 'Tilt Neon, "Courier New", monospace'}}>Loading Queues</div>
                </div>
              }

            {this.state.displayMyQueues && (
              <>
                {this.state.queueNodeTree.map((node) => (
                  <React.Fragment key={node.id + 100}>
                    {node.type === 'ou' && (
                      <React.Fragment key={node.id}>
                        <li className='list-group-item d-flex justify-content-between aligh-items-start'>
                          <div
                            className='me-auto'
                            onClick={() => this.toggleNode(node, 'queues')}
                          >
                            {node.isOpen && (
                              <FontAwesomeIcon icon={solid('caret-down')} />
                            )}
                            {!node.isOpen && (
                              <FontAwesomeIcon icon={solid('caret-right')} />
                            )}{' '}
                            <FontAwesomeIcon
                              className='orgUnit-icon'
                              icon={solid('folder')}
                            />{' '}
                            {node.name}
                          </div>
                          <span className='badge bg-success rounded-pill'>
                            {node.count}
                          </span>
                        </li>
                        {node.isOpen && (
                          <div className='ms-3'>
                            {node.children.map((child) => (
                              <React.Fragment key={child.id}>
                                <li className='list-group-item d-flex justify-content-between aligh-items-start'>
                                  <div
                                    className='me-auto'
                                    onClick={() =>
                                      this.toggleNode(child, 'queues')
                                    }
                                  >
                                    {child.isOpen && (
                                      <FontAwesomeIcon
                                        icon={solid('caret-down')}
                                      />
                                    )}
                                    {!child.isOpen && (
                                      <FontAwesomeIcon
                                        icon={solid('caret-right')}
                                      />
                                    )}{' '}
                                    {child.name}
                                  </div>
                                  <span>
                                    {!child.isFav && (
                                      <FontAwesomeIcon
                                        onClick={() =>
                                          this.toggleFavorite(child)
                                        }
                                        style={{ color: '#9C9C9C' }}
                                        icon={regular('star')}
                                      />
                                    )}
                                    {child.isFav && (
                                      <FontAwesomeIcon
                                        style={{ color: '#EAE83A' }}
                                        onClick={() =>
                                          this.toggleFavorite(child)
                                        }
                                        icon={solid('star')}
                                      />
                                    )}{' '}
                                    <span className='badge bg-success rounded-pill'>
                                      {child.count}
                                    </span>
                                  </span>
                                </li>
                                {child.isOpen && (
                                  <div className='ms-3'>
                                    <ul className='list-group active-documents'>
                                      {child.children.map((step) => (
                                        <li
                                          key={step.id}
                                          className='list-group-item d-flex justify-content-between aligh-items-start'
                                          onClick={(s) =>
                                            this.props.LoadStepClick(
                                              step.id,
                                              child.id,
                                              'queues'
                                            )
                                          }
                                        >
                                          <div
                                            className='me-auto'
                                            style={{
                                              overflow: 'hidden',
                                              textOverflow: 'ellipsis',
                                            }}
                                          >
                                            {step.stepType === 'User' && (
                                              <span>
                                                <FontAwesomeIcon
                                                  icon={solid('user')}
                                                />{' '}
                                                {step.name}
                                              </span>
                                            )}
                                            {step.stepType !== 'User' && (
                                              <span
                                                style={{ color: '#C1C1C1' }}
                                              >
                                                <FontAwesomeIcon
                                                  icon={solid('cog')}
                                                />{' '}
                                                {step.name}
                                              </span>
                                            )}
                                          </div>
                                          {step.count > 0 && (
                                            <span className='badge bg-success rounded-pill'>
                                              {step.count}
                                            </span>
                                          )}
                                        </li>
                                      ))}
                                    </ul>
                                  </div>
                                )}
                              </React.Fragment>
                            ))}
                          </div>
                        )}
                      </React.Fragment>
                    )}

                    {node.type === 'bp' && (
                      <React.Fragment key={node.id}>
                        <li className='list-group-item d-flex justify-content-between aligh-items-start'>
                          <div
                            className='me-auto'
                            onClick={() => this.toggleNode(node, 'queues')}
                          >
                            {node.isOpen && (
                              <FontAwesomeIcon icon={solid('caret-down')} />
                            )}
                            {!node.isOpen && (
                              <FontAwesomeIcon icon={solid('caret-right')} />
                            )}{' '}
                            {node.name}
                          </div>
                          <span>
                            {' '}
                            {!node.isFav && (
                              <FontAwesomeIcon
                                onClick={() => this.toggleFavorite(node)}
                                style={{ color: '#9C9C9C' }}
                                icon={regular('star')}
                              />
                            )}
                            {node.isFav && (
                              <FontAwesomeIcon
                                style={{ color: '#EAE83A' }}
                                onClick={() => this.toggleFavorite(node)}
                                icon={solid('star')}
                              />
                            )}{' '}
                            <span className='badge bg-success rounded-pill'>
                              {node.count}
                            </span>
                          </span>
                        </li>
                        {node.isOpen && (
                          <div className='ms-3'>
                            <ul className='list-group active-documents'>
                              {node.children.map((step) => (
                                <li
                                  key={step.id}
                                  className='list-group-item d-flex justify-content-between aligh-items-start'
                                  onClick={() =>
                                    this.props.LoadStepClick(
                                      step.id,
                                      node.id,
                                      'queues'
                                    )
                                  }
                                >
                                  <div
                                    className='me-auto'
                                    style={{ overflow: 'hidden' }}
                                  >
                                    {step.stepType === 'User' && (
                                      <span>
                                        <FontAwesomeIcon icon={solid('user')} />{' '}
                                        {step.name}
                                      </span>
                                    )}
                                    {step.stepType !== 'User' && (
                                      <span style={{ color: '#C1C1C1' }}>
                                        <FontAwesomeIcon icon={solid('cog')} />{' '}
                                        {step.name}
                                      </span>
                                    )}
                                  </div>
                                  {step.count > 0 && (
                                    <span className='badge bg-success rounded-pill'>
                                      {step.count}
                                    </span>
                                  )}
                                </li>
                              ))}
                            </ul>
                          </div>
                        )}
                      </React.Fragment>
                    )}
                  </React.Fragment>
                ))}
              </>
            )}

            <li className='list-group-item d-flex justify-content-between aligh-items-start'>
              <div className='me-auto' >
                <span onClick={(s) => this.displayMyFilters()}>
                <FontAwesomeIcon icon={solid('filter')} />{' '}
                <strong>My Filters</strong>{' '}
                </span>
                <span onClick={() => this.reloadFilters()}>
                <FontAwesomeIcon className='redo-icon' icon={solid('redo')} />
                </span>
              </div>
              {this.state.filterTotalDocCount > 0 && (
                <span
                  className='badge bg-success rounded-pill'
                  style={{ float: 'right' }}
                >
                  {this.state.filterTotalDocCount}
                </span>
              )}
            </li>
            {this.state.fetchingFilterTree === true &&
                <div className='d-flex flex-column align-items-center justify-content-center'>
                <div style={{color: '#8DF5F5', marginBottom: '2px'}}><FontAwesomeIcon icon={solid('circle-notch')} size='2x' spin /></div>
                <div style={{color: '#8DF5F5', fontSize: '10pt', fontFamily: 'Tilt Neon, "Courier New", monospace'}}>Loading Filters</div>
                </div>
              }
            {this.state.displayMyFilters && (
              <>
                {this.state.filterNodeTree.map((filter) => (
                  <>
                    <div className='me-auto'>
                      {' '}
                      <FontAwesomeIcon
                        className='orgUnit-icon'
                        icon={solid('filter')}
                      />{' '}
                      {filter.name}
                    </div>
                    {filter.tree.map((node) => (
                      <React.Fragment key={node.id + 100}>
                        {node.type === 'ou' && (
                          <React.Fragment key={node.id}>
                            <li className='list-group-item d-flex justify-content-between aligh-items-start'>
                              <div
                                className='me-auto'
                                onClick={() =>
                                  this.toggleFilterNode(node, filter)
                                }
                              >
                                {node.isOpen && (
                                  <FontAwesomeIcon icon={solid('caret-down')} />
                                )}
                                {!node.isOpen && (
                                  <FontAwesomeIcon
                                    icon={solid('caret-right')}
                                  />
                                )}{' '}
                                <FontAwesomeIcon
                                  className='orgUnit-icon'
                                  icon={solid('folder')}
                                />{' '}
                                {node.name}
                              </div>
                              <span className='badge bg-success rounded-pill'>
                                {node.count}
                              </span>
                            </li>
                            {node.isOpen && (
                              <div className='ms-3'>
                                {node.children.map((child) => (
                                  <React.Fragment key={child.id}>
                                    <li className='list-group-item d-flex justify-content-between aligh-items-start'>
                                      <div
                                        className='me-auto'
                                        onClick={() =>
                                          this.toggleFilterNode(child, filter)
                                        }
                                      >
                                        {child.isOpen && (
                                          <FontAwesomeIcon
                                            icon={solid('caret-down')}
                                          />
                                        )}
                                        {!child.isOpen && (
                                          <FontAwesomeIcon
                                            icon={solid('caret-right')}
                                          />
                                        )}{' '}
                                        {child.name}
                                      </div>
                                      <span>
                                        {!child.isFav && (
                                          <FontAwesomeIcon
                                            onClick={() =>
                                              this.toggleFavorite(child)
                                            }
                                            style={{ color: '#9C9C9C' }}
                                            icon={regular('star')}
                                          />
                                        )}
                                        {child.isFav && (
                                          <FontAwesomeIcon
                                            style={{ color: '#EAE83A' }}
                                            onClick={() =>
                                              this.toggleFavorite(child)
                                            }
                                            icon={solid('star')}
                                          />
                                        )}{' '}
                                        <span className='badge bg-success rounded-pill'>
                                          {child.count}
                                        </span>
                                      </span>
                                    </li>
                                    {child.isOpen && (
                                      <div className='ms-3'>
                                        <ul className='list-group active-documents'>
                                          {child.children.map((step) => (
                                            <li
                                              key={step.id}
                                              className='list-group-item d-flex justify-content-between aligh-items-start'
                                              onClick={(s) =>
                                                this.props.LoadStepClick(
                                                  step.id,
                                                  child.id,
                                                  'filters-' + filter.id
                                                )
                                              }
                                            >
                                              <div
                                                className='me-auto'
                                                style={{
                                                  overflow: 'hidden',
                                                  textOverflow: 'ellipsis',
                                                }}
                                              >
                                                {step.stepType === 'User' && (
                                                  <span>
                                                    <FontAwesomeIcon
                                                      icon={solid('user')}
                                                    />{' '}
                                                    {step.name}
                                                  </span>
                                                )}
                                                {step.stepType !== 'User' && (
                                                  <span
                                                    style={{ color: '#C1C1C1' }}
                                                  >
                                                    <FontAwesomeIcon
                                                      icon={solid('cog')}
                                                    />{' '}
                                                    {step.name}
                                                  </span>
                                                )}
                                              </div>
                                              {step.count > 0 && (
                                                <span className='badge bg-success rounded-pill'>
                                                  {step.count}
                                                </span>
                                              )}
                                            </li>
                                          ))}
                                        </ul>
                                      </div>
                                    )}
                                  </React.Fragment>
                                ))}
                              </div>
                            )}
                          </React.Fragment>
                        )}

                        {node.type === 'bp' && (
                          <React.Fragment key={node.id}>
                            <li className='list-group-item d-flex justify-content-between aligh-items-start'>
                              <div
                                className='me-auto'
                                onClick={() =>
                                  this.toggleFilterNode(node, filter)
                                }
                              >
                                {node.isOpen && (
                                  <FontAwesomeIcon icon={solid('caret-down')} />
                                )}
                                {!node.isOpen && (
                                  <FontAwesomeIcon
                                    icon={solid('caret-right')}
                                  />
                                )}{' '}
                                {node.name}
                              </div>
                              <span>
                                {' '}
                                {!node.isFav && (
                                  <FontAwesomeIcon
                                    onClick={() => this.toggleFavorite(node)}
                                    style={{ color: '#9C9C9C' }}
                                    icon={regular('star')}
                                  />
                                )}
                                {node.isFav && (
                                  <FontAwesomeIcon
                                    style={{ color: '#EAE83A' }}
                                    onClick={() => this.toggleFavorite(node)}
                                    icon={solid('star')}
                                  />
                                )}{' '}
                                <span className='badge bg-success rounded-pill'>
                                  {node.count}
                                </span>
                              </span>
                            </li>
                            {node.isOpen && (
                              <div className='ms-3'>
                                <ul className='list-group active-documents'>
                                  {node.children.map((step) => (
                                    <li
                                      key={step.id}
                                      className='list-group-item d-flex justify-content-between aligh-items-start'
                                      onClick={() =>
                                        this.props.LoadStepClick(
                                          step.id,
                                          node.id,
                                          'filters-' + filter.id
                                        )
                                      }
                                    >
                                      <div
                                        className='me-auto'
                                        style={{ overflow: 'hidden' }}
                                      >
                                        {step.stepType === 'User' && (
                                          <span>
                                            <FontAwesomeIcon
                                              icon={solid('user')}
                                            />{' '}
                                            {step.name}
                                          </span>
                                        )}
                                        {step.stepType !== 'User' && (
                                          <span style={{ color: '#C1C1C1' }}>
                                            <FontAwesomeIcon
                                              icon={solid('cog')}
                                            />{' '}
                                            {step.name}
                                          </span>
                                        )}
                                      </div>
                                      {step.count > 0 && (
                                        <span className='badge bg-success rounded-pill'>
                                          {step.count}
                                        </span>
                                      )}
                                    </li>
                                  ))}
                                </ul>
                              </div>
                            )}
                          </React.Fragment>
                        )}
                      </React.Fragment>
                    ))}
                  </>
                ))}
              </>
            )}

            {this.state.licenseLevel >= 3 && (
              <li className='list-group-item d-flex justify-content-between aligh-items-start'>
                <div className='me-auto' >
                  <span onClick={(s) => this.displayErrors()}>
                  <FontAwesomeIcon icon={solid('exclamation-triangle')} />{' '}
                  <strong>Errors</strong>{' '}
                  </span>
                   <span onClick={this.reloadErrors}>
                  <FontAwesomeIcon className='redo-icon' icon={solid('redo')} />
                  </span>
                </div>
                {this.state.errorTotalDocCount > 0 && (
                  <span
                    className='badge bg-danger rounded-pill'
                    style={{ float: 'right' }}
                  >
                    {this.state.errorTotalDocCount}
                  </span>
                )}
              </li>
            )}
            {this.state.fetchingErrorTree === true &&
                <div className='d-flex flex-column align-items-center justify-content-center'>
                <div style={{color: '#8DF5F5', marginBottom: '2px'}}><FontAwesomeIcon icon={solid('circle-notch')} size='2x' spin /></div>
                <div style={{color: '#8DF5F5', fontSize: '10pt', fontFamily: 'Tilt Neon, "Courier New", monospace'}}>Loading Errors</div>
                </div>
              }
            {this.state.displayErrors && (
              <>
                {this.state.errorNodeTree.map((node) => (
                  <React.Fragment key={node.id + 100}>
                    {node.type === 'ou' && (
                      <React.Fragment key={node.id}>
                        <li className='list-group-item d-flex justify-content-between aligh-items-start'>
                          <div
                            className='me-auto'
                            onClick={() => this.toggleNode(node, 'errors')}
                          >
                            {node.isOpen && (
                              <FontAwesomeIcon icon={solid('caret-down')} />
                            )}
                            {!node.isOpen && (
                              <FontAwesomeIcon icon={solid('caret-right')} />
                            )}{' '}
                            <FontAwesomeIcon
                              className='orgUnit-icon'
                              icon={solid('folder')}
                            />{' '}
                            {node.name}
                          </div>
                          <span className='badge bg-danger rounded-pill'>
                            {node.count}
                          </span>
                        </li>
                        {node.isOpen && (
                          <div className='ms-3'>
                            {node.children.map((child) => (
                              <React.Fragment key={child.id}>
                                <li className='list-group-item d-flex justify-content-between aligh-items-start'>
                                  <div
                                    className='me-auto'
                                    onClick={() =>
                                      this.toggleNode(child, 'errors')
                                    }
                                  >
                                    {child.isOpen && (
                                      <FontAwesomeIcon
                                        icon={solid('caret-down')}
                                      />
                                    )}
                                    {!child.isOpen && (
                                      <FontAwesomeIcon
                                        icon={solid('caret-right')}
                                      />
                                    )}{' '}
                                    {child.name}
                                  </div>
                                  <span>
                                    <span className='badge bg-danger rounded-pill'>
                                      {child.count}
                                    </span>
                                  </span>
                                </li>
                                {child.isOpen && (
                                  <div className='ms-3'>
                                    <ul className='list-group active-documents'>
                                      {child.children.map((step) => (
                                        <li
                                          key={step.id}
                                          className='list-group-item d-flex justify-content-between aligh-items-start'
                                          onClick={(s) =>
                                            this.props.LoadStepClick(
                                              step.id,
                                              child.id,
                                              'errors'
                                            )
                                          }
                                        >
                                          <div
                                            className='me-auto'
                                            style={{
                                              overflow: 'hidden',
                                              textOverflow: 'ellipsis',
                                            }}
                                          >
                                            {step.stepType === 'User' && (
                                              <span>
                                                <FontAwesomeIcon
                                                  icon={solid('user')}
                                                />{' '}
                                                {step.name}
                                              </span>
                                            )}
                                            {step.stepType !== 'User' && (
                                              <span
                                                style={{ color: '#C1C1C1' }}
                                              >
                                                <FontAwesomeIcon
                                                  icon={solid('cog')}
                                                />{' '}
                                                {step.name}
                                              </span>
                                            )}
                                          </div>
                                          {step.count > 0 && (
                                            <span className='badge bg-danger rounded-pill'>
                                              {step.count}
                                            </span>
                                          )}
                                        </li>
                                      ))}
                                    </ul>
                                  </div>
                                )}
                              </React.Fragment>
                            ))}
                          </div>
                        )}
                      </React.Fragment>
                    )}

                    {node.type === 'bp' && (
                      <React.Fragment key={node.id}>
                        <li className='list-group-item d-flex justify-content-between aligh-items-start'>
                          <div
                            className='me-auto'
                            onClick={() => this.toggleNode(node, 'errors')}
                          >
                            {node.isOpen && (
                              <FontAwesomeIcon icon={solid('caret-down')} />
                            )}
                            {!node.isOpen && (
                              <FontAwesomeIcon icon={solid('caret-right')} />
                            )}{' '}
                            {node.name}
                          </div>
                          <span>
                            {' '}
                            {!node.isFav && (
                              <FontAwesomeIcon
                                onClick={() => this.toggleFavorite(node)}
                                style={{ color: '#9C9C9C' }}
                                icon={regular('star')}
                              />
                            )}
                            {node.isFav && (
                              <FontAwesomeIcon
                                style={{ color: '#EAE83A' }}
                                onClick={() => this.toggleFavorite(node)}
                                icon={solid('star')}
                              />
                            )}{' '}
                            <span className='badge bg-error rounded-pill'>
                              {node.count}
                            </span>
                          </span>
                        </li>
                        {node.isOpen && (
                          <div className='ms-3'>
                            <ul className='list-group active-documents'>
                              {node.children.map((step) => (
                                <li
                                  key={step.id}
                                  className='list-group-item d-flex justify-content-between aligh-items-start'
                                  onClick={() =>
                                    this.props.LoadStepClick(
                                      step.id,
                                      node.id,
                                      'errors'
                                    )
                                  }
                                >
                                  <div
                                    className='me-auto'
                                    style={{ overflow: 'hidden' }}
                                  >
                                    {step.stepType === 'User' && (
                                      <span>
                                        <FontAwesomeIcon icon={solid('user')} />{' '}
                                        {step.name}
                                      </span>
                                    )}
                                    {step.stepType !== 'User' && (
                                      <span style={{ color: '#C1C1C1' }}>
                                        <FontAwesomeIcon icon={solid('cog')} />{' '}
                                        {step.name}
                                      </span>
                                    )}
                                  </div>
                                  {step.count > 0 && (
                                    <span className='badge bg-danger rounded-pill'>
                                      {step.count}
                                    </span>
                                  )}
                                </li>
                              ))}
                            </ul>
                          </div>
                        )}
                      </React.Fragment>
                    )}
                  </React.Fragment>
                ))}
              </>
            )}

            {this.state.licenseLevel >= 3 && (
              <li className='list-group-item d-flex justify-content-between aligh-items-start'>
                <div className='me-auto' >
                  <span onClick={(s) => this.displayDeleted()}>
                  <FontAwesomeIcon icon={solid('trash')} />{' '}
                  <strong>Deleted</strong>{' '}
                  </span>
                  <span onClick={this.reloadDeleted}>
                  <FontAwesomeIcon className='redo-icon' icon={solid('redo')} />
                  </span>
                </div>
                {this.state.deletedTotalDocCount > 0 && (
                  <span
                    className='badge bg-primary rounded-pill'
                    style={{ float: 'right' }}
                  >
                    {this.state.deletedTotalDocCount}
                  </span>
                )}
              </li>
            )}
            {this.state.fetchingDeletedTree === true &&
                <div className='d-flex flex-column align-items-center justify-content-center'>
                <div style={{color: '#8DF5F5', marginBottom: '2px'}}><FontAwesomeIcon icon={solid('circle-notch')} size='2x' spin /></div>
                <div style={{color: '#8DF5F5', fontSize: '10pt', fontFamily: 'Tilt Neon, "Courier New", monospace'}}>Loading Deleted docs</div>
                </div>
              }
            {this.state.displayDeleted && (
              <>
                {this.state.deletedNodeTree.map((node) => (
                  <React.Fragment key={node.id + 100}>
                    {node.type === 'ou' && (
                      <React.Fragment key={node.id}>
                        <li className='list-group-item d-flex justify-content-between aligh-items-start'>
                          <div
                            className='me-auto'
                            onClick={() => this.toggleNode(node, 'deleted')}
                          >
                            {node.isOpen && (
                              <FontAwesomeIcon icon={solid('caret-down')} />
                            )}
                            {!node.isOpen && (
                              <FontAwesomeIcon icon={solid('caret-right')} />
                            )}{' '}
                            <FontAwesomeIcon
                              className='orgUnit-icon'
                              icon={solid('folder')}
                            />{' '}
                            {node.name}
                          </div>
                          <span className='badge bg-primary rounded-pill'>
                            {node.count}
                          </span>
                        </li>
                        {node.isOpen && (
                          <div className='ms-3'>
                            {node.children.map((child) => (
                              <React.Fragment key={child.id}>
                                <li className='list-group-item d-flex justify-content-between aligh-items-start'>
                                  <div
                                    className='me-auto'
                                    onClick={() =>
                                      this.toggleNode(child, 'deleted')
                                    }
                                  >
                                    {child.isOpen && (
                                      <FontAwesomeIcon
                                        icon={solid('caret-down')}
                                      />
                                    )}
                                    {!child.isOpen && (
                                      <FontAwesomeIcon
                                        icon={solid('caret-right')}
                                      />
                                    )}{' '}
                                    {child.name}
                                  </div>
                                  <span>
                                    <span className='badge bg-primary rounded-pill'>
                                      {child.count}
                                    </span>
                                  </span>
                                </li>
                                {child.isOpen && (
                                  <div className='ms-3'>
                                    <ul className='list-group active-documents'>
                                      {child.children.map((step) => (
                                        <li
                                          key={step.id}
                                          className='list-group-item d-flex justify-content-between aligh-items-start'
                                          onClick={(s) =>
                                            this.props.LoadStepClick(
                                              step.id,
                                              child.id,
                                              'deleted'
                                            )
                                          }
                                        >
                                          <div
                                            className='me-auto'
                                            style={{
                                              overflow: 'hidden',
                                              textOverflow: 'ellipsis',
                                            }}
                                          >
                                            {step.stepType === 'User' && (
                                              <span>
                                                <FontAwesomeIcon
                                                  icon={solid('user')}
                                                />{' '}
                                                {step.name}
                                              </span>
                                            )}
                                            {step.stepType !== 'User' && (
                                              <span
                                                style={{ color: '#C1C1C1' }}
                                              >
                                                <FontAwesomeIcon
                                                  icon={solid('cog')}
                                                />{' '}
                                                {step.name}
                                              </span>
                                            )}
                                          </div>
                                          {step.count > 0 && (
                                            <span className='badge bg-primary rounded-pill'>
                                              {step.count}
                                            </span>
                                          )}
                                        </li>
                                      ))}
                                    </ul>
                                  </div>
                                )}
                              </React.Fragment>
                            ))}
                          </div>
                        )}
                      </React.Fragment>
                    )}

                    {node.type === 'bp' && (
                      <React.Fragment key={node.id}>
                        <li className='list-group-item d-flex justify-content-between aligh-items-start'>
                          <div
                            className='me-auto'
                            onClick={() => this.toggleNode(node, 'deleted')}
                          >
                            {node.isOpen && (
                              <FontAwesomeIcon icon={solid('caret-down')} />
                            )}
                            {!node.isOpen && (
                              <FontAwesomeIcon icon={solid('caret-right')} />
                            )}{' '}
                            {node.name}
                          </div>
                          <span>
                            {' '}
                            <span className='badge bg-primary rounded-pill'>
                              {node.count}
                            </span>
                          </span>
                        </li>
                        {node.isOpen && (
                          <div className='ms-3'>
                            <ul className='list-group active-documents'>
                              {node.children.map((step) => (
                                <li
                                  key={step.id}
                                  className='list-group-item d-flex justify-content-between aligh-items-start'
                                  onClick={() =>
                                    this.props.LoadStepClick(
                                      step.id,
                                      node.id,
                                      'deleted'
                                    )
                                  }
                                >
                                  <div
                                    className='me-auto'
                                    style={{ overflow: 'hidden' }}
                                  >
                                    {step.stepType === 'User' && (
                                      <span>
                                        <FontAwesomeIcon icon={solid('user')} />{' '}
                                        {step.name}
                                      </span>
                                    )}
                                    {step.stepType !== 'User' && (
                                      <span style={{ color: '#C1C1C1' }}>
                                        <FontAwesomeIcon icon={solid('cog')} />{' '}
                                        {step.name}
                                      </span>
                                    )}
                                  </div>
                                  {step.count > 0 && (
                                    <span className='badge bg-primary rounded-pill'>
                                      {step.count}
                                    </span>
                                  )}
                                </li>
                              ))}
                            </ul>
                          </div>
                        )}
                      </React.Fragment>
                    )}
                  </React.Fragment>
                ))}
              </>
            )}
            {this.state.licenseLevel >= 3 && (
              <li className='list-group-item d-flex justify-content-between aligh-items-start'>
                <div
                  className='me-auto'
                >
                  <span onClick={(s) => this.displayImageReview()}>
                  <FontAwesomeIcon icon={solid('image')} />{' '}
                  <strong>Image Review</strong>{' '}
                  </span>
                  <span onClick={this.reloadImageReview}>
                    <FontAwesomeIcon className='redo-icon' icon={solid('redo')} />
                  </span>
                </div>
                {this.state.imageReviewTotalDocCount > 0 && (
                  <span
                    className='badge bg-imageRev rounded-pill'
                    style={{ float: 'right' }}
                  >
                    {this.state.imageReviewTotalDocCount}
                  </span>
                )}
              </li>
            )}
             {this.state.fetchingImageReviewTree === true &&
                <div className='d-flex flex-column align-items-center justify-content-center'>
                <div style={{color: '#8DF5F5', marginBottom: '2px'}}><FontAwesomeIcon icon={solid('circle-notch')} size='2x' spin /></div>
                <div style={{color: '#8DF5F5', fontSize: '10pt', fontFamily: 'Tilt Neon, "Courier New", monospace'}}>Loading Image Review</div>
                </div>
              }
            {this.state.displayImageReview && (
              <>
                {this.state.imageReviewNodeTree.map((node) => (
                  <React.Fragment key={node.id + 100}>
                    {node.type === 'ou' && (
                      <React.Fragment key={node.id}>
                        <li className='list-group-item d-flex justify-content-between aligh-items-start'>
                          <div
                            className='me-auto'
                            onClick={() => this.toggleNode(node, 'imageReview')}
                          >
                            {node.isOpen && (
                              <FontAwesomeIcon icon={solid('caret-down')} />
                            )}
                            {!node.isOpen && (
                              <FontAwesomeIcon icon={solid('caret-right')} />
                            )}{' '}
                            <FontAwesomeIcon
                              className='orgUnit-icon'
                              icon={solid('folder')}
                            />{' '}
                            {node.name}
                          </div>
                          <span className='badge bg-imageRev rounded-pill'>
                            {node.count}
                          </span>
                        </li>
                        {node.isOpen && (
                          <div className='ms-3'>
                            {node.children.map((child) => (
                              <React.Fragment key={child.id}>
                                <li className='list-group-item d-flex justify-content-between aligh-items-start'>
                                  <div
                                    className='me-auto'
                                    onClick={() =>
                                      this.toggleNode(child, 'imageReview')
                                    }
                                  >
                                    {child.isOpen && (
                                      <FontAwesomeIcon
                                        icon={solid('caret-down')}
                                      />
                                    )}
                                    {!child.isOpen && (
                                      <FontAwesomeIcon
                                        icon={solid('caret-right')}
                                      />
                                    )}{' '}
                                    {child.name}
                                  </div>
                                  <span>
                                    <span className='badge bg-imageRev rounded-pill'>
                                      {child.count}
                                    </span>
                                  </span>
                                </li>
                                {child.isOpen && (
                                  <div className='ms-3'>
                                    <ul className='list-group active-documents'>
                                      {child.children.map((step) => (
                                        <li
                                          key={step.id}
                                          className='list-group-item d-flex justify-content-between aligh-items-start'
                                          onClick={(s) =>
                                            this.props.LoadStepClick(
                                              step.id,
                                              child.id,
                                              'imageReview'
                                            )
                                          }
                                        >
                                          <div
                                            className='me-auto'
                                            style={{
                                              overflow: 'hidden',
                                              textOverflow: 'ellipsis',
                                            }}
                                          >
                                            {step.stepType === 'User' && (
                                              <span>
                                                <FontAwesomeIcon
                                                  icon={solid('user')}
                                                />{' '}
                                                {step.name}
                                              </span>
                                            )}
                                            {step.stepType !== 'User' && (
                                              <span
                                                style={{ color: '#C1C1C1' }}
                                              >
                                                <FontAwesomeIcon
                                                  icon={solid('cog')}
                                                />{' '}
                                                {step.name}
                                              </span>
                                            )}
                                          </div>
                                          {step.count > 0 && (
                                            <span className='badge bg-imageRev rounded-pill'>
                                              {step.count}
                                            </span>
                                          )}
                                        </li>
                                      ))}
                                    </ul>
                                  </div>
                                )}
                              </React.Fragment>
                            ))}
                          </div>
                        )}
                      </React.Fragment>
                    )}

                    {node.type === 'bp' && (
                      <React.Fragment key={node.id}>
                        <li className='list-group-item d-flex justify-content-between aligh-items-start'>
                          <div
                            className='me-auto'
                            onClick={() => this.toggleNode(node, 'imageReview')}
                          >
                            {node.isOpen && (
                              <FontAwesomeIcon icon={solid('caret-down')} />
                            )}
                            {!node.isOpen && (
                              <FontAwesomeIcon icon={solid('caret-right')} />
                            )}{' '}
                            {node.name}
                          </div>
                          <span>
                            {' '}
                            <span className='badge bg-imageRev rounded-pill'>
                              {node.count}
                            </span>
                          </span>
                        </li>
                        {node.isOpen && (
                          <div className='ms-3'>
                            <ul className='list-group active-documents'>
                              {node.children.map((step) => (
                                <li
                                  key={step.id}
                                  className='list-group-item d-flex justify-content-between aligh-items-start'
                                  onClick={() =>
                                    this.props.LoadStepClick(
                                      step.id,
                                      node.id,
                                      'imageReview'
                                    )
                                  }
                                >
                                  <div
                                    className='me-auto'
                                    style={{ overflow: 'hidden' }}
                                  >
                                    {step.stepType === 'User' && (
                                      <span>
                                        <FontAwesomeIcon icon={solid('user')} />{' '}
                                        {step.name}
                                      </span>
                                    )}
                                    {step.stepType !== 'User' && (
                                      <span style={{ color: '#C1C1C1' }}>
                                        <FontAwesomeIcon icon={solid('cog')} />{' '}
                                        {step.name}
                                      </span>
                                    )}
                                  </div>
                                  {step.count > 0 && (
                                    <span className='badge bg-imageRev rounded-pill'>
                                      {step.count}
                                    </span>
                                  )}
                                </li>
                              ))}
                            </ul>
                          </div>
                        )}
                      </React.Fragment>
                    )}
                  </React.Fragment>
                ))}
              </>
            )}
          </ul>
        </div>
      </div>
    );
  }
}

export default InboxSidebar;
