import dispatcher from '../Dispatcher';
import actionTypes from './ActionTypes';
import axios from 'axios';
import * as Globals from '../Globals';
import { Logout } from './LoginActions';
import { PostAlert } from './AlertActions';
import { GetUserSettings } from './DocumentListActions';

export function GetSettingsForUser(userId) {
  axios
    .get(`${Globals.baseUrl}/UserSettings/GetSettingsForUser/${userId}`, {
      headers: {
        'content-type': 'application/json; charset=utf-8',
        Authorization: `Bearer ${Globals.getJwtToken()}`,
      },
    })
    .then((res) => {
      if (res.status === 200) {
        dispatcher.dispatch({
          actionType: actionTypes.GetUserSettings,
          Result: res.data,
        });
      } else if (res.status === 400 || res.status === 401) {
        Logout();
      }
    })
    .catch((res) => {
      //Logout();
    });
}

export function GetCurrentUser(userId) {
  axios
    .get(`${Globals.baseUrl}/UserSettings/GetCurrentUser`, {
      headers: {
        'content-type': 'application/json; charset=utf-8',
        Authorization: `Bearer ${Globals.getJwtToken()}`,
      },
    })
    .then((res) => {
      if (res.status === 200) {
        dispatcher.dispatch({
          actionType: actionTypes.GetCurrentUser,
          Result: res.data,
        });
      } else if (res.status === 400 || res.status === 401) {
        Logout();
      }
    })
    .catch((res) => {
      //Logout();
    });
}

export function SaveUserSettings(
  firstName,
  lastName,
  fontSize,
  quickSearchPriority,
  sendEmailNotifications,
  defaultHome,
) {
  console.log(defaultHome);
  axios
    .post(
      `${Globals.baseUrl}/UserSettings/UpdateUserSettings`,
      {
        FirstName: firstName,
        LastName: lastName,
        FontSize: fontSize,
        QuickSearchPriority: quickSearchPriority,
        SendEmailNotifications: sendEmailNotifications,
        DefaultHome: defaultHome,
      },
      {
        headers: {
          'content-type': 'application/json; charset=utf-8',
          Authorization: `Bearer ${Globals.getJwtToken()}`,
        },
      }
    )
    .then((res) => {
      if (res.status === 200) {
        dispatcher.dispatch({
          actionType: actionTypes.ChangeUserSettings,
          Result: res.data,
        });
        if (res.data === 'Success') {
          PostAlert('success', 'User data updated');
          GetUserSettings();
        }
        if (res.data === 'Error') {
          PostAlert('error', 'Unable to update user data');
          PostAlert('error', res.data.updateMessage);
        }
      } else if (res.status === 400 || res.status === 401) {
        Logout();
      }
    })
    .catch((res) => {});
}
