import { EventEmitter } from 'events';
import ExtenderDispatcher from './ExtenderDispatcher';

const CHANGE_EVENT = 'extenderChange';
let _lastExtender = {
  textVal: '',
  extenderKey: 0,
};

class ExtenderStore extends EventEmitter {
  addChangeListener(callback) {
    this.on(CHANGE_EVENT, callback);
  }

  removeChangeListener(callback) {
    this.removeListener(CHANGE_EVENT, callback);
  }

  emitChange() {
    this.emit(CHANGE_EVENT);
  }

  getLastExtender() {
    return _lastExtender;
  }
}

const store = new ExtenderStore();

ExtenderDispatcher.register((extenderEvent) => {
  _lastExtender = extenderEvent;
  store.emitChange();
});

export default store;
