import React, { useEffect, useState, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';

var dragStartX = -1;
var dragStartY = -1;
var isDrawing = false;
var isDragging = false;
var isDown = false;
var inZone = false;
var drawCapture = '';
var drawCaptureType = 'OCR';
var resizeStart = false;
var isResizing = false;
var colSizeStart = false;
var colSizeColumn = undefined;
var resizeType = '';
var resizeZone = undefined;
var zoneCopy = [];
var dragZone = undefined;
var currentZone = undefined;
var draggingColumn = false;
var currentColumn = undefined;
var showPopup = false;
var columnClickX = 0;
var popupX = 0;
var popupY = 0;
var currentLayer = '';
var currentOCRId = 1;

var dragStartBox = {
  x: 0,
  y: 0,
  width: 0,
  height: 0,
};
var dragStart = {
  x: 0,
  y: 0,
};
var drawBox = {
  x: 0,
  y: 0,
  width: 0,
  height: 0,
};
var captureLayer = [];

var mouse = {
  x: undefined,
  y: undefined,
  downx: undefined,
  downy: undefined,
  isDrawing: false,
  isStartDrag: false,
};
function TemplateImageViewer(props) {
  const canvasRef = useRef();
  //const [viewWidth, setViewWidth] = useState(832);
  const [scale, setScale] = useState(100);
  const [fresh, refresh] = useState(0);

  const mouseMove = (e) => {
    var canvas = document.getElementById('myCanvas');
    var rect = canvas.getBoundingClientRect();
    mouse.x = (e.clientX * 200) / scale - (rect.left * 200) / scale;
    mouse.y = (e.clientY * 200) / scale - (rect.top * 200) / scale;
    var inAZone = false;
    var zonesOnPage = [];
    props.zones.forEach((zone) => {

        if(zone.readFrom === 'start' && zone.page === props.currentPage)
        {

          zonesOnPage.push(zone);
        }
        else if(zone.readFrom === 'first' && zone.page === 1)
          {

            zonesOnPage.push(zone);
          }

        else if(zone.readFrom === 'last' && props.currentPage === props.imageCount)
          {

            zonesOnPage.push(zone);
          }
        else if(zone.readFrom === 'end' && props.currentPage === props.imageCount - zone.page + 1)
        {

            zonesOnPage.push(zone);
        }
        else {

          zonesOnPage.push(zone);
        }

    });

    zonesOnPage.forEach((zone) => {
      if (
        mouse.x >= zone.x &&
        mouse.y >= zone.y &&
        mouse.x <= zone.x + zone.width &&
        mouse.y <= zone.y + zone.height
      ) {
        inAZone = true;
      }
    });
    if (inZone && !inAZone) {
      inZone = false;
      canvas.style.cursor = 'auto';
    }
    if (!inZone && inAZone) {
      inZone = true;
      canvas.style.cursor = 'pointer';
    }

    // are we near an edge?
    var nearLeft = false;
    var nearRight = false;
    var nearTop = false;
    var nearBottom = false;
    var nearColSeparator = false;
    var inTableHeader = false;

    zonesOnPage.forEach((zone) => {

      if (
        mouse.x >= zone.x - 10 &&
        mouse.x < zone.x + 10 &&
        mouse.y >= zone.y &&
        mouse.y <= zone.y + zone.height
      ) {
        nearLeft = true;
      }
      if (
        mouse.y >= zone.y - 5 &&
        mouse.y < zone.y + 5 &&
        mouse.x >= zone.x &&
        mouse.x <= zone.x + zone.width
      ) {
        nearTop = true;
      }
      if (
        mouse.x >= zone.x + zone.width - 5 &&
        mouse.x < zone.x + zone.width + 5 &&
        mouse.y >= zone.y &&
        mouse.y <= zone.y + zone.height
      ) {
        nearRight = true;
      }
      if (
        mouse.y >= zone.y + zone.height - 5 &&
        mouse.y < zone.y + zone.height + 5 &&
        mouse.x >= zone.x &&
        mouse.x <= zone.x + zone.width
      ) {
        nearBottom = true;
      }
      if (
        zone.zoneType === 3 &&
        mouse.y >= zone.y - (24 * 200) / scale &&
        mouse.y < zone.y - 5 &&
        mouse.x > zone.x + 5 &&
        mouse.x < zone.x + zone.width
      ) {
        inTableHeader = true;
      }

      if (zone.zoneType === 3) {
        zone.columns.forEach((col) => {
          if (mouse.y >= zone.y && mouse.y < zone.y + zone.height) {
            if (
              mouse.x > zone.x + col.x + col.width - 8 &&
              mouse.x < zone.x + col.x + col.width + 8
            ) {
              nearColSeparator = true;
              colSizeColumn = col;
            }
          }
        });
        let change = false;
        if (zone.rows === undefined) zone.rows = [];
        zone.rows.forEach((row) => {
          if (row.cells === undefined) row.cells = [];
          row.cells.forEach((cell) => {
            if (
              mouse.y > zone.y + cell.top &&
              mouse.y <= zone.y + cell.top + cell.height &&
              mouse.x > zone.x + cell.left &&
              mouse.x <= zone.x + cell.left + cell.width
            ) {
              if (cell.active === false) {
                cell.active = true;
                change = true;
              }
            } else if (cell.active === true) {
              cell.active = false;
              change = true;
            }
          });
        });
        if (change) {
          let refresher = fresh + 1;
          if (refresher > 100) refresher = 1;
          refresh(refresher);
        }
      }
    });

    if (nearLeft || nearRight) {
      canvas.style.cursor = 'ew-resize';
    }
    if (nearTop || nearBottom) {
      canvas.style.cursor = 'ns-resize';
    }
    if (
      !nearLeft &&
      !nearRight &&
      !nearTop &&
      !nearBottom &&
      inAZone &&
      canvas.style.cursor !== 'pointer'
    ) {
      canvas.style.cursor = 'pointer';
    }

    if (!nearLeft && !nearRight && !nearTop && !nearBottom && !inAZone) {
      canvas.style.cursor = 'auto';
    }

    if (resizeStart && !isResizing) {
      if (
        Math.abs(dragStartX - e.pageX) > 5 ||
        Math.abs(dragStartY - e.pageY) > 5
      ) {
        isResizing = true;
      }
    }

    if (inTableHeader) {
      canvas.style.cursor = 'move';
    }

    if (nearColSeparator) {
      canvas.style.cursor = 'ew-resize';
    }

    if (!isDrawing && isDown && dragZone === undefined) {
      if (
        Math.abs(dragStartX - e.pageX) > 10 ||
        Math.abs(dragStartY - e.pageY) > 10
      ) {
        isDrawing = true;
        drawBox.height = mouse.y - drawBox.y;
        drawBox.width = mouse.x - drawBox.x;
      }
    }

    if (!isDrawing && dragZone !== undefined) {
      if (
        Math.abs(dragStartX - e.pageX) > 10 ||
        Math.abs(dragStartY - e.pageY) > 10
      ) {
        isDragging = true;
      }
    }

    if (colSizeStart && !draggingColumn) {
      if (
        Math.abs(dragStartX - e.pageX) > 5 ||
        Math.abs(dragStartY - e.pageY) > 5
      ) {
        draggingColumn = true;
        isDragging = false;
      }
    }

    if (!isDrawing && !isDown) {
      // check for floating capture zones

      var change = false;
      captureLayer.forEach((z) => {
        if (
          mouse.x > z.x &&
          mouse.y > z.y &&
          mouse.x < z.x + z.width &&
          mouse.y < z.y + z.height &&
          !z.active
        ) {
          z.active = true;
          change = true;
        } else if (z.active) {
          z.active = false;
          change = true;
        }
      });

      if (change) {
        let refresher = fresh + 1;
        if (refresher > 100) refresher = 1;
        refresh(refresher);
      }
    }

    if (draggingColumn) {
      let leftCol = colSizeColumn;
      let rightCol = undefined;
      let t = props.currentZone;
      if (t.zoneType === 3) {
        let idx = t.columns.indexOf(leftCol);
        rightCol = t.columns[idx + 1];
      }
      if (rightCol) {
        let delta = mouse.x - columnClickX;
        let netW = rightCol.width + leftCol.width;
        //leftCol.endingOffset = delta - leftCol.x - t.x;
        leftCol.width = Math.round(columnClickX + delta - t.x - leftCol.x);
        rightCol.x = Math.round(columnClickX + delta - t.x);
        rightCol.width = Math.round(netW - leftCol.width);
        //rightCol.endingOffset = rightCol.width - delta;
        //rightCol.width = rightCol.width - delta;

        let refresher = fresh + 1;
        if (refresher > 100) refresher = 1;
        refresh(refresher);
      }
    }

    if (isDragging && !draggingColumn) {
      var newx = dragStartBox.x + (mouse.x - dragStart.x);
      var newy = dragStartBox.y + (mouse.y - dragStart.y);
      props.updateZoneGeometry(
        dragStartBox.screenId,
        newx,
        dragStartBox.width,
        newy,
        dragStartBox.height
      );
    }

    if (isResizing) {
      if (resizeType === 'l') {
        var newX = mouse.x;
        var newWidth = resizeZone.x + resizeZone.width - mouse.x;

        resizeZone.x = newX;
        resizeZone.width = newWidth;
        props.updateZoneGeometry(
          resizeZone.screenId,
          newX,
          newWidth,
          resizeZone.y,
          resizeZone.height
        );
        //draw();
      }
      if (resizeType === 't') {
        var newY = mouse.y;
        var newHeight = resizeZone.y + resizeZone.height - mouse.y;

        resizeZone.y = newY;
        resizeZone.height = newHeight;
        props.updateZoneGeometry(
          resizeZone.screenId,
          resizeZone.x,
          resizeZone.width,
          resizeZone.y,
          resizeZone.height
        );
        //draw();
      }
      if (resizeType === 'b') {
        //newY = mouse.y;
        newHeight = mouse.y - resizeZone.y;

        resizeZone.height = newHeight;
        props.updateZoneGeometry(
          resizeZone.screenId,
          resizeZone.x,
          resizeZone.width,
          resizeZone.y,
          newHeight
        );
        //draw();
      }
      if (resizeType === 'r') {
        //var newX = mouse.x;
        newWidth = mouse.x - resizeZone.x;

        //resizeZone.x = newX;
        resizeZone.width = newWidth;
        props.updateZoneGeometry(
          resizeZone.screenId,
          resizeZone.x,
          newWidth,
          resizeZone.y,
          resizeZone.height
        );
        //draw();
      }
    }

    if (!isDrawing && !isDragging) {
      if (props.pageData !== null && props.pageData !== undefined) {
        let wordFound = false;
        if(props.captureLayer === 't' || props.captureLayer === 'to')
        {
          let t = props.pageData.textData;

          t.words.forEach((w) => {
            if (
              mouse.x >= w.loc.x &&
              mouse.y >= w.loc.y &&
              mouse.x <= w.loc.x + w.loc.w &&
              mouse.y <= w.loc.y + w.loc.h
            ) {
              if (currentOCRId !== w.ocrId) {
                currentOCRId = w.ocrId;
                let refresher = fresh + 1;
                if (refresher > 100) refresher = 1;
                refresh(refresher);
              }
              wordFound = true;
            }
          });
        }

        if (!wordFound && (props.captureLayer === 'o' || props.captureLayer === 'to')) {
          let t2 = props.pageData.ocrData;

          t2.words.forEach((w) => {
            if (
              mouse.x >= w.loc.x &&
              mouse.y >= w.loc.y &&
              mouse.x <= w.loc.x + w.loc.w &&
              mouse.y <= w.loc.y + w.loc.h
            ) {

              if (currentOCRId !== w.ocrId) {
                currentOCRId = w.ocrId;
                let refresher = fresh + 1;
                if (refresher > 100) refresher = 1;
                refresh(refresher);
              }
              wordFound = true;
            }
          });
        }
        if (!wordFound && currentOCRId !== 0) {
          currentOCRId = 0;
          let refresher = fresh + 1;
          if (refresher > 100) refresher = 1;
          refresh(refresher);
        }
      }
    }

    if (isDrawing) {
      drawBox.height = mouse.y - drawBox.y;
      drawBox.width = mouse.x - drawBox.x;

      // attemp to read from the ocr/text
      var text = '';
      if (props.pageData !== null && props.pageData !== undefined) {
        let t = props.pageData.textData;
        t.words.forEach((w) => {
          if (
            w.loc.x >= drawBox.x &&
            w.loc.y >= drawBox.y &&
            w.loc.x + w.loc.w <= drawBox.x + drawBox.width &&
            w.loc.y + w.loc.h <= drawBox.y + drawBox.height
          ) {
            text = text + ' ' + w.value;
          }
        });
        if (text === '') {
          let t2 = props.pageData.ocrData;
          t2.words.forEach((w) => {
            if (
              w.loc.x >= drawBox.x &&
              w.loc.y >= drawBox.y &&
              w.loc.x + w.loc.w <= drawBox.x + drawBox.width &&
              w.loc.y + w.loc.h <= drawBox.y + drawBox.height
            ) {
              text = text + ' ' + w.value;
            }
          });
        }
      }
      if (text.length > 0) {
        drawCapture = text;
        drawCaptureType = 'Text';
      } else {
        drawCaptureType = 'None';
      }

      let sel = document.getSelection();
      sel.removeAllRanges();

      let refresher = fresh + 1;
      if (refresher > 100) refresher = 1;
      refresh(refresher);
    }
  };

  const mouseDown = (e) => {
    var canvas = document.getElementById('myCanvas');
    var rect = canvas.getBoundingClientRect();
    mouse.x = (e.clientX * 200) / scale - (rect.left * 200) / scale;
    mouse.y = (e.clientY * 200) / scale - (rect.top * 200) / scale;
    zoneCopy.forEach((zone) => {
      if (
        mouse.x >= zone.x &&
        mouse.y >= zone.y &&
        mouse.x <= zone.x + zone.width &&
        mouse.y <= zone.y + zone.height
      ) {
      }
    });

    var nearLeft = false;
    var nearRight = false;
    var nearTop = false;
    var nearBottom = false;
    var targetZone = undefined;
    var zoneActivated = false;
    var nearColSeparator = false;
    zoneCopy.forEach((zone) => {
      if (
        mouse.x >= zone.x - 5 &&
        mouse.x < zone.x + 5 &&
        mouse.y >= zone.y &&
        mouse.y <= zone.y + zone.height
      ) {
        nearLeft = true;
        targetZone = zone;
      }
      if (
        mouse.y >= zone.y - 5 &&
        mouse.y < zone.y + 5 &&
        mouse.x >= zone.x &&
        mouse.x <= zone.x + zone.width
      ) {
        nearTop = true;
        targetZone = zone;
      }
      if (
        mouse.x >= zone.x + zone.width - 5 &&
        mouse.x < zone.x + zone.width + 5 &&
        mouse.y >= zone.y &&
        mouse.y <= zone.y + zone.height
      ) {
        nearRight = true;
        targetZone = zone;
      }
      if (
        mouse.y >= zone.y + zone.height - 5 &&
        mouse.y < zone.y + zone.height + 5 &&
        mouse.x >= zone.x &&
        mouse.x <= zone.x + zone.width
      ) {
        nearBottom = true;
        targetZone = zone;
      }

      if (
        (mouse.y >= zone.y &&
          mouse.x >= zone.x &&
          mouse.y <= zone.y + zone.height &&
          mouse.x <= zone.x + zone.width) ||
        (zone.zoneType === 3 &&
          mouse.y >= zone.y - (24 * 200) / scale &&
          mouse.y < zone.y - 5 &&
          mouse.x > zone.x + 5 &&
          mouse.x < zone.x + zone.width)
      ) {
        //inside the zone.
        if (zone.zoneType === 3) {
          props.setActiveZone(zone);
          zoneActivated = true;
          // inside table zone.
          // which column are we in??
          if (zone.columns) {
            zone.columns.forEach((zc) => {
              if (
                mouse.y >= zc.y + zone.y &&
                mouse.x >= zc.x + zone.x &&
                mouse.y <= zc.y + zone.y + zc.height &&
                mouse.x <= zc.x + zone.x + zc.width
              ) {
                // inside column
                props.setActiveColumn(zc);
                currentColumn = zc;
                zoneActivated = true;
              }
            });
          }
        } else {
          props.setActiveZone(zone);
          zoneActivated = true;
        }
        dragZone = zone;
        dragStart = {
          x: mouse.x,
          y: mouse.y,
        };
        dragStartBox = {
          screenId: zone.screenId,
          x: zone.x,
          y: zone.y,
          height: zone.height,
          width: zone.width,
        };
      }

      if (zone.zoneType === 3) {
        zone.columns.forEach((col) => {
          if (mouse.y >= zone.y && mouse.y < zone.y + zone.height) {
            if (
              mouse.x > zone.x + col.x + col.width - 8 &&
              mouse.x < zone.x + col.x + col.width + 8
            ) {
              nearColSeparator = true;
              columnClickX = mouse.x;
            }
          }
        });
      }
    });
    if (!zoneActivated) {
      showPopup = false;
      props.setActiveZone(undefined);
      dragZone = undefined;
    }

    if (nearLeft) {
      resizeStart = true;
      resizeType = 'l';
      resizeZone = targetZone;
      dragZone = undefined;
    }
    if (nearRight) {
      resizeStart = true;
      resizeType = 'r';
      resizeZone = targetZone;
      dragZone = undefined;
    }
    if (nearTop) {
      resizeStart = true;
      resizeType = 't';
      resizeZone = targetZone;
      dragZone = undefined;
    }
    if (nearBottom) {
      resizeStart = true;
      resizeType = 'b';
      resizeZone = targetZone;
      dragZone = undefined;
    }

    if (nearColSeparator && !nearRight) {
      colSizeStart = true;
    }

    if (!nearLeft && !nearRight && !nearTop && !nearBottom && !inZone) {
      isDown = true;
    }

    dragStartX = e.pageX;
    dragStartY = e.pageY;

    drawBox = {
      x: mouse.x,
      y: mouse.y,
      height: 0,
      width: 0,
    };
  };

  const mouseUp = (e) => {
    if (!isDrawing && !isResizing && inZone) {
      //selectZone = hoverZone;
    }

    if (isDrawing) {
      // release, create new zone
      props.initializeNewZone(drawBox);
    }

    isDown = false;
    isDrawing = false;
    isDragging = false;
    dragZone = undefined;
    if(draggingColumn) {
      props.reCalculate(props.currentZone);
    }
    draggingColumn = false;
    resizeStart = false;
    colSizeStart = false;
    if (isResizing) {
      props.updateZoneGeometry(
        resizeZone.screenId,
        resizeZone.x,
        resizeZone.width,
        resizeZone.y,
        resizeZone.height
      );
    }
    isResizing = false;
  };

  const mouseLeave = (e) => {
    isDrawing = false;
    isDown = false;
    resizeStart = false;
    isResizing = false;
  };

  const contextMenu = (e) => {
    e.preventDefault();

    var canvas = document.getElementById('myCanvas');
    var rect = canvas.getBoundingClientRect();
    mouse.x = (e.clientX * 200) / scale - (rect.left * 200) / scale;
    mouse.y = (e.clientY * 200) / scale - (rect.top * 200) / scale;

    zoneCopy.forEach((zone) => {
      if (
        mouse.y >= zone.y &&
        mouse.x >= zone.x &&
        mouse.y <= zone.y + zone.height &&
        mouse.x <= zone.x + zone.width
      ) {
        showPopup = true;
        popupX = e.clientX - rect.left;
        popupY = e.clientY - rect.top;

        props.setActiveZone(zone);

        if (zone.zoneType === 3) {
          if (zone.columns) {
            zone.columns.forEach((zc) => {
              if (
                mouse.y >= zc.y + zone.y &&
                mouse.x >= zc.x + zone.x &&
                mouse.y <= zone.y + zc.y + zc.height &&
                mouse.x <= zc.x + zone.x + zc.width
              ) {
                // inside column
                columnClickX = mouse.x;
                currentColumn = zc;
              }
            });
          }
        }
      }
    });
    return false;
  };

  const mouseWheel = (e) => {};

  const draw = () => {
    var canvas = canvasRef.current;
    var context = canvas.getContext('2d');

    var image = new Image();

    image.onload = function () {
      context.drawImage(
        image,
        0,
        0,
        (props.imageWidth * scale) / 100,
        (props.imageHeight * scale) / 100
      );
    };
    image.src = props.base64;
    context.drawImage(
      image,
      0,
      0,
      (props.imageWidth * scale) / 100,
      (props.imageHeight * scale) / 100
    );

    //let sel = document.getSelection();
    //sel.removeAllRanges();
  };

  const scaleImage = (e) => {
    setScale(parseInt(e.target.value));
  };

  const createCaptureLayer = () => {
    let newLayer = [];

    if (currentLayer !== props.captureLayer && props.pageData !== null) {
      currentLayer = props.captureLayer;

      if (props.captureLayer === 't' || props.captureLayer === 'to') {
        let t = props.pageData.textData;
        t.words.forEach((w) => {
          let newBox = {
            x: w.loc.x,
            y: w.loc.y,
            height: w.loc.h,
            width: w.loc.w,
            active: false,
            type: 'Text',
            value: w.value,
          };
          newLayer.push(newBox);
        });
      }

      // if (props.captureLayer === 'o' || props.captureLayer === 'to') {
      //   let t = props.pageData.ocrData;
      //   t.words.forEach((w) => {
      //     let newBox = {
      //       x: w.loc.x,
      //       y: w.loc.y,
      //       height: w.loc.h,
      //       width: w.loc.w,
      //       active: false,
      //       type: 'OCR',
      //       value: w.value,
      //     };
      //     newLayer.push(newBox);
      //   });
      // }

      if (props.captureLayer === 'to') {
        // only add ocr boxes that don't overlap
      }

      captureLayer = newLayer;

      let refresher = fresh + 1;
      if (refresher > 100) refresher = 1;
      refresh(refresher);
    }
  };

  const computeClass = (zoneType, screenId) => {
    let currentScreenId = -1;
    if (props.currentZone) {
      currentScreenId = props.currentZone.screenId;
    }
    if (zoneType === parseZoneType('capture') && screenId === currentScreenId)
      return 'te-captureZone-1';
    if (zoneType === parseZoneType('capture') && screenId !== currentScreenId)
      return 'te-captureZone-2';

    if (
      zoneType === parseZoneType('identifier') &&
      screenId === currentScreenId
    )
      return 'te-identifierZone-1';
    if (
      zoneType === parseZoneType('identifier') &&
      screenId !== currentScreenId
    )
      return 'te-identifierZone-2';

    if (zoneType === parseZoneType('split') && screenId === currentScreenId)
      return 'te-splitZone-1';
    if (zoneType === parseZoneType('split') && screenId !== currentScreenId)
      return 'te-splitZone-2';

    if (zoneType === parseZoneType('table') && screenId === currentScreenId)
      return 'te-tableZone-1';
    if (zoneType === parseZoneType('table') && screenId !== currentScreenId)
      return 'te-tableZone-2';

    if (zoneType === parseZoneType('scan') && screenId === currentScreenId)
      return 'te-scanZone-1';
    if (zoneType === parseZoneType('scan') && screenId !== currentScreenId)
      return 'te-scanZone-2';
    return 'te-captureZone-2';
  };

  const computeClass2 = (zoneType) => {
    if (zoneType === parseZoneType('capture')) return 'te-readValue';
    if (zoneType === parseZoneType('identifier')) return 'te-identifierValue';
    if (zoneType === parseZoneType('split')) return 'te-splitValue';
  };

  const computeColumnClass = (screenId) => {
    let currentScreenId = -1;
    if (props.currentColumn) {
      currentScreenId = props.currentColumn.screenId;
    }
    if (screenId === currentScreenId) {
      return 'te-selected-column';
    } else return 'te-column';
  };

  const parseZoneType = (zoneType) => {
    if (zoneType === 'capture') return 0;
    if (zoneType === 'identifier') return 1;
    if (zoneType === 'split') return 2;
    if (zoneType === 'table') return 3;
    if (zoneType === 'scan') return 4;
    return 0;
  };

  const onDeleteZone = () => {
    showPopup = false;
    props.deleteZone(props.currentZone);
  };

  const onSplitColumn = () => {
    showPopup = false;
    props.splitColumn(currentColumn, columnClickX);
  };

  const onDeleteColumn = () => {
    showPopup = false;
    props.deleteColumn(currentColumn);
  };

  const onShowTable = () => {};

  const hidePopup = () => {
    showPopup = false;
  }

  useEffect(() => {
    draw();
    zoneCopy = [...props.zones];
    //createCaptureLayer();
  });

  return (
    <div>
      <div className='d-flex flex-row aligh-items-start te-toolbar2'>
        <input
          type='range'
          tabIndex='-1'
          min='30'
          max='200'
          value={scale}
          className='slider'
          id='myRange'
          onChange={scaleImage}
        />
        <div className='mt-1 ms-1'>{scale}%</div>
        <FontAwesomeIcon
          style={{ cursor: 'pointer' }}
          className='mt-2 ms-3'
          icon={solid('undo')}
        />

        <FontAwesomeIcon
          style={{ cursor: 'pointer' }}
          className='mt-2 ms-3'
          icon={solid('redo')}
        />

        <FontAwesomeIcon
          style={{ cursor: 'pointer', marginLeft: '40px' }}
          className='mt-2'
          icon={solid('angle-double-left')}
          onClick={() => props.onPageNext(1)}
        />
        <FontAwesomeIcon
          style={{ cursor: 'pointer' }}
          className='mt-2 ms-3'
          icon={solid('arrow-left')}
          onClick={() => props.onPageNext(props.currentPage - 1)}
        />
        <div className='mt-1 ms-3'>
          <input
            style={{
              fontSize: '11pt',
              border: 'solid 1px #4C8CAE',
              paddingTop: '0',
              width: '30px',
              paddingLeft: '4px',
            }}
            value={props.currentPage}
            onChange={(e) => {
              var idx = parseInt(e.target.value);
              if (!isNaN(idx)) {
                props.onPageNext(idx);
              }
            }}
            type='text'
          />{' '}
          / {props.imageCount}
        </div>
        <FontAwesomeIcon
          style={{ cursor: 'pointer' }}
          className='mt-2 ms-3'
          icon={solid('arrow-right')}
          onClick={() => props.onPageNext(props.currentPage + 1)}
        />
        <FontAwesomeIcon
          style={{ cursor: 'pointer' }}
          className='mt-2 ms-3'
          icon={solid('angle-double-right')}
          onClick={() => props.onPageNext(props.imageCount)}
        />
        <FontAwesomeIcon
          style={{ cursor: 'pointer', color: '#1F5979' }}
          className='mt-2 ms-4'
          icon={solid('file-image')}

        />
        <span className='mt-1 ms-1 font-weight-bold' style={{fontWeight: 'bold'}}>{props.sampleName}</span>
      </div>
      <div
        id='te-canvas-container'
        className='coolScroll2'
        style={{
          height: `${props.viewHeight}px`,
          maxWidth: `${props.viewWidth}px`,
          overflow: 'auto',
          background: '#4F4F4F',
          padding: '10px',
          position: 'relative',
        }}
      >
        <canvas
          id='myCanvas'
          onMouseLeave={mouseLeave}
          onMouseUp={mouseUp}
          onWheel={mouseWheel}
          onMouseDown={mouseDown}
          onMouseMove={mouseMove}
          onContextMenu={contextMenu}
          ref={canvasRef}
          width={(props.imageWidth * scale) / 100}
          height={(props.imageHeight * scale) / 100}
        ></canvas>

        {isDrawing && (
          <>
            <div
              style={{
                position: 'absolute',
                top: `${(drawBox.y * scale) / 200 + 10}px`,
                left: `${(drawBox.x * scale) / 200 + 10}px`,
                height: `${(drawBox.height * scale) / 200}px`,
                width: `${(drawBox.width * scale) / 200}px`,
                border: '2px dotted #689BD2',
                pointerEvents: 'none',
                backgroundColor: '#AFD6FF',
                opacity: '0.5',
              }}
            />
            {drawCaptureType !== 'None' && (
              <div
                style={{
                  position: 'absolute',
                  zIndex: '100',
                  top: `${((drawBox.y + drawBox.height) * scale) / 200 + 15}px`,
                  left: `${(drawBox.x * scale) / 200 + 10}px`,
                  backgroundColor:
                    drawCaptureType === 'Text' ? '#21991F' : '#CD3A47',
                  pointerEvents: 'none',
                  borderRadius: '10px',
                  color: '#fff',
                  paddingLeft: '6px',
                  paddingRight: '6px',
                  whiteSpace: 'nowrap',
                }}
              >
                {drawCaptureType}: {drawCapture}
              </div>
            )}
          </>
        )}

        {props.captureLayer !== '' &&
          captureLayer.map((capture) => (
            <React.Fragment>
              <div
                style={{
                  position: 'absolute',
                  top: `${(capture.y * scale) / 200 + 10}px`,
                  left: `${(capture.x * scale) / 200 + 10}px`,
                  height: `${(capture.height * scale) / 200}px`,
                  width: `${(capture.width * scale) / 200}px`,
                  border: `2px solid ${
                    capture.type === 'OCR' ? '#AE0327' : '#189404'
                  }`,
                  pointerEvents: 'none',
                  backgroundColor:
                    capture.active === false
                      ? 'transparent'
                      : capture.type === 'OCR'
                      ? '#AE0327'
                      : '#189404',
                  opacity: capture.active === true ? '0.3' : '0.4',
                }}
              />
              {capture.active && (
                <div
                  style={{
                    position: 'absolute',
                    zIndex: '100',
                    top: `${
                      ((capture.y + capture.height) * scale) / 200 + 10
                    }px`,
                    left: `${(capture.x * scale) / 200 + 10}px`,
                    backgroundColor:
                      capture.type === 'Text' ? '#21991F' : '#CD3A47',
                    pointerEvents: 'none',
                    color: '#fff',
                    paddingLeft: '4px',
                    paddingRight: '4px',
                    fontSize: '10pt',
                    whiteSpace: 'nowrap',
                  }}
                >
                  {capture.value}
                </div>
              )}
            </React.Fragment>
          ))}

        {props.zones.map((zone) => (
          <React.Fragment key={zone.screenId}>
            {zone.zoneType === 3 && (
              <div
                className={
                  zone.screenId === props.currentZone.screenId
                    ? 'te-table-gridHeader'
                    : 'te-table-hidden'
                }
                style={{
                  position: 'absolute',
                  top: `${(zone.y * scale) / 200 - 15}px`,
                  left: `${(zone.x * scale) / 200 + 5}px`,
                  height: '26px',
                  width: `${(zone.width * scale) / 200}px`,
                  pointerEvents: 'none',
                }}
              >
                {zone.zoneName}
              </div>
            )}
            {((zone.readFrom === 'start' && zone.page === props.currentPage) ||
              (zone.readFrom === 'end' &&
                zone.page === props.imageCount - props.currentPage + 1) ||
                (zone.readFrom === 'first' && props.currentPage === 1) ||
                (zone.readFrom === 'last' && props.currentPage === props.imageCount) ||
                (zone.readFrom === 'firstv' ) ||
                (zone.readFrom === 'lastv' ) ||
              zone.readFrom === 'all') && zone.zoneType === 0 && zone.screenId === props.currentZone.screenId && (
              <div
                className={
                  zone.screenId === props.currentZone.screenId
                    ? 'te-capture-header'
                    : 'te-table-hidden'
                }
                style={{
                  position: 'absolute',
                  top: `${(zone.y * scale) / 200 - 15}px`,
                  left: `${(zone.x * scale) / 200 + 5}px`,
                  height: '26px',
                  width: `${Math.max((zone.width * scale) / 200, zone.zoneName.length * 11)}px`,
                  pointerEvents: 'none',
                }}
              >
                {zone.zoneName}
              </div>
            )}



            {(((zone.readFrom === 'start' && zone.page === props.currentPage) ||
              (zone.readFrom === 'end' &&
                zone.page === props.imageCount - props.currentPage + 1) ||
                (zone.readFrom === 'first' && props.currentPage === 1) ||
                (zone.readFrom === 'last' && props.currentPage === props.imageCount) ||
                (zone.readFrom === 'firstv' && props.currentPage === zone.pageFound) ||
                (zone.readFrom === 'lastv' && props.currentPage === zone.pageFound) ||
              zone.readFrom === 'all')  ||
              zone.zoneType === 3) && zone.show !== false && (
              <div
                className={computeClass(zone.zoneType, zone.screenId)}
                style={{
                  position: 'absolute',
                  top: `${(zone.y * scale) / 200 + 10}px`,
                  left: `${(zone.x * scale) / 200 + 10}px`,
                  height: `${(zone.height * scale) / 200}px`,
                  width: `${(zone.width * scale) / 200}px`,
                  pointerEvents: 'none',
                }}
              >

                {zone.zoneType === 3 && (
                  <div>
                    {zone.columns !== undefined &&
                      zone.columns.map((col) => (
                        <div
                          key={col.screenId}
                          className={computeColumnClass(col.screenId)}
                          style={{
                            position: 'absolute',
                            top: `${(col.y * scale) / 200}px`,
                            left: `${(col.x * scale) / 200}px`,
                            height: `${(col.height * scale) / 200}px`,
                            width: `${(col.width * scale) / 200}px`,
                          }}
                        ></div>
                      ))}
                  </div>
                )}

                {zone.zoneType === 3 && (
                  <div>
                    {zone.rows !== undefined &&
                      zone.rows.map((row) => (
                        <div key={row.rowId}>
                          {' '}
                          {/*key={row.rowId} className='te-row' style={{position: 'absolute', top: `${row.top * scale / 200}px`, left: `0px`, height: `${row.height * scale / 200 + 5}px`, width:`${zone.width * scale / 200}px`}} >*/}
                          {row.cells.map((cell) => (
                            <div
                              key={cell.id}
                              className='te-row'
                              style={{
                                position: 'absolute',
                                top: `${(cell.top * scale) / 200}px`,
                                left: `${(cell.left * scale) / 200}px`,
                                height: `${(cell.height * scale) / 200 + 5}px`,
                                width: `${(cell.width * scale) / 200}px`,
                              }}
                            >
                              {cell.active && (
                                <span
                                  className='te-cellText'
                                  style={{
                                    position: 'absolute',
                                    top: `${(cell.height * scale) / 200 + 5}px`,
                                    left: '0px',
                                  }}
                                >
                                  {cell.value}
                                </span>
                              )}
                            </div>
                          ))}
                        </div>
                      ))}
                  </div>
                )}
              </div>
            )}

          {(
                (zone.readFrom === 'firstv' && props.currentPage !== zone.pageFound) ||
                (zone.readFrom === 'lastv' && props.currentPage !== zone.pageFound)

              ) && zone.screenId === props.currentZone.screenId && zone.show !== false && (
              <div
                className='te-captureZone-3'
                style={{
                  position: 'absolute',
                  top: `${(zone.y * scale) / 200 + 10}px`,
                  left: `${(zone.x * scale) / 200 + 10}px`,
                  height: `${(zone.height * scale) / 200}px`,
                  width: `${(zone.width * scale) / 200}px`,
                  pointerEvents: 'none',
                }}
              >
              </div>
            )}

            {((zone.readFrom === 'start' && zone.page === props.currentPage) ||
              (zone.readFrom === 'end' &&
                zone.page === props.imageCount - props.currentPage + 1) ||
              (zone.readFrom === 'first' && props.currentPage === 1) ||
              (zone.readFrom === 'last' && props.currentPage === props.imageCount) ||
              (zone.readFrom === 'firstv') ||
                (zone.readFrom === 'lastv') ||
                zone.readFrom === 'all') &&
              zone.screenId === props.currentZone.screenId &&
              (zone.show === undefined || zone.show !== false) &&
              zone.zoneType !== 3 && (
                <div
                  className={computeClass2(zone.zoneType)}
                  style={{
                    position: 'absolute',
                    zIndex: '100',
                    top: `${((zone.y + zone.height) * scale) / 200 + 10}px`,
                    left: `${(zone.x * scale) / 200 + 10}px`,
                    pointerEvents: 'none',
                    fontSize: '10pt',
                    whiteSpace: 'nowrap',
                  }}
                >
                  {zone.value}
                </div>
              )}
            {((zone.readFrom === 'start' && zone.page === props.currentPage) ||
              (zone.readFrom === 'end' &&
                zone.page === props.imageCount - props.currentPage + 1) ||
                (zone.readFrom === 'first' && props.currentPage === 1) ||
                (zone.readFrom === 'last' && props.currentPage === props.imageCount) ||
                (zone.readFrom === 'firstv' ) ||
                (zone.readFrom === 'lastv' ) ||
              zone.readFrom === 'all') &&

              zone.childZone !== undefined && (
                <div
                  className='te-childZone'
                  style={{
                    position: 'absolute',
                    top: `${(zone.childZone.y * scale) / 200 + 10}px`,
                    left: `${(zone.childZone.x * scale) / 200 + 10}px`,
                    height: `${(zone.childZone.height * scale) / 200}px`,
                    width: `${(zone.childZone.width * scale) / 200}px`,
                    pointerEvents: 'none',
                  }}
                />
              )}
          </React.Fragment>
        ))}

        {props.pageData &&
          props.pageData.ocrData &&
          (props.captureLayer === 'o' || props.captureLayer === 'to') &&
          props.pageData.ocrData.words.map((ocrZone) => (
            <React.Fragment key={ocrZone.ocrId}>
              <div
                className='te-ocrZone'
                style={{
                  position: 'absolute',
                  top: `${(ocrZone.loc.y * scale) / 200 + 10}px`,
                  left: `${(ocrZone.loc.x * scale) / 200 + 10}px`,
                  height: `${(ocrZone.loc.h * scale) / 200}px`,
                  width: `${(ocrZone.loc.w * scale) / 200}px`,
                  pointerEvents: 'none',
                }}
              />
              {ocrZone.ocrId === currentOCRId && (
                <span
                  className='te-ocrText'
                  style={{
                    position: 'absolute',
                    top: `${
                      ((ocrZone.loc.y + ocrZone.loc.h) * scale) / 200 + 10
                    }px`,
                    left: `${(ocrZone.loc.x * scale) / 200 + 10}px`,
                  }}
                >
                  {ocrZone.value}
                </span>
              )}
            </React.Fragment>
          ))}

      {props.pageData &&
          props.pageData.textData &&
          (props.captureLayer === 't' || props.captureLayer === 'to') &&
          props.pageData.textData.words.map((ocrZone) => (
            <React.Fragment key={ocrZone.ocrId}>
              <div
                className='te-textZone'
                style={{
                  position: 'absolute',
                  top: `${(ocrZone.loc.y * scale) / 200 + 10}px`,
                  left: `${(ocrZone.loc.x * scale) / 200 + 10}px`,
                  height: `${(ocrZone.loc.h * scale) / 200}px`,
                  width: `${(ocrZone.loc.w * scale) / 200}px`,
                  pointerEvents: 'none',
                }}
              />
              {ocrZone.ocrId === currentOCRId && (
                <span
                  className='te-textText'
                  style={{
                    position: 'absolute',
                    top: `${
                      ((ocrZone.loc.y + ocrZone.loc.h) * scale) / 200 + 10
                    }px`,
                    left: `${(ocrZone.loc.x * scale) / 200 + 10}px`,
                  }}
                >
                  {ocrZone.value}
                </span>
              )}
            </React.Fragment>
          ))}

        {props.zones.map(
          (zoneOffset) =>
            zoneOffset.anchorZoneId !== undefined &&
            zoneOffset.anchorZoneId !== null &&
            zoneOffset.anchorZoneId !== 0 &&
            zoneOffset.show !== false &&
            zoneOffset.offsetY >= 0 &&
            zoneOffset.offsetX >= 0 &&
            ((zoneOffset.readFrom === 'start' && zoneOffset.page === props.currentPage) ||
              (zoneOffset.readFrom === 'end' &&
              zoneOffset.page === props.imageCount - props.currentPage + 1) ||
                (zoneOffset.readFrom === 'first' && props.currentPage === 1) ||
                (zoneOffset.readFrom === 'last' && props.currentPage === props.imageCount) ||
                (zoneOffset.readFrom === 'firstv' && props.currentPage === zoneOffset.pageFound) ||
                (zoneOffset.readFrom === 'lastv' && props.currentPage === zoneOffset.pageFound) ||
                zoneOffset.readFrom === 'all') && (
              <div
                style={{
                  position: 'absolute',
                  pointerEvents: 'none',
                  zIndex: '100',
                  top: `${
                    (Math.min(zoneOffset.y, zoneOffset.y - zoneOffset.offsetY) *
                      scale) /
                    200
                  }px`,
                  left: `${
                    (Math.min(zoneOffset.x, zoneOffset.x - zoneOffset.offsetX) *
                      scale) /
                    200
                  }px`,
                  height: `${
                    (Math.abs(zoneOffset.offsetY) * scale) / 200 + 25
                  }px`,
                  width: `${
                    (Math.abs(zoneOffset.offsetX) * scale) / 200 + 25
                  }px`,
                }}
              >
                <svg
                  width={`${
                    (Math.abs(zoneOffset.offsetX) * scale) / 200 + 20
                  }px`}
                  height={`${
                    (Math.abs(zoneOffset.offsetY) * scale) / 200 + 20
                  }px`}
                >
                  <line
                    x1='10'
                    y1={`${(Math.abs(zoneOffset.offsetY) * scale) / 200 + 14}`}
                    x2={`${(Math.abs(zoneOffset.offsetX) * scale) / 200 + 10}`}
                    y2={`${(Math.abs(zoneOffset.offsetY) * scale) / 200 + 14}`}
                    style={{ stroke: 'rgb(36,147,214)', strokeWidth: '2px' }}
                  />
                  <line
                    x1='10'
                    y1='10'
                    x2='10'
                    y2={`${(Math.abs(zoneOffset.offsetY) * scale) / 200 + 14}`}
                    style={{ stroke: 'rgb(36,147,214)', strokeWidth: '2px' }}
                  />
                  <line
                    x1={`${(Math.abs(zoneOffset.offsetX) * scale) / 200 + 10}`}
                    y1={`${(Math.abs(zoneOffset.offsetY) * scale) / 200 + 14}`}
                    x2={`${(Math.abs(zoneOffset.offsetX) * scale) / 200 + 3}`}
                    y2={`${(Math.abs(zoneOffset.offsetY) * scale) / 200 + 21}`}
                    style={{ stroke: 'rgb(36,147,214)', strokeWidth: '2px' }}
                  />
                  <line
                    x1={`${(Math.abs(zoneOffset.offsetX) * scale) / 200 + 10}`}
                    y1={`${(Math.abs(zoneOffset.offsetY) * scale) / 200 + 14}`}
                    x2={`${(Math.abs(zoneOffset.offsetX) * scale) / 200 + 3}`}
                    y2={`${(Math.abs(zoneOffset.offsetY) * scale) / 200 + 7}`}
                    style={{ stroke: 'rgb(36,147,214)', strokeWidth: '2px' }}
                  />
                </svg>
              </div>
            )
        )}

        {props.zones.map(
          (zoneOffset) =>
            zoneOffset.anchorZoneId !== undefined &&
            zoneOffset.anchorZoneId !== null &&
            zoneOffset.anchorZoneId !== 0 &&
            zoneOffset.show !== false &&
            zoneOffset.offsetY < 0 &&
            zoneOffset.offsetX >= 0 && ((zoneOffset.readFrom === 'start' && zoneOffset.page === props.currentPage) ||
            (zoneOffset.readFrom === 'end' &&
            zoneOffset.page === props.imageCount - props.currentPage + 1) ||
              (zoneOffset.readFrom === 'first' && props.currentPage === 1) ||
              (zoneOffset.readFrom === 'last' && props.currentPage === props.imageCount) ||
              (zoneOffset.readFrom === 'firstv' && props.currentPage === zoneOffset.pageFound) ||
              (zoneOffset.readFrom === 'lastv' && props.currentPage === zoneOffset.pageFound) ||
              zoneOffset.readFrom === 'all') && (
              <div
                style={{
                  position: 'absolute',
                  pointerEvents: 'none',
                  zIndex: '100',
                  top: `${
                    (Math.min(zoneOffset.y, zoneOffset.y - zoneOffset.offsetY) *
                      scale) /
                    200
                  }px`,
                  left: `${
                    (Math.min(zoneOffset.x, zoneOffset.x - zoneOffset.offsetX) *
                      scale) /
                    200
                  }px`,
                  height: `${
                    (Math.abs(zoneOffset.offsetY) * scale) / 200 + 25
                  }px`,
                  width: `${
                    (Math.abs(zoneOffset.offsetX) * scale) / 200 + 25
                  }px`,
                }}
              >
                <svg
                  width={`${
                    (Math.abs(zoneOffset.offsetX) * scale) / 200 + 20
                  }px`}
                  height={`${
                    (Math.abs(zoneOffset.offsetY) * scale) / 200 + 20
                  }px`}
                >
                  <line
                    x1='10'
                    y1='14'
                    x2={`${(Math.abs(zoneOffset.offsetX) * scale) / 200 + 10}`}
                    y2='14'
                    style={{ stroke: 'rgb(36,147,214)', strokeWidth: '2px' }}
                  />
                  <line
                    x1='10'
                    y1='14'
                    x2='10'
                    y2={`${(Math.abs(zoneOffset.offsetY) * scale) / 200 + 14}`}
                    style={{ stroke: 'rgb(36,147,214)', strokeWidth: '2px' }}
                  />
                  <line
                    x1={`${(Math.abs(zoneOffset.offsetX) * scale) / 200 + 10}`}
                    y1='14'
                    x2={`${(Math.abs(zoneOffset.offsetX) * scale) / 200 + 3}`}
                    y2='7'
                    style={{ stroke: 'rgb(36,147,214)', strokeWidth: '2px' }}
                  />
                  <line
                    x1={`${(Math.abs(zoneOffset.offsetX) * scale) / 200 + 10}`}
                    y1='14'
                    x2={`${(Math.abs(zoneOffset.offsetX) * scale) / 200 + 3}`}
                    y2='21'
                    style={{ stroke: 'rgb(36,147,214)', strokeWidth: '2px' }}
                  />
                </svg>
              </div>
            )
        )}

        {props.zones.map(
          (zoneOffset) =>
            zoneOffset.anchorZoneId !== undefined &&
            zoneOffset.anchorZoneId !== null &&
            zoneOffset.anchorZoneId !== 0 &&
            zoneOffset.show !== false &&
            zoneOffset.offsetY >= 0 &&
            zoneOffset.offsetX < 0 && ((zoneOffset.readFrom === 'start' && zoneOffset.page === props.currentPage) ||
            (zoneOffset.readFrom === 'end' &&
            zoneOffset.page === props.imageCount - props.currentPage + 1) ||
              (zoneOffset.readFrom === 'first' && props.currentPage === 1) ||
              (zoneOffset.readFrom === 'last' && props.currentPage === props.imageCount) ||
              (zoneOffset.readFrom === 'firstv' && props.currentPage === zoneOffset.pageFound) ||
              (zoneOffset.readFrom === 'lastv' && props.currentPage === zoneOffset.pageFound) ||
              zoneOffset.readFrom === 'all') && (
              <div
                style={{
                  position: 'absolute',
                  pointerEvents: 'none',
                  zIndex: '100',

                  top: `${
                    (Math.min(zoneOffset.y, zoneOffset.y - zoneOffset.offsetY) *
                      scale) /
                    200
                  }px`,
                  left: `${
                    (Math.min(zoneOffset.x, zoneOffset.x - zoneOffset.offsetX) *
                      scale) /
                    200
                  }px`,
                  height: `${
                    (Math.abs(zoneOffset.offsetY) * scale) / 200 + 25
                  }px`,
                  width: `${
                    (Math.abs(zoneOffset.offsetX) * scale) / 200 + 25
                  }px`,
                }}
              >
                <svg
                  width={`${
                    (Math.abs(zoneOffset.offsetX) * scale) / 200 + 20
                  }px`}
                  height={`${
                    (Math.abs(zoneOffset.offsetY) * scale) / 200 + 20
                  }px`}
                >
                  <line
                    x1='10'
                    y1='10'
                    x2={`${(Math.abs(zoneOffset.offsetX) * scale) / 200 + 10}`}
                    y2='10'
                    style={{ stroke: 'rgb(36,147,214)', strokeWidth: '2px' }}
                  />
                  <line
                    x1='10'
                    y1='10'
                    x2='10'
                    y2={`${(Math.abs(zoneOffset.offsetY) * scale) / 200 + 10}`}
                    style={{ stroke: 'rgb(36,147,214)', strokeWidth: '2px' }}
                  />
                  <line
                    x1='10'
                    y1={`${(Math.abs(zoneOffset.offsetY) * scale) / 200 + 10}`}
                    x2='3'
                    y2={`${(Math.abs(zoneOffset.offsetY) * scale) / 200 + 3}`}
                    style={{ stroke: 'rgb(36,147,214)', strokeWidth: '2px' }}
                  />
                  <line
                    x1='10'
                    y1={`${(Math.abs(zoneOffset.offsetY) * scale) / 200 + 10}`}
                    x2='17'
                    y2={`${(Math.abs(zoneOffset.offsetY) * scale) / 200 + 3}`}
                    style={{ stroke: 'rgb(36,147,214)', strokeWidth: '2px' }}
                  />
                </svg>
              </div>
            )
        )}

        {props.zones.map(
          (zoneOffset) =>
            zoneOffset.anchorZoneId !== undefined &&
            zoneOffset.anchorZoneId !== null &&
            zoneOffset.anchorZoneId !== 0 &&

            zoneOffset.offsetY < 0 &&
            zoneOffset.offsetX < 0 && ((zoneOffset.readFrom === 'start' && zoneOffset.page === props.currentPage) ||
            (zoneOffset.readFrom === 'end' &&
            zoneOffset.page === props.imageCount - props.currentPage + 1) ||
              (zoneOffset.readFrom === 'first' && props.currentPage === 1) ||
              (zoneOffset.readFrom === 'last' && props.currentPage === props.imageCount) ||
              (zoneOffset.readFrom === 'firstv' && props.currentPage === zoneOffset.pageFound) ||
              (zoneOffset.readFrom === 'lastv' && props.currentPage === zoneOffset.pageFound) ||
              zoneOffset.readFrom === 'all') && (
              <div
                style={{
                  position: 'absolute',
                  pointerEvents: 'none',
                  zIndex: '100',

                  top: `${
                    (Math.min(zoneOffset.y, zoneOffset.y - zoneOffset.offsetY) *
                      scale) /
                    200
                  }px`,
                  left: `${
                    (Math.min(zoneOffset.x, zoneOffset.x - zoneOffset.offsetX) *
                      scale) /
                    200
                  }px`,
                  height: `${
                    (Math.abs(zoneOffset.offsetY) * scale) / 200 + 25
                  }px`,
                  width: `${
                    (Math.abs(zoneOffset.offsetX) * scale) / 200 + 25
                  }px`,
                }}
              >
                <svg
                  width={`${
                    (Math.abs(zoneOffset.offsetX) * scale) / 200 + 20
                  }px`}
                  height={`${
                    (Math.abs(zoneOffset.offsetY) * scale) / 200 + 20
                  }px`}
                >
                  <line
                    x1='10'
                    y1={`${(Math.abs(zoneOffset.offsetY) * scale) / 200 + 10}`}
                    x2={`${(Math.abs(zoneOffset.offsetX) * scale) / 200 + 10}`}
                    y2={`${(Math.abs(zoneOffset.offsetY) * scale) / 200 + 10}`}
                    style={{ stroke: 'rgb(36,147,214)', strokeWidth: '2px' }}
                  />
                  <line
                    x1='10'
                    y1={`${(Math.abs(zoneOffset.offsetY) * scale) / 200 + 10}`}
                    x2='10'
                    y2={`${(Math.abs(zoneOffset.height) * scale) / 200 + 10}`}
                    style={{ stroke: 'rgb(36,147,214)', strokeWidth: '2px' }}
                  />
                  <line
                    x1='10'
                    y1={`${(Math.abs(zoneOffset.height) * scale) / 200 + 10}`}
                    x2='3'
                    y2={`${(Math.abs(zoneOffset.height) * scale) / 200 + 17}`}
                    style={{ stroke: 'rgb(36,147,214)', strokeWidth: '2px' }}
                  />
                  <line
                    x1='10'
                    y1={`${(Math.abs(zoneOffset.height) * scale) / 200 + 10}`}
                    x2='17'
                    y2={`${(Math.abs(zoneOffset.height) * scale) / 200 + 17}`}
                    style={{ stroke: 'rgb(36,147,214)', strokeWidth: '2px' }}
                  />
                </svg>
              </div>
            )
        )}

        {showPopup && (
          <div
            className='dropdown'
            style={{
              position: 'absolute',
              zIndex: '200',
              top: `${popupY}px`,
              left: `${popupX}px`,
              padding: '2px',
            }}
          >
            <ul
              className='dropdown-menu'
              style={{
                display: 'block',
                cursor: 'pointer',
                borderRadius: '5px',
                border: 'solid 1px #333',
              }}
            >
              {props.currentZone.zoneType === 3 && (
                <li>
                  <span className='dropdown-item' onClick={onSplitColumn}>
                    Add Column
                  </span>
                </li>
              )}
              {props.currentZone.zoneType === 3 && (
                <li>
                  <span className='dropdown-item' onClick={onDeleteColumn}>
                    Remove Column
                  </span>
                </li>
              )}
              {props.currentZone.zoneType === 3 && (
                <li>
                <span className='dropdown-item' onClick={onDeleteZone}>
                  Delete Table
                </span>
                </li>
              )}
              {props.currentZone.zoneType !== 3 && (
              <li>
                <span className='dropdown-item' onClick={onDeleteZone}>
                  Delete Zone
                </span>
              </li>
              )}
              <li>
                <span className='dropdown-item' onClick={hidePopup}>
                  Cancel
                </span>
              </li>
            </ul>
          </div>
        )}
      </div>
    </div>
  );
}

export default TemplateImageViewer;
