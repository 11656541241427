import * as React from 'react';
import FiltersSidebar from './FiltersSidebar';
import InboxStore from '../../../stores/InboxStore';
import { GetWorkflowStepsForBPs, GetHeaderFieldsByFormType, UpdateFilter, GetFilterData, DeleteFilter } from '../../../actions/InboxActions';
import actionTypes from '../../../actions/ActionTypes';
import FormTypeStore from '../../../stores/FormTypeStore';
import { GetAllFormTypesWithPermission } from '../../../actions/FormTypeActions';
import { GetBPListByFormType } from '../../../actions/BPActions';
import BPStore from '../../../stores/BPStore';

class Filters extends React.Component {
  constructor(props) {
    super(props);
    // state
    this.state = {
        selectedFormType: {
            formTypeId: 0,
            name: '',
            data: '',
            indexId: null
        },
        allFormTypes: [],
        bpList: [],
        selectedBPs: [],
        workflowSteps: [],
        selectedWorkflowSteps: [],
        currentFilterName: '',
        onlyMyQueue: false,
        fields: [],
        selectedField: '-1',
        filterId: '0',
        reverseSort: false,
        step2Disabled: false,
        step3Disabled: false,
        step4Disabled: false,
        nextDisabled: false,
        currentStep: 1,
        canSave: false,
        selectAllSteps: false,
    };

    this.onInboxChange = this.onInboxChange.bind(this);
    this.checkParams = this.checkParams.bind(this);
    this.onReceiveFormTypeChange = this.onReceiveFormTypeChange.bind(this);
    this.changeFormType = this.changeFormType.bind(this);
    this.changeDisplay = this.changeDisplay.bind(this);
    this.onBPUpdate = this.onBPUpdate.bind(this);
    this.changeBPs = this.changeBPs.bind(this);
    this.handleFilterNameChange = this.handleFilterNameChange.bind(this);
    this.toggleOnlyMyQueue = this.toggleOnlyMyQueue.bind(this);
    this.changeReverseSort = this.changeReverseSort.bind(this);
    this.saveFilter = this.saveFilter.bind(this);
    this.deleteFilter = this.deleteFilter.bind(this);
    this.goToNextStep = this.goToNextStep.bind(this);
    this.goToPrevStep = this.goToPrevStep.bind(this);
    this.onToggleSelectAllSteps = this.onToggleSelectAllSteps.bind(this);
  }

  componentDidMount() {
    InboxStore.addChangeListener(this.onInboxChange);
    FormTypeStore.addChangeListener(this.onReceiveFormTypeChange);
    BPStore.addChangeListener(this.onBPUpdate);
    this.checkParams();
    GetAllFormTypesWithPermission();

  }

  componentWillUnmount() {
    InboxStore.removeChangeListener(this.onInboxChange);
    FormTypeStore.removeChangeListener(this.onReceiveFormTypeChange);
    BPStore.removeChangeListener(this.onBPUpdate);
  }

  componentDidUpdate() {
    this.checkParams2();
  }

  onBPUpdate() {
    let lastChange = BPStore.getLastChange();

    if (lastChange === actionTypes.BPGetListByFormType) {
      let bpList = BPStore.getBPList();
      this.setState({ bpList: bpList });
    }
  }

  onReceiveFormTypeChange() {
    let lastChange = FormTypeStore.getLastAction();
    if (lastChange.actionType === 'FTGetAll') {
      let formTypes = FormTypeStore.getAllFormTypes();
      this.setState({
        allFormTypes: formTypes,
        //searchText: '',
        //filteredFormTypeList: formTypes,
      });
    }
  }

  onInboxChange() {

    var lastChange = InboxStore.lastChange();

    if (lastChange === actionTypes.GetWorkflowStepsForBPs) {
        var workflowSteps = InboxStore.getWorkflowSteps();
        this.setState({workflowSteps: workflowSteps});
    }

    if (lastChange === actionTypes.GetHeaderFieldsByFormType) {
        var fields = InboxStore.getHeaderFields();
        this.setState({fields: fields});
    }

    if (lastChange === actionTypes.InboxUpdateFilter) {
      this.setState({canSave: false});
  }

    if (lastChange === actionTypes.GetFilterData) {
      var filterData = InboxStore.getFilterData();

      this.setState({filterId: filterData.id,
        selectedBPs: filterData.bps,
        selectedFormType: filterData.formType,
        selectedWorkflowSteps: filterData.workflowSteps,
        selectedField: filterData.field,
        currentFilterName: filterData.name,
        onlyMyQueue: filterData.onlyMyQueue,
        reverseSort: filterData.reverseSort
      });

      GetBPListByFormType(filterData.formType.formTypeId);
      GetHeaderFieldsByFormType(filterData.formType.formTypeId);
      GetWorkflowStepsForBPs(this.state.selectedBPs);
    }

  }

  checkParams() {
    let loc = window.location.pathname;
    let pathParts = loc.split('/');
    let filterId = pathParts[pathParts.length - 1];

    if (filterId !== '0'){
      GetFilterData(filterId);
      this.setState({ filterId: filterId, canSave: true });
    }
    else{
      this.setState({filterId: '0', step2Disabled: true, step3Disabled: true, step4Disabled: true, nextDisabled: true});
    }


  }

  checkParams2() {
    let loc = window.location.pathname;
    let pathParts = loc.split('/');
    let filterId = pathParts[pathParts.length - 1];

    if (filterId !== this.state.filterId.toString()){
      GetFilterData(filterId);
    }


  }

  changeFormType(formType) {
    let x = this.state.selectedFormType;
    x.name = formType.name;
    x.data = formType.data;
    x.formTypeId = formType.formTypeId;
    x.indexId = formType.indexId;
    this.setState({selectedFormType: x, step2Disabled: false, nextDisabled: false });

    GetBPListByFormType(formType.formTypeId);
    GetHeaderFieldsByFormType(formType.formTypeId);

  }


  changeBPs(bp) {
    let ci = this.state.selectedBPs.find(
        (i) => i.bpId === bp.bpId
      );

      if (ci) {
        // remove

        let bps = this.state.selectedBPs;
        let idx = bps.indexOf(ci);
        bps.splice(idx, 1);
        this.setState({ selectedBPs: bps });
      } else {
        // add
        let otherBps = this.state.selectedBPs;
        otherBps.push({
          bpId: bp.bpId,
          bpName: bp.bpName,
          formTypeId: bp.formTypeId,
          orgUnitId: bp.orgUnitId,
          workflowId: bp.workflowId
        });
        this.setState({ selectedBPs: otherBps, nextDisabled: false, step3Disabled: false });

        GetWorkflowStepsForBPs(this.state.selectedBPs);
      }

  }

  changeWorkflowSteps(step) {
    let ci = this.state.selectedWorkflowSteps.find(
        (i) => i.workflowStepId === step.workflowStepId
      );

      if (ci) {
        // remove

        let steps = this.state.selectedWorkflowSteps;
        let idx = steps.indexOf(ci);
        steps.splice(idx, 1);
        this.setState({ selectedWorkflowSteps: steps });
      } else {
        // add
        let otherSteps = this.state.selectedWorkflowSteps;
        otherSteps.push({
          workflowId: step.workflowId,
          typeId: step.typeId,
          displayOrder: step.displayOrder,
          isActive: step.isActive,
          stepType: step.stepType,
          description: step.description,
          notes: step.notes,
          customColor: step.customColor,
          useStamp: step.useStamp,
          name: step.name,
          workflowStepId: step.workflowStepId
        });
        this.setState({ selectedWorkflowSteps: otherSteps, step4Disabled: false, nextDisabled: false });

      }

  }


  changeDisplay(newDisplay) {
    this.setState({currentStep: newDisplay});
  }

  toggleOnlyMyQueue() {
    if (this.state.onlyMyQueue){
        this.setState({onlyMyQueue: false, canSave: true});
    }
    else{
        this.setState({onlyMyQueue: true, canSave: true});
    }
  }

  onToggleSelectAllSteps() {
    if (this.state.selectAllSteps){
        this.setState({selectAllSteps: false, selectedWorkflowSteps: [], canSave: true, step4Disabled: false, nextDisabled: false});
    }
    else{
        this.setState({selectAllSteps: true, selectedWorkflowSteps: this.state.workflowSteps, canSave: true, step4Disabled: false, nextDisabled: false});
    }
  }

  handleFilterNameChange(event) {
    if (event.target.value === ''){
      this.setState({ currentFilterName: event.target.value, canSave: false });
    }
    else{
      this.setState({ currentFilterName: event.target.value, canSave: true });
    }
  }

  editSelectedField(event) {
    this.setState({ selectedField: event.target.value, canSave: true });
  }

  changeReverseSort(value) {
    this.setState({ reverseSort: value, canSave: true });
  }

  goToNextStep() {
    if (this.state.filterId === '0'){
      if (this.state.currentStep === 1){
        if (this.state.bpList.length === 1){
          var bp = this.state.bpList[0];
          let otherBps = this.state.selectedBPs;
          otherBps.push({
            bpId: bp.bpId,
            bpName: bp.bpName,
            formTypeId: bp.formTypeId,
            orgUnitId: bp.orgUnitId,
            workflowId: bp.workflowId
          });
          this.setState({ selectedBPs: otherBps, nextDisabled: false, step3Disabled: false, currentStep: 2 });

          GetWorkflowStepsForBPs(this.state.selectedBPs);
        }
        else{
          this.setState({ currentStep: 2, nextDisabled: true });
        }
      }

      if (this.state.currentStep === 2){
        this.setState({ currentStep: 3, nextDisabled: true });
      }

      if (this.state.currentStep === 3){
        this.setState({ currentStep: 4, nextDisabled: true });
      }
    }
    else{
      if (this.state.currentStep === 1){
        this.setState({ currentStep: 2 });
      }

      if (this.state.currentStep === 2){
        this.setState({ currentStep: 3 });
      }

      if (this.state.currentStep === 3){
        this.setState({ currentStep: 4 });
      }
    }




  }

  goToPrevStep() {
    if (this.state.currentStep === 2){
      this.setState({ currentStep: 1, nextDisabled: false });
    }

    if (this.state.currentStep === 3){
      this.setState({ currentStep: 2, nextDisabled: false });
    }

    if (this.state.currentStep === 4){
      this.setState({ currentStep: 3, nextDisabled: false });
    }
  }

  saveFilter() {
    UpdateFilter(this.state.filterId,
        this.state.selectedBPs,
        this.state.selectedFormType,
        this.state.selectedWorkflowSteps,
        this.state.selectedField,
        this.state.currentFilterName,
        this.state.onlyMyQueue,
        this.state.reverseSort);
  }

  deleteFilter() {
    DeleteFilter(this.state.filterId);
  }

  render() {
    return (
      <div className='sidebarLayout d-flex flex-row flex-fill'>

          <FiltersSidebar LoadStepClick={this.LoadStep} LoadBPClick={this.LoadBP} />

          <div className='h-100 d-flex flex-column flex-fill'>
              <nav class="navbar navbar-expand-lg navbar-light bg-light">
                  <div class="container-fluid">
                      {this.state.filterId === 0 && (
                        <div class="navbar-brand">Create New Filter</div>
                      )}
                      {this.state.filterId !== 0 && (
                        <div class="navbar-brand">Manage Filter</div>
                      )}
                      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                          <span class="navbar-toggler-icon"></span>
                      </button>
                      <div class="nav nav-pills m-2" >
                        <li class="nav-item">
                            <div class={`nav-link ${
                                  this.state.currentStep === 1
                                    ? 'active'
                                    : ''
                                  }`} style={{cursor: 'pointer'}} onClick={() => this.changeDisplay(1)}>1. Form Types</div>
                        </li>
                        <li class="nav-item">
                            <div
                              className={`nav-link ${
                                this.state.step2Disabled
                                  ? 'disabled'
                                  : ''
                                }
                                ${
                                  this.state.currentStep === 2
                                    ? 'active'
                                    : ''
                                  }`}
                              style={{cursor: 'pointer'}}
                              onClick={() => this.changeDisplay(2)}>2. Business Processes</div>
                        </li>
                        <li class="nav-item">
                            <div
                              className={`nav-link ${
                                this.state.step3Disabled
                                  ? 'disabled'
                                  : ''
                                }
                                ${
                                  this.state.currentStep === 3
                                    ? 'active'
                                    : ''
                                  }`}
                                style={{cursor: 'pointer'}}
                              onClick={() => this.changeDisplay(3)}>3. Workflow Steps</div>
                        </li>
                        <li class="nav-item">
                            <div className={`nav-link ${
                                this.state.step4Disabled
                                  ? 'disabled'
                                  : ''
                                }
                                ${
                                  this.state.currentStep === 4
                                    ? 'active'
                                    : ''
                                  }`}
                                style={{cursor: 'pointer'}} onClick={() => this.changeDisplay(4)}>4. Summary</div>
                        </li>
                      </div>
                  </div>
              </nav>
              <nav class="navbar navbar-expand-lg navbar-light bg-light">
                  <div class="container-fluid">
                    <button
                      type='button'
                      className='btn btn-danger'
                      style={{ marginTop: '4px', marginRight: '4px' }}
                      onClick={this.deleteFilter}
                    >
                      Delete
                    </button>
                    <div class="nav nav-pills" >

                    {this.state.currentStep !== 1 && (
                      <li class="nav-item p-2">
                          <button
                                type='button'
                                className='btn btn-primary'
                                style={{ marginTop: '4px', marginRight: '4px' }}
                                onClick={this.goToPrevStep}
                              >
                                Previous
                            </button>
                      </li>
                    )}

                      {this.state.currentStep !== 4 && (
                        <li class="nav-item p-2">
                            <button
                                  type='button'
                                  className='btn btn-primary'
                                  style={{ marginTop: '4px', marginRight: '4px' }}
                                  onClick={this.goToNextStep}
                                  disabled={this.state.nextDisabled}
                                >
                                  Next
                              </button>
                        </li>
                      )}

                      {this.state.canSave && (
                        <li class="nav-item p-2">
                            <button
                              type='button'
                              className='btn btn-success'
                              style={{ marginTop: '4px', marginRight: '4px' }}
                              onClick={this.saveFilter}
                              disabled={!this.state.canSave}
                            >
                              Save
                          </button>
                        </li>
                      )}
                    </div>
                  </div>
              </nav>

              <div
                className='flex-fill'
                style={{ backgroundColor: '#efefef'}}
                  >
                    <div className='card m-3'>
                        {this.state.currentStep === 1 && (
                          <>
                            <div className='card-header'>Select Form Type
                            </div>
                            <div className='card-body p-3'>
                              <div class="radio custom-control custom-radio">
                                  {this.state.allFormTypes.map((formType) => (
                                      <div class="form-group">
                                          <input type="radio" class="custom-control-input" name="i" value={formType.name}
                                            checked={this.state.selectedFormType.formTypeId === formType.formTypeId}
                                            onClick={() => this.changeFormType(formType)} />
                                          <label class="custom-control-label" for="i1">{' '}{formType.name}</label>
                                      </div>
                                  ))}
                              </div>
                            </div>
                          </>
                        )}

                        {this.state.currentStep === 2 && (
                          <>
                            <div className='card-header'>Select Business Process
                            </div>
                            <div className='card-body p-3'>
                              <div >
                                {this.state.bpList.map((bp) => (
                                  <div key={bp.bpId} className='form-check'>
                                    <input
                                      className='form-check-input'
                                      type='checkbox'
                                      checked={
                                        this.state.selectedBPs.find(
                                          (i) => i.bpId === bp.bpId
                                        ) !== undefined
                                      }
                                      onChange={() => this.changeBPs(bp)}
                                    />
                                    <label className='form-check-label'>
                                      {bp.bpName}
                                    </label>
                                  </div>
                                ))}
                              </div>
                            </div>
                          </>
                        )}

                        {this.state.currentStep === 3 && (
                          <>
                            <div className='card-header'>Select Steps
                            </div>
                            <div className='card-body p-3'>
                              <div >
                              <div className='form-check'>
                                  <input
                                      className='form-check-input'
                                      type='checkbox'
                                      checked={this.state.selectAllSteps}
                                      onChange={() => this.onToggleSelectAllSteps()}
                                    />
                                    <label className='form-check-label'>
                                      {' '}Check all
                                    </label>
                                </div>
                                <hr style={{margin: '8px'}} ></hr>
                                {this.state.workflowSteps.map((step) => (
                                  <div key={step.id} className='form-check'>
                                    <input
                                      className='form-check-input'
                                      type='checkbox'
                                      checked={
                                        this.state.selectedWorkflowSteps.find(
                                          (i) => i.workflowStepId === step.workflowStepId
                                        ) !== undefined
                                      }
                                      onChange={() => this.changeWorkflowSteps(step)}
                                    />
                                    <label className='form-check-label'>
                                      {step.name}
                                    </label>
                                  </div>
                                ))}
                              </div>

                            </div>
                          </>
                        )}

                        {this.state.currentStep === 4 && (
                          <>
                            <div className='card-header'>Manage Filter Settings
                            </div>
                            <div className='card-body p-3'>

                                <div className='form-group'>
                                    <div className='Header3'>Name:</div>
                                    <input
                                    type='text'
                                    className='form-control'
                                    id='headerName'
                                    value={this.state.currentFilterName}
                                    onChange={this.handleFilterNameChange}
                                    ></input>
                                </div>

                                <div class="radio custom-control custom-radio">
                                    <label>Sort Order</label>
                                    <div class="form-group">
                                        <input type="radio" class="custom-control-input" name="i" value='Ascending'
                                          checked={this.state.reverseSort === false}
                                          onClick={() => this.changeReverseSort(false)}  />
                                        <label class="custom-control-label" for="i1">{' Ascending'}</label>
                                    </div>
                                    <div class="form-group">
                                        <input type="radio" class="custom-control-input" name="i" value='Descending'
                                          checked={this.state.reverseSort === true}
                                          onClick={() => this.changeReverseSort(true)}  />
                                        <label class="custom-control-label" for="i1">{' Descending'}</label>
                                    </div>
                                </div>

                                <div className='form-check'>
                                    <input
                                        className='form-check-input'
                                        type='checkbox'
                                        checked={this.state.onlyMyQueue}
                                        onChange={() => this.toggleOnlyMyQueue()}
                                    />
                                    <label className='form-check-label'>
                                        {'Only My Queue'}
                                    </label>
                                </div>

                                <div className='col-3 pt-2'>
                                    <label className='form-check-label'>
                                        {'Sort Field'}
                                    </label>
                                    <select
                                        className='form-select'
                                        value={this.state.selectedField}
                                        onChange={event => this.editSelectedField(event)}
                                        >
                                        <option value='-7'>{'Related'}</option>
                                        <option value='-5'>{'Queue'}</option>
                                        <option value='-3'>{'Page Count'}</option>
                                        <option value='-2'>{'Note Count'}</option>
                                        <option value='-1'>{'Document Id'}</option>
                                        {this.state.fields.map((field) => (
                                            <option
                                                value={field.fieldId}
                                                >
                                                {field.name}
                                            </option>
                                        ))}
                                    </select>
                                </div>

                            </div>
                          </>
                        )}
                    </div>
              </div>

          </div>
      </div>
    );
  }
}

export default Filters;
