import { EventEmitter } from 'events';
import Dispatcher from '../Dispatcher';
import actionTypes from '../actions/ActionTypes';

const CHANGE_EVENT = 'userChange';
let _bpList = [];
let _currentBP = {};
let _lastChange = '';
let _importMessage = '';
let _lastError = '';

class BPStore extends EventEmitter {
  addChangeListener(callback) {
    this.on(CHANGE_EVENT, callback);
  }

  removeChangeListener(callback) {
    this.removeListener(CHANGE_EVENT, callback);
  }

  emitChange() {
    this.emit(CHANGE_EVENT);
  }

  getBPList() {
    return _bpList;
  }

  getLastChange() {
    return _lastChange;
  }

  getCurrentBP() {
    return _currentBP;
  }

  getImportMessage() {
    return _importMessage;
  }

  getLastError() {
    return _lastError;
  }
}

const store = new BPStore();

Dispatcher.register((action) => {
  switch (action.actionType) {
    case actionTypes.BPGetList:
      _bpList = action.Result;
      _lastChange = action.actionType;
      console.log('store update');
      store.emitChange();
      break;
    case actionTypes.BPGetOne:
      _currentBP = action.Result;
      _lastChange = action.actionType;
      store.emitChange();
      break;
    case actionTypes.BPUpdate:
      _currentBP = action.Result;
      _lastChange = action.actionType;
      store.emitChange();
      break;

    case actionTypes.BPGetListByFormType:
      _bpList = action.Result;
      _lastChange = action.actionType;

      store.emitChange();
      break;

    case actionTypes.ImportBP:
      _lastChange = action.actionType;
      _importMessage = action.Result.updateMessage;

      store.emitChange();
      break;

    default:
  }
});

export default store;
