import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import BPSidebar from './BPSidebar';

/*import { UpdateName } from '../../../Actions/BPActions';
import ISStore from '../../../Stores/IntakeStrategyStore';
import { IntakeStrategyModel } from '../../../Models/Admin/IntakeStrategy';*/
/*import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
   faCog,
   faSitemap,
   faFileInvoice,
} from '@fortawesome/free-solid-svg-icons';*/

class BusinessProcessHome extends React.Component {
  constructor() {
    // state
    super();
    this.state = {};
  }

  componentDidMount() {}
  componentWillUnmount() {}
  componentDidUpdate() {}

  render() {
    return (
      <div className='sidebarLayout d-flex flex-row flex-fill'>
        <div className='sidebar-container'>
          <BPSidebar />
        </div>
        <div className='flex-fill'>
          <div className='h-100 d-flex flex-column align-items-center justify-content-center'>
            <FontAwesomeIcon icon={solid('th-large')} size='6x' />
            <h2
              style={{
                fontWeight: 'bolder',
                fontStyle: 'italic',
                margin: '16px',
              }}
            >
              Business Processes
            </h2>
          </div>
        </div>
      </div>
    );
  }
}

export default BusinessProcessHome;
