import { EventEmitter } from 'events';
import Dispatcher from '../Dispatcher';
import actionTypes from '../actions/ActionTypes';

const CHANGE_EVENT = 'inboundAutoChange';
let _allIAs = undefined;
let _currentIA = undefined;
let _lastChange = '';
let _lastError = '';

class IAStore extends EventEmitter {
  addChangeListener(callback) {
    this.on(CHANGE_EVENT, callback);
  }

  removeChangeListener(callback) {
    this.removeListener(CHANGE_EVENT, callback);
  }

  emitChange() {
    this.emit(CHANGE_EVENT);
  }

  getAllIAs() {
    return _allIAs;
  }

  getCurrentIA() {
    return _currentIA;
  }

  lastChange() {
    return _lastChange;
  }

  getLastError() {
    return _lastError;
  }
}

const store = new IAStore();

Dispatcher.register((action) => {
  _lastChange = action.actionType;
  switch (action.actionType) {
    // loading the full list of Companion schemas
    case actionTypes.IAList:
      _lastChange = 'IAList';
      _allIAs = action.Result;
      store.emitChange();
      break;
    case actionTypes.IALoadOne:
      _lastChange = 'IALoadOne';
      _currentIA = action.Result;

      store.emitChange();
      break;
    case actionTypes.IAUpdateOne:
      _lastChange = 'IAUpdateOne';
      if (action.Result.updateStatus === 'Success') {
        _lastError = '';
        _currentIA = action.Result.result;
      } else {
        _lastError = action.Result.errorMessage;
      }
      store.emitChange();
      break;
    case actionTypes.IACreate:
      _lastChange = 'IACreate';
      if (action.Result.updateStatus === 'Success') {
        _lastError = '';
        _currentIA = action.Result.result;
      } else {
        _lastError = action.Result.errorMessage;
      }
      store.emitChange();
      break;
    case actionTypes.IADelete:
      _lastChange = 'IADelete';
      if (action.Result.updateStatus === 'Success') {
        _lastError = '';
        _currentIA = action.Result.result;
      } else {
        _lastError = action.Result.errorMessage;
      }
      store.emitChange();
      break;
    default:
  }
});

export default store;
