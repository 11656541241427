//@flow
import dispatcher from '../Dispatcher';
import actionTypes from './ActionTypes';
import axios from 'axios';
import * as Globals from '../Globals';
import { PostAlert } from './AlertActions';
//import { CompanionRecord } from '../Models/Admin/CompanionSchema';

export function GetDirectoryList() {
  axios
    .get(`${Globals.baseUrl}/Directories/GetDirectories`, {
      headers: {
        'content-type': 'application/json; charset=utf-8',
        Authorization: `Bearer ${Globals.getJwtToken()}`,
      },
    })
    .then((res) => {
      if (res.status === 200) {
        dispatcher.dispatch({
          actionType: actionTypes.DirectoriesList,
          Result: res.data,
        });
      } else if (res.status === 400 || res.status === 401) {
        console.log('error: 401');
      }
    })
    .catch((res) => {
      let result = {
        updateStatus: 'Error',
        errorMessage: '401: Bad request',
      };
      dispatcher.dispatch({
        actionType: actionTypes.DirectoriesList,
        Result: result,
      });
    });
}

export function LoadDirectory(id) {
  axios
    .post(
      `${Globals.baseUrl}/Directories/GetDirectory`,
      { dirId: id },
      {
        headers: {
          'content-type': 'application/json; charset=utf-8',
          Authorization: `Bearer ${Globals.getJwtToken()}`,
        },
      }
    )
    .then((res) => {
      if (res.status === 200) {
        dispatcher.dispatch({
          actionType: actionTypes.DirectoriesLoadOne,
          Result: res.data,
        });
      } else if (res.status === 400 || res.status === 401) {
        console.log('error: 401');
      }
    })
    .catch((res) => {
      console.log('error');
    });
}

export function CreateDirectory(uploadPath, isActive) {
  axios
    .post(
      `${Globals.baseUrl}/Directories/CreateDirectory`,
      { dirId: 0, uploadPath: uploadPath, isActive: isActive },
      {
        headers: {
          'content-type': 'application/json; charset=utf-8',
          Authorization: `Bearer ${Globals.getJwtToken()}`,
        },
      }
    )
    .then((res) => {
      if (res.status === 200) {
        dispatcher.dispatch({
          actionType: actionTypes.DirectoriesUpdateOne,
          Result: res.data,
        });
        console.log(res.data);
        if (res.data.updateStatus === 'Success') {
          PostAlert('Success', 'Directory created');
        }
        if (res.data.updateStatus === 'Error') {
          PostAlert('Error', 'Unable to create directory');
        }
      } else if (res.status === 400 || res.status === 401) {
        console.log('error: 401');
      }
    })
    .catch((res) => {
      console.log(res);
    });
}

export function DeleteDirectory(directoryId) {
  axios
    .post(
      `${Globals.baseUrl}/Directories/DeleteDirectory`,
      { dirId: directoryId },
      {
        headers: {
          'content-type': 'application/json; charset=utf-8',
          Authorization: `Bearer ${Globals.getJwtToken()}`,
        },
      }
    )
    .then((res) => {
      if (res.status === 200) {
        dispatcher.dispatch({
          actionType: actionTypes.DirectoriesDelete,
          Result: res.data,
        });
      } else if (res.status === 400 || res.status === 401) {
        console.log('error: 401');
      }
    })
    .catch((res) => {
      console.log(res);
    });
}

export function UpdateSectionOne(id, uploadPath, isActive) {
  axios
    .post(
      `${Globals.baseUrl}/Directories/UpdateDirectoryPath`,
      { dirId: id, uploadPath: uploadPath, isActive: isActive },
      {
        headers: {
          'content-type': 'application/json; charset=utf-8',
          Authorization: `Bearer ${Globals.getJwtToken()}`,
        },
      }
    )
    .then((res) => {
      if (res.status === 200) {
        dispatcher.dispatch({
          actionType: actionTypes.DirectoriesUpdateOne,
          Result: res.data,
        });
        console.log(res.data);
        if (res.data.updateStatus === 'Success') {
          console.log('post');
          PostAlert('success', 'Directory created');
        }
        if (res.data.updateStatus === 'Error') {
          PostAlert('error', 'Unable to create directory');
        }
      } else if (res.status === 400 || res.status === 401) {
        console.log('error: 401');
      }
    })
    .catch((res) => {
      console.log(res);
    });
}

export function UpdateSectionTwo(
  id,
  routingType,
  ftps,
  defaultFtp,
  intakeStrategy
) {
  axios
    .post(
      `${Globals.baseUrl}/Directories/UpdateDirectoryRouting`,
      {
        dirId: id,
        routingType: routingType,
        ftps: ftps,
        defaultFtp: defaultFtp,
        intakeStrategy: intakeStrategy,
      },
      {
        headers: {
          'content-type': 'application/json; charset=utf-8',
          Authorization: `Bearer ${Globals.getJwtToken()}`,
        },
      }
    )
    .then((res) => {
      if (res.status === 200) {
        dispatcher.dispatch({
          actionType: actionTypes.DirectoriesUpdateOne,
          Result: res.data,
        });
      } else if (res.status === 400 || res.status === 401) {
        console.log('error: 401');
      }
    })
    .catch((res) => {
      console.log(res);
    });
}
