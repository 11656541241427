import * as React from 'react';
import Dropzone from 'react-dropzone';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'; //
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';

import actionTypes from '../../../../actions/ActionTypes';
import ModuleSidebar from './ModuleSidebar';
import ModuleStore from '../../../../stores/ModuleStore';
import {
  UploadModule,
  GetModuleList,
  GetModule,
  UpdateModuleName,
  ReUploadModule,
  RunPlugin,
  GetModuleDataList,
  DownloadModule,
  MakeCurrentModule,
  DeleteModule,
  ReanalyzeModule,
} from '../../../../actions/ModuleActions';
import Moment from 'moment';

class ModuleView extends React.Component {
  constructor(props) {
    super(props);
    // state
    this.state = {
      currentModule: {
        moduleId: 0,
        plugins: [],
      },
      currentModuleData: [],
      isDirty: false,
      isSaving: false,
      isNew: false,
      errorMessage: '',
      currentView: 'plugins',
      editModName: '',
      isUploading: false,
      upload: undefined,
      isRenaming: false,
    };
    this.onModuleUpdate = this.onModuleUpdate.bind(this);
    this.onDrop = this.onDrop.bind(this);
    this.onReUploadDrop = this.onReUploadDrop.bind(this);
    this.updateModule = this.updateModule.bind(this);
    this.onEditModName = this.onEditModName.bind(this);
    this.uploadTryAgain = this.uploadTryAgain.bind(this);
    this.onChangeView = this.onChangeView.bind(this);
    this.uploadClear = this.uploadClear.bind(this);
    this.onRename = this.onRename.bind(this);
    this.onRenaming = this.onRenaming.bind(this);
    this.runPlugin = this.runPlugin.bind(this);
    this.onDownloadClick = this.onDownloadClick.bind(this);
    this.deleteModule = this.deleteModule.bind(this);
    this.setCurrentModule = this.setCurrentModule.bind(this);
    this.reanalyzeModule = this.reanalyzeModule.bind(this);
  }

  componentDidMount() {
    ModuleStore.addChangeListener(this.onModuleUpdate);
    this.updateModule();
  }

  componentWillUnmount() {
    ModuleStore.removeChangeListener(this.onModuleUpdate);
  }

  componentDidUpdate() {
    this.updateModule();
  }

  onModuleUpdate() {
    let lastChange = ModuleStore.getLastChange();
    if (lastChange === actionTypes.ModulesUpload) {
      let up = ModuleStore.getUploadResult();

      this.setState({ upload: up, isUploading: false });
      if (up.resultStatus === 'success') {
        GetModule(up.moduleId);
        GetModuleDataList(up.moduleId);
        GetModuleList();
      }
    }
    if (lastChange === actionTypes.ModulesRename) {
      let mod = ModuleStore.getModule();
      this.setState({
        currentModule: mod,
        isNew: false,
        isDirty: false,
        errorMessage: '',
      });
    }
    if (lastChange === actionTypes.ModulesGetOne) {
      let mod = ModuleStore.getModule();
      this.setState({
        currentModule: mod,
        isNew: false,
        isDirty: false,
        errorMessage: '',
      });
    }

    if (lastChange === actionTypes.ModuleDataGetList) {
      let modData = ModuleStore.getModuleDataList();
      this.setState({ currentModuleData: modData });
    }

    if (lastChange === actionTypes.ModuleDelete) {
      let deleteResult = ModuleStore.getUploadResult();
      if (deleteResult.resultStatus === 'error') {
        this.setState({ errorMessage: deleteResult.errorMessage });
      } else {
        // leave page
        this.setState({ errorMessage: '' });
        let link = document.getElementById('modRoot');
        link.click();
      }
    }

    if (lastChange === actionTypes.ModuleAnalyze) {
      let up = ModuleStore.getUploadResult();

      this.setState({ upload: up, isUploading: false });
      if (up.resultStatus === 'success') {
        GetModule(up.moduleId);
        GetModuleDataList(up.moduleId);
        GetModuleList();
      }
    }
  }

  updateModule() {
    let loc = window.location.pathname;
    let pathParts = loc.split('/');
    let modId = parseInt(pathParts[pathParts.length - 1], 10);
    if (
      modId !== 0 &&
      (this.state.currentModule === undefined ||
        this.state.currentModule.moduleId !== modId)
    ) {
      let module = {
        moduleId: modId,
      };
      this.setState({ currentModule: module, isNew: false });
      GetModule(modId);
      GetModuleDataList(modId);
    } else if (modId === 0 && this.state.isNew === false) {
      let currentModule = undefined;
      this.setState({
        currentModule: currentModule,
        isNew: true,
      });
    }
  }

  onRename() {
    if (this.state.isRenaming === true) {
      this.setState({ isRenaming: false });
    } else {
      this.setState({ isRenaming: true });
    }
  }

  onEditModName(e) {
    let n = e.target.value;
    this.setState({ editModName: n });
  }

  updateModuleName() {
    UpdateModuleName(
      this.state.currentModule.moduleId,
      this.state.currentModule.moduleName
    );
  }

  onRenaming(e) {
    let cm = this.state.currentModule;
    cm.moduleName = e.target.value;
    this.setState({ currentModule: cm });
  }

  onChangeView(newView) {
    this.setState({ currentView: newView });
  }

  onDrop(files) {
    files.forEach((file) => {
      let upload = {
        file: file,
      };
      UploadModule(upload, this.state.editModName);
      this.setState({ isUploading: true });
    });
  }

  onReUploadDrop(files) {
    files.forEach((file) => {
      let upload = {
        file: file,
      };
      ReUploadModule(
        upload,
        this.state.currentModule.moduleName,
        this.state.currentModule.moduleId
      );
      this.setState({ isUploading: true });
    });
  }

  uploadTryAgain() {
    this.setState({ upload: undefined, isUploading: false });
  }

  uploadClear() {
    this.setState({ upload: undefined, isUploading: false, editModName: '' });
  }

  runPlugin(pluginId) {
    RunPlugin(pluginId);
  }

  onDownloadClick(id) {
    DownloadModule(id, this.state.currentModule.moduleName);
  }

  deleteModule() {
    let mod = this.state.currentModule;
    console.log(mod);

    DeleteModule(mod.moduleId);
  }

  setCurrentModule(id) {
    MakeCurrentModule(id);
  }

  reanalyzeModule() {
    let mod = this.state.currentModule;
    console.log(mod);
    ReanalyzeModule(mod.moduleId);
  }

  render() {
    return (
      <div className='container-fluid' style={{ height: '100%' }}>
        <div className='row flex-nowrap' style={{ height: '100%' }}>
          <ModuleSidebar />
          {!this.state.isNew && (
            <div className='flex-fill' style={{ backgroundColor: '#efefef' }}>
              <div className='card m-3'>
                <div className='card-header'>Module</div>
                <div className='card-body p-3'>
                  <span>
                    <strong>Name: </strong>
                    <span style={{ float: 'right' }}>
                      <button
                        className='btn btn-success'
                        onClick={() => this.updateModuleName()}
                      >
                        Save
                      </button>
                    </span>
                    {!this.state.isRenaming && (
                      <>
                        <span>{this.state.currentModule.moduleName}</span>
                        <button
                          className='btn btn-sm btn-primary ms-3'
                          onClick={() => this.onRename()}
                        >
                          Rename
                        </button>
                        <button
                          className='btn btn-sm btn-info ms-3'
                          onClick={this.reanalyzeModule}
                        >
                          Reanalyze Module
                        </button>
                        <button
                          className='btn btn-sm btn-danger ms-3'
                          onClick={this.deleteModule}
                        >
                          Delete Module
                        </button>
                      </>
                    )}
                    {this.state.isRenaming && (
                      <>
                        <input
                          value={this.state.currentModule.moduleName}
                          onChange={this.onRenaming}
                        />
                        <button
                          className='btn btn-sm btn-primary ms-3'
                          onClick={() => this.onRename()}
                        >
                          Finished Renaming
                        </button>
                      </>
                    )}
                  </span>

                  {this.state.errorMessage !== '' && (
                    <div className='alert alert-danger mt-2'>
                      {this.state.errorMessage}
                    </div>
                  )}
                  <div className='nav nav-pills mt-3'>
                    <li className='nav-item'>
                      <span
                        className={`nav-link ${
                          this.state.currentView === 'plugins' ? 'active' : ''
                        }`}
                        onClick={() => this.onChangeView('plugins')}
                        style={{ cursor: 'pointer' }}
                      >
                        PlugIns
                      </span>
                    </li>
                    <li className='nav-item'>
                      <div
                        className={`nav-link ${
                          this.state.currentView === 'update' ? 'active' : ''
                        }`}
                        onClick={() => this.onChangeView('update')}
                        style={{ cursor: 'pointer' }}
                      >
                        Update
                      </div>
                    </li>
                    <li className='nav-item'>
                      <div
                        className={`nav-link ${
                          this.state.currentView === 'history' ? 'active' : ''
                        }`}
                        onClick={() => this.onChangeView('history')}
                        style={{ cursor: 'pointer' }}
                      >
                        Upload history
                      </div>
                    </li>
                  </div>
                  {this.state.currentView === 'plugins' &&
                    this.state.currentModule.plugins !== undefined && (
                      <div
                        className='mt-2 p-3'
                        style={{
                          border: 'solid 1px #797979',
                          backgroundColor: '#F8FADE',
                          borderRadius: '12px',
                        }}
                      >
                        {this.state.currentModule.plugins.map((plugin) => (
                          <div key={plugin.pluginId} className='mt-1'>
                            <FontAwesomeIcon icon={solid('cog')} />{' '}
                            <span>
                              <strong>{plugin.pluginName}</strong>
                            </span>
                            {plugin.pluginType === 0 && (
                              <>
                                <span className='ms-2'>
                                  {'('}Unknown{')'}
                                </span>
                                <span className='ms-2'>Analyze</span>
                              </>
                            )}
                            {plugin.pluginType === 1 && (
                              <span style={{ color: '#888' }} className='ms-2'>
                                {'('}
                                <FontAwesomeIcon icon={solid('sitemap')} />{' '}
                                Workflow{')'}
                              </span>
                            )}
                            {plugin.pluginType === 2 && (
                              <span style={{ color: '#888' }} className='ms-2'>
                                {'('}
                                <FontAwesomeIcon icon={regular('clock')} />{' '}
                                Scheduled{')'}
                              </span>
                            )}
                            {plugin.pluginType === 3 && (
                              <span style={{ color: '#888' }} className='ms-2'>
                                {'('}
                                <FontAwesomeIcon icon={solid('running')} />{' '}
                                Runtime{')'}
                              </span>
                            )}
                            {plugin.pluginType === 4 && (
                              <span style={{ color: '#888' }} className='ms-2'>
                                {'('}
                                <FontAwesomeIcon icon={solid('list-ul')} />{' '}
                                Lookup{')'}
                              </span>
                            )}
                            {plugin.pluginType === 5 && (
                              <span style={{ color: '#888' }} className='ms-2'>
                                {'('}
                                <FontAwesomeIcon
                                  icon={solid('truck-moving')}
                                />{' '}
                                Intake{')'}
                              </span>
                            )}
                            <span className='ms-2'>{plugin.version}</span>
                            {plugin.pluginType === 2 && (
                              <div className='ms-2 mt-1'>
                                <button
                                  className='btn btn-sm btn-primary'
                                  onClick={() =>
                                    this.runPlugin(plugin.pluginId)
                                  }
                                >
                                  Run Now
                                </button>
                              </div>
                            )}
                          </div>
                        ))}
                      </div>
                    )}
                  {this.state.currentView === 'update' && (
                    <div
                      className='flex-fill'
                      style={{ backgroundColor: '#efefef' }}
                    >
                      <div className='card mt-3'>
                        <div className='card-header'>Update module</div>
                        <div className='card-body p-4'>
                          {this.state.isUploading && (
                            <div
                              style={{ height: '80px', textAlign: 'center' }}
                            >
                              <span style={{ color: '#30E830' }}>
                                <FontAwesomeIcon
                                  icon={solid('spinner')}
                                  size='4x'
                                  spin
                                />
                              </span>
                              <div className='mt-2 mb-2'>Uploading...</div>
                            </div>
                          )}
                          {!this.state.isUploading &&
                            this.state.upload === undefined && (
                              <>
                                <Dropzone
                                  className='ui raised segment'
                                  multiple={true}
                                  onDrop={this.onReUploadDrop}
                                  accept={{
                                    'application/pdf': ['.zip'],
                                  }}
                                >
                                  {({ getRootProps, getInputProps }) => (
                                    <div className='drop-container'>
                                      <div
                                        {...getRootProps({
                                          className: 'dropzone',
                                        })}
                                      >
                                        <input {...getInputProps()} />
                                        <div className='drop-content'>
                                          <FontAwesomeIcon
                                            icon={solid('box')}
                                            size='3x'
                                          />
                                          <div className='drop-header'>
                                            Module Upload
                                          </div>
                                          <p className='drop-subheader'>
                                            Drag 'n' drop your zipped module
                                            here
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                </Dropzone>
                              </>
                            )}
                          {this.state.upload !== undefined && (
                            <div>
                              {this.state.upload.resultStatus === 'error' && (
                                <div>
                                  <h4>Upload: Failed</h4>
                                  <div className='alert alert-danger'>
                                    {this.state.upload.errorMessage}
                                  </div>
                                  <button
                                    className='btn btn-primary'
                                    onClick={this.uploadTryAgain}
                                  >
                                    Try again
                                  </button>
                                </div>
                              )}
                              {this.state.upload.resultStatus === 'success' && (
                                <div>
                                  <h4>Upload: Success</h4>
                                  <div className='alert alert-info'>
                                    <strong className='mb-2'>
                                      New plugins installed:
                                    </strong>
                                    {this.state.upload.integrations.map(
                                      (intg) => (
                                        <div>
                                          <FontAwesomeIcon
                                            icon={solid('cog')}
                                          />{' '}
                                          {intg.integrationName}
                                          {' ('}
                                          {intg.integrationVersion}
                                          {')'}
                                        </div>
                                      )
                                    )}
                                  </div>
                                  <Link
                                    to={
                                      '/Admin/Module/' +
                                      this.state.upload.moduleId
                                    }
                                  >
                                    <button className='btn btn-primary'>
                                      View module
                                    </button>
                                  </Link>
                                  <button
                                    className='btn btn-secondary ms-2'
                                    onClick={this.uploadClear}
                                  >
                                    Clear
                                  </button>
                                </div>
                              )}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                  {this.state.currentView === 'history' && (
                    <div
                      className='mt-2 p-3'
                      style={{
                        border: 'solid 1px #797979',
                        backgroundColor: '#F8FADE',
                        borderRadius: '12px',
                      }}
                    >
                      {this.state.currentModuleData.map((md) => (
                        <div key={md.id} className='mt-1'>
                          <span>
                            <strong>
                              {Moment(md.uploadDtime).format(
                                'MM/DD/yyyy hh:mm A'
                              )}
                            </strong>
                          </span>
                          {md.isCurrent && (
                            <span className='ms-2'>Current </span>
                          )}
                          {!md.isCurrent && (
                            <>
                              <button
                                className='btn btn-sm btn-primary ms-2'
                                onClick={() => this.setCurrentModule(md.id)}
                              >
                                <FontAwesomeIcon icon={solid('right-left')} />{' '}
                                Make Current
                              </button>
                            </>
                          )}
                          <button
                            className='btn btn-sm btn-success ms-2'
                            onClick={() => this.onDownloadClick(md.id)}
                          >
                            <FontAwesomeIcon icon={solid('download')} />{' '}
                            Download
                          </button>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
          {this.state.isNew && (
            <div className='flex-fill' style={{ backgroundColor: '#efefef' }}>
              <div className='card m-3'>
                <div className='card-header'>Upload new module</div>
                <div className='card-body p-4'>
                  {this.state.isUploading && (
                    <div style={{ height: '80px', textAlign: 'center' }}>
                      <span style={{ color: '#30E830' }}>
                        <FontAwesomeIcon
                          icon={solid('spinner')}
                          size='4x'
                          spin
                        />
                      </span>
                      <div className='mt-2 mb-2'>Uploading...</div>
                    </div>
                  )}
                  {!this.state.isUploading &&
                    this.state.upload === undefined && (
                      <>
                        <div className='row'>
                          <div className='mb-3 col-4'>
                            <label className='form-label'>Module Name:</label>
                            <input
                              value={this.state.editModName}
                              onChange={this.onEditModName}
                              type='text'
                              className='form-control'
                            />
                          </div>
                        </div>
                        <Dropzone
                          className='ui raised segment'
                          multiple={true}
                          onDrop={this.onDrop}
                          accept={{
                            'application/pdf': ['.zip'],
                          }}
                        >
                          {({ getRootProps, getInputProps }) => (
                            <div className='drop-container'>
                              <div {...getRootProps({ className: 'dropzone' })}>
                                <input {...getInputProps()} />
                                <div className='drop-content'>
                                  <FontAwesomeIcon
                                    icon={solid('box')}
                                    size='3x'
                                  />
                                  <div className='drop-header'>
                                    Module Upload
                                  </div>
                                  <p className='drop-subheader'>
                                    Drag 'n' drop your zipped module here
                                  </p>
                                </div>
                              </div>
                            </div>
                          )}
                        </Dropzone>
                      </>
                    )}
                  {this.state.upload !== undefined && (
                    <div>
                      {this.state.upload.resultStatus === 'error' && (
                        <div>
                          <h4>Upload: Failed</h4>
                          <div className='alert alert-danger'>
                            {this.state.upload.errorMessage}
                          </div>
                          <button
                            className='btn btn-primary'
                            onClick={this.uploadTryAgain}
                          >
                            Try again
                          </button>
                        </div>
                      )}
                      {this.state.upload.resultStatus === 'success' && (
                        <div>
                          <h4>Upload: Success</h4>
                          <div className='alert alert-info'>
                            <strong className='mb-2'>
                              New plugins installed:
                            </strong>
                            {this.state.upload.integrations.map((intg) => (
                              <div>
                                <FontAwesomeIcon icon={solid('cog')} />{' '}
                                {intg.integrationName}
                                {' ('}
                                {intg.integrationVersion}
                                {')'}
                              </div>
                            ))}
                          </div>
                          <Link
                            to={'/Admin/Module/' + this.state.upload.moduleId}
                          >
                            <button className='btn btn-primary'>
                              View module
                            </button>
                          </Link>
                          <button
                            className='btn btn-secondary ms-2'
                            onClick={this.uploadClear}
                          >
                            Clear
                          </button>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
        <Link id='modRoot' to={'/Admin/Modules'} style={{ display: 'none' }}>
          HiddenLink
        </Link>
      </div>
    );
  }
}

export default ModuleView;
