import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import AutoOcrSidebar from './AutoOcrSidebar';
import aiIcon from '../../../../images/TD AI Icon 2 Color.png';

class AutoOcrHome extends React.Component {
  constructor(props) {
    super(props);
    // state
    this.state = {};
  }

  componentDidMount() {
    document.title = 'TrinDocs - Auto OCR Config';
  }

  componentWillUnmount() {}

  render() {
    return (
      <div className='container-fluid' style={{ height: '100%' }}>
        <div className='row flex-nowrap' style={{ height: '100%' }}>
          <AutoOcrSidebar />
          <div className='h-100 d-flex align-items-center'>
            <div
              className='p-2 col-example'
              style={{
                textAlign: 'center',
                marginLeft: '100px',
                marginRight: '100px',
                width: '60%',
              }}
            >
              <img src={aiIcon} className='aiMenuIcon2' alt='aiIcon' />{' '}

              <h2 style={{ fontWeight: 'bolder', fontStyle: 'italic' }}>
                Auto OCR
              </h2>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AutoOcrHome;
