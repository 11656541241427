import dispatcher from '../Dispatcher';
import * as Globals from '../Globals';
import axios from 'axios';
import actionTypes from './ActionTypes';

export function CheckMSButton() {
  axios
    .get(`${Globals.baseUrl}/Login/GetUseOauthSetting`, {
      headers: { 'content-type': 'application/json; charset=utf-8' },
    })
    .then((res) => {
      if (res.status === 200) {
        dispatcher.dispatch({
          actionType: actionTypes.Login_CheckMSButton,
          Result: res.data,
        });
      } else if (res.status === 400 || res.status === 401) {
        console.log('error: 401');
      }
    })
    .catch((res) => {
      console.log(res);
    });
}



export function CheckTDVersion() {
  axios
    .get(
      `${Globals.baseUrl}/Login/GetTDVersion`,
      {
        headers: { 'content-type': 'application/json; charset=utf-8' },
      }
    )
    .then((res) => {
      if (res.status === 200) {
        dispatcher.dispatch({
          actionType: actionTypes.LoginCheckVer,
          Result: res.data,
        });
      } else if (res.status === 400 || res.status === 401) {
        console.log('error: 401');
      }
    });
}


export function LoginViaTd6Token(token) {
  axios
    .post(
      `${Globals.baseUrl}/Login/LogInViaTd6Token`,
      { TokenValue: token },
      {
        headers: { 'content-type': 'application/json; charset=utf-8' },
      }
    )
    .then((res) => {
      if (res.status === 200) {
        dispatcher.dispatch({
          actionType: actionTypes.Login_Silent,
          Result: res.data,
        });
      } else if (res.status === 400 || res.status === 401) {
        console.log('error: 401');
      }
    });
}

export function LoginViaMSOAuth(azureToken) {
  axios
    .get(`${Globals.baseUrl}/Login/LogInViaMSOauth/${azureToken}`, {
      headers: { 'content-type': 'application/json; charset=utf-8' },
    })
    .then((res) => {
      if (res.status === 200) {
        dispatcher.dispatch({
          actionType: actionTypes.Login_Silent,
          Result: res.data,
        });
      } else if (res.status === 400 || res.status === 401) {
        console.log('error: 401');
      }
    });
}

export function LoginViaUserIdPwd(userId, password) {
  axios
    .post(
      `${Globals.baseUrl}/Login/LogInViaUserIdPwd`,
      { UserId: userId, Password: password },
      {
        headers: { 'content-type': 'application/json; charset=utf-8' },
      }
    )
    .then((res) => {
      if (res.status === 200) {
        dispatcher.dispatch({
          actionType: actionTypes.Login_ViaPassword,
          Result: res.data,
        });
      } else if (res.status === 400 || res.status === 401) {
        console.log('error: 401');
      }
    })
    .catch((res) => {
      console.log('error');
      console.log(res);
      dispatcher.dispatch({
        actionType: actionTypes.Login_ViaPassword,
        Result: {
          isLoggedIn: false,
          jwtToken: '',
          message: 'Unable to reach server',
          userFullName: '',
          userId: 0,
          userName: '',
        },
      });
    });
}

export function Logout() {
  axios
    .get(`${Globals.baseUrl}/Login/Logout`, {
      headers: {
        'content-type': 'application/json; charset=utf-8',
        Authorization: `Bearer ${Globals.getJwtToken()}`,
      },
    })
    .then((res) => {
      dispatcher.dispatch({
        actionType: actionTypes.Login_Logout,
        Result: null,
      });
    });
}

export function CanUserWebUpload() {
  axios
    .get(`${Globals.baseUrl}/Login/CanUserWebUpload`, {
      headers: {
        'content-type': 'application/json; charset=utf-8',
        Authorization: `Bearer ${Globals.getJwtToken()}`,
      },
    })
    .then((res) => {
      if (res.status === 200) {
        dispatcher.dispatch({
          actionType: actionTypes.CanUserWebUpload,
          Result: res.data,
        });
      } else if (res.status === 400 || res.status === 401) {
        Logout();
      }
    })
    .catch((res) => {});
}

export function LoginViaMicrosoft() {
  console.log('Login via Microsoft');
  console.log('sign in');

  axios
    .post(`${Globals.baseUrl}/Login/LoginViaMicrosoft`, null, {
      headers: {
        'content-type': 'application/json; charset=utf-8',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
      },
    })
    .then((res) => {
      if (res.status === 200) {
        console.log(res.data);

        window.location.href = res.data.address;
        //LoginViaToken(res.data.address);
      } else if (res.status === 400 || res.status === 401) {
        console.log('error: 401');
      }
    })
    .catch((res) => {
      console.log('error');
    });
}
