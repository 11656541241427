import * as React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';

import actionTypes from '../../../../actions/ActionTypes';
import { GetModuleList } from '../../../../actions/ModuleActions';
import ModuleStore from '../../../../stores/ModuleStore';
//import { GetAllFormTypes } from '../../../actions/FormTypeActions';

class ModuleSidebar extends React.Component {
  constructor() {
    super();
    this.state = {
      moduleList: [],
      searchText: '',
      filteredModules: [],
      maxHeight: (window.innerHeight - 280)
    };
    this.onReceiveModuleChange = this.onReceiveModuleChange.bind(this);
    this.onSearchChange = this.onSearchChange.bind(this);
  }

  componentDidMount() {
    ModuleStore.addChangeListener(this.onReceiveModuleChange);
    GetModuleList();
  }

  componentWillUnmount() {
    ModuleStore.removeChangeListener(this.onReceiveModuleChange);
  }

  onReceiveModuleChange() {
    var lastChange = ModuleStore.getLastChange();
    if (lastChange === actionTypes.ModulesGetList) {
      var modList = ModuleStore.getModuleList();
      var filter = [...modList];
      this.setState({ moduleList: modList, filteredModules: filter });
    }
    //let lastChange = FormTypeStore.getLastAction();
    /*if (lastChange.actionType === 'FTGetAll') {
      let formTypes = FormTypeStore.getAllFormTypes();
      this.setState({
        formTypeList: formTypes,
        searchText: '',
        filteredFormTypeList: formTypes,
      });
    }*/
  }

  onSearchChange(e) {
    let search = e.target.value;
    if (search === '') {
      // no search, show all
      let newList = [...this.state.formTypeList];
      this.setState({ searchText: search, filteredModules: newList });
    } else {
      // filter results
      let newList = [];
      this.state.formTypeList.forEach((formType) => {
        if (formType.name.toLowerCase().includes(search.toLowerCase())) {
          newList.push(formType);
        }
      });
      this.setState({ searchText: search, filteredModules: newList });
    }
  }

  render() {
    let listOfMods;
    if (this.state.filteredModules !== undefined) {
      listOfMods = this.state.filteredModules.map((module) => (
        <div key={module.moduleId}>
          <span className='me-2' style={{ color: '#B7E4EA', fontSize: '10pt' }}>
            <FontAwesomeIcon icon={solid('box')} />
          </span>
          <Link
            className='sidebar-link2'
            to={'/Admin/Module/' + module.moduleId}
          >
            {module.moduleName}
          </Link>
          <div style={{ marginLeft: '12px', fontSize: '10pt' }}>
            {module.items.map((plugin) => (
              <div key={plugin.integrationId}>
                <span
                  className='me-2'
                  style={{ color: '#C5C5C5', fontSize: '10pt' }}
                >
                  {plugin.integrationType === 'Schedule' && (
                    <FontAwesomeIcon icon={regular('clock')} />
                  )}
                  {plugin.integrationType === 'Workflow' && (
                    <FontAwesomeIcon icon={solid('sitemap')} />
                  )}
                  {plugin.integrationType === 'Runtime' && (
                    <FontAwesomeIcon icon={solid('running')} />
                  )}
                  {plugin.integrationType === 'Lookup' && (
                    <FontAwesomeIcon icon={solid('list-ul')} />
                  )}
                  {plugin.integrationType === 'Intake' && (
                    <FontAwesomeIcon icon={solid('truck-moving')} />
                  )}
                </span>
                {plugin.integrationName}
              </div>
            ))}
          </div>
        </div>
      ));
    } else {
      listOfMods = <div />;
    }
    return (
      <div
        className='d-flex flex-column p-3 text-white bg-dark'
        style={{ height: '100%', width: '380px' }}
      >
        <div //link
          to='/Admin/Modules'
          className='d-flex align-items-center mb-3 mb-md-0 me-md-auto text-white text-decoration-none'
        >
          <span className='fs-4'>
            <span style={{ color: '#6CE84E' }}>
              <FontAwesomeIcon icon={solid('box')} />
            </span>
            {'   '}
            Modules
          </span>
        </div>{' '}
        {/* link */}
        <hr />
        <div className='input-group rounded' style={{ marginBottom: '16px' }}>
          <input
            type='search'
            value={this.state.searchText}
            className='form-control'
            placeholder='Search'
            aria-label='Search'
            onChange={this.onSearchChange}
            aria-describedby='search-addon'
          />
          <span className='input-group-text border-0' id='search-addon'>
            <FontAwesomeIcon icon={solid('search')} />
          </span>
        </div>
        <div className='coolScroll' style={{maxHeight: `${this.state.maxHeight}px`, overflowY: 'auto'}}>{listOfMods}</div>
        <hr />
        <Link className='sidebar-link' to='/Admin/Module/0'>
          New Module
        </Link>
      </div>
    );
  }
}

export default ModuleSidebar;
