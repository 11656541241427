// @flow
import * as React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';

import IAStore from '../../../../stores/IAStore';
import { GetIAList } from '../../../../actions/IAActions';

class InboundAutomationSidebar extends React.Component {
  //isList: Array<BPModel>;
  constructor(props) {
    super(props);
    this.state = {
        iaList: [],
        searchText: '',
        filteredIaList: [],
    };

    this.onReceiveIAChange = this.onReceiveIAChange.bind(this);
    this.onSearchChange = this.onSearchChange.bind(this);
  }

  componentDidMount() {
    IAStore.addChangeListener(this.onReceiveIAChange);
    GetIAList();
  }

  componentWillUnmount() {
    IAStore.removeChangeListener(this.onReceiveIAChange);
  }

  onReceiveIAChange() {
    let lastChange = IAStore.lastChange();
    if (lastChange === 'IAList') {
       let ias = IAStore.getAllIAs();

       this.setState({ iaList: ias, searchText: '', filteredIaList: ias });
    }
  }

  onSearchChange(e) {
    let search = e.target.value;
    if (search === '') {
       // no search, show all
       let newList = [...this.state.iaList];

       this.setState({ searchText: search, filteredIaList: newList });
    } else {
       // filter results
       let newList = [];
       this.state.iaList.forEach((ia) => {
          if (ia.mailBoxName.toLowerCase().includes(search.toLowerCase())) {
             newList.push(ia);
          }
       });
       this.setState({ searchText: search, filteredIaList: newList });
    }
 }

  render() {
    let listOfIAs;
      if (
         this.state.filteredIaList !== undefined &&
         this.state.filteredIaList !== null
      ) {
         listOfIAs = this.state.filteredIaList.map((ia) => (
            <Link className='sidebar-link'
               to={
                  '/Admin/inboundAutomationView/' +
                  ia.inboundAutomationId
               }
               key={ia.inboundAutomationId}
               style={{ cursor: 'Pointer', wordWrap: 'break-word' }}
               
            >
               { + ia.isActive == true? ia.mailBoxName: ia.mailBoxName+'-Inactive'}
            </Link>
         ));
      } else {
         listOfIAs = <div />;
      }
      return (
         <div
            className='d-flex flex-column p-3 text-white bg-dark'
            style={{ height: '100%', width: '245px' }}
         >
            <Link
               to='/Admin/InboundAutomationHome'
               className='d-flex align-items-center mb-3 mb-md-0 me-md-auto text-white text-decoration-none'
            >
               <span style={{fontSize: '14pt'}}>
                  <FontAwesomeIcon icon={regular('envelope-open')} />
                  {'   '}
                  InboundAutomations
               </span>
            </Link>
            <hr />
            <div
               className='input-group rounded'
               style={{ marginBottom: '16px' }}
            >
               <input
                  type='search'
                  value={this.state.searchText}
                  className='form-control'
                  placeholder='Search'
                  aria-label='Search'
                  onChange={this.onSearchChange}
                  aria-describedby='search-addon'
               />
               <span className='input-group-text border-0' id='search-addon'>
                  <FontAwesomeIcon icon={solid('search')} />
               </span>
            </div>
            {listOfIAs}
            <hr />
            <Link className='sidebar-link' to='/Admin/newIAView'>New InboundAutomation</Link>
         </div>
      );
  }
}

export default InboundAutomationSidebar;
