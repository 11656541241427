import dispatcher from '../Dispatcher';
import actionTypes from './ActionTypes';

export function PostAlert(alertType, message) {
  let newAlert = {
    alertType: alertType,
    message: message,
  };
  dispatcher.dispatch({
    actionType: actionTypes.AlertAdd,
    Result: newAlert,
  });
}

export function RemoveAlert(id) {
  dispatcher.dispatch({
    actionType: actionTypes.AlertDelete,
    Result: id,
  });
}
