export class IntakeStrategyModel {
  id;
  ocrMethod;
  // 1 = No OCR
  // 2 = Text Only
  // 3 = Smart OCR
  // 4 = FullOCR
  isName;
  ocrQuality;
  // 1 = standard
  // 2 = high
  // 3 = highest
  // 4 = use system default
  enablePreSplit;
  enableBarcodeSplit;
  objectId;
  readBarcode;
  isDefault;
  useFastPass;

  barCodeSplits;
  //barCodeSplitsVerbose: Array<BarcodeSplit>;
  templateStrategy;
  ftpId;
  templateId;
  intakeTargets;

  dupeDetection;
}

export class BarcodeSplit {
  barCodeId;
  barCodeName;
  barCodeFormula;
}

export class IntakeStrategyTarget {
  priority;
  isEditing;
  bpId;
  bpName;
  workflowId;
  formTypeId;
}
