export class WebUpload {
  userName;
  file;
  //fileName;

  routingType;
  defaultFtp;
  defaultTemplate;
  intakeStrategyId;
}

export class WebUploadResult {
  uploadId;
  uploadStatus;
  error;
}
