import * as React from 'react';
import { createBrowserHistory } from 'history';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { Dropdown } from 'react-bootstrap';
import UsersSidebar from './UsersSidebar';
import {
  GetAllUserList,
  GetUser,
  ChangePassword,
  SaveUser,
} from '../../../actions/UserActions';
import { GetLicenseSummary } from '../../../actions/LicenseActions';
import { GetRoleList } from '../../../actions/RoleActions';
import { GetUserInRoleForUser } from '../../../actions/UserInRoleAction';
import UserStore from '../../../stores/UserStore';
import LicenseStore from '../../../stores/LicenseStore';
import RoleStore from '../../../stores/RoleStore';
import UserInRoleStore from '../../../stores/UserInRoleStore';
import actionTypes from '../../../actions/ActionTypes';

const customHistory = createBrowserHistory();

/*import { UpdateName } from '../../../Actions/BPActions';
import ISStore from '../../../Stores/IntakeStrategyStore';
import { IntakeStrategyModel } from '../../../Models/Admin/IntakeStrategy';*/
/*import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
   faCog,
   faSitemap,
   faFileInvoice,
} from '@fortawesome/free-solid-svg-icons';*/
class UsersView extends React.Component {
  constructor(props) {
    // state
    super(props);

    this.state = {
      currentUser: {
        userId: 1,
        userName: 'system',
        isPermanant: true,
        licenseLevel: 0,
        emailAddress: 'trindocs@trindocs.com',
        //licenseLevelName: 'System Admin',
        //licenseStyle: 'llbtn-sysadmin',
        licenseLevelName: 'System Manager',
        licenseStyle: 'llbtn-busadmin',
        firstName: 'system',
        lastName: 'acct',
        isActive: true,
        isADuser: false,
        isNew: false,

        roles: [],
        sendEmailNotifications: false,
      },
      currentView: 'roles',
      password1: '',
      password2: '',
      passwordError: '',
      isDirty: false,
      licenseSummary: undefined,
      hasError: false,
      errorMessage: '',
      allRoles: [],
      busMgrRoles: [],
      approverRoles: [],
      viewerRoles: [],
      aliasSearchText: '',
      initialLicenseLevel: '',
      hasLicenseCountChanged: false,
      newLicenseCount: 0,
      invalidLicenseChange: false,
    };

    this.componentDidUpdate = this.componentDidUpdate.bind(this);
    this.setLicenseLevel = this.setLicenseLevel.bind(this);
    this.onChangeUserName = this.onChangeUserName.bind(this);
    this.loadUserFromDB = this.loadUserFromDB.bind(this);
    this.onUserUpdate = this.onUserUpdate.bind(this);
    this.onChangePassword1 = this.onChangePassword1.bind(this);
    this.onChangePassword2 = this.onChangePassword2.bind(this);
    this.onChangePasswordClick = this.onChangePasswordClick.bind(this);
    this.onChangeEmail = this.onChangeEmail.bind(this);
    this.onChangeFirstName = this.onChangeFirstName.bind(this);
    this.onChangeLastName = this.onChangeLastName.bind(this);
    this.onSaveClick = this.onSaveClick.bind(this);
    this.onLicenseUpdate = this.onLicenseUpdate.bind(this);
    this.isActiveChange = this.isActiveChange.bind(this);
    this.isADUserChange = this.isADUserChange.bind(this);
    this.onRoleUpdate = this.onRoleUpdate.bind(this);
    this.toggleRolePermission = this.toggleRolePermission.bind(this);
    this.onUserRoleChange = this.onUserRoleChange.bind(this);
    this.toggleSendEmailNotifications = this.toggleSendEmailNotifications.bind(this);
  }

  componentDidMount() {
    UserStore.addChangeListener(this.onUserUpdate);
    LicenseStore.addChangeListener(this.onLicenseUpdate);
    RoleStore.addChangeListener(this.onRoleUpdate);
    UserInRoleStore.addChangeListener(this.onUserRoleChange);
    this.loadUserFromDB();
    GetLicenseSummary();
    GetRoleList();
  }

  componentWillUnmount() {
    UserStore.removeChangeListener(this.onUserUpdate);
    LicenseStore.removeChangeListener(this.onLicenseUpdate);
    RoleStore.removeChangeListener(this.onRoleUpdate);
    UserInRoleStore.removeChangeListener(this.onUserRoleChange);
  }

  componentDidUpdate() {
    this.loadUserFromDB();
  }

  onUserUpdate() {
    let lastChange = UserStore.getLastChange();
    if (lastChange === actionTypes.UsersGetOne) {
      let newUser = UserStore.getCurrentUser();

      if (newUser.licenseLevel === 4 || newUser.licenseLevel === 5) {
        newUser.licenseLevelName = 'System Manager';
        newUser.licenseStyle = 'llbtn-sysadmin';
      }
      if (newUser.licenseLevel === 5) {
        newUser.isPermanant = true;
      } else {
        newUser.isPermanant = false;
      }
      if (newUser.licenseLevel === 3) {
        newUser.licenseLevelName = 'Admin';
        newUser.licenseStyle = 'llbtn-busadmin';
      }
      if (newUser.licenseLevel === 2) {
        newUser.licenseLevelName = 'Approver';
        newUser.licenseStyle = 'llbtn-approver';
      }
      if (newUser.licenseLevel === 1) {
        newUser.licenseLevelName = 'Viewer';
        newUser.licenseStyle = 'llbtn-viewer';
      }

      this.setState({
        currentUser: newUser,
        initialLicenseLevel: newUser.licenseLevelName,
        hasError: false,
      });
    }

    if (lastChange === actionTypes.UsersUpdateUser) {
      this.setState({ isDirty: false, hasError: false });
      GetLicenseSummary();
      GetAllUserList();
    }
    if (lastChange === actionTypes.UserChangePassword) {
      this.setState({ password1: '', password2: '' });
    }
    if (lastChange === 'UserCreateUser') {
      this.setState({ isDirty: false, hasError: false });
      let cu = UserStore.getCurrentUser();
      customHistory.push(`/Admin/UsersView/${cu.userId}`);
      GetLicenseSummary();
      GetAllUserList();
    }
    if (lastChange === 'UserUpdateError') {
      let err = UserStore.getLastError();
      this.setState({ hasError: true, errorMessage: err });
    }

    //this.onUserRoleChange();
  }

  onLicenseUpdate() {
    let ls = LicenseStore.getLicenseSummary();
    ls.sysManagerUsed = ls.sysManagerUsed - 2;
    ls.sysManagerCount = ls.sysManagerCount - 2;
    this.setState({ licenseSummary: ls });
  }

  onRoleUpdate() {
    let action = RoleStore.getLastChange();

    if (action === actionTypes.RolesGetRoles) {
      let allRoles = RoleStore.getRoleList();

      let bmRoles = [];
      let appRoles = [];
      let vRoles = [];
      allRoles.forEach((r) => {
        if (r.licenseLevel === 3) {
          bmRoles.push(r);
        }
        if (r.licenseLevel === 2) {
          appRoles.push(r);
        }
        if (r.licenseLevel === 1) {
          vRoles.push(r);
        }
      });

      this.setState({
        allRoles: allRoles,
        busMgrRoles: bmRoles,
        approverRoles: appRoles,
        viewerRoles: vRoles,
      });
    }
  }

  onUserRoleChange() {
    let cu = this.state.currentUser;
    cu.roles = [];
    this.setState({ currentUser: cu });
    let action = UserInRoleStore.getLastChange();
    if (action === actionTypes.UserInRoleGetList) {
      let allUserRoles = UserInRoleStore.getUserRoleList();
      allUserRoles.forEach((u) => {
        var result = u.roleId;
        if (result === undefined) {
          return;
        }
        // we want to add Role Id to list of roles in current User
        let idx = this.state.currentUser.roles.indexOf(u.roleId);
        if (idx > -1) {
          let newRoles = this.state.currentUser.roles;
          let cu = this.state.currentUser;
          newRoles.splice(idx, 1);
          cu.roles = newRoles;
          this.setState({ currentUser: cu });
        } else {
          let cu = this.state.currentUser;
          let newRoles = cu.roles;
          newRoles.push(u.roleId);
          cu.roles = newRoles;
          this.setState({ currentUser: cu });
        }
      });
    }
  }

  toggleSendEmailNotifications(e) {
    let cu = this.state.currentUser;
    cu.sendEmailNotifications = e.target.checked;
    this.setState({ currentUser: cu, isDirty: true });
  }

  detectParam(id) {
    //console.log(`Detected param: ${id}`);
  }

  setLicenseLevel(levelId) {
    let style = '';
    let name = '';
    let currentCount = 0;
    let invalidLicenseChange = false;
    switch (levelId) {
      case 4:
        style = 'llbtn-sysadmin';
        name = 'System Manager';
        currentCount = this.state.licenseSummary.sysManagerUsed;
        if (
          this.state.licenseSummary.sysManagerUsed >=
          this.state.licenseSummary.sysManagerCount
        ) {
          invalidLicenseChange = true;
        }
        break;
      case 3:
        style = 'llbtn-busadmin';
        name = 'Admin';
        currentCount = this.state.licenseSummary.adminUsed;
        if (
          this.state.licenseSummary.adminUsed >=
          this.state.licenseSummary.adminCount
        ) {
          invalidLicenseChange = true;
        }
        break;

      case 2:
        style = 'llbtn-approver';
        name = 'Approver';
        currentCount = this.state.licenseSummary.approverUsed;
        if (
          this.state.licenseSummary.approverUsed >=
          this.state.licenseSummary.approverCount
        ) {
          invalidLicenseChange = true;
        }
        break;

      case 1:
        style = 'llbtn-viewer';
        name = 'Viewer';
        currentCount = this.state.licenseSummary.viewerUsed;
        break;
      default:
    }
    let cu = this.state.currentUser;
    cu.licenseLevel = levelId;
    cu.licenseLevelName = name;
    cu.licenseStyle = style;

    let newCount = currentCount + 1;
    var hasLicenseChanged = true;
    // do not show the New License Count text if the new user is a viewer -- there is no limit
    // OR
    // do not show if they change to what they initially were.
    // For example, a change from approver to admin, BACK to approver without saving. Do not show the new approver count because it hasn't changed
    if (name === this.state.initialLicenseLevel || name === 'Viewer') {
      hasLicenseChanged = false;
    }

    this.setState({
      currentUser: cu,
      isDirty: true,
      hasLicenseCountChanged: hasLicenseChanged,
      newLicenseCount: newCount,
      invalidLicenseChange: invalidLicenseChange,
    });
  }

  loadUserFromDB() {
    let loc = window.location.pathname;
    let pathParts = loc.split('/');
    let userId = parseInt(pathParts[pathParts.length - 1], 10);
    if (this.state.currentUser.userId !== 0 && userId === 0) {
      let newUser = {
        userId: 0,
        userName: '',
        isPermanant: false,
        licenseLevel: 1,
        emailAddress: '',

        licenseLevelName: 'Viewer',
        licenseStyle: 'llbtn-viewer',
        firstName: '',
        lastName: '',
        isActive: true,
        isNew: true,
        isADuser: false,

        roles: [],
      };

      this.setState({
        currentUser: newUser,
        initialLicenseLevel: newUser.licenseLevelName,
        isDirty: true,
      });
    } else if (this.state.currentUser.userId !== userId) {
      let user = this.state.currentUser;
      user.userId = userId;
      this.setState({ currentUser: user, isDirty: false });
      GetUser(userId);
      GetUserInRoleForUser(userId);
    }
  }

  onChangeUserName(e) {
    let cu = this.state.currentUser;
    cu.userName = e.target.value;
    this.setState({ currentUser: cu, isDirty: true });
  }

  onChangeFirstName(e) {
    let cu = this.state.currentUser;
    cu.firstName = e.target.value;
    this.setState({ currentUser: cu, isDirty: true });
  }

  onChangeLastName(e) {
    let cu = this.state.currentUser;
    cu.lastName = e.target.value;
    this.setState({ currentUser: cu, isDirty: true });
  }

  onChangeEmail(e) {
    let cu = this.state.currentUser;
    cu.emailAddress = e.target.value;
    this.setState({ currentUser: cu, isDirty: true });
  }

  switchView(newView) {
    this.setState({ currentView: newView });
  }

  onChangePassword1(e) {
    this.setState({ password1: e.target.value });
  }

  onChangePassword2(e) {
    this.setState({ password2: e.target.value });
  }

  onSaveClick() {
    // validate
    let cu = this.state.currentUser;
    SaveUser(this.state.currentUser.userId, {
      userId: cu.userId,
      userName: cu.userName,
      firstName: cu.firstName,
      lastName: cu.lastName,
      emailAddress: cu.emailAddress,
      licenseLevel: cu.licenseLevel,
      isActive: cu.isActive,
      isADuser: cu.isADuser,
      roles: cu.roles,
      sendEmailNotifications: cu.sendEmailNotifications
    });
  }

  isActiveChange(e) {
    let cu = this.state.currentUser;
    cu.isActive = e.target.checked;
    this.setState({ currentUser: cu, isDirty: true });
  }

  isADUserChange(e) {
    let cu = this.state.currentUser;
    cu.isADuser = e.target.checked;
    this.setState({ currentUser: cu, isDirty: true });
  }

  onChangePasswordClick() {
    if (this.state.password1.length === 0) {
      this.setState({ passwordError: 'Password is required' });
    } else if (this.state.password1.length <= 4) {
      this.setState({
        passwordError: 'Password must be greater than four characters',
      });
    } else if (this.state.password1 !== this.state.password2) {
      this.setState({ passwordError: "Passwords don't match" });
    } else if (this.state.currentUser.userId === 0) {
      this.setState({
        passwordError: 'Must create user account before setting password',
      });
    } else {
      this.setState({ passwordError: '' });
      ChangePassword(this.state.currentUser.userId, this.state.password2);
    }
  }
  toggleRolePermission(role) {
    let idx = this.state.currentUser.roles.indexOf(role.id);
    if (idx > -1) {
      let newRoles = this.state.currentUser.roles;
      let cu = this.state.currentUser;
      newRoles.splice(idx, 1);
      cu.roles = newRoles;
      this.setState({ currentUser: cu, isDirty: true });
    } else {
      let cu = this.state.currentUser;
      let newRoles = cu.roles;
      newRoles.push(role.id);
      cu.roles = newRoles;
      this.setState({ currentUser: cu, isDirty: true });
    }
  }

  onAliasSearchChange() {}

  render() {
    return (
      <div className='sidebarLayout d-flex flex-row flex-fill'>
        <div className='sidebar-container'>
          <UsersSidebar />
        </div>
        <div
          className='flex-fill'
          style={{
            display: 'grid',
            gridTemplateColumns: '650px 1fr',
            gridTemplateRows: '350px 60px 1fr 15px',
          }}
        >
          <div
            id='userInfo'
            className='card'
            style={{ gridColumn: '1', gridRow: '1', margin: '16px' }}
          >
            <div className='card-header'>
              <FontAwesomeIcon icon={solid('user')} /> User Info
            </div>
            <div className='card-body'>
              <div className='row'>
                <div className='form-group col-6'>
                  <label htmlFor='txtUserName'>Username</label>
                  <input
                    type='text'
                    disabled={this.state.currentUser.isPermanant}
                    className={`form-control`}
                    id='txtUserName'
                    value={this.state.currentUser.userName}
                    onChange={this.onChangeUserName}
                    placeholder='userName'
                  />
                </div>
                <div className='form-group col-6'>
                  <label htmlFor='txtUserName'>License Level</label>
                  <Dropdown>
                    <Dropdown.Toggle
                      id='ddLicenseLevel'
                      className={
                        this.state.currentUser.isPermanant
                          ? 'disabled btn-danger'
                          : this.state.currentUser.licenseStyle
                      }
                    >
                      {this.state.currentUser.licenseLevelName}
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item
                        href=''
                        onClick={() => this.setLicenseLevel(1)}
                      >
                        Viewer
                      </Dropdown.Item>
                      <Dropdown.Item
                        href=''
                        onClick={() => this.setLicenseLevel(2)}
                      >
                        Approver
                      </Dropdown.Item>
                      <Dropdown.Item
                        href=''
                        onClick={() => this.setLicenseLevel(3)}
                      >
                        Admin
                      </Dropdown.Item>
                      <Dropdown.Item
                        href=''
                        onClick={() => this.setLicenseLevel(4)}
                      >
                        System Manager
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                  {this.state.hasLicenseCountChanged && (
                    <span>
                      New License Count:{' '}
                      <span
                        style={
                          this.state.invalidLicenseChange
                            ? { color: 'red' }
                            : {}
                        }
                      >
                        {this.state.newLicenseCount}
                      </span>
                    </span>
                  )}
                </div>
              </div>
              <div className='row' style={{ marginTop: '16px' }}>
                <div className='form-group col-6'>
                  <label htmlFor='txtFirstName'>First Name</label>
                  <input
                    type='text'
                    disabled={this.state.currentUser.isPermanant}
                    className='form-control'
                    id='txtFirstName'
                    value={this.state.currentUser.firstName}
                    onChange={this.onChangeFirstName}
                    placeholder='first name'
                  />
                </div>
                <div className='form-group col-6'>
                  <label htmlFor='txtLastName'>Last Name</label>
                  <input
                    type='text'
                    disabled={this.state.currentUser.isPermanant}
                    className='form-control'
                    id='txtLastName'
                    value={this.state.currentUser.lastName}
                    onChange={this.onChangeLastName}
                    placeholder='last name'
                  />
                </div>
              </div>
              <div className='row' style={{ marginTop: '16px' }}>
                <div className='form-group col-6'>
                  <label htmlFor='txtEmail'>Email Address</label>
                  <input
                    type='text'
                    className='form-control'
                    id='txtEmail'
                    value={this.state.currentUser.emailAddress}
                    onChange={this.onChangeEmail}
                    placeholder='last name'
                  />
                </div>
                <div className='col-6'>
                  <div className='form-check form-switch'>
                    <input
                      disabled={this.state.currentUser.isPermanant}
                      type='checkbox'
                      className='form-check-input'
                      checked={this.state.currentUser.isActive}
                      onChange={this.isActiveChange}
                      id='chkActive'
                    />
                    <label className='form-check-label' htmlFor='chkActive'>
                      Active
                    </label>
                  </div>
                  <div className='form-check form-switch'>
                    <input
                      disabled={this.state.currentUser.isPermanant}
                      type='checkbox'
                      className='form-check-input'
                      checked={this.state.currentUser.isADuser}
                      onChange={this.isADUserChange}
                      id='chkIsAD'
                    />
                    <label className='form-check-label' htmlFor='chkIsAD'>
                      Is AD User
                    </label>
                  </div>
                  
                  <div className='form-check form-switch'>
                    <input
                      type='checkbox'
                      className='form-check-input'
                      checked={this.state.currentUser.sendEmailNotifications}
                      onChange={this.toggleSendEmailNotifications}
                      id='chkActive'
                    />
                    <label className='form-check-label' htmlFor='sendEmailNotif'>
                      Send email notifications
                    </label>
                  </div>
                  
                </div>
              </div>
            </div>
          </div>
          <div
            style={{
              gridColumn: '2',
              gridRow: '1',
              margin: '16px',
              width: '500px',
              height: '200px',
            }}
          >
            <div className='card' style={{ width: '260px' }}>
              <div className='card-header'>
                <FontAwesomeIcon icon={solid('balance-scale')} /> License usage
              </div>
              <div className='card-body'>
                {this.state.licenseSummary !== undefined && (
                  <table>
                    <tbody>
                      <tr>
                        <td style={{ width: '180px' }}>System Managers:</td>
                        <td style={{ textAlign: 'right', color: '#349920' }}>
                          {this.state.licenseSummary.sysManagerUsed}/
                          {this.state.licenseSummary.sysManagerCount}
                        </td>
                      </tr>
                      <tr>
                        <td>Admin:</td>
                        <td style={{ textAlign: 'right', color: '#349920' }}>
                          {this.state.licenseSummary.adminUsed}/
                          {this.state.licenseSummary.adminCount}
                        </td>
                      </tr>
                      <tr>
                        <td>Approvers:</td>
                        <td style={{ textAlign: 'right', color: '#349920' }}>
                          {this.state.licenseSummary.approverUsed}/
                          {this.state.licenseSummary.approverCount}
                        </td>
                      </tr>
                      <tr>
                        <td>Viewers:</td>
                        <td style={{ textAlign: 'right', color: '#349920' }}>
                          {this.state.licenseSummary.viewerUsed}/∞
                        </td>
                      </tr>
                    </tbody>
                  </table>
                )}
              </div>
            </div>
            {this.state.hasError && (
              <div className='alert alert-danger' style={{ marginTop: '16px' }}>
                {this.state.errorMessage}
              </div>
            )}
          </div>

          <div
            id='userPills'
            style={{
              gridColumnStart: '1',
              gridColumnEnd: '3',
              gridRow: '2 / 2',
              margin: '16px',
            }}
          >
            <ul className='nav nav-pills'>
              <li className='nav-item'>
                <span
                  className={`nav-link ${
                    this.state.currentView === 'roles' ? 'active' : ''
                  }`}
                  style={{ cursor: 'pointer' }}
                  onClick={() => this.switchView('roles')}
                >
                  Roles
                </span>
              </li>

              <li className='nav-item'>
                <span
                  className={`nav-link ${
                    this.state.currentView === 'password' ? 'active' : ''
                  }`}
                  style={{ cursor: 'pointer' }}
                  onClick={() => this.switchView('password')}
                >
                  Password
                </span>
              </li>

              {this.state.isDirty && (
                <li className='nav_item'>
                  <button
                    className='btn btn-success'
                    onClick={this.onSaveClick}
                    style={{ marginLeft: '30px' }}
                    disabled={this.state.invalidLicenseChange}
                  >
                    <FontAwesomeIcon icon={solid('save')} /> Save Changes
                  </button>
                </li>
              )}
            </ul>
          </div>

          {this.state.currentView === 'roles' &&
            this.state.currentUser.licenseLevel > 0 &&
            this.state.currentUser.licenseLevel <= 3 && (
              <div
                id='userRoles'
                className='card'
                style={{
                  gridColumnStart: '1',
                  gridColumnEnd: '3',
                  gridRow: '3 / 3',
                  margin: '16px',
                }}
              >
                <div className='card-header'>Roles</div>
                <div className='card-body'>
                  <div className='row'>
                    {this.state.currentUser.licenseLevel >= 3 && (
                      <div className='col-4'>
                        <div className='card'>
                          <div className='card-header'>Admin</div>
                          <div className='card-body'>
                            {this.state.busMgrRoles.map((role) => (
                              <div
                                className='form-check form-switch'
                                key={role.id}
                              >
                                <input
                                  type='checkbox'
                                  className='form-check-input'
                                  id={`chkRole_${role.id}`}
                                  checked={
                                    this.state.currentUser.roles.indexOf(
                                      role.id
                                    ) !== -1
                                  }
                                  onChange={() =>
                                    this.toggleRolePermission(role)
                                  }
                                />
                                <label
                                  className='form-check-label'
                                  htmlFor='chkAP2'
                                >
                                  {role.name}
                                </label>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    )}

                    {this.state.currentUser.licenseLevel >= 2 && (
                      <div className='col-4'>
                        <div className='card'>
                          <div className='card-header'>Approver</div>
                          <div className='card-body'>
                            {this.state.approverRoles.map((role) => (
                              <div
                                className='form-check form-switch'
                                key={role.id}
                              >
                                <input
                                  type='checkbox'
                                  className='form-check-input'
                                  id={`chkRole_${role.id}`}
                                  checked={
                                    this.state.currentUser.roles.indexOf(
                                      role.id
                                    ) !== -1
                                  }
                                  onChange={() =>
                                    this.toggleRolePermission(role)
                                  }
                                />
                                <label
                                  className='form-check-label'
                                  htmlFor='chkAP2'
                                >
                                  {role.name}
                                </label>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    )}

                    {this.state.currentUser.licenseLevel >= 1 && (
                      <div className='col-4'>
                        <div className='card'>
                          <div className='card-header'>Viewer</div>
                          <div className='card-body'>
                            {this.state.viewerRoles.map((role) => (
                              <div
                                className='form-check form-switch'
                                key={role.id}
                              >
                                <input
                                  type='checkbox'
                                  className='form-check-input'
                                  checked={
                                    this.state.currentUser.roles.indexOf(
                                      role.id
                                    ) !== -1
                                  }
                                  onChange={() =>
                                    this.toggleRolePermission(role)
                                  }
                                  id={`chkRole_${role.id}`}
                                />
                                <label
                                  className='form-check-label'
                                  htmlFor='chkAP2'
                                >
                                  {role.name}
                                </label>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
          {this.state.currentView === 'aliases' && (
            <div
              id='Aliases'
              className='card'
              style={{
                gridColumnStart: '1',
                gridColumnEnd: '3',
                gridRow: '3 / 3',
                margin: '16px',
              }}
            >
              <div className='card-header'>Aliases</div>
              <div className='card-body'>
                {this.state.currentUser.licenseLevel >= 4 && (
                  <div>System Managers see all aliases</div>
                )}
                {this.state.currentUser.licenseLevel < 4 && (
                  <div className='row'>
                    <div className='col-4'>
                      <label className='form-label'>
                        Aliases for {this.state.currentUser.userName}:
                      </label>
                      <div className='list-group'>
                        <li className='list-group-item'>
                          <span>Glen Frey</span>{' '}
                          <button
                            className='btn btn-danger btn-sm'
                            style={{ float: 'right' }}
                          >
                            <FontAwesomeIcon icon={solid('times')} />
                          </button>
                        </li>
                        <li className='list-group-item'>
                          <span>Don Henley</span>{' '}
                          <button
                            className='btn btn-danger btn-sm'
                            style={{ float: 'right' }}
                          >
                            <FontAwesomeIcon icon={solid('times')} />
                          </button>
                        </li>
                        <li className='list-group-item'>
                          <span>Randy Meisner</span>{' '}
                          <button
                            className='btn btn-danger btn-sm'
                            style={{ float: 'right' }}
                          >
                            <FontAwesomeIcon icon={solid('times')} />
                          </button>
                        </li>
                      </div>
                      <button className='btn btn-success'>
                        <FontAwesomeIcon icon={solid('plus')} /> Add Alias
                      </button>
                    </div>
                    <div className='col-4'>
                      <div
                        className='input-group rounded'
                        style={{ marginBottom: '16px' }}
                      >
                        <input
                          type='search'
                          value={this.state.aliasSearchText}
                          className='form-control'
                          placeholder='Search for alias by Name'
                          aria-label='Search'
                          onChange={this.onAliasSearchChange}
                          aria-describedby='search-addon'
                        />
                        <span
                          className='input-group-text border-0'
                          id='search-addon'
                        >
                          <FontAwesomeIcon icon={solid('search')} />
                        </span>
                      </div>
                      <div
                        className='list-group'
                        style={{ maxHeight: '300px', overflow: 'auto' }}
                      >
                        <li className='list-group-item'>
                          <input
                            className='form-check-input me-1'
                            type='checkbox'
                            value=''
                          />{' '}
                          Nathan Overbey (noverbey)
                        </li>
                        <li className='list-group-item'>
                          <input
                            className='form-check-input me-1'
                            type='checkbox'
                            value=''
                          />{' '}
                          Heather Short (hshort)
                        </li>
                        <li className='list-group-item'>
                          <input
                            className='form-check-input me-1'
                            type='checkbox'
                            value=''
                          />{' '}
                          David Mack (dmack)
                        </li>
                        <li className='list-group-item'>
                          <input
                            className='form-check-input me-1'
                            type='checkbox'
                            value=''
                          />{' '}
                          David Mack (dmack)
                        </li>
                      </div>
                      <button
                        className='btn btn-success'
                        style={{ margin: '4px' }}
                      >
                        Add
                      </button>
                      <button
                        className='btn btn-secondary'
                        style={{ margin: '4px' }}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
          {this.state.currentView === 'password' && (
            <div
              id='Aliases'
              className='card'
              style={{
                gridColumnStart: '1',
                gridColumnEnd: '3',
                gridRow: '3 / 3',
                margin: '16px',
              }}
            >
              <div className='card-header'>Change Password</div>
              <div className='card-body'>
                <div className='row'>
                  <div className='form-group col-6'>
                    <label htmlFor='pwdPassword1'>New Password</label>
                    <input
                      type='password'
                      value={this.state.password1}
                      onChange={this.onChangePassword1}
                      className='form-control'
                      id='pwdPassword1'
                      placeholder='Password'
                    />
                  </div>
                </div>
                <div className='row' style={{ marginTop: '20px' }}>
                  <div className='form-group col-6'>
                    <label htmlFor='pwdPassword2'>Confirm New Password</label>
                    <input
                      type='password'
                      value={this.state.password2}
                      onChange={this.onChangePassword2}
                      className='form-control'
                      id='pwdPassword2'
                      placeholder='Password'
                    />
                    {this.state.passwordError.length > 0 && (
                      <div
                        style={{ marginTop: '10px' }}
                        className='alert alert-danger'
                        role='alert'
                      >
                        {this.state.passwordError}
                      </div>
                    )}
                  </div>
                </div>

                <button
                  onClick={this.onChangePasswordClick}
                  style={{ marginTop: '20px' }}
                  className='btn btn-primary'
                >
                  Change Password
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default UsersView;
