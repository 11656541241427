import { EventEmitter } from 'events';
import Dispatcher from '../Dispatcher';
import actionTypes from '../actions/ActionTypes';

const CHANGE_EVENT = 'bulkActionEvent';
let _bulkActionResult = {};
let _bulkWorkflowStepsResults = {};
let _bulkWorkflowAvailableTemplatesResults = {};
let _bulkBpResults = {};
let _lastChange = '';
let _lastError = '';

class BulkActionStore extends EventEmitter {
  addChangeListener(callback) {
    this.on(CHANGE_EVENT, callback);
  }

  removeChangeListener(callback) {
    this.removeListener(CHANGE_EVENT, callback);
  }

  emitChange() {
    this.emit(CHANGE_EVENT);
  }

  getBulkResults() {
    return _bulkActionResult;
  }

  getWorkflowStepsResults(){
    return _bulkWorkflowStepsResults;
  }

  getWorkflowAvailableTemplatesResults(){
    return _bulkWorkflowAvailableTemplatesResults;
  }

  getBulkBpResults(){
    return _bulkBpResults;
  }

  getLastChange() {
    return _lastChange;
  }

  getLastError() {
    return _lastError;
  }
}

const store = new BulkActionStore();

Dispatcher.register((action) => {
  switch (action.actionType) {
    case actionTypes.BulkAction:
      _bulkActionResult = action.Result;
      _lastChange = action.actionType;
      store.emitChange();
      break;
      case actionTypes.WorkflowStepAction:
        _bulkWorkflowStepsResults = action.Result;
        _lastChange = action.actionType;
        store.emitChange();
        break;
      case actionTypes.DocumentGetAvailTemplatesByBp:
        _bulkWorkflowAvailableTemplatesResults = action.Result;
        _lastChange = action.actionType;
        store.emitChange();
        break;
        case actionTypes.DocumentGetAvailTemplatesByBp2:
          _bulkWorkflowAvailableTemplatesResults = action.Result;
          _lastChange = action.actionType;
          store.emitChange();
          break;
      case actionTypes.DocumentGetAvailBpByBp:
        _bulkBpResults = action.Result;
        _lastChange = action.actionType;
        store.emitChange();
        break;
      case actionTypes.BulkDownload:
        _lastChange = action.actionType;
        store.emitChange();
        break;
    default:
  }
});

export default store;
