import { EventEmitter } from 'events';
import Dispatcher from '../Dispatcher';
import actionTypes from '../actions/ActionTypes';

const CHANGE_EVENT = 'systemSettingsChanges';
let _currentSystemSettings = {};
let _lastChange = '';
let _lastError = '';

class SystemSettingsStore extends EventEmitter {
  addChangeListener(callback) {
    this.on(CHANGE_EVENT, callback);
  }

  removeChangeListener(callback) {
    this.removeListener(CHANGE_EVENT, callback);
  }

  emitChange() {
    this.emit(CHANGE_EVENT);
  }

  getSystemSettings() {
    return _currentSystemSettings;
  }

  getLastChange() {
    return _lastChange;
  }

  getLastError() {
    return _lastError;
  }
}

const store = new SystemSettingsStore();

Dispatcher.register((action) => {
  switch (action.actionType) {
    case actionTypes.SystemGetSettings:
      _currentSystemSettings = action.Result;
      _lastChange = action.actionType;

      store.emitChange();
      break;
    case actionTypes.SystemUpdateSettings:
      _currentSystemSettings = action.Result;
      _lastChange = action.actionType;
      store.emitChange();
      break;
    default:
  }
});

export default store;
