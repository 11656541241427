import { EventEmitter } from 'events';
import Dispatcher from '../Dispatcher';
import actionTypes from '../actions/ActionTypes';

const CHANGE_EVENT = 'secretChange';
let _allDocumentTypes = undefined;
let _allFields = undefined;
let _allFieldIdentifiers = undefined;
let _allPatterns = undefined;
let _allFieldData = undefined;
let _allMappings = undefined;
let _currentDocumentType = undefined;
let _currentDocumentTypeId = undefined;
let _lastChange = '';
let _lastError = '';

class AutoOcrStore extends EventEmitter {
  addChangeListener(callback) {
    this.on(CHANGE_EVENT, callback);
  }

  removeChangeListener(callback) {
    this.removeListener(CHANGE_EVENT, callback);
  }

  emitChange() {
    this.emit(CHANGE_EVENT);
  }

  getDocumentTypes() {
    return _allDocumentTypes;
  }

  getFields() {
    return _allFields;
  }

  getFieldIdentifiers() {
    return _allFieldIdentifiers;
  }

  getPatterns() {
    return _allPatterns;
  }

  getFieldData() {
    return _allFieldData;
  }

  getMappings() {
    return _allMappings;
  }

  getCurrentDocumentTypes() {
    return _currentDocumentType;
  }

  getCurrentDocumentTypeId() {
    return _currentDocumentTypeId;
  }

  lastChange() {
    return _lastChange;
  }

  getLastError() {
    return _lastError;
  }
}

const store = new AutoOcrStore();

Dispatcher.register((action) => {
  _lastChange = action.actionType;
  switch (action.actionType) {
    // loading the full list of Companion schemas
    case actionTypes.AutoOcrDocumentTypeGetList:
      _lastChange = actionTypes.AutoOcrDocumentTypeGetList;
      _allDocumentTypes = action.Result;
      store.emitChange();
      break;
    case actionTypes.AutoOcrFieldGetList:
      _lastChange = actionTypes.AutoOcrFieldGetList;
      _allFields = action.Result;
      store.emitChange();
      break;
    case actionTypes.AutoOcrFieldIdentifierList:
      _lastChange = actionTypes.AutoOcrFieldIdentifierList;
      _allFieldIdentifiers = action.Result;
      store.emitChange();
      break;
    case actionTypes.AutoOcrMappingList:
        _lastChange = actionTypes.AutoOcrMappingList;
        _allMappings = action.Result;
        store.emitChange();
        break;
    case actionTypes.AutoOcrDataUpdate:
      _lastChange = actionTypes.AutoOcrDataUpdate;
      _currentDocumentTypeId = action.Result.data;
      store.emitChange();
      break;
    case actionTypes.AutoOcrMappingDocumentType:
        _lastChange = actionTypes.AutoOcrMappingDocumentType;
        _currentDocumentType = action.Result;
        store.emitChange();
        break;
    case actionTypes.AutoOcrPatternList:
      _lastChange = actionTypes.AutoOcrPatternList;
      _allPatterns = action.Result;
      store.emitChange();
      break;
    case actionTypes.AutoOcrFieldDataList:
      _lastChange = actionTypes.AutoOcrFieldDataList;
      _allFieldData = action.Result;
      store.emitChange();
      break;
    case actionTypes.AutoOcrDocTypeDelete:
      _lastChange = actionTypes.AutoOcrDocTypeDelete;
      store.emitChange();
      break;
    default:
  }
});

export default store;
